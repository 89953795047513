import {
  Dispatch, SetStateAction,
} from 'react';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';
import { TDynamicTableExtended } from 'features/sqlEngine/types';

type TParams = {
  tables: TDynamicTableExtended[];
  setTables: Dispatch<SetStateAction<TDynamicTableExtended[]>>;
}

export const useTableCrud = (params: TParams) => {
  const { tables, setTables } = params;

  const updateTable = <TKey extends keyof TDynamicTableExtended, >(
    key: TKey,
    value: SetStateAction<TDynamicTableExtended[TKey]>,
    idx: number,
  ) => {
    setTables((prev) => prev.map((table, curIdx) => {
      if (curIdx !== idx) return table;

      return {
        ...table,
        [key]: value,
      };
    }));
  };

  const createTable = () => {
    setTables([
      ...tables,
      {
        name: `new_table_${tables.length}`,
        columns: ['id', 'name'],
        values: [
          [0, 'Viesturs Kauliņš'],
        ],
        columnTypes: [SqlColumnType.INT, SqlColumnType.VARCHAR],
      },
    ]);
  };

  const deleteTable = (idx: number) => {
    setTables((prev) => prev.filter((_, curIdx) => curIdx !== idx));
  };

  return { updateTable, createTable, deleteTable };
};
