import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';

import LinkRouter from 'components/ui/atoms/link/LinkRouter';
import useInnerClassLinks from 'components/ui/organisms/sidebar/hooks/useInnerClassLinks';
import Collapse from 'components/animations/collapse/Collapse';
import Flex from 'components/ui/atoms/flex/Flex';
import ChevronUpIcon from 'components/ui/atoms/icons/new/ChevronUp';
import Paragraph from 'components/ui/atoms/typography/Paragraph';

import { IClass } from 'domain/entities/class';

import classes from './LinkClassSidebar.module.scss';

type TProps = {
  classDetails: IClass;
};

export default function LinkClassSidebar(props: TProps) {
  const { classDetails } = props;
  const { name } = classDetails;

  const { classId } = useParams();
  const { pathname } = useLocation();
  const innerClassLinks = useInnerClassLinks({ classId: classDetails.id });

  const [isCollapsed, setCollapsed] = useState(true);

  const isClassActive = classDetails.id === classId;

  const toggleCollapsed = () => setCollapsed((prev) => !prev);

  return (
    <div>
      <Flex
        justify="space-between"
        align="center"
        className={clsx(classes.link_class_sidebar, {
          [classes.link_class_sidebar_is_active]: isClassActive,
        })}
        onClick={toggleCollapsed}
      >
        <Paragraph weight="medium" className={classes.class_name}>{name}</Paragraph>
        <ChevronUpIcon className={clsx(classes.chevron_icon, {
          [classes.chevron_icon_is_not_collapsed]: isCollapsed,
        })}
        />
      </Flex>
      <Collapse isVisible={!isCollapsed}>
        {innerClassLinks.map(({ to, text }) => {
          const isInnerLinkActive = pathname === to;

          return (
            <LinkRouter
              to={to}
              key={to}
              className={clsx(classes.class_inner_link, {
                [classes.class_inner_link_is_active]: isInnerLinkActive,
              })}
            >
              <Paragraph variant="footnote-large">{text}</Paragraph>
            </LinkRouter>
          );
        })}
      </Collapse>
    </div>
  );
}
