import type { Klass, LexicalNode } from 'lexical';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';

import { ImageNode } from './ImageNode';
import { InlineImageNode } from './InlineImageNode/InlineImageNode';
import { KeywordNode } from './KeywordNode';
import { LayoutContainerNode } from './LayoutContainerNode';
import { LayoutItemNode } from './LayoutItemNode';

const PlaygroundNodes: Array<Klass<LexicalNode>> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  // @ts-ignore
  TableNode,
  // @ts-ignore
  TableCellNode,
  // @ts-ignore
  TableRowNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  ImageNode,
  InlineImageNode,
  KeywordNode,
  HorizontalRuleNode,
  LayoutContainerNode,
  LayoutItemNode,
];

export default PlaygroundNodes;
