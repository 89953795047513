import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function TsuIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M17.0462 1.01106H15.6157L14.6649 1.28029V1.63012H11.5477V1.26043L10.5928 0.880911H9.81898L9.65731 0.790286C9.66263 0.773601 9.66754 0.756566 9.66754 0.738077C9.66754 0.667571 9.62459 0.607843 9.5644 0.583448V0H9.44435V0.583623C9.38465 0.608527 9.3426 0.667904 9.3426 0.738077C9.3426 0.756216 9.34734 0.773076 9.3526 0.789427L9.18908 0.880911H8.379L7.46969 1.27429V1.63012H4.33188V1.2813L3.38127 1.01106H1.95061L1 1.28148V11.3442C1 16.117 4.77779 20 9.42119 20H9.57888C14.2224 20 18 16.117 18 11.3442V1.81221H17.997V1.28276L17.0462 1.01106ZM16.7863 11.3442C16.7863 15.4291 13.553 18.7523 9.57888 18.7523H9.42119C5.447 18.7523 2.21386 15.4291 2.21386 11.3442V2.82148H16.7863L16.7863 11.3442Z"
        fill="currentColor"
      />
      <path
        d="M5.11078 10.778V4.6924H7.90018V10.973C7.90018 11.9081 8.47114 12.8686 9.60047 12.8686C10.7159 12.8686 11.2875 11.9081 11.2875 10.973V4.6924H14.0762V10.7632C14.0762 13.189 12.6616 15.5743 9.60047 15.5743C6.5393 15.5743 5.11078 13.189 5.11078 10.778Z"
        fill="currentColor"
      />
    </svg>
  );
}
