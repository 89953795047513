import React, { useMemo } from 'react';

import { SidebarViewModel } from 'components/ui/organisms/sidebar/viewModel';
import { ViewModelContext } from 'components/ui/organisms/sidebar/viewModel/context';
import SidebarController from 'components/ui/organisms/sidebar/SidebarController';

import { GetClassesUseCase } from 'domain/useCases/class/GetClassesUseCase';
import { classRepository } from 'domain/repositories/api';

import { useStore } from 'storesMobx/MobxStoreProvider';

const SidebarProvider: React.FC = () => {
  const getClassesListUseCase = new GetClassesUseCase({
    requestCallback: classRepository.getClasses,
  });

  const { commonDataStore } = useStore();

  const vm = useMemo(
    () => new SidebarViewModel(getClassesListUseCase, commonDataStore),
    [],
  );

  return (
    <ViewModelContext.Provider value={vm}>
      <SidebarController />
    </ViewModelContext.Provider>
  );
};

export default SidebarProvider;
