import React from 'react';
import { Tooltip as CuiTooltip, TooltipProps } from 'antd';
import clsx from 'clsx';

import classes from './Tooltip.module.scss';

type TProps = TooltipProps;

export default function Tooltip(props: TProps) {
  const { className } = props;

  return (
    <CuiTooltip {...props} rootClassName={clsx(classes.tooltip, className)} />
  );
}
