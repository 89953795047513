import {
  ILogoutPayload,
  ILogoutResponse,
} from 'domain/repositories/api/auth/IAuthRepository';
import { ApiUseCaseConstructorParams, FetchFunctionParams } from 'domain/useCases/common/types';
import { APIUseCase } from 'domain/useCases/common/APIUseCase';
import { ITokenRepository } from 'domain/repositories/other/interfaces/ITokenRepository';

import { UserStore } from 'storesMobx/stores/UserStore';

import { deleteAllHttpCache } from 'utils/cache';

interface ConstructorParams extends ApiUseCaseConstructorParams<ILogoutPayload, ILogoutResponse> {
  tokenRepository: ITokenRepository;
  userStore: UserStore;
}

export class LogoutUseCase extends APIUseCase<ILogoutPayload, ILogoutResponse> {
  private readonly tokenRepository: ITokenRepository;

  private readonly userStore: UserStore;

  public constructor(params: ConstructorParams) {
    super(params);

    this.tokenRepository = params.tokenRepository;
    this.userStore = params.userStore;
  }

  public fetch = (
    params: FetchFunctionParams<ILogoutPayload, ILogoutResponse>,
  ): Promise<void> => (
    super.fetch({
      ...params,
      onSuccess: async (response) => {
        this.tokenRepository.removeToken();
        this.tokenRepository.removeRefreshToken();

        this.userStore.resetStore();
        deleteAllHttpCache();

        params.onSuccess?.(response);
      },
    })
  );
}
