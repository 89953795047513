import { createBrowserRouter } from 'react-router-dom';
import React from 'react';
import {
  AdminPanelPage,
  ClassesPage,
  ClassPage, ClassPageNew,
  ClassStructureEditor, JoinClassPage,
  ModulePage, NotFoundPage,
  PostsPage,
  QueuePage, RatingPage,
  ResultsUserPage,
  SectionPage,
  SendSolutionPage, SettingsPage,
  SolutionPostModeration,
  SolutionsHistoryPage,
  TaskClassPage,
  TaskLinkedClasses,
  TaskLinkedRefSolutions,
  TaskLinkedTestGroupsPage,
  TaskPage,
  TasksPage,
  UserPage, UsersPage,
} from 'appLazyImports';
import RootElement from 'RootElement';
import ClassProvider from 'providers/class/ClassProvider';

import { route } from 'application/routes/route';

import SqlTest from 'pages/sqlTest/SqlTest';

import SupportPage from './pages/support/SupportPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootElement />,
    children: [
      {
        path: route.base,
        element: <ClassesPage />,
      },

      {
        path: route.base,
        element: <ClassProvider />,
        children: [
          {
            path: route.posts(),
            element: <PostsPage />,
          },
          {
            path: route.tasks(),
            element: <TasksPage />,
          },
          {
            path: route.task(),
            element: <TaskClassPage />,
          },
          {
            path: route.sendSolution(),
            element: <SendSolutionPage />,
          },
          {
            path: route.queue(),
            element: <QueuePage />,
          },
          {
            path: route.postModeration(),
            element: <QueuePage />,
          },
          {
            path: route.submission(),
            element: <SolutionPostModeration />,
          },
          {
            path: route.solutionsHistory(),
            element: <SolutionsHistoryPage />,
          },
          {
            path: route.users(),
            element: <UsersPage />,
          },
          {
            path: route.rating(),
            element: <RatingPage />,
          },
          {
            path: route.userClassResults(),
            element: <ResultsUserPage />,
          },
        ],
      },

      {
        path: route.classManager(),
        element: <ClassPage />,
      },
      {
        path: route.classManagerNew(),
        element: <ClassPageNew />,
      },
      {
        path: route.classManagerStructureEditor(),
        element: <ClassStructureEditor />,
      },
      {
        path: route.moduleManager(),
        element: <ModulePage />,
      },
      {
        path: route.sectionManager(),
        element: <SectionPage />,
      },
      {
        path: route.taskManager(),
        element: <TaskPage />,
      },
      {
        path: route.taskLinkedTestGroups(),
        element: <TaskLinkedTestGroupsPage />,
      },
      {
        path: route.taskLinkedReferenceSolutions(),
        element: <TaskLinkedRefSolutions />,
      },
      {
        path: route.taskLinkedClasses(),
        element: <TaskLinkedClasses />,
      },

      {
        path: route.sectionManagerSimplified(),
        element: <SectionPage />,
      },
      {
        path: route.taskManagerSimplified(),
        element: <TaskPage />,
      },
      {
        path: route.taskLinkedTestGroupsSimplified(),
        element: <TaskLinkedTestGroupsPage />,
      },
      {
        path: route.taskLinkedReferenceSolutionsSimplified(),
        element: <TaskLinkedRefSolutions />,
      },
      {
        path: route.taskLinkedClasses(),
        element: <TaskLinkedClasses />,
      },

      {
        path: route.joinClass(),
        element: <JoinClassPage />,
      },
      {
        path: route.user(),
        element: <UserPage />,
      },
      {
        path: route.settings(),
        element: <SettingsPage />,
      },

      {
        path: route.support,
        element: <SupportPage />,
      },
      {
        path: route.adminPanel,
        element: <AdminPanelPage />,
      },
      {
        path: route.sqlTest,
        element: <SqlTest />,
      },

      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);
