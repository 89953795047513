import React, { useRef } from 'react';

import DeleteIcon from 'components/ui/atoms/icons/new/Delete';
import { EditableTableAbility } from 'components/ui/organisms/editableTable/enums/editableTableAbility';
import IconButton from 'components/ui/atoms/iconButton/IconButton';
import { ADD_COLUMN_NAME } from 'components/ui/organisms/editableTable/constants';

type TProps = {
  value: string;
  column: string;
  abilities: EditableTableAbility[];
  rowIdx: number;
  columnIdx: number;
  onDeleteRow: (rowIdx: number) => void;
  onEditCell: (rowIdx: number, columnIdx: number, newValue: string) => void;
}

const Cell: React.FC<TProps> = (props) => {
  const {
    value, column, abilities, rowIdx, columnIdx, onDeleteRow, onEditCell,
  } = props;

  const paragraphRef = useRef<HTMLParagraphElement | null>(null);

  if (column !== ADD_COLUMN_NAME) {
    return (
      <p
        contentEditable={abilities.includes(EditableTableAbility.EDIT_CELL)}
        suppressContentEditableWarning
        ref={paragraphRef}
        style={{ whiteSpace: 'pre-wrap' }}
        onBlur={() => onEditCell(
          rowIdx,
          columnIdx,
          (paragraphRef.current as HTMLElement).innerText,
        )}
      >
        {value}
      </p>
    );
  }

  if (abilities.includes(EditableTableAbility.DELETE_ROW)) {
    return (
      <IconButton icon={<DeleteIcon />} type="default" danger onClick={() => onDeleteRow(rowIdx)} />
    );
  }

  return null;
};

export default Cell;
