import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function SendPlaneIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.6357 15.6701L21.3521 10.5208C22.8516 6.02242 23.6013 3.77322 22.414 2.58595C21.2268 1.39869 18.9776 2.14842 14.4792 3.64788L9.32987 5.36432C5.69923 6.57453 3.88392 7.17964 3.36806 8.06698C2.87731 8.91112 2.87731 9.95369 3.36806 10.7978C3.88392 11.6852 5.69923 12.2903 9.32987 13.5005C9.91282 13.6948 10.2043 13.792 10.4479 13.9551C10.684 14.1131 10.8869 14.316 11.0449 14.5521C11.208 14.7957 11.3052 15.0872 11.4995 15.6701C12.7097 19.3008 13.3148 21.1161 14.2022 21.6319C15.0463 22.1227 16.0889 22.1227 16.933 21.6319C17.8204 21.1161 18.4255 19.3008 19.6357 15.6701Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M17.2116 8.84823C17.5061 8.55696 17.5087 8.0821 17.2174 7.78758C16.9262 7.49307 16.4513 7.49044 16.1568 7.78171L17.2116 8.84823ZM11.6626 14.336L17.2116 8.84823L16.1568 7.78171L10.6079 13.2695L11.6626 14.336Z"
        fill="currentColor"
      />
    </svg>
  );
}
