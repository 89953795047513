import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IBatchRepository,
  IUpdateClassStructurePayload,
  IUpdateClassStructureResponse,
  IUpdateModulesStructurePayload,
  IUpdateModulesStructureResponse,
  IUpdateSectionsStructurePayload,
  IUpdateSectionsStructureResponse,
} from 'domain/repositories/api/batch/IBatchRepository';

export class BatchRepository implements IBatchRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/batch`;

  public constructor(private _httpClient: IHttpClient) {}

  public updateSectionsStructure = (payload: IUpdateSectionsStructurePayload) => {
    const { classId, classSections } = payload;

    return this._httpClient.post<IUpdateSectionsStructureResponse>(
      `${this.prefix}/class/${classId}/classSection/update`,
      { classSections },
    );
  };

  public updateModulesStructure = (payload: IUpdateModulesStructurePayload) => {
    const { classId, classStudyModules } = payload;

    return this._httpClient.post<IUpdateModulesStructureResponse>(
      `${this.prefix}/class/${classId}/classStudyModule/update`,
      { classStudyModules },
    );
  };

  public updateClassStructure = (payload: IUpdateClassStructurePayload) => {
    const { classId, classStudyModulesId } = payload;

    return this._httpClient.post<IUpdateClassStructureResponse>(
      `${this.prefix}/class/${classId}/update`,
      { classStudyModulesId },
    );
  };
}
