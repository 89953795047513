import {
  action, computed, makeObservable, observable,
} from 'mobx';

import { IThemeRepository } from 'domain/repositories/other/interfaces/IThemeRepository';

export class ThemeStore {
  @observable private _isDarkMode: boolean = false;

  public constructor(private _themeRepository: IThemeRepository) {
    makeObservable(this);

    this._isDarkMode = this._themeRepository.isDarkMode() ?? false;
    this.updateDocumentBodyClassList();
  }

  @action public toggleTheme = () => {
    this._isDarkMode = !this._isDarkMode;
    this._themeRepository.toggleTheme();

    this.updateDocumentBodyClassList();
  };

  @computed public get isDarkMode(): boolean {
    return this._isDarkMode;
  }

  private updateDocumentBodyClassList = () => {
    if (!this.isDarkMode) {
      document.body.classList.remove('dark');
    } else {
      document.body.classList.add('dark');
    }
  };
}
