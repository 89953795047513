import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import classes from './ContentLayout.module.scss';

type TProps = PropsWithChildren & {
  className?: string;
  withClassHeader?: boolean;
}

export default function ContentLayout(props: TProps) {
  const { className, withClassHeader, children } = props;

  return (
    <div className={clsx(classes.content_layout, {
      [classes.content_layout_with_class_header]: withClassHeader,
    }, className)}
    >
      {children}
    </div>
  );
}
