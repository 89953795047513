import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import SectionHeader from 'components/ui/organisms/classStructure/components/SectionHeader';
import TaskHeader from 'components/ui/organisms/classStructure/components/TaskHeader';
import { SectionWrapper } from 'components/ui/organisms/classStructure/styled';

interface SectionProps {
  id: string;
  name: string;
  tasks: { id: string, name: string, max_score: number }[];
  moduleId?: string;
  verticalLine?: boolean;
  isSectionPercentable?: boolean;
  onDeleteSection?: (id: string) => void;
  draggableProps?: DraggableProvidedDragHandleProps | null | undefined;
}

const Section: React.FC<SectionProps> = ({
  id,
  name,
  tasks,
  moduleId,
  verticalLine = false,
  isSectionPercentable = false,
  onDeleteSection,
  draggableProps,
}) => {
  const totalSum = tasks.reduce((sum, task) => sum + task.max_score, 0);

  return (
    <SectionWrapper verticalLine={verticalLine}>
      <SectionHeader
        id={id}
        name={name}
        points={totalSum}
        moduleId={moduleId}
        // percent={isSectionPercentable ? data.percentage : undefined}
        onDelete={onDeleteSection}
        draggableProps={draggableProps}
      />
      {
        tasks.map((task) => (
          <TaskHeader
            key={task.id}
            id={task.id}
            name={task.name}
            maxScore={task.max_score}
            moduleId={moduleId}
            sectionId={id}
          />
        ))
      }
    </SectionWrapper>
  );
};

export default Section;
