import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAuthPageViewModel } from 'pages/auth/viewModel/context';

const schema = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(8),
});

export type TLoginFormState = z.infer<typeof schema>;

export default function useLoginForm() {
  const { login } = useAuthPageViewModel();

  const methods = useForm<TLoginFormState>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  const handleSubmit = async ({ email, password }: TLoginFormState) => {
    await login({ email, password });
  };

  return { methods, handleSubmit };
}
