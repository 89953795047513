import { IProgrammingLanguage } from 'domain/entities/programmingLanguage';

export interface ISubjectType {
  id: string;
  name: SubjectType;
  programming_languages: IProgrammingLanguage[];
}

export enum SubjectType {
  PROGRAMMING = 'Программирование',
  DATA_BASES = 'Базы данных',
  SYSTEM_ADMINISTRATION = 'Основы системного администрирования',
  FRONTEND = 'Разработка пользовательского интерфейса',
  BACKEND = 'Разработка серверных приложений',
  MOBILE = 'Мобильная разработка',
  ARCHITECTURE = 'Архитектура',
  ANALYTICS = 'Аналитика',
  ML = 'Машинное обучение'
}
