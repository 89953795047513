export enum CodeEditorMode {
  C_CPP = 'c_cpp',
  C_SHARP = 'csharp',
  PYTHON = 'python',
  Java = 'java',
  SQL = 'sql',
  GOLANG = 'golang',
  PASCAL = 'pascal',
  R = 'r',
}

export interface IProgrammingLanguage {
  class_id: string;
  name: string;
  programming_language_id: string;
  aceMode: CodeEditorMode;
}

export interface IProgrammingLanguageShort {
  id: string;
  name: string;
}

export interface IProgrammingLanguageParent {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  imageName: string;
  fileExtension: string;
  isCompilable: boolean;
  compiler: string;
}
