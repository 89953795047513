import { z } from 'zod';
import { useTranslation } from 'react-i18next';

import { ns } from 'utils/locales';

export default function useSetterValidationError() {
  const { t } = useTranslation();

  z.setErrorMap((issue, ctx) => {
    const { data } = ctx;

    if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === 'email') {
        return { message: t(`${ns.errors}:invalidEmail`) };
      }
    }

    if (issue.code === z.ZodIssueCode.too_small) {
      if (!data) return { message: t(`${ns.errors}:required`) };

      return { message: t(`${ns.errors}:invalidMinLength`, { min: issue.minimum }) };
    }

    return { message: ctx.defaultError };
  });
}
