import React from 'react';
import { Modal as CuiModal, ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/ui/atoms/button/Button';
import Flex from 'components/ui/atoms/flex/Flex';
import Title from 'components/ui/atoms/typography/Title';

import { ns } from 'utils/locales';

import classes from './Modal.module.scss';

export type TModalProps = ModalProps & {
  title?: string;
  onClose?: () => void;
};

export default function Modal(props: TModalProps) {
  const {
    title, children, onClose, ...rest
  } = props;

  const { t } = useTranslation();

  return (
    <CuiModal
      destroyOnClose
      maskClosable
      footer={null}
      closeIcon={null}
      onCancel={onClose}
      classNames={{
        content: classes.content,
      }}
      {...rest}
    >
      {title && (
        <Flex justify="space-between" align="center" className={classes.header}>
          <Title level={3}>{title}</Title>
          <Button onClick={onClose}>{t(`${ns.buttons}:close`)}</Button>
        </Flex>
      )}
      {children}
    </CuiModal>
  );
}
