import { IHttpClient } from 'data/network/httpClient/IHttpClient';
import { GET_CLASS_ROLES_CACHE_KEY } from 'data/axios/constants';

import {
  IGetClassRolesResponse, IGetGlobalPermissionsResponse, IGetPlatformPermissionsResponse,
  IPermissionRepository,
} from 'domain/repositories/api/permission/IPermissionRepository';

export class PermissionRepository implements IPermissionRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/permission`;

  public constructor(private _httpClient: IHttpClient) {}

  public getClassRoles = () => this._httpClient.get<IGetClassRolesResponse>(`${this.prefix}/classRoles`, undefined, true, {
    id: GET_CLASS_ROLES_CACHE_KEY,
  });

  public getGlobalPermissions = () => this._httpClient.get<IGetGlobalPermissionsResponse>(`${this.prefix}/global`, undefined, true);

  public getPlatformPermissions = () => this._httpClient.get<IGetPlatformPermissionsResponse>(`${this.prefix}/platform`, undefined, true);
}
