import React from 'react';
import { Skeleton as CuiSkeleton, SkeletonProps } from 'antd';

export type TSkeletonProps = SkeletonProps;

export default function Skeleton(props: TSkeletonProps) {
  return (
    <CuiSkeleton {...props} />
  );
}
