import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

import classes from './LinkRouter.module.scss';

type TLinkColor = 'primary' | 'accent';

type TProps = Omit<LinkProps, 'to'> & {
  to?: string | number;
  withHover?: boolean;
  color?: TLinkColor;
};

export default function LinkRouter(props: TProps) {
  const {
    className, to, withHover, color, children, ...rest
  } = props;

  if (!to) return <div className={className}>{children}</div>;

  return (
    <Link
      {...rest}
      // @ts-ignore
      to={to}
      className={clsx(classes.link_router, className, {
        [classes.link_router_with_hover]: withHover,
        [classes.link_router_color_accent]: color === 'accent',
      })}
    >
      {children}
    </Link>
  );
}
