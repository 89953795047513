import React from 'react';

import { StructureWrapper } from 'components/ui/organisms/classStructure/styled';
import Module, { IModuleStructureShort } from 'components/ui/organisms/classStructure/components/Module';

interface ClassStructureProps {
  data: IModuleStructureShort[];
}

const ClassStructure: React.FC<ClassStructureProps> = ({
  data,
}) => (
  <StructureWrapper>
    {
      data.map((module) => (
        <Module data={module} key={module.id} />
      ))
    }
  </StructureWrapper>
);

export default ClassStructure;
