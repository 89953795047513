import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  ICreateCommentPayloadDS, ICreateCommentResponseDS,
  IDeleteCommentPayloadDS, IDeleteCommentResponseDS,
  IGetPrivateCommentsPayloadDS, IGetPrivateCommentsResponseDS,
  IGetPublicCommentsPayloadDS, IGetPublicCommentsResponseDS,
  ISolutionCommentDataSource, IUpdateCommentPayloadDS, IUpdateCommentResponseDS,
} from 'domain/repositories/api/solutionComment/datasource/ISolutionCommentDataSource';

export class SolutionCommentDataSource implements ISolutionCommentDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/solution`;

  public constructor(private _httpClient: IHttpClient) {}

  public getPublicComments = ({ solutionId }: IGetPublicCommentsPayloadDS) => this._httpClient
    .get<IGetPublicCommentsResponseDS>(`${this.prefix}/${solutionId}/comment/public/get`);

  public getPrivateComments = ({ solutionId }: IGetPrivateCommentsPayloadDS) => this._httpClient
    .get<IGetPrivateCommentsResponseDS>(`${this.prefix}/${solutionId}/comment/private/get`);

  public createComment = ({ solutionId, ...body }: ICreateCommentPayloadDS) => this._httpClient
    .post<ICreateCommentResponseDS>(`${this.prefix}/${solutionId}/comment/add`, body);

  public updateComment = ({ commentId, ...body }: IUpdateCommentPayloadDS) => this._httpClient
    .post<IUpdateCommentResponseDS>(`${this.prefix}/comment/${commentId}/update`, body);

  public deleteComment = ({ commentId }: IDeleteCommentPayloadDS) => this._httpClient
    .post<IDeleteCommentResponseDS>(`${this.prefix}/comment/${commentId}/delete`);
}
