import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  ICreateModulePayloadDS,
  ICreateModuleResponseDS,
  IDeleteModulePayloadDS, IDeleteModuleResponseDS,
  IForkModulePayloadDS, IForkModuleResponseDS,
  IGetModulePayloadDS, IGetModuleResponseDS, IGetModuleSectionsPayloadDS,
  IGetModuleSectionsResponseDS,
  IModuleDataSource, IUpdateModulePayloadDS, IUpdateModuleResponseDS,
} from 'domain/repositories/api/module/dataSource/IModuleDataSource';

export class ModuleDataSource implements IModuleDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/classStudyModule`;

  public constructor(private _httpClient: IHttpClient) {}

  public getModule = ({ id }: IGetModulePayloadDS) => this._httpClient
    .get<IGetModuleResponseDS>(`${this.prefix}/${id}/get`);

  public createModule = ({ classId, ...body }: ICreateModulePayloadDS) => this._httpClient
    .post<ICreateModuleResponseDS>(`${this.prefix}/class/${classId}/create`, body);

  public updateModule = ({ id, ...body }: IUpdateModulePayloadDS) => this._httpClient
    .post<IUpdateModuleResponseDS>(`${this.prefix}/${id}/update`, body);

  public deleteModule = ({ id }: IDeleteModulePayloadDS) => this._httpClient
    .post<IDeleteModuleResponseDS>(`${this.prefix}/${id}/delete`);

  public forkModule = ({ moduleId, classId, iso }: IForkModulePayloadDS) => this._httpClient
    .post<IForkModuleResponseDS>(`${this.prefix}/fork/${moduleId}/${classId}/${iso}`);

  public getSections = ({ studyModuleId }: IGetModuleSectionsPayloadDS) => this._httpClient
    .get<IGetModuleSectionsResponseDS>(`${this.prefix}/${studyModuleId}/classSection/get`, {});
}
