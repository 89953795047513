import { BaseCookieRepository } from 'domain/repositories/other/BaseCookieRepository';
import { ITokenRepository } from 'domain/repositories/other/interfaces/ITokenRepository';

export class TokenRepository extends BaseCookieRepository implements ITokenRepository {
  private readonly tokenKey = 'token';

  private readonly refreshTokenKey = 'refreshToken';

  public setToken = (token: string) => this.set(this.tokenKey, token);

  public getToken = () => this.get(this.tokenKey);

  public removeToken = () => this.remove(this.tokenKey);

  public setRefreshToken = (token: string) => this.set(this.refreshTokenKey, token);

  public getRefreshToken = () => this.get(this.refreshTokenKey);

  public removeRefreshToken = () => this.remove(this.refreshTokenKey);

  public init = (token: string, refreshToken: string) => {
    this.setToken(token);
    this.setRefreshToken(refreshToken);
  };

  public reset = () => {
    this.removeToken();
    this.removeRefreshToken();
  };

  public tokensPresent = () => !!this.getToken() && !!this.getRefreshToken();
}

export const tokenRepository = new TokenRepository();
