import { useEffect, useRef } from 'react';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';
import { TDynamicTableColumns, TDynamicTableValues } from 'features/sqlEngine/types';

type TParams = {
  columns: string[];
  values: TDynamicTableValues;
  columnTypes?: SqlColumnType[];
  setColumns?: (columns: TDynamicTableColumns) => void;
  setValues?: (values: TDynamicTableValues) => void;
  setColumnTypes?: (columnTypes: SqlColumnType[]) => void;
}

export const useDataEditing = (params: TParams) => {
  const {
    columns,
    values,
    columnTypes,
    setColumns,
    setValues,
    setColumnTypes,
  } = params;

  const valuesRef = useRef(values);
  const columnsRef = useRef(columns);
  const columnTypesRef = useRef(columnTypes);

  useEffect(() => { valuesRef.current = values; }, [values]);
  useEffect(() => { columnsRef.current = columns; }, [columns]);
  useEffect(() => { columnTypesRef.current = columnTypes; }, [columnTypes]);

  const addRow = () => {
    const newRow = Array.from({ length: columnsRef.current.length }, () => `value${valuesRef.current.length}`);

    setValues?.([...valuesRef.current, newRow]);
  };

  const deleteRow = (rowIdx: number) => {
    setValues?.(valuesRef.current.filter((_, idx) => idx !== rowIdx - 1));
  };

  const addColumn = () => {
    setColumns?.([...columnsRef.current, 'column']);

    if (columnTypesRef.current) {
      setColumnTypes?.([...columnTypesRef.current, SqlColumnType.VARCHAR]);
    }

    setValues?.(valuesRef.current.map((row, idx) => [...row, `value${idx}`]));
  };

  const editColumn = (columnIdx: number, newValue: string) => {
    setColumns?.(columnsRef.current.map((col, idx) => (idx === columnIdx ? newValue : col)));
  };

  const deleteColumn = (columnIdx: number) => {
    setColumns?.(columnsRef.current.filter((_, idx) => idx !== columnIdx));

    if (columnTypesRef.current) {
      setColumnTypes?.(columnTypesRef.current.filter((_, idx) => idx !== columnIdx));
    }

    setValues?.(valuesRef.current.map((row) => row.filter((_, idx) => idx !== columnIdx)));
  };

  const editCell = (rowIdx: number, columnIdx: number, newValue: string) => {
    setValues?.(valuesRef.current.map((row, curRowIdx) => {
      if (curRowIdx !== rowIdx - 1) return row;

      return row.map((cell, curColumnIdx) => {
        if (curColumnIdx !== columnIdx) return cell;

        return newValue;
      });
    }));
  };

  const editColumnType = (columnIdx: number, sqlColumnType: SqlColumnType) => {
    if (!columnTypesRef.current) return;

    setColumnTypes?.(columnTypesRef.current.map((curType, curColumnIdx) => (
      curColumnIdx === columnIdx ? sqlColumnType : curType
    )));
  };

  return {
    addRow, deleteRow, addColumn, editColumn, deleteColumn, editCell, editColumnType,
  };
};
