import React from 'react';

import { ModuleWrapper } from 'components/ui/organisms/classStructure/styled';
import ModuleHeader from 'components/ui/organisms/classStructure/components/ModuleHeader';
import Section from 'components/ui/organisms/classStructure/components/Section';

export interface IModuleStructureShort {
  id: string;
  name: string;
  classSections: {
    id: string;
    name: string;
    classTasks: {
      id: string;
      name: string;
      max_score: number;
    }[];
  }[];
}

interface ModuleProps {
  data: IModuleStructureShort;
}

const Module: React.FC<ModuleProps> = ({ data }) => {
  const { id, name, classSections } = data;

  const totalSum = classSections.reduce((sum, section) => (
    sum + section.classTasks.reduce((tasksSum, curTask) => tasksSum + curTask.max_score, 0)
  ), 0);

  return (
    <ModuleWrapper>
      <ModuleHeader
        id={id}
        name={name}
        points={totalSum}
      />
      {
        classSections.map((section, idx) => (
          <Section
            id={section.id}
            name={section.name}
            tasks={section.classTasks}
            moduleId={id}
            key={idx}
          />
        ))
      }
    </ModuleWrapper>
  );
};

export default Module;
