import { AxiosError } from 'axios';

import { ErrorNotificationType, ShowErrorFunction } from 'application/notification/types';

import { ILoginPayload, ILoginResponse } from 'domain/repositories/api/auth/IAuthRepository';
import { ApiUseCaseConstructorParams, FetchFunctionParams } from 'domain/useCases/common/types';
import { APIUseCase } from 'domain/useCases/common/APIUseCase';
import { ITokenRepository } from 'domain/repositories/other/interfaces/ITokenRepository';

import { CommonDataStore } from 'storesMobx/stores/CommonDataStore';
import { UserStore } from 'storesMobx/stores/UserStore';

import { deleteAllHttpCache } from 'utils/cache';

interface ConstructorParams extends ApiUseCaseConstructorParams<ILoginPayload, ILoginResponse> {
  tokenRepository: ITokenRepository;
  userStore: UserStore;
  commonDataStore: CommonDataStore;
}

export class LoginUseCase extends APIUseCase<ILoginPayload, ILoginResponse> {
  private readonly tokenRepository: ITokenRepository;

  private readonly userStore: UserStore;

  private readonly commonDataStore: CommonDataStore;

  private readonly _notifyError: ShowErrorFunction | undefined;

  public constructor(params: ConstructorParams) {
    super(params);

    this._notifyError = params.notifyError;
    this.userStore = params.userStore;
    this.commonDataStore = params.commonDataStore;
    this.tokenRepository = params.tokenRepository;
  }

  public fetch = (
    params: FetchFunctionParams<ILoginPayload, ILoginResponse>,
  ): Promise<void> => (
    super.fetch({
      ...params,
      onSuccess: async (response) => {
        const { token, refreshToken } = response;

        deleteAllHttpCache();

        this.tokenRepository.setToken(token);
        this.tokenRepository.setRefreshToken(refreshToken);

        await this.userStore.onAuthenticateSuccess();
        await this.commonDataStore.fetch();

        params.onSuccess?.(response);
      },
      onError: (error) => {
        if (error instanceof AxiosError && error.response?.status === 401) {
          this._notifyError?.(ErrorNotificationType.INCORRECT_LOGIN_OR_PASSWORD);
        } else {
          this._notifyError?.(ErrorNotificationType.SOMETHING_WENT_WRONG);
        }
      },
    })
  );
}
