import { useLocation, useParams } from 'react-router-dom';

import { getClassId } from 'application/routes/routesInClass';

import { ClassRole } from 'domain/entities/class';

import { useStore } from 'storesMobx/MobxStoreProvider';

type TReturn = {
  classRole: ClassRole;
  isTeacher: boolean;
  isStudent: boolean;
}

export const useClassRoleByRoute = (): TReturn => {
  const { userStore } = useStore();
  const { classId: classIdParams } = useParams();
  const { pathname } = useLocation();

  const classId = classIdParams ?? getClassId(pathname);

  if (!classId) {
    return {
      classRole: ClassRole.STUDENT,
      isStudent: true,
      isTeacher: false,
    };
  }

  const classRole = userStore.getRoleInClass(classId);

  return {
    classRole,
    isStudent: classRole === ClassRole.STUDENT,
    isTeacher: classRole === ClassRole.TEACHER,
  };
};
