import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function LogoSpines(props: IconProps) {
  return (
    <svg width="143" height="79" viewBox="0 0 143 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.9963 78.6506V23.3286L39.4947 0L19.9963 78.6506Z" fill="#0055AE" />
      <path d="M27.5974 75.9845L45.4433 19.9959L65.2721 6.6653L27.5974 75.9845Z" fill="#263340" />
      <path d="M37.8423 73.9849L65.2721 28.6608L85.7619 19.9959L37.8423 73.9849Z" fill="#0055AE" />
      <path d="M48.0872 68.6526L82.7876 37.659L101.625 31.3269L48.0872 68.6526Z" fill="#263340" />
      <path d="M65.2721 68.6526L92.702 49.6565L113.522 45.3241L99.9726 54.3222L65.2721 68.6526Z" fill="#263340" />
      <path d="M81.4657 70.319L103.277 60.9875H121.784L108.235 65.9865L81.4657 70.319Z" fill="#263340" />
      <path d="M99.9726 73.9849L117.819 70.319H131.038L117.819 73.9849H99.9726Z" fill="#263340" />
      <path d="M113.522 78.6506L125.42 75.9845L142.935 78.6506H113.522Z" fill="#263340" />
      <path d="M16.361 78.6506L6.11613 52.6559L13.3867 37.659L16.361 78.6506Z" fill="#263340" />
      <path d="M10.0819 78.6506L0.16748 75.9845L2.48085 61.9873L10.0819 78.6506Z" fill="#263340" />
    </svg>
  );
}
