import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import { SubjectType } from 'domain/entities/subject';
import {
  IGetSubjectTypeByIdPayload, IGetSubjectTypesListResponse,
  ISubjectTypeRepository,
} from 'domain/repositories/api/subjectType/ISubjectTypeRepository';

export class SubjectTypeRepository implements ISubjectTypeRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/proxy/subjectType`;

  public constructor(private _httpClient: IHttpClient) {}

  public getList = () => this._httpClient.get<IGetSubjectTypesListResponse>(`${this.prefix}/get`, undefined, true);

  public getSubjectTypeById = async ({ id }: IGetSubjectTypeByIdPayload) => {
    const { subject_types: SubjectTypes } = await this.getList();

    return SubjectTypes.find((item) => item.id === id)?.name ?? SubjectType.PROGRAMMING;
  };
}
