import React, { HTMLAttributeAnchorTarget } from 'react';
import { useTheme } from 'styled-components';

import { StyledLink, StyledRouterLink } from 'components/ui/atoms/link/styled';

interface LinkProps {
  children: React.ReactNode;
  href?: string;
  to?: string;
  className?: string;
  underline?: boolean;
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
}

const Link: React.FC<LinkProps> = ({
  children,
  href,
  to,
  className = '',
  underline = false,
  target,
  onClick,
}) => {
  const theme = useTheme();

  return (
    to ? (
      <StyledRouterLink
        to={to}
        className={className}
        $underline={underline}
        target={target}
        theme={theme}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
        }}
      >
        {children}
      </StyledRouterLink>
    ) : (
      <StyledLink
        href={href}
        className={className}
        $underline={underline}
        target={target}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
        }}
      >
        <div>
          {children}
        </div>
      </StyledLink>
    )
  );
};

export default Link;
