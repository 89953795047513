import { IHttpClient } from 'data/network/httpClient/IHttpClient';
import { GET_ME_CACHE_KEY } from 'data/axios/constants';
import { DUPLICATE_SEARCH_QUERY_SUFFIX } from 'data/network/httpClient/HttpClient';

import {
  IChangePasswordPayload, IChangePasswordResponse,
  IGetAllUsersPayload,
  IGetAllUsersResponse, IGetMeResponse,
  IGetUserPayload,
  IUserRepository, TGetUserAgentsPayload,
  TGetUserAgentsResponse, TUpdateUsernamePayload, TUpdateUsernameResponse,
} from 'domain/repositories/api/user/IUserRepository';

import { SearchQueryOperators } from 'utils/enums/SearchQueryOperators';

export class UserRepository implements IUserRepository {
  private readonly prefix = `${process.env.REACT_APP_USER_API}/user`;

  private readonly prefixClass = `${process.env.REACT_APP_CLASS_API}/user`;

  public constructor(private _httpClient: IHttpClient) {}

  public getMe = () => this._httpClient
    .get<IGetMeResponse>(`${this.prefix}/retrieve`, undefined, true, {
      id: GET_ME_CACHE_KEY,
    });

  public getUser = async ({ id }: IGetUserPayload) => {
    const { users, ...response } = await this.getAllUsers({ filters: { id } });
    return { ...response, user: users.length ? users[0] : null };
  };

  public getAllUsers = ({ filters, ...pagination }: IGetAllUsersPayload) => this._httpClient
    .get<IGetAllUsersResponse>(`${this.prefix}/get`, {
      ...pagination,
      id: filters?.id,
      name: filters?.name && `${SearchQueryOperators.LIKE}${filters.name}`,
      email: filters?.email && `${SearchQueryOperators.LIKE}${filters.email}`,
      created_at: filters?.createdAt?.at(0) && `${SearchQueryOperators.GREATER_THAN_OR_EQUAL}${filters.createdAt.at(0)}`,
      [`created_at${DUPLICATE_SEARCH_QUERY_SUFFIX}`]: filters?.createdAt?.at(1) && `${SearchQueryOperators.LESS_THAN_OR_EQUAL}${filters.createdAt.at(1)}`,
      updated_at: filters?.updatedAt?.at(0) && `${SearchQueryOperators.GREATER_THAN_OR_EQUAL}${filters.updatedAt.at(0)}`,
      [`updated_at${DUPLICATE_SEARCH_QUERY_SUFFIX}`]: filters?.updatedAt?.at(1) && `${SearchQueryOperators.LESS_THAN_OR_EQUAL}${filters.updatedAt.at(1)}`,
    });

  public changePassword = (body: IChangePasswordPayload) => this._httpClient
    .post<IChangePasswordResponse>(`${this.prefix}/authorizationFields/update`, body);

  public updateUsername = (body: TUpdateUsernamePayload) => this._httpClient
    .post<TUpdateUsernameResponse>(`${this.prefix}/username`, body);

  public logoutAll = () => this._httpClient.post<void>(`${this.prefix}/logout/all`);

  public getUserAgents = ({ userId }: TGetUserAgentsPayload) => this._httpClient
    .get<TGetUserAgentsResponse>(`${this.prefixClass}/${userId}/agent`);
}
