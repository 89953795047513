import React from 'react';

import Flex from 'components/ui/atoms/flex/Flex';
import TaskForm from 'components/ui/organisms/entityDrawer/entities/taskProxy/content/components/TaskForm';
import { useEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel/context';

const EntityDrawerTaskProxyContent: React.FC = () => {
  const { taskProxyViewModel } = useEntityDrawerViewModel();

  const { taskProxy } = taskProxyViewModel;

  return (
    <Flex gap={20}>
      {taskProxy?.task_translation.map((translation) => (
        <TaskForm translation={translation} key={translation.iso} />
      ))}
    </Flex>
  );
};

export default EntityDrawerTaskProxyContent;
