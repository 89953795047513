import React from 'react';
import clsx from 'clsx';

import ChevronUpIcon from 'components/ui/atoms/icons/new/ChevronUp';
import classes from 'components/ui/atoms/select/Select.module.scss';

type TProps = {
  menuIsOpen: boolean;
};

export default function DropdownIndicator(props: TProps) {
  const { menuIsOpen } = props;

  return (
    <ChevronUpIcon className={clsx(classes.dropdown_indicator, {
      [classes.dropdown_indicator_collapsed]: menuIsOpen,
    })}
    />
  );
}
