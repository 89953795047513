import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';

import Flex from 'components/ui/atoms/flex/Flex';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';

type TProps = {
  value: SqlColumnType;
  onChange: (value: SqlColumnType) => void;
  closePopover: () => void;
}

const TypePopoverContent = (props: TProps) => {
  const { value, onChange, closePopover } = props;

  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
    closePopover();
  };

  return (
    <Radio.Group onChange={handleChange} value={value}>
      <Flex vertical gap={4}>
        {Object.values(SqlColumnType).map((columnType) => (
          <Radio key={columnType} value={columnType}>{columnType}</Radio>
        ))}
      </Flex>
    </Radio.Group>
  );
};

export default TypePopoverContent;
