import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ChainedBackend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';

import { ns } from 'utils/locales';

if (!localStorage.getItem('i18nextLng')) {
  localStorage.setItem('i18nextLng', 'ru-RU');
}

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: ns.common,
    ns: Object.values(ns).map((key) => key),
    returnNull: false,
    backend: {
      backends: [
        LocalStorageBackend,
        HttpBackend,
      ],
      backendOptions: [{
        expirationTime: 24 * 60 * 60 * 1000, // 1 day
        versions: { en: 'v1.0.16', ru: 'v1.0.16' },
      }],
    },
  });
