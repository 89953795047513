import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalConfirmContext } from 'application/confirmModal/ModalConfirmProvider';

import WarningIcon from 'components/ui/atoms/icons/new/Warning';

import { ns } from 'utils/locales';

interface UseConfirmProps<TArgs = undefined> {
  title: string;
  cbOnOk: (args: TArgs | undefined) => void;
  loading?: boolean;
  content?: string;
}

export const useConfirmModal = <TArgs = undefined, >({
  title,
  cbOnOk,
  loading,
  content,
}: UseConfirmProps<TArgs>) => {
  const api = useContext(ModalConfirmContext);

  const { t } = useTranslation([ns.errors, ns.messages]);

  const showConfirm = (args?: TArgs) => {
    api?.confirm({
      title,
      icon: <WarningIcon />,
      ...(content ? { content } : null),
      onOk() {
        cbOnOk(args);
      },
      okButtonProps: {
        loading,
      },
      okText: t(`${ns.buttons}:accept`),
      cancelText: t(`${ns.buttons}:cancel`),
      maskClosable: true,
    });
  };

  return {
    showConfirm,
  };
};
