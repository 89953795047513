import { IHttpClient } from 'data/network/httpClient/IHttpClient';
import { DUPLICATE_SEARCH_QUERY_SUFFIX } from 'data/network/httpClient/HttpClient';

import {
  IGetCheckerRunOnTestDetailsPayloadDS,
  IGetCheckerRunOnTestDetailsResponseDS,
  ISendSubmissionPayloadDS,
  ISendSubmissionResponseDS,
  IGetClassSubmissionsPayloadDS,
  IGetClassSubmissionsResponseDS,
  ISolutionDataSource,
  IGetSubmissionDetailsPayloadDS,
  IGetSubmissionDetailsResponseDS,
  IGetSolutionSubmissionsPayloadDS,
  IGetSolutionSubmissionsResponseDS,
  IUpdateSolutionPayloadDS,
  IUpdateSolutionResponseDS,
  IResendSubmissionPayloadDS,
  IResendSubmissionResponseDS, IGetCheckerRunOnTestFullDataPayloadDS,
  IGetCheckerRunOnTestFullDataResponseDS, IUpdateSubmissionPayloadDS, IUpdateSubmissionResponseDS,
} from 'domain/repositories/api/solution/dataSource/ISolutionDataSource';

import { SearchQueryOperators } from 'utils/enums/SearchQueryOperators';

export class SolutionDataSource implements ISolutionDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/solution`;

  public constructor(private _httpClient: IHttpClient) {}

  public getClassSubmissions = async ({
    classId,
    filters,
    ...pagination
  }: IGetClassSubmissionsPayloadDS) => this._httpClient
    .get<IGetClassSubmissionsResponseDS>(`${this.prefix}/class/${classId}/attempt/get`, {
      ...pagination,
      name: filters?.taskName && `${SearchQueryOperators.LIKE}${filters.taskName}`,
      sender_id: filters?.senderId,
      postmoderation_verdict: filters?.postmoderationVerdicts,
      testing_verdict: filters?.testingVerdicts,
      final_testing_verdict: filters?.finalTestingVerdicts,
      programming_language_id: filters?.programmingLanguageIds,
      class_study_module_id: filters?.classStudyModuleIds,
      class_section_id: filters?.classSectionIds,
      class_task_id: filters?.classTaskIds,
      updated_at: filters?.updatedAt?.at(0) && `[gte]${filters?.updatedAt.at(0)}`,
      [`updated_at${DUPLICATE_SEARCH_QUERY_SUFFIX}`]: filters?.updatedAt?.at(1) && `[lte]${filters.updatedAt.at(1)}`,
      study_module_score_less: filters?.studyModuleScoreLess,
    });

  public getSubmissionDetails = ({ solutionId, submissionId }: IGetSubmissionDetailsPayloadDS) => (
    this._httpClient
      .get<IGetSubmissionDetailsResponseDS>(`${this.prefix}/${solutionId}/submission/${submissionId}/get`)
  );

  public sendSubmission = ({ classTaskId, ...body }: ISendSubmissionPayloadDS) => this._httpClient
    .post<ISendSubmissionResponseDS>(`${this.prefix}/classTask/${classTaskId}/addAttempt`, body);

  public resendSubmission = ({ solutionId, submissionId }: IResendSubmissionPayloadDS) => (
    this._httpClient
      .post<IResendSubmissionResponseDS>(`${this.prefix}/${solutionId}/submission/${submissionId}/resend`)
  );

  public updateSolution = ({ solutionId, ...body }: IUpdateSolutionPayloadDS) => this._httpClient
    .post<IUpdateSolutionResponseDS>(`${this.prefix}/${solutionId}/update`, body);

  public updateSubmission = ({ solutionId, submissionId, ...body }: IUpdateSubmissionPayloadDS) => (
    this._httpClient
      .post<IUpdateSubmissionResponseDS>(`${this.prefix}/${solutionId}/submission/${submissionId}/update`, body)
  );

  public getSolutionSubmissions = ({
    solutionId,
  }: IGetSolutionSubmissionsPayloadDS) => this._httpClient
    .get<IGetSolutionSubmissionsResponseDS>(`${this.prefix}/${solutionId}/attempt/get`);

  public getCheckerRunOnTestDetails = ({
    solutionId,
    checkerRunOnTestId,
  }: IGetCheckerRunOnTestDetailsPayloadDS) => this._httpClient
    .get<IGetCheckerRunOnTestDetailsResponseDS>(`${this.prefix}/${solutionId}/checkerRunOnTest/${checkerRunOnTestId}/get`);

  public getCheckerRunOnTestInput = ({
    solutionId,
    checkerRunOnTestId,
  }: IGetCheckerRunOnTestFullDataPayloadDS) => this._httpClient
    .get<IGetCheckerRunOnTestFullDataResponseDS>(`${this.prefix}/${solutionId}/checkerRunOnTest/${checkerRunOnTestId}/inputData/get`);

  public getCheckerRunOnTestOutput = ({
    solutionId,
    checkerRunOnTestId,
  }: IGetCheckerRunOnTestFullDataPayloadDS) => this._httpClient
    .get<IGetCheckerRunOnTestFullDataResponseDS>(`${this.prefix}/${solutionId}/checkerRunOnTest/${checkerRunOnTestId}/outputData/get`);

  public getCheckerRunOnTestUserOutput = ({
    solutionId,
    checkerRunOnTestId,
  }: IGetCheckerRunOnTestFullDataPayloadDS) => this._httpClient
    .get<IGetCheckerRunOnTestFullDataResponseDS>(`${this.prefix}/${solutionId}/checkerRunOnTest/${checkerRunOnTestId}/userOutputData/get`);

  public getCheckerRunOnTestHistory = ({
    solutionId, checkerRunOnTestId,
  }: IGetCheckerRunOnTestFullDataPayloadDS) => this._httpClient
    .get<IGetCheckerRunOnTestFullDataResponseDS>(`${this.prefix}/${solutionId}/checkerRunOnTest/${checkerRunOnTestId}/history/get`);
}
