import { useState } from 'react';

type TParams = {
  defaultValue?: boolean;
}

export default function useFlag(params?: TParams) {
  const { defaultValue = false } = params ?? {};

  const [flag, setFlag] = useState(defaultValue);

  const enable = () => setFlag(true);
  const disable = () => setFlag(false);
  const toggle = () => setFlag((prev) => !prev);

  return {
    flag, enable, disable, toggle,
  };
}
