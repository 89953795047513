import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useClassRole } from 'application/authentication/useClassRole';
import { useTitle } from 'application/hooks/useTitle';
import { route } from 'application/routes/route';
import { getClassId, isRouteInClass } from 'application/routes/routesInClass';

import Skeleton from 'components/ui/atoms/skeleton/Skeleton';
import Flex from 'components/ui/atoms/flex/Flex';
import Title from 'components/ui/atoms/typography/Title';
import SettingsIcon from 'components/ui/atoms/icons/new/Settings';
import Button from 'components/ui/atoms/button/Button';
import LinkRouter from 'components/ui/atoms/link/LinkRouter';

import { ClassRole, IClass } from 'domain/entities/class';
import { GetClassUseCase } from 'domain/useCases/class/GetClassUseCase';
import { classRepository } from 'domain/repositories/api';

import { useStore } from 'storesMobx/MobxStoreProvider';

import { ns } from 'utils/locales';
import { getClassIllustration } from 'utils/assets/getClassTypeBg';

import classes from './HeaderClass.module.scss';

const getClassUseCase = new GetClassUseCase({
  requestCallback: classRepository.getClass,
});

type TProps = {
  forcedDisplay?: boolean;
}

export default function HeaderClass(props: TProps) {
  const { forcedDisplay } = props;

  const { pathname } = useLocation();

  const { t } = useTranslation();
  const { commonDataStore } = useStore();
  const [classDetails, setClassDetails] = useState<IClass>();

  useTitle(classDetails?.name);

  const shouldDisplay = isRouteInClass(pathname) || forcedDisplay;
  const classId = getClassId(pathname);
  const classIllustration = getClassIllustration(classDetails?.class_type);

  const { classRole } = useClassRole(classId ?? '');

  useEffect(() => {
    if (!shouldDisplay || !classId) return;
    if (classDetails && classDetails.id === classId) return;

    getClassUseCase.fetch({
      payload: { id: classId! },
      onSuccess: ({ class: classDetails }) => {
        setClassDetails(classDetails);
        commonDataStore.setOpenedClass(classDetails ?? null);
      },
    });
  }, [pathname]);

  useEffect(() => () => {
    setClassDetails(undefined);
    commonDataStore.setOpenedClass(null);
  }, [classId]);

  if (!shouldDisplay) return null;

  return (
    <div className={classes.header_class} style={{ backgroundImage: `url(${classDetails && classIllustration})` }}>
      {classRole === ClassRole.TEACHER && (
        <LinkRouter to={route.classManager(classId)} className={classes.button_class_management}>
          <Button icon={<SettingsIcon />}>{t(`${ns.buttons}:classManagement`)}</Button>
        </LinkRouter>
      )}
      <Flex vertical gap={12} className={classes.titles_container}>
        {classDetails ? (
          <>
            <Title level={1}>{classDetails.name}</Title>
            <Title level={3}>{classDetails.subdivision.name}</Title>
          </>
        ) : (
          <Skeleton paragraph={{ rows: 1 }} />
        )}
      </Flex>
    </div>
  );
}
