import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthLogo from 'components/ui/atoms/icons/auth/AuthLogo';

import { ns } from 'utils/locales';

import classes from './AuthForm.module.scss';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import FooterButtons from './FooterButtons';

type TProps = {
  isRegister: boolean;
}

export default function AuthForm(props: TProps) {
  const { isRegister } = props;

  const { t } = useTranslation();

  return (
    <div className={classes.auth_form}>
      <AuthLogo className={classes.auth_logo} />
      <h2 className={classes.auth_title}>{t(`${ns.auth}:welcome`)}</h2>
      {isRegister ? <RegisterForm /> : <LoginForm />}
      <FooterButtons />
    </div>
  );
}
