import { createContext, ReactNode } from 'react';

export type TConfirmationModalContextValue = {
  content: ReactNode;
  title?: string;
  onConfirm?: () => Promise<void>;
}

export type TConfirmationModalContext = {
  openConfirmationModal: (value: TConfirmationModalContextValue) => void;
}

export const confirmationModalContextDefaultValue: TConfirmationModalContextValue = {
  content: null,
};

export const ConfirmationModalContext = createContext<
  TConfirmationModalContext
>({
  openConfirmationModal: () => {},
});
