import React from 'react';
import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';

import EntityDrawerSubjectProxyContent from 'components/ui/organisms/entityDrawer/entities/subjectProxy/content/EntityDrawerSubjectProxyContent';
import { EntityDrawerType } from 'components/ui/organisms/entityDrawer/enums';
import WithLoader from 'components/ui/molecules/withLoader/WithLoader';
import { drawerPlacement, drawerWidth } from 'components/ui/organisms/entityDrawer/constants';
import { useEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel/context';

const ContentWithLoader = WithLoader(EntityDrawerSubjectProxyContent, true);

const EntityDrawerSubjectProxy: React.FC = () => {
  const { isOpened, close, subjectProxyViewModel } = useEntityDrawerViewModel();

  const { pageStatus, title } = subjectProxyViewModel;

  return (
    <Drawer
      title={title}
      placement={drawerPlacement}
      width={drawerWidth}
      open={isOpened(EntityDrawerType.SUBJECT_PROXY)}
      onClose={() => close(EntityDrawerType.SUBJECT_PROXY)}
      destroyOnClose
    >
      <ContentWithLoader
        isLoading={pageStatus.isLoading}
        isFailed={pageStatus.isFailed}
        errorCode={pageStatus.errorCode}
      />
    </Drawer>
  );
};

export default observer(EntityDrawerSubjectProxy);
