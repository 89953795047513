import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function AvatarEmpty(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M24 3.05176e-05C10.7665 3.05176e-05 0 10.7666 0 24C0 37.2335 10.7665 48 24 48C37.2335 48 48 37.2335 48 24C48 10.7666 37.2335 3.05176e-05 24 3.05176e-05ZM24.2308 11.077C25.8739 11.077 27.4801 11.5642 28.8463 12.4771C30.2125 13.3899 31.2773 14.6874 31.9061 16.2054C32.5349 17.7235 32.6994 19.3939 32.3788 21.0054C32.0583 22.6169 31.267 24.0972 30.1052 25.2591C28.9433 26.4209 27.4631 27.2122 25.8515 27.5327C24.24 27.8533 22.5696 27.6887 21.0516 27.06C19.5335 26.4312 18.236 25.3663 17.3232 24.0002C16.4103 22.634 15.9231 21.0278 15.9231 19.3846C15.9231 17.1813 16.7983 15.0682 18.3563 13.5102C19.9143 11.9522 22.0274 11.077 24.2308 11.077ZM24 44.3077C21.199 44.3089 18.4282 43.7291 15.8626 42.605C13.2971 41.4809 10.9925 39.8369 9.09461 37.777C10.1146 32.4946 19.2762 31.3846 24 31.3846C28.7238 31.3846 37.8854 32.4946 38.9054 37.7758C37.0077 39.8362 34.7032 41.4805 32.1376 42.6048C29.5721 43.7291 26.8011 44.3089 24 44.3077Z"
        fill="#6BB2EE"
      />
    </svg>
  );
}
