import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IGetLanguagesResponse,
  IGetModuleDetailsProxyPayload,
  IGetModuleDetailsProxyResponse,
  IGetModulesProxyPayload,
  IGetModulesProxyResponse,
  IGetSectionDetailsProxyPayload,
  IGetSectionDetailsProxyResponse,
  IGetSectionsProxyPayload,
  IGetSectionsProxyResponse, IGetSubjectDetailsProxyPayload,
  IGetSubjectDetailsProxyResponse, IGetSubjectsProxyResponse,
  IGetTaskProxyPayload,
  IGetTasksProxyPayload,
  IGetTasksProxyResponse,
  IProxyRepository,
} from 'domain/repositories/api/proxy/IProxyRepository';

import { SearchQueryOperators } from 'utils/enums/SearchQueryOperators';

export class ProxyRepository implements IProxyRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/proxy`;

  public constructor(private _httpClient: IHttpClient) {}

  public getLanguages = () => this._httpClient.get<IGetLanguagesResponse>(`${this.prefix}/language/get`, undefined, true);

  public getSubjectsProxy = () => this._httpClient.get<IGetSubjectsProxyResponse>(`${this.prefix}/subject/get`);

  public getSubjectDetailsProxy = ({ id }: IGetSubjectDetailsProxyPayload) => this._httpClient
    .get<IGetSubjectDetailsProxyResponse>(`${this.prefix}/subject/${id}/details/get`);

  public getModulesProxy = async ({ filters, ...pagination }: IGetModulesProxyPayload) => (
    this._httpClient.get<IGetModulesProxyResponse>(`${this.prefix}/studyModule/get`, {
      ...pagination,
      id: filters?.id && `${SearchQueryOperators.EQUAL}${filters.id}`,
    })
  );

  public getModuleDetailsProxy = ({ id }: IGetModuleDetailsProxyPayload) => this._httpClient
    .get<IGetModuleDetailsProxyResponse>(`${this.prefix}/studyModule/${id}/details/get`);

  public getSectionsProxy = async ({ filters, ...pagination }: IGetSectionsProxyPayload) => (
    this._httpClient.get<IGetSectionsProxyResponse>(`${this.prefix}/section/get`, {
      ...pagination,
      id: filters?.id && `${SearchQueryOperators.EQUAL}${filters.id}`,
    })
  );

  public getSectionDetailsProxy = ({ id }: IGetSectionDetailsProxyPayload) => this._httpClient
    .get<IGetSectionDetailsProxyResponse>(`${this.prefix}/section/${id}/details/get`);

  public getTasksProxy = async ({ filters, ...pagination }: IGetTasksProxyPayload) => (
    this._httpClient.get<IGetTasksProxyResponse>(`${this.prefix}/task/get`, {
      ...pagination,
      id: filters?.id && `${SearchQueryOperators.EQUAL}${filters.id}`,
    })
  );

  public getTaskProxy = async ({ id }: IGetTaskProxyPayload) => {
    const { tasks, ...response } = await this.getTasksProxy({ filters: { id } });

    if (!tasks.length) return { ...response, task: undefined };
    return { ...response, task: tasks[0] };
  };
}
