import React from 'react';
import { ColumnsType } from 'antd/es/table';

import { EditableTableAbility } from 'components/ui/organisms/editableTable/enums/editableTableAbility';
import Cell from 'components/ui/organisms/editableTable/ui/Cell';
import TitleColumn from 'components/ui/organisms/editableTable/ui/TitleColumn';
import { ADD_COLUMN_NAME } from 'components/ui/organisms/editableTable/constants';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';

export const useColumns = (
  id: string,
  columns: string[],
  abilities: EditableTableAbility[],
  onDeleteRow: (rowIdx: number) => void,
  onAddColumn: () => void,
  onEditColumn: (columnIdx: number, newValue: string) => void,
  onDeleteColumn: (columnIdx: number) => void,
  onEditCell: (rowIdx: number, columnIdx: number, newValue: string) => void,
  columnTypes?: SqlColumnType[],
  editColumnType?: (columnIdx: number, columnType: SqlColumnType) => void,
) => {
  if (abilities.includes(EditableTableAbility.ADD_COLUMN)
    || abilities.includes(EditableTableAbility.DELETE_ROW)) {
    columns = [...columns, ADD_COLUMN_NAME];
  }

  const tableColumns: ColumnsType = columns.map((column, columnIdx) => ({
    id: column,
    width: column === ADD_COLUMN_NAME ? '0px' : undefined,
    dataIndex: column,
    className: column !== ADD_COLUMN_NAME ? 'sql-table-data-cell' : 'sql-table-add-column-cell',
    fixed: column === ADD_COLUMN_NAME ? 'right' : undefined,
    render: (value, row, rowIdx) => {
      if (rowIdx === 0) {
        return (
          <TitleColumn
            id={id}
            column={column}
            abilities={abilities}
            columnIdx={columnIdx}
            onAddColumn={onAddColumn}
            onEditColumn={onEditColumn}
            onDeleteColumn={onDeleteColumn}
            sqlColumnType={columnTypes && columnTypes[columnIdx]}
            editColumnType={editColumnType}
          />
        );
      }

      return (
        <Cell
          value={value}
          column={column}
          abilities={abilities}
          rowIdx={rowIdx}
          columnIdx={columnIdx}
          onDeleteRow={onDeleteRow}
          onEditCell={onEditCell}
        />
      );
    },
  }));

  return { tableColumns };
};
