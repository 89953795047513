import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function ArrowLeftAltIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M22 12L2 12M2 12L8 18M2 12L8 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
