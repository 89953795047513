import { Route } from 'application/routes/types';

import { ClassRole } from 'domain/entities/class';

export const routesForbiddenRules: Record<keyof Route, ClassRole[]> = {
  base: [],
  classManager: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  classManagerNew: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  classManagerStructureEditor: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  moduleManager: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  sectionManager: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskManager: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskLinkedTestGroups: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskLinkedReferenceSolutions: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskLinkedClasses: [ClassRole.STUDENT, ClassRole.ASSISTANT],

  sectionManagerSimplified: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskManagerSimplified: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskLinkedTestGroupsSimplified: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskLinkedReferenceSolutionsSimplified: [ClassRole.STUDENT, ClassRole.ASSISTANT],
  taskLinkedClassesSimplified: [ClassRole.STUDENT, ClassRole.ASSISTANT],

  refSolution: [ClassRole.STUDENT, ClassRole.ASSISTANT],

  posts: [],
  themes: [],
  tasks: [],
  task: [],
  sendSolution: [],
  queue: [],
  postModeration: [],
  submission: [],
  users: [],
  rating: [],

  userClassResults: [ClassRole.STUDENT],

  solutionsHistory: [],

  joinClass: [],

  user: [],
  settings: [],
  tsuAuth: [],

  sqlTest: [],
  adminPanel: [],

  support: [],
  about: [],
};
