import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import SendPlaneIcon from 'components/ui/atoms/icons/new/SendPlane';
import Button from 'components/ui/atoms/button/Button';
import FormInput from 'components/ui/molecules/form/FormInput';
import FormSelect from 'components/ui/molecules/form/FormSelect';
import SearchIcon from 'components/ui/atoms/icons/new/Search';
import Form from 'components/ui/molecules/form/Form';
import useReportTagsOptions from 'components/ui/atoms/select/hooks/useReportTagsOptions';
import Flex from 'components/ui/atoms/flex/Flex';
import Card from 'components/ui/atoms/card/Card';
import Title from 'components/ui/atoms/typography/Title';

import { ns } from 'utils/locales';

import classes from './SupportPage.module.scss';
import useReportForm from './hooks/useReportForm';

function SupportPage() {
  const { options } = useReportTagsOptions();
  const { methods, loading, handleSubmit } = useReportForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (!options || !options.length) return;

    methods.setValue('tagId', options[0].value);
  }, [options]);

  return (
    <>
      <div className={classes.illustration}>
        <Title level={2} className={classes.illustration__title}>{t(`${ns.report}:reportTitle`)}</Title>
      </div>
      <div className={classes.content_layout}>
        <Form methods={methods} onSubmit={handleSubmit}>
          <Card className={classes.select_card}>
            <Flex vertical gap={16}>
              <Title level={3}>{t(`${ns.report}:chooseCategory`)}</Title>
              <FormSelect name="tagId" options={options} suffixIcon={<SearchIcon />} />
            </Flex>
          </Card>
          <Card>
            <Flex vertical gap={16}>
              <Title level={3}>{t(`${ns.report}:describeProblem`)}</Title>
              <FormInput name="description" type="textarea" rows={5} placeholder={t(`${ns.placeholders}:startTypingText`)} />
              <Button variant="accent" icon={<SendPlaneIcon />} htmlType="submit" loading={loading} className={classes.send_message_btn}>
                {t(`${ns.buttons}:sendMessage`)}
              </Button>
            </Flex>
          </Card>
        </Form>
      </div>
    </>
  );
}

export default observer(SupportPage);
