import React from 'react';
import { useController, useFormContext, useFormState } from 'react-hook-form';

import Select, { TSelectProps } from 'components/ui/atoms/select/Select';
import Paragraph from 'components/ui/atoms/typography/Paragraph';

import classes from './Form.module.scss';

type TProps = {
  name: string;
  label?: string;
  classNameFormItem?: string;
} & TSelectProps;

export default function FormSelect(props: TProps) {
  const {
    name, label, classNameFormItem, id, onChange, ...restProps
  } = props;

  const { control } = useFormContext();
  const formState = useFormState();
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: '',
  });

  const handleChange = (newValue: string) => {
    field.onChange(newValue);
    onChange?.(newValue);
  };

  return (
    <div className={classNameFormItem}>
      {label && (
        <label htmlFor={id}>
          <Paragraph weight="bold" className={classes.label}>{label}</Paragraph>
        </label>
      )}
      <Select
        name={name}
        value={field.value}
        id={id}
        // @ts-ignore
        onChange={handleChange}
        error={!!fieldState?.error}
        errorMessage={formState.errors[name]?.message?.toString()}
        {...restProps}
      />
    </div>
  );
}
