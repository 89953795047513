import { Permission } from 'domain/entities/permission';

import { useStore } from 'storesMobx/MobxStoreProvider';

export const useIsPermitted = (permission: Permission) => {
  const { userStore } = useStore();

  if (userStore.isSuperAdmin) return true;

  return !!userStore.platformPermissions.find((p) => p.permission === permission)
    || !!userStore.globalPermissions.find((p) => p.permission === permission);
};
