import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  ICreateSubdivisionPayload,
  ICreateSubdivisionResponse,
  IDeleteSubdivisionPayload,
  IDeleteSubdivisionResponse,
  IGetSubdivisionStudyGroupsPayload,
  IGetSubdivisionStudyGroupsResponse,
  ISubdivisionRepository,
  IUpdateSubdivisionPayload,
  IUpdateSubdivisionResponse,
} from 'domain/repositories/api/subdivision/ISubdivisionRepository';

export class SubdivisionRepository implements ISubdivisionRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/subdivision`;

  public constructor(private _httpClient: IHttpClient) {}

  public createSubdivision = (
    { universityId, ...body }: ICreateSubdivisionPayload,
  ) => (this._httpClient
    .post<ICreateSubdivisionResponse>(`${this.prefix}/university/${universityId}/create`, body));

  public updateSubdivision = ({ subdivisionId, ...body }: IUpdateSubdivisionPayload) => (
    this._httpClient
      .post<IUpdateSubdivisionResponse>(`${this.prefix}/${subdivisionId}/update`, body)
  );

  public deleteSubdivision = ({ id }: IDeleteSubdivisionPayload) => this._httpClient
    .post<IDeleteSubdivisionResponse>(`${this.prefix}/${id}/delete`);

  public getSubdivisionStudyGroups = ({
    subdivisionId, ...pagination
  }: IGetSubdivisionStudyGroupsPayload) => (
    this._httpClient
      .get<IGetSubdivisionStudyGroupsResponse>(`${this.prefix}/${subdivisionId}/studyGroup/get`, {
        ...pagination,
      })
  );
}
