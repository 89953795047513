import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';

import classes from './Card.module.scss';

type TProps = PropsWithChildren & {
  className?: string;
};

function Card(props: TProps, ref: ForwardedRef<HTMLDivElement>) {
  const { className, children } = props;

  return (
    <div className={clsx(classes.card, className)} ref={ref}>
      {children}
    </div>
  );
}

export default forwardRef(Card);
