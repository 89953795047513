import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  ICreateSectionPayloadDS,
  ICreateSectionResponseDS,
  IDeleteSectionPayloadDS, IDeleteSectionResponseDS, IForkSectionPayloadDS, IForkSectionResponseDS,
  IGetSectionPayloadDS,
  IGetSectionResponseDS,
  IGetSectionSolutionsPayloadDS,
  IGetSectionSolutionsResponseDS, IGetSectionStatisticsPayloadDS, IGetSectionStatisticsResponseDS,
  IGetSectionTasksPayloadDS,
  IGetSectionTasksResponseDS,
  IGetSectionUserSolutionsPayloadDS,
  IGetSectionUserSolutionsResponseDS,
  ISectionDataSource,
  IUpdateSectionPayloadDS,
} from 'domain/repositories/api/section/dataSource/ISectionsDataSource';

export class SectionsDataSource implements ISectionDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/classSection`;

  public constructor(private _httpClient: IHttpClient) {}

  public getSection = ({ id }: IGetSectionPayloadDS) => this._httpClient.get<IGetSectionResponseDS>(`${this.prefix}/${id}/get`);

  public createSection = ({ moduleId, ...body }: ICreateSectionPayloadDS) => this._httpClient
    .post<ICreateSectionResponseDS>(`${this.prefix}/classStudyModule/${moduleId}/create`, body);

  public updateSection = ({ id, ...body }: IUpdateSectionPayloadDS) => this._httpClient
    .post<IGetSectionResponseDS>(`${this.prefix}/${id}/update`, body);

  public deleteSection = ({ id }: IDeleteSectionPayloadDS) => this._httpClient
    .post<IDeleteSectionResponseDS>(`${this.prefix}/${id}/delete`);

  public forkSection = ({ sectionId, moduleId, iso }: IForkSectionPayloadDS) => this._httpClient
    .post<IForkSectionResponseDS>(`${this.prefix}/fork/${sectionId}/${moduleId}/${iso}`);

  public getStatistics = ({ id }: IGetSectionStatisticsPayloadDS) => this._httpClient
    .get<IGetSectionStatisticsResponseDS>(`${this.prefix}/getStatistics/sectionId/${id}`);

  public getTasks = ({ sectionId, ...pagination }: IGetSectionTasksPayloadDS) => this._httpClient
    .get<IGetSectionTasksResponseDS>(`${this.prefix}/${sectionId}/tasks/get`, {
      ...pagination,
    });

  public getSolutions = (
    { sectionId, ...pagination }: IGetSectionSolutionsPayloadDS,
  ) => this._httpClient
    .get<IGetSectionSolutionsResponseDS>(`${this.prefix}/${sectionId}/results/get`, {
      ...pagination,
    });

  public getUserSolutions = (
    { sectionId, userId, ...pagination }: IGetSectionUserSolutionsPayloadDS,
  ) => this._httpClient
    .get<IGetSectionUserSolutionsResponseDS>(`${this.prefix}/${sectionId}/userResults/${userId}/get`, {
      ...pagination,
    });
}
