import React, { useEffect, useRef } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

import { route } from 'application/routes/route';

import BackIcon from 'components/ui/atoms/icons/new/Back';
import HomeIcon from 'components/ui/atoms/icons/new/Home';
import Flex from 'components/ui/atoms/flex/Flex';
import Center from 'components/ui/atoms/center/Center';
import Button from 'components/ui/atoms/button/Button';

import { ns } from 'utils/locales';

import classes from './FallbackError.module.scss';

type TProps = FallbackProps;

export default function FallbackError(props: TProps) {
  const { resetErrorBoundary } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const errorLocation = useRef(location.pathname);
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname]);

  const onBack = () => {
    navigate(-1);
    resetErrorBoundary();
  };

  const onMainPage = () => {
    navigate(route.base);
    resetErrorBoundary();
  };

  return (
    <>
      <Center height="100dvh">
        <Result
          status="500"
          title={t(`${ns.errors}:oops`)}
          subTitle={t(`${ns.errors}:unexpectedError`)}
          extra={(
            <Flex justify="center" gap={12}>
              <Button type="primary" icon={<BackIcon />} className={classes.button} onClick={onBack}>{t(`${ns.buttons}:backLink`)}</Button>
              <Button type="primary" icon={<HomeIcon />} className={classes.button} onClick={onMainPage}>{t(`${ns.buttons}:homePage`)}</Button>
            </Flex>
          )}
        />
      </Center>
    </>
  );
}
