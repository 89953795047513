import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { route } from 'application/routes/route';

import LinkRouter from 'components/ui/atoms/link/LinkRouter';
import { useSidebarViewModel } from 'components/ui/organisms/sidebar/viewModel/context';
import LogoTextDark from 'components/ui/atoms/icons/logoNew/LogoTextDark';
import LogoSpinesDark from 'components/ui/atoms/icons/logoNew/LogoSpinesDark';
import Fade from 'components/animations/fade/Fade';
import LogoText from 'components/ui/atoms/icons/logoNew/LogoText';
import LogoSpines from 'components/ui/atoms/icons/logoNew/LogoSpines';
import classes from 'components/ui/organisms/sidebar/ui/logo/Logo.module.scss';

import { useStore } from 'storesMobx/MobxStoreProvider';

function Logo() {
  const { isSidebarCollapsed } = useSidebarViewModel();
  const { themeStore } = useStore();

  const LogoSpinesThemed = themeStore.isDarkMode ? LogoSpinesDark : LogoSpines;
  const LogoTextThemed = themeStore.isDarkMode ? LogoTextDark : LogoText;

  return (
    <div className={classes.logo_wrapper}>
      <LinkRouter to={route.base}>
        <LogoSpinesThemed
          className={clsx(classes.logo_spines, {
            [classes.logo_spines_is_sidebar_collapsed]: isSidebarCollapsed,
          })}
        />
      </LinkRouter>
      <LinkRouter to={route.base}>
        <Fade isVisible={!isSidebarCollapsed}>
          <LogoTextThemed className={classes.logo_text} />
        </Fade>
      </LinkRouter>
    </div>
  );
}

export default observer(Logo);
