interface ITranslation {
  iso: string;
}

export const getPrioritizeTranslation = (
  translations: ITranslation[],
): string | undefined => {
  const localizations = translations.map((t) => t.iso);

  return localizations.includes('ru')
    ? 'ru' : localizations.includes('en')
      ? 'en' : localizations.length
        ? localizations[0] : undefined;
};
