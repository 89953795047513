import {
  ICreateCommentPayload, IDeleteCommentPayload,
  IGetPrivateCommentsPayload,
  IGetPublicCommentsPayload,
  ISolutionCommentRepository, IUpdateCommentPayload,
} from 'domain/repositories/api/solutionComment/ISolutionCommentRepository';
import { ISolutionCommentDataSource } from 'domain/repositories/api/solutionComment/datasource/ISolutionCommentDataSource';

export class SolutionCommentRepository implements ISolutionCommentRepository {
  public constructor(public _ds: ISolutionCommentDataSource) {}

  public getPublicComments = (p: IGetPublicCommentsPayload) => this._ds.getPublicComments(p);

  public getPrivateComments = (p: IGetPrivateCommentsPayload) => this._ds.getPrivateComments(p);

  public createComment = (p: ICreateCommentPayload) => this._ds.createComment(p);

  public updateComment = (p: IUpdateCommentPayload) => this._ds.updateComment(p);

  public deleteComment = (p: IDeleteCommentPayload) => this._ds.deleteComment(p);
}
