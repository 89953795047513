import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function QueueIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 11.5C6 9.61438 6 8.67157 6.58579 8.08579C7.17157 7.5 8.11438 7.5 10 7.5H16C17.8856 7.5 18.8284 7.5 19.4142 8.08579C20 8.67157 20 9.61438 20 11.5V12.5C20 14.3856 20 15.3284 19.4142 15.9142C18.8284 16.5 17.8856 16.5 16 16.5H10C8.11438 16.5 7.17157 16.5 6.58579 15.9142C6 15.3284 6 14.3856 6 12.5V11.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M20 2V2.5C20 3.88071 18.8807 5 17.5 5H8.5C7.11929 5 6 3.88071 6 2.5V2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 22V21.5C20 20.1193 18.8807 19 17.5 19H8.5C7.11929 19 6 20.1193 6 21.5V22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
