import { ClassRole } from 'domain/entities/class';

import { useStore } from 'storesMobx/MobxStoreProvider';

export const useClassRole = (classId: string) => {
  const { userStore } = useStore();

  const classRole = userStore.getRoleInClass(classId);

  return {
    classRole,
    isStudent: classRole === ClassRole.STUDENT,
    isTeacher: classRole === ClassRole.TEACHER,
  };
};
