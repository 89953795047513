import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ClassContext } from 'providers/class/classContext';
import { axiosCacheInstance } from 'data/axios/axiosCacheInstance';
import { GET_CLASS_ROLES_CACHE_KEY } from 'data/axios/constants';
import { observer } from 'mobx-react-lite';

import { route } from 'application/routes/route';
import { getClassId } from 'application/routes/routesInClass';

import Center from 'components/ui/atoms/center/Center';
import Loader from 'components/ui/atoms/loader/Loader';

import { classRepository } from 'domain/repositories/api';
import { GetClassUseCase } from 'domain/useCases/class/GetClassUseCase';
import { IClass } from 'domain/entities/class';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';
import { useStore } from 'storesMobx/MobxStoreProvider';

const getClassUseCase = new GetClassUseCase({
  requestCallback: classRepository.getClass,
});

const loadStatus = new LoadStatus();

function ClassProvider() {
  const { pathname } = useLocation();
  const { userStore } = useStore();
  const navigate = useNavigate();

  const [classDetails, setClassDetails] = useState<IClass>();

  const classId = getClassId(pathname);

  useEffect(() => {
    if (classDetails && classDetails.id === classId) return;

    loadStatus.onStartRequest();

    getClassUseCase.fetch({
      payload: { id: classId! },
      onSuccess: async ({ class: classDetails }) => {
        if (classDetails) {
          const isClassPermissionExists = !!userStore
            .classRoles
            .find(({ classId }) => classId === classDetails.id);

          if (!isClassPermissionExists) {
            axiosCacheInstance.storage.remove(GET_CLASS_ROLES_CACHE_KEY);

            await userStore.fetch();
          }

          setClassDetails(classDetails);
          loadStatus.onEndRequest();

          return;
        }

        navigate(route.base);
      },
      onError: () => {
        loadStatus.onEndRequest(false);
      },
    });
  }, [pathname]);

  useEffect(() => () => {
    setClassDetails(undefined);
  }, [classId]);

  const classContextValue = useMemo(() => ({
    classDetails: classDetails!,
  }), [classDetails]);

  if (loadStatus.isLoading) {
    return (
      <Center height="100dvh">
        <Loader />
      </Center>
    );
  }

  return (
    <ClassContext.Provider value={classContextValue}>
      <Outlet />
    </ClassContext.Provider>
  );
}

export default observer(ClassProvider);
