import React from 'react';
import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { route } from 'application/routes/route';
import { useConfirmModal } from 'application/confirmModal/useConfirmModal';

import IconButton from 'components/ui/atoms/iconButton/IconButton';
import DragIcon from 'components/ui/atoms/icons/DragIcon';
import DeleteIcon from 'components/ui/atoms/icons/DeleteIcon';
import Link from 'components/ui/atoms/link/Link';
import {
  HeaderTextWrapper, HeaderWrapper, IconsWrapper, PercentageTitle, PointsTitle,
} from 'components/ui/organisms/classStructure/styled';

import { ns } from 'utils/locales';

interface ModuleHeaderProps {
  id: string;
  name: string;
  points?: number;
  percentage?: number;
  onDelete?: (id: string) => void;
  draggableProps?: DraggableProvidedDragHandleProps | null | undefined;
}

const ModuleHeader: React.FC<ModuleHeaderProps> = ({
  id,
  name,
  points,
  percentage,
  onDelete,
  draggableProps,
}) => {
  const { classId } = useParams();
  const { t } = useTranslation();

  const { showConfirm } = useConfirmModal({
    title: t(`${ns.confirmations}:deleteModule`, { name }),
    cbOnOk: () => onDelete?.(id),
  });

  return (
    <HeaderWrapper headerType="module">
      <HeaderTextWrapper>
        <Link to={route.moduleManager(classId, id)}>
          <Title level={2}>{name}</Title>
        </Link>
        {
          (onDelete || draggableProps) && (
            <IconsWrapper>
              {draggableProps && (
                <IconButton icon={<DragIcon />} {...draggableProps} />
              )}
              {onDelete && (
                <IconButton icon={<DeleteIcon />} onClick={() => showConfirm()} />
              )}
            </IconsWrapper>
          )
        }
      </HeaderTextWrapper>

      {points || points === 0 ? <PointsTitle level={2}>{points}</PointsTitle> : null}
      {percentage && <PercentageTitle level={2}>{`(${percentage}%)`}</PercentageTitle>}
    </HeaderWrapper>
  );
};

export default ModuleHeader;
