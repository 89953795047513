import { IPostDataSource } from 'domain/repositories/api/post/dataSource/IPostDataSource';
import {
  ICreatePostPayload, IDeletePostPayload, IEditPostPayload,
  IGetPostPayload, IGetPostResponse,
  IGetPostsPayload,
  IPostRepository,
} from 'domain/repositories/api/post/IPostRepository';

export class PostRepository implements IPostRepository {
  public constructor(private _ds: IPostDataSource) {}

  public getPosts = (payload: IGetPostsPayload) => this._ds.getPosts(payload);

  public getPost = async ({ postId, classId }: IGetPostPayload) : Promise<IGetPostResponse> => {
    const { class_posts, ...response } = await this._ds.getPosts({
      classId,
      filters: { id: postId },
    });

    return { ...response, classPost: class_posts.length ? class_posts[0] : null };
  };

  public createPost = (payload: ICreatePostPayload) => this._ds.createPost(payload);

  public editPost = (payload: IEditPostPayload) => this._ds.editPost(payload);

  public deletePost = (payload: IDeletePostPayload) => this._ds.deletePost(payload);
}
