import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import ChevronLeftIcon from 'components/ui/atoms/icons/new/ChevronLeft';
import Paragraph from 'components/ui/atoms/typography/Paragraph';
import Tooltip from 'components/ui/atoms/tooltip/Tooltip';
import LanguageIcon from 'components/ui/atoms/icons/new/Language';
import MoonIcon from 'components/ui/atoms/icons/new/Moon';
import SunIcon from 'components/ui/atoms/icons/new/Sun';
import LinkSidebar from 'components/ui/organisms/sidebar/ui/linkSidebar/LinkSidebar';
import Flex from 'components/ui/atoms/flex/Flex';

import { useStore } from 'storesMobx/MobxStoreProvider';

import { LocaleType } from 'utils/locales';

import classes from './TogglesContainer.module.scss';

function TogglesContainer() {
  const { themeStore } = useStore();
  const { i18n } = useTranslation();

  const toggleTheme = () => themeStore.toggleTheme();

  const toggleLanguage = async () => {
    if (i18n.language === LocaleType.RU) {
      await i18n.changeLanguage(LocaleType.EN);
    } else {
      await i18n.changeLanguage(LocaleType.RU);
    }
  };

  const themeTextLabel = themeStore.isDarkMode ? 'Темная тема' : 'Светлая тема';
  const themeIcon = themeStore.isDarkMode ? <MoonIcon /> : <SunIcon />;

  const languageTextLabel = i18n.language === LocaleType.EN ? 'English' : 'Русский';

  const renderLanguageTooltipContent = () => {
    const title = i18n.language === LocaleType.RU ? 'English' : 'Русский';
    const subtitle = i18n.language === LocaleType.RU ? 'Английский' : 'Russian';

    return (
      <Flex
        vertical
        gap={4}
        className={classes.tooltip__inner_content_wrapper}
        onClick={toggleLanguage}
      >
        <Paragraph>{title}</Paragraph>
        <Paragraph variant="footnote-large" className={classes.tooltip__footnote}>{subtitle}</Paragraph>
      </Flex>
    );
  };

  return (
    <Flex vertical gap={4} className={classes.toggles_container}>
      <LinkSidebar link={{ text: themeTextLabel, icon: themeIcon, onClick: toggleTheme }} />
      <Tooltip title={renderLanguageTooltipContent()} placement="right" rootClassName={classes.tooltip}>
        <LinkSidebar link={{
          text: languageTextLabel,
          icon: <LanguageIcon />,
          onClick: toggleLanguage,
        }}
        >
          <ChevronLeftIcon className={classes.chevron_right} />
        </LinkSidebar>
      </Tooltip>
    </Flex>
  );
}

export default observer(TogglesContainer);
