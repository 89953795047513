import React, { useMemo } from 'react';

import { useNotifications } from 'application/notification/useNotifications';

import { RegisterUseCase } from 'domain/useCases/auth/RegisterUseCase';
import { authRepository } from 'domain/repositories/api';
import { LoginUseCase } from 'domain/useCases/auth/LoginUseCase';
import { tokenRepository } from 'domain/repositories/other/TokenRepository';

import AuthPageView from 'pages/auth/AuthPageView';
import { ViewModelContext } from 'pages/auth/viewModel/context';
import { AuthPageViewModel } from 'pages/auth/viewModel';

import { useStore } from 'storesMobx/MobxStoreProvider';

const AuthPageProvider: React.FC = () => {
  const { notifyError, notifySuccess } = useNotifications();

  const { userStore, commonDataStore } = useStore();

  const loginUseCase = new LoginUseCase({
    requestCallback: authRepository.login,
    notifyError,
    userStore,
    commonDataStore,
    tokenRepository,
  });

  const registerUseCase = new RegisterUseCase({
    requestCallback: authRepository.register,
    shouldThrowException: true,
    notifyError,
    notifySuccess,
  });

  const viewModel = useMemo(
    () => new AuthPageViewModel(loginUseCase, registerUseCase),
    [],
  );

  return (
    <ViewModelContext.Provider value={viewModel}>
      <AuthPageView />
    </ViewModelContext.Provider>
  );
};

export default AuthPageProvider;
