import React from 'react';
import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';

import { EntityDrawerType } from 'components/ui/organisms/entityDrawer/enums';
import EntityDrawerModuleProxyContent from 'components/ui/organisms/entityDrawer/entities/moduleProxy/content/EntityDrawerModuleProxyContent';
import WithLoader from 'components/ui/molecules/withLoader/WithLoader';
import { drawerPlacement, drawerWidth } from 'components/ui/organisms/entityDrawer/constants';
import { useEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel/context';

const ContentWithLoader = WithLoader(EntityDrawerModuleProxyContent, true);

const EntityDrawerModuleProxy: React.FC = () => {
  const { isOpened, close, moduleProxyViewModel } = useEntityDrawerViewModel();

  const { pageStatus, title } = moduleProxyViewModel;

  return (
    <Drawer
      title={title}
      placement={drawerPlacement}
      width={drawerWidth}
      open={isOpened(EntityDrawerType.MODULE_PROXY)}
      onClose={() => close(EntityDrawerType.MODULE_PROXY)}
      destroyOnClose
    >
      <ContentWithLoader
        isLoading={pageStatus.isLoading}
        isFailed={pageStatus.isFailed}
        errorCode={pageStatus.errorCode}
      />
    </Drawer>
  );
};

export default observer(EntityDrawerModuleProxy);
