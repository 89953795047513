import React from 'react';
import { Modal as AntModal } from 'antd';
import { observer } from 'mobx-react-lite';

import { useModalViewModel } from 'components/ui/organisms/modal/context/ModalProvider';

const Modal: React.FC = () => {
  const vm = useModalViewModel();

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    vm.props?.onCancel?.(e);

    vm.close();
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    vm.props?.onOk?.(e);

    vm.close();
  };

  return (
    <AntModal
      {...vm.props}
      open={vm.isOpened}
      onCancel={onCancel}
      onOk={onOk}
      destroyOnClose
      footer={false}
      maskClosable={false}
    >
      {vm.props?.content}
    </AntModal>
  );
};

export default observer(Modal);
