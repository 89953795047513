import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import {
  getClassId, getNavbarItemKeyByRoute, isRouteInClass, NavbarItemKey, routesInClass,
} from 'application/routes/routesInClass';

import { useItems } from 'components/ui/organisms/navbarClass/hooks/useItems';

import classes from './NavbarClass.module.scss';

const NavbarClass: React.FC = () => {
  const { pathname } = useLocation();
  const items = useItems();
  const navigate = useNavigate();

  const id = getClassId(pathname);
  const activeKey = getNavbarItemKeyByRoute(pathname);

  if (!isRouteInClass(pathname) || !id) return null;

  return (
    <div className={classes.navbar_container}>
      {items.map((item, index) => (
        <button
          type="button"
          className={clsx(classes.navbar_item, {
            [classes.navbar_item_is_active]: activeKey === item.key,
          })}
          key={item.key}
          onClick={() => navigate(
            routesInClass[item.key as NavbarItemKey]?.(id) ?? '',
          )}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default NavbarClass;
