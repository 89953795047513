import { createContext, useContext } from 'react';

import { AuthPageViewModel } from 'pages/auth/viewModel';

export const ViewModelContext = createContext<
  AuthPageViewModel | undefined
>(undefined);

export const useAuthPageViewModel = () => {
  const vm = useContext(ViewModelContext);

  if (!vm) throw new Error();

  return vm;
};
