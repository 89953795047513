import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function PostModerationIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21 6L4 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M11 11L4 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M11 16H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M15 13.5L17.1 16L21 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
