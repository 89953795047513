import { useSearchParams } from 'react-router-dom';

import { AuthPageMode, MODE_SEARCH_PARAM } from 'pages/auth/constants';

export default function useRegisterMode() {
  const [searchParams, setSearchParams] = useSearchParams();

  const isRegisterMode = searchParams.get(MODE_SEARCH_PARAM) === AuthPageMode.REGISTER;

  const disableRegisterMode = () => {
    if (searchParams.has(MODE_SEARCH_PARAM)) {
      searchParams.delete(MODE_SEARCH_PARAM);
      setSearchParams(searchParams);
    }
  };

  const enableRegisterMode = () => {
    searchParams.set(MODE_SEARCH_PARAM, AuthPageMode.REGISTER);
    setSearchParams(searchParams);
  };

  return { isRegisterMode, disableRegisterMode, enableRegisterMode };
}
