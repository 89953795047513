import { ErrorNotificationType, SuccessNotificationType } from 'application/notification/types';

import { IRegisterPayload, IRegisterResponse } from 'domain/repositories/api/auth/IAuthRepository';
import { APIUseCase } from 'domain/useCases/common/APIUseCase';

export class RegisterUseCase extends APIUseCase<
  IRegisterPayload,
  IRegisterResponse
> {
  protected override readonly errorMessage: ErrorNotificationType = ErrorNotificationType
    .FAILED_TO_REGISTER;

  protected override readonly successMessage: SuccessNotificationType = SuccessNotificationType
    .REGISTERED_SUCCESSFULLY;
}
