import React from 'react';
import { Form, Input, Typography } from 'antd';

import RichEditorWrapper from 'components/ui/molecules/richEditor/RichEditorWrapper';
import Flex from 'components/ui/atoms/flex/Flex';

import { ITaskTranslation } from 'domain/entities/proxyTask';

const { Title } = Typography;

interface TaskFormProps {
  translation: ITaskTranslation;
}

const TaskForm: React.FC<TaskFormProps> = ({ translation }) => (
  <Form
    initialValues={translation}
    layout="vertical"
    style={{ width: '49%' }}
    disabled
  >
    <Flex justify="space-between" style={{ margin: '0 0 12px 0' }}>
      <Title level={4}>
        Локализация:
        {' '}
        {translation.iso}
      </Title>
    </Flex>
    <Form.Item label="Название" name="name">
      <Input />
    </Form.Item>
    <Form.Item label="Условие задачи" name="description">
      <RichEditorWrapper defaultValue={translation.description} editable={false} />
    </Form.Item>
    <Form.Item label="Описание входных данных" name="input_data_description">
      <RichEditorWrapper defaultValue={translation.input_data_description} editable={false} />
    </Form.Item>
    <Form.Item label="Описание выходных данных" name="output_data_description">
      <RichEditorWrapper defaultValue={translation.output_data_description} editable={false} />
    </Form.Item>
  </Form>
);

export default TaskForm;
