import React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';

import StrictModeDroppable from 'components/ui/molecules/droppable/Droppable';
import Section from 'components/ui/organisms/classStructure/components/Section';
import { StructureWrapper } from 'components/ui/organisms/classStructure/styled';

import { useOnDragEnd } from 'utils/dnd/useOnDragEnd';

interface ISection {
  id: string;
  name: string;
  tasks: { id: string; name: string; max_score: number; }[];
}

interface ModuleStructureProps<T extends ISection> {
  data: T[];
  moduleId?: string;
  onDeleteSection?: (id: string) => void;
  onChangeSectionsList?: (sectionList: T[]) => void;
}

const ModuleStructure = <T extends ISection, >({
  data,
  moduleId,
  onDeleteSection,
  onChangeSectionsList,
}: ModuleStructureProps<T>) => {
  const { onDragEnd } = useOnDragEnd(data, onChangeSectionsList);

  return (
    <StructureWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppableTasks">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {
                data.map((section, index) => (
                  <Draggable key={section.id} draggableId={section.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                      >
                        <Section
                          id={section.id}
                          name={section.name}
                          tasks={section.tasks}
                          moduleId={moduleId}
                          key={section.id}
                          verticalLine
                          isSectionPercentable
                          onDeleteSection={onDeleteSection}
                          draggableProps={onChangeSectionsList
                            ? provided.dragHandleProps : undefined}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </StructureWrapper>
  );
};

export default ModuleStructure;
