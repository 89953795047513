import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function AddSignIcon(props: IconProps) {
  return (
    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" {...props}>
      <path fill="currentColor" d="M10 7H6V3H4v4H0v2h4v4h2V9h4z" />
    </svg>
  );
}
