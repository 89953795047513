import { ISectionDataSource } from 'domain/repositories/api/section/dataSource/ISectionsDataSource';
import {
  ICreateSectionPayload,
  IDeleteSectionPayload, IForkSectionPayload,
  IGetSectionOverviewPayload, IGetSectionPayload,
  IGetSectionSolutionsPayload, IGetSectionStatisticsPayload,
  IGetSectionTasksPayload, IGetSectionUserSolutionsPayload,
  ISectionsRepository, IUpdateSectionPayload,
} from 'domain/repositories/api/section/ISectionsRepository';

export class SectionsRepository implements ISectionsRepository {
  public constructor(private _ds: ISectionDataSource) {}

  public getSection = (p: IGetSectionPayload) => this._ds.getSection(p);

  public createSection = (p: ICreateSectionPayload) => this._ds.createSection(p);

  public updateSection = (p: IUpdateSectionPayload) => this._ds.updateSection(p);

  public deleteSection = (p: IDeleteSectionPayload) => this._ds.deleteSection(p);

  public forkSection = (p: IForkSectionPayload) => this._ds.forkSection(p);

  public getStatistics = (p: IGetSectionStatisticsPayload) => this._ds.getStatistics(p);

  public getSectionOverview = async ({ id }: IGetSectionOverviewPayload) => {
    const { class_section } = await this._ds.getSection({ id });
    const { classTasks } = await this.getTasks({ sectionId: id });

    return { ...class_section, tasks: classTasks };
  };

  public getTasks = (p: IGetSectionTasksPayload) => this._ds.getTasks(p);

  public getSolutions = (p: IGetSectionSolutionsPayload) => this._ds.getSolutions(p);

  public getUserSolutions = (p: IGetSectionUserSolutionsPayload) => this._ds.getUserSolutions(p);
}
