import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function AuthLogo(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="315" height="152" viewBox="0 0 315 152" fill="none" {...props}>
      <path d="M43.75 151.651V44.9811L86.7708 0L43.75 151.651Z" fill="#0055AE" />
      <path d="M60.5208 146.51L99.8958 38.5552L143.646 12.8517L60.5208 146.51Z" fill="#5FA7E9" />
      <path d="M83.125 142.654L143.646 55.2625L188.854 38.5552L135.99 90.6048L83.125 142.654Z" fill="#0055AE" />
      <path d="M105.729 132.373L182.292 72.6124L223.854 60.4032L105.729 132.373Z" fill="#5FA7E9" />
      <path d="M143.646 132.373L204.167 95.7455L250.104 87.3919L220.208 104.742L143.646 132.373Z" fill="#5FA7E9" />
      <path d="M179.375 135.586L227.5 117.593H268.333L238.438 127.232L179.375 135.586Z" fill="#5FA7E9" />
      <path d="M220.208 142.654L259.583 135.586H288.75L259.583 142.654H220.208Z" fill="#5FA7E9" />
      <path d="M250.104 151.651L276.354 146.51L315 151.651H250.104Z" fill="#5FA7E9" />
      <path d="M35.7292 151.651L13.125 101.529L29.1667 72.6124L35.7292 151.651Z" fill="#5FA7E9" />
      <path d="M21.875 151.651L0 146.51L5.10419 119.521L21.875 151.651Z" fill="#5FA7E9" />
    </svg>
  );
}
