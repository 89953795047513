import React from 'react';
import {
  DragDropContext, Draggable,
} from 'react-beautiful-dnd';

import { StructureWrapper } from 'components/ui/organisms/classStructure/styled';
import TaskHeader from 'components/ui/organisms/classStructure/components/TaskHeader';
import StrictModeDroppable from 'components/ui/molecules/droppable/Droppable';

import { useOnDragEnd } from 'utils/dnd/useOnDragEnd';

interface ITask {
  id: string;
  name: string;
  max_score: number;
}

interface SectionStructureProps<T> {
  data: T[];
  moduleId?: string;
  sectionId?: string;
  setUpdatedTasksList?: (list: T[]) => void;
  onDeleteTask?: (id: string) => void;
}

const SectionStructure = <T extends ITask, >({
  data,
  moduleId,
  sectionId,
  setUpdatedTasksList,
  onDeleteTask,
}: SectionStructureProps<T>) => {
  const { onDragEnd } = useOnDragEnd(data, setUpdatedTasksList);

  return (
    <StructureWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppableTasks">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {
                data.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                      >
                        <TaskHeader
                          key={task.id}
                          id={task.id}
                          name={task.name}
                          maxScore={task.max_score}
                          moduleId={moduleId}
                          sectionId={sectionId}
                          onDelete={onDeleteTask}
                          draggableProps={setUpdatedTasksList
                            ? provided.dragHandleProps : null}
                          leftPadding={false}
                          index={index + 1}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>

    </StructureWrapper>
  );
};

export default SectionStructure;
