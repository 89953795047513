import axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import { requestInterceptorOnFulfilled, requestInterceptorOnRejected } from 'data/axios/requestInterceptor';
import { getResponseInterceptorOnRejected } from 'data/axios/responseInterceptor';

export const axiosCacheInstance = setupCache(axios.create(), {
  storage: buildWebStorage(localStorage, 'axios-cache:'),
  ttl: 1000 * 60 * 60 * 12, // 12 hours
  cacheTakeover: false,
});

axiosCacheInstance.interceptors.request.use(
  requestInterceptorOnFulfilled,
  requestInterceptorOnRejected,
);

axiosCacheInstance.interceptors.response.use(
  (response) => response,
  getResponseInterceptorOnRejected(axiosCacheInstance),
);
