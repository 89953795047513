import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function OkIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M9.5 12.5L11.5 14.5L16.5 9.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
