import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';

import { IEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/interfaces';

import { GetSectionDetailsProxyUseCase } from 'domain/useCases/proxy/GetSectionDetailsProxyUseCase';
import { ISectionProxy } from 'domain/entities/proxySection';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

import { getFieldFromTranslations } from 'utils/locales/getFieldFromTranslations';

export class EntityDrawerSectionProxyViewModel implements IEntityDrawerViewModel {
  @observable public pageStatus = new LoadStatus();

  @observable public section: ISectionProxy | undefined;

  @observable public localization = '';

  public constructor(
    private _getSection: GetSectionDetailsProxyUseCase,
  ) {
    makeObservable(this);
  }

  @computed public get title() {
    return this.section
      ? getFieldFromTranslations('name', this.section.translations)
      : 'Загрузка...';
  }

  @computed public get tasks() {
    return this.section?.tasks?.map(({ id, translation }) => ({
      id,
      name: getFieldFromTranslations('name', translation, this.localization),
      max_score: 0,
    }));
  }

  @action public setLocalization = (l: string) => {
    this.localization = l;
  };

  @action public async fetch(id: string) {
    await this._getSection.fetch({
      payload: { id },
      onSuccess: ({ section }) => {
        runInAction(() => {
          this.section = section;
        });
      },
      loadStatus: this.pageStatus,
    });
  }

  public reset = () => {
    this.section = undefined;

    this.pageStatus.onStartRequest();
  };
}
