import { IHttpClient, TAxiosConfig, TQueryObject } from 'data/network/httpClient/IHttpClient';
import { AxiosResponse } from 'axios';
import { axiosCacheInstance } from 'data/axios/axiosCacheInstance';
import { axiosInstance } from 'data/axios/axiosInstance';

export const DUPLICATE_SEARCH_QUERY_SUFFIX = '__1';

class HttpClient implements IHttpClient {
  public get = async <TResponse>(
    resource: string,
    queryObject?: TQueryObject,
    shouldBeCached?: boolean,
    axiosConfig?: TAxiosConfig,
  ) => {
    const axios = shouldBeCached ? axiosCacheInstance : axiosInstance;

    return axios
      .get(`${resource}${this.getQueryString(queryObject)}`, axiosConfig)
      .then((response: AxiosResponse<TResponse>) => response.data);
  };

  public post = async <TResponse>(resource: string, body?: object) => axiosInstance
    .post(resource, body)
    .then((response: AxiosResponse<TResponse>) => response.data);

  private getQueryString = (queryObject?: TQueryObject) => {
    if (!queryObject) return '';

    const searchParams = new URLSearchParams();

    Object.entries(queryObject).forEach(([k, v]) => {
      if (k.endsWith(DUPLICATE_SEARCH_QUERY_SUFFIX)) {
        k = k.replaceAll(DUPLICATE_SEARCH_QUERY_SUFFIX, '');
      }

      if (Array.isArray(v)) {
        v.forEach((curValue) => {
          searchParams.append(k, curValue);
        });
      } else if (v || v === 0 || v === false) searchParams.append(k, v.toString());
    });

    if (searchParams.size) return `?${searchParams.toString()}`;

    return '';
  };
}

export const httpClient = new HttpClient();
