import React, { ReactNode, useCallback, useRef } from 'react';
import CuiSelect, {
  components, MultiValue, Props, SelectInstance, SingleValue,
} from 'react-select';
import clsx from 'clsx';

import Loader from 'components/ui/atoms/loader/Loader';
import Center from 'components/ui/atoms/center/Center';
import SearchIcon from 'components/ui/atoms/icons/new/Search';
import Collapse from 'components/animations/collapseHidden/Collapse';
import Paragraph from 'components/ui/atoms/typography/Paragraph';

import { sleep } from 'utils/asyncHelpers';

import classes from './Select.module.scss';
import DropdownIndicator from './ui/DropdownIndicator';
import { TSelectOption } from './types';

export type TSelectProps = Omit<Props<TSelectOption>, 'onMenuOpen' | 'value' | 'onChange' | 'options'> & {
  value?: string;
  error?: boolean;
  errorMessage?: string;
  options: TSelectOption[];
  suffixIcon?: ReactNode;
  classNameContainer?: string;
  withDropdownIndicator?: boolean;
  onMenuOpen?: () => Promise<void>;
  onChange?: (value: string) => void;
};

export default function Select(props: TSelectProps) {
  const {
    value,
    className,
    error,
    errorMessage,
    options,
    suffixIcon,
    classNameContainer,
    withDropdownIndicator = true,
    isMulti,
    onMenuOpen,
    onChange,
    ...rest
  } = props;

  const dropdownIndicator = useCallback(
    (menuIsOpen: boolean) => <DropdownIndicator menuIsOpen={menuIsOpen} />,
    [],
  );

  const ref = useRef<SelectInstance>(null);

  const handleMenuOpen = async () => {
    await onMenuOpen?.();
    await sleep(0);
    ref.current?.inputRef?.focus();
  };

  const handleChange = (newValue: MultiValue<TSelectOption> | SingleValue<TSelectOption>) => {
    const newValueFixed = newValue as TSelectOption;

    onChange?.(newValueFixed?.value);
  };

  const isArrayValueEmpty = Array.isArray(value) ? !value.length : true;
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div className={classNameContainer}>
      <CuiSelect
        // @ts-ignore
        ref={ref}
        value={selectedOption}
        options={options}
        className={clsx(classes.select, className)}
        classNames={{
          control: ({ menuIsOpen }) => (
            clsx(classes.select__control, { [classes.select__control_active]: menuIsOpen })
          ),
          menu: () => classes.select__menu,
          option: () => classes.select__option,
          multiValue: () => classes.multi_value,
          singleValue: () => classes.select__single_value,
          placeholder: () => classes.select__placeholder,
          clearIndicator: () => classes.select__clear_indicator,
        }}
        components={{
          IndicatorSeparator: null,
          IndicatorsContainer: ({ children }) => (
            <div className={clsx(classes.indicators_container, {
              [classes.indicators_container_no_indicator_container]: !withDropdownIndicator,
            })}
            >
              {children}
            </div>
          ),
          DropdownIndicator: ({ selectProps }) => (
            withDropdownIndicator
              ? dropdownIndicator(selectProps.menuIsOpen)
              : null
          ),
          ValueContainer: ({ children, ...rest }) => (
            <components.ValueContainer
              {...rest}
              className={clsx({
                [classes.value_container_with_icon]: !!suffixIcon && isArrayValueEmpty,
              })}
            >
              {suffixIcon && (
                <div className={classes.icon_container}>
                  <SearchIcon />
                </div>
              )}
              {children}
            </components.ValueContainer>
          ),
          LoadingIndicator: () => null,
          LoadingMessage: () => (
            <Center>
              <Loader />
            </Center>
          ),
        }}
        isMulti={isMulti}
        onMenuOpen={handleMenuOpen}
        onChange={handleChange}
        {...rest}
      />
      <Collapse isOpen={!!errorMessage}>
        <Paragraph color="error" className={classes.error_message}>{errorMessage}</Paragraph>
      </Collapse>
    </div>
  );
}
