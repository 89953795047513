import classes from 'components/ui/atoms/button/Button.module.scss';
import { TButtonVariant } from 'components/ui/atoms/button/types';

export const getClassNameVariant = (type?: TButtonVariant): string => {
  if (!type || type === 'secondary') return '';

  if (type === 'accent') return classes.button_type_accent;
  if (type === 'success') return classes.button_type_success;
  if (type === 'error') return classes.button_type_error;
  if (type === 'purple') return classes.button_type_purple;
  if (type === 'accent-alt') return classes.button_type_accent_alt;
  if (type === 'ghost') return classes.button_type_ghost;

  return '';
};
