import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function ChevronUpIcon(props: IconProps) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 7L8 1L1 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
