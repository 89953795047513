import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IAgentRepository,
  TGetMyAgentsResponse,
  TRegisterAgentPayload,
  TRegisterAgentResponse, TVerifyAgentPayload, TVerifyAgentResponse,
} from './IAgentRepository';

export class AgentRepository implements IAgentRepository {
  private readonly prefix = `${process.env.REACT_APP_USER_API}/agent`;

  public constructor(private _httpClient: IHttpClient) {}

  public getMyAgents = () => (
    this._httpClient.get<TGetMyAgentsResponse>(`${this.prefix}`)
  );

  public registerAgent = (payload: TRegisterAgentPayload) => (
    this._httpClient.post<TRegisterAgentResponse>(`${this.prefix}/register`, payload)
  );

  public verifyAgent = ({ tokenId }: TVerifyAgentPayload) => (
    this._httpClient.get<TVerifyAgentResponse>(`${this.prefix}/token/${tokenId}/verify`)
  );
}
