import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import { IUpdateClassResponseDS } from 'domain/repositories/api/class/dataSource/IClassDataSource';
import {
  IGetClassTasksListPayloadDS,
  IGetClassTasksListResponseDS,
  IGetClassTaskResponseDS,
  IGetClassTaskPayloadDS,
  ITaskDataSource,
  IForkTaskPayloadDS,
  IForkTaskResponseDS,
  IUpdateClassTaskPayloadDS,
  IGetClassTaskConfigsPayloadDS,
  IGetClassTaskConfigsResponseDS,
  IUpdateClassTaskConfigPayloadDS,
  IUpdateClassTaskConfigResponseDS,
  IDeleteClassTaskConfigPayloadDS,
  IDeleteClassTaskConfigResponseDS,
  IDeleteClassTaskPayloadDS,
  IDeleteClassTaskResponseDS,
} from 'domain/repositories/api/task/dataSource/ITaskDataSource';

import { SearchQueryOperators } from 'utils/enums/SearchQueryOperators';

export class TaskDataSource implements ITaskDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/classTask`;

  public constructor(private _httpClient: IHttpClient) {}

  public getClassTasks = ({ filters, classId, ...pagination }: IGetClassTasksListPayloadDS) => (
    this._httpClient
      .get<IGetClassTasksListResponseDS>(`${this.prefix}/class/${classId}/get`, {
        ...pagination,
        name: filters?.name && `${SearchQueryOperators.LIKE}${filters.name}`,
        id: filters?.id,
        is_visible: filters?.isVisible,
        class_section_id: filters?.classSectionsIds,
        class_study_module_id: filters?.classStudyModulesIds,
        testing_verdict: filters?.testingVerdicts,
        postmoderation_verdict: filters?.postmoderationVerdicts,
        not_tested: filters?.notTested,
        solved: filters?.solved,
      })
  );

  public getClassTask = ({ taskId }: IGetClassTaskPayloadDS) => this._httpClient
    .get<IGetClassTaskResponseDS>(`${this.prefix}/${taskId}/get`);

  public updateClassTask = ({ taskId, ...body }: IUpdateClassTaskPayloadDS) => this._httpClient
    .post<IUpdateClassResponseDS>(`${this.prefix}/${taskId}/update`, body);

  public deleteClassTask = ({ id }: IDeleteClassTaskPayloadDS) => this._httpClient
    .post<IDeleteClassTaskResponseDS>(`${this.prefix}/${id}/delete`);

  public forkTask = ({ taskId, sectionId, iso }: IForkTaskPayloadDS) => this._httpClient
    .post<IForkTaskResponseDS>(`${this.prefix}/fork/${taskId}/${sectionId}/${iso}`);

  public getClassTaskConfigs = ({ taskId, ...pagination }: IGetClassTaskConfigsPayloadDS) => (
    this._httpClient
      .get<IGetClassTaskConfigsResponseDS>(`${this.prefix}/${taskId}/config/get`, {
        ...pagination,
      })
  );

  public updateClassTaskConfig = ({
    taskId, configId, ...body
  }: IUpdateClassTaskConfigPayloadDS) => (
    this._httpClient
      .post<IUpdateClassTaskConfigResponseDS>(`${this.prefix}/${taskId}/config/${configId}/update`, body)
  );

  public deleteClassTaskConfig = ({ taskId, configId }: IDeleteClassTaskConfigPayloadDS) => (
    this._httpClient
      .post<IDeleteClassTaskConfigResponseDS>(`${this.prefix}/${taskId}/config/${configId}/delete`)
  );
}
