export interface IPermission {
  type: PermissionType;
  permission: Permission;
  permissionEntityId: string;
}

export enum PermissionType {
  PLATFORM = 'platform',
  GLOBAL = 'global',
  UNIVERSITY = 'university',
  SUBDIVISION ='subdivision',
}

export enum Permission {
  SUPER_ADMIN = 'super_admin',
  EDIT_TASK_BANK = 'edit_task_bank',
  CAN_CREATE_SUBDIVISION = 'can_create_subdivision',
  CAN_CREATE_CLASS = 'can_create_class',
}
