import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ArgsProps } from 'antd/es/notification';

import { NotificationContext } from 'application/notification/NotificationsProvider';
import {
  ErrorNotificationType,
  ShowErrorFunction,
  ShowSuccessNotificationFunction, SuccessNotificationType,
} from 'application/notification/types';

import { ns } from 'utils/locales';

import classes from './Notification.module.scss';
import Notification from './Notification';

const commonProps: ArgsProps = {
  message: null,
  placement: 'bottom',
  className: classes.notification_container,
  closable: false,
  icon: null,
  closeIcon: null,
};

export const useNotifications = () => {
  const api = useContext(NotificationContext);

  const { t } = useTranslation([ns.errors, ns.messages]);

  const notifyError: ShowErrorFunction = (errorType: ErrorNotificationType | string) => {
    let message;

    if (i18n.exists(`${ns.errors}:${errorType}`)) {
      message = t(`${ns.errors}:${errorType}`);
    } else {
      message = errorType;
    }

    api?.error({
      ...commonProps,
      message: <Notification message={message} type="error" />,
    });
  };

  const notifySuccess: ShowSuccessNotificationFunction = (
    successType: SuccessNotificationType | string,
  ) => {
    let message;

    if (i18n.exists(`${ns.messages}:${successType}`)) {
      message = t(`${ns.messages}:${successType}`);
    } else {
      message = successType;
    }

    api?.success({
      ...commonProps,
      message: <Notification message={message} type="success" />,
    });
  };

  return {
    notifyError,
    notifySuccess,
  };
};
