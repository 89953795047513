import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function Background(props: IconProps) {
  return (
    <svg width="1031" height="1053" viewBox="0 0 1031 1053" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M73.811 410.568C73.811 411.883 73.6698 413.058 73.3875 414.095C73.1051 415.132 72.6897 416.01 72.1412 416.728C71.6007 417.438 70.9392 417.981 70.1568 418.357C69.3743 418.732 68.4789 418.92 67.4706 418.92C66.5994 418.92 65.7968 418.765 65.0627 418.455C64.3286 418.145 63.6954 417.659 63.163 416.997C62.6306 416.328 62.2152 415.475 61.9167 414.438C61.6182 413.401 61.469 412.16 61.469 410.715C61.469 409.401 61.6102 408.225 61.8925 407.188C62.1829 406.151 62.5983 405.278 63.1388 404.567C63.6793 403.849 64.3448 403.302 65.1353 402.926C65.9258 402.551 66.8212 402.363 67.8215 402.363C68.6927 402.363 69.4953 402.518 70.2294 402.828C70.9634 403.138 71.5926 403.624 72.117 404.286C72.6494 404.947 73.0648 405.796 73.3633 406.833C73.6617 407.87 73.811 409.115 73.811 410.568ZM64.4456 410.617C64.4456 410.78 64.4456 410.935 64.4456 411.082C64.4456 411.221 64.4537 411.364 64.4698 411.511L70.3988 407.176C70.1164 406.367 69.7413 405.771 69.2735 405.388C68.8137 404.996 68.2692 404.8 67.64 404.8C67.1883 404.8 66.7688 404.918 66.3816 405.155C65.9944 405.384 65.6556 405.739 65.3652 406.22C65.0829 406.702 64.857 407.306 64.6876 408.033C64.5263 408.76 64.4456 409.621 64.4456 410.617ZM70.8344 410.666C70.8344 410.527 70.8303 410.388 70.8223 410.25C70.8142 410.103 70.8102 409.964 70.8102 409.833L64.9175 414.144C65.1756 414.936 65.5386 415.524 66.0065 415.908C66.4744 416.291 67.0189 416.483 67.64 416.483C68.0917 416.483 68.5112 416.365 68.8984 416.128C69.2936 415.891 69.6324 415.536 69.9148 415.063C70.2052 414.581 70.431 413.977 70.5924 413.25C70.7537 412.515 70.8344 411.654 70.8344 410.666Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 445.113V442.455H66.6962V432.168L63.1872 434.128L62.2192 431.691L67.2407 429.009H69.7212V442.455H73.1939V445.113H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 471.587V468.93H66.6962V458.643L63.1872 460.602L62.2192 458.165L67.2407 455.483H69.7212V468.93H73.1939V471.587H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 489.991C73.811 491.306 73.6698 492.481 73.3875 493.518C73.1051 494.555 72.6897 495.433 72.1412 496.151C71.6007 496.862 70.9392 497.404 70.1568 497.78C69.3743 498.156 68.4789 498.343 67.4706 498.343C66.5994 498.343 65.7968 498.188 65.0627 497.878C64.3286 497.568 63.6954 497.082 63.163 496.421C62.6306 495.751 62.2152 494.898 61.9167 493.861C61.6182 492.824 61.469 491.583 61.469 490.138C61.469 488.824 61.6102 487.648 61.8925 486.611C62.1829 485.574 62.5983 484.701 63.1388 483.99C63.6793 483.272 64.3448 482.725 65.1353 482.349C65.9258 481.974 66.8212 481.786 67.8215 481.786C68.6927 481.786 69.4953 481.941 70.2294 482.251C70.9634 482.562 71.5926 483.047 72.117 483.709C72.6494 484.37 73.0648 485.219 73.3633 486.256C73.6617 487.293 73.811 488.538 73.811 489.991ZM64.4456 490.04C64.4456 490.203 64.4456 490.359 64.4456 490.506C64.4456 490.644 64.4537 490.787 64.4698 490.934L70.3988 486.599C70.1164 485.791 69.7413 485.195 69.2735 484.811C68.8137 484.419 68.2692 484.223 67.64 484.223C67.1883 484.223 66.7688 484.341 66.3816 484.578C65.9944 484.807 65.6556 485.162 65.3652 485.644C65.0829 486.125 64.857 486.73 64.6876 487.456C64.5263 488.183 64.4456 489.044 64.4456 490.04ZM70.8344 490.089C70.8344 489.95 70.8303 489.812 70.8223 489.673C70.8142 489.526 70.8102 489.387 70.8102 489.256L64.9175 493.567C65.1756 494.359 65.5386 494.947 66.0065 495.331C66.4744 495.714 67.0189 495.906 67.64 495.906C68.0917 495.906 68.5112 495.788 68.8984 495.551C69.2936 495.314 69.6324 494.959 69.9148 494.486C70.2052 494.004 70.431 493.4 70.5924 492.673C70.7537 491.938 70.8344 491.077 70.8344 490.089Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 524.536V521.879H66.6962V511.591L63.1872 513.551L62.2192 511.114L67.2407 508.432H69.7212V521.879H73.1939V524.536H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 542.94C73.811 544.254 73.6698 545.43 73.3875 546.467C73.1051 547.504 72.6897 548.382 72.1412 549.1C71.6007 549.81 70.9392 550.353 70.1568 550.729C69.3743 551.104 68.4789 551.292 67.4706 551.292C66.5994 551.292 65.7968 551.137 65.0627 550.827C64.3286 550.517 63.6954 550.031 63.163 549.369C62.6306 548.7 62.2152 547.847 61.9167 546.81C61.6182 545.773 61.469 544.532 61.469 543.087C61.469 541.772 61.6102 540.597 61.8925 539.56C62.1829 538.523 62.5983 537.649 63.1388 536.939C63.6793 536.221 64.3448 535.674 65.1353 535.298C65.9258 534.923 66.8212 534.735 67.8215 534.735C68.6927 534.735 69.4953 534.89 70.2294 535.2C70.9634 535.51 71.5926 535.996 72.117 536.658C72.6494 537.319 73.0648 538.168 73.3633 539.205C73.6617 540.242 73.811 541.487 73.811 542.94ZM64.4456 542.989C64.4456 543.152 64.4456 543.307 64.4456 543.454C64.4456 543.593 64.4537 543.736 64.4698 543.883L70.3988 539.548C70.1164 538.739 69.7413 538.143 69.2735 537.76C68.8137 537.368 68.2692 537.172 67.64 537.172C67.1883 537.172 66.7688 537.29 66.3816 537.527C65.9944 537.756 65.6556 538.111 65.3652 538.592C65.0829 539.074 64.857 539.678 64.6876 540.405C64.5263 541.132 64.4456 541.993 64.4456 542.989ZM70.8344 543.038C70.8344 542.899 70.8303 542.76 70.8223 542.622C70.8142 542.475 70.8102 542.336 70.8102 542.205L64.9175 546.516C65.1756 547.308 65.5386 547.896 66.0065 548.28C66.4744 548.663 67.0189 548.855 67.64 548.855C68.0917 548.855 68.5112 548.737 68.8984 548.5C69.2936 548.263 69.6324 547.908 69.9148 547.434C70.2052 546.953 70.431 546.349 70.5924 545.622C70.7537 544.887 70.8344 544.026 70.8344 543.038Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 569.414C73.811 570.729 73.6698 571.905 73.3875 572.941C73.1051 573.978 72.6897 574.856 72.1412 575.574C71.6007 576.285 70.9392 576.828 70.1568 577.203C69.3743 577.579 68.4789 577.767 67.4706 577.767C66.5994 577.767 65.7968 577.611 65.0627 577.301C64.3286 576.991 63.6954 576.505 63.163 575.844C62.6306 575.174 62.2152 574.321 61.9167 573.284C61.6182 572.247 61.469 571.006 61.469 569.561C61.469 568.247 61.6102 567.071 61.8925 566.034C62.1829 564.997 62.5983 564.124 63.1388 563.414C63.6793 562.695 64.3448 562.148 65.1353 561.773C65.9258 561.397 66.8212 561.209 67.8215 561.209C68.6927 561.209 69.4953 561.364 70.2294 561.675C70.9634 561.985 71.5926 562.471 72.117 563.132C72.6494 563.793 73.0648 564.642 73.3633 565.679C73.6617 566.716 73.811 567.961 73.811 569.414ZM64.4456 569.463C64.4456 569.627 64.4456 569.782 64.4456 569.929C64.4456 570.068 64.4537 570.21 64.4698 570.357L70.3988 566.022C70.1164 565.214 69.7413 564.618 69.2735 564.234C68.8137 563.842 68.2692 563.646 67.64 563.646C67.1883 563.646 66.7688 563.765 66.3816 564.001C65.9944 564.23 65.6556 564.585 65.3652 565.067C65.0829 565.549 64.857 566.153 64.6876 566.879C64.5263 567.606 64.4456 568.467 64.4456 569.463ZM70.8344 569.512C70.8344 569.374 70.8303 569.235 70.8223 569.096C70.8142 568.949 70.8102 568.81 70.8102 568.68L64.9175 572.99C65.1756 573.782 65.5386 574.37 66.0065 574.754C66.4744 575.138 67.0189 575.329 67.64 575.329C68.0917 575.329 68.5112 575.211 68.8984 574.974C69.2936 574.738 69.6324 574.382 69.9148 573.909C70.2052 573.427 70.431 572.823 70.5924 572.096C70.7537 571.362 70.8344 570.5 70.8344 569.512Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 595.889C73.811 597.203 73.6698 598.379 73.3875 599.416C73.1051 600.453 72.6897 601.33 72.1412 602.049C71.6007 602.759 70.9392 603.302 70.1568 603.678C69.3743 604.053 68.4789 604.241 67.4706 604.241C66.5994 604.241 65.7968 604.086 65.0627 603.776C64.3286 603.465 63.6954 602.98 63.163 602.318C62.6306 601.649 62.2152 600.796 61.9167 599.759C61.6182 598.722 61.469 597.481 61.469 596.036C61.469 594.721 61.6102 593.546 61.8925 592.509C62.1829 591.472 62.5983 590.598 63.1388 589.888C63.6793 589.169 64.3448 588.622 65.1353 588.247C65.9258 587.871 66.8212 587.684 67.8215 587.684C68.6927 587.684 69.4953 587.839 70.2294 588.149C70.9634 588.459 71.5926 588.945 72.117 589.606C72.6494 590.268 73.0648 591.117 73.3633 592.154C73.6617 593.19 73.811 594.436 73.811 595.889ZM64.4456 595.938C64.4456 596.101 64.4456 596.256 64.4456 596.403C64.4456 596.542 64.4537 596.685 64.4698 596.832L70.3988 592.496C70.1164 591.688 69.7413 591.092 69.2735 590.708C68.8137 590.317 68.2692 590.121 67.64 590.121C67.1883 590.121 66.7688 590.239 66.3816 590.476C65.9944 590.704 65.6556 591.06 65.3652 591.541C65.0829 592.023 64.857 592.627 64.6876 593.354C64.5263 594.08 64.4456 594.942 64.4456 595.938ZM70.8344 595.987C70.8344 595.848 70.8303 595.709 70.8223 595.57C70.8142 595.423 70.8102 595.285 70.8102 595.154L64.9175 599.465C65.1756 600.257 65.5386 600.845 66.0065 601.228C66.4744 601.612 67.0189 601.804 67.64 601.804C68.0917 601.804 68.5112 601.686 68.8984 601.449C69.2936 601.212 69.6324 600.857 69.9148 600.383C70.2052 599.902 70.431 599.297 70.5924 598.571C70.7537 597.836 70.8344 596.975 70.8344 595.987Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 630.434V627.776H66.6962V617.489L63.1872 619.448L62.2192 617.011L67.2407 614.329H69.7212V627.776H73.1939V630.434H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 656.908V654.251H66.6962V643.963L63.1872 645.923L62.2192 643.486L67.2407 640.804H69.7212V654.251H73.1939V656.908H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 675.312C73.811 676.626 73.6698 677.802 73.3875 678.839C73.1051 679.876 72.6897 680.754 72.1412 681.472C71.6007 682.182 70.9392 682.725 70.1568 683.101C69.3743 683.476 68.4789 683.664 67.4706 683.664C66.5994 683.664 65.7968 683.509 65.0627 683.199C64.3286 682.889 63.6954 682.403 63.163 681.741C62.6306 681.072 62.2152 680.219 61.9167 679.182C61.6182 678.145 61.469 676.904 61.469 675.459C61.469 674.144 61.6102 672.969 61.8925 671.932C62.1829 670.895 62.5983 670.021 63.1388 669.311C63.6793 668.593 64.3448 668.046 65.1353 667.67C65.9258 667.295 66.8212 667.107 67.8215 667.107C68.6927 667.107 69.4953 667.262 70.2294 667.572C70.9634 667.882 71.5926 668.368 72.117 669.029C72.6494 669.691 73.0648 670.54 73.3633 671.577C73.6617 672.614 73.811 673.859 73.811 675.312ZM64.4456 675.361C64.4456 675.524 64.4456 675.679 64.4456 675.826C64.4456 675.965 64.4537 676.108 64.4698 676.255L70.3988 671.92C70.1164 671.111 69.7413 670.515 69.2735 670.132C68.8137 669.74 68.2692 669.544 67.64 669.544C67.1883 669.544 66.7688 669.662 66.3816 669.899C65.9944 670.128 65.6556 670.483 65.3652 670.964C65.0829 671.446 64.857 672.05 64.6876 672.777C64.5263 673.504 64.4456 674.365 64.4456 675.361ZM70.8344 675.41C70.8344 675.271 70.8303 675.132 70.8223 674.994C70.8142 674.847 70.8102 674.708 70.8102 674.577L64.9175 678.888C65.1756 679.68 65.5386 680.268 66.0065 680.651C66.4744 681.035 67.0189 681.227 67.64 681.227C68.0917 681.227 68.5112 681.109 68.8984 680.872C69.2936 680.635 69.6324 680.28 69.9148 679.806C70.2052 679.325 70.431 678.721 70.5924 677.994C70.7537 677.259 70.8344 676.398 70.8344 675.41Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 709.857V707.199H66.6962V696.912L63.1872 698.872L62.2192 696.435L67.2407 693.753H69.7212V707.199H73.1939V709.857H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 736.331V733.674H66.6962V723.387L63.1872 725.346L62.2192 722.909L67.2407 720.227H69.7212V733.674H73.1939V736.331H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 754.735C73.811 756.05 73.6698 757.225 73.3875 758.262C73.1051 759.299 72.6897 760.177 72.1412 760.895C71.6007 761.605 70.9392 762.148 70.1568 762.524C69.3743 762.9 68.4789 763.087 67.4706 763.087C66.5994 763.087 65.7968 762.932 65.0627 762.622C64.3286 762.312 63.6954 761.826 63.163 761.165C62.6306 760.495 62.2152 759.642 61.9167 758.605C61.6182 757.568 61.469 756.327 61.469 754.882C61.469 753.568 61.6102 752.392 61.8925 751.355C62.1829 750.318 62.5983 749.445 63.1388 748.734C63.6793 748.016 64.3448 747.469 65.1353 747.093C65.9258 746.718 66.8212 746.53 67.8215 746.53C68.6927 746.53 69.4953 746.685 70.2294 746.995C70.9634 747.306 71.5926 747.791 72.117 748.453C72.6494 749.114 73.0648 749.963 73.3633 751C73.6617 752.037 73.811 753.282 73.811 754.735ZM64.4456 754.784C64.4456 754.947 64.4456 755.103 64.4456 755.25C64.4456 755.388 64.4537 755.531 64.4698 755.678L70.3988 751.343C70.1164 750.535 69.7413 749.939 69.2735 749.555C68.8137 749.163 68.2692 748.967 67.64 748.967C67.1883 748.967 66.7688 749.085 66.3816 749.322C65.9944 749.551 65.6556 749.906 65.3652 750.388C65.0829 750.869 64.857 751.473 64.6876 752.2C64.5263 752.927 64.4456 753.788 64.4456 754.784ZM70.8344 754.833C70.8344 754.694 70.8303 754.556 70.8223 754.417C70.8142 754.27 70.8102 754.131 70.8102 754L64.9175 758.311C65.1756 759.103 65.5386 759.691 66.0065 760.075C66.4744 760.458 67.0189 760.65 67.64 760.65C68.0917 760.65 68.5112 760.532 68.8984 760.295C69.2936 760.058 69.6324 759.703 69.9148 759.23C70.2052 758.748 70.431 758.144 70.5924 757.417C70.7537 756.682 70.8344 755.821 70.8344 754.833Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 789.28V786.623H66.6962V776.335L63.1872 778.295L62.2192 775.858L67.2407 773.176H69.7212V786.623H73.1939V789.28H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 815.754V813.097H66.6962V802.81L63.1872 804.769L62.2192 802.332L67.2407 799.65H69.7212V813.097H73.1939V815.754H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 842.229V839.571H66.6962V829.284L63.1872 831.244L62.2192 828.807L67.2407 826.125H69.7212V839.571H73.1939V842.229H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 860.633C73.811 861.947 73.6698 863.123 73.3875 864.16C73.1051 865.197 72.6897 866.074 72.1412 866.793C71.6007 867.503 70.9392 868.046 70.1568 868.422C69.3743 868.797 68.4789 868.985 67.4706 868.985C66.5994 868.985 65.7968 868.83 65.0627 868.52C64.3286 868.209 63.6954 867.723 63.163 867.062C62.6306 866.393 62.2152 865.54 61.9167 864.503C61.6182 863.466 61.469 862.225 61.469 860.78C61.469 859.465 61.6102 858.29 61.8925 857.253C62.1829 856.216 62.5983 855.342 63.1388 854.632C63.6793 853.913 64.3448 853.366 65.1353 852.991C65.9258 852.615 66.8212 852.427 67.8215 852.427C68.6927 852.427 69.4953 852.583 70.2294 852.893C70.9634 853.203 71.5926 853.689 72.117 854.35C72.6494 855.012 73.0648 855.861 73.3633 856.898C73.6617 857.934 73.811 859.179 73.811 860.633ZM64.4456 860.682C64.4456 860.845 64.4456 861 64.4456 861.147C64.4456 861.286 64.4537 861.429 64.4698 861.576L70.3988 857.24C70.1164 856.432 69.7413 855.836 69.2735 855.452C68.8137 855.061 68.2692 854.865 67.64 854.865C67.1883 854.865 66.7688 854.983 66.3816 855.22C65.9944 855.448 65.6556 855.803 65.3652 856.285C65.0829 856.767 64.857 857.371 64.6876 858.098C64.5263 858.824 64.4456 859.686 64.4456 860.682ZM70.8344 860.731C70.8344 860.592 70.8303 860.453 70.8223 860.314C70.8142 860.167 70.8102 860.029 70.8102 859.898L64.9175 864.209C65.1756 865.001 65.5386 865.589 66.0065 865.972C66.4744 866.356 67.0189 866.548 67.64 866.548C68.0917 866.548 68.5112 866.429 68.8984 866.193C69.2936 865.956 69.6324 865.601 69.9148 865.127C70.2052 864.646 70.431 864.041 70.5924 863.315C70.7537 862.58 70.8344 861.719 70.8344 860.731Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M73.811 887.107C73.811 888.422 73.6698 889.597 73.3875 890.634C73.1051 891.671 72.6897 892.549 72.1412 893.267C71.6007 893.977 70.9392 894.52 70.1568 894.896C69.3743 895.271 68.4789 895.459 67.4706 895.459C66.5994 895.459 65.7968 895.304 65.0627 894.994C64.3286 894.684 63.6954 894.198 63.163 893.537C62.6306 892.867 62.2152 892.014 61.9167 890.977C61.6182 889.94 61.469 888.699 61.469 887.254C61.469 885.94 61.6102 884.764 61.8925 883.727C62.1829 882.69 62.5983 881.817 63.1388 881.106C63.6793 880.388 64.3448 879.841 65.1353 879.465C65.9258 879.09 66.8212 878.902 67.8215 878.902C68.6927 878.902 69.4953 879.057 70.2294 879.367C70.9634 879.678 71.5926 880.163 72.117 880.825C72.6494 881.486 73.0648 882.335 73.3633 883.372C73.6617 884.409 73.811 885.654 73.811 887.107ZM64.4456 887.156C64.4456 887.319 64.4456 887.475 64.4456 887.621C64.4456 887.76 64.4537 887.903 64.4698 888.05L70.3988 883.715C70.1164 882.907 69.7413 882.311 69.2735 881.927C68.8137 881.535 68.2692 881.339 67.64 881.339C67.1883 881.339 66.7688 881.457 66.3816 881.694C65.9944 881.923 65.6556 882.278 65.3652 882.76C65.0829 883.241 64.857 883.845 64.6876 884.572C64.5263 885.299 64.4456 886.16 64.4456 887.156ZM70.8344 887.205C70.8344 887.066 70.8303 886.927 70.8223 886.789C70.8142 886.642 70.8102 886.503 70.8102 886.372L64.9175 890.683C65.1756 891.475 65.5386 892.063 66.0065 892.447C66.4744 892.83 67.0189 893.022 67.64 893.022C68.0917 893.022 68.5112 892.904 68.8984 892.667C69.2936 892.43 69.6324 892.075 69.9148 891.602C70.2052 891.12 70.431 890.516 70.5924 889.789C70.7537 889.054 70.8344 888.193 70.8344 887.205Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M62.6669 921.652V918.994H66.6962V908.707L63.1872 910.667L62.2192 908.23L67.2407 905.548H69.7212V918.994H73.1939V921.652H62.6669Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M107.474 407.285C107.474 408.453 107.349 409.498 107.098 410.42C106.847 411.341 106.477 412.122 105.99 412.76C105.509 413.392 104.922 413.874 104.226 414.208C103.53 414.542 102.735 414.709 101.838 414.709C101.064 414.709 100.35 414.571 99.6979 414.295C99.0454 414.019 98.4825 413.588 98.0093 413C97.536 412.405 97.1667 411.646 96.9014 410.725C96.6361 409.803 96.5035 408.7 96.5035 407.415C96.5035 406.247 96.629 405.202 96.8799 404.28C97.1381 403.358 97.5073 402.582 97.9877 401.951C98.4682 401.312 99.0597 400.826 99.7624 400.492C100.465 400.158 101.261 399.991 102.15 399.991C102.925 399.991 103.638 400.129 104.291 400.405C104.943 400.681 105.502 401.112 105.968 401.7C106.442 402.288 106.811 403.043 107.076 403.964C107.342 404.886 107.474 405.993 107.474 407.285ZM99.1493 407.328C99.1493 407.473 99.1493 407.611 99.1493 407.742C99.1493 407.865 99.1565 407.992 99.1709 408.123L104.441 404.269C104.19 403.551 103.857 403.021 103.441 402.68C103.032 402.332 102.548 402.157 101.989 402.157C101.587 402.157 101.214 402.263 100.87 402.473C100.526 402.676 100.225 402.992 99.9668 403.42C99.7158 403.848 99.515 404.385 99.3645 405.031C99.2211 405.677 99.1493 406.443 99.1493 407.328ZM104.828 407.372C104.828 407.248 104.825 407.125 104.818 407.002C104.81 406.871 104.807 406.748 104.807 406.631L99.5688 410.463C99.7983 411.167 100.121 411.69 100.537 412.031C100.953 412.372 101.437 412.542 101.989 412.542C102.39 412.542 102.763 412.437 103.107 412.227C103.459 412.016 103.76 411.701 104.011 411.28C104.269 410.852 104.47 410.315 104.613 409.669C104.757 409.015 104.828 408.25 104.828 407.372Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 438.146V435.784H101.15V426.64L98.0308 428.381L97.1703 426.215L101.634 423.831H103.839V435.784H106.926V438.146H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 461.834V459.471H101.15V450.327L98.0308 452.069L97.1703 449.903L101.634 447.519H103.839V459.471H106.926V461.834H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M107.474 478.347C107.474 479.516 107.349 480.561 107.098 481.483C106.847 482.404 106.477 483.184 105.99 483.823C105.509 484.454 104.922 484.937 104.226 485.271C103.53 485.605 102.735 485.772 101.838 485.772C101.064 485.772 100.35 485.634 99.6979 485.358C99.0454 485.082 98.4825 484.65 98.0093 484.063C97.536 483.467 97.1667 482.709 96.9014 481.787C96.6361 480.866 96.5035 479.763 96.5035 478.478C96.5035 477.31 96.629 476.265 96.8799 475.343C97.1381 474.421 97.5073 473.645 97.9877 473.013C98.4682 472.375 99.0597 471.889 99.7624 471.555C100.465 471.221 101.261 471.054 102.15 471.054C102.925 471.054 103.638 471.192 104.291 471.468C104.943 471.743 105.502 472.175 105.968 472.763C106.442 473.351 106.811 474.106 107.076 475.027C107.342 475.949 107.474 477.056 107.474 478.347ZM99.1493 478.391C99.1493 478.536 99.1493 478.674 99.1493 478.805C99.1493 478.928 99.1565 479.055 99.1709 479.186L104.441 475.332C104.19 474.614 103.857 474.084 103.441 473.743C103.032 473.394 102.548 473.22 101.989 473.22C101.587 473.22 101.214 473.325 100.87 473.536C100.526 473.739 100.225 474.055 99.9668 474.483C99.7158 474.911 99.515 475.448 99.3645 476.094C99.2211 476.74 99.1493 477.506 99.1493 478.391ZM104.828 478.435C104.828 478.311 104.825 478.188 104.818 478.064C104.81 477.934 104.807 477.81 104.807 477.694L99.5688 481.526C99.7983 482.23 100.121 482.753 100.537 483.094C100.953 483.435 101.437 483.605 101.989 483.605C102.39 483.605 102.763 483.5 103.107 483.29C103.459 483.079 103.76 482.763 104.011 482.343C104.269 481.914 104.47 481.377 104.613 480.731C104.757 480.078 104.828 479.313 104.828 478.435Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 509.209V506.847H101.15V497.702L98.0308 499.444L97.1703 497.278L101.634 494.894H103.839V506.847H106.926V509.209H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M107.474 525.723C107.474 526.891 107.349 527.936 107.098 528.858C106.847 529.779 106.477 530.56 105.99 531.198C105.509 531.83 104.922 532.312 104.226 532.646C103.53 532.98 102.735 533.147 101.838 533.147C101.064 533.147 100.35 533.009 99.6979 532.733C99.0454 532.457 98.4825 532.026 98.0093 531.438C97.536 530.843 97.1667 530.084 96.9014 529.163C96.6361 528.241 96.5035 527.138 96.5035 525.853C96.5035 524.685 96.629 523.64 96.8799 522.718C97.1381 521.797 97.5073 521.02 97.9877 520.389C98.4682 519.75 99.0597 519.264 99.7624 518.93C100.465 518.596 101.261 518.429 102.15 518.429C102.925 518.429 103.638 518.567 104.291 518.843C104.943 519.119 105.502 519.55 105.968 520.138C106.442 520.726 106.811 521.481 107.076 522.402C107.342 523.324 107.474 524.431 107.474 525.723ZM99.1493 525.766C99.1493 525.911 99.1493 526.049 99.1493 526.18C99.1493 526.303 99.1565 526.43 99.1709 526.561L104.441 522.707C104.19 521.989 103.857 521.459 103.441 521.118C103.032 520.77 102.548 520.595 101.989 520.595C101.587 520.595 101.214 520.701 100.87 520.911C100.526 521.114 100.225 521.43 99.9668 521.858C99.7158 522.286 99.515 522.823 99.3645 523.469C99.2211 524.115 99.1493 524.881 99.1493 525.766ZM104.828 525.81C104.828 525.686 104.825 525.563 104.818 525.44C104.81 525.309 104.807 525.186 104.807 525.07L99.5688 528.901C99.7983 529.605 100.121 530.128 100.537 530.469C100.953 530.81 101.437 530.981 101.989 530.981C102.39 530.981 102.763 530.875 103.107 530.665C103.459 530.454 103.76 530.139 104.011 529.718C104.269 529.29 104.47 528.753 104.613 528.107C104.757 527.454 104.828 526.688 104.828 525.81Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 603.959V601.597H101.15V592.453L98.0308 594.195L97.1703 592.028L101.634 589.644H103.839V601.597H106.926V603.959H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 627.647V625.285H101.15V616.141L98.0308 617.882L97.1703 615.716L101.634 613.332H103.839V625.285H106.926V627.647H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M107.474 644.161C107.474 645.329 107.349 646.374 107.098 647.296C106.847 648.218 106.477 648.998 105.99 649.636C105.509 650.268 104.922 650.75 104.226 651.084C103.53 651.418 102.735 651.585 101.838 651.585C101.064 651.585 100.35 651.447 99.6979 651.171C99.0454 650.895 98.4825 650.464 98.0093 649.876C97.536 649.281 97.1667 648.522 96.9014 647.601C96.6361 646.679 96.5035 645.576 96.5035 644.291C96.5035 643.123 96.629 642.078 96.8799 641.156C97.1381 640.235 97.5073 639.458 97.9877 638.827C98.4682 638.188 99.0597 637.702 99.7624 637.368C100.465 637.034 101.261 636.867 102.15 636.867C102.925 636.867 103.638 637.005 104.291 637.281C104.943 637.557 105.502 637.988 105.968 638.576C106.442 639.164 106.811 639.919 107.076 640.841C107.342 641.762 107.474 642.869 107.474 644.161ZM99.1493 644.204C99.1493 644.349 99.1493 644.487 99.1493 644.618C99.1493 644.741 99.1565 644.868 99.1709 644.999L104.441 641.145C104.19 640.427 103.857 639.897 103.441 639.556C103.032 639.208 102.548 639.034 101.989 639.034C101.587 639.034 101.214 639.139 100.87 639.349C100.526 639.552 100.225 639.868 99.9668 640.296C99.7158 640.724 99.515 641.261 99.3645 641.907C99.2211 642.553 99.1493 643.319 99.1493 644.204ZM104.828 644.248C104.828 644.124 104.825 644.001 104.818 643.878C104.81 643.747 104.807 643.624 104.807 643.508L99.5688 647.339C99.7983 648.043 100.121 648.566 100.537 648.907C100.953 649.248 101.437 649.419 101.989 649.419C102.39 649.419 102.763 649.313 103.107 649.103C103.459 648.892 103.76 648.577 104.011 648.156C104.269 647.728 104.47 647.191 104.613 646.545C104.757 645.892 104.828 645.126 104.828 644.248Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 675.022V672.66H101.15V663.516L98.0308 665.258L97.1703 663.091L101.634 660.707H103.839V672.66H106.926V675.022H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 698.71V696.348H101.15V687.203L98.0308 688.945L97.1703 686.779L101.634 684.395H103.839V696.348H106.926V698.71H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M107.474 715.224C107.474 716.392 107.349 717.437 107.098 718.359C106.847 719.28 106.477 720.061 105.99 720.699C105.509 721.331 104.922 721.813 104.226 722.147C103.53 722.481 102.735 722.648 101.838 722.648C101.064 722.648 100.35 722.51 99.6979 722.234C99.0454 721.958 98.4825 721.527 98.0093 720.939C97.536 720.344 97.1667 719.585 96.9014 718.664C96.6361 717.742 96.5035 716.639 96.5035 715.354C96.5035 714.186 96.629 713.141 96.8799 712.219C97.1381 711.297 97.5073 710.521 97.9877 709.89C98.4682 709.251 99.0597 708.765 99.7624 708.431C100.465 708.097 101.261 707.93 102.15 707.93C102.925 707.93 103.638 708.068 104.291 708.344C104.943 708.62 105.502 709.051 105.968 709.639C106.442 710.227 106.811 710.982 107.076 711.903C107.342 712.825 107.474 713.932 107.474 715.224ZM99.1493 715.267C99.1493 715.412 99.1493 715.55 99.1493 715.681C99.1493 715.804 99.1565 715.931 99.1709 716.062L104.441 712.208C104.19 711.49 103.857 710.96 103.441 710.619C103.032 710.271 102.548 710.096 101.989 710.096C101.587 710.096 101.214 710.202 100.87 710.412C100.526 710.615 100.225 710.931 99.9668 711.359C99.7158 711.787 99.515 712.324 99.3645 712.97C99.2211 713.616 99.1493 714.382 99.1493 715.267ZM104.828 715.311C104.828 715.187 104.825 715.064 104.818 714.941C104.81 714.81 104.807 714.687 104.807 714.57L99.5688 718.402C99.7983 719.106 100.121 719.629 100.537 719.97C100.953 720.311 101.437 720.481 101.989 720.481C102.39 720.481 102.763 720.376 103.107 720.166C103.459 719.955 103.76 719.64 104.011 719.219C104.269 718.791 104.47 718.254 104.613 717.608C104.757 716.954 104.828 716.189 104.828 715.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 746.085V743.723H101.15V734.579L98.0308 736.32L97.1703 734.154L101.634 731.77H103.839V743.723H106.926V746.085H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 769.773V767.41H101.15V758.266L98.0308 760.008L97.1703 757.842L101.634 755.458H103.839V767.41H106.926V769.773H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 793.46V791.098H101.15V781.954L98.0308 783.696L97.1703 781.529L101.634 779.145H103.839V791.098H106.926V793.46H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M107.474 809.974C107.474 811.142 107.349 812.188 107.098 813.109C106.847 814.031 106.477 814.811 105.99 815.45C105.509 816.081 104.922 816.564 104.226 816.897C103.53 817.231 102.735 817.398 101.838 817.398C101.064 817.398 100.35 817.26 99.6979 816.985C99.0454 816.709 98.4825 816.277 98.0093 815.689C97.536 815.094 97.1667 814.336 96.9014 813.414C96.6361 812.492 96.5035 811.389 96.5035 810.105C96.5035 808.936 96.629 807.891 96.8799 806.97C97.1381 806.048 97.5073 805.271 97.9877 804.64C98.4682 804.001 99.0597 803.515 99.7624 803.181C100.465 802.847 101.261 802.681 102.15 802.681C102.925 802.681 103.638 802.818 104.291 803.094C104.943 803.37 105.502 803.802 105.968 804.39C106.442 804.977 106.811 805.732 107.076 806.654C107.342 807.576 107.474 808.682 107.474 809.974ZM99.1493 810.018C99.1493 810.163 99.1493 810.301 99.1493 810.431C99.1493 810.555 99.1565 810.682 99.1709 810.812L104.441 806.959C104.19 806.24 103.857 805.71 103.441 805.369C103.032 805.021 102.548 804.847 101.989 804.847C101.587 804.847 101.214 804.952 100.87 805.163C100.526 805.366 100.225 805.681 99.9668 806.11C99.7158 806.538 99.515 807.075 99.3645 807.721C99.2211 808.367 99.1493 809.132 99.1493 810.018ZM104.828 810.061C104.828 809.938 104.825 809.814 104.818 809.691C104.81 809.56 104.807 809.437 104.807 809.321L99.5688 813.153C99.7983 813.857 100.121 814.379 100.537 814.72C100.953 815.061 101.437 815.232 101.989 815.232C102.39 815.232 102.763 815.127 103.107 814.916C103.459 814.706 103.76 814.39 104.011 813.969C104.269 813.541 104.47 813.004 104.613 812.358C104.757 811.705 104.828 810.939 104.828 810.061Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M107.474 833.662C107.474 834.83 107.349 835.875 107.098 836.797C106.847 837.718 106.477 838.499 105.99 839.137C105.509 839.769 104.922 840.251 104.226 840.585C103.53 840.919 102.735 841.086 101.838 841.086C101.064 841.086 100.35 840.948 99.6979 840.672C99.0454 840.396 98.4825 839.965 98.0093 839.377C97.536 838.782 97.1667 838.023 96.9014 837.102C96.6361 836.18 96.5035 835.077 96.5035 833.792C96.5035 832.624 96.629 831.579 96.8799 830.657C97.1381 829.736 97.5073 828.959 97.9877 828.328C98.4682 827.689 99.0597 827.203 99.7624 826.869C100.465 826.535 101.261 826.368 102.15 826.368C102.925 826.368 103.638 826.506 104.291 826.782C104.943 827.058 105.502 827.489 105.968 828.077C106.442 828.665 106.811 829.42 107.076 830.341C107.342 831.263 107.474 832.37 107.474 833.662ZM99.1493 833.705C99.1493 833.85 99.1493 833.988 99.1493 834.119C99.1493 834.242 99.1565 834.369 99.1709 834.5L104.441 830.646C104.19 829.928 103.857 829.398 103.441 829.057C103.032 828.709 102.548 828.534 101.989 828.534C101.587 828.534 101.214 828.64 100.87 828.85C100.526 829.053 100.225 829.369 99.9668 829.797C99.7158 830.225 99.515 830.762 99.3645 831.408C99.2211 832.054 99.1493 832.82 99.1493 833.705ZM104.828 833.749C104.828 833.625 104.825 833.502 104.818 833.379C104.81 833.248 104.807 833.125 104.807 833.009L99.5688 836.84C99.7983 837.544 100.121 838.067 100.537 838.408C100.953 838.749 101.437 838.92 101.989 838.92C102.39 838.92 102.763 838.814 103.107 838.604C103.459 838.393 103.76 838.078 104.011 837.657C104.269 837.229 104.47 836.692 104.613 836.046C104.757 835.393 104.828 834.627 104.828 833.749Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M97.5683 864.523V862.161H101.15V853.017L98.0308 854.758L97.1703 852.592L101.634 850.208H103.839V862.161H106.926V864.523H97.5683Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M144.443 162.952H133.406V160.747H137.923V147.766L133.715 150.079L132.854 148.065L138.447 145.085H140.518V160.747H144.443V162.952Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 192.91H133.406V190.705H137.923V177.724L133.715 180.037L132.854 178.023L138.447 175.043H140.518V190.705H144.443V192.91Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 213.982C144.995 215.343 144.86 216.585 144.591 217.71C144.322 218.826 143.915 219.788 143.368 220.595C142.821 221.393 142.14 222.015 141.324 222.459C140.518 222.895 139.568 223.112 138.474 223.112C137.533 223.112 136.677 222.935 135.906 222.582C135.135 222.219 134.477 221.665 133.93 220.922C133.383 220.178 132.962 219.23 132.666 218.078C132.37 216.926 132.223 215.56 132.223 213.982C132.223 212.621 132.357 211.378 132.626 210.253C132.904 209.129 133.312 208.167 133.849 207.369C134.396 206.561 135.077 205.94 135.893 205.504C136.708 205.06 137.659 204.838 138.743 204.838C139.684 204.838 140.54 205.019 141.311 205.382C142.082 205.736 142.74 206.289 143.287 207.042C143.834 207.786 144.255 208.734 144.551 209.886C144.847 211.038 144.995 212.403 144.995 213.982ZM142.628 214.063C142.628 213.755 142.615 213.451 142.588 213.152C142.57 212.843 142.548 212.544 142.521 212.254L135.086 217.846C135.221 218.318 135.391 218.753 135.597 219.153C135.803 219.552 136.05 219.896 136.336 220.187C136.632 220.468 136.964 220.69 137.331 220.854C137.708 221.008 138.134 221.085 138.609 221.085C139.218 221.085 139.769 220.935 140.262 220.636C140.764 220.336 141.19 219.892 141.539 219.302C141.889 218.713 142.158 217.982 142.346 217.112C142.534 216.232 142.628 215.216 142.628 214.063ZM134.589 213.9C134.589 214.181 134.589 214.463 134.589 214.744C134.598 215.025 134.616 215.297 134.643 215.56L142.077 209.995C141.943 209.532 141.772 209.11 141.566 208.729C141.36 208.348 141.114 208.022 140.827 207.75C140.54 207.468 140.213 207.251 139.845 207.097C139.478 206.942 139.066 206.865 138.609 206.865C137.999 206.865 137.443 207.015 136.941 207.314C136.449 207.614 136.027 208.063 135.678 208.661C135.328 209.251 135.059 209.986 134.871 210.866C134.683 211.737 134.589 212.748 134.589 213.9Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 243.94C144.995 245.3 144.86 246.543 144.591 247.668C144.322 248.784 143.915 249.745 143.368 250.553C142.821 251.351 142.14 251.973 141.324 252.417C140.518 252.852 139.568 253.07 138.474 253.07C137.533 253.07 136.677 252.893 135.906 252.54C135.135 252.177 134.477 251.623 133.93 250.879C133.383 250.136 132.962 249.188 132.666 248.035C132.37 246.883 132.223 245.518 132.223 243.94C132.223 242.579 132.357 241.336 132.626 240.211C132.904 239.086 133.312 238.125 133.849 237.327C134.396 236.519 135.077 235.898 135.893 235.462C136.708 235.018 137.659 234.796 138.743 234.796C139.684 234.796 140.54 234.977 141.311 235.34C142.082 235.694 142.74 236.247 143.287 237C143.834 237.744 144.255 238.692 144.551 239.844C144.847 240.996 144.995 242.361 144.995 243.94ZM142.628 244.021C142.628 243.713 142.615 243.409 142.588 243.11C142.57 242.801 142.548 242.502 142.521 242.212L135.086 247.804C135.221 248.276 135.391 248.711 135.597 249.11C135.803 249.51 136.05 249.854 136.336 250.145C136.632 250.426 136.964 250.648 137.331 250.811C137.708 250.966 138.134 251.043 138.609 251.043C139.218 251.043 139.769 250.893 140.262 250.594C140.764 250.294 141.19 249.85 141.539 249.26C141.889 248.671 142.158 247.94 142.346 247.069C142.534 246.189 142.628 245.173 142.628 244.021ZM134.589 243.858C134.589 244.139 134.589 244.42 134.589 244.702C134.598 244.983 134.616 245.255 134.643 245.518L142.077 239.953C141.943 239.49 141.772 239.068 141.566 238.687C141.36 238.306 141.114 237.98 140.827 237.708C140.54 237.426 140.213 237.209 139.845 237.054C139.478 236.9 139.066 236.823 138.609 236.823C137.999 236.823 137.443 236.973 136.941 237.272C136.449 237.571 136.027 238.021 135.678 238.619C135.328 239.209 135.059 239.944 134.871 240.824C134.683 241.694 134.589 242.706 134.589 243.858Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 273.898C144.995 275.258 144.86 276.501 144.591 277.626C144.322 278.742 143.915 279.703 143.368 280.511C142.821 281.309 142.14 281.93 141.324 282.375C140.518 282.81 139.568 283.028 138.474 283.028C137.533 283.028 136.677 282.851 135.906 282.497C135.135 282.135 134.477 281.581 133.93 280.837C133.383 280.093 132.962 279.145 132.666 277.993C132.37 276.841 132.223 275.476 132.223 273.898C132.223 272.537 132.357 271.294 132.626 270.169C132.904 269.044 133.312 268.083 133.849 267.284C134.396 266.477 135.077 265.856 135.893 265.42C136.708 264.976 137.659 264.753 138.743 264.753C139.684 264.753 140.54 264.935 141.311 265.298C142.082 265.652 142.74 266.205 143.287 266.958C143.834 267.702 144.255 268.65 144.551 269.802C144.847 270.954 144.995 272.319 144.995 273.898ZM142.628 273.979C142.628 273.671 142.615 273.367 142.588 273.068C142.57 272.759 142.548 272.46 142.521 272.169L135.086 277.762C135.221 278.234 135.391 278.669 135.597 279.068C135.803 279.467 136.05 279.812 136.336 280.103C136.632 280.384 136.964 280.606 137.331 280.769C137.708 280.923 138.134 281.001 138.609 281.001C139.218 281.001 139.769 280.851 140.262 280.552C140.764 280.252 141.19 279.808 141.539 279.218C141.889 278.628 142.158 277.898 142.346 277.027C142.534 276.147 142.628 275.131 142.628 273.979ZM134.589 273.816C134.589 274.097 134.589 274.378 134.589 274.66C134.598 274.941 134.616 275.213 134.643 275.476L142.077 269.911C141.943 269.448 141.772 269.026 141.566 268.645C141.36 268.264 141.114 267.938 140.827 267.665C140.54 267.384 140.213 267.166 139.845 267.012C139.478 266.858 139.066 266.781 138.609 266.781C137.999 266.781 137.443 266.931 136.941 267.23C136.449 267.529 136.027 267.978 135.678 268.577C135.328 269.167 135.059 269.902 134.871 270.781C134.683 271.652 134.589 272.664 134.589 273.816Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 312.741H133.406V310.537H137.923V297.555L133.715 299.868L132.854 297.855L138.447 294.875H140.518V310.537H144.443V312.741Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 333.813C144.995 335.174 144.86 336.417 144.591 337.542C144.322 338.658 143.915 339.619 143.368 340.426C142.821 341.225 142.14 341.846 141.324 342.291C140.518 342.726 139.568 342.944 138.474 342.944C137.533 342.944 136.677 342.767 135.906 342.413C135.135 342.05 134.477 341.497 133.93 340.753C133.383 340.009 132.962 339.061 132.666 337.909C132.37 336.757 132.223 335.392 132.223 333.813C132.223 332.453 132.357 331.21 132.626 330.085C132.904 328.96 133.312 327.998 133.849 327.2C134.396 326.393 135.077 325.771 135.893 325.336C136.708 324.891 137.659 324.669 138.743 324.669C139.684 324.669 140.54 324.851 141.311 325.213C142.082 325.567 142.74 326.121 143.287 326.874C143.834 327.617 144.255 328.565 144.551 329.717C144.847 330.87 144.995 332.235 144.995 333.813ZM142.628 333.895C142.628 333.587 142.615 333.283 142.588 332.983C142.57 332.675 142.548 332.375 142.521 332.085L135.086 337.678C135.221 338.15 135.391 338.585 135.597 338.984C135.803 339.383 136.05 339.728 136.336 340.018C136.632 340.299 136.964 340.522 137.331 340.685C137.708 340.839 138.134 340.916 138.609 340.916C139.218 340.916 139.769 340.767 140.262 340.467C140.764 340.168 141.19 339.723 141.539 339.134C141.889 338.544 142.158 337.814 142.346 336.943C142.534 336.063 142.628 335.047 142.628 333.895ZM134.589 333.732C134.589 334.013 134.589 334.294 134.589 334.575C134.598 334.857 134.616 335.129 134.643 335.392L142.077 329.826C141.943 329.364 141.772 328.942 141.566 328.561C141.36 328.18 141.114 327.853 140.827 327.581C140.54 327.3 140.213 327.082 139.845 326.928C139.478 326.774 139.066 326.697 138.609 326.697C137.999 326.697 137.443 326.846 136.941 327.146C136.449 327.445 136.027 327.894 135.678 328.493C135.328 329.082 135.059 329.817 134.871 330.697C134.683 331.568 134.589 332.58 134.589 333.732Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 372.657H133.406V370.452H137.923V357.471L133.715 359.784L132.854 357.77L138.447 354.79H140.518V370.452H144.443V372.657Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 402.615H133.406V400.41H137.923V387.429L133.715 389.742L132.854 387.728L138.447 384.748H140.518V400.41H144.443V402.615Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 423.687C144.995 425.048 144.86 426.29 144.591 427.415C144.322 428.531 143.915 429.493 143.368 430.3C142.821 431.098 142.14 431.72 141.324 432.164C140.518 432.6 139.568 432.817 138.474 432.817C137.533 432.817 136.677 432.641 135.906 432.287C135.135 431.924 134.477 431.371 133.93 430.627C133.383 429.883 132.962 428.935 132.666 427.783C132.37 426.631 132.223 425.265 132.223 423.687C132.223 422.326 132.357 421.083 132.626 419.958C132.904 418.834 133.312 417.872 133.849 417.074C134.396 416.266 135.077 415.645 135.893 415.21C136.708 414.765 137.659 414.543 138.743 414.543C139.684 414.543 140.54 414.724 141.311 415.087C142.082 415.441 142.74 415.994 143.287 416.747C143.834 417.491 144.255 418.439 144.551 419.591C144.847 420.743 144.995 422.108 144.995 423.687ZM142.628 423.769C142.628 423.46 142.615 423.156 142.588 422.857C142.57 422.548 142.548 422.249 142.521 421.959L135.086 427.551C135.221 428.023 135.391 428.459 135.597 428.858C135.803 429.257 136.05 429.602 136.336 429.892C136.632 430.173 136.964 430.395 137.331 430.559C137.708 430.713 138.134 430.79 138.609 430.79C139.218 430.79 139.769 430.64 140.262 430.341C140.764 430.042 141.19 429.597 141.539 429.007C141.889 428.418 142.158 427.687 142.346 426.817C142.534 425.937 142.628 424.921 142.628 423.769ZM134.589 423.605C134.589 423.886 134.589 424.168 134.589 424.449C134.598 424.73 134.616 425.002 134.643 425.265L142.077 419.7C141.943 419.237 141.772 418.815 141.566 418.434C141.36 418.053 141.114 417.727 140.827 417.455C140.54 417.174 140.213 416.956 139.845 416.802C139.478 416.647 139.066 416.57 138.609 416.57C137.999 416.57 137.443 416.72 136.941 417.019C136.449 417.319 136.027 417.768 135.678 418.366C135.328 418.956 135.059 419.691 134.871 420.571C134.683 421.442 134.589 422.453 134.589 423.605Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 453.645C144.995 455.006 144.86 456.248 144.591 457.373C144.322 458.489 143.915 459.451 143.368 460.258C142.821 461.056 142.14 461.678 141.324 462.122C140.518 462.558 139.568 462.775 138.474 462.775C137.533 462.775 136.677 462.598 135.906 462.245C135.135 461.882 134.477 461.328 133.93 460.585C133.383 459.841 132.962 458.893 132.666 457.741C132.37 456.589 132.223 455.223 132.223 453.645C132.223 452.284 132.357 451.041 132.626 449.916C132.904 448.792 133.312 447.83 133.849 447.032C134.396 446.224 135.077 445.603 135.893 445.167C136.708 444.723 137.659 444.501 138.743 444.501C139.684 444.501 140.54 444.682 141.311 445.045C142.082 445.399 142.74 445.952 143.287 446.705C143.834 447.449 144.255 448.397 144.551 449.549C144.847 450.701 144.995 452.066 144.995 453.645ZM142.628 453.726C142.628 453.418 142.615 453.114 142.588 452.815C142.57 452.506 142.548 452.207 142.521 451.917L135.086 457.509C135.221 457.981 135.391 458.416 135.597 458.816C135.803 459.215 136.05 459.559 136.336 459.85C136.632 460.131 136.964 460.353 137.331 460.516C137.708 460.671 138.134 460.748 138.609 460.748C139.218 460.748 139.769 460.598 140.262 460.299C140.764 459.999 141.19 459.555 141.539 458.965C141.889 458.376 142.158 457.645 142.346 456.774C142.534 455.895 142.628 454.879 142.628 453.726ZM134.589 453.563C134.589 453.844 134.589 454.126 134.589 454.407C134.598 454.688 134.616 454.96 134.643 455.223L142.077 449.658C141.943 449.195 141.772 448.773 141.566 448.392C141.36 448.011 141.114 447.685 140.827 447.413C140.54 447.131 140.213 446.914 139.845 446.759C139.478 446.605 139.066 446.528 138.609 446.528C137.999 446.528 137.443 446.678 136.941 446.977C136.449 447.277 136.027 447.726 135.678 448.324C135.328 448.914 135.059 449.649 134.871 450.529C134.683 451.4 134.589 452.411 134.589 453.563Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 483.603C144.995 484.963 144.86 486.206 144.591 487.331C144.322 488.447 143.915 489.408 143.368 490.216C142.821 491.014 142.14 491.635 141.324 492.08C140.518 492.515 139.568 492.733 138.474 492.733C137.533 492.733 136.677 492.556 135.906 492.202C135.135 491.84 134.477 491.286 133.93 490.542C133.383 489.799 132.962 488.851 132.666 487.698C132.37 486.546 132.223 485.181 132.223 483.603C132.223 482.242 132.357 480.999 132.626 479.874C132.904 478.749 133.312 477.788 133.849 476.99C134.396 476.182 135.077 475.561 135.893 475.125C136.708 474.681 137.659 474.459 138.743 474.459C139.684 474.459 140.54 474.64 141.311 475.003C142.082 475.357 142.74 475.91 143.287 476.663C143.834 477.407 144.255 478.355 144.551 479.507C144.847 480.659 144.995 482.024 144.995 483.603ZM142.628 483.684C142.628 483.376 142.615 483.072 142.588 482.773C142.57 482.464 142.548 482.165 142.521 481.875L135.086 487.467C135.221 487.939 135.391 488.374 135.597 488.773C135.803 489.173 136.05 489.517 136.336 489.808C136.632 490.089 136.964 490.311 137.331 490.474C137.708 490.629 138.134 490.706 138.609 490.706C139.218 490.706 139.769 490.556 140.262 490.257C140.764 489.957 141.19 489.513 141.539 488.923C141.889 488.333 142.158 487.603 142.346 486.732C142.534 485.852 142.628 484.836 142.628 483.684ZM134.589 483.521C134.589 483.802 134.589 484.083 134.589 484.365C134.598 484.646 134.616 484.918 134.643 485.181L142.077 479.616C141.943 479.153 141.772 478.731 141.566 478.35C141.36 477.969 141.114 477.643 140.827 477.37C140.54 477.089 140.213 476.872 139.845 476.717C139.478 476.563 139.066 476.486 138.609 476.486C137.999 476.486 137.443 476.636 136.941 476.935C136.449 477.234 136.027 477.683 135.678 478.282C135.328 478.872 135.059 479.607 134.871 480.487C134.683 481.357 134.589 482.369 134.589 483.521Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 522.446H133.406V520.242H137.923V507.26L133.715 509.574L132.854 507.56L138.447 504.58H140.518V520.242H144.443V522.446Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 552.404H133.406V550.2H137.923V537.218L133.715 539.531L132.854 537.518L138.447 534.538H140.518V550.2H144.443V552.404Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 582.362H133.406V580.157H137.923V567.176L133.715 569.489L132.854 567.475L138.447 564.495H140.518V580.157H144.443V582.362Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 603.434C144.995 604.795 144.86 606.038 144.591 607.163C144.322 608.278 143.915 609.24 143.368 610.047C142.821 610.846 142.14 611.467 141.324 611.911C140.518 612.347 139.568 612.565 138.474 612.565C137.533 612.565 136.677 612.388 135.906 612.034C135.135 611.671 134.477 611.118 133.93 610.374C133.383 609.63 132.962 608.682 132.666 607.53C132.37 606.378 132.223 605.013 132.223 603.434C132.223 602.073 132.357 600.831 132.626 599.706C132.904 598.581 133.312 597.619 133.849 596.821C134.396 596.014 135.077 595.392 135.893 594.957C136.708 594.512 137.659 594.29 138.743 594.29C139.684 594.29 140.54 594.471 141.311 594.834C142.082 595.188 142.74 595.741 143.287 596.494C143.834 597.238 144.255 598.186 144.551 599.338C144.847 600.49 144.995 601.856 144.995 603.434ZM142.628 603.516C142.628 603.207 142.615 602.903 142.588 602.604C142.57 602.296 142.548 601.996 142.521 601.706L135.086 607.299C135.221 607.77 135.391 608.206 135.597 608.605C135.803 609.004 136.05 609.349 136.336 609.639C136.632 609.92 136.964 610.143 137.331 610.306C137.708 610.46 138.134 610.537 138.609 610.537C139.218 610.537 139.769 610.387 140.262 610.088C140.764 609.789 141.19 609.344 141.539 608.755C141.889 608.165 142.158 607.435 142.346 606.564C142.534 605.684 142.628 604.668 142.628 603.516ZM134.589 603.352C134.589 603.634 134.589 603.915 134.589 604.196C134.598 604.477 134.616 604.749 134.643 605.013L142.077 599.447C141.943 598.985 141.772 598.563 141.566 598.182C141.36 597.801 141.114 597.474 140.827 597.202C140.54 596.921 140.213 596.703 139.845 596.549C139.478 596.395 139.066 596.318 138.609 596.318C137.999 596.318 137.443 596.467 136.941 596.767C136.449 597.066 136.027 597.515 135.678 598.114C135.328 598.703 135.059 599.438 134.871 600.318C134.683 601.189 134.589 602.2 134.589 603.352Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 642.278H133.406V640.073H137.923V627.092L133.715 629.405L132.854 627.391L138.447 624.411H140.518V640.073H144.443V642.278Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 663.35C144.995 664.711 144.86 665.953 144.591 667.078C144.322 668.194 143.915 669.156 143.368 669.963C142.821 670.761 142.14 671.383 141.324 671.827C140.518 672.263 139.568 672.48 138.474 672.48C137.533 672.48 136.677 672.303 135.906 671.95C135.135 671.587 134.477 671.033 133.93 670.29C133.383 669.546 132.962 668.598 132.666 667.446C132.37 666.294 132.223 664.928 132.223 663.35C132.223 661.989 132.357 660.746 132.626 659.621C132.904 658.497 133.312 657.535 133.849 656.737C134.396 655.929 135.077 655.308 135.893 654.872C136.708 654.428 137.659 654.206 138.743 654.206C139.684 654.206 140.54 654.387 141.311 654.75C142.082 655.104 142.74 655.657 143.287 656.41C143.834 657.154 144.255 658.102 144.551 659.254C144.847 660.406 144.995 661.771 144.995 663.35ZM142.628 663.432C142.628 663.123 142.615 662.819 142.588 662.52C142.57 662.211 142.548 661.912 142.521 661.622L135.086 667.214C135.221 667.686 135.391 668.121 135.597 668.521C135.803 668.92 136.05 669.265 136.336 669.555C136.632 669.836 136.964 670.058 137.331 670.222C137.708 670.376 138.134 670.453 138.609 670.453C139.218 670.453 139.769 670.303 140.262 670.004C140.764 669.704 141.19 669.26 141.539 668.67C141.889 668.081 142.158 667.35 142.346 666.48C142.534 665.6 142.628 664.584 142.628 663.432ZM134.589 663.268C134.589 663.549 134.589 663.831 134.589 664.112C134.598 664.393 134.616 664.665 134.643 664.928L142.077 659.363C141.943 658.9 141.772 658.478 141.566 658.097C141.36 657.716 141.114 657.39 140.827 657.118C140.54 656.836 140.213 656.619 139.845 656.465C139.478 656.31 139.066 656.233 138.609 656.233C137.999 656.233 137.443 656.383 136.941 656.682C136.449 656.982 136.027 657.431 135.678 658.029C135.328 658.619 135.059 659.354 134.871 660.234C134.683 661.105 134.589 662.116 134.589 663.268Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.995 693.308C144.995 694.668 144.86 695.911 144.591 697.036C144.322 698.152 143.915 699.114 143.368 699.921C142.821 700.719 142.14 701.341 141.324 701.785C140.518 702.221 139.568 702.438 138.474 702.438C137.533 702.438 136.677 702.261 135.906 701.908C135.135 701.545 134.477 700.991 133.93 700.247C133.383 699.504 132.962 698.556 132.666 697.404C132.37 696.251 132.223 694.886 132.223 693.308C132.223 691.947 132.357 690.704 132.626 689.579C132.904 688.454 133.312 687.493 133.849 686.695C134.396 685.887 135.077 685.266 135.893 684.83C136.708 684.386 137.659 684.164 138.743 684.164C139.684 684.164 140.54 684.345 141.311 684.708C142.082 685.062 142.74 685.615 143.287 686.368C143.834 687.112 144.255 688.06 144.551 689.212C144.847 690.364 144.995 691.729 144.995 693.308ZM142.628 693.389C142.628 693.081 142.615 692.777 142.588 692.478C142.57 692.169 142.548 691.87 142.521 691.58L135.086 697.172C135.221 697.644 135.391 698.079 135.597 698.479C135.803 698.878 136.05 699.222 136.336 699.513C136.632 699.794 136.964 700.016 137.331 700.179C137.708 700.334 138.134 700.411 138.609 700.411C139.218 700.411 139.769 700.261 140.262 699.962C140.764 699.662 141.19 699.218 141.539 698.628C141.889 698.039 142.158 697.308 142.346 696.437C142.534 695.557 142.628 694.541 142.628 693.389ZM134.589 693.226C134.589 693.507 134.589 693.789 134.589 694.07C134.598 694.351 134.616 694.623 134.643 694.886L142.077 689.321C141.943 688.858 141.772 688.436 141.566 688.055C141.36 687.674 141.114 687.348 140.827 687.076C140.54 686.794 140.213 686.577 139.845 686.422C139.478 686.268 139.066 686.191 138.609 686.191C137.999 686.191 137.443 686.341 136.941 686.64C136.449 686.94 136.027 687.389 135.678 687.987C135.328 688.577 135.059 689.312 134.871 690.192C134.683 691.062 134.589 692.074 134.589 693.226Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M144.443 732.151H133.406V729.947H137.923V716.965L133.715 719.279L132.854 717.265L138.447 714.285H140.518V729.947H144.443V732.151Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 401.221H263.149V399.347H266.989V388.313L263.412 390.28L262.681 388.568L267.435 386.035H269.195V399.347H272.531V401.221Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 426.302H263.149V424.428H266.989V413.394L263.412 415.361L262.681 413.649L267.435 411.116H269.195V424.428H272.531V426.302Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 443.83C273 444.987 272.886 446.043 272.657 447C272.429 447.948 272.082 448.765 271.617 449.452C271.153 450.13 270.574 450.658 269.88 451.036C269.195 451.406 268.387 451.591 267.458 451.591C266.658 451.591 265.93 451.441 265.275 451.14C264.62 450.832 264.06 450.362 263.595 449.729C263.13 449.097 262.772 448.291 262.521 447.312C262.269 446.333 262.144 445.172 262.144 443.83C262.144 442.674 262.258 441.617 262.486 440.661C262.723 439.705 263.069 438.888 263.526 438.209C263.991 437.523 264.57 436.995 265.263 436.625C265.957 436.247 266.764 436.058 267.686 436.058C268.486 436.058 269.214 436.212 269.869 436.521C270.524 436.821 271.084 437.292 271.549 437.932C272.013 438.564 272.371 439.37 272.623 440.349C272.874 441.328 273 442.489 273 443.83ZM270.989 443.9C270.989 443.638 270.977 443.379 270.954 443.125C270.939 442.863 270.92 442.608 270.897 442.362L264.578 447.115C264.692 447.516 264.837 447.886 265.012 448.226C265.187 448.565 265.397 448.858 265.641 449.105C265.892 449.344 266.174 449.533 266.486 449.671C266.806 449.802 267.168 449.868 267.572 449.868C268.09 449.868 268.558 449.741 268.977 449.486C269.404 449.232 269.766 448.854 270.063 448.353C270.36 447.852 270.589 447.231 270.749 446.491C270.909 445.743 270.989 444.879 270.989 443.9ZM264.155 443.761C264.155 444 264.155 444.239 264.155 444.478C264.163 444.717 264.178 444.949 264.201 445.172L270.52 440.442C270.406 440.048 270.261 439.69 270.086 439.366C269.911 439.042 269.701 438.764 269.457 438.533C269.214 438.294 268.936 438.109 268.623 437.978C268.311 437.847 267.96 437.781 267.572 437.781C267.054 437.781 266.581 437.909 266.155 438.163C265.736 438.417 265.378 438.799 265.081 439.308C264.783 439.809 264.555 440.434 264.395 441.182C264.235 441.922 264.155 442.782 264.155 443.761Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 468.911C273 470.068 272.886 471.124 272.657 472.081C272.429 473.029 272.082 473.846 271.617 474.533C271.153 475.211 270.574 475.739 269.88 476.117C269.195 476.487 268.387 476.672 267.458 476.672C266.658 476.672 265.93 476.522 265.275 476.221C264.62 475.913 264.06 475.443 263.595 474.81C263.13 474.178 262.772 473.372 262.521 472.393C262.269 471.414 262.144 470.253 262.144 468.911C262.144 467.755 262.258 466.698 262.486 465.742C262.723 464.786 263.069 463.969 263.526 463.29C263.991 462.604 264.57 462.076 265.263 461.706C265.957 461.328 266.764 461.139 267.686 461.139C268.486 461.139 269.214 461.293 269.869 461.602C270.524 461.902 271.084 462.373 271.549 463.013C272.013 463.645 272.371 464.451 272.623 465.43C272.874 466.409 273 467.57 273 468.911ZM270.989 468.981C270.989 468.719 270.977 468.46 270.954 468.206C270.939 467.944 270.92 467.689 270.897 467.443L264.578 472.196C264.692 472.597 264.837 472.967 265.012 473.307C265.187 473.646 265.397 473.939 265.641 474.186C265.892 474.425 266.174 474.614 266.486 474.752C266.806 474.883 267.168 474.949 267.572 474.949C268.09 474.949 268.558 474.822 268.977 474.567C269.404 474.313 269.766 473.935 270.063 473.434C270.36 472.933 270.589 472.312 270.749 471.572C270.909 470.824 270.989 469.96 270.989 468.981ZM264.155 468.842C264.155 469.081 264.155 469.32 264.155 469.559C264.163 469.798 264.178 470.03 264.201 470.253L270.52 465.523C270.406 465.129 270.261 464.771 270.086 464.447C269.911 464.123 269.701 463.845 269.457 463.614C269.214 463.375 268.936 463.19 268.623 463.059C268.311 462.928 267.96 462.862 267.572 462.862C267.054 462.862 266.581 462.99 266.155 463.244C265.736 463.498 265.378 463.88 265.081 464.389C264.783 464.89 264.555 465.515 264.395 466.263C264.235 467.003 264.155 467.863 264.155 468.842Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 493.992C273 495.149 272.886 496.205 272.657 497.162C272.429 498.11 272.082 498.927 271.617 499.614C271.153 500.292 270.574 500.82 269.88 501.198C269.195 501.568 268.387 501.753 267.458 501.753C266.658 501.753 265.93 501.603 265.275 501.302C264.62 500.994 264.06 500.524 263.595 499.891C263.13 499.259 262.772 498.453 262.521 497.474C262.269 496.495 262.144 495.334 262.144 493.992C262.144 492.836 262.258 491.779 262.486 490.823C262.723 489.867 263.069 489.05 263.526 488.371C263.991 487.685 264.57 487.157 265.263 486.787C265.957 486.409 266.764 486.22 267.686 486.22C268.486 486.22 269.214 486.374 269.869 486.683C270.524 486.983 271.084 487.454 271.549 488.094C272.013 488.726 272.371 489.532 272.623 490.511C272.874 491.49 273 492.651 273 493.992ZM270.989 494.062C270.989 493.8 270.977 493.541 270.954 493.287C270.939 493.025 270.92 492.77 270.897 492.524L264.578 497.277C264.692 497.678 264.837 498.048 265.012 498.388C265.187 498.727 265.397 499.02 265.641 499.267C265.892 499.506 266.174 499.695 266.486 499.833C266.806 499.964 267.168 500.03 267.572 500.03C268.09 500.03 268.558 499.903 268.977 499.648C269.404 499.394 269.766 499.016 270.063 498.515C270.36 498.014 270.589 497.393 270.749 496.653C270.909 495.905 270.989 495.041 270.989 494.062ZM264.155 493.923C264.155 494.162 264.155 494.401 264.155 494.64C264.163 494.879 264.178 495.111 264.201 495.334L270.52 490.604C270.406 490.21 270.261 489.852 270.086 489.528C269.911 489.204 269.701 488.926 269.457 488.695C269.214 488.456 268.936 488.271 268.623 488.14C268.311 488.009 267.96 487.943 267.572 487.943C267.054 487.943 266.581 488.071 266.155 488.325C265.736 488.579 265.378 488.961 265.081 489.47C264.783 489.971 264.555 490.596 264.395 491.344C264.235 492.084 264.155 492.944 264.155 493.923Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 526.626H263.149V524.752H266.989V513.718L263.412 515.685L262.681 513.973L267.435 511.44H269.195V524.752H272.531V526.626Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 544.154C273 545.311 272.886 546.367 272.657 547.324C272.429 548.272 272.082 549.089 271.617 549.776C271.153 550.454 270.574 550.982 269.88 551.36C269.195 551.73 268.387 551.915 267.458 551.915C266.658 551.915 265.93 551.765 265.275 551.464C264.62 551.156 264.06 550.686 263.595 550.053C263.13 549.421 262.772 548.615 262.521 547.636C262.269 546.657 262.144 545.496 262.144 544.154C262.144 542.998 262.258 541.941 262.486 540.985C262.723 540.029 263.069 539.212 263.526 538.533C263.991 537.847 264.57 537.319 265.263 536.949C265.957 536.571 266.764 536.382 267.686 536.382C268.486 536.382 269.214 536.536 269.869 536.845C270.524 537.145 271.084 537.616 271.549 538.256C272.013 538.888 272.371 539.694 272.623 540.673C272.874 541.652 273 542.813 273 544.154ZM270.989 544.224C270.989 543.962 270.977 543.703 270.954 543.449C270.939 543.187 270.92 542.932 270.897 542.686L264.578 547.439C264.692 547.84 264.837 548.21 265.012 548.55C265.187 548.889 265.397 549.182 265.641 549.429C265.892 549.668 266.174 549.857 266.486 549.995C266.806 550.127 267.168 550.192 267.572 550.192C268.09 550.192 268.558 550.065 268.977 549.81C269.404 549.556 269.766 549.178 270.063 548.677C270.36 548.176 270.589 547.555 270.749 546.815C270.909 546.067 270.989 545.203 270.989 544.224ZM264.155 544.085C264.155 544.324 264.155 544.563 264.155 544.802C264.163 545.041 264.178 545.273 264.201 545.496L270.52 540.766C270.406 540.372 270.261 540.014 270.086 539.69C269.911 539.366 269.701 539.088 269.457 538.857C269.214 538.618 268.936 538.433 268.623 538.302C268.311 538.171 267.96 538.105 267.572 538.105C267.054 538.105 266.581 538.233 266.155 538.487C265.736 538.741 265.378 539.123 265.081 539.632C264.783 540.133 264.555 540.758 264.395 541.506C264.235 542.246 264.155 543.106 264.155 544.085Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 576.788H263.149V574.915H266.989V563.88L263.412 565.847L262.681 564.135L267.435 561.602H269.195V574.915H272.531V576.788Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 601.869H263.149V599.996H266.989V588.961L263.412 590.928L262.681 589.216L267.435 586.683H269.195V599.996H272.531V601.869Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 619.398C273 620.554 272.886 621.611 272.657 622.567C272.429 623.515 272.082 624.332 271.617 625.019C271.153 625.697 270.574 626.225 269.88 626.603C269.195 626.973 268.387 627.158 267.458 627.158C266.658 627.158 265.93 627.008 265.275 626.707C264.62 626.399 264.06 625.929 263.595 625.296C263.13 624.664 262.772 623.858 262.521 622.879C262.269 621.9 262.144 620.739 262.144 619.398C262.144 618.241 262.258 617.185 262.486 616.228C262.723 615.272 263.069 614.455 263.526 613.776C263.991 613.09 264.57 612.562 265.263 612.192C265.957 611.814 266.764 611.625 267.686 611.625C268.486 611.625 269.214 611.779 269.869 612.088C270.524 612.388 271.084 612.859 271.549 613.499C272.013 614.131 272.371 614.937 272.623 615.916C272.874 616.895 273 618.056 273 619.398ZM270.989 619.467C270.989 619.205 270.977 618.946 270.954 618.692C270.939 618.43 270.92 618.175 270.897 617.929L264.578 622.682C264.692 623.083 264.837 623.453 265.012 623.793C265.187 624.132 265.397 624.425 265.641 624.672C265.892 624.911 266.174 625.1 266.486 625.238C266.806 625.37 267.168 625.435 267.572 625.435C268.09 625.435 268.558 625.308 268.977 625.053C269.404 624.799 269.766 624.421 270.063 623.92C270.36 623.419 270.589 622.798 270.749 622.058C270.909 621.31 270.989 620.446 270.989 619.467ZM264.155 619.328C264.155 619.567 264.155 619.806 264.155 620.045C264.163 620.284 264.178 620.516 264.201 620.739L270.52 616.009C270.406 615.615 270.261 615.257 270.086 614.933C269.911 614.609 269.701 614.331 269.457 614.1C269.214 613.861 268.936 613.676 268.623 613.545C268.311 613.414 267.96 613.348 267.572 613.348C267.054 613.348 266.581 613.476 266.155 613.73C265.736 613.985 265.378 614.366 265.081 614.875C264.783 615.376 264.555 616.001 264.395 616.749C264.235 617.489 264.155 618.349 264.155 619.328Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 644.479C273 645.635 272.886 646.692 272.657 647.648C272.429 648.596 272.082 649.413 271.617 650.1C271.153 650.778 270.574 651.306 269.88 651.684C269.195 652.054 268.387 652.239 267.458 652.239C266.658 652.239 265.93 652.089 265.275 651.788C264.62 651.48 264.06 651.01 263.595 650.377C263.13 649.745 262.772 648.939 262.521 647.96C262.269 646.981 262.144 645.82 262.144 644.479C262.144 643.322 262.258 642.266 262.486 641.309C262.723 640.353 263.069 639.536 263.526 638.857C263.991 638.171 264.57 637.643 265.263 637.273C265.957 636.895 266.764 636.706 267.686 636.706C268.486 636.706 269.214 636.86 269.869 637.169C270.524 637.469 271.084 637.94 271.549 638.58C272.013 639.212 272.371 640.018 272.623 640.997C272.874 641.976 273 643.137 273 644.479ZM270.989 644.548C270.989 644.286 270.977 644.027 270.954 643.773C270.939 643.511 270.92 643.256 270.897 643.01L264.578 647.763C264.692 648.164 264.837 648.534 265.012 648.874C265.187 649.213 265.397 649.506 265.641 649.753C265.892 649.992 266.174 650.181 266.486 650.319C266.806 650.451 267.168 650.516 267.572 650.516C268.09 650.516 268.558 650.389 268.977 650.134C269.404 649.88 269.766 649.502 270.063 649.001C270.36 648.5 270.589 647.879 270.749 647.139C270.909 646.391 270.989 645.527 270.989 644.548ZM264.155 644.409C264.155 644.648 264.155 644.887 264.155 645.126C264.163 645.365 264.178 645.597 264.201 645.82L270.52 641.09C270.406 640.696 270.261 640.338 270.086 640.014C269.911 639.69 269.701 639.413 269.457 639.181C269.214 638.942 268.936 638.757 268.623 638.626C268.311 638.495 267.96 638.429 267.572 638.429C267.054 638.429 266.581 638.557 266.155 638.811C265.736 639.066 265.378 639.447 265.081 639.956C264.783 640.457 264.555 641.082 264.395 641.83C264.235 642.57 264.155 643.43 264.155 644.409Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 669.56C273 670.716 272.886 671.773 272.657 672.729C272.429 673.677 272.082 674.494 271.617 675.181C271.153 675.859 270.574 676.387 269.88 676.765C269.195 677.135 268.387 677.32 267.458 677.32C266.658 677.32 265.93 677.17 265.275 676.869C264.62 676.561 264.06 676.091 263.595 675.458C263.13 674.826 262.772 674.02 262.521 673.041C262.269 672.062 262.144 670.901 262.144 669.56C262.144 668.403 262.258 667.347 262.486 666.39C262.723 665.434 263.069 664.617 263.526 663.938C263.991 663.252 264.57 662.724 265.263 662.354C265.957 661.976 266.764 661.787 267.686 661.787C268.486 661.787 269.214 661.941 269.869 662.25C270.524 662.55 271.084 663.021 271.549 663.661C272.013 664.293 272.371 665.099 272.623 666.078C272.874 667.057 273 668.218 273 669.56ZM270.989 669.629C270.989 669.367 270.977 669.108 270.954 668.854C270.939 668.592 270.92 668.337 270.897 668.091L264.578 672.844C264.692 673.245 264.837 673.615 265.012 673.955C265.187 674.294 265.397 674.587 265.641 674.834C265.892 675.073 266.174 675.262 266.486 675.4C266.806 675.532 267.168 675.597 267.572 675.597C268.09 675.597 268.558 675.47 268.977 675.215C269.404 674.961 269.766 674.583 270.063 674.082C270.36 673.581 270.589 672.96 270.749 672.22C270.909 671.472 270.989 670.608 270.989 669.629ZM264.155 669.49C264.155 669.729 264.155 669.968 264.155 670.207C264.163 670.446 264.178 670.678 264.201 670.901L270.52 666.171C270.406 665.777 270.261 665.419 270.086 665.095C269.911 664.771 269.701 664.494 269.457 664.262C269.214 664.023 268.936 663.838 268.623 663.707C268.311 663.576 267.96 663.51 267.572 663.51C267.054 663.51 266.581 663.638 266.155 663.892C265.736 664.147 265.378 664.528 265.081 665.037C264.783 665.538 264.555 666.163 264.395 666.911C264.235 667.651 264.155 668.511 264.155 669.49Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 702.193H263.149V700.32H266.989V689.285L263.412 691.252L262.681 689.54L267.435 687.007H269.195V700.32H272.531V702.193Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 727.274H263.149V725.401H266.989V714.366L263.412 716.333L262.681 714.621L267.435 712.088H269.195V725.401H272.531V727.274Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 752.355H263.149V750.482H266.989V739.447L263.412 741.414L262.681 739.702L267.435 737.169H269.195V750.482H272.531V752.355Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 769.884C273 771.04 272.886 772.097 272.657 773.053C272.429 774.001 272.082 774.818 271.617 775.505C271.153 776.183 270.574 776.711 269.88 777.089C269.195 777.459 268.387 777.645 267.458 777.645C266.658 777.645 265.93 777.494 265.275 777.193C264.62 776.885 264.06 776.415 263.595 775.782C263.13 775.15 262.772 774.344 262.521 773.365C262.269 772.386 262.144 771.225 262.144 769.884C262.144 768.727 262.258 767.671 262.486 766.714C262.723 765.758 263.069 764.941 263.526 764.262C263.991 763.576 264.57 763.048 265.263 762.678C265.957 762.3 266.764 762.111 267.686 762.111C268.486 762.111 269.214 762.265 269.869 762.574C270.524 762.874 271.084 763.345 271.549 763.985C272.013 764.617 272.371 765.423 272.623 766.402C272.874 767.381 273 768.542 273 769.884ZM270.989 769.953C270.989 769.691 270.977 769.432 270.954 769.178C270.939 768.916 270.92 768.661 270.897 768.415L264.578 773.168C264.692 773.569 264.837 773.939 265.012 774.279C265.187 774.618 265.397 774.911 265.641 775.158C265.892 775.397 266.174 775.586 266.486 775.724C266.806 775.856 267.168 775.921 267.572 775.921C268.09 775.921 268.558 775.794 268.977 775.539C269.404 775.285 269.766 774.907 270.063 774.406C270.36 773.905 270.589 773.284 270.749 772.544C270.909 771.796 270.989 770.932 270.989 769.953ZM264.155 769.814C264.155 770.053 264.155 770.292 264.155 770.531C264.163 770.77 264.178 771.002 264.201 771.225L270.52 766.495C270.406 766.101 270.261 765.743 270.086 765.419C269.911 765.095 269.701 764.818 269.457 764.586C269.214 764.347 268.936 764.162 268.623 764.031C268.311 763.9 267.96 763.834 267.572 763.834C267.054 763.834 266.581 763.962 266.155 764.216C265.736 764.471 265.378 764.852 265.081 765.361C264.783 765.862 264.555 766.487 264.395 767.235C264.235 767.975 264.155 768.835 264.155 769.814Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 802.517H263.149V800.644H266.989V789.609L263.412 791.576L262.681 789.864L267.435 787.331H269.195V800.644H272.531V802.517Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 820.046C273 821.202 272.886 822.259 272.657 823.215C272.429 824.163 272.082 824.98 271.617 825.667C271.153 826.345 270.574 826.874 269.88 827.251C269.195 827.621 268.387 827.807 267.458 827.807C266.658 827.807 265.93 827.656 265.275 827.355C264.62 827.047 264.06 826.577 263.595 825.944C263.13 825.312 262.772 824.506 262.521 823.527C262.269 822.548 262.144 821.387 262.144 820.046C262.144 818.889 262.258 817.833 262.486 816.876C262.723 815.92 263.069 815.103 263.526 814.424C263.991 813.738 264.57 813.21 265.263 812.84C265.957 812.462 266.764 812.273 267.686 812.273C268.486 812.273 269.214 812.427 269.869 812.736C270.524 813.036 271.084 813.507 271.549 814.147C272.013 814.779 272.371 815.585 272.623 816.564C272.874 817.543 273 818.704 273 820.046ZM270.989 820.115C270.989 819.853 270.977 819.594 270.954 819.34C270.939 819.078 270.92 818.823 270.897 818.577L264.578 823.33C264.692 823.731 264.837 824.101 265.012 824.441C265.187 824.78 265.397 825.073 265.641 825.32C265.892 825.559 266.174 825.748 266.486 825.887C266.806 826.018 267.168 826.083 267.572 826.083C268.09 826.083 268.558 825.956 268.977 825.701C269.404 825.447 269.766 825.069 270.063 824.568C270.36 824.067 270.589 823.446 270.749 822.706C270.909 821.958 270.989 821.094 270.989 820.115ZM264.155 819.976C264.155 820.215 264.155 820.454 264.155 820.693C264.163 820.932 264.178 821.164 264.201 821.387L270.52 816.657C270.406 816.263 270.261 815.905 270.086 815.581C269.911 815.257 269.701 814.98 269.457 814.748C269.214 814.509 268.936 814.324 268.623 814.193C268.311 814.062 267.96 813.996 267.572 813.996C267.054 813.996 266.581 814.124 266.155 814.378C265.736 814.633 265.378 815.014 265.081 815.523C264.783 816.024 264.555 816.649 264.395 817.397C264.235 818.137 264.155 818.997 264.155 819.976Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M273 845.127C273 846.283 272.886 847.34 272.657 848.296C272.429 849.244 272.082 850.061 271.617 850.748C271.153 851.426 270.574 851.954 269.88 852.332C269.195 852.702 268.387 852.888 267.458 852.888C266.658 852.888 265.93 852.737 265.275 852.436C264.62 852.128 264.06 851.658 263.595 851.025C263.13 850.393 262.772 849.587 262.521 848.608C262.269 847.629 262.144 846.468 262.144 845.127C262.144 843.97 262.258 842.914 262.486 841.957C262.723 841.001 263.069 840.184 263.526 839.505C263.991 838.819 264.57 838.291 265.263 837.921C265.957 837.543 266.764 837.354 267.686 837.354C268.486 837.354 269.214 837.508 269.869 837.817C270.524 838.117 271.084 838.588 271.549 839.228C272.013 839.86 272.371 840.666 272.623 841.645C272.874 842.624 273 843.785 273 845.127ZM270.989 845.196C270.989 844.934 270.977 844.676 270.954 844.421C270.939 844.159 270.92 843.904 270.897 843.658L264.578 848.411C264.692 848.812 264.837 849.182 265.012 849.522C265.187 849.861 265.397 850.154 265.641 850.401C265.892 850.64 266.174 850.829 266.486 850.968C266.806 851.099 267.168 851.164 267.572 851.164C268.09 851.164 268.558 851.037 268.977 850.782C269.404 850.528 269.766 850.15 270.063 849.649C270.36 849.148 270.589 848.527 270.749 847.787C270.909 847.039 270.989 846.175 270.989 845.196ZM264.155 845.057C264.155 845.296 264.155 845.535 264.155 845.774C264.163 846.013 264.178 846.245 264.201 846.468L270.52 841.738C270.406 841.344 270.261 840.986 270.086 840.662C269.911 840.338 269.701 840.061 269.457 839.829C269.214 839.59 268.936 839.405 268.623 839.274C268.311 839.143 267.96 839.077 267.572 839.077C267.054 839.077 266.581 839.205 266.155 839.459C265.736 839.714 265.378 840.095 265.081 840.604C264.783 841.105 264.555 841.73 264.395 842.478C264.235 843.218 264.155 844.078 264.155 845.057Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M272.531 877.76H263.149V875.887H266.989V864.852L263.412 866.819L262.681 865.107L267.435 862.574H269.195V875.887H272.531V877.76Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M215.38 306.471H205.446V304.487H209.512V292.803L205.724 294.885L204.95 293.073L209.983 290.391H211.847V304.487H215.38V306.471Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 332.945H205.446V330.961H209.512V319.278L205.724 321.36L204.95 319.547L209.983 316.865H211.847V330.961H215.38V332.945Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 351.422C215.876 352.647 215.755 353.766 215.513 354.778C215.271 355.782 214.904 356.648 214.412 357.374C213.92 358.093 213.307 358.652 212.573 359.052C211.847 359.444 210.992 359.64 210.008 359.64C209.161 359.64 208.39 359.481 207.697 359.162C207.003 358.836 206.41 358.338 205.918 357.668C205.426 356.999 205.047 356.146 204.78 355.109C204.514 354.072 204.381 352.843 204.381 351.422C204.381 350.198 204.502 349.079 204.744 348.067C204.994 347.054 205.361 346.189 205.845 345.471C206.337 344.744 206.95 344.185 207.684 343.793C208.418 343.393 209.274 343.193 210.25 343.193C211.097 343.193 211.867 343.356 212.561 343.683C213.254 344.001 213.847 344.499 214.339 345.177C214.832 345.846 215.211 346.699 215.477 347.736C215.743 348.773 215.876 350.002 215.876 351.422ZM213.747 351.496C213.747 351.218 213.734 350.945 213.71 350.675C213.694 350.398 213.674 350.128 213.65 349.867L206.958 354.9C207.079 355.325 207.233 355.717 207.418 356.076C207.604 356.435 207.826 356.746 208.084 357.007C208.35 357.26 208.648 357.46 208.979 357.607C209.318 357.746 209.701 357.815 210.129 357.815C210.677 357.815 211.173 357.68 211.617 357.411C212.069 357.142 212.452 356.742 212.766 356.211C213.081 355.68 213.323 355.023 213.492 354.239C213.662 353.447 213.747 352.533 213.747 351.496ZM206.511 351.349C206.511 351.602 206.511 351.855 206.511 352.108C206.519 352.361 206.535 352.606 206.559 352.843L213.25 347.834C213.129 347.418 212.976 347.038 212.791 346.695C212.605 346.352 212.383 346.058 212.125 345.813C211.867 345.56 211.573 345.364 211.242 345.226C210.911 345.087 210.54 345.017 210.129 345.017C209.58 345.017 209.08 345.152 208.628 345.422C208.185 345.691 207.805 346.095 207.491 346.634C207.176 347.165 206.934 347.826 206.765 348.618C206.595 349.402 206.511 350.312 206.511 351.349Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 377.897C215.876 379.121 215.755 380.24 215.513 381.252C215.271 382.257 214.904 383.122 214.412 383.849C213.92 384.567 213.307 385.126 212.573 385.526C211.847 385.918 210.992 386.114 210.008 386.114C209.161 386.114 208.39 385.955 207.697 385.637C207.003 385.31 206.41 384.812 205.918 384.143C205.426 383.473 205.047 382.62 204.78 381.583C204.514 380.546 204.381 379.317 204.381 377.897C204.381 376.672 204.502 375.554 204.744 374.541C204.994 373.529 205.361 372.663 205.845 371.945C206.337 371.218 206.95 370.659 207.684 370.267C208.418 369.867 209.274 369.667 210.25 369.667C211.097 369.667 211.867 369.83 212.561 370.157C213.254 370.475 213.847 370.973 214.339 371.651C214.832 372.321 215.211 373.174 215.477 374.211C215.743 375.247 215.876 376.476 215.876 377.897ZM213.747 377.97C213.747 377.693 213.734 377.419 213.71 377.15C213.694 376.872 213.674 376.603 213.65 376.342L206.958 381.375C207.079 381.799 207.233 382.191 207.418 382.551C207.604 382.91 207.826 383.22 208.084 383.481C208.35 383.734 208.648 383.934 208.979 384.081C209.318 384.22 209.701 384.29 210.129 384.29C210.677 384.29 211.173 384.155 211.617 383.885C212.069 383.616 212.452 383.216 212.766 382.685C213.081 382.155 213.323 381.497 213.492 380.714C213.662 379.922 213.747 379.007 213.747 377.97ZM206.511 377.823C206.511 378.076 206.511 378.33 206.511 378.583C206.519 378.836 206.535 379.081 206.559 379.317L213.25 374.309C213.129 373.892 212.976 373.513 212.791 373.17C212.605 372.827 212.383 372.533 212.125 372.288C211.867 372.035 211.573 371.839 211.242 371.7C210.911 371.561 210.54 371.492 210.129 371.492C209.58 371.492 209.08 371.627 208.628 371.896C208.185 372.165 207.805 372.57 207.491 373.108C207.176 373.639 206.934 374.3 206.765 375.092C206.595 375.876 206.511 376.786 206.511 377.823Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 404.371C215.876 405.596 215.755 406.714 215.513 407.727C215.271 408.731 214.904 409.596 214.412 410.323C213.92 411.042 213.307 411.601 212.573 412.001C211.847 412.393 210.992 412.589 210.008 412.589C209.161 412.589 208.39 412.429 207.697 412.111C207.003 411.784 206.41 411.286 205.918 410.617C205.426 409.948 205.047 409.094 204.78 408.057C204.514 407.021 204.381 405.792 204.381 404.371C204.381 403.147 204.502 402.028 204.744 401.016C204.994 400.003 205.361 399.138 205.845 398.419C206.337 397.693 206.95 397.133 207.684 396.742C208.418 396.342 209.274 396.142 210.25 396.142C211.097 396.142 211.867 396.305 212.561 396.631C213.254 396.95 213.847 397.448 214.339 398.125C214.832 398.795 215.211 399.648 215.477 400.685C215.743 401.722 215.876 402.951 215.876 404.371ZM213.747 404.445C213.747 404.167 213.734 403.894 213.71 403.624C213.694 403.347 213.674 403.077 213.65 402.816L206.958 407.849C207.079 408.274 207.233 408.666 207.418 409.025C207.604 409.384 207.826 409.694 208.084 409.956C208.35 410.209 208.648 410.409 208.979 410.556C209.318 410.695 209.701 410.764 210.129 410.764C210.677 410.764 211.173 410.629 211.617 410.36C212.069 410.09 212.452 409.69 212.766 409.16C213.081 408.629 213.323 407.972 213.492 407.188C213.662 406.396 213.747 405.482 213.747 404.445ZM206.511 404.298C206.511 404.551 206.511 404.804 206.511 405.057C206.519 405.31 206.535 405.555 206.559 405.792L213.25 400.783C213.129 400.367 212.976 399.987 212.791 399.644C212.605 399.301 212.383 399.007 212.125 398.762C211.867 398.509 211.573 398.313 211.242 398.174C210.911 398.036 210.54 397.966 210.129 397.966C209.58 397.966 209.08 398.101 208.628 398.37C208.185 398.64 207.805 399.044 207.491 399.583C207.176 400.113 206.934 400.775 206.765 401.567C206.595 402.351 206.511 403.261 206.511 404.298Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 438.843H205.446V436.859H209.512V425.175L205.724 427.257L204.95 425.445L209.983 422.763H211.847V436.859H215.38V438.843Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 457.32C215.876 458.545 215.755 459.663 215.513 460.676C215.271 461.68 214.904 462.545 214.412 463.272C213.92 463.99 213.307 464.55 212.573 464.95C211.847 465.342 210.992 465.537 210.008 465.537C209.161 465.537 208.39 465.378 207.697 465.06C207.003 464.733 206.41 464.235 205.918 463.566C205.426 462.896 205.047 462.043 204.78 461.006C204.514 459.969 204.381 458.741 204.381 457.32C204.381 456.095 204.502 454.977 204.744 453.964C204.994 452.952 205.361 452.087 205.845 451.368C206.337 450.642 206.95 450.082 207.684 449.69C208.418 449.29 209.274 449.09 210.25 449.09C211.097 449.09 211.867 449.254 212.561 449.58C213.254 449.899 213.847 450.397 214.339 451.074C214.832 451.744 215.211 452.597 215.477 453.634C215.743 454.671 215.876 455.899 215.876 457.32ZM213.747 457.393C213.747 457.116 213.734 456.842 213.71 456.573C213.694 456.295 213.674 456.026 213.65 455.765L206.958 460.798C207.079 461.223 207.233 461.614 207.418 461.974C207.604 462.333 207.826 462.643 208.084 462.904C208.35 463.158 208.648 463.358 208.979 463.505C209.318 463.643 209.701 463.713 210.129 463.713C210.677 463.713 211.173 463.578 211.617 463.309C212.069 463.039 212.452 462.639 212.766 462.108C213.081 461.578 213.323 460.921 213.492 460.137C213.662 459.345 213.747 458.43 213.747 457.393ZM206.511 457.247C206.511 457.5 206.511 457.753 206.511 458.006C206.519 458.259 206.535 458.504 206.559 458.741L213.25 453.732C213.129 453.315 212.976 452.936 212.791 452.593C212.605 452.25 212.383 451.956 212.125 451.711C211.867 451.458 211.573 451.262 211.242 451.123C210.911 450.984 210.54 450.915 210.129 450.915C209.58 450.915 209.08 451.05 208.628 451.319C208.185 451.589 207.805 451.993 207.491 452.532C207.176 453.062 206.934 453.724 206.765 454.516C206.595 455.299 206.511 456.21 206.511 457.247Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 491.791H205.446V489.807H209.512V478.124L205.724 480.206L204.95 478.394L209.983 475.712H211.847V489.807H215.38V491.791Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 518.266H205.446V516.282H209.512V504.599L205.724 506.681L204.95 504.868L209.983 502.186H211.847V516.282H215.38V518.266Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 536.743C215.876 537.968 215.755 539.086 215.513 540.099C215.271 541.103 214.904 541.968 214.412 542.695C213.92 543.414 213.307 543.973 212.573 544.373C211.847 544.765 210.992 544.961 210.008 544.961C209.161 544.961 208.39 544.801 207.697 544.483C207.003 544.156 206.41 543.658 205.918 542.989C205.426 542.319 205.047 541.466 204.78 540.429C204.514 539.393 204.381 538.164 204.381 536.743C204.381 535.519 204.502 534.4 204.744 533.388C204.994 532.375 205.361 531.51 205.845 530.791C206.337 530.065 206.95 529.505 207.684 529.114C208.418 528.714 209.274 528.514 210.25 528.514C211.097 528.514 211.867 528.677 212.561 529.003C213.254 529.322 213.847 529.82 214.339 530.497C214.832 531.167 215.211 532.02 215.477 533.057C215.743 534.094 215.876 535.323 215.876 536.743ZM213.747 536.817C213.747 536.539 213.734 536.266 213.71 535.996C213.694 535.719 213.674 535.449 213.65 535.188L206.958 540.221C207.079 540.646 207.233 541.038 207.418 541.397C207.604 541.756 207.826 542.066 208.084 542.328C208.35 542.581 208.648 542.781 208.979 542.928C209.318 543.067 209.701 543.136 210.129 543.136C210.677 543.136 211.173 543.001 211.617 542.732C212.069 542.462 212.452 542.062 212.766 541.532C213.081 541.001 213.323 540.344 213.492 539.56C213.662 538.768 213.747 537.854 213.747 536.817ZM206.511 536.67C206.511 536.923 206.511 537.176 206.511 537.429C206.519 537.682 206.535 537.927 206.559 538.164L213.25 533.155C213.129 532.739 212.976 532.359 212.791 532.016C212.605 531.673 212.383 531.379 212.125 531.134C211.867 530.881 211.573 530.685 211.242 530.546C210.911 530.408 210.54 530.338 210.129 530.338C209.58 530.338 209.08 530.473 208.628 530.742C208.185 531.012 207.805 531.416 207.491 531.955C207.176 532.485 206.934 533.147 206.765 533.939C206.595 534.723 206.511 535.633 206.511 536.67Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 563.218C215.876 564.442 215.755 565.561 215.513 566.573C215.271 567.577 214.904 568.443 214.412 569.169C213.92 569.888 213.307 570.447 212.573 570.847C211.847 571.239 210.992 571.435 210.008 571.435C209.161 571.435 208.39 571.276 207.697 570.957C207.003 570.631 206.41 570.133 205.918 569.463C205.426 568.794 205.047 567.941 204.78 566.904C204.514 565.867 204.381 564.638 204.381 563.218C204.381 561.993 204.502 560.874 204.744 559.862C204.994 558.85 205.361 557.984 205.845 557.266C206.337 556.539 206.95 555.98 207.684 555.588C208.418 555.188 209.274 554.988 210.25 554.988C211.097 554.988 211.867 555.151 212.561 555.478C213.254 555.796 213.847 556.294 214.339 556.972C214.832 557.641 215.211 558.495 215.477 559.531C215.743 560.568 215.876 561.797 215.876 563.218ZM213.747 563.291C213.747 563.013 213.734 562.74 213.71 562.471C213.694 562.193 213.674 561.924 213.65 561.662L206.958 566.696C207.079 567.12 207.233 567.512 207.418 567.871C207.604 568.231 207.826 568.541 208.084 568.802C208.35 569.055 208.648 569.255 208.979 569.402C209.318 569.541 209.701 569.61 210.129 569.61C210.677 569.61 211.173 569.476 211.617 569.206C212.069 568.937 212.452 568.537 212.766 568.006C213.081 567.475 213.323 566.818 213.492 566.034C213.662 565.242 213.747 564.328 213.747 563.291ZM206.511 563.144C206.511 563.397 206.511 563.65 206.511 563.903C206.519 564.156 206.535 564.401 206.559 564.638L213.25 559.629C213.129 559.213 212.976 558.833 212.791 558.49C212.605 558.148 212.383 557.854 212.125 557.609C211.867 557.356 211.573 557.16 211.242 557.021C210.911 556.882 210.54 556.813 210.129 556.813C209.58 556.813 209.08 556.947 208.628 557.217C208.185 557.486 207.805 557.89 207.491 558.429C207.176 558.96 206.934 559.621 206.765 560.413C206.595 561.197 206.511 562.107 206.511 563.144Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 589.692C215.876 590.917 215.755 592.035 215.513 593.048C215.271 594.052 214.904 594.917 214.412 595.644C213.92 596.362 213.307 596.922 212.573 597.322C211.847 597.714 210.992 597.909 210.008 597.909C209.161 597.909 208.39 597.75 207.697 597.432C207.003 597.105 206.41 596.607 205.918 595.938C205.426 595.268 205.047 594.415 204.78 593.378C204.514 592.341 204.381 591.113 204.381 589.692C204.381 588.467 204.502 587.349 204.744 586.336C204.994 585.324 205.361 584.459 205.845 583.74C206.337 583.014 206.95 582.454 207.684 582.062C208.418 581.662 209.274 581.462 210.25 581.462C211.097 581.462 211.867 581.626 212.561 581.952C213.254 582.271 213.847 582.769 214.339 583.446C214.832 584.116 215.211 584.969 215.477 586.006C215.743 587.043 215.876 588.271 215.876 589.692ZM213.747 589.765C213.747 589.488 213.734 589.214 213.71 588.945C213.694 588.667 213.674 588.398 213.65 588.137L206.958 593.17C207.079 593.595 207.233 593.986 207.418 594.346C207.604 594.705 207.826 595.015 208.084 595.276C208.35 595.53 208.648 595.73 208.979 595.877C209.318 596.015 209.701 596.085 210.129 596.085C210.677 596.085 211.173 595.95 211.617 595.681C212.069 595.411 212.452 595.011 212.766 594.48C213.081 593.95 213.323 593.292 213.492 592.509C213.662 591.717 213.747 590.802 213.747 589.765ZM206.511 589.618C206.511 589.872 206.511 590.125 206.511 590.378C206.519 590.631 206.535 590.876 206.559 591.113L213.25 586.104C213.129 585.687 212.976 585.308 212.791 584.965C212.605 584.622 212.383 584.328 212.125 584.083C211.867 583.83 211.573 583.634 211.242 583.495C210.911 583.356 210.54 583.287 210.129 583.287C209.58 583.287 209.08 583.422 208.628 583.691C208.185 583.961 207.805 584.365 207.491 584.904C207.176 585.434 206.934 586.096 206.765 586.888C206.595 587.671 206.511 588.582 206.511 589.618Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 624.163H205.446V622.179H209.512V610.496L205.724 612.578L204.95 610.766L209.983 608.084H211.847V622.179H215.38V624.163Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 650.638H205.446V648.654H209.512V636.971L205.724 639.053L204.95 637.24L209.983 634.558H211.847V648.654H215.38V650.638Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 677.112H205.446V675.128H209.512V663.445L205.724 665.527L204.95 663.714L209.983 661.032H211.847V675.128H215.38V677.112Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 695.59C215.876 696.814 215.755 697.933 215.513 698.945C215.271 699.949 214.904 700.815 214.412 701.541C213.92 702.26 213.307 702.819 212.573 703.219C211.847 703.611 210.992 703.807 210.008 703.807C209.161 703.807 208.39 703.648 207.697 703.329C207.003 703.003 206.41 702.505 205.918 701.835C205.426 701.166 205.047 700.313 204.78 699.276C204.514 698.239 204.381 697.01 204.381 695.59C204.381 694.365 204.502 693.246 204.744 692.234C204.994 691.222 205.361 690.356 205.845 689.638C206.337 688.911 206.95 688.352 207.684 687.96C208.418 687.56 209.274 687.36 210.25 687.36C211.097 687.36 211.867 687.523 212.561 687.85C213.254 688.168 213.847 688.666 214.339 689.344C214.832 690.013 215.211 690.866 215.477 691.903C215.743 692.94 215.876 694.169 215.876 695.59ZM213.747 695.663C213.747 695.385 213.734 695.112 213.71 694.843C213.694 694.565 213.674 694.296 213.65 694.034L206.958 699.068C207.079 699.492 207.233 699.884 207.418 700.243C207.604 700.603 207.826 700.913 208.084 701.174C208.35 701.427 208.648 701.627 208.979 701.774C209.318 701.913 209.701 701.982 210.129 701.982C210.677 701.982 211.173 701.848 211.617 701.578C212.069 701.309 212.452 700.909 212.766 700.378C213.081 699.847 213.323 699.19 213.492 698.406C213.662 697.614 213.747 696.7 213.747 695.663ZM206.511 695.516C206.511 695.769 206.511 696.022 206.511 696.275C206.519 696.528 206.535 696.773 206.559 697.01L213.25 692.001C213.129 691.585 212.976 691.205 212.791 690.862C212.605 690.52 212.383 690.226 212.125 689.981C211.867 689.728 211.573 689.532 211.242 689.393C210.911 689.254 210.54 689.185 210.129 689.185C209.58 689.185 209.08 689.319 208.628 689.589C208.185 689.858 207.805 690.262 207.491 690.801C207.176 691.332 206.934 691.993 206.765 692.785C206.595 693.569 206.511 694.479 206.511 695.516Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 730.061H205.446V728.077H209.512V716.394L205.724 718.476L204.95 716.663L209.983 713.981H211.847V728.077H215.38V730.061Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 748.538C215.876 749.763 215.755 750.882 215.513 751.894C215.271 752.898 214.904 753.764 214.412 754.49C213.92 755.209 213.307 755.768 212.573 756.168C211.847 756.56 210.992 756.756 210.008 756.756C209.161 756.756 208.39 756.597 207.697 756.278C207.003 755.952 206.41 755.454 205.918 754.784C205.426 754.115 205.047 753.261 204.78 752.225C204.514 751.188 204.381 749.959 204.381 748.538C204.381 747.314 204.502 746.195 204.744 745.183C204.994 744.17 205.361 743.305 205.845 742.587C206.337 741.86 206.95 741.301 207.684 740.909C208.418 740.509 209.274 740.309 210.25 740.309C211.097 740.309 211.867 740.472 212.561 740.799C213.254 741.117 213.847 741.615 214.339 742.293C214.832 742.962 215.211 743.815 215.477 744.852C215.743 745.889 215.876 747.118 215.876 748.538ZM213.747 748.612C213.747 748.334 213.734 748.061 213.71 747.791C213.694 747.514 213.674 747.244 213.65 746.983L206.958 752.016C207.079 752.441 207.233 752.833 207.418 753.192C207.604 753.551 207.826 753.862 208.084 754.123C208.35 754.376 208.648 754.576 208.979 754.723C209.318 754.862 209.701 754.931 210.129 754.931C210.677 754.931 211.173 754.796 211.617 754.527C212.069 754.258 212.452 753.857 212.766 753.327C213.081 752.796 213.323 752.139 213.492 751.355C213.662 750.563 213.747 749.649 213.747 748.612ZM206.511 748.465C206.511 748.718 206.511 748.971 206.511 749.224C206.519 749.477 206.535 749.722 206.559 749.959L213.25 744.95C213.129 744.534 212.976 744.154 212.791 743.811C212.605 743.468 212.383 743.174 212.125 742.929C211.867 742.676 211.573 742.48 211.242 742.342C210.911 742.203 210.54 742.133 210.129 742.133C209.58 742.133 209.08 742.268 208.628 742.538C208.185 742.807 207.805 743.211 207.491 743.75C207.176 744.281 206.934 744.942 206.765 745.734C206.595 746.518 206.511 747.428 206.511 748.465Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.876 775.013C215.876 776.237 215.755 777.356 215.513 778.368C215.271 779.373 214.904 780.238 214.412 780.965C213.92 781.683 213.307 782.242 212.573 782.642C211.847 783.034 210.992 783.23 210.008 783.23C209.161 783.23 208.39 783.071 207.697 782.753C207.003 782.426 206.41 781.928 205.918 781.259C205.426 780.589 205.047 779.736 204.78 778.699C204.514 777.662 204.381 776.433 204.381 775.013C204.381 773.788 204.502 772.67 204.744 771.657C204.994 770.645 205.361 769.779 205.845 769.061C206.337 768.334 206.95 767.775 207.684 767.383C208.418 766.983 209.274 766.783 210.25 766.783C211.097 766.783 211.867 766.946 212.561 767.273C213.254 767.591 213.847 768.089 214.339 768.767C214.832 769.436 215.211 770.29 215.477 771.327C215.743 772.363 215.876 773.592 215.876 775.013ZM213.747 775.086C213.747 774.809 213.734 774.535 213.71 774.266C213.694 773.988 213.674 773.719 213.65 773.457L206.958 778.491C207.079 778.915 207.233 779.307 207.418 779.666C207.604 780.026 207.826 780.336 208.084 780.597C208.35 780.85 208.648 781.05 208.979 781.197C209.318 781.336 209.701 781.405 210.129 781.405C210.677 781.405 211.173 781.271 211.617 781.001C212.069 780.732 212.452 780.332 212.766 779.801C213.081 779.27 213.323 778.613 213.492 777.829C213.662 777.038 213.747 776.123 213.747 775.086ZM206.511 774.939C206.511 775.192 206.511 775.445 206.511 775.699C206.519 775.952 206.535 776.197 206.559 776.433L213.25 771.424C213.129 771.008 212.976 770.628 212.791 770.286C212.605 769.943 212.383 769.649 212.125 769.404C211.867 769.151 211.573 768.955 211.242 768.816C210.911 768.677 210.54 768.608 210.129 768.608C209.58 768.608 209.08 768.743 208.628 769.012C208.185 769.281 207.805 769.685 207.491 770.224C207.176 770.755 206.934 771.416 206.765 772.208C206.595 772.992 206.511 773.902 206.511 774.939Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M215.38 809.484H205.446V807.5H209.512V795.817L205.724 797.899L204.95 796.086L209.983 793.404H211.847V807.5H215.38V809.484Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 534.986H26.4745V533.003H30.54V521.319L26.7528 523.401L25.9784 521.589L31.0119 518.907H32.8753V533.003H36.4084V534.986Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 561.461H26.4745V559.477H30.54V547.794L26.7528 549.876L25.9784 548.063L31.0119 545.381H32.8753V559.477H36.4084V561.461Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 579.938C36.9045 581.163 36.7835 582.281 36.5415 583.294C36.2995 584.298 35.9325 585.163 35.4404 585.89C34.9484 586.609 34.3353 587.168 33.6013 587.568C32.8753 587.96 32.0202 588.156 31.0361 588.156C30.1891 588.156 29.4187 587.996 28.725 587.678C28.0313 587.352 27.4384 586.853 26.9463 586.184C26.4543 585.515 26.0752 584.661 25.809 583.624C25.5428 582.588 25.4097 581.359 25.4097 579.938C25.4097 578.714 25.5307 577.595 25.7727 576.583C26.0227 575.57 26.3898 574.705 26.8737 573.986C27.3658 573.26 27.9789 572.701 28.7129 572.309C29.447 571.909 30.302 571.709 31.2781 571.709C32.1251 571.709 32.8954 571.872 33.5892 572.198C34.2829 572.517 34.8758 573.015 35.3678 573.692C35.8599 574.362 36.239 575.215 36.5052 576.252C36.7714 577.289 36.9045 578.518 36.9045 579.938ZM34.7749 580.012C34.7749 579.734 34.7628 579.461 34.7386 579.191C34.7225 578.914 34.7023 578.644 34.6781 578.383L27.9869 583.416C28.1079 583.841 28.2612 584.233 28.4467 584.592C28.6323 584.951 28.8541 585.261 29.1122 585.523C29.3784 585.776 29.6769 585.976 30.0076 586.123C30.3464 586.262 30.7296 586.331 31.1571 586.331C31.7056 586.331 32.2017 586.196 32.6454 585.927C33.0971 585.657 33.4803 585.257 33.7949 584.727C34.1095 584.196 34.3514 583.539 34.5208 582.755C34.6902 581.963 34.7749 581.049 34.7749 580.012ZM27.5392 579.865C27.5392 580.118 27.5392 580.371 27.5392 580.624C27.5473 580.877 27.5634 581.122 27.5876 581.359L34.2789 576.35C34.1579 575.934 34.0046 575.554 33.8191 575.211C33.6335 574.868 33.4117 574.574 33.1536 574.329C32.8954 574.076 32.601 573.88 32.2703 573.741C31.9395 573.603 31.5685 573.533 31.1571 573.533C30.6086 573.533 30.1084 573.668 29.6567 573.937C29.2131 574.207 28.8339 574.611 28.5193 575.15C28.2047 575.681 27.9627 576.342 27.7933 577.134C27.6239 577.918 27.5392 578.828 27.5392 579.865Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 606.413C36.9045 607.637 36.7835 608.756 36.5415 609.768C36.2995 610.772 35.9325 611.638 35.4404 612.364C34.9484 613.083 34.3353 613.642 33.6013 614.042C32.8753 614.434 32.0202 614.63 31.0361 614.63C30.1891 614.63 29.4187 614.471 28.725 614.152C28.0313 613.826 27.4384 613.328 26.9463 612.658C26.4543 611.989 26.0752 611.136 25.809 610.099C25.5428 609.062 25.4097 607.833 25.4097 606.413C25.4097 605.188 25.5307 604.069 25.7727 603.057C26.0227 602.045 26.3898 601.179 26.8737 600.461C27.3658 599.734 27.9789 599.175 28.7129 598.783C29.447 598.383 30.302 598.183 31.2781 598.183C32.1251 598.183 32.8954 598.346 33.5892 598.673C34.2829 598.991 34.8758 599.489 35.3678 600.167C35.8599 600.836 36.239 601.69 36.5052 602.726C36.7714 603.763 36.9045 604.992 36.9045 606.413ZM34.7749 606.486C34.7749 606.209 34.7628 605.935 34.7386 605.666C34.7225 605.388 34.7023 605.119 34.6781 604.857L27.9869 609.891C28.1079 610.315 28.2612 610.707 28.4467 611.066C28.6323 611.426 28.8541 611.736 29.1122 611.997C29.3784 612.25 29.6769 612.45 30.0076 612.597C30.3464 612.736 30.7296 612.805 31.1571 612.805C31.7056 612.805 32.2017 612.671 32.6454 612.401C33.0971 612.132 33.4803 611.732 33.7949 611.201C34.1095 610.67 34.3514 610.013 34.5208 609.229C34.6902 608.437 34.7749 607.523 34.7749 606.486ZM27.5392 606.339C27.5392 606.592 27.5392 606.845 27.5392 607.098C27.5473 607.352 27.5634 607.596 27.5876 607.833L34.2789 602.824C34.1579 602.408 34.0046 602.028 33.8191 601.685C33.6335 601.343 33.4117 601.049 33.1536 600.804C32.8954 600.551 32.601 600.355 32.2703 600.216C31.9395 600.077 31.5685 600.008 31.1571 600.008C30.6086 600.008 30.1084 600.142 29.6567 600.412C29.2131 600.681 28.8339 601.085 28.5193 601.624C28.2047 602.155 27.9627 602.816 27.7933 603.608C27.6239 604.392 27.5392 605.302 27.5392 606.339Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 632.887C36.9045 634.112 36.7835 635.23 36.5415 636.243C36.2995 637.247 35.9325 638.112 35.4404 638.839C34.9484 639.557 34.3353 640.117 33.6013 640.517C32.8753 640.909 32.0202 641.104 31.0361 641.104C30.1891 641.104 29.4187 640.945 28.725 640.627C28.0313 640.3 27.4384 639.802 26.9463 639.133C26.4543 638.463 26.0752 637.61 25.809 636.573C25.5428 635.536 25.4097 634.308 25.4097 632.887C25.4097 631.662 25.5307 630.544 25.7727 629.531C26.0227 628.519 26.3898 627.654 26.8737 626.935C27.3658 626.209 27.9789 625.649 28.7129 625.257C29.447 624.857 30.302 624.657 31.2781 624.657C32.1251 624.657 32.8954 624.821 33.5892 625.147C34.2829 625.466 34.8758 625.964 35.3678 626.641C35.8599 627.311 36.239 628.164 36.5052 629.201C36.7714 630.238 36.9045 631.466 36.9045 632.887ZM34.7749 632.961C34.7749 632.683 34.7628 632.409 34.7386 632.14C34.7225 631.862 34.7023 631.593 34.6781 631.332L27.9869 636.365C28.1079 636.79 28.2612 637.182 28.4467 637.541C28.6323 637.9 28.8541 638.21 29.1122 638.471C29.3784 638.725 29.6769 638.925 30.0076 639.072C30.3464 639.21 30.7296 639.28 31.1571 639.28C31.7056 639.28 32.2017 639.145 32.6454 638.876C33.0971 638.606 33.4803 638.206 33.7949 637.675C34.1095 637.145 34.3514 636.488 34.5208 635.704C34.6902 634.912 34.7749 633.997 34.7749 632.961ZM27.5392 632.814C27.5392 633.067 27.5392 633.32 27.5392 633.573C27.5473 633.826 27.5634 634.071 27.5876 634.308L34.2789 629.299C34.1579 628.882 34.0046 628.503 33.8191 628.16C33.6335 627.817 33.4117 627.523 33.1536 627.278C32.8954 627.025 32.601 626.829 32.2703 626.69C31.9395 626.551 31.5685 626.482 31.1571 626.482C30.6086 626.482 30.1084 626.617 29.6567 626.886C29.2131 627.156 28.8339 627.56 28.5193 628.099C28.2047 628.629 27.9627 629.291 27.7933 630.083C27.6239 630.866 27.5392 631.777 27.5392 632.814Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 667.358H26.4745V665.374H30.54V653.691L26.7528 655.773L25.9784 653.961L31.0119 651.279H32.8753V665.374H36.4084V667.358Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 685.836C36.9045 687.06 36.7835 688.179 36.5415 689.191C36.2995 690.196 35.9325 691.061 35.4404 691.788C34.9484 692.506 34.3353 693.065 33.6013 693.465C32.8753 693.857 32.0202 694.053 31.0361 694.053C30.1891 694.053 29.4187 693.894 28.725 693.576C28.0313 693.249 27.4384 692.751 26.9463 692.082C26.4543 691.412 26.0752 690.559 25.809 689.522C25.5428 688.485 25.4097 687.256 25.4097 685.836C25.4097 684.611 25.5307 683.493 25.7727 682.48C26.0227 681.468 26.3898 680.602 26.8737 679.884C27.3658 679.157 27.9789 678.598 28.7129 678.206C29.447 677.806 30.302 677.606 31.2781 677.606C32.1251 677.606 32.8954 677.769 33.5892 678.096C34.2829 678.414 34.8758 678.912 35.3678 679.59C35.8599 680.26 36.239 681.113 36.5052 682.15C36.7714 683.186 36.9045 684.415 36.9045 685.836ZM34.7749 685.909C34.7749 685.632 34.7628 685.358 34.7386 685.089C34.7225 684.811 34.7023 684.542 34.6781 684.281L27.9869 689.314C28.1079 689.738 28.2612 690.13 28.4467 690.49C28.6323 690.849 28.8541 691.159 29.1122 691.42C29.3784 691.673 29.6769 691.873 30.0076 692.02C30.3464 692.159 30.7296 692.229 31.1571 692.229C31.7056 692.229 32.2017 692.094 32.6454 691.824C33.0971 691.555 33.4803 691.155 33.7949 690.624C34.1095 690.094 34.3514 689.436 34.5208 688.653C34.6902 687.861 34.7749 686.946 34.7749 685.909ZM27.5392 685.762C27.5392 686.015 27.5392 686.269 27.5392 686.522C27.5473 686.775 27.5634 687.02 27.5876 687.256L34.2789 682.248C34.1579 681.831 34.0046 681.452 33.8191 681.109C33.6335 680.766 33.4117 680.472 33.1536 680.227C32.8954 679.974 32.601 679.778 32.2703 679.639C31.9395 679.5 31.5685 679.431 31.1571 679.431C30.6086 679.431 30.1084 679.566 29.6567 679.835C29.2131 680.104 28.8339 680.509 28.5193 681.047C28.2047 681.578 27.9627 682.239 27.7933 683.031C27.6239 683.815 27.5392 684.725 27.5392 685.762Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 720.307H26.4745V718.323H30.54V706.64L26.7528 708.722L25.9784 706.909L31.0119 704.227H32.8753V718.323H36.4084V720.307Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 746.782H26.4745V744.798H30.54V733.114L26.7528 735.196L25.9784 733.384L31.0119 730.702H32.8753V744.798H36.4084V746.782Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 765.259C36.9045 766.484 36.7835 767.602 36.5415 768.615C36.2995 769.619 35.9325 770.484 35.4404 771.211C34.9484 771.929 34.3353 772.489 33.6013 772.889C32.8753 773.281 32.0202 773.476 31.0361 773.476C30.1891 773.476 29.4187 773.317 28.725 772.999C28.0313 772.672 27.4384 772.174 26.9463 771.505C26.4543 770.835 26.0752 769.982 25.809 768.945C25.5428 767.908 25.4097 766.68 25.4097 765.259C25.4097 764.034 25.5307 762.916 25.7727 761.903C26.0227 760.891 26.3898 760.026 26.8737 759.307C27.3658 758.581 27.9789 758.021 28.7129 757.629C29.447 757.229 30.302 757.029 31.2781 757.029C32.1251 757.029 32.8954 757.193 33.5892 757.519C34.2829 757.838 34.8758 758.336 35.3678 759.013C35.8599 759.683 36.239 760.536 36.5052 761.573C36.7714 762.61 36.9045 763.838 36.9045 765.259ZM34.7749 765.332C34.7749 765.055 34.7628 764.781 34.7386 764.512C34.7225 764.234 34.7023 763.965 34.6781 763.704L27.9869 768.737C28.1079 769.162 28.2612 769.553 28.4467 769.913C28.6323 770.272 28.8541 770.582 29.1122 770.843C29.3784 771.097 29.6769 771.297 30.0076 771.444C30.3464 771.582 30.7296 771.652 31.1571 771.652C31.7056 771.652 32.2017 771.517 32.6454 771.248C33.0971 770.978 33.4803 770.578 33.7949 770.047C34.1095 769.517 34.3514 768.86 34.5208 768.076C34.6902 767.284 34.7749 766.369 34.7749 765.332ZM27.5392 765.186C27.5392 765.439 27.5392 765.692 27.5392 765.945C27.5473 766.198 27.5634 766.443 27.5876 766.68L34.2789 761.671C34.1579 761.254 34.0046 760.875 33.8191 760.532C33.6335 760.189 33.4117 759.895 33.1536 759.65C32.8954 759.397 32.601 759.201 32.2703 759.062C31.9395 758.923 31.5685 758.854 31.1571 758.854C30.6086 758.854 30.1084 758.989 29.6567 759.258C29.2131 759.528 28.8339 759.932 28.5193 760.471C28.2047 761.001 27.9627 761.663 27.7933 762.455C27.6239 763.238 27.5392 764.149 27.5392 765.186Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 791.733C36.9045 792.958 36.7835 794.077 36.5415 795.089C36.2995 796.093 35.9325 796.959 35.4404 797.685C34.9484 798.404 34.3353 798.963 33.6013 799.363C32.8753 799.755 32.0202 799.951 31.0361 799.951C30.1891 799.951 29.4187 799.792 28.725 799.473C28.0313 799.147 27.4384 798.649 26.9463 797.979C26.4543 797.31 26.0752 796.457 25.809 795.42C25.5428 794.383 25.4097 793.154 25.4097 791.733C25.4097 790.509 25.5307 789.39 25.7727 788.378C26.0227 787.365 26.3898 786.5 26.8737 785.782C27.3658 785.055 27.9789 784.496 28.7129 784.104C29.447 783.704 30.302 783.504 31.2781 783.504C32.1251 783.504 32.8954 783.667 33.5892 783.994C34.2829 784.312 34.8758 784.81 35.3678 785.488C35.8599 786.157 36.239 787.01 36.5052 788.047C36.7714 789.084 36.9045 790.313 36.9045 791.733ZM34.7749 791.807C34.7749 791.529 34.7628 791.256 34.7386 790.986C34.7225 790.709 34.7023 790.439 34.6781 790.178L27.9869 795.211C28.1079 795.636 28.2612 796.028 28.4467 796.387C28.6323 796.746 28.8541 797.057 29.1122 797.318C29.3784 797.571 29.6769 797.771 30.0076 797.918C30.3464 798.057 30.7296 798.126 31.1571 798.126C31.7056 798.126 32.2017 797.991 32.6454 797.722C33.0971 797.453 33.4803 797.053 33.7949 796.522C34.1095 795.991 34.3514 795.334 34.5208 794.55C34.6902 793.758 34.7749 792.844 34.7749 791.807ZM27.5392 791.66C27.5392 791.913 27.5392 792.166 27.5392 792.419C27.5473 792.672 27.5634 792.917 27.5876 793.154L34.2789 788.145C34.1579 787.729 34.0046 787.349 33.8191 787.006C33.6335 786.663 33.4117 786.369 33.1536 786.124C32.8954 785.871 32.601 785.675 32.2703 785.537C31.9395 785.398 31.5685 785.328 31.1571 785.328C30.6086 785.328 30.1084 785.463 29.6567 785.733C29.2131 786.002 28.8339 786.406 28.5193 786.945C28.2047 787.476 27.9627 788.137 27.7933 788.929C27.6239 789.713 27.5392 790.623 27.5392 791.66Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 818.208C36.9045 819.432 36.7835 820.551 36.5415 821.563C36.2995 822.568 35.9325 823.433 35.4404 824.16C34.9484 824.878 34.3353 825.437 33.6013 825.837C32.8753 826.229 32.0202 826.425 31.0361 826.425C30.1891 826.425 29.4187 826.266 28.725 825.948C28.0313 825.621 27.4384 825.123 26.9463 824.454C26.4543 823.784 26.0752 822.931 25.809 821.894C25.5428 820.857 25.4097 819.628 25.4097 818.208C25.4097 816.983 25.5307 815.865 25.7727 814.852C26.0227 813.84 26.3898 812.974 26.8737 812.256C27.3658 811.529 27.9789 810.97 28.7129 810.578C29.447 810.178 30.302 809.978 31.2781 809.978C32.1251 809.978 32.8954 810.141 33.5892 810.468C34.2829 810.786 34.8758 811.284 35.3678 811.962C35.8599 812.632 36.239 813.485 36.5052 814.522C36.7714 815.558 36.9045 816.787 36.9045 818.208ZM34.7749 818.281C34.7749 818.004 34.7628 817.73 34.7386 817.461C34.7225 817.183 34.7023 816.914 34.6781 816.652L27.9869 821.686C28.1079 822.11 28.2612 822.502 28.4467 822.862C28.6323 823.221 28.8541 823.531 29.1122 823.792C29.3784 824.045 29.6769 824.245 30.0076 824.392C30.3464 824.531 30.7296 824.601 31.1571 824.601C31.7056 824.601 32.2017 824.466 32.6454 824.196C33.0971 823.927 33.4803 823.527 33.7949 822.996C34.1095 822.466 34.3514 821.808 34.5208 821.025C34.6902 820.233 34.7749 819.318 34.7749 818.281ZM27.5392 818.134C27.5392 818.387 27.5392 818.641 27.5392 818.894C27.5473 819.147 27.5634 819.392 27.5876 819.628L34.2789 814.62C34.1579 814.203 34.0046 813.824 33.8191 813.481C33.6335 813.138 33.4117 812.844 33.1536 812.599C32.8954 812.346 32.601 812.15 32.2703 812.011C31.9395 811.872 31.5685 811.803 31.1571 811.803C30.6086 811.803 30.1084 811.938 29.6567 812.207C29.2131 812.476 28.8339 812.881 28.5193 813.419C28.2047 813.95 27.9627 814.611 27.7933 815.403C27.6239 816.187 27.5392 817.097 27.5392 818.134Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 852.679H26.4745V850.695H30.54V839.012L26.7528 841.094L25.9784 839.281L31.0119 836.599H32.8753V850.695H36.4084V852.679Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 879.154H26.4745V877.17H30.54V865.486L26.7528 867.568L25.9784 865.756L31.0119 863.074H32.8753V877.17H36.4084V879.154Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 905.628H26.4745V903.644H30.54V891.961L26.7528 894.043L25.9784 892.23L31.0119 889.548H32.8753V903.644H36.4084V905.628Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 924.105C36.9045 925.33 36.7835 926.449 36.5415 927.461C36.2995 928.465 35.9325 929.331 35.4404 930.057C34.9484 930.776 34.3353 931.335 33.6013 931.735C32.8753 932.127 32.0202 932.323 31.0361 932.323C30.1891 932.323 29.4187 932.164 28.725 931.845C28.0313 931.519 27.4384 931.021 26.9463 930.351C26.4543 929.682 26.0752 928.828 25.809 927.792C25.5428 926.755 25.4097 925.526 25.4097 924.105C25.4097 922.881 25.5307 921.762 25.7727 920.75C26.0227 919.737 26.3898 918.872 26.8737 918.154C27.3658 917.427 27.9789 916.868 28.7129 916.476C29.447 916.076 30.302 915.876 31.2781 915.876C32.1251 915.876 32.8954 916.039 33.5892 916.366C34.2829 916.684 34.8758 917.182 35.3678 917.86C35.8599 918.529 36.239 919.382 36.5052 920.419C36.7714 921.456 36.9045 922.685 36.9045 924.105ZM34.7749 924.179C34.7749 923.901 34.7628 923.628 34.7386 923.358C34.7225 923.081 34.7023 922.811 34.6781 922.55L27.9869 927.583C28.1079 928.008 28.2612 928.4 28.4467 928.759C28.6323 929.118 28.8541 929.429 29.1122 929.69C29.3784 929.943 29.6769 930.143 30.0076 930.29C30.3464 930.429 30.7296 930.498 31.1571 930.498C31.7056 930.498 32.2017 930.363 32.6454 930.094C33.0971 929.825 33.4803 929.424 33.7949 928.894C34.1095 928.363 34.3514 927.706 34.5208 926.922C34.6902 926.13 34.7749 925.216 34.7749 924.179ZM27.5392 924.032C27.5392 924.285 27.5392 924.538 27.5392 924.791C27.5473 925.044 27.5634 925.289 27.5876 925.526L34.2789 920.517C34.1579 920.101 34.0046 919.721 33.8191 919.378C33.6335 919.035 33.4117 918.741 33.1536 918.496C32.8954 918.243 32.601 918.047 32.2703 917.909C31.9395 917.77 31.5685 917.7 31.1571 917.7C30.6086 917.7 30.1084 917.835 29.6567 918.105C29.2131 918.374 28.8339 918.778 28.5193 919.317C28.2047 919.848 27.9627 920.509 27.7933 921.301C27.6239 922.085 27.5392 922.995 27.5392 924.032Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 958.577H26.4745V956.593H30.54V944.91L26.7528 946.992L25.9784 945.179L31.0119 942.497H32.8753V956.593H36.4084V958.577Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 977.054C36.9045 978.279 36.7835 979.397 36.5415 980.41C36.2995 981.414 35.9325 982.279 35.4404 983.006C34.9484 983.724 34.3353 984.284 33.6013 984.684C32.8753 985.076 32.0202 985.272 31.0361 985.272C30.1891 985.272 29.4187 985.112 28.725 984.794C28.0313 984.467 27.4384 983.969 26.9463 983.3C26.4543 982.63 26.0752 981.777 25.809 980.74C25.5428 979.704 25.4097 978.475 25.4097 977.054C25.4097 975.83 25.5307 974.711 25.7727 973.699C26.0227 972.686 26.3898 971.821 26.8737 971.102C27.3658 970.376 27.9789 969.816 28.7129 969.425C29.447 969.025 30.302 968.824 31.2781 968.824C32.1251 968.824 32.8954 968.988 33.5892 969.314C34.2829 969.633 34.8758 970.131 35.3678 970.808C35.8599 971.478 36.239 972.331 36.5052 973.368C36.7714 974.405 36.9045 975.634 36.9045 977.054ZM34.7749 977.128C34.7749 976.85 34.7628 976.577 34.7386 976.307C34.7225 976.03 34.7023 975.76 34.6781 975.499L27.9869 980.532C28.1079 980.957 28.2612 981.349 28.4467 981.708C28.6323 982.067 28.8541 982.377 29.1122 982.639C29.3784 982.892 29.6769 983.092 30.0076 983.239C30.3464 983.378 30.7296 983.447 31.1571 983.447C31.7056 983.447 32.2017 983.312 32.6454 983.043C33.0971 982.773 33.4803 982.373 33.7949 981.843C34.1095 981.312 34.3514 980.655 34.5208 979.871C34.6902 979.079 34.7749 978.165 34.7749 977.128ZM27.5392 976.981C27.5392 977.234 27.5392 977.487 27.5392 977.74C27.5473 977.993 27.5634 978.238 27.5876 978.475L34.2789 973.466C34.1579 973.05 34.0046 972.67 33.8191 972.327C33.6335 971.984 33.4117 971.69 33.1536 971.445C32.8954 971.192 32.601 970.996 32.2703 970.857C31.9395 970.719 31.5685 970.649 31.1571 970.649C30.6086 970.649 30.1084 970.784 29.6567 971.053C29.2131 971.323 28.8339 971.727 28.5193 972.266C28.2047 972.796 27.9627 973.458 27.7933 974.25C27.6239 975.033 27.5392 975.944 27.5392 976.981Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.9045 1003.53C36.9045 1004.75 36.7835 1005.87 36.5415 1006.88C36.2995 1007.89 35.9325 1008.75 35.4404 1009.48C34.9484 1010.2 34.3353 1010.76 33.6013 1011.16C32.8753 1011.55 32.0202 1011.75 31.0361 1011.75C30.1891 1011.75 29.4187 1011.59 28.725 1011.27C28.0313 1010.94 27.4384 1010.44 26.9463 1009.77C26.4543 1009.1 26.0752 1008.25 25.809 1007.21C25.5428 1006.18 25.4097 1004.95 25.4097 1003.53C25.4097 1002.3 25.5307 1001.19 25.7727 1000.17C26.0227 999.161 26.3898 998.295 26.8737 997.577C27.3658 996.85 27.9789 996.291 28.7129 995.899C29.447 995.499 30.302 995.299 31.2781 995.299C32.1251 995.299 32.8954 995.462 33.5892 995.789C34.2829 996.107 34.8758 996.605 35.3678 997.283C35.8599 997.952 36.239 998.805 36.5052 999.842C36.7714 1000.88 36.9045 1002.11 36.9045 1003.53ZM34.7749 1003.6C34.7749 1003.32 34.7628 1003.05 34.7386 1002.78C34.7225 1002.5 34.7023 1002.23 34.6781 1001.97L27.9869 1007.01C28.1079 1007.43 28.2612 1007.82 28.4467 1008.18C28.6323 1008.54 28.8541 1008.85 29.1122 1009.11C29.3784 1009.37 29.6769 1009.57 30.0076 1009.71C30.3464 1009.85 30.7296 1009.92 31.1571 1009.92C31.7056 1009.92 32.2017 1009.79 32.6454 1009.52C33.0971 1009.25 33.4803 1008.85 33.7949 1008.32C34.1095 1007.79 34.3514 1007.13 34.5208 1006.35C34.6902 1005.55 34.7749 1004.64 34.7749 1003.6ZM27.5392 1003.46C27.5392 1003.71 27.5392 1003.96 27.5392 1004.21C27.5473 1004.47 27.5634 1004.71 27.5876 1004.95L34.2789 999.94C34.1579 999.524 34.0046 999.144 33.8191 998.801C33.6335 998.458 33.4117 998.165 33.1536 997.92C32.8954 997.667 32.601 997.471 32.2703 997.332C31.9395 997.193 31.5685 997.124 31.1571 997.124C30.6086 997.124 30.1084 997.258 29.6567 997.528C29.2131 997.797 28.8339 998.201 28.5193 998.74C28.2047 999.271 27.9627 999.932 27.7933 1000.72C27.6239 1001.51 27.5392 1002.42 27.5392 1003.46Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M36.4084 1038H26.4745V1036.02H30.54V1024.33L26.7528 1026.41L25.9784 1024.6L31.0119 1021.92H32.8753V1036.02H36.4084V1038Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M242.896 173.402H232.41V171.308H236.701V158.976L232.703 161.173L231.886 159.26L237.199 156.429H239.166V171.308H242.896V173.402Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 201.27H232.41V199.176H236.701V186.843L232.703 189.041L231.886 187.128L237.199 184.297H239.166V199.176H242.896V201.27Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 220.696C243.419 221.989 243.292 223.17 243.036 224.238C242.781 225.298 242.393 226.212 241.874 226.979C241.354 227.737 240.707 228.328 239.933 228.75C239.166 229.163 238.264 229.37 237.225 229.37C236.331 229.37 235.518 229.202 234.785 228.866C234.053 228.521 233.427 227.996 232.908 227.289C232.388 226.582 231.988 225.682 231.707 224.587C231.426 223.493 231.286 222.196 231.286 220.696C231.286 219.404 231.413 218.223 231.669 217.154C231.933 216.086 232.32 215.172 232.831 214.414C233.351 213.647 233.998 213.056 234.773 212.643C235.547 212.221 236.45 212.009 237.48 212.009C238.374 212.009 239.187 212.182 239.92 212.526C240.652 212.863 241.278 213.388 241.797 214.104C242.317 214.81 242.717 215.711 242.998 216.805C243.279 217.9 243.419 219.197 243.419 220.696ZM241.171 220.774C241.171 220.481 241.159 220.192 241.133 219.908C241.116 219.615 241.095 219.33 241.069 219.055L234.006 224.368C234.134 224.816 234.296 225.229 234.492 225.609C234.687 225.988 234.922 226.315 235.194 226.591C235.475 226.858 235.79 227.069 236.139 227.224C236.497 227.371 236.901 227.444 237.353 227.444C237.932 227.444 238.455 227.302 238.924 227.018C239.4 226.733 239.805 226.311 240.137 225.751C240.469 225.191 240.724 224.497 240.903 223.67C241.082 222.834 241.171 221.868 241.171 220.774ZM233.534 220.619C233.534 220.886 233.534 221.153 233.534 221.42C233.542 221.687 233.559 221.946 233.585 222.196L240.648 216.909C240.52 216.469 240.358 216.068 240.162 215.707C239.967 215.345 239.732 215.034 239.46 214.776C239.187 214.509 238.877 214.302 238.528 214.155C238.178 214.009 237.787 213.936 237.353 213.936C236.774 213.936 236.246 214.078 235.769 214.362C235.3 214.646 234.9 215.073 234.568 215.642C234.236 216.202 233.981 216.9 233.802 217.736C233.623 218.563 233.534 219.524 233.534 220.619Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 248.564C243.419 249.857 243.292 251.037 243.036 252.106C242.781 253.166 242.393 254.08 241.874 254.847C241.354 255.605 240.707 256.195 239.933 256.618C239.166 257.031 238.264 257.238 237.225 257.238C236.331 257.238 235.518 257.07 234.785 256.734C234.053 256.389 233.427 255.864 232.908 255.157C232.388 254.45 231.988 253.55 231.707 252.455C231.426 251.361 231.286 250.064 231.286 248.564C231.286 247.271 231.413 246.091 231.669 245.022C231.933 243.953 232.32 243.04 232.831 242.282C233.351 241.515 233.998 240.924 234.773 240.511C235.547 240.088 236.45 239.877 237.48 239.877C238.374 239.877 239.187 240.05 239.92 240.394C240.652 240.73 241.278 241.256 241.797 241.971C242.317 242.678 242.717 243.579 242.998 244.673C243.279 245.768 243.419 247.065 243.419 248.564ZM241.171 248.642C241.171 248.349 241.159 248.06 241.133 247.776C241.116 247.483 241.095 247.198 241.069 246.922L234.006 252.235C234.134 252.683 234.296 253.097 234.492 253.476C234.687 253.856 234.922 254.183 235.194 254.459C235.475 254.726 235.79 254.937 236.139 255.092C236.497 255.239 236.901 255.312 237.353 255.312C237.932 255.312 238.455 255.17 238.924 254.885C239.4 254.601 239.805 254.179 240.137 253.619C240.469 253.058 240.724 252.365 240.903 251.537C241.082 250.701 241.171 249.736 241.171 248.642ZM233.534 248.487C233.534 248.754 233.534 249.021 233.534 249.288C233.542 249.555 233.559 249.814 233.585 250.064L240.648 244.776C240.52 244.337 240.358 243.936 240.162 243.574C239.967 243.212 239.732 242.902 239.46 242.644C239.187 242.376 238.877 242.17 238.528 242.023C238.178 241.877 237.787 241.803 237.353 241.803C236.774 241.803 236.246 241.945 235.769 242.23C235.3 242.514 234.9 242.941 234.568 243.51C234.236 244.07 233.981 244.768 233.802 245.604C233.623 246.431 233.534 247.392 233.534 248.487Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 276.432C243.419 277.725 243.292 278.905 243.036 279.974C242.781 281.034 242.393 281.947 241.874 282.714C241.354 283.473 240.707 284.063 239.933 284.485C239.166 284.899 238.264 285.106 237.225 285.106C236.331 285.106 235.518 284.938 234.785 284.602C234.053 284.257 233.427 283.731 232.908 283.025C232.388 282.318 231.988 281.417 231.707 280.323C231.426 279.228 231.286 277.931 231.286 276.432C231.286 275.139 231.413 273.959 231.669 272.89C231.933 271.821 232.32 270.908 232.831 270.149C233.351 269.382 233.998 268.792 234.773 268.378C235.547 267.956 236.45 267.745 237.48 267.745C238.374 267.745 239.187 267.917 239.92 268.262C240.652 268.598 241.278 269.124 241.797 269.839C242.317 270.546 242.717 271.446 242.998 272.541C243.279 273.635 243.419 274.932 243.419 276.432ZM241.171 276.509C241.171 276.216 241.159 275.928 241.133 275.643C241.116 275.35 241.095 275.066 241.069 274.79L234.006 280.103C234.134 280.551 234.296 280.965 234.492 281.344C234.687 281.723 234.922 282.051 235.194 282.327C235.475 282.594 235.79 282.805 236.139 282.96C236.497 283.106 236.901 283.18 237.353 283.18C237.932 283.18 238.455 283.038 238.924 282.753C239.4 282.469 239.805 282.046 240.137 281.486C240.469 280.926 240.724 280.232 240.903 279.405C241.082 278.569 241.171 277.604 241.171 276.509ZM233.534 276.354C233.534 276.621 233.534 276.889 233.534 277.156C233.542 277.423 233.559 277.681 233.585 277.931L240.648 272.644C240.52 272.205 240.358 271.804 240.162 271.442C239.967 271.08 239.732 270.77 239.46 270.511C239.187 270.244 238.877 270.037 238.528 269.891C238.178 269.744 237.787 269.671 237.353 269.671C236.774 269.671 236.246 269.813 235.769 270.098C235.3 270.382 234.9 270.809 234.568 271.377C234.236 271.938 233.981 272.636 233.802 273.472C233.623 274.299 233.534 275.26 233.534 276.354Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 312.741H232.41V310.647H236.701V298.314L232.703 300.512L231.886 298.599L237.199 295.768H239.166V310.647H242.896V312.741Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 332.167C243.419 333.46 243.292 334.641 243.036 335.709C242.781 336.769 242.393 337.683 241.874 338.45C241.354 339.208 240.707 339.799 239.933 340.221C239.166 340.635 238.264 340.841 237.225 340.841C236.331 340.841 235.518 340.673 234.785 340.337C234.053 339.993 233.427 339.467 232.908 338.76C232.388 338.054 231.988 337.153 231.707 336.058C231.426 334.964 231.286 333.667 231.286 332.167C231.286 330.875 231.413 329.694 231.669 328.625C231.933 327.557 232.32 326.643 232.831 325.885C233.351 325.118 233.998 324.528 234.773 324.114C235.547 323.692 236.45 323.481 237.48 323.481C238.374 323.481 239.187 323.653 239.92 323.998C240.652 324.334 241.278 324.859 241.797 325.575C242.317 326.281 242.717 327.182 242.998 328.276C243.279 329.371 243.419 330.668 243.419 332.167ZM241.171 332.245C241.171 331.952 241.159 331.663 241.133 331.379C241.116 331.086 241.095 330.801 241.069 330.526L234.006 335.839C234.134 336.287 234.296 336.7 234.492 337.08C234.687 337.459 234.922 337.786 235.194 338.062C235.475 338.329 235.79 338.54 236.139 338.696C236.497 338.842 236.901 338.915 237.353 338.915C237.932 338.915 238.455 338.773 238.924 338.489C239.4 338.204 239.805 337.782 240.137 337.222C240.469 336.662 240.724 335.968 240.903 335.141C241.082 334.305 241.171 333.339 241.171 332.245ZM233.534 332.09C233.534 332.357 233.534 332.624 233.534 332.891C233.542 333.159 233.559 333.417 233.585 333.667L240.648 328.38C240.52 327.94 240.358 327.54 240.162 327.178C239.967 326.816 239.732 326.505 239.46 326.247C239.187 325.98 238.877 325.773 238.528 325.626C238.178 325.48 237.787 325.407 237.353 325.407C236.774 325.407 236.246 325.549 235.769 325.833C235.3 326.118 234.9 326.544 234.568 327.113C234.236 327.673 233.981 328.371 233.802 329.207C233.623 330.034 233.534 330.995 233.534 332.09Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 368.477H232.41V366.382H236.701V354.05L232.703 356.248L231.886 354.334L237.199 351.503H239.166V366.382H242.896V368.477Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 396.344H232.41V394.25H236.701V381.918L232.703 384.115L231.886 382.202L237.199 379.371H239.166V394.25H242.896V396.344Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 415.771C243.419 417.063 243.292 418.244 243.036 419.313C242.781 420.373 242.393 421.286 241.874 422.053C241.354 422.812 240.707 423.402 239.933 423.824C239.166 424.238 238.264 424.445 237.225 424.445C236.331 424.445 235.518 424.277 234.785 423.941C234.053 423.596 233.427 423.07 232.908 422.364C232.388 421.657 231.988 420.756 231.707 419.662C231.426 418.567 231.286 417.27 231.286 415.771C231.286 414.478 231.413 413.297 231.669 412.229C231.933 411.16 232.32 410.247 232.831 409.488C233.351 408.721 233.998 408.131 234.773 407.717C235.547 407.295 236.45 407.084 237.48 407.084C238.374 407.084 239.187 407.256 239.92 407.601C240.652 407.937 241.278 408.463 241.797 409.178C242.317 409.885 242.717 410.785 242.998 411.88C243.279 412.974 243.419 414.271 243.419 415.771ZM241.171 415.848C241.171 415.555 241.159 415.267 241.133 414.982C241.116 414.689 241.095 414.405 241.069 414.129L234.006 419.442C234.134 419.89 234.296 420.304 234.492 420.683C234.687 421.062 234.922 421.39 235.194 421.665C235.475 421.933 235.79 422.144 236.139 422.299C236.497 422.445 236.901 422.519 237.353 422.519C237.932 422.519 238.455 422.376 238.924 422.092C239.4 421.808 239.805 421.385 240.137 420.825C240.469 420.265 240.724 419.571 240.903 418.744C241.082 417.908 241.171 416.943 241.171 415.848ZM233.534 415.693C233.534 415.96 233.534 416.228 233.534 416.495C233.542 416.762 233.559 417.02 233.585 417.27L240.648 411.983C240.52 411.544 240.358 411.143 240.162 410.781C239.967 410.419 239.732 410.109 239.46 409.85C239.187 409.583 238.877 409.376 238.528 409.23C238.178 409.083 237.787 409.01 237.353 409.01C236.774 409.01 236.246 409.152 235.769 409.437C235.3 409.721 234.9 410.148 234.568 410.716C234.236 411.277 233.981 411.975 233.802 412.811C233.623 413.638 233.534 414.599 233.534 415.693Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 443.639C243.419 444.931 243.292 446.112 243.036 447.181C242.781 448.241 242.393 449.154 241.874 449.921C241.354 450.679 240.707 451.27 239.933 451.692C239.166 452.106 238.264 452.313 237.225 452.313C236.331 452.313 235.518 452.145 234.785 451.808C234.053 451.464 233.427 450.938 232.908 450.231C232.388 449.525 231.988 448.624 231.707 447.53C231.426 446.435 231.286 445.138 231.286 443.639C231.286 442.346 231.413 441.165 231.669 440.097C231.933 439.028 232.32 438.114 232.831 437.356C233.351 436.589 233.998 435.999 234.773 435.585C235.547 435.163 236.45 434.952 237.48 434.952C238.374 434.952 239.187 435.124 239.92 435.469C240.652 435.805 241.278 436.331 241.797 437.046C242.317 437.753 242.717 438.653 242.998 439.748C243.279 440.842 243.419 442.139 243.419 443.639ZM241.171 443.716C241.171 443.423 241.159 443.134 241.133 442.85C241.116 442.557 241.095 442.273 241.069 441.997L234.006 447.31C234.134 447.758 234.296 448.172 234.492 448.551C234.687 448.93 234.922 449.257 235.194 449.533C235.475 449.8 235.79 450.012 236.139 450.167C236.497 450.313 236.901 450.386 237.353 450.386C237.932 450.386 238.455 450.244 238.924 449.96C239.4 449.675 239.805 449.253 240.137 448.693C240.469 448.133 240.724 447.439 240.903 446.612C241.082 445.776 241.171 444.811 241.171 443.716ZM233.534 443.561C233.534 443.828 233.534 444.095 233.534 444.362C233.542 444.63 233.559 444.888 233.585 445.138L240.648 439.851C240.52 439.411 240.358 439.011 240.162 438.649C239.967 438.287 239.732 437.977 239.46 437.718C239.187 437.451 238.877 437.244 238.528 437.098C238.178 436.951 237.787 436.878 237.353 436.878C236.774 436.878 236.246 437.02 235.769 437.304C235.3 437.589 234.9 438.015 234.568 438.584C234.236 439.144 233.981 439.842 233.802 440.678C233.623 441.506 233.534 442.467 233.534 443.561Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 471.506C243.419 472.799 243.292 473.98 243.036 475.048C242.781 476.108 242.393 477.022 241.874 477.789C241.354 478.547 240.707 479.138 239.933 479.56C239.166 479.974 238.264 480.18 237.225 480.18C236.331 480.18 235.518 480.012 234.785 479.676C234.053 479.331 233.427 478.806 232.908 478.099C232.388 477.392 231.988 476.492 231.707 475.397C231.426 474.303 231.286 473.006 231.286 471.506C231.286 470.214 231.413 469.033 231.669 467.964C231.933 466.896 232.32 465.982 232.831 465.224C233.351 464.457 233.998 463.867 234.773 463.453C235.547 463.031 236.45 462.819 237.48 462.819C238.374 462.819 239.187 462.992 239.92 463.337C240.652 463.673 241.278 464.198 241.797 464.914C242.317 465.62 242.717 466.521 242.998 467.615C243.279 468.71 243.419 470.007 243.419 471.506ZM241.171 471.584C241.171 471.291 241.159 471.002 241.133 470.718C241.116 470.425 241.095 470.14 241.069 469.865L234.006 475.178C234.134 475.626 234.296 476.039 234.492 476.419C234.687 476.798 234.922 477.125 235.194 477.401C235.475 477.668 235.79 477.879 236.139 478.034C236.497 478.181 236.901 478.254 237.353 478.254C237.932 478.254 238.455 478.112 238.924 477.828C239.4 477.543 239.805 477.121 240.137 476.561C240.469 476.001 240.724 475.307 240.903 474.48C241.082 473.644 241.171 472.678 241.171 471.584ZM233.534 471.429C233.534 471.696 233.534 471.963 233.534 472.23C233.542 472.497 233.559 472.756 233.585 473.006L240.648 467.719C240.52 467.279 240.358 466.879 240.162 466.517C239.967 466.155 239.732 465.844 239.46 465.586C239.187 465.319 238.877 465.112 238.528 464.965C238.178 464.819 237.787 464.746 237.353 464.746C236.774 464.746 236.246 464.888 235.769 465.172C235.3 465.457 234.9 465.883 234.568 466.452C234.236 467.012 233.981 467.71 233.802 468.546C233.623 469.373 233.534 470.334 233.534 471.429Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 507.815H232.41V505.721H236.701V493.389L232.703 495.587L231.886 493.673L237.199 490.842H239.166V505.721H242.896V507.815Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 535.683H232.41V533.589H236.701V521.257L232.703 523.454L231.886 521.541L237.199 518.71H239.166V533.589H242.896V535.683Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 563.551H232.41V561.457H236.701V549.125L232.703 551.322L231.886 549.409L237.199 546.578H239.166V561.457H242.896V563.551Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 582.977C243.419 584.27 243.292 585.451 243.036 586.519C242.781 587.579 242.393 588.493 241.874 589.26C241.354 590.018 240.707 590.609 239.933 591.031C239.166 591.445 238.264 591.651 237.225 591.651C236.331 591.651 235.518 591.483 234.785 591.147C234.053 590.803 233.427 590.277 232.908 589.57C232.388 588.864 231.988 587.963 231.707 586.868C231.426 585.774 231.286 584.477 231.286 582.977C231.286 581.685 231.413 580.504 231.669 579.435C231.933 578.367 232.32 577.453 232.831 576.695C233.351 575.928 233.998 575.338 234.773 574.924C235.547 574.502 236.45 574.291 237.48 574.291C238.374 574.291 239.187 574.463 239.92 574.808C240.652 575.144 241.278 575.669 241.797 576.385C242.317 577.091 242.717 577.992 242.998 579.086C243.279 580.181 243.419 581.478 243.419 582.977ZM241.171 583.055C241.171 582.762 241.159 582.473 241.133 582.189C241.116 581.896 241.095 581.612 241.069 581.336L234.006 586.649C234.134 587.097 234.296 587.511 234.492 587.89C234.687 588.269 234.922 588.596 235.194 588.872C235.475 589.139 235.79 589.35 236.139 589.506C236.497 589.652 236.901 589.725 237.353 589.725C237.932 589.725 238.455 589.583 238.924 589.299C239.4 589.014 239.805 588.592 240.137 588.032C240.469 587.472 240.724 586.778 240.903 585.951C241.082 585.115 241.171 584.15 241.171 583.055ZM233.534 582.9C233.534 583.167 233.534 583.434 233.534 583.701C233.542 583.969 233.559 584.227 233.585 584.477L240.648 579.19C240.52 578.75 240.358 578.35 240.162 577.988C239.967 577.626 239.732 577.315 239.46 577.057C239.187 576.79 238.877 576.583 238.528 576.436C238.178 576.29 237.787 576.217 237.353 576.217C236.774 576.217 236.246 576.359 235.769 576.643C235.3 576.928 234.9 577.354 234.568 577.923C234.236 578.483 233.981 579.181 233.802 580.017C233.623 580.845 233.534 581.805 233.534 582.9Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 619.287H232.41V617.192H236.701V604.86L232.703 607.058L231.886 605.144L237.199 602.313H239.166V617.192H242.896V619.287Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 638.713C243.419 640.006 243.292 641.186 243.036 642.255C242.781 643.315 242.393 644.229 241.874 644.996C241.354 645.754 240.707 646.344 239.933 646.767C239.166 647.18 238.264 647.387 237.225 647.387C236.331 647.387 235.518 647.219 234.785 646.883C234.053 646.538 233.427 646.012 232.908 645.306C232.388 644.599 231.988 643.699 231.707 642.604C231.426 641.51 231.286 640.213 231.286 638.713C231.286 637.42 231.413 636.24 231.669 635.171C231.933 634.102 232.32 633.189 232.831 632.431C233.351 631.664 233.998 631.073 234.773 630.66C235.547 630.237 236.45 630.026 237.48 630.026C238.374 630.026 239.187 630.198 239.92 630.543C240.652 630.879 241.278 631.405 241.797 632.12C242.317 632.827 242.717 633.728 242.998 634.822C243.279 635.917 243.419 637.214 243.419 638.713ZM241.171 638.791C241.171 638.498 241.159 638.209 241.133 637.924C241.116 637.631 241.095 637.347 241.069 637.071L234.006 642.384C234.134 642.832 234.296 643.246 234.492 643.625C234.687 644.004 234.922 644.332 235.194 644.608C235.475 644.875 235.79 645.086 236.139 645.241C236.497 645.388 236.901 645.461 237.353 645.461C237.932 645.461 238.455 645.319 238.924 645.034C239.4 644.75 239.805 644.328 240.137 643.767C240.469 643.207 240.724 642.514 240.903 641.686C241.082 640.85 241.171 639.885 241.171 638.791ZM233.534 638.635C233.534 638.903 233.534 639.17 233.534 639.437C233.542 639.704 233.559 639.963 233.585 640.213L240.648 634.925C240.52 634.486 240.358 634.085 240.162 633.723C239.967 633.361 239.732 633.051 239.46 632.792C239.187 632.525 238.877 632.319 238.528 632.172C238.178 632.025 237.787 631.952 237.353 631.952C236.774 631.952 236.246 632.094 235.769 632.379C235.3 632.663 234.9 633.09 234.568 633.659C234.236 634.219 233.981 634.917 233.802 635.753C233.623 636.58 233.534 637.541 233.534 638.635Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M243.419 666.581C243.419 667.874 243.292 669.054 243.036 670.123C242.781 671.183 242.393 672.096 241.874 672.863C241.354 673.622 240.707 674.212 239.933 674.634C239.166 675.048 238.264 675.255 237.225 675.255C236.331 675.255 235.518 675.087 234.785 674.751C234.053 674.406 233.427 673.88 232.908 673.174C232.388 672.467 231.988 671.566 231.707 670.472C231.426 669.377 231.286 668.08 231.286 666.581C231.286 665.288 231.413 664.107 231.669 663.039C231.933 661.97 232.32 661.057 232.831 660.298C233.351 659.531 233.998 658.941 234.773 658.527C235.547 658.105 236.45 657.894 237.48 657.894C238.374 657.894 239.187 658.066 239.92 658.411C240.652 658.747 241.278 659.273 241.797 659.988C242.317 660.695 242.717 661.595 242.998 662.69C243.279 663.784 243.419 665.081 243.419 666.581ZM241.171 666.658C241.171 666.365 241.159 666.077 241.133 665.792C241.116 665.499 241.095 665.215 241.069 664.939L234.006 670.252C234.134 670.7 234.296 671.114 234.492 671.493C234.687 671.872 234.922 672.2 235.194 672.476C235.475 672.743 235.79 672.954 236.139 673.109C236.497 673.255 236.901 673.329 237.353 673.329C237.932 673.329 238.455 673.186 238.924 672.902C239.4 672.618 239.805 672.195 240.137 671.635C240.469 671.075 240.724 670.381 240.903 669.554C241.082 668.718 241.171 667.753 241.171 666.658ZM233.534 666.503C233.534 666.77 233.534 667.038 233.534 667.305C233.542 667.572 233.559 667.83 233.585 668.08L240.648 662.793C240.52 662.354 240.358 661.953 240.162 661.591C239.967 661.229 239.732 660.919 239.46 660.66C239.187 660.393 238.877 660.186 238.528 660.04C238.178 659.893 237.787 659.82 237.353 659.82C236.774 659.82 236.246 659.962 235.769 660.247C235.3 660.531 234.9 660.958 234.568 661.526C234.236 662.087 233.981 662.785 233.802 663.621C233.623 664.448 233.534 665.409 233.534 666.503Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M242.896 702.89H232.41V700.796H236.701V688.463L232.703 690.661L231.886 688.748L237.199 685.917H239.166V700.796H242.896V702.89Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 31.9731H1.12396V29.8789H5.41542V17.5466L1.41772 19.7442L0.600295 17.831L5.91354 15H7.88046V29.8789H11.61V31.9731Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 59.8409H1.12396V57.7467H5.41542V45.4144L1.41772 47.612L0.600295 45.6988L5.91354 42.8678H7.88046V57.7467H11.61V59.8409Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 79.2673C12.1336 80.56 12.0059 81.7407 11.7504 82.8093C11.495 83.8693 11.1076 84.7829 10.5882 85.5499C10.0688 86.3082 9.42165 86.8986 8.6468 87.3208C7.88046 87.7345 6.97789 87.9413 5.93909 87.9413C5.04503 87.9413 4.23186 87.7733 3.49959 87.4372C2.76732 87.0925 2.14148 86.5668 1.62207 85.8601C1.10267 85.1534 0.702472 84.2528 0.421483 83.1584C0.140494 82.0639 0 80.7669 0 79.2673C0 77.9747 0.127722 76.794 0.383167 75.7254C0.647126 74.6567 1.03455 73.7432 1.54544 72.9849C2.06484 72.2179 2.71197 71.6275 3.48682 71.2139C4.26167 70.7916 5.16424 70.5804 6.19453 70.5804C7.08859 70.5804 7.90175 70.7528 8.63402 71.0975C9.3663 71.4336 9.99214 71.9593 10.5115 72.6746C11.0309 73.3813 11.4311 74.2819 11.7121 75.3763C11.9931 76.4708 12.1336 77.7678 12.1336 79.2673ZM9.8857 79.3449C9.8857 79.0519 9.87293 78.7632 9.84739 78.4788C9.83036 78.1858 9.80907 77.9014 9.78352 77.6256L2.72048 82.9386C2.84821 83.3867 3.00999 83.8004 3.20583 84.1796C3.40167 84.5588 3.63583 84.8863 3.9083 85.162C4.18929 85.4292 4.50434 85.6403 4.85345 85.7955C5.21107 85.942 5.61552 86.0152 6.06681 86.0152C6.64581 86.0152 7.16948 85.873 7.63779 85.5886C8.11462 85.3042 8.51907 84.882 8.85115 84.3218C9.18323 83.7616 9.43867 83.0679 9.61749 82.2405C9.7963 81.4046 9.8857 80.4394 9.8857 79.3449ZM2.24791 79.1898C2.24791 79.4569 2.24791 79.7241 2.24791 79.9913C2.25643 80.2584 2.27346 80.517 2.299 80.7669L9.36204 75.4798C9.23432 75.0402 9.07254 74.6395 8.8767 74.2775C8.68086 73.9156 8.4467 73.6053 8.17422 73.3468C7.90175 73.0797 7.59096 72.8728 7.24185 72.7263C6.89274 72.5798 6.50106 72.5066 6.06681 72.5066C5.4878 72.5066 4.95988 72.6488 4.48305 72.9331C4.01474 73.2175 3.61454 73.6441 3.28246 74.2129C2.95038 74.7731 2.69494 75.4711 2.51613 76.3071C2.33732 77.1344 2.24791 78.0953 2.24791 79.1898Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 107.135C12.1336 108.428 12.0059 109.608 11.7504 110.677C11.495 111.737 11.1076 112.651 10.5882 113.418C10.0688 114.176 9.42165 114.766 8.6468 115.189C7.88046 115.602 6.97789 115.809 5.93909 115.809C5.04503 115.809 4.23186 115.641 3.49959 115.305C2.76732 114.96 2.14148 114.435 1.62207 113.728C1.10267 113.021 0.702472 112.121 0.421483 111.026C0.140494 109.932 0 108.635 0 107.135C0 105.842 0.127722 104.662 0.383167 103.593C0.647126 102.525 1.03455 101.611 1.54544 100.853C2.06484 100.086 2.71197 99.4953 3.48682 99.0816C4.26167 98.6594 5.16424 98.4482 6.19453 98.4482C7.08859 98.4482 7.90175 98.6206 8.63402 98.9653C9.3663 99.3014 9.99214 99.8271 10.5115 100.542C11.0309 101.249 11.4311 102.15 11.7121 103.244C11.9931 104.339 12.1336 105.636 12.1336 107.135ZM9.8857 107.213C9.8857 106.92 9.87293 106.631 9.84739 106.347C9.83036 106.054 9.80907 105.769 9.78352 105.493L2.72048 110.806C2.84821 111.255 3.00999 111.668 3.20583 112.047C3.40167 112.427 3.63583 112.754 3.9083 113.03C4.18929 113.297 4.50434 113.508 4.85345 113.663C5.21107 113.81 5.61552 113.883 6.06681 113.883C6.64581 113.883 7.16948 113.741 7.63779 113.456C8.11462 113.172 8.51907 112.75 8.85115 112.19C9.18323 111.629 9.43867 110.936 9.61749 110.108C9.7963 109.272 9.8857 108.307 9.8857 107.213ZM2.24791 107.058C2.24791 107.325 2.24791 107.592 2.24791 107.859C2.25643 108.126 2.27346 108.385 2.299 108.635L9.36204 103.348C9.23432 102.908 9.07254 102.507 8.8767 102.145C8.68086 101.783 8.4467 101.473 8.17422 101.215C7.90175 100.947 7.59096 100.741 7.24185 100.594C6.89274 100.448 6.50106 100.374 6.06681 100.374C5.4878 100.374 4.95988 100.517 4.48305 100.801C4.01474 101.085 3.61454 101.512 3.28246 102.081C2.95038 102.641 2.69494 103.339 2.51613 104.175C2.33732 105.002 2.24791 105.963 2.24791 107.058Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 135.003C12.1336 136.296 12.0059 137.476 11.7504 138.545C11.495 139.605 11.1076 140.518 10.5882 141.285C10.0688 142.044 9.42165 142.634 8.6468 143.056C7.88046 143.47 6.97789 143.677 5.93909 143.677C5.04503 143.677 4.23186 143.509 3.49959 143.173C2.76732 142.828 2.14148 142.302 1.62207 141.596C1.10267 140.889 0.702472 139.988 0.421483 138.894C0.140494 137.799 0 136.502 0 135.003C0 133.71 0.127722 132.53 0.383167 131.461C0.647126 130.392 1.03455 129.479 1.54544 128.72C2.06484 127.953 2.71197 127.363 3.48682 126.949C4.26167 126.527 5.16424 126.316 6.19453 126.316C7.08859 126.316 7.90175 126.488 8.63402 126.833C9.3663 127.169 9.99214 127.695 10.5115 128.41C11.0309 129.117 11.4311 130.017 11.7121 131.112C11.9931 132.206 12.1336 133.503 12.1336 135.003ZM9.8857 135.08C9.8857 134.787 9.87293 134.499 9.84739 134.214C9.83036 133.921 9.80907 133.637 9.78352 133.361L2.72048 138.674C2.84821 139.122 3.00999 139.536 3.20583 139.915C3.40167 140.294 3.63583 140.622 3.9083 140.898C4.18929 141.165 4.50434 141.376 4.85345 141.531C5.21107 141.678 5.61552 141.751 6.06681 141.751C6.64581 141.751 7.16948 141.609 7.63779 141.324C8.11462 141.04 8.51907 140.618 8.85115 140.057C9.18323 139.497 9.43867 138.803 9.61749 137.976C9.7963 137.14 9.8857 136.175 9.8857 135.08ZM2.24791 134.925C2.24791 135.193 2.24791 135.46 2.24791 135.727C2.25643 135.994 2.27346 136.253 2.299 136.502L9.36204 131.215C9.23432 130.776 9.07254 130.375 8.8767 130.013C8.68086 129.651 8.4467 129.341 8.17422 129.082C7.90175 128.815 7.59096 128.608 7.24185 128.462C6.89274 128.315 6.50106 128.242 6.06681 128.242C5.4878 128.242 4.95988 128.384 4.48305 128.669C4.01474 128.953 3.61454 129.38 3.28246 129.948C2.95038 130.509 2.69494 131.207 2.51613 132.043C2.33732 132.87 2.24791 133.831 2.24791 134.925Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 171.312H1.12396V169.218H5.41542V156.886L1.41772 159.083L0.600295 157.17L5.91354 154.339H7.88046V169.218H11.61V171.312Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 190.738C12.1336 192.031 12.0059 193.212 11.7504 194.28C11.495 195.34 11.1076 196.254 10.5882 197.021C10.0688 197.779 9.42165 198.37 8.6468 198.792C7.88046 199.206 6.97789 199.412 5.93909 199.412C5.04503 199.412 4.23186 199.244 3.49959 198.908C2.76732 198.564 2.14148 198.038 1.62207 197.331C1.10267 196.625 0.702472 195.724 0.421483 194.629C0.140494 193.535 0 192.238 0 190.738C0 189.446 0.127722 188.265 0.383167 187.197C0.647126 186.128 1.03455 185.214 1.54544 184.456C2.06484 183.689 2.71197 183.099 3.48682 182.685C4.26167 182.263 5.16424 182.052 6.19453 182.052C7.08859 182.052 7.90175 182.224 8.63402 182.569C9.3663 182.905 9.99214 183.43 10.5115 184.146C11.0309 184.852 11.4311 185.753 11.7121 186.847C11.9931 187.942 12.1336 189.239 12.1336 190.738ZM9.8857 190.816C9.8857 190.523 9.87293 190.234 9.84739 189.95C9.83036 189.657 9.80907 189.373 9.78352 189.097L2.72048 194.41C2.84821 194.858 3.00999 195.272 3.20583 195.651C3.40167 196.03 3.63583 196.357 3.9083 196.633C4.18929 196.9 4.50434 197.111 4.85345 197.267C5.21107 197.413 5.61552 197.486 6.06681 197.486C6.64581 197.486 7.16948 197.344 7.63779 197.06C8.11462 196.775 8.51907 196.353 8.85115 195.793C9.18323 195.233 9.43867 194.539 9.61749 193.712C9.7963 192.876 9.8857 191.911 9.8857 190.816ZM2.24791 190.661C2.24791 190.928 2.24791 191.195 2.24791 191.462C2.25643 191.73 2.27346 191.988 2.299 192.238L9.36204 186.951C9.23432 186.511 9.07254 186.111 8.8767 185.749C8.68086 185.387 8.4467 185.076 8.17422 184.818C7.90175 184.551 7.59096 184.344 7.24185 184.197C6.89274 184.051 6.50106 183.978 6.06681 183.978C5.4878 183.978 4.95988 184.12 4.48305 184.404C4.01474 184.689 3.61454 185.115 3.28246 185.684C2.95038 186.244 2.69494 186.942 2.51613 187.778C2.33732 188.606 2.24791 189.566 2.24791 190.661Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 227.048H1.12396V224.953H5.41542V212.621L1.41772 214.819L0.600295 212.905L5.91354 210.074H7.88046V224.953H11.61V227.048Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 254.915H1.12396V252.821H5.41542V240.489L1.41772 242.686L0.600295 240.773L5.91354 237.942H7.88046V252.821H11.61V254.915Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 274.342C12.1336 275.635 12.0059 276.815 11.7504 277.884C11.495 278.944 11.1076 279.857 10.5882 280.624C10.0688 281.383 9.42165 281.973 8.6468 282.395C7.88046 282.809 6.97789 283.016 5.93909 283.016C5.04503 283.016 4.23186 282.848 3.49959 282.512C2.76732 282.167 2.14148 281.641 1.62207 280.935C1.10267 280.228 0.702472 279.327 0.421483 278.233C0.140494 277.138 0 275.841 0 274.342C0 273.049 0.127722 271.868 0.383167 270.8C0.647126 269.731 1.03455 268.818 1.54544 268.059C2.06484 267.292 2.71197 266.702 3.48682 266.288C4.26167 265.866 5.16424 265.655 6.19453 265.655C7.08859 265.655 7.90175 265.827 8.63402 266.172C9.3663 266.508 9.99214 267.034 10.5115 267.749C11.0309 268.456 11.4311 269.356 11.7121 270.451C11.9931 271.545 12.1336 272.842 12.1336 274.342ZM9.8857 274.419C9.8857 274.126 9.87293 273.838 9.84739 273.553C9.83036 273.26 9.80907 272.976 9.78352 272.7L2.72048 278.013C2.84821 278.461 3.00999 278.875 3.20583 279.254C3.40167 279.633 3.63583 279.961 3.9083 280.237C4.18929 280.504 4.50434 280.715 4.85345 280.87C5.21107 281.016 5.61552 281.09 6.06681 281.09C6.64581 281.09 7.16948 280.948 7.63779 280.663C8.11462 280.379 8.51907 279.956 8.85115 279.396C9.18323 278.836 9.43867 278.142 9.61749 277.315C9.7963 276.479 9.8857 275.514 9.8857 274.419ZM2.24791 274.264C2.24791 274.531 2.24791 274.799 2.24791 275.066C2.25643 275.333 2.27346 275.591 2.299 275.841L9.36204 270.554C9.23432 270.115 9.07254 269.714 8.8767 269.352C8.68086 268.99 8.4467 268.68 8.17422 268.421C7.90175 268.154 7.59096 267.947 7.24185 267.801C6.89274 267.654 6.50106 267.581 6.06681 267.581C5.4878 267.581 4.95988 267.723 4.48305 268.008C4.01474 268.292 3.61454 268.719 3.28246 269.287C2.95038 269.848 2.69494 270.546 2.51613 271.382C2.33732 272.209 2.24791 273.17 2.24791 274.264Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 302.21C12.1336 303.502 12.0059 304.683 11.7504 305.752C11.495 306.812 11.1076 307.725 10.5882 308.492C10.0688 309.25 9.42165 309.841 8.6468 310.263C7.88046 310.677 6.97789 310.884 5.93909 310.884C5.04503 310.884 4.23186 310.716 3.49959 310.379C2.76732 310.035 2.14148 309.509 1.62207 308.802C1.10267 308.096 0.702472 307.195 0.421483 306.101C0.140494 305.006 0 303.709 0 302.21C0 300.917 0.127722 299.736 0.383167 298.668C0.647126 297.599 1.03455 296.685 1.54544 295.927C2.06484 295.16 2.71197 294.57 3.48682 294.156C4.26167 293.734 5.16424 293.523 6.19453 293.523C7.08859 293.523 7.90175 293.695 8.63402 294.04C9.3663 294.376 9.99214 294.902 10.5115 295.617C11.0309 296.324 11.4311 297.224 11.7121 298.319C11.9931 299.413 12.1336 300.71 12.1336 302.21ZM9.8857 302.287C9.8857 301.994 9.87293 301.705 9.84739 301.421C9.83036 301.128 9.80907 300.844 9.78352 300.568L2.72048 305.881C2.84821 306.329 3.00999 306.743 3.20583 307.122C3.40167 307.501 3.63583 307.829 3.9083 308.104C4.18929 308.371 4.50434 308.583 4.85345 308.738C5.21107 308.884 5.61552 308.957 6.06681 308.957C6.64581 308.957 7.16948 308.815 7.63779 308.531C8.11462 308.246 8.51907 307.824 8.85115 307.264C9.18323 306.704 9.43867 306.01 9.61749 305.183C9.7963 304.347 9.8857 303.382 9.8857 302.287ZM2.24791 302.132C2.24791 302.399 2.24791 302.666 2.24791 302.934C2.25643 303.201 2.27346 303.459 2.299 303.709L9.36204 298.422C9.23432 297.982 9.07254 297.582 8.8767 297.22C8.68086 296.858 8.4467 296.548 8.17422 296.289C7.90175 296.022 7.59096 295.815 7.24185 295.669C6.89274 295.522 6.50106 295.449 6.06681 295.449C5.4878 295.449 4.95988 295.591 4.48305 295.875C4.01474 296.16 3.61454 296.586 3.28246 297.155C2.95038 297.715 2.69494 298.413 2.51613 299.249C2.33732 300.077 2.24791 301.038 2.24791 302.132Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 330.077C12.1336 331.37 12.0059 332.551 11.7504 333.619C11.495 334.679 11.1076 335.593 10.5882 336.36C10.0688 337.118 9.42165 337.709 8.6468 338.131C7.88046 338.545 6.97789 338.751 5.93909 338.751C5.04503 338.751 4.23186 338.583 3.49959 338.247C2.76732 337.902 2.14148 337.377 1.62207 336.67C1.10267 335.963 0.702472 335.063 0.421483 333.968C0.140494 332.874 0 331.577 0 330.077C0 328.785 0.127722 327.604 0.383167 326.535C0.647126 325.467 1.03455 324.553 1.54544 323.795C2.06484 323.028 2.71197 322.438 3.48682 322.024C4.26167 321.602 5.16424 321.39 6.19453 321.39C7.08859 321.39 7.90175 321.563 8.63402 321.908C9.3663 322.244 9.99214 322.769 10.5115 323.485C11.0309 324.191 11.4311 325.092 11.7121 326.186C11.9931 327.281 12.1336 328.578 12.1336 330.077ZM9.8857 330.155C9.8857 329.862 9.87293 329.573 9.84739 329.289C9.83036 328.996 9.80907 328.711 9.78352 328.436L2.72048 333.749C2.84821 334.197 3.00999 334.61 3.20583 334.99C3.40167 335.369 3.63583 335.696 3.9083 335.972C4.18929 336.239 4.50434 336.45 4.85345 336.605C5.21107 336.752 5.61552 336.825 6.06681 336.825C6.64581 336.825 7.16948 336.683 7.63779 336.399C8.11462 336.114 8.51907 335.692 8.85115 335.132C9.18323 334.572 9.43867 333.878 9.61749 333.051C9.7963 332.215 9.8857 331.249 9.8857 330.155ZM2.24791 330C2.24791 330.267 2.24791 330.534 2.24791 330.801C2.25643 331.068 2.27346 331.327 2.299 331.577L9.36204 326.29C9.23432 325.85 9.07254 325.45 8.8767 325.088C8.68086 324.726 8.4467 324.415 8.17422 324.157C7.90175 323.89 7.59096 323.683 7.24185 323.536C6.89274 323.39 6.50106 323.317 6.06681 323.317C5.4878 323.317 4.95988 323.459 4.48305 323.743C4.01474 324.028 3.61454 324.454 3.28246 325.023C2.95038 325.583 2.69494 326.281 2.51613 327.117C2.33732 327.944 2.24791 328.905 2.24791 330Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 366.386H1.12396V364.292H5.41542V351.96L1.41772 354.158L0.600295 352.244L5.91354 349.413H7.88046V364.292H11.61V366.386Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 394.254H1.12396V392.16H5.41542V379.828L1.41772 382.025L0.600295 380.112L5.91354 377.281H7.88046V392.16H11.61V394.254Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 422.122H1.12396V420.028H5.41542V407.696L1.41772 409.893L0.600295 407.98L5.91354 405.149H7.88046V420.028H11.61V422.122Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 441.549C12.1336 442.841 12.0059 444.022 11.7504 445.091C11.495 446.151 11.1076 447.064 10.5882 447.831C10.0688 448.589 9.42165 449.18 8.6468 449.602C7.88046 450.016 6.97789 450.223 5.93909 450.223C5.04503 450.223 4.23186 450.054 3.49959 449.718C2.76732 449.374 2.14148 448.848 1.62207 448.141C1.10267 447.435 0.702472 446.534 0.421483 445.44C0.140494 444.345 0 443.048 0 441.549C0 440.256 0.127722 439.075 0.383167 438.007C0.647126 436.938 1.03455 436.024 1.54544 435.266C2.06484 434.499 2.71197 433.909 3.48682 433.495C4.26167 433.073 5.16424 432.862 6.19453 432.862C7.08859 432.862 7.90175 433.034 8.63402 433.379C9.3663 433.715 9.99214 434.24 10.5115 434.956C11.0309 435.662 11.4311 436.563 11.7121 437.658C11.9931 438.752 12.1336 440.049 12.1336 441.549ZM9.8857 441.626C9.8857 441.333 9.87293 441.044 9.84739 440.76C9.83036 440.467 9.80907 440.183 9.78352 439.907L2.72048 445.22C2.84821 445.668 3.00999 446.082 3.20583 446.461C3.40167 446.84 3.63583 447.167 3.9083 447.443C4.18929 447.71 4.50434 447.922 4.85345 448.077C5.21107 448.223 5.61552 448.296 6.06681 448.296C6.64581 448.296 7.16948 448.154 7.63779 447.87C8.11462 447.585 8.51907 447.163 8.85115 446.603C9.18323 446.043 9.43867 445.349 9.61749 444.522C9.7963 443.686 9.8857 442.721 9.8857 441.626ZM2.24791 441.471C2.24791 441.738 2.24791 442.005 2.24791 442.272C2.25643 442.54 2.27346 442.798 2.299 443.048L9.36204 437.761C9.23432 437.321 9.07254 436.921 8.8767 436.559C8.68086 436.197 8.4467 435.887 8.17422 435.628C7.90175 435.361 7.59096 435.154 7.24185 435.007C6.89274 434.861 6.50106 434.788 6.06681 434.788C5.4878 434.788 4.95988 434.93 4.48305 435.214C4.01474 435.499 3.61454 435.925 3.28246 436.494C2.95038 437.054 2.69494 437.752 2.51613 438.588C2.33732 439.416 2.24791 440.376 2.24791 441.471Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 477.858H1.12396V475.763H5.41542V463.431L1.41772 465.629L0.600295 463.716L5.91354 460.885H7.88046V475.763H11.61V477.858Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 497.284C12.1336 498.577 12.0059 499.757 11.7504 500.826C11.495 501.886 11.1076 502.8 10.5882 503.567C10.0688 504.325 9.42165 504.915 8.6468 505.338C7.88046 505.751 6.97789 505.958 5.93909 505.958C5.04503 505.958 4.23186 505.79 3.49959 505.454C2.76732 505.109 2.14148 504.583 1.62207 503.877C1.10267 503.17 0.702472 502.27 0.421483 501.175C0.140494 500.081 0 498.784 0 497.284C0 495.991 0.127722 494.811 0.383167 493.742C0.647126 492.673 1.03455 491.76 1.54544 491.002C2.06484 490.235 2.71197 489.644 3.48682 489.231C4.26167 488.808 5.16424 488.597 6.19453 488.597C7.08859 488.597 7.90175 488.77 8.63402 489.114C9.3663 489.45 9.99214 489.976 10.5115 490.691C11.0309 491.398 11.4311 492.299 11.7121 493.393C11.9931 494.488 12.1336 495.785 12.1336 497.284ZM9.8857 497.362C9.8857 497.069 9.87293 496.78 9.84739 496.496C9.83036 496.203 9.80907 495.918 9.78352 495.642L2.72048 500.955C2.84821 501.403 3.00999 501.817 3.20583 502.196C3.40167 502.576 3.63583 502.903 3.9083 503.179C4.18929 503.446 4.50434 503.657 4.85345 503.812C5.21107 503.959 5.61552 504.032 6.06681 504.032C6.64581 504.032 7.16948 503.89 7.63779 503.605C8.11462 503.321 8.51907 502.899 8.85115 502.339C9.18323 501.778 9.43867 501.085 9.61749 500.257C9.7963 499.421 9.8857 498.456 9.8857 497.362ZM2.24791 497.207C2.24791 497.474 2.24791 497.741 2.24791 498.008C2.25643 498.275 2.27346 498.534 2.299 498.784L9.36204 493.496C9.23432 493.057 9.07254 492.656 8.8767 492.294C8.68086 491.932 8.4467 491.622 8.17422 491.364C7.90175 491.096 7.59096 490.89 7.24185 490.743C6.89274 490.597 6.50106 490.523 6.06681 490.523C5.4878 490.523 4.95988 490.665 4.48305 490.95C4.01474 491.234 3.61454 491.661 3.28246 492.23C2.95038 492.79 2.69494 493.488 2.51613 494.324C2.33732 495.151 2.24791 496.112 2.24791 497.207Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M12.1336 525.152C12.1336 526.445 12.0059 527.625 11.7504 528.694C11.495 529.754 11.1076 530.667 10.5882 531.434C10.0688 532.193 9.42165 532.783 8.6468 533.205C7.88046 533.619 6.97789 533.826 5.93909 533.826C5.04503 533.826 4.23186 533.658 3.49959 533.322C2.76732 532.977 2.14148 532.451 1.62207 531.745C1.10267 531.038 0.702472 530.137 0.421483 529.043C0.140494 527.948 0 526.651 0 525.152C0 523.859 0.127722 522.678 0.383167 521.61C0.647126 520.541 1.03455 519.628 1.54544 518.869C2.06484 518.102 2.71197 517.512 3.48682 517.098C4.26167 516.676 5.16424 516.465 6.19453 516.465C7.08859 516.465 7.90175 516.637 8.63402 516.982C9.3663 517.318 9.99214 517.844 10.5115 518.559C11.0309 519.266 11.4311 520.166 11.7121 521.261C11.9931 522.355 12.1336 523.652 12.1336 525.152ZM9.8857 525.229C9.8857 524.936 9.87293 524.648 9.84739 524.363C9.83036 524.07 9.80907 523.786 9.78352 523.51L2.72048 528.823C2.84821 529.271 3.00999 529.685 3.20583 530.064C3.40167 530.443 3.63583 530.771 3.9083 531.047C4.18929 531.314 4.50434 531.525 4.85345 531.68C5.21107 531.826 5.61552 531.9 6.06681 531.9C6.64581 531.9 7.16948 531.758 7.63779 531.473C8.11462 531.189 8.51907 530.766 8.85115 530.206C9.18323 529.646 9.43867 528.952 9.61749 528.125C9.7963 527.289 9.8857 526.324 9.8857 525.229ZM2.24791 525.074C2.24791 525.341 2.24791 525.609 2.24791 525.876C2.25643 526.143 2.27346 526.401 2.299 526.651L9.36204 521.364C9.23432 520.925 9.07254 520.524 8.8767 520.162C8.68086 519.8 8.4467 519.49 8.17422 519.231C7.90175 518.964 7.59096 518.757 7.24185 518.611C6.89274 518.464 6.50106 518.391 6.06681 518.391C5.4878 518.391 4.95988 518.533 4.48305 518.818C4.01474 519.102 3.61454 519.529 3.28246 520.097C2.95038 520.658 2.69494 521.356 2.51613 522.192C2.33732 523.019 2.24791 523.98 2.24791 525.074Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M11.61 561.461H1.12396V559.367H5.41542V547.034L1.41772 549.232L0.600295 547.319L5.91354 544.488H7.88046V559.367H11.61V561.461Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M169.36 206.837C169.36 207.859 169.25 208.773 169.031 209.58C168.811 210.386 168.488 211.069 168.061 211.628C167.641 212.18 167.126 212.602 166.518 212.895C165.909 213.187 165.213 213.333 164.429 213.333C163.751 213.333 163.127 213.212 162.556 212.971C161.985 212.729 161.492 212.352 161.078 211.837C160.664 211.317 160.341 210.653 160.109 209.846C159.877 209.04 159.761 208.075 159.761 206.951C159.761 205.928 159.871 205.014 160.09 204.208C160.316 203.401 160.639 202.722 161.06 202.169C161.48 201.61 161.997 201.185 162.612 200.893C163.227 200.601 163.924 200.455 164.702 200.455C165.379 200.455 166.003 200.575 166.574 200.817C167.145 201.058 167.635 201.436 168.043 201.95C168.457 202.465 168.78 203.125 169.012 203.931C169.244 204.738 169.36 205.706 169.36 206.837ZM162.076 206.875C162.076 207.002 162.076 207.122 162.076 207.237C162.076 207.345 162.082 207.456 162.095 207.57L166.706 204.198C166.487 203.569 166.195 203.106 165.831 202.807C165.473 202.503 165.05 202.35 164.56 202.35C164.209 202.35 163.883 202.442 163.582 202.626C163.281 202.804 163.017 203.08 162.791 203.455C162.572 203.83 162.396 204.3 162.264 204.865C162.139 205.43 162.076 206.1 162.076 206.875ZM167.045 206.913C167.045 206.805 167.042 206.697 167.036 206.589C167.029 206.475 167.026 206.367 167.026 206.265L162.443 209.618C162.644 210.234 162.926 210.691 163.29 210.989C163.654 211.288 164.077 211.437 164.56 211.437C164.912 211.437 165.238 211.345 165.539 211.161C165.847 210.977 166.11 210.701 166.33 210.332C166.556 209.958 166.731 209.488 166.857 208.923C166.982 208.351 167.045 207.681 167.045 206.913Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 234.014V231.947H163.826V223.946L161.097 225.47L160.344 223.575L164.25 221.489H166.179V231.947H168.88V234.014H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 254.915V252.848H163.826V244.847L161.097 246.371L160.344 244.476L164.25 242.39H166.179V252.848H168.88V254.915H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 269.539C169.36 270.561 169.25 271.476 169.031 272.282C168.811 273.089 168.488 273.771 168.061 274.33C167.641 274.883 167.126 275.305 166.518 275.597C165.909 275.889 165.213 276.035 164.429 276.035C163.751 276.035 163.127 275.915 162.556 275.673C161.985 275.432 161.492 275.054 161.078 274.54C160.664 274.019 160.341 273.355 160.109 272.549C159.877 271.743 159.761 270.777 159.761 269.653C159.761 268.631 159.871 267.717 160.09 266.91C160.316 266.104 160.639 265.424 161.06 264.872C161.48 264.313 161.997 263.887 162.612 263.595C163.227 263.303 163.924 263.157 164.702 263.157C165.379 263.157 166.003 263.278 166.574 263.519C167.145 263.76 167.635 264.138 168.043 264.653C168.457 265.167 168.78 265.827 169.012 266.634C169.244 267.44 169.36 268.409 169.36 269.539ZM162.076 269.577C162.076 269.704 162.076 269.825 162.076 269.939C162.076 270.047 162.082 270.158 162.095 270.272L166.706 266.901C166.487 266.272 166.195 265.808 165.831 265.51C165.473 265.205 165.05 265.053 164.56 265.053C164.209 265.053 163.883 265.145 163.582 265.329C163.281 265.507 163.017 265.783 162.791 266.158C162.572 266.532 162.396 267.002 162.264 267.567C162.139 268.133 162.076 268.802 162.076 269.577ZM167.045 269.615C167.045 269.507 167.042 269.399 167.036 269.291C167.029 269.177 167.026 269.069 167.026 268.968L162.443 272.32C162.644 272.936 162.926 273.394 163.29 273.692C163.654 273.99 164.077 274.14 164.56 274.14C164.912 274.14 165.238 274.048 165.539 273.863C165.847 273.679 166.11 273.403 166.33 273.035C166.556 272.66 166.731 272.19 166.857 271.625C166.982 271.054 167.045 270.384 167.045 269.615Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 296.717V294.65H163.826V286.649L161.097 288.173L160.344 286.277L164.25 284.191H166.179V294.65H168.88V296.717H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 311.341C169.36 312.363 169.25 313.278 169.031 314.084C168.811 314.89 168.488 315.573 168.061 316.132C167.641 316.684 167.126 317.107 166.518 317.399C165.909 317.691 165.213 317.837 164.429 317.837C163.751 317.837 163.127 317.716 162.556 317.475C161.985 317.234 161.492 316.856 161.078 316.341C160.664 315.821 160.341 315.157 160.109 314.351C159.877 313.544 159.761 312.579 159.761 311.455C159.761 310.433 159.871 309.518 160.09 308.712C160.316 307.905 160.639 307.226 161.06 306.673C161.48 306.115 161.997 305.689 162.612 305.397C163.227 305.105 163.924 304.959 164.702 304.959C165.379 304.959 166.003 305.08 166.574 305.321C167.145 305.562 167.635 305.94 168.043 306.454C168.457 306.969 168.78 307.629 169.012 308.436C169.244 309.242 169.36 310.21 169.36 311.341ZM162.076 311.379C162.076 311.506 162.076 311.626 162.076 311.741C162.076 311.849 162.082 311.96 162.095 312.074L166.706 308.702C166.487 308.074 166.195 307.61 165.831 307.312C165.473 307.007 165.05 306.854 164.56 306.854C164.209 306.854 163.883 306.946 163.582 307.131C163.281 307.308 163.017 307.585 162.791 307.959C162.572 308.334 162.396 308.804 162.264 309.369C162.139 309.934 162.076 310.604 162.076 311.379ZM167.045 311.417C167.045 311.309 167.042 311.201 167.036 311.093C167.029 310.979 167.026 310.871 167.026 310.769L162.443 314.122C162.644 314.738 162.926 315.195 163.29 315.494C163.654 315.792 164.077 315.941 164.56 315.941C164.912 315.941 165.238 315.849 165.539 315.665C165.847 315.481 166.11 315.205 166.33 314.836C166.556 314.462 166.731 313.992 166.857 313.427C166.982 312.855 167.045 312.185 167.045 311.417Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 332.242C169.36 333.264 169.25 334.178 169.031 334.985C168.811 335.791 168.488 336.474 168.061 337.033C167.641 337.585 167.126 338.007 166.518 338.3C165.909 338.592 165.213 338.738 164.429 338.738C163.751 338.738 163.127 338.617 162.556 338.376C161.985 338.134 161.492 337.757 161.078 337.242C160.664 336.722 160.341 336.058 160.109 335.252C159.877 334.445 159.761 333.48 159.761 332.356C159.761 331.334 159.871 330.419 160.09 329.613C160.316 328.806 160.639 328.127 161.06 327.574C161.48 327.015 161.997 326.59 162.612 326.298C163.227 326.006 163.924 325.86 164.702 325.86C165.379 325.86 166.003 325.98 166.574 326.222C167.145 326.463 167.635 326.841 168.043 327.355C168.457 327.87 168.78 328.53 169.012 329.336C169.244 330.143 169.36 331.111 169.36 332.242ZM162.076 332.28C162.076 332.407 162.076 332.527 162.076 332.642C162.076 332.75 162.082 332.861 162.095 332.975L166.706 329.603C166.487 328.974 166.195 328.511 165.831 328.212C165.473 327.908 165.05 327.755 164.56 327.755C164.209 327.755 163.883 327.847 163.582 328.031C163.281 328.209 163.017 328.486 162.791 328.86C162.572 329.235 162.396 329.705 162.264 330.27C162.139 330.835 162.076 331.505 162.076 332.28ZM167.045 332.318C167.045 332.21 167.042 332.102 167.036 331.994C167.029 331.88 167.026 331.772 167.026 331.67L162.443 335.023C162.644 335.639 162.926 336.096 163.29 336.395C163.654 336.693 164.077 336.842 164.56 336.842C164.912 336.842 165.238 336.75 165.539 336.566C165.847 336.382 166.11 336.106 166.33 335.737C166.556 335.363 166.731 334.893 166.857 334.328C166.982 333.756 167.045 333.086 167.045 332.318Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 353.142C169.36 354.165 169.25 355.079 169.031 355.886C168.811 356.692 168.488 357.375 168.061 357.934C167.641 358.486 167.126 358.908 166.518 359.2C165.909 359.493 165.213 359.639 164.429 359.639C163.751 359.639 163.127 359.518 162.556 359.277C161.985 359.035 161.492 358.657 161.078 358.143C160.664 357.622 160.341 356.959 160.109 356.152C159.877 355.346 159.761 354.381 159.761 353.257C159.761 352.234 159.871 351.32 160.09 350.513C160.316 349.707 160.639 349.028 161.06 348.475C161.48 347.916 161.997 347.491 162.612 347.199C163.227 346.907 163.924 346.761 164.702 346.761C165.379 346.761 166.003 346.881 166.574 347.123C167.145 347.364 167.635 347.742 168.043 348.256C168.457 348.77 168.78 349.431 169.012 350.237C169.244 351.044 169.36 352.012 169.36 353.142ZM162.076 353.181C162.076 353.308 162.076 353.428 162.076 353.542C162.076 353.65 162.082 353.762 162.095 353.876L166.706 350.504C166.487 349.875 166.195 349.412 165.831 349.113C165.473 348.808 165.05 348.656 164.56 348.656C164.209 348.656 163.883 348.748 163.582 348.932C163.281 349.11 163.017 349.386 162.791 349.761C162.572 350.136 162.396 350.606 162.264 351.171C162.139 351.736 162.076 352.406 162.076 353.181ZM167.045 353.219C167.045 353.111 167.042 353.003 167.036 352.895C167.029 352.78 167.026 352.673 167.026 352.571L162.443 355.924C162.644 356.54 162.926 356.997 163.29 357.295C163.654 357.594 164.077 357.743 164.56 357.743C164.912 357.743 165.238 357.651 165.539 357.467C165.847 357.283 166.11 357.006 166.33 356.638C166.556 356.263 166.731 355.794 166.857 355.228C166.982 354.657 167.045 353.987 167.045 353.219Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 380.32V378.253H163.826V370.252L161.097 371.776L160.344 369.881L164.25 367.795H166.179V378.253H168.88V380.32H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 401.221V399.154H163.826V391.153L161.097 392.677L160.344 390.782L164.25 388.696H166.179V399.154H168.88V401.221H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 415.845C169.36 416.867 169.25 417.782 169.031 418.588C168.811 419.395 168.488 420.077 168.061 420.636C167.641 421.189 167.126 421.611 166.518 421.903C165.909 422.195 165.213 422.341 164.429 422.341C163.751 422.341 163.127 422.22 162.556 421.979C161.985 421.738 161.492 421.36 161.078 420.846C160.664 420.325 160.341 419.661 160.109 418.855C159.877 418.048 159.761 417.083 159.761 415.959C159.761 414.937 159.871 414.022 160.09 413.216C160.316 412.41 160.639 411.73 161.06 411.178C161.48 410.619 161.997 410.193 162.612 409.901C163.227 409.609 163.924 409.463 164.702 409.463C165.379 409.463 166.003 409.584 166.574 409.825C167.145 410.066 167.635 410.444 168.043 410.959C168.457 411.473 168.78 412.133 169.012 412.94C169.244 413.746 169.36 414.715 169.36 415.845ZM162.076 415.883C162.076 416.01 162.076 416.131 162.076 416.245C162.076 416.353 162.082 416.464 162.095 416.578L166.706 413.206C166.487 412.578 166.195 412.114 165.831 411.816C165.473 411.511 165.05 411.359 164.56 411.359C164.209 411.359 163.883 411.451 163.582 411.635C163.281 411.813 163.017 412.089 162.791 412.464C162.572 412.838 162.396 413.308 162.264 413.873C162.139 414.438 162.076 415.108 162.076 415.883ZM167.045 415.921C167.045 415.813 167.042 415.705 167.036 415.597C167.029 415.483 167.026 415.375 167.026 415.273L162.443 418.626C162.644 419.242 162.926 419.699 163.29 419.998C163.654 420.296 164.077 420.446 164.56 420.446C164.912 420.446 165.238 420.354 165.539 420.169C165.847 419.985 166.11 419.709 166.33 419.341C166.556 418.966 166.731 418.496 166.857 417.931C166.982 417.359 167.045 416.689 167.045 415.921Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 443.023V440.956H163.826V432.955L161.097 434.479L160.344 432.583L164.25 430.497H166.179V440.956H168.88V443.023H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 463.924V461.857H163.826V453.856L161.097 455.38L160.344 453.484L164.25 451.398H166.179V461.857H168.88V463.924H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 478.547C169.36 479.57 169.25 480.484 169.031 481.291C168.811 482.097 168.488 482.78 168.061 483.339C167.641 483.891 167.126 484.313 166.518 484.605C165.909 484.898 165.213 485.044 164.429 485.044C163.751 485.044 163.127 484.923 162.556 484.682C161.985 484.44 161.492 484.062 161.078 483.548C160.664 483.027 160.341 482.364 160.109 481.557C159.877 480.751 159.761 479.786 159.761 478.662C159.761 477.639 159.871 476.725 160.09 475.919C160.316 475.112 160.639 474.433 161.06 473.88C161.48 473.321 161.997 472.896 162.612 472.604C163.227 472.312 163.924 472.166 164.702 472.166C165.379 472.166 166.003 472.286 166.574 472.528C167.145 472.769 167.635 473.147 168.043 473.661C168.457 474.175 168.78 474.836 169.012 475.642C169.244 476.449 169.36 477.417 169.36 478.547ZM162.076 478.586C162.076 478.713 162.076 478.833 162.076 478.948C162.076 479.055 162.082 479.167 162.095 479.281L166.706 475.909C166.487 475.28 166.195 474.817 165.831 474.518C165.473 474.214 165.05 474.061 164.56 474.061C164.209 474.061 163.883 474.153 163.582 474.337C163.281 474.515 163.017 474.791 162.791 475.166C162.572 475.541 162.396 476.011 162.264 476.576C162.139 477.141 162.076 477.811 162.076 478.586ZM167.045 478.624C167.045 478.516 167.042 478.408 167.036 478.3C167.029 478.186 167.026 478.078 167.026 477.976L162.443 481.329C162.644 481.945 162.926 482.402 163.29 482.7C163.654 482.999 164.077 483.148 164.56 483.148C164.912 483.148 165.238 483.056 165.539 482.872C165.847 482.688 166.11 482.411 166.33 482.043C166.556 481.669 166.731 481.199 166.857 480.633C166.982 480.062 167.045 479.392 167.045 478.624Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 505.725V503.658H163.826V495.657L161.097 497.181L160.344 495.286L164.25 493.2H166.179V503.658H168.88V505.725H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 526.626V524.559H163.826V516.558L161.097 518.082L160.344 516.187L164.25 514.101H166.179V524.559H168.88V526.626H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 547.527V545.46H163.826V537.459L161.097 538.983L160.344 537.087L164.25 535.001H166.179V545.46H168.88V547.527H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 562.151C169.36 563.173 169.25 564.088 169.031 564.894C168.811 565.701 168.488 566.383 168.061 566.942C167.641 567.494 167.126 567.917 166.518 568.209C165.909 568.501 165.213 568.647 164.429 568.647C163.751 568.647 163.127 568.526 162.556 568.285C161.985 568.044 161.492 567.666 161.078 567.152C160.664 566.631 160.341 565.967 160.109 565.161C159.877 564.354 159.761 563.389 159.761 562.265C159.761 561.243 159.871 560.328 160.09 559.522C160.316 558.715 160.639 558.036 161.06 557.484C161.48 556.925 161.997 556.499 162.612 556.207C163.227 555.915 163.924 555.769 164.702 555.769C165.379 555.769 166.003 555.89 166.574 556.131C167.145 556.372 167.635 556.75 168.043 557.264C168.457 557.779 168.78 558.439 169.012 559.246C169.244 560.052 169.36 561.021 169.36 562.151ZM162.076 562.189C162.076 562.316 162.076 562.437 162.076 562.551C162.076 562.659 162.082 562.77 162.095 562.884L166.706 559.512C166.487 558.884 166.195 558.42 165.831 558.122C165.473 557.817 165.05 557.664 164.56 557.664C164.209 557.664 163.883 557.757 163.582 557.941C163.281 558.119 163.017 558.395 162.791 558.769C162.572 559.144 162.396 559.614 162.264 560.179C162.139 560.744 162.076 561.414 162.076 562.189ZM167.045 562.227C167.045 562.119 167.042 562.011 167.036 561.903C167.029 561.789 167.026 561.681 167.026 561.579L162.443 564.932C162.644 565.548 162.926 566.005 163.29 566.304C163.654 566.602 164.077 566.751 164.56 566.751C164.912 566.751 165.238 566.659 165.539 566.475C165.847 566.291 166.11 566.015 166.33 565.647C166.556 565.272 166.731 564.802 166.857 564.237C166.982 563.665 167.045 562.995 167.045 562.227Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M169.36 583.052C169.36 584.074 169.25 584.988 169.031 585.795C168.811 586.601 168.488 587.284 168.061 587.843C167.641 588.395 167.126 588.818 166.518 589.11C165.909 589.402 165.213 589.548 164.429 589.548C163.751 589.548 163.127 589.427 162.556 589.186C161.985 588.945 161.492 588.567 161.078 588.052C160.664 587.532 160.341 586.868 160.109 586.062C159.877 585.255 159.761 584.29 159.761 583.166C159.761 582.144 159.871 581.229 160.09 580.423C160.316 579.616 160.639 578.937 161.06 578.384C161.48 577.826 161.997 577.4 162.612 577.108C163.227 576.816 163.924 576.67 164.702 576.67C165.379 576.67 166.003 576.79 166.574 577.032C167.145 577.273 167.635 577.651 168.043 578.165C168.457 578.68 168.78 579.34 169.012 580.147C169.244 580.953 169.36 581.921 169.36 583.052ZM162.076 583.09C162.076 583.217 162.076 583.337 162.076 583.452C162.076 583.56 162.082 583.671 162.095 583.785L166.706 580.413C166.487 579.785 166.195 579.321 165.831 579.023C165.473 578.718 165.05 578.565 164.56 578.565C164.209 578.565 163.883 578.657 163.582 578.842C163.281 579.019 163.017 579.296 162.791 579.67C162.572 580.045 162.396 580.515 162.264 581.08C162.139 581.645 162.076 582.315 162.076 583.09ZM167.045 583.128C167.045 583.02 167.042 582.912 167.036 582.804C167.029 582.69 167.026 582.582 167.026 582.48L162.443 585.833C162.644 586.449 162.926 586.906 163.29 587.205C163.654 587.503 164.077 587.652 164.56 587.652C164.912 587.652 165.238 587.56 165.539 587.376C165.847 587.192 166.11 586.916 166.33 586.547C166.556 586.173 166.731 585.703 166.857 585.138C166.982 584.566 167.045 583.896 167.045 583.128Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M160.692 610.23V608.163H163.826V600.161L161.097 601.686L160.344 599.79L164.25 597.704H166.179V608.163H168.88V610.23H160.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M191.387 502.235C191.387 503.257 191.277 504.172 191.058 504.978C190.838 505.785 190.515 506.467 190.088 507.026C189.668 507.579 189.153 508.001 188.545 508.293C187.936 508.585 187.24 508.731 186.456 508.731C185.778 508.731 185.154 508.611 184.583 508.369C184.012 508.128 183.519 507.75 183.105 507.236C182.691 506.715 182.368 506.052 182.136 505.245C181.904 504.439 181.788 503.473 181.788 502.349C181.788 501.327 181.898 500.413 182.117 499.606C182.343 498.8 182.666 498.12 183.086 497.568C183.507 497.009 184.024 496.584 184.639 496.291C185.254 495.999 185.951 495.853 186.728 495.853C187.406 495.853 188.03 495.974 188.601 496.215C189.172 496.457 189.662 496.834 190.069 497.349C190.483 497.863 190.807 498.524 191.039 499.33C191.271 500.136 191.387 501.105 191.387 502.235ZM184.103 502.273C184.103 502.4 184.103 502.521 184.103 502.635C184.103 502.743 184.109 502.854 184.122 502.969L188.733 499.597C188.513 498.968 188.222 498.504 187.858 498.206C187.5 497.901 187.077 497.749 186.587 497.749C186.236 497.749 185.91 497.841 185.609 498.025C185.307 498.203 185.044 498.479 184.818 498.854C184.598 499.228 184.423 499.698 184.291 500.263C184.166 500.829 184.103 501.499 184.103 502.273ZM189.072 502.311C189.072 502.203 189.069 502.095 189.062 501.987C189.056 501.873 189.053 501.765 189.053 501.664L184.47 505.016C184.671 505.632 184.953 506.09 185.317 506.388C185.681 506.687 186.104 506.836 186.587 506.836C186.939 506.836 187.265 506.744 187.566 506.56C187.873 506.375 188.137 506.099 188.357 505.731C188.582 505.356 188.758 504.886 188.884 504.321C189.009 503.75 189.072 503.08 189.072 502.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 529.413V527.346H185.853V519.345L183.124 520.869L182.371 518.973L186.277 516.887H188.206V527.346H190.907V529.413H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 550.314V548.247H185.853V540.246L183.124 541.77L182.371 539.874L186.277 537.788H188.206V548.247H190.907V550.314H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 564.938C191.387 565.96 191.277 566.874 191.058 567.681C190.838 568.487 190.515 569.17 190.088 569.729C189.668 570.281 189.153 570.704 188.545 570.996C187.936 571.288 187.24 571.434 186.456 571.434C185.778 571.434 185.154 571.313 184.583 571.072C184.012 570.831 183.519 570.453 183.105 569.938C182.691 569.418 182.368 568.754 182.136 567.948C181.904 567.141 181.788 566.176 181.788 565.052C181.788 564.03 181.898 563.115 182.117 562.309C182.343 561.502 182.666 560.823 183.086 560.27C183.507 559.712 184.024 559.286 184.639 558.994C185.254 558.702 185.951 558.556 186.728 558.556C187.406 558.556 188.03 558.676 188.601 558.918C189.172 559.159 189.662 559.537 190.069 560.051C190.483 560.566 190.807 561.226 191.039 562.032C191.271 562.839 191.387 563.807 191.387 564.938ZM184.103 564.976C184.103 565.103 184.103 565.223 184.103 565.338C184.103 565.446 184.109 565.557 184.122 565.671L188.733 562.299C188.513 561.671 188.222 561.207 187.858 560.909C187.5 560.604 187.077 560.451 186.587 560.451C186.236 560.451 185.91 560.543 185.609 560.728C185.307 560.905 185.044 561.182 184.818 561.556C184.598 561.931 184.423 562.401 184.291 562.966C184.166 563.531 184.103 564.201 184.103 564.976ZM189.072 565.014C189.072 564.906 189.069 564.798 189.062 564.69C189.056 564.576 189.053 564.468 189.053 564.366L184.47 567.719C184.671 568.335 184.953 568.792 185.317 569.091C185.681 569.389 186.104 569.538 186.587 569.538C186.939 569.538 187.265 569.446 187.566 569.262C187.873 569.078 188.137 568.802 188.357 568.433C188.582 568.059 188.758 567.589 188.884 567.024C189.009 566.452 189.072 565.782 189.072 565.014Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 592.116V590.049H185.853V582.047L183.124 583.572L182.371 581.676L186.277 579.59H188.206V590.049H190.907V592.116H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 606.739C191.387 607.762 191.277 608.676 191.058 609.483C190.838 610.289 190.515 610.972 190.088 611.53C189.668 612.083 189.153 612.505 188.545 612.797C187.936 613.089 187.24 613.235 186.456 613.235C185.778 613.235 185.154 613.115 184.583 612.874C184.012 612.632 183.519 612.254 183.105 611.74C182.691 611.219 182.368 610.556 182.136 609.749C181.904 608.943 181.788 607.978 181.788 606.854C181.788 605.831 181.898 604.917 182.117 604.11C182.343 603.304 182.666 602.624 183.086 602.072C183.507 601.513 184.024 601.088 184.639 600.796C185.254 600.504 185.951 600.357 186.728 600.357C187.406 600.357 188.03 600.478 188.601 600.719C189.172 600.961 189.662 601.339 190.069 601.853C190.483 602.367 190.807 603.028 191.039 603.834C191.271 604.641 191.387 605.609 191.387 606.739ZM184.103 606.777C184.103 606.904 184.103 607.025 184.103 607.139C184.103 607.247 184.109 607.358 184.122 607.473L188.733 604.101C188.513 603.472 188.222 603.009 187.858 602.71C187.5 602.405 187.077 602.253 186.587 602.253C186.236 602.253 185.91 602.345 185.609 602.529C185.307 602.707 185.044 602.983 184.818 603.358C184.598 603.733 184.423 604.202 184.291 604.768C184.166 605.333 184.103 606.003 184.103 606.777ZM189.072 606.816C189.072 606.708 189.069 606.6 189.062 606.492C189.056 606.377 189.053 606.269 189.053 606.168L184.47 609.521C184.671 610.137 184.953 610.594 185.317 610.892C185.681 611.191 186.104 611.34 186.587 611.34C186.939 611.34 187.265 611.248 187.566 611.064C187.873 610.88 188.137 610.603 188.357 610.235C188.582 609.86 188.758 609.39 188.884 608.825C189.009 608.254 189.072 607.584 189.072 606.816Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 627.64C191.387 628.663 191.277 629.577 191.058 630.383C190.838 631.19 190.515 631.872 190.088 632.431C189.668 632.984 189.153 633.406 188.545 633.698C187.936 633.99 187.24 634.136 186.456 634.136C185.778 634.136 185.154 634.016 184.583 633.774C184.012 633.533 183.519 633.155 183.105 632.641C182.691 632.12 182.368 631.457 182.136 630.65C181.904 629.844 181.788 628.878 181.788 627.754C181.788 626.732 181.898 625.818 182.117 625.011C182.343 624.205 182.666 623.525 183.086 622.973C183.507 622.414 184.024 621.989 184.639 621.696C185.254 621.404 185.951 621.258 186.728 621.258C187.406 621.258 188.03 621.379 188.601 621.62C189.172 621.862 189.662 622.239 190.069 622.754C190.483 623.268 190.807 623.929 191.039 624.735C191.271 625.541 191.387 626.51 191.387 627.64ZM184.103 627.678C184.103 627.805 184.103 627.926 184.103 628.04C184.103 628.148 184.109 628.259 184.122 628.374L188.733 625.002C188.513 624.373 188.222 623.909 187.858 623.611C187.5 623.306 187.077 623.154 186.587 623.154C186.236 623.154 185.91 623.246 185.609 623.43C185.307 623.608 185.044 623.884 184.818 624.259C184.598 624.633 184.423 625.103 184.291 625.668C184.166 626.234 184.103 626.904 184.103 627.678ZM189.072 627.716C189.072 627.608 189.069 627.5 189.062 627.393C189.056 627.278 189.053 627.17 189.053 627.069L184.47 630.421C184.671 631.037 184.953 631.495 185.317 631.793C185.681 632.092 186.104 632.241 186.587 632.241C186.939 632.241 187.265 632.149 187.566 631.965C187.873 631.78 188.137 631.504 188.357 631.136C188.582 630.761 188.758 630.291 188.884 629.726C189.009 629.155 189.072 628.485 189.072 627.716Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 648.541C191.387 649.563 191.277 650.478 191.058 651.284C190.838 652.091 190.515 652.773 190.088 653.332C189.668 653.885 189.153 654.307 188.545 654.599C187.936 654.891 187.24 655.037 186.456 655.037C185.778 655.037 185.154 654.916 184.583 654.675C184.012 654.434 183.519 654.056 183.105 653.542C182.691 653.021 182.368 652.357 182.136 651.551C181.904 650.744 181.788 649.779 181.788 648.655C181.788 647.633 181.898 646.719 182.117 645.912C182.343 645.106 182.666 644.426 183.086 643.874C183.507 643.315 184.024 642.889 184.639 642.597C185.254 642.305 185.951 642.159 186.728 642.159C187.406 642.159 188.03 642.28 188.601 642.521C189.172 642.762 189.662 643.14 190.069 643.655C190.483 644.169 190.807 644.829 191.039 645.636C191.271 646.442 191.387 647.411 191.387 648.541ZM184.103 648.579C184.103 648.706 184.103 648.827 184.103 648.941C184.103 649.049 184.109 649.16 184.122 649.274L188.733 645.903C188.513 645.274 188.222 644.81 187.858 644.512C187.5 644.207 187.077 644.055 186.587 644.055C186.236 644.055 185.91 644.147 185.609 644.331C185.307 644.509 185.044 644.785 184.818 645.16C184.598 645.534 184.423 646.004 184.291 646.569C184.166 647.134 184.103 647.804 184.103 648.579ZM189.072 648.617C189.072 648.509 189.069 648.401 189.062 648.293C189.056 648.179 189.053 648.071 189.053 647.969L184.47 651.322C184.671 651.938 184.953 652.396 185.317 652.694C185.681 652.992 186.104 653.142 186.587 653.142C186.939 653.142 187.265 653.05 187.566 652.865C187.873 652.681 188.137 652.405 188.357 652.037C188.582 651.662 188.758 651.192 188.884 650.627C189.009 650.055 189.072 649.386 189.072 648.617Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 675.719V673.652H185.853V665.651L183.124 667.175L182.371 665.279L186.277 663.193H188.206V673.652H190.907V675.719H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 696.62V694.553H185.853V686.552L183.124 688.076L182.371 686.18L186.277 684.094H188.206V694.553H190.907V696.62H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 711.244C191.387 712.266 191.277 713.18 191.058 713.987C190.838 714.793 190.515 715.476 190.088 716.035C189.668 716.587 189.153 717.009 188.545 717.301C187.936 717.594 187.24 717.74 186.456 717.74C185.778 717.74 185.154 717.619 184.583 717.378C184.012 717.136 183.519 716.759 183.105 716.244C182.691 715.723 182.368 715.06 182.136 714.253C181.904 713.447 181.788 712.482 181.788 711.358C181.788 710.335 181.898 709.421 182.117 708.615C182.343 707.808 182.666 707.129 183.086 706.576C183.507 706.017 184.024 705.592 184.639 705.3C185.254 705.008 185.951 704.862 186.728 704.862C187.406 704.862 188.03 704.982 188.601 705.224C189.172 705.465 189.662 705.843 190.069 706.357C190.483 706.871 190.807 707.532 191.039 708.338C191.271 709.145 191.387 710.113 191.387 711.244ZM184.103 711.282C184.103 711.409 184.103 711.529 184.103 711.644C184.103 711.752 184.109 711.863 184.122 711.977L188.733 708.605C188.513 707.976 188.222 707.513 187.858 707.214C187.5 706.91 187.077 706.757 186.587 706.757C186.236 706.757 185.91 706.849 185.609 707.033C185.307 707.211 185.044 707.487 184.818 707.862C184.598 708.237 184.423 708.707 184.291 709.272C184.166 709.837 184.103 710.507 184.103 711.282ZM189.072 711.32C189.072 711.212 189.069 711.104 189.062 710.996C189.056 710.882 189.053 710.774 189.053 710.672L184.47 714.025C184.671 714.641 184.953 715.098 185.317 715.396C185.681 715.695 186.104 715.844 186.587 715.844C186.939 715.844 187.265 715.752 187.566 715.568C187.873 715.384 188.137 715.108 188.357 714.739C188.582 714.365 188.758 713.895 188.884 713.33C189.009 712.758 189.072 712.088 189.072 711.32Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 738.421V736.354H185.853V728.353L183.124 729.877L182.371 727.982L186.277 725.896H188.206V736.354H190.907V738.421H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 759.322V757.255H185.853V749.254L183.124 750.778L182.371 748.883L186.277 746.797H188.206V757.255H190.907V759.322H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 773.946C191.387 774.968 191.277 775.883 191.058 776.689C190.838 777.496 190.515 778.178 190.088 778.737C189.668 779.29 189.153 779.712 188.545 780.004C187.936 780.296 187.24 780.442 186.456 780.442C185.778 780.442 185.154 780.322 184.583 780.08C184.012 779.839 183.519 779.461 183.105 778.947C182.691 778.426 182.368 777.762 182.136 776.956C181.904 776.15 181.788 775.184 181.788 774.06C181.788 773.038 181.898 772.124 182.117 771.317C182.343 770.511 182.666 769.831 183.086 769.279C183.507 768.72 184.024 768.294 184.639 768.002C185.254 767.71 185.951 767.564 186.728 767.564C187.406 767.564 188.03 767.685 188.601 767.926C189.172 768.167 189.662 768.545 190.069 769.06C190.483 769.574 190.807 770.234 191.039 771.041C191.271 771.847 191.387 772.816 191.387 773.946ZM184.103 773.984C184.103 774.111 184.103 774.232 184.103 774.346C184.103 774.454 184.109 774.565 184.122 774.679L188.733 771.308C188.513 770.679 188.222 770.215 187.858 769.917C187.5 769.612 187.077 769.46 186.587 769.46C186.236 769.46 185.91 769.552 185.609 769.736C185.307 769.914 185.044 770.19 184.818 770.565C184.598 770.939 184.423 771.409 184.291 771.974C184.166 772.539 184.103 773.209 184.103 773.984ZM189.072 774.022C189.072 773.914 189.069 773.806 189.062 773.698C189.056 773.584 189.053 773.476 189.053 773.375L184.47 776.727C184.671 777.343 184.953 777.801 185.317 778.099C185.681 778.397 186.104 778.547 186.587 778.547C186.939 778.547 187.265 778.455 187.566 778.27C187.873 778.086 188.137 777.81 188.357 777.442C188.582 777.067 188.758 776.597 188.884 776.032C189.009 775.461 189.072 774.791 189.072 774.022Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 801.124V799.057H185.853V791.056L183.124 792.58L182.371 790.684L186.277 788.598H188.206V799.057H190.907V801.124H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 822.025V819.958H185.853V811.957L183.124 813.481L182.371 811.585L186.277 809.499H188.206V819.958H190.907V822.025H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 842.926V840.859H185.853V832.858L183.124 834.382L182.371 832.486L186.277 830.4H188.206V840.859H190.907V842.926H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 857.549C191.387 858.572 191.277 859.486 191.058 860.293C190.838 861.099 190.515 861.782 190.088 862.341C189.668 862.893 189.153 863.315 188.545 863.607C187.936 863.899 187.24 864.046 186.456 864.046C185.778 864.046 185.154 863.925 184.583 863.684C184.012 863.442 183.519 863.064 183.105 862.55C182.691 862.029 182.368 861.366 182.136 860.559C181.904 859.753 181.788 858.788 181.788 857.664C181.788 856.641 181.898 855.727 182.117 854.92C182.343 854.114 182.666 853.435 183.086 852.882C183.507 852.323 184.024 851.898 184.639 851.606C185.254 851.314 185.951 851.168 186.728 851.168C187.406 851.168 188.03 851.288 188.601 851.53C189.172 851.771 189.662 852.149 190.069 852.663C190.483 853.177 190.807 853.838 191.039 854.644C191.271 855.451 191.387 856.419 191.387 857.549ZM184.103 857.587C184.103 857.714 184.103 857.835 184.103 857.949C184.103 858.057 184.109 858.169 184.122 858.283L188.733 854.911C188.513 854.282 188.222 853.819 187.858 853.52C187.5 853.215 187.077 853.063 186.587 853.063C186.236 853.063 185.91 853.155 185.609 853.339C185.307 853.517 185.044 853.793 184.818 854.168C184.598 854.543 184.423 855.013 184.291 855.578C184.166 856.143 184.103 856.813 184.103 857.587ZM189.072 857.626C189.072 857.518 189.069 857.41 189.062 857.302C189.056 857.187 189.053 857.079 189.053 856.978L184.47 860.331C184.671 860.947 184.953 861.404 185.317 861.702C185.681 862.001 186.104 862.15 186.587 862.15C186.939 862.15 187.265 862.058 187.566 861.874C187.873 861.69 188.137 861.413 188.357 861.045C188.582 860.67 188.758 860.201 188.884 859.635C189.009 859.064 189.072 858.394 189.072 857.626Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M191.387 878.45C191.387 879.473 191.277 880.387 191.058 881.193C190.838 882 190.515 882.683 190.088 883.241C189.668 883.794 189.153 884.216 188.545 884.508C187.936 884.8 187.24 884.946 186.456 884.946C185.778 884.946 185.154 884.826 184.583 884.584C184.012 884.343 183.519 883.965 183.105 883.451C182.691 882.93 182.368 882.267 182.136 881.46C181.904 880.654 181.788 879.688 181.788 878.565C181.788 877.542 181.898 876.628 182.117 875.821C182.343 875.015 182.666 874.335 183.086 873.783C183.507 873.224 184.024 872.799 184.639 872.507C185.254 872.214 185.951 872.068 186.728 872.068C187.406 872.068 188.03 872.189 188.601 872.43C189.172 872.672 189.662 873.049 190.069 873.564C190.483 874.078 190.807 874.739 191.039 875.545C191.271 876.352 191.387 877.32 191.387 878.45ZM184.103 878.488C184.103 878.615 184.103 878.736 184.103 878.85C184.103 878.958 184.109 879.069 184.122 879.184L188.733 875.812C188.513 875.183 188.222 874.72 187.858 874.421C187.5 874.116 187.077 873.964 186.587 873.964C186.236 873.964 185.91 874.056 185.609 874.24C185.307 874.418 185.044 874.694 184.818 875.069C184.598 875.443 184.423 875.913 184.291 876.479C184.166 877.044 184.103 877.714 184.103 878.488ZM189.072 878.526C189.072 878.418 189.069 878.311 189.062 878.203C189.056 878.088 189.053 877.98 189.053 877.879L184.47 881.232C184.671 881.848 184.953 882.305 185.317 882.603C185.681 882.902 186.104 883.051 186.587 883.051C186.939 883.051 187.265 882.959 187.566 882.775C187.873 882.59 188.137 882.314 188.357 881.946C188.582 881.571 188.758 881.101 188.884 880.536C189.009 879.965 189.072 879.295 189.072 878.526Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M182.719 905.628V903.561H185.853V895.56L183.124 897.084L182.371 895.189L186.277 893.103H188.206V903.561H190.907V905.628H182.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M361.811 425.568C361.811 426.883 361.67 428.058 361.387 429.095C361.105 430.132 360.69 431.01 360.141 431.728C359.601 432.438 358.939 432.981 358.157 433.357C357.374 433.732 356.479 433.92 355.471 433.92C354.599 433.92 353.797 433.765 353.063 433.455C352.329 433.145 351.695 432.659 351.163 431.997C350.631 431.328 350.215 430.475 349.917 429.438C349.618 428.401 349.469 427.16 349.469 425.715C349.469 424.401 349.61 423.225 349.892 422.188C350.183 421.151 350.598 420.278 351.139 419.567C351.679 418.849 352.345 418.302 353.135 417.926C353.926 417.551 354.821 417.363 355.821 417.363C356.693 417.363 357.495 417.518 358.229 417.828C358.963 418.138 359.593 418.624 360.117 419.286C360.649 419.947 361.065 420.796 361.363 421.833C361.662 422.87 361.811 424.115 361.811 425.568ZM352.446 425.617C352.446 425.78 352.446 425.935 352.446 426.082C352.446 426.221 352.454 426.364 352.47 426.511L358.399 422.176C358.116 421.367 357.741 420.771 357.273 420.388C356.814 419.996 356.269 419.8 355.64 419.8C355.188 419.8 354.769 419.918 354.382 420.155C353.994 420.384 353.656 420.739 353.365 421.22C353.083 421.702 352.857 422.306 352.688 423.033C352.526 423.76 352.446 424.621 352.446 425.617ZM358.834 425.666C358.834 425.527 358.83 425.388 358.822 425.25C358.814 425.103 358.81 424.964 358.81 424.833L352.917 429.144C353.176 429.936 353.539 430.524 354.006 430.908C354.474 431.291 355.019 431.483 355.64 431.483C356.092 431.483 356.511 431.365 356.898 431.128C357.294 430.891 357.632 430.536 357.915 430.063C358.205 429.581 358.431 428.977 358.592 428.25C358.754 427.515 358.834 426.654 358.834 425.666Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 460.113V457.455H354.696V447.168L351.187 449.128L350.219 446.691L355.241 444.009H357.721V457.455H361.194V460.113H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 486.587V483.93H354.696V473.643L351.187 475.602L350.219 473.165L355.241 470.483H357.721V483.93H361.194V486.587H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 504.991C361.811 506.306 361.67 507.481 361.387 508.518C361.105 509.555 360.69 510.433 360.141 511.151C359.601 511.862 358.939 512.404 358.157 512.78C357.374 513.156 356.479 513.343 355.471 513.343C354.599 513.343 353.797 513.188 353.063 512.878C352.329 512.568 351.695 512.082 351.163 511.421C350.631 510.751 350.215 509.898 349.917 508.861C349.618 507.824 349.469 506.583 349.469 505.138C349.469 503.824 349.61 502.648 349.892 501.611C350.183 500.574 350.598 499.701 351.139 498.99C351.679 498.272 352.345 497.725 353.135 497.349C353.926 496.974 354.821 496.786 355.821 496.786C356.693 496.786 357.495 496.941 358.229 497.251C358.963 497.562 359.593 498.047 360.117 498.709C360.649 499.37 361.065 500.219 361.363 501.256C361.662 502.293 361.811 503.538 361.811 504.991ZM352.446 505.04C352.446 505.203 352.446 505.359 352.446 505.506C352.446 505.644 352.454 505.787 352.47 505.934L358.399 501.599C358.116 500.791 357.741 500.195 357.273 499.811C356.814 499.419 356.269 499.223 355.64 499.223C355.188 499.223 354.769 499.341 354.382 499.578C353.994 499.807 353.656 500.162 353.365 500.644C353.083 501.125 352.857 501.73 352.688 502.456C352.526 503.183 352.446 504.044 352.446 505.04ZM358.834 505.089C358.834 504.95 358.83 504.812 358.822 504.673C358.814 504.526 358.81 504.387 358.81 504.256L352.917 508.567C353.176 509.359 353.539 509.947 354.006 510.331C354.474 510.714 355.019 510.906 355.64 510.906C356.092 510.906 356.511 510.788 356.898 510.551C357.294 510.314 357.632 509.959 357.915 509.486C358.205 509.004 358.431 508.4 358.592 507.673C358.754 506.938 358.834 506.077 358.834 505.089Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 539.536V536.879H354.696V526.591L351.187 528.551L350.219 526.114L355.241 523.432H357.721V536.879H361.194V539.536H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 557.94C361.811 559.254 361.67 560.43 361.387 561.467C361.105 562.504 360.69 563.382 360.141 564.1C359.601 564.81 358.939 565.353 358.157 565.729C357.374 566.104 356.479 566.292 355.471 566.292C354.599 566.292 353.797 566.137 353.063 565.827C352.329 565.517 351.695 565.031 351.163 564.369C350.631 563.7 350.215 562.847 349.917 561.81C349.618 560.773 349.469 559.532 349.469 558.087C349.469 556.772 349.61 555.597 349.892 554.56C350.183 553.523 350.598 552.649 351.139 551.939C351.679 551.221 352.345 550.674 353.135 550.298C353.926 549.923 354.821 549.735 355.821 549.735C356.693 549.735 357.495 549.89 358.229 550.2C358.963 550.51 359.593 550.996 360.117 551.658C360.649 552.319 361.065 553.168 361.363 554.205C361.662 555.242 361.811 556.487 361.811 557.94ZM352.446 557.989C352.446 558.152 352.446 558.307 352.446 558.454C352.446 558.593 352.454 558.736 352.47 558.883L358.399 554.548C358.116 553.739 357.741 553.143 357.273 552.76C356.814 552.368 356.269 552.172 355.64 552.172C355.188 552.172 354.769 552.29 354.382 552.527C353.994 552.756 353.656 553.111 353.365 553.592C353.083 554.074 352.857 554.678 352.688 555.405C352.526 556.132 352.446 556.993 352.446 557.989ZM358.834 558.038C358.834 557.899 358.83 557.76 358.822 557.622C358.814 557.475 358.81 557.336 358.81 557.205L352.917 561.516C353.176 562.308 353.539 562.896 354.006 563.28C354.474 563.663 355.019 563.855 355.64 563.855C356.092 563.855 356.511 563.737 356.898 563.5C357.294 563.263 357.632 562.908 357.915 562.434C358.205 561.953 358.431 561.349 358.592 560.622C358.754 559.887 358.834 559.026 358.834 558.038Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 584.414C361.811 585.729 361.67 586.905 361.387 587.941C361.105 588.978 360.69 589.856 360.141 590.574C359.601 591.285 358.939 591.828 358.157 592.203C357.374 592.579 356.479 592.767 355.471 592.767C354.599 592.767 353.797 592.611 353.063 592.301C352.329 591.991 351.695 591.505 351.163 590.844C350.631 590.174 350.215 589.321 349.917 588.284C349.618 587.247 349.469 586.006 349.469 584.561C349.469 583.247 349.61 582.071 349.892 581.034C350.183 579.997 350.598 579.124 351.139 578.414C351.679 577.695 352.345 577.148 353.135 576.773C353.926 576.397 354.821 576.209 355.821 576.209C356.693 576.209 357.495 576.364 358.229 576.675C358.963 576.985 359.593 577.471 360.117 578.132C360.649 578.793 361.065 579.642 361.363 580.679C361.662 581.716 361.811 582.961 361.811 584.414ZM352.446 584.463C352.446 584.627 352.446 584.782 352.446 584.929C352.446 585.068 352.454 585.21 352.47 585.357L358.399 581.022C358.116 580.214 357.741 579.618 357.273 579.234C356.814 578.842 356.269 578.646 355.64 578.646C355.188 578.646 354.769 578.765 354.382 579.001C353.994 579.23 353.656 579.585 353.365 580.067C353.083 580.549 352.857 581.153 352.688 581.879C352.526 582.606 352.446 583.467 352.446 584.463ZM358.834 584.512C358.834 584.374 358.83 584.235 358.822 584.096C358.814 583.949 358.81 583.81 358.81 583.68L352.917 587.99C353.176 588.782 353.539 589.37 354.006 589.754C354.474 590.138 355.019 590.329 355.64 590.329C356.092 590.329 356.511 590.211 356.898 589.974C357.294 589.738 357.632 589.382 357.915 588.909C358.205 588.427 358.431 587.823 358.592 587.096C358.754 586.362 358.834 585.5 358.834 584.512Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 610.889C361.811 612.203 361.67 613.379 361.387 614.416C361.105 615.453 360.69 616.33 360.141 617.049C359.601 617.759 358.939 618.302 358.157 618.678C357.374 619.053 356.479 619.241 355.471 619.241C354.599 619.241 353.797 619.086 353.063 618.776C352.329 618.465 351.695 617.98 351.163 617.318C350.631 616.649 350.215 615.796 349.917 614.759C349.618 613.722 349.469 612.481 349.469 611.036C349.469 609.721 349.61 608.546 349.892 607.509C350.183 606.472 350.598 605.598 351.139 604.888C351.679 604.169 352.345 603.622 353.135 603.247C353.926 602.871 354.821 602.684 355.821 602.684C356.693 602.684 357.495 602.839 358.229 603.149C358.963 603.459 359.593 603.945 360.117 604.606C360.649 605.268 361.065 606.117 361.363 607.154C361.662 608.19 361.811 609.436 361.811 610.889ZM352.446 610.938C352.446 611.101 352.446 611.256 352.446 611.403C352.446 611.542 352.454 611.685 352.47 611.832L358.399 607.496C358.116 606.688 357.741 606.092 357.273 605.708C356.814 605.317 356.269 605.121 355.64 605.121C355.188 605.121 354.769 605.239 354.382 605.476C353.994 605.704 353.656 606.06 353.365 606.541C353.083 607.023 352.857 607.627 352.688 608.354C352.526 609.08 352.446 609.942 352.446 610.938ZM358.834 610.987C358.834 610.848 358.83 610.709 358.822 610.57C358.814 610.423 358.81 610.285 358.81 610.154L352.917 614.465C353.176 615.257 353.539 615.845 354.006 616.228C354.474 616.612 355.019 616.804 355.64 616.804C356.092 616.804 356.511 616.686 356.898 616.449C357.294 616.212 357.632 615.857 357.915 615.383C358.205 614.902 358.431 614.297 358.592 613.571C358.754 612.836 358.834 611.975 358.834 610.987Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 645.434V642.776H354.696V632.489L351.187 634.448L350.219 632.011L355.241 629.329H357.721V642.776H361.194V645.434H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 671.908V669.251H354.696V658.963L351.187 660.923L350.219 658.486L355.241 655.804H357.721V669.251H361.194V671.908H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 690.312C361.811 691.626 361.67 692.802 361.387 693.839C361.105 694.876 360.69 695.754 360.141 696.472C359.601 697.182 358.939 697.725 358.157 698.101C357.374 698.476 356.479 698.664 355.471 698.664C354.599 698.664 353.797 698.509 353.063 698.199C352.329 697.889 351.695 697.403 351.163 696.741C350.631 696.072 350.215 695.219 349.917 694.182C349.618 693.145 349.469 691.904 349.469 690.459C349.469 689.144 349.61 687.969 349.892 686.932C350.183 685.895 350.598 685.021 351.139 684.311C351.679 683.593 352.345 683.046 353.135 682.67C353.926 682.295 354.821 682.107 355.821 682.107C356.693 682.107 357.495 682.262 358.229 682.572C358.963 682.882 359.593 683.368 360.117 684.029C360.649 684.691 361.065 685.54 361.363 686.577C361.662 687.614 361.811 688.859 361.811 690.312ZM352.446 690.361C352.446 690.524 352.446 690.679 352.446 690.826C352.446 690.965 352.454 691.108 352.47 691.255L358.399 686.92C358.116 686.111 357.741 685.515 357.273 685.132C356.814 684.74 356.269 684.544 355.64 684.544C355.188 684.544 354.769 684.662 354.382 684.899C353.994 685.128 353.656 685.483 353.365 685.964C353.083 686.446 352.857 687.05 352.688 687.777C352.526 688.504 352.446 689.365 352.446 690.361ZM358.834 690.41C358.834 690.271 358.83 690.132 358.822 689.994C358.814 689.847 358.81 689.708 358.81 689.577L352.917 693.888C353.176 694.68 353.539 695.268 354.006 695.651C354.474 696.035 355.019 696.227 355.64 696.227C356.092 696.227 356.511 696.109 356.898 695.872C357.294 695.635 357.632 695.28 357.915 694.806C358.205 694.325 358.431 693.721 358.592 692.994C358.754 692.259 358.834 691.398 358.834 690.41Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 724.857V722.199H354.696V711.912L351.187 713.872L350.219 711.435L355.241 708.753H357.721V722.199H361.194V724.857H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 751.331V748.674H354.696V738.387L351.187 740.346L350.219 737.909L355.241 735.227H357.721V748.674H361.194V751.331H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 769.735C361.811 771.05 361.67 772.225 361.387 773.262C361.105 774.299 360.69 775.177 360.141 775.895C359.601 776.605 358.939 777.148 358.157 777.524C357.374 777.9 356.479 778.087 355.471 778.087C354.599 778.087 353.797 777.932 353.063 777.622C352.329 777.312 351.695 776.826 351.163 776.165C350.631 775.495 350.215 774.642 349.917 773.605C349.618 772.568 349.469 771.327 349.469 769.882C349.469 768.568 349.61 767.392 349.892 766.355C350.183 765.318 350.598 764.445 351.139 763.734C351.679 763.016 352.345 762.469 353.135 762.093C353.926 761.718 354.821 761.53 355.821 761.53C356.693 761.53 357.495 761.685 358.229 761.995C358.963 762.306 359.593 762.791 360.117 763.453C360.649 764.114 361.065 764.963 361.363 766C361.662 767.037 361.811 768.282 361.811 769.735ZM352.446 769.784C352.446 769.947 352.446 770.103 352.446 770.25C352.446 770.388 352.454 770.531 352.47 770.678L358.399 766.343C358.116 765.535 357.741 764.939 357.273 764.555C356.814 764.163 356.269 763.967 355.64 763.967C355.188 763.967 354.769 764.085 354.382 764.322C353.994 764.551 353.656 764.906 353.365 765.388C353.083 765.869 352.857 766.473 352.688 767.2C352.526 767.927 352.446 768.788 352.446 769.784ZM358.834 769.833C358.834 769.694 358.83 769.556 358.822 769.417C358.814 769.27 358.81 769.131 358.81 769L352.917 773.311C353.176 774.103 353.539 774.691 354.006 775.075C354.474 775.458 355.019 775.65 355.64 775.65C356.092 775.65 356.511 775.532 356.898 775.295C357.294 775.058 357.632 774.703 357.915 774.23C358.205 773.748 358.431 773.144 358.592 772.417C358.754 771.682 358.834 770.821 358.834 769.833Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 804.28V801.623H354.696V791.335L351.187 793.295L350.219 790.858L355.241 788.176H357.721V801.623H361.194V804.28H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 830.754V828.097H354.696V817.81L351.187 819.769L350.219 817.332L355.241 814.65H357.721V828.097H361.194V830.754H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 857.229V854.571H354.696V844.284L351.187 846.244L350.219 843.807L355.241 841.125H357.721V854.571H361.194V857.229H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 875.633C361.811 876.947 361.67 878.123 361.387 879.16C361.105 880.197 360.69 881.074 360.141 881.793C359.601 882.503 358.939 883.046 358.157 883.422C357.374 883.797 356.479 883.985 355.471 883.985C354.599 883.985 353.797 883.83 353.063 883.52C352.329 883.209 351.695 882.723 351.163 882.062C350.631 881.393 350.215 880.54 349.917 879.503C349.618 878.466 349.469 877.225 349.469 875.78C349.469 874.465 349.61 873.29 349.892 872.253C350.183 871.216 350.598 870.342 351.139 869.632C351.679 868.913 352.345 868.366 353.135 867.991C353.926 867.615 354.821 867.427 355.821 867.427C356.693 867.427 357.495 867.583 358.229 867.893C358.963 868.203 359.593 868.689 360.117 869.35C360.649 870.012 361.065 870.861 361.363 871.898C361.662 872.934 361.811 874.179 361.811 875.633ZM352.446 875.682C352.446 875.845 352.446 876 352.446 876.147C352.446 876.286 352.454 876.429 352.47 876.576L358.399 872.24C358.116 871.432 357.741 870.836 357.273 870.452C356.814 870.061 356.269 869.865 355.64 869.865C355.188 869.865 354.769 869.983 354.382 870.22C353.994 870.448 353.656 870.803 353.365 871.285C353.083 871.767 352.857 872.371 352.688 873.098C352.526 873.824 352.446 874.686 352.446 875.682ZM358.834 875.731C358.834 875.592 358.83 875.453 358.822 875.314C358.814 875.167 358.81 875.029 358.81 874.898L352.917 879.209C353.176 880.001 353.539 880.589 354.006 880.972C354.474 881.356 355.019 881.548 355.64 881.548C356.092 881.548 356.511 881.429 356.898 881.193C357.294 880.956 357.632 880.601 357.915 880.127C358.205 879.646 358.431 879.041 358.592 878.315C358.754 877.58 358.834 876.719 358.834 875.731Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M361.811 902.107C361.811 903.422 361.67 904.597 361.387 905.634C361.105 906.671 360.69 907.549 360.141 908.267C359.601 908.977 358.939 909.52 358.157 909.896C357.374 910.271 356.479 910.459 355.471 910.459C354.599 910.459 353.797 910.304 353.063 909.994C352.329 909.684 351.695 909.198 351.163 908.537C350.631 907.867 350.215 907.014 349.917 905.977C349.618 904.94 349.469 903.699 349.469 902.254C349.469 900.94 349.61 899.764 349.892 898.727C350.183 897.69 350.598 896.817 351.139 896.106C351.679 895.388 352.345 894.841 353.135 894.465C353.926 894.09 354.821 893.902 355.821 893.902C356.693 893.902 357.495 894.057 358.229 894.367C358.963 894.678 359.593 895.163 360.117 895.825C360.649 896.486 361.065 897.335 361.363 898.372C361.662 899.409 361.811 900.654 361.811 902.107ZM352.446 902.156C352.446 902.319 352.446 902.475 352.446 902.621C352.446 902.76 352.454 902.903 352.47 903.05L358.399 898.715C358.116 897.907 357.741 897.311 357.273 896.927C356.814 896.535 356.269 896.339 355.64 896.339C355.188 896.339 354.769 896.457 354.382 896.694C353.994 896.923 353.656 897.278 353.365 897.76C353.083 898.241 352.857 898.845 352.688 899.572C352.526 900.299 352.446 901.16 352.446 902.156ZM358.834 902.205C358.834 902.066 358.83 901.927 358.822 901.789C358.814 901.642 358.81 901.503 358.81 901.372L352.917 905.683C353.176 906.475 353.539 907.063 354.006 907.447C354.474 907.83 355.019 908.022 355.64 908.022C356.092 908.022 356.511 907.904 356.898 907.667C357.294 907.43 357.632 907.075 357.915 906.602C358.205 906.12 358.431 905.516 358.592 904.789C358.754 904.054 358.834 903.193 358.834 902.205Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M350.667 936.652V933.994H354.696V923.707L351.187 925.667L350.219 923.23L355.241 920.548H357.721V933.994H361.194V936.652H350.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M395.474 422.285C395.474 423.453 395.349 424.498 395.098 425.42C394.847 426.341 394.477 427.122 393.99 427.76C393.509 428.392 392.922 428.874 392.226 429.208C391.53 429.542 390.735 429.709 389.838 429.709C389.064 429.709 388.35 429.571 387.698 429.295C387.045 429.019 386.483 428.588 386.009 428C385.536 427.405 385.167 426.646 384.901 425.725C384.636 424.803 384.503 423.7 384.503 422.415C384.503 421.247 384.629 420.202 384.88 419.28C385.138 418.358 385.507 417.582 385.988 416.951C386.468 416.312 387.06 415.826 387.762 415.492C388.465 415.158 389.261 414.991 390.15 414.991C390.925 414.991 391.638 415.129 392.291 415.405C392.943 415.681 393.502 416.112 393.968 416.7C394.442 417.288 394.811 418.043 395.076 418.964C395.342 419.886 395.474 420.993 395.474 422.285ZM387.149 422.328C387.149 422.473 387.149 422.611 387.149 422.742C387.149 422.865 387.157 422.992 387.171 423.123L392.441 419.269C392.19 418.551 391.857 418.021 391.441 417.68C391.032 417.332 390.548 417.157 389.989 417.157C389.587 417.157 389.214 417.263 388.87 417.473C388.526 417.676 388.225 417.992 387.967 418.42C387.716 418.848 387.515 419.385 387.364 420.031C387.221 420.677 387.149 421.443 387.149 422.328ZM392.828 422.372C392.828 422.248 392.825 422.125 392.818 422.002C392.81 421.871 392.807 421.748 392.807 421.631L387.569 425.463C387.798 426.167 388.121 426.69 388.537 427.031C388.953 427.372 389.437 427.542 389.989 427.542C390.39 427.542 390.763 427.437 391.107 427.227C391.459 427.016 391.76 426.701 392.011 426.28C392.269 425.852 392.47 425.315 392.613 424.669C392.757 424.015 392.828 423.25 392.828 422.372Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 453.146V450.784H389.15V441.64L386.031 443.381L385.17 441.215L389.634 438.831H391.839V450.784H394.926V453.146H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 476.834V474.471H389.15V465.327L386.031 467.069L385.17 464.903L389.634 462.519H391.839V474.471H394.926V476.834H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M395.474 493.347C395.474 494.516 395.349 495.561 395.098 496.483C394.847 497.404 394.477 498.184 393.99 498.823C393.509 499.454 392.922 499.937 392.226 500.271C391.53 500.605 390.735 500.772 389.838 500.772C389.064 500.772 388.35 500.634 387.698 500.358C387.045 500.082 386.483 499.65 386.009 499.063C385.536 498.467 385.167 497.709 384.901 496.787C384.636 495.866 384.503 494.763 384.503 493.478C384.503 492.31 384.629 491.265 384.88 490.343C385.138 489.421 385.507 488.645 385.988 488.013C386.468 487.375 387.06 486.889 387.762 486.555C388.465 486.221 389.261 486.054 390.15 486.054C390.925 486.054 391.638 486.192 392.291 486.468C392.943 486.743 393.502 487.175 393.968 487.763C394.442 488.351 394.811 489.106 395.076 490.027C395.342 490.949 395.474 492.056 395.474 493.347ZM387.149 493.391C387.149 493.536 387.149 493.674 387.149 493.805C387.149 493.928 387.157 494.055 387.171 494.186L392.441 490.332C392.19 489.614 391.857 489.084 391.441 488.743C391.032 488.394 390.548 488.22 389.989 488.22C389.587 488.22 389.214 488.325 388.87 488.536C388.526 488.739 388.225 489.055 387.967 489.483C387.716 489.911 387.515 490.448 387.364 491.094C387.221 491.74 387.149 492.506 387.149 493.391ZM392.828 493.435C392.828 493.311 392.825 493.188 392.818 493.064C392.81 492.934 392.807 492.81 392.807 492.694L387.569 496.526C387.798 497.23 388.121 497.753 388.537 498.094C388.953 498.435 389.437 498.605 389.989 498.605C390.39 498.605 390.763 498.5 391.107 498.29C391.459 498.079 391.76 497.763 392.011 497.343C392.269 496.914 392.47 496.377 392.613 495.731C392.757 495.078 392.828 494.313 392.828 493.435Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 524.209V521.847H389.15V512.702L386.031 514.444L385.17 512.278L389.634 509.894H391.839V521.847H394.926V524.209H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M395.474 540.723C395.474 541.891 395.349 542.936 395.098 543.858C394.847 544.779 394.477 545.56 393.99 546.198C393.509 546.83 392.922 547.312 392.226 547.646C391.53 547.98 390.735 548.147 389.838 548.147C389.064 548.147 388.35 548.009 387.698 547.733C387.045 547.457 386.483 547.026 386.009 546.438C385.536 545.843 385.167 545.084 384.901 544.163C384.636 543.241 384.503 542.138 384.503 540.853C384.503 539.685 384.629 538.64 384.88 537.718C385.138 536.797 385.507 536.02 385.988 535.389C386.468 534.75 387.06 534.264 387.762 533.93C388.465 533.596 389.261 533.429 390.15 533.429C390.925 533.429 391.638 533.567 392.291 533.843C392.943 534.119 393.502 534.55 393.968 535.138C394.442 535.726 394.811 536.481 395.076 537.402C395.342 538.324 395.474 539.431 395.474 540.723ZM387.149 540.766C387.149 540.911 387.149 541.049 387.149 541.18C387.149 541.303 387.157 541.43 387.171 541.561L392.441 537.707C392.19 536.989 391.857 536.459 391.441 536.118C391.032 535.77 390.548 535.595 389.989 535.595C389.587 535.595 389.214 535.701 388.87 535.911C388.526 536.114 388.225 536.43 387.967 536.858C387.716 537.286 387.515 537.823 387.364 538.469C387.221 539.115 387.149 539.881 387.149 540.766ZM392.828 540.81C392.828 540.686 392.825 540.563 392.818 540.44C392.81 540.309 392.807 540.186 392.807 540.07L387.569 543.901C387.798 544.605 388.121 545.128 388.537 545.469C388.953 545.81 389.437 545.981 389.989 545.981C390.39 545.981 390.763 545.875 391.107 545.665C391.459 545.454 391.76 545.139 392.011 544.718C392.269 544.29 392.47 543.753 392.613 543.107C392.757 542.454 392.828 541.688 392.828 540.81Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 618.959V616.597H389.15V607.453L386.031 609.195L385.17 607.028L389.634 604.644H391.839V616.597H394.926V618.959H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 642.647V640.285H389.15V631.141L386.031 632.882L385.17 630.716L389.634 628.332H391.839V640.285H394.926V642.647H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M395.474 659.161C395.474 660.329 395.349 661.374 395.098 662.296C394.847 663.218 394.477 663.998 393.99 664.636C393.509 665.268 392.922 665.75 392.226 666.084C391.53 666.418 390.735 666.585 389.838 666.585C389.064 666.585 388.35 666.447 387.698 666.171C387.045 665.895 386.483 665.464 386.009 664.876C385.536 664.281 385.167 663.522 384.901 662.601C384.636 661.679 384.503 660.576 384.503 659.291C384.503 658.123 384.629 657.078 384.88 656.156C385.138 655.235 385.507 654.458 385.988 653.827C386.468 653.188 387.06 652.702 387.762 652.368C388.465 652.034 389.261 651.867 390.15 651.867C390.925 651.867 391.638 652.005 392.291 652.281C392.943 652.557 393.502 652.988 393.968 653.576C394.442 654.164 394.811 654.919 395.076 655.841C395.342 656.762 395.474 657.869 395.474 659.161ZM387.149 659.204C387.149 659.349 387.149 659.487 387.149 659.618C387.149 659.741 387.157 659.868 387.171 659.999L392.441 656.145C392.19 655.427 391.857 654.897 391.441 654.556C391.032 654.208 390.548 654.034 389.989 654.034C389.587 654.034 389.214 654.139 388.87 654.349C388.526 654.552 388.225 654.868 387.967 655.296C387.716 655.724 387.515 656.261 387.364 656.907C387.221 657.553 387.149 658.319 387.149 659.204ZM392.828 659.248C392.828 659.124 392.825 659.001 392.818 658.878C392.81 658.747 392.807 658.624 392.807 658.508L387.569 662.339C387.798 663.043 388.121 663.566 388.537 663.907C388.953 664.248 389.437 664.419 389.989 664.419C390.39 664.419 390.763 664.313 391.107 664.103C391.459 663.892 391.76 663.577 392.011 663.156C392.269 662.728 392.47 662.191 392.613 661.545C392.757 660.892 392.828 660.126 392.828 659.248Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 690.022V687.66H389.15V678.516L386.031 680.258L385.17 678.091L389.634 675.707H391.839V687.66H394.926V690.022H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 713.71V711.348H389.15V702.203L386.031 703.945L385.17 701.779L389.634 699.395H391.839V711.348H394.926V713.71H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M395.474 730.224C395.474 731.392 395.349 732.437 395.098 733.359C394.847 734.28 394.477 735.061 393.99 735.699C393.509 736.331 392.922 736.813 392.226 737.147C391.53 737.481 390.735 737.648 389.838 737.648C389.064 737.648 388.35 737.51 387.698 737.234C387.045 736.958 386.483 736.527 386.009 735.939C385.536 735.344 385.167 734.585 384.901 733.664C384.636 732.742 384.503 731.639 384.503 730.354C384.503 729.186 384.629 728.141 384.88 727.219C385.138 726.297 385.507 725.521 385.988 724.89C386.468 724.251 387.06 723.765 387.762 723.431C388.465 723.097 389.261 722.93 390.15 722.93C390.925 722.93 391.638 723.068 392.291 723.344C392.943 723.62 393.502 724.051 393.968 724.639C394.442 725.227 394.811 725.982 395.076 726.903C395.342 727.825 395.474 728.932 395.474 730.224ZM387.149 730.267C387.149 730.412 387.149 730.55 387.149 730.681C387.149 730.804 387.157 730.931 387.171 731.062L392.441 727.208C392.19 726.49 391.857 725.96 391.441 725.619C391.032 725.271 390.548 725.096 389.989 725.096C389.587 725.096 389.214 725.202 388.87 725.412C388.526 725.615 388.225 725.931 387.967 726.359C387.716 726.787 387.515 727.324 387.364 727.97C387.221 728.616 387.149 729.382 387.149 730.267ZM392.828 730.311C392.828 730.187 392.825 730.064 392.818 729.941C392.81 729.81 392.807 729.687 392.807 729.57L387.569 733.402C387.798 734.106 388.121 734.629 388.537 734.97C388.953 735.311 389.437 735.481 389.989 735.481C390.39 735.481 390.763 735.376 391.107 735.166C391.459 734.955 391.76 734.64 392.011 734.219C392.269 733.791 392.47 733.254 392.613 732.608C392.757 731.954 392.828 731.189 392.828 730.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 761.085V758.723H389.15V749.579L386.031 751.32L385.17 749.154L389.634 746.77H391.839V758.723H394.926V761.085H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 784.773V782.41H389.15V773.266L386.031 775.008L385.17 772.842L389.634 770.458H391.839V782.41H394.926V784.773H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 808.46V806.098H389.15V796.954L386.031 798.696L385.17 796.529L389.634 794.145H391.839V806.098H394.926V808.46H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M395.474 824.974C395.474 826.142 395.349 827.188 395.098 828.109C394.847 829.031 394.477 829.811 393.99 830.45C393.509 831.081 392.922 831.564 392.226 831.897C391.53 832.231 390.735 832.398 389.838 832.398C389.064 832.398 388.35 832.26 387.698 831.985C387.045 831.709 386.483 831.277 386.009 830.689C385.536 830.094 385.167 829.336 384.901 828.414C384.636 827.492 384.503 826.389 384.503 825.105C384.503 823.936 384.629 822.891 384.88 821.97C385.138 821.048 385.507 820.271 385.988 819.64C386.468 819.001 387.06 818.515 387.762 818.181C388.465 817.847 389.261 817.681 390.15 817.681C390.925 817.681 391.638 817.818 392.291 818.094C392.943 818.37 393.502 818.802 393.968 819.39C394.442 819.977 394.811 820.732 395.076 821.654C395.342 822.576 395.474 823.682 395.474 824.974ZM387.149 825.018C387.149 825.163 387.149 825.301 387.149 825.431C387.149 825.555 387.157 825.682 387.171 825.812L392.441 821.959C392.19 821.24 391.857 820.71 391.441 820.369C391.032 820.021 390.548 819.847 389.989 819.847C389.587 819.847 389.214 819.952 388.87 820.163C388.526 820.366 388.225 820.681 387.967 821.11C387.716 821.538 387.515 822.075 387.364 822.721C387.221 823.367 387.149 824.132 387.149 825.018ZM392.828 825.061C392.828 824.938 392.825 824.814 392.818 824.691C392.81 824.56 392.807 824.437 392.807 824.321L387.569 828.153C387.798 828.857 388.121 829.379 388.537 829.72C388.953 830.061 389.437 830.232 389.989 830.232C390.39 830.232 390.763 830.127 391.107 829.916C391.459 829.706 391.76 829.39 392.011 828.969C392.269 828.541 392.47 828.004 392.613 827.358C392.757 826.705 392.828 825.939 392.828 825.061Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M395.474 848.662C395.474 849.83 395.349 850.875 395.098 851.797C394.847 852.718 394.477 853.499 393.99 854.137C393.509 854.769 392.922 855.251 392.226 855.585C391.53 855.919 390.735 856.086 389.838 856.086C389.064 856.086 388.35 855.948 387.698 855.672C387.045 855.396 386.483 854.965 386.009 854.377C385.536 853.782 385.167 853.023 384.901 852.102C384.636 851.18 384.503 850.077 384.503 848.792C384.503 847.624 384.629 846.579 384.88 845.657C385.138 844.736 385.507 843.959 385.988 843.328C386.468 842.689 387.06 842.203 387.762 841.869C388.465 841.535 389.261 841.368 390.15 841.368C390.925 841.368 391.638 841.506 392.291 841.782C392.943 842.058 393.502 842.489 393.968 843.077C394.442 843.665 394.811 844.42 395.076 845.341C395.342 846.263 395.474 847.37 395.474 848.662ZM387.149 848.705C387.149 848.85 387.149 848.988 387.149 849.119C387.149 849.242 387.157 849.369 387.171 849.5L392.441 845.646C392.19 844.928 391.857 844.398 391.441 844.057C391.032 843.709 390.548 843.534 389.989 843.534C389.587 843.534 389.214 843.64 388.87 843.85C388.526 844.053 388.225 844.369 387.967 844.797C387.716 845.225 387.515 845.762 387.364 846.408C387.221 847.054 387.149 847.82 387.149 848.705ZM392.828 848.749C392.828 848.625 392.825 848.502 392.818 848.379C392.81 848.248 392.807 848.125 392.807 848.009L387.569 851.84C387.798 852.544 388.121 853.067 388.537 853.408C388.953 853.749 389.437 853.92 389.989 853.92C390.39 853.92 390.763 853.814 391.107 853.604C391.459 853.393 391.76 853.078 392.011 852.657C392.269 852.229 392.47 851.692 392.613 851.046C392.757 850.393 392.828 849.627 392.828 848.749Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M385.568 879.523V877.161H389.15V868.017L386.031 869.758L385.17 867.592L389.634 865.208H391.839V877.161H394.926V879.523H385.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M432.443 177.952H421.406V175.747H425.923V162.766L421.715 165.079L420.854 163.065L426.447 160.085H428.518V175.747H432.443V177.952Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 207.91H421.406V205.705H425.923V192.724L421.715 195.037L420.854 193.023L426.447 190.043H428.518V205.705H432.443V207.91Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 228.982C432.995 230.343 432.86 231.585 432.591 232.71C432.322 233.826 431.915 234.788 431.368 235.595C430.821 236.393 430.14 237.015 429.324 237.459C428.518 237.895 427.568 238.112 426.474 238.112C425.533 238.112 424.677 237.935 423.906 237.582C423.135 237.219 422.477 236.665 421.93 235.922C421.383 235.178 420.962 234.23 420.666 233.078C420.37 231.926 420.223 230.56 420.223 228.982C420.223 227.621 420.357 226.378 420.626 225.253C420.904 224.129 421.312 223.167 421.849 222.369C422.396 221.561 423.077 220.94 423.893 220.504C424.708 220.06 425.659 219.838 426.743 219.838C427.684 219.838 428.54 220.019 429.311 220.382C430.082 220.736 430.74 221.289 431.287 222.042C431.834 222.786 432.255 223.734 432.551 224.886C432.847 226.038 432.995 227.403 432.995 228.982ZM430.628 229.063C430.628 228.755 430.615 228.451 430.588 228.152C430.57 227.843 430.548 227.544 430.521 227.254L423.086 232.846C423.221 233.318 423.391 233.753 423.597 234.153C423.803 234.552 424.05 234.896 424.336 235.187C424.632 235.468 424.964 235.69 425.331 235.854C425.708 236.008 426.134 236.085 426.609 236.085C427.218 236.085 427.769 235.935 428.262 235.636C428.764 235.336 429.19 234.892 429.539 234.302C429.889 233.713 430.158 232.982 430.346 232.112C430.534 231.232 430.628 230.216 430.628 229.063ZM422.589 228.9C422.589 229.181 422.589 229.463 422.589 229.744C422.598 230.025 422.616 230.297 422.643 230.56L430.077 224.995C429.943 224.532 429.772 224.11 429.566 223.729C429.36 223.348 429.114 223.022 428.827 222.75C428.54 222.468 428.213 222.251 427.845 222.097C427.478 221.942 427.066 221.865 426.609 221.865C425.999 221.865 425.443 222.015 424.941 222.314C424.449 222.614 424.027 223.063 423.678 223.661C423.328 224.251 423.059 224.986 422.871 225.866C422.683 226.737 422.589 227.748 422.589 228.9Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 258.94C432.995 260.3 432.86 261.543 432.591 262.668C432.322 263.784 431.915 264.745 431.368 265.553C430.821 266.351 430.14 266.973 429.324 267.417C428.518 267.852 427.568 268.07 426.474 268.07C425.533 268.07 424.677 267.893 423.906 267.54C423.135 267.177 422.477 266.623 421.93 265.879C421.383 265.136 420.962 264.188 420.666 263.035C420.37 261.883 420.223 260.518 420.223 258.94C420.223 257.579 420.357 256.336 420.626 255.211C420.904 254.086 421.312 253.125 421.849 252.327C422.396 251.519 423.077 250.898 423.893 250.462C424.708 250.018 425.659 249.796 426.743 249.796C427.684 249.796 428.54 249.977 429.311 250.34C430.082 250.694 430.74 251.247 431.287 252C431.834 252.744 432.255 253.692 432.551 254.844C432.847 255.996 432.995 257.361 432.995 258.94ZM430.628 259.021C430.628 258.713 430.615 258.409 430.588 258.11C430.57 257.801 430.548 257.502 430.521 257.212L423.086 262.804C423.221 263.276 423.391 263.711 423.597 264.11C423.803 264.51 424.05 264.854 424.336 265.145C424.632 265.426 424.964 265.648 425.331 265.811C425.708 265.966 426.134 266.043 426.609 266.043C427.218 266.043 427.769 265.893 428.262 265.594C428.764 265.294 429.19 264.85 429.539 264.26C429.889 263.671 430.158 262.94 430.346 262.069C430.534 261.189 430.628 260.173 430.628 259.021ZM422.589 258.858C422.589 259.139 422.589 259.42 422.589 259.702C422.598 259.983 422.616 260.255 422.643 260.518L430.077 254.953C429.943 254.49 429.772 254.068 429.566 253.687C429.36 253.306 429.114 252.98 428.827 252.708C428.54 252.426 428.213 252.209 427.845 252.054C427.478 251.9 427.066 251.823 426.609 251.823C425.999 251.823 425.443 251.973 424.941 252.272C424.449 252.571 424.027 253.021 423.678 253.619C423.328 254.209 423.059 254.944 422.871 255.824C422.683 256.694 422.589 257.706 422.589 258.858Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 288.898C432.995 290.258 432.86 291.501 432.591 292.626C432.322 293.742 431.915 294.703 431.368 295.511C430.821 296.309 430.14 296.93 429.324 297.375C428.518 297.81 427.568 298.028 426.474 298.028C425.533 298.028 424.677 297.851 423.906 297.497C423.135 297.135 422.477 296.581 421.93 295.837C421.383 295.093 420.962 294.145 420.666 292.993C420.37 291.841 420.223 290.476 420.223 288.898C420.223 287.537 420.357 286.294 420.626 285.169C420.904 284.044 421.312 283.083 421.849 282.284C422.396 281.477 423.077 280.856 423.893 280.42C424.708 279.976 425.659 279.753 426.743 279.753C427.684 279.753 428.54 279.935 429.311 280.298C430.082 280.652 430.74 281.205 431.287 281.958C431.834 282.702 432.255 283.65 432.551 284.802C432.847 285.954 432.995 287.319 432.995 288.898ZM430.628 288.979C430.628 288.671 430.615 288.367 430.588 288.068C430.57 287.759 430.548 287.46 430.521 287.169L423.086 292.762C423.221 293.234 423.391 293.669 423.597 294.068C423.803 294.467 424.05 294.812 424.336 295.103C424.632 295.384 424.964 295.606 425.331 295.769C425.708 295.923 426.134 296.001 426.609 296.001C427.218 296.001 427.769 295.851 428.262 295.552C428.764 295.252 429.19 294.808 429.539 294.218C429.889 293.628 430.158 292.898 430.346 292.027C430.534 291.147 430.628 290.131 430.628 288.979ZM422.589 288.816C422.589 289.097 422.589 289.378 422.589 289.66C422.598 289.941 422.616 290.213 422.643 290.476L430.077 284.911C429.943 284.448 429.772 284.026 429.566 283.645C429.36 283.264 429.114 282.938 428.827 282.665C428.54 282.384 428.213 282.166 427.845 282.012C427.478 281.858 427.066 281.781 426.609 281.781C425.999 281.781 425.443 281.931 424.941 282.23C424.449 282.529 424.027 282.978 423.678 283.577C423.328 284.167 423.059 284.902 422.871 285.781C422.683 286.652 422.589 287.664 422.589 288.816Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 327.741H421.406V325.537H425.923V312.555L421.715 314.868L420.854 312.855L426.447 309.875H428.518V325.537H432.443V327.741Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 348.813C432.995 350.174 432.86 351.417 432.591 352.542C432.322 353.658 431.915 354.619 431.368 355.426C430.821 356.225 430.14 356.846 429.324 357.291C428.518 357.726 427.568 357.944 426.474 357.944C425.533 357.944 424.677 357.767 423.906 357.413C423.135 357.05 422.477 356.497 421.93 355.753C421.383 355.009 420.962 354.061 420.666 352.909C420.37 351.757 420.223 350.392 420.223 348.813C420.223 347.453 420.357 346.21 420.626 345.085C420.904 343.96 421.312 342.998 421.849 342.2C422.396 341.393 423.077 340.771 423.893 340.336C424.708 339.891 425.659 339.669 426.743 339.669C427.684 339.669 428.54 339.851 429.311 340.213C430.082 340.567 430.74 341.121 431.287 341.874C431.834 342.617 432.255 343.565 432.551 344.717C432.847 345.87 432.995 347.235 432.995 348.813ZM430.628 348.895C430.628 348.587 430.615 348.283 430.588 347.983C430.57 347.675 430.548 347.375 430.521 347.085L423.086 352.678C423.221 353.15 423.391 353.585 423.597 353.984C423.803 354.383 424.05 354.728 424.336 355.018C424.632 355.299 424.964 355.522 425.331 355.685C425.708 355.839 426.134 355.916 426.609 355.916C427.218 355.916 427.769 355.767 428.262 355.467C428.764 355.168 429.19 354.723 429.539 354.134C429.889 353.544 430.158 352.814 430.346 351.943C430.534 351.063 430.628 350.047 430.628 348.895ZM422.589 348.732C422.589 349.013 422.589 349.294 422.589 349.575C422.598 349.857 422.616 350.129 422.643 350.392L430.077 344.826C429.943 344.364 429.772 343.942 429.566 343.561C429.36 343.18 429.114 342.853 428.827 342.581C428.54 342.3 428.213 342.082 427.845 341.928C427.478 341.774 427.066 341.697 426.609 341.697C425.999 341.697 425.443 341.846 424.941 342.146C424.449 342.445 424.027 342.894 423.678 343.493C423.328 344.082 423.059 344.817 422.871 345.697C422.683 346.568 422.589 347.58 422.589 348.732Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 387.657H421.406V385.452H425.923V372.471L421.715 374.784L420.854 372.77L426.447 369.79H428.518V385.452H432.443V387.657Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 417.615H421.406V415.41H425.923V402.429L421.715 404.742L420.854 402.728L426.447 399.748H428.518V415.41H432.443V417.615Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 438.687C432.995 440.048 432.86 441.29 432.591 442.415C432.322 443.531 431.915 444.493 431.368 445.3C430.821 446.098 430.14 446.72 429.324 447.164C428.518 447.6 427.568 447.817 426.474 447.817C425.533 447.817 424.677 447.641 423.906 447.287C423.135 446.924 422.477 446.371 421.93 445.627C421.383 444.883 420.962 443.935 420.666 442.783C420.37 441.631 420.223 440.265 420.223 438.687C420.223 437.326 420.357 436.083 420.626 434.958C420.904 433.834 421.312 432.872 421.849 432.074C422.396 431.266 423.077 430.645 423.893 430.21C424.708 429.765 425.659 429.543 426.743 429.543C427.684 429.543 428.54 429.724 429.311 430.087C430.082 430.441 430.74 430.994 431.287 431.747C431.834 432.491 432.255 433.439 432.551 434.591C432.847 435.743 432.995 437.108 432.995 438.687ZM430.628 438.769C430.628 438.46 430.615 438.156 430.588 437.857C430.57 437.548 430.548 437.249 430.521 436.959L423.086 442.551C423.221 443.023 423.391 443.459 423.597 443.858C423.803 444.257 424.05 444.602 424.336 444.892C424.632 445.173 424.964 445.395 425.331 445.559C425.708 445.713 426.134 445.79 426.609 445.79C427.218 445.79 427.769 445.64 428.262 445.341C428.764 445.042 429.19 444.597 429.539 444.007C429.889 443.418 430.158 442.687 430.346 441.817C430.534 440.937 430.628 439.921 430.628 438.769ZM422.589 438.605C422.589 438.886 422.589 439.168 422.589 439.449C422.598 439.73 422.616 440.002 422.643 440.265L430.077 434.7C429.943 434.237 429.772 433.815 429.566 433.434C429.36 433.053 429.114 432.727 428.827 432.455C428.54 432.174 428.213 431.956 427.845 431.802C427.478 431.647 427.066 431.57 426.609 431.57C425.999 431.57 425.443 431.72 424.941 432.019C424.449 432.319 424.027 432.768 423.678 433.366C423.328 433.956 423.059 434.691 422.871 435.571C422.683 436.442 422.589 437.453 422.589 438.605Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 468.645C432.995 470.006 432.86 471.248 432.591 472.373C432.322 473.489 431.915 474.451 431.368 475.258C430.821 476.056 430.14 476.678 429.324 477.122C428.518 477.558 427.568 477.775 426.474 477.775C425.533 477.775 424.677 477.598 423.906 477.245C423.135 476.882 422.477 476.328 421.93 475.585C421.383 474.841 420.962 473.893 420.666 472.741C420.37 471.589 420.223 470.223 420.223 468.645C420.223 467.284 420.357 466.041 420.626 464.916C420.904 463.792 421.312 462.83 421.849 462.032C422.396 461.224 423.077 460.603 423.893 460.167C424.708 459.723 425.659 459.501 426.743 459.501C427.684 459.501 428.54 459.682 429.311 460.045C430.082 460.399 430.74 460.952 431.287 461.705C431.834 462.449 432.255 463.397 432.551 464.549C432.847 465.701 432.995 467.066 432.995 468.645ZM430.628 468.726C430.628 468.418 430.615 468.114 430.588 467.815C430.57 467.506 430.548 467.207 430.521 466.917L423.086 472.509C423.221 472.981 423.391 473.416 423.597 473.816C423.803 474.215 424.05 474.559 424.336 474.85C424.632 475.131 424.964 475.353 425.331 475.516C425.708 475.671 426.134 475.748 426.609 475.748C427.218 475.748 427.769 475.598 428.262 475.299C428.764 474.999 429.19 474.555 429.539 473.965C429.889 473.376 430.158 472.645 430.346 471.774C430.534 470.895 430.628 469.879 430.628 468.726ZM422.589 468.563C422.589 468.844 422.589 469.126 422.589 469.407C422.598 469.688 422.616 469.96 422.643 470.223L430.077 464.658C429.943 464.195 429.772 463.773 429.566 463.392C429.36 463.011 429.114 462.685 428.827 462.413C428.54 462.131 428.213 461.914 427.845 461.759C427.478 461.605 427.066 461.528 426.609 461.528C425.999 461.528 425.443 461.678 424.941 461.977C424.449 462.277 424.027 462.726 423.678 463.324C423.328 463.914 423.059 464.649 422.871 465.529C422.683 466.4 422.589 467.411 422.589 468.563Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 498.603C432.995 499.963 432.86 501.206 432.591 502.331C432.322 503.447 431.915 504.408 431.368 505.216C430.821 506.014 430.14 506.635 429.324 507.08C428.518 507.515 427.568 507.733 426.474 507.733C425.533 507.733 424.677 507.556 423.906 507.202C423.135 506.84 422.477 506.286 421.93 505.542C421.383 504.799 420.962 503.851 420.666 502.698C420.37 501.546 420.223 500.181 420.223 498.603C420.223 497.242 420.357 495.999 420.626 494.874C420.904 493.749 421.312 492.788 421.849 491.99C422.396 491.182 423.077 490.561 423.893 490.125C424.708 489.681 425.659 489.459 426.743 489.459C427.684 489.459 428.54 489.64 429.311 490.003C430.082 490.357 430.74 490.91 431.287 491.663C431.834 492.407 432.255 493.355 432.551 494.507C432.847 495.659 432.995 497.024 432.995 498.603ZM430.628 498.684C430.628 498.376 430.615 498.072 430.588 497.773C430.57 497.464 430.548 497.165 430.521 496.875L423.086 502.467C423.221 502.939 423.391 503.374 423.597 503.773C423.803 504.173 424.05 504.517 424.336 504.808C424.632 505.089 424.964 505.311 425.331 505.474C425.708 505.629 426.134 505.706 426.609 505.706C427.218 505.706 427.769 505.556 428.262 505.257C428.764 504.957 429.19 504.513 429.539 503.923C429.889 503.333 430.158 502.603 430.346 501.732C430.534 500.852 430.628 499.836 430.628 498.684ZM422.589 498.521C422.589 498.802 422.589 499.083 422.589 499.365C422.598 499.646 422.616 499.918 422.643 500.181L430.077 494.616C429.943 494.153 429.772 493.731 429.566 493.35C429.36 492.969 429.114 492.643 428.827 492.37C428.54 492.089 428.213 491.872 427.845 491.717C427.478 491.563 427.066 491.486 426.609 491.486C425.999 491.486 425.443 491.636 424.941 491.935C424.449 492.234 424.027 492.683 423.678 493.282C423.328 493.872 423.059 494.607 422.871 495.487C422.683 496.357 422.589 497.369 422.589 498.521Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 537.446H421.406V535.242H425.923V522.26L421.715 524.574L420.854 522.56L426.447 519.58H428.518V535.242H432.443V537.446Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 567.404H421.406V565.2H425.923V552.218L421.715 554.531L420.854 552.518L426.447 549.538H428.518V565.2H432.443V567.404Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 597.362H421.406V595.157H425.923V582.176L421.715 584.489L420.854 582.475L426.447 579.495H428.518V595.157H432.443V597.362Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 618.434C432.995 619.795 432.86 621.038 432.591 622.163C432.322 623.278 431.915 624.24 431.368 625.047C430.821 625.846 430.14 626.467 429.324 626.911C428.518 627.347 427.568 627.565 426.474 627.565C425.533 627.565 424.677 627.388 423.906 627.034C423.135 626.671 422.477 626.118 421.93 625.374C421.383 624.63 420.962 623.682 420.666 622.53C420.37 621.378 420.223 620.013 420.223 618.434C420.223 617.073 420.357 615.831 420.626 614.706C420.904 613.581 421.312 612.619 421.849 611.821C422.396 611.014 423.077 610.392 423.893 609.957C424.708 609.512 425.659 609.29 426.743 609.29C427.684 609.29 428.54 609.471 429.311 609.834C430.082 610.188 430.74 610.741 431.287 611.494C431.834 612.238 432.255 613.186 432.551 614.338C432.847 615.49 432.995 616.856 432.995 618.434ZM430.628 618.516C430.628 618.207 430.615 617.903 430.588 617.604C430.57 617.296 430.548 616.996 430.521 616.706L423.086 622.299C423.221 622.77 423.391 623.206 423.597 623.605C423.803 624.004 424.05 624.349 424.336 624.639C424.632 624.92 424.964 625.143 425.331 625.306C425.708 625.46 426.134 625.537 426.609 625.537C427.218 625.537 427.769 625.387 428.262 625.088C428.764 624.789 429.19 624.344 429.539 623.755C429.889 623.165 430.158 622.435 430.346 621.564C430.534 620.684 430.628 619.668 430.628 618.516ZM422.589 618.352C422.589 618.634 422.589 618.915 422.589 619.196C422.598 619.477 422.616 619.749 422.643 620.013L430.077 614.447C429.943 613.985 429.772 613.563 429.566 613.182C429.36 612.801 429.114 612.474 428.827 612.202C428.54 611.921 428.213 611.703 427.845 611.549C427.478 611.395 427.066 611.318 426.609 611.318C425.999 611.318 425.443 611.467 424.941 611.767C424.449 612.066 424.027 612.515 423.678 613.114C423.328 613.703 423.059 614.438 422.871 615.318C422.683 616.189 422.589 617.2 422.589 618.352Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 657.278H421.406V655.073H425.923V642.092L421.715 644.405L420.854 642.391L426.447 639.411H428.518V655.073H432.443V657.278Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 678.35C432.995 679.711 432.86 680.953 432.591 682.078C432.322 683.194 431.915 684.156 431.368 684.963C430.821 685.761 430.14 686.383 429.324 686.827C428.518 687.263 427.568 687.48 426.474 687.48C425.533 687.48 424.677 687.303 423.906 686.95C423.135 686.587 422.477 686.033 421.93 685.29C421.383 684.546 420.962 683.598 420.666 682.446C420.37 681.294 420.223 679.928 420.223 678.35C420.223 676.989 420.357 675.746 420.626 674.621C420.904 673.497 421.312 672.535 421.849 671.737C422.396 670.929 423.077 670.308 423.893 669.872C424.708 669.428 425.659 669.206 426.743 669.206C427.684 669.206 428.54 669.387 429.311 669.75C430.082 670.104 430.74 670.657 431.287 671.41C431.834 672.154 432.255 673.102 432.551 674.254C432.847 675.406 432.995 676.771 432.995 678.35ZM430.628 678.432C430.628 678.123 430.615 677.819 430.588 677.52C430.57 677.211 430.548 676.912 430.521 676.622L423.086 682.214C423.221 682.686 423.391 683.121 423.597 683.521C423.803 683.92 424.05 684.265 424.336 684.555C424.632 684.836 424.964 685.058 425.331 685.222C425.708 685.376 426.134 685.453 426.609 685.453C427.218 685.453 427.769 685.303 428.262 685.004C428.764 684.704 429.19 684.26 429.539 683.67C429.889 683.081 430.158 682.35 430.346 681.48C430.534 680.6 430.628 679.584 430.628 678.432ZM422.589 678.268C422.589 678.549 422.589 678.831 422.589 679.112C422.598 679.393 422.616 679.665 422.643 679.928L430.077 674.363C429.943 673.9 429.772 673.478 429.566 673.097C429.36 672.716 429.114 672.39 428.827 672.118C428.54 671.836 428.213 671.619 427.845 671.465C427.478 671.31 427.066 671.233 426.609 671.233C425.999 671.233 425.443 671.383 424.941 671.682C424.449 671.982 424.027 672.431 423.678 673.029C423.328 673.619 423.059 674.354 422.871 675.234C422.683 676.105 422.589 677.116 422.589 678.268Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.995 708.308C432.995 709.668 432.86 710.911 432.591 712.036C432.322 713.152 431.915 714.114 431.368 714.921C430.821 715.719 430.14 716.341 429.324 716.785C428.518 717.221 427.568 717.438 426.474 717.438C425.533 717.438 424.677 717.261 423.906 716.908C423.135 716.545 422.477 715.991 421.93 715.247C421.383 714.504 420.962 713.556 420.666 712.404C420.37 711.251 420.223 709.886 420.223 708.308C420.223 706.947 420.357 705.704 420.626 704.579C420.904 703.454 421.312 702.493 421.849 701.695C422.396 700.887 423.077 700.266 423.893 699.83C424.708 699.386 425.659 699.164 426.743 699.164C427.684 699.164 428.54 699.345 429.311 699.708C430.082 700.062 430.74 700.615 431.287 701.368C431.834 702.112 432.255 703.06 432.551 704.212C432.847 705.364 432.995 706.729 432.995 708.308ZM430.628 708.389C430.628 708.081 430.615 707.777 430.588 707.478C430.57 707.169 430.548 706.87 430.521 706.58L423.086 712.172C423.221 712.644 423.391 713.079 423.597 713.479C423.803 713.878 424.05 714.222 424.336 714.513C424.632 714.794 424.964 715.016 425.331 715.179C425.708 715.334 426.134 715.411 426.609 715.411C427.218 715.411 427.769 715.261 428.262 714.962C428.764 714.662 429.19 714.218 429.539 713.628C429.889 713.039 430.158 712.308 430.346 711.437C430.534 710.557 430.628 709.541 430.628 708.389ZM422.589 708.226C422.589 708.507 422.589 708.789 422.589 709.07C422.598 709.351 422.616 709.623 422.643 709.886L430.077 704.321C429.943 703.858 429.772 703.436 429.566 703.055C429.36 702.674 429.114 702.348 428.827 702.076C428.54 701.794 428.213 701.577 427.845 701.422C427.478 701.268 427.066 701.191 426.609 701.191C425.999 701.191 425.443 701.341 424.941 701.64C424.449 701.94 424.027 702.389 423.678 702.987C423.328 703.577 423.059 704.312 422.871 705.192C422.683 706.062 422.589 707.074 422.589 708.226Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M432.443 747.151H421.406V744.947H425.923V731.965L421.715 734.279L420.854 732.265L426.447 729.285H428.518V744.947H432.443V747.151Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 416.221H551.149V414.347H554.989V403.313L551.412 405.28L550.681 403.568L555.435 401.035H557.195V414.347H560.531V416.221Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 441.302H551.149V439.428H554.989V428.394L551.412 430.361L550.681 428.649L555.435 426.116H557.195V439.428H560.531V441.302Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 458.83C561 459.987 560.886 461.043 560.657 462C560.429 462.948 560.082 463.765 559.617 464.452C559.153 465.13 558.574 465.658 557.88 466.036C557.195 466.406 556.387 466.591 555.458 466.591C554.658 466.591 553.93 466.441 553.275 466.14C552.62 465.832 552.06 465.362 551.595 464.729C551.13 464.097 550.772 463.291 550.521 462.312C550.269 461.333 550.144 460.172 550.144 458.83C550.144 457.674 550.258 456.617 550.486 455.661C550.723 454.705 551.069 453.888 551.526 453.209C551.991 452.523 552.57 451.995 553.263 451.625C553.957 451.247 554.764 451.058 555.686 451.058C556.486 451.058 557.214 451.212 557.869 451.521C558.524 451.821 559.084 452.292 559.549 452.932C560.013 453.564 560.371 454.37 560.623 455.349C560.874 456.328 561 457.489 561 458.83ZM558.989 458.9C558.989 458.638 558.977 458.379 558.954 458.125C558.939 457.863 558.92 457.608 558.897 457.362L552.578 462.115C552.692 462.516 552.837 462.886 553.012 463.226C553.187 463.565 553.397 463.858 553.641 464.105C553.892 464.344 554.174 464.533 554.486 464.671C554.806 464.802 555.168 464.868 555.572 464.868C556.09 464.868 556.558 464.741 556.977 464.486C557.404 464.232 557.766 463.854 558.063 463.353C558.36 462.852 558.589 462.231 558.749 461.491C558.909 460.743 558.989 459.879 558.989 458.9ZM552.155 458.761C552.155 459 552.155 459.239 552.155 459.478C552.163 459.717 552.178 459.949 552.201 460.172L558.52 455.442C558.406 455.048 558.261 454.69 558.086 454.366C557.911 454.042 557.701 453.764 557.457 453.533C557.214 453.294 556.936 453.109 556.623 452.978C556.311 452.847 555.96 452.781 555.572 452.781C555.054 452.781 554.581 452.909 554.155 453.163C553.736 453.417 553.378 453.799 553.081 454.308C552.783 454.809 552.555 455.434 552.395 456.182C552.235 456.922 552.155 457.782 552.155 458.761Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 483.911C561 485.068 560.886 486.124 560.657 487.081C560.429 488.029 560.082 488.846 559.617 489.533C559.153 490.211 558.574 490.739 557.88 491.117C557.195 491.487 556.387 491.672 555.458 491.672C554.658 491.672 553.93 491.522 553.275 491.221C552.62 490.913 552.06 490.443 551.595 489.81C551.13 489.178 550.772 488.372 550.521 487.393C550.269 486.414 550.144 485.253 550.144 483.911C550.144 482.755 550.258 481.698 550.486 480.742C550.723 479.786 551.069 478.969 551.526 478.29C551.991 477.604 552.57 477.076 553.263 476.706C553.957 476.328 554.764 476.139 555.686 476.139C556.486 476.139 557.214 476.293 557.869 476.602C558.524 476.902 559.084 477.373 559.549 478.013C560.013 478.645 560.371 479.451 560.623 480.43C560.874 481.409 561 482.57 561 483.911ZM558.989 483.981C558.989 483.719 558.977 483.46 558.954 483.206C558.939 482.944 558.92 482.689 558.897 482.443L552.578 487.196C552.692 487.597 552.837 487.967 553.012 488.307C553.187 488.646 553.397 488.939 553.641 489.186C553.892 489.425 554.174 489.614 554.486 489.752C554.806 489.883 555.168 489.949 555.572 489.949C556.09 489.949 556.558 489.822 556.977 489.567C557.404 489.313 557.766 488.935 558.063 488.434C558.36 487.933 558.589 487.312 558.749 486.572C558.909 485.824 558.989 484.96 558.989 483.981ZM552.155 483.842C552.155 484.081 552.155 484.32 552.155 484.559C552.163 484.798 552.178 485.03 552.201 485.253L558.52 480.523C558.406 480.129 558.261 479.771 558.086 479.447C557.911 479.123 557.701 478.845 557.457 478.614C557.214 478.375 556.936 478.19 556.623 478.059C556.311 477.928 555.96 477.862 555.572 477.862C555.054 477.862 554.581 477.99 554.155 478.244C553.736 478.498 553.378 478.88 553.081 479.389C552.783 479.89 552.555 480.515 552.395 481.263C552.235 482.003 552.155 482.863 552.155 483.842Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 508.992C561 510.149 560.886 511.205 560.657 512.162C560.429 513.11 560.082 513.927 559.617 514.614C559.153 515.292 558.574 515.82 557.88 516.198C557.195 516.568 556.387 516.753 555.458 516.753C554.658 516.753 553.93 516.603 553.275 516.302C552.62 515.994 552.06 515.524 551.595 514.891C551.13 514.259 550.772 513.453 550.521 512.474C550.269 511.495 550.144 510.334 550.144 508.992C550.144 507.836 550.258 506.779 550.486 505.823C550.723 504.867 551.069 504.05 551.526 503.371C551.991 502.685 552.57 502.157 553.263 501.787C553.957 501.409 554.764 501.22 555.686 501.22C556.486 501.22 557.214 501.374 557.869 501.683C558.524 501.983 559.084 502.454 559.549 503.094C560.013 503.726 560.371 504.532 560.623 505.511C560.874 506.49 561 507.651 561 508.992ZM558.989 509.062C558.989 508.8 558.977 508.541 558.954 508.287C558.939 508.025 558.92 507.77 558.897 507.524L552.578 512.277C552.692 512.678 552.837 513.048 553.012 513.388C553.187 513.727 553.397 514.02 553.641 514.267C553.892 514.506 554.174 514.695 554.486 514.833C554.806 514.964 555.168 515.03 555.572 515.03C556.09 515.03 556.558 514.903 556.977 514.648C557.404 514.394 557.766 514.016 558.063 513.515C558.36 513.014 558.589 512.393 558.749 511.653C558.909 510.905 558.989 510.041 558.989 509.062ZM552.155 508.923C552.155 509.162 552.155 509.401 552.155 509.64C552.163 509.879 552.178 510.111 552.201 510.334L558.52 505.604C558.406 505.21 558.261 504.852 558.086 504.528C557.911 504.204 557.701 503.926 557.457 503.695C557.214 503.456 556.936 503.271 556.623 503.14C556.311 503.009 555.96 502.943 555.572 502.943C555.054 502.943 554.581 503.071 554.155 503.325C553.736 503.579 553.378 503.961 553.081 504.47C552.783 504.971 552.555 505.596 552.395 506.344C552.235 507.084 552.155 507.944 552.155 508.923Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 541.626H551.149V539.752H554.989V528.718L551.412 530.685L550.681 528.973L555.435 526.44H557.195V539.752H560.531V541.626Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 559.154C561 560.311 560.886 561.367 560.657 562.324C560.429 563.272 560.082 564.089 559.617 564.776C559.153 565.454 558.574 565.982 557.88 566.36C557.195 566.73 556.387 566.915 555.458 566.915C554.658 566.915 553.93 566.765 553.275 566.464C552.62 566.156 552.06 565.686 551.595 565.053C551.13 564.421 550.772 563.615 550.521 562.636C550.269 561.657 550.144 560.496 550.144 559.154C550.144 557.998 550.258 556.941 550.486 555.985C550.723 555.029 551.069 554.212 551.526 553.533C551.991 552.847 552.57 552.319 553.263 551.949C553.957 551.571 554.764 551.382 555.686 551.382C556.486 551.382 557.214 551.536 557.869 551.845C558.524 552.145 559.084 552.616 559.549 553.256C560.013 553.888 560.371 554.694 560.623 555.673C560.874 556.652 561 557.813 561 559.154ZM558.989 559.224C558.989 558.962 558.977 558.703 558.954 558.449C558.939 558.187 558.92 557.932 558.897 557.686L552.578 562.439C552.692 562.84 552.837 563.21 553.012 563.55C553.187 563.889 553.397 564.182 553.641 564.429C553.892 564.668 554.174 564.857 554.486 564.995C554.806 565.127 555.168 565.192 555.572 565.192C556.09 565.192 556.558 565.065 556.977 564.81C557.404 564.556 557.766 564.178 558.063 563.677C558.36 563.176 558.589 562.555 558.749 561.815C558.909 561.067 558.989 560.203 558.989 559.224ZM552.155 559.085C552.155 559.324 552.155 559.563 552.155 559.802C552.163 560.041 552.178 560.273 552.201 560.496L558.52 555.766C558.406 555.372 558.261 555.014 558.086 554.69C557.911 554.366 557.701 554.088 557.457 553.857C557.214 553.618 556.936 553.433 556.623 553.302C556.311 553.171 555.96 553.105 555.572 553.105C555.054 553.105 554.581 553.233 554.155 553.487C553.736 553.741 553.378 554.123 553.081 554.632C552.783 555.133 552.555 555.758 552.395 556.506C552.235 557.246 552.155 558.106 552.155 559.085Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 591.788H551.149V589.915H554.989V578.88L551.412 580.847L550.681 579.135L555.435 576.602H557.195V589.915H560.531V591.788Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 616.869H551.149V614.996H554.989V603.961L551.412 605.928L550.681 604.216L555.435 601.683H557.195V614.996H560.531V616.869Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 634.398C561 635.554 560.886 636.611 560.657 637.567C560.429 638.515 560.082 639.332 559.617 640.019C559.153 640.697 558.574 641.225 557.88 641.603C557.195 641.973 556.387 642.158 555.458 642.158C554.658 642.158 553.93 642.008 553.275 641.707C552.62 641.399 552.06 640.929 551.595 640.296C551.13 639.664 550.772 638.858 550.521 637.879C550.269 636.9 550.144 635.739 550.144 634.398C550.144 633.241 550.258 632.185 550.486 631.228C550.723 630.272 551.069 629.455 551.526 628.776C551.991 628.09 552.57 627.562 553.263 627.192C553.957 626.814 554.764 626.625 555.686 626.625C556.486 626.625 557.214 626.779 557.869 627.088C558.524 627.388 559.084 627.859 559.549 628.499C560.013 629.131 560.371 629.937 560.623 630.916C560.874 631.895 561 633.056 561 634.398ZM558.989 634.467C558.989 634.205 558.977 633.946 558.954 633.692C558.939 633.43 558.92 633.175 558.897 632.929L552.578 637.682C552.692 638.083 552.837 638.453 553.012 638.793C553.187 639.132 553.397 639.425 553.641 639.672C553.892 639.911 554.174 640.1 554.486 640.238C554.806 640.37 555.168 640.435 555.572 640.435C556.09 640.435 556.558 640.308 556.977 640.053C557.404 639.799 557.766 639.421 558.063 638.92C558.36 638.419 558.589 637.798 558.749 637.058C558.909 636.31 558.989 635.446 558.989 634.467ZM552.155 634.328C552.155 634.567 552.155 634.806 552.155 635.045C552.163 635.284 552.178 635.516 552.201 635.739L558.52 631.009C558.406 630.615 558.261 630.257 558.086 629.933C557.911 629.609 557.701 629.331 557.457 629.1C557.214 628.861 556.936 628.676 556.623 628.545C556.311 628.414 555.96 628.348 555.572 628.348C555.054 628.348 554.581 628.476 554.155 628.73C553.736 628.985 553.378 629.366 553.081 629.875C552.783 630.376 552.555 631.001 552.395 631.749C552.235 632.489 552.155 633.349 552.155 634.328Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 659.479C561 660.635 560.886 661.692 560.657 662.648C560.429 663.596 560.082 664.413 559.617 665.1C559.153 665.778 558.574 666.306 557.88 666.684C557.195 667.054 556.387 667.239 555.458 667.239C554.658 667.239 553.93 667.089 553.275 666.788C552.62 666.48 552.06 666.01 551.595 665.377C551.13 664.745 550.772 663.939 550.521 662.96C550.269 661.981 550.144 660.82 550.144 659.479C550.144 658.322 550.258 657.266 550.486 656.309C550.723 655.353 551.069 654.536 551.526 653.857C551.991 653.171 552.57 652.643 553.263 652.273C553.957 651.895 554.764 651.706 555.686 651.706C556.486 651.706 557.214 651.86 557.869 652.169C558.524 652.469 559.084 652.94 559.549 653.58C560.013 654.212 560.371 655.018 560.623 655.997C560.874 656.976 561 658.137 561 659.479ZM558.989 659.548C558.989 659.286 558.977 659.027 558.954 658.773C558.939 658.511 558.92 658.256 558.897 658.01L552.578 662.763C552.692 663.164 552.837 663.534 553.012 663.874C553.187 664.213 553.397 664.506 553.641 664.753C553.892 664.992 554.174 665.181 554.486 665.319C554.806 665.451 555.168 665.516 555.572 665.516C556.09 665.516 556.558 665.389 556.977 665.134C557.404 664.88 557.766 664.502 558.063 664.001C558.36 663.5 558.589 662.879 558.749 662.139C558.909 661.391 558.989 660.527 558.989 659.548ZM552.155 659.409C552.155 659.648 552.155 659.887 552.155 660.126C552.163 660.365 552.178 660.597 552.201 660.82L558.52 656.09C558.406 655.696 558.261 655.338 558.086 655.014C557.911 654.69 557.701 654.413 557.457 654.181C557.214 653.942 556.936 653.757 556.623 653.626C556.311 653.495 555.96 653.429 555.572 653.429C555.054 653.429 554.581 653.557 554.155 653.811C553.736 654.066 553.378 654.447 553.081 654.956C552.783 655.457 552.555 656.082 552.395 656.83C552.235 657.57 552.155 658.43 552.155 659.409Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 684.56C561 685.716 560.886 686.773 560.657 687.729C560.429 688.677 560.082 689.494 559.617 690.181C559.153 690.859 558.574 691.387 557.88 691.765C557.195 692.135 556.387 692.32 555.458 692.32C554.658 692.32 553.93 692.17 553.275 691.869C552.62 691.561 552.06 691.091 551.595 690.458C551.13 689.826 550.772 689.02 550.521 688.041C550.269 687.062 550.144 685.901 550.144 684.56C550.144 683.403 550.258 682.347 550.486 681.39C550.723 680.434 551.069 679.617 551.526 678.938C551.991 678.252 552.57 677.724 553.263 677.354C553.957 676.976 554.764 676.787 555.686 676.787C556.486 676.787 557.214 676.941 557.869 677.25C558.524 677.55 559.084 678.021 559.549 678.661C560.013 679.293 560.371 680.099 560.623 681.078C560.874 682.057 561 683.218 561 684.56ZM558.989 684.629C558.989 684.367 558.977 684.108 558.954 683.854C558.939 683.592 558.92 683.337 558.897 683.091L552.578 687.844C552.692 688.245 552.837 688.615 553.012 688.955C553.187 689.294 553.397 689.587 553.641 689.834C553.892 690.073 554.174 690.262 554.486 690.4C554.806 690.532 555.168 690.597 555.572 690.597C556.09 690.597 556.558 690.47 556.977 690.215C557.404 689.961 557.766 689.583 558.063 689.082C558.36 688.581 558.589 687.96 558.749 687.22C558.909 686.472 558.989 685.608 558.989 684.629ZM552.155 684.49C552.155 684.729 552.155 684.968 552.155 685.207C552.163 685.446 552.178 685.678 552.201 685.901L558.52 681.171C558.406 680.777 558.261 680.419 558.086 680.095C557.911 679.771 557.701 679.494 557.457 679.262C557.214 679.023 556.936 678.838 556.623 678.707C556.311 678.576 555.96 678.51 555.572 678.51C555.054 678.51 554.581 678.638 554.155 678.892C553.736 679.147 553.378 679.528 553.081 680.037C552.783 680.538 552.555 681.163 552.395 681.911C552.235 682.651 552.155 683.511 552.155 684.49Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 717.193H551.149V715.32H554.989V704.285L551.412 706.252L550.681 704.54L555.435 702.007H557.195V715.32H560.531V717.193Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 742.274H551.149V740.401H554.989V729.366L551.412 731.333L550.681 729.621L555.435 727.088H557.195V740.401H560.531V742.274Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 767.355H551.149V765.482H554.989V754.447L551.412 756.414L550.681 754.702L555.435 752.169H557.195V765.482H560.531V767.355Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 784.884C561 786.04 560.886 787.097 560.657 788.053C560.429 789.001 560.082 789.818 559.617 790.505C559.153 791.183 558.574 791.711 557.88 792.089C557.195 792.459 556.387 792.645 555.458 792.645C554.658 792.645 553.93 792.494 553.275 792.193C552.62 791.885 552.06 791.415 551.595 790.782C551.13 790.15 550.772 789.344 550.521 788.365C550.269 787.386 550.144 786.225 550.144 784.884C550.144 783.727 550.258 782.671 550.486 781.714C550.723 780.758 551.069 779.941 551.526 779.262C551.991 778.576 552.57 778.048 553.263 777.678C553.957 777.3 554.764 777.111 555.686 777.111C556.486 777.111 557.214 777.265 557.869 777.574C558.524 777.874 559.084 778.345 559.549 778.985C560.013 779.617 560.371 780.423 560.623 781.402C560.874 782.381 561 783.542 561 784.884ZM558.989 784.953C558.989 784.691 558.977 784.432 558.954 784.178C558.939 783.916 558.92 783.661 558.897 783.415L552.578 788.168C552.692 788.569 552.837 788.939 553.012 789.279C553.187 789.618 553.397 789.911 553.641 790.158C553.892 790.397 554.174 790.586 554.486 790.724C554.806 790.856 555.168 790.921 555.572 790.921C556.09 790.921 556.558 790.794 556.977 790.539C557.404 790.285 557.766 789.907 558.063 789.406C558.36 788.905 558.589 788.284 558.749 787.544C558.909 786.796 558.989 785.932 558.989 784.953ZM552.155 784.814C552.155 785.053 552.155 785.292 552.155 785.531C552.163 785.77 552.178 786.002 552.201 786.225L558.52 781.495C558.406 781.101 558.261 780.743 558.086 780.419C557.911 780.095 557.701 779.818 557.457 779.586C557.214 779.347 556.936 779.162 556.623 779.031C556.311 778.9 555.96 778.834 555.572 778.834C555.054 778.834 554.581 778.962 554.155 779.216C553.736 779.471 553.378 779.852 553.081 780.361C552.783 780.862 552.555 781.487 552.395 782.235C552.235 782.975 552.155 783.835 552.155 784.814Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 817.517H551.149V815.644H554.989V804.609L551.412 806.576L550.681 804.864L555.435 802.331H557.195V815.644H560.531V817.517Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 835.046C561 836.202 560.886 837.259 560.657 838.215C560.429 839.163 560.082 839.98 559.617 840.667C559.153 841.345 558.574 841.874 557.88 842.251C557.195 842.621 556.387 842.807 555.458 842.807C554.658 842.807 553.93 842.656 553.275 842.355C552.62 842.047 552.06 841.577 551.595 840.944C551.13 840.312 550.772 839.506 550.521 838.527C550.269 837.548 550.144 836.387 550.144 835.046C550.144 833.889 550.258 832.833 550.486 831.876C550.723 830.92 551.069 830.103 551.526 829.424C551.991 828.738 552.57 828.21 553.263 827.84C553.957 827.462 554.764 827.273 555.686 827.273C556.486 827.273 557.214 827.427 557.869 827.736C558.524 828.036 559.084 828.507 559.549 829.147C560.013 829.779 560.371 830.585 560.623 831.564C560.874 832.543 561 833.704 561 835.046ZM558.989 835.115C558.989 834.853 558.977 834.594 558.954 834.34C558.939 834.078 558.92 833.823 558.897 833.577L552.578 838.33C552.692 838.731 552.837 839.101 553.012 839.441C553.187 839.78 553.397 840.073 553.641 840.32C553.892 840.559 554.174 840.748 554.486 840.887C554.806 841.018 555.168 841.083 555.572 841.083C556.09 841.083 556.558 840.956 556.977 840.701C557.404 840.447 557.766 840.069 558.063 839.568C558.36 839.067 558.589 838.446 558.749 837.706C558.909 836.958 558.989 836.094 558.989 835.115ZM552.155 834.976C552.155 835.215 552.155 835.454 552.155 835.693C552.163 835.932 552.178 836.164 552.201 836.387L558.52 831.657C558.406 831.263 558.261 830.905 558.086 830.581C557.911 830.257 557.701 829.98 557.457 829.748C557.214 829.509 556.936 829.324 556.623 829.193C556.311 829.062 555.96 828.996 555.572 828.996C555.054 828.996 554.581 829.124 554.155 829.378C553.736 829.633 553.378 830.014 553.081 830.523C552.783 831.024 552.555 831.649 552.395 832.397C552.235 833.137 552.155 833.997 552.155 834.976Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M561 860.127C561 861.283 560.886 862.34 560.657 863.296C560.429 864.244 560.082 865.061 559.617 865.748C559.153 866.426 558.574 866.954 557.88 867.332C557.195 867.702 556.387 867.888 555.458 867.888C554.658 867.888 553.93 867.737 553.275 867.436C552.62 867.128 552.06 866.658 551.595 866.025C551.13 865.393 550.772 864.587 550.521 863.608C550.269 862.629 550.144 861.468 550.144 860.127C550.144 858.97 550.258 857.914 550.486 856.957C550.723 856.001 551.069 855.184 551.526 854.505C551.991 853.819 552.57 853.291 553.263 852.921C553.957 852.543 554.764 852.354 555.686 852.354C556.486 852.354 557.214 852.508 557.869 852.817C558.524 853.117 559.084 853.588 559.549 854.228C560.013 854.86 560.371 855.666 560.623 856.645C560.874 857.624 561 858.785 561 860.127ZM558.989 860.196C558.989 859.934 558.977 859.676 558.954 859.421C558.939 859.159 558.92 858.904 558.897 858.658L552.578 863.411C552.692 863.812 552.837 864.182 553.012 864.522C553.187 864.861 553.397 865.154 553.641 865.401C553.892 865.64 554.174 865.829 554.486 865.968C554.806 866.099 555.168 866.164 555.572 866.164C556.09 866.164 556.558 866.037 556.977 865.782C557.404 865.528 557.766 865.15 558.063 864.649C558.36 864.148 558.589 863.527 558.749 862.787C558.909 862.039 558.989 861.175 558.989 860.196ZM552.155 860.057C552.155 860.296 552.155 860.535 552.155 860.774C552.163 861.013 552.178 861.245 552.201 861.468L558.52 856.738C558.406 856.344 558.261 855.986 558.086 855.662C557.911 855.338 557.701 855.061 557.457 854.829C557.214 854.59 556.936 854.405 556.623 854.274C556.311 854.143 555.96 854.077 555.572 854.077C555.054 854.077 554.581 854.205 554.155 854.459C553.736 854.714 553.378 855.095 553.081 855.604C552.783 856.105 552.555 856.73 552.395 857.478C552.235 858.218 552.155 859.078 552.155 860.057Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M560.531 892.76H551.149V890.887H554.989V879.852L551.412 881.819L550.681 880.107L555.435 877.574H557.195V890.887H560.531V892.76Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M503.38 321.471H493.446V319.487H497.512V307.803L493.724 309.885L492.95 308.073L497.983 305.391H499.847V319.487H503.38V321.471Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 347.945H493.446V345.961H497.512V334.278L493.724 336.36L492.95 334.547L497.983 331.865H499.847V345.961H503.38V347.945Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 366.422C503.876 367.647 503.755 368.766 503.513 369.778C503.271 370.782 502.904 371.648 502.412 372.374C501.92 373.093 501.307 373.652 500.573 374.052C499.847 374.444 498.992 374.64 498.008 374.64C497.161 374.64 496.39 374.481 495.697 374.162C495.003 373.836 494.41 373.338 493.918 372.668C493.426 371.999 493.047 371.146 492.78 370.109C492.514 369.072 492.381 367.843 492.381 366.422C492.381 365.198 492.502 364.079 492.744 363.067C492.994 362.054 493.361 361.189 493.845 360.471C494.337 359.744 494.95 359.185 495.684 358.793C496.418 358.393 497.274 358.193 498.25 358.193C499.097 358.193 499.867 358.356 500.561 358.683C501.254 359.001 501.847 359.499 502.339 360.177C502.832 360.846 503.211 361.699 503.477 362.736C503.743 363.773 503.876 365.002 503.876 366.422ZM501.747 366.496C501.747 366.218 501.734 365.945 501.71 365.675C501.694 365.398 501.674 365.128 501.65 364.867L494.958 369.9C495.079 370.325 495.233 370.717 495.418 371.076C495.604 371.435 495.826 371.746 496.084 372.007C496.35 372.26 496.648 372.46 496.979 372.607C497.318 372.746 497.701 372.815 498.129 372.815C498.677 372.815 499.173 372.68 499.617 372.411C500.069 372.142 500.452 371.742 500.766 371.211C501.081 370.68 501.323 370.023 501.492 369.239C501.662 368.447 501.747 367.533 501.747 366.496ZM494.511 366.349C494.511 366.602 494.511 366.855 494.511 367.108C494.519 367.361 494.535 367.606 494.559 367.843L501.25 362.834C501.129 362.418 500.976 362.038 500.791 361.695C500.605 361.352 500.383 361.058 500.125 360.813C499.867 360.56 499.573 360.364 499.242 360.226C498.911 360.087 498.54 360.017 498.129 360.017C497.58 360.017 497.08 360.152 496.628 360.422C496.185 360.691 495.805 361.095 495.491 361.634C495.176 362.165 494.934 362.826 494.765 363.618C494.595 364.402 494.511 365.312 494.511 366.349Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 392.897C503.876 394.121 503.755 395.24 503.513 396.252C503.271 397.257 502.904 398.122 502.412 398.849C501.92 399.567 501.307 400.126 500.573 400.526C499.847 400.918 498.992 401.114 498.008 401.114C497.161 401.114 496.39 400.955 495.697 400.637C495.003 400.31 494.41 399.812 493.918 399.143C493.426 398.473 493.047 397.62 492.78 396.583C492.514 395.546 492.381 394.317 492.381 392.897C492.381 391.672 492.502 390.554 492.744 389.541C492.994 388.529 493.361 387.663 493.845 386.945C494.337 386.218 494.95 385.659 495.684 385.267C496.418 384.867 497.274 384.667 498.25 384.667C499.097 384.667 499.867 384.83 500.561 385.157C501.254 385.475 501.847 385.973 502.339 386.651C502.832 387.321 503.211 388.174 503.477 389.211C503.743 390.247 503.876 391.476 503.876 392.897ZM501.747 392.97C501.747 392.693 501.734 392.419 501.71 392.15C501.694 391.872 501.674 391.603 501.65 391.342L494.958 396.375C495.079 396.799 495.233 397.191 495.418 397.551C495.604 397.91 495.826 398.22 496.084 398.481C496.35 398.734 496.648 398.934 496.979 399.081C497.318 399.22 497.701 399.29 498.129 399.29C498.677 399.29 499.173 399.155 499.617 398.885C500.069 398.616 500.452 398.216 500.766 397.685C501.081 397.155 501.323 396.497 501.492 395.714C501.662 394.922 501.747 394.007 501.747 392.97ZM494.511 392.823C494.511 393.076 494.511 393.33 494.511 393.583C494.519 393.836 494.535 394.081 494.559 394.317L501.25 389.309C501.129 388.892 500.976 388.513 500.791 388.17C500.605 387.827 500.383 387.533 500.125 387.288C499.867 387.035 499.573 386.839 499.242 386.7C498.911 386.561 498.54 386.492 498.129 386.492C497.58 386.492 497.08 386.627 496.628 386.896C496.185 387.165 495.805 387.57 495.491 388.108C495.176 388.639 494.934 389.3 494.765 390.092C494.595 390.876 494.511 391.786 494.511 392.823Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 419.371C503.876 420.596 503.755 421.714 503.513 422.727C503.271 423.731 502.904 424.596 502.412 425.323C501.92 426.042 501.307 426.601 500.573 427.001C499.847 427.393 498.992 427.589 498.008 427.589C497.161 427.589 496.39 427.429 495.697 427.111C495.003 426.784 494.41 426.286 493.918 425.617C493.426 424.948 493.047 424.094 492.78 423.057C492.514 422.021 492.381 420.792 492.381 419.371C492.381 418.147 492.502 417.028 492.744 416.016C492.994 415.003 493.361 414.138 493.845 413.419C494.337 412.693 494.95 412.133 495.684 411.742C496.418 411.342 497.274 411.142 498.25 411.142C499.097 411.142 499.867 411.305 500.561 411.631C501.254 411.95 501.847 412.448 502.339 413.125C502.832 413.795 503.211 414.648 503.477 415.685C503.743 416.722 503.876 417.951 503.876 419.371ZM501.747 419.445C501.747 419.167 501.734 418.894 501.71 418.624C501.694 418.347 501.674 418.077 501.65 417.816L494.958 422.849C495.079 423.274 495.233 423.666 495.418 424.025C495.604 424.384 495.826 424.694 496.084 424.956C496.35 425.209 496.648 425.409 496.979 425.556C497.318 425.695 497.701 425.764 498.129 425.764C498.677 425.764 499.173 425.629 499.617 425.36C500.069 425.09 500.452 424.69 500.766 424.16C501.081 423.629 501.323 422.972 501.492 422.188C501.662 421.396 501.747 420.482 501.747 419.445ZM494.511 419.298C494.511 419.551 494.511 419.804 494.511 420.057C494.519 420.31 494.535 420.555 494.559 420.792L501.25 415.783C501.129 415.367 500.976 414.987 500.791 414.644C500.605 414.301 500.383 414.007 500.125 413.762C499.867 413.509 499.573 413.313 499.242 413.174C498.911 413.036 498.54 412.966 498.129 412.966C497.58 412.966 497.08 413.101 496.628 413.37C496.185 413.64 495.805 414.044 495.491 414.583C495.176 415.113 494.934 415.775 494.765 416.567C494.595 417.351 494.511 418.261 494.511 419.298Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 453.843H493.446V451.859H497.512V440.175L493.724 442.257L492.95 440.445L497.983 437.763H499.847V451.859H503.38V453.843Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 472.32C503.876 473.545 503.755 474.663 503.513 475.676C503.271 476.68 502.904 477.545 502.412 478.272C501.92 478.99 501.307 479.55 500.573 479.95C499.847 480.342 498.992 480.537 498.008 480.537C497.161 480.537 496.39 480.378 495.697 480.06C495.003 479.733 494.41 479.235 493.918 478.566C493.426 477.896 493.047 477.043 492.78 476.006C492.514 474.969 492.381 473.741 492.381 472.32C492.381 471.095 492.502 469.977 492.744 468.964C492.994 467.952 493.361 467.087 493.845 466.368C494.337 465.642 494.95 465.082 495.684 464.69C496.418 464.29 497.274 464.09 498.25 464.09C499.097 464.09 499.867 464.254 500.561 464.58C501.254 464.899 501.847 465.397 502.339 466.074C502.832 466.744 503.211 467.597 503.477 468.634C503.743 469.671 503.876 470.899 503.876 472.32ZM501.747 472.393C501.747 472.116 501.734 471.842 501.71 471.573C501.694 471.295 501.674 471.026 501.65 470.765L494.958 475.798C495.079 476.223 495.233 476.614 495.418 476.974C495.604 477.333 495.826 477.643 496.084 477.904C496.35 478.158 496.648 478.358 496.979 478.505C497.318 478.643 497.701 478.713 498.129 478.713C498.677 478.713 499.173 478.578 499.617 478.309C500.069 478.039 500.452 477.639 500.766 477.108C501.081 476.578 501.323 475.921 501.492 475.137C501.662 474.345 501.747 473.43 501.747 472.393ZM494.511 472.247C494.511 472.5 494.511 472.753 494.511 473.006C494.519 473.259 494.535 473.504 494.559 473.741L501.25 468.732C501.129 468.315 500.976 467.936 500.791 467.593C500.605 467.25 500.383 466.956 500.125 466.711C499.867 466.458 499.573 466.262 499.242 466.123C498.911 465.984 498.54 465.915 498.129 465.915C497.58 465.915 497.08 466.05 496.628 466.319C496.185 466.589 495.805 466.993 495.491 467.532C495.176 468.062 494.934 468.724 494.765 469.516C494.595 470.299 494.511 471.21 494.511 472.247Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 506.791H493.446V504.807H497.512V493.124L493.724 495.206L492.95 493.394L497.983 490.712H499.847V504.807H503.38V506.791Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 533.266H493.446V531.282H497.512V519.599L493.724 521.681L492.95 519.868L497.983 517.186H499.847V531.282H503.38V533.266Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 551.743C503.876 552.968 503.755 554.086 503.513 555.099C503.271 556.103 502.904 556.968 502.412 557.695C501.92 558.414 501.307 558.973 500.573 559.373C499.847 559.765 498.992 559.961 498.008 559.961C497.161 559.961 496.39 559.801 495.697 559.483C495.003 559.156 494.41 558.658 493.918 557.989C493.426 557.319 493.047 556.466 492.78 555.429C492.514 554.393 492.381 553.164 492.381 551.743C492.381 550.519 492.502 549.4 492.744 548.388C492.994 547.375 493.361 546.51 493.845 545.791C494.337 545.065 494.95 544.505 495.684 544.114C496.418 543.714 497.274 543.514 498.25 543.514C499.097 543.514 499.867 543.677 500.561 544.003C501.254 544.322 501.847 544.82 502.339 545.497C502.832 546.167 503.211 547.02 503.477 548.057C503.743 549.094 503.876 550.323 503.876 551.743ZM501.747 551.817C501.747 551.539 501.734 551.266 501.71 550.996C501.694 550.719 501.674 550.449 501.65 550.188L494.958 555.221C495.079 555.646 495.233 556.038 495.418 556.397C495.604 556.756 495.826 557.066 496.084 557.328C496.35 557.581 496.648 557.781 496.979 557.928C497.318 558.067 497.701 558.136 498.129 558.136C498.677 558.136 499.173 558.001 499.617 557.732C500.069 557.462 500.452 557.062 500.766 556.532C501.081 556.001 501.323 555.344 501.492 554.56C501.662 553.768 501.747 552.854 501.747 551.817ZM494.511 551.67C494.511 551.923 494.511 552.176 494.511 552.429C494.519 552.682 494.535 552.927 494.559 553.164L501.25 548.155C501.129 547.739 500.976 547.359 500.791 547.016C500.605 546.673 500.383 546.379 500.125 546.134C499.867 545.881 499.573 545.685 499.242 545.546C498.911 545.408 498.54 545.338 498.129 545.338C497.58 545.338 497.08 545.473 496.628 545.742C496.185 546.012 495.805 546.416 495.491 546.955C495.176 547.485 494.934 548.147 494.765 548.939C494.595 549.723 494.511 550.633 494.511 551.67Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 578.218C503.876 579.442 503.755 580.561 503.513 581.573C503.271 582.577 502.904 583.443 502.412 584.169C501.92 584.888 501.307 585.447 500.573 585.847C499.847 586.239 498.992 586.435 498.008 586.435C497.161 586.435 496.39 586.276 495.697 585.957C495.003 585.631 494.41 585.133 493.918 584.463C493.426 583.794 493.047 582.941 492.78 581.904C492.514 580.867 492.381 579.638 492.381 578.218C492.381 576.993 492.502 575.874 492.744 574.862C492.994 573.85 493.361 572.984 493.845 572.266C494.337 571.539 494.95 570.98 495.684 570.588C496.418 570.188 497.274 569.988 498.25 569.988C499.097 569.988 499.867 570.151 500.561 570.478C501.254 570.796 501.847 571.294 502.339 571.972C502.832 572.641 503.211 573.495 503.477 574.531C503.743 575.568 503.876 576.797 503.876 578.218ZM501.747 578.291C501.747 578.013 501.734 577.74 501.71 577.471C501.694 577.193 501.674 576.924 501.65 576.662L494.958 581.696C495.079 582.12 495.233 582.512 495.418 582.871C495.604 583.231 495.826 583.541 496.084 583.802C496.35 584.055 496.648 584.255 496.979 584.402C497.318 584.541 497.701 584.61 498.129 584.61C498.677 584.61 499.173 584.476 499.617 584.206C500.069 583.937 500.452 583.537 500.766 583.006C501.081 582.475 501.323 581.818 501.492 581.034C501.662 580.242 501.747 579.328 501.747 578.291ZM494.511 578.144C494.511 578.397 494.511 578.65 494.511 578.903C494.519 579.156 494.535 579.401 494.559 579.638L501.25 574.629C501.129 574.213 500.976 573.833 500.791 573.49C500.605 573.148 500.383 572.854 500.125 572.609C499.867 572.356 499.573 572.16 499.242 572.021C498.911 571.882 498.54 571.813 498.129 571.813C497.58 571.813 497.08 571.947 496.628 572.217C496.185 572.486 495.805 572.89 495.491 573.429C495.176 573.96 494.934 574.621 494.765 575.413C494.595 576.197 494.511 577.107 494.511 578.144Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 604.692C503.876 605.917 503.755 607.035 503.513 608.048C503.271 609.052 502.904 609.917 502.412 610.644C501.92 611.362 501.307 611.922 500.573 612.322C499.847 612.714 498.992 612.909 498.008 612.909C497.161 612.909 496.39 612.75 495.697 612.432C495.003 612.105 494.41 611.607 493.918 610.938C493.426 610.268 493.047 609.415 492.78 608.378C492.514 607.341 492.381 606.113 492.381 604.692C492.381 603.467 492.502 602.349 492.744 601.336C492.994 600.324 493.361 599.459 493.845 598.74C494.337 598.014 494.95 597.454 495.684 597.062C496.418 596.662 497.274 596.462 498.25 596.462C499.097 596.462 499.867 596.626 500.561 596.952C501.254 597.271 501.847 597.769 502.339 598.446C502.832 599.116 503.211 599.969 503.477 601.006C503.743 602.043 503.876 603.271 503.876 604.692ZM501.747 604.765C501.747 604.488 501.734 604.214 501.71 603.945C501.694 603.667 501.674 603.398 501.65 603.137L494.958 608.17C495.079 608.595 495.233 608.986 495.418 609.346C495.604 609.705 495.826 610.015 496.084 610.276C496.35 610.53 496.648 610.73 496.979 610.877C497.318 611.015 497.701 611.085 498.129 611.085C498.677 611.085 499.173 610.95 499.617 610.681C500.069 610.411 500.452 610.011 500.766 609.48C501.081 608.95 501.323 608.292 501.492 607.509C501.662 606.717 501.747 605.802 501.747 604.765ZM494.511 604.618C494.511 604.872 494.511 605.125 494.511 605.378C494.519 605.631 494.535 605.876 494.559 606.113L501.25 601.104C501.129 600.687 500.976 600.308 500.791 599.965C500.605 599.622 500.383 599.328 500.125 599.083C499.867 598.83 499.573 598.634 499.242 598.495C498.911 598.356 498.54 598.287 498.129 598.287C497.58 598.287 497.08 598.422 496.628 598.691C496.185 598.961 495.805 599.365 495.491 599.904C495.176 600.434 494.934 601.096 494.765 601.888C494.595 602.671 494.511 603.582 494.511 604.618Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 639.163H493.446V637.179H497.512V625.496L493.724 627.578L492.95 625.766L497.983 623.084H499.847V637.179H503.38V639.163Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 665.638H493.446V663.654H497.512V651.971L493.724 654.053L492.95 652.24L497.983 649.558H499.847V663.654H503.38V665.638Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 692.112H493.446V690.128H497.512V678.445L493.724 680.527L492.95 678.714L497.983 676.032H499.847V690.128H503.38V692.112Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 710.59C503.876 711.814 503.755 712.933 503.513 713.945C503.271 714.949 502.904 715.815 502.412 716.541C501.92 717.26 501.307 717.819 500.573 718.219C499.847 718.611 498.992 718.807 498.008 718.807C497.161 718.807 496.39 718.648 495.697 718.329C495.003 718.003 494.41 717.505 493.918 716.835C493.426 716.166 493.047 715.313 492.78 714.276C492.514 713.239 492.381 712.01 492.381 710.59C492.381 709.365 492.502 708.246 492.744 707.234C492.994 706.222 493.361 705.356 493.845 704.638C494.337 703.911 494.95 703.352 495.684 702.96C496.418 702.56 497.274 702.36 498.25 702.36C499.097 702.36 499.867 702.523 500.561 702.85C501.254 703.168 501.847 703.666 502.339 704.344C502.832 705.013 503.211 705.866 503.477 706.903C503.743 707.94 503.876 709.169 503.876 710.59ZM501.747 710.663C501.747 710.385 501.734 710.112 501.71 709.843C501.694 709.565 501.674 709.296 501.65 709.034L494.958 714.068C495.079 714.492 495.233 714.884 495.418 715.243C495.604 715.603 495.826 715.913 496.084 716.174C496.35 716.427 496.648 716.627 496.979 716.774C497.318 716.913 497.701 716.982 498.129 716.982C498.677 716.982 499.173 716.848 499.617 716.578C500.069 716.309 500.452 715.909 500.766 715.378C501.081 714.847 501.323 714.19 501.492 713.406C501.662 712.614 501.747 711.7 501.747 710.663ZM494.511 710.516C494.511 710.769 494.511 711.022 494.511 711.275C494.519 711.528 494.535 711.773 494.559 712.01L501.25 707.001C501.129 706.585 500.976 706.205 500.791 705.862C500.605 705.52 500.383 705.226 500.125 704.981C499.867 704.728 499.573 704.532 499.242 704.393C498.911 704.254 498.54 704.185 498.129 704.185C497.58 704.185 497.08 704.319 496.628 704.589C496.185 704.858 495.805 705.262 495.491 705.801C495.176 706.332 494.934 706.993 494.765 707.785C494.595 708.569 494.511 709.479 494.511 710.516Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 745.061H493.446V743.077H497.512V731.394L493.724 733.476L492.95 731.663L497.983 728.981H499.847V743.077H503.38V745.061Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 763.538C503.876 764.763 503.755 765.882 503.513 766.894C503.271 767.898 502.904 768.764 502.412 769.49C501.92 770.209 501.307 770.768 500.573 771.168C499.847 771.56 498.992 771.756 498.008 771.756C497.161 771.756 496.39 771.597 495.697 771.278C495.003 770.952 494.41 770.454 493.918 769.784C493.426 769.115 493.047 768.261 492.78 767.225C492.514 766.188 492.381 764.959 492.381 763.538C492.381 762.314 492.502 761.195 492.744 760.183C492.994 759.17 493.361 758.305 493.845 757.587C494.337 756.86 494.95 756.301 495.684 755.909C496.418 755.509 497.274 755.309 498.25 755.309C499.097 755.309 499.867 755.472 500.561 755.799C501.254 756.117 501.847 756.615 502.339 757.293C502.832 757.962 503.211 758.815 503.477 759.852C503.743 760.889 503.876 762.118 503.876 763.538ZM501.747 763.612C501.747 763.334 501.734 763.061 501.71 762.791C501.694 762.514 501.674 762.244 501.65 761.983L494.958 767.016C495.079 767.441 495.233 767.833 495.418 768.192C495.604 768.551 495.826 768.862 496.084 769.123C496.35 769.376 496.648 769.576 496.979 769.723C497.318 769.862 497.701 769.931 498.129 769.931C498.677 769.931 499.173 769.796 499.617 769.527C500.069 769.258 500.452 768.857 500.766 768.327C501.081 767.796 501.323 767.139 501.492 766.355C501.662 765.563 501.747 764.649 501.747 763.612ZM494.511 763.465C494.511 763.718 494.511 763.971 494.511 764.224C494.519 764.477 494.535 764.722 494.559 764.959L501.25 759.95C501.129 759.534 500.976 759.154 500.791 758.811C500.605 758.468 500.383 758.174 500.125 757.929C499.867 757.676 499.573 757.48 499.242 757.342C498.911 757.203 498.54 757.133 498.129 757.133C497.58 757.133 497.08 757.268 496.628 757.538C496.185 757.807 495.805 758.211 495.491 758.75C495.176 759.281 494.934 759.942 494.765 760.734C494.595 761.518 494.511 762.428 494.511 763.465Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.876 790.013C503.876 791.237 503.755 792.356 503.513 793.368C503.271 794.373 502.904 795.238 502.412 795.965C501.92 796.683 501.307 797.242 500.573 797.642C499.847 798.034 498.992 798.23 498.008 798.23C497.161 798.23 496.39 798.071 495.697 797.753C495.003 797.426 494.41 796.928 493.918 796.259C493.426 795.589 493.047 794.736 492.78 793.699C492.514 792.662 492.381 791.433 492.381 790.013C492.381 788.788 492.502 787.67 492.744 786.657C492.994 785.645 493.361 784.779 493.845 784.061C494.337 783.334 494.95 782.775 495.684 782.383C496.418 781.983 497.274 781.783 498.25 781.783C499.097 781.783 499.867 781.946 500.561 782.273C501.254 782.591 501.847 783.089 502.339 783.767C502.832 784.436 503.211 785.29 503.477 786.327C503.743 787.363 503.876 788.592 503.876 790.013ZM501.747 790.086C501.747 789.809 501.734 789.535 501.71 789.266C501.694 788.988 501.674 788.719 501.65 788.457L494.958 793.491C495.079 793.915 495.233 794.307 495.418 794.666C495.604 795.026 495.826 795.336 496.084 795.597C496.35 795.85 496.648 796.05 496.979 796.197C497.318 796.336 497.701 796.405 498.129 796.405C498.677 796.405 499.173 796.271 499.617 796.001C500.069 795.732 500.452 795.332 500.766 794.801C501.081 794.27 501.323 793.613 501.492 792.829C501.662 792.038 501.747 791.123 501.747 790.086ZM494.511 789.939C494.511 790.192 494.511 790.445 494.511 790.699C494.519 790.952 494.535 791.197 494.559 791.433L501.25 786.424C501.129 786.008 500.976 785.628 500.791 785.286C500.605 784.943 500.383 784.649 500.125 784.404C499.867 784.151 499.573 783.955 499.242 783.816C498.911 783.677 498.54 783.608 498.129 783.608C497.58 783.608 497.08 783.743 496.628 784.012C496.185 784.281 495.805 784.685 495.491 785.224C495.176 785.755 494.934 786.416 494.765 787.208C494.595 787.992 494.511 788.902 494.511 789.939Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M503.38 824.484H493.446V822.5H497.512V810.817L493.724 812.899L492.95 811.086L497.983 808.404H499.847V822.5H503.38V824.484Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 549.986H314.474V548.003H318.54V536.319L314.753 538.401L313.978 536.589L319.012 533.907H320.875V548.003H324.408V549.986Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 576.461H314.474V574.477H318.54V562.794L314.753 564.876L313.978 563.063L319.012 560.381H320.875V574.477H324.408V576.461Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 594.938C324.905 596.163 324.784 597.281 324.542 598.294C324.3 599.298 323.932 600.163 323.44 600.89C322.948 601.609 322.335 602.168 321.601 602.568C320.875 602.96 320.02 603.156 319.036 603.156C318.189 603.156 317.419 602.996 316.725 602.678C316.031 602.352 315.438 601.853 314.946 601.184C314.454 600.515 314.075 599.661 313.809 598.624C313.543 597.588 313.41 596.359 313.41 594.938C313.41 593.714 313.531 592.595 313.773 591.583C314.023 590.57 314.39 589.705 314.874 588.986C315.366 588.26 315.979 587.701 316.713 587.309C317.447 586.909 318.302 586.709 319.278 586.709C320.125 586.709 320.895 586.872 321.589 587.198C322.283 587.517 322.876 588.015 323.368 588.692C323.86 589.362 324.239 590.215 324.505 591.252C324.771 592.289 324.905 593.518 324.905 594.938ZM322.775 595.012C322.775 594.734 322.763 594.461 322.739 594.191C322.723 593.914 322.702 593.644 322.678 593.383L315.987 598.416C316.108 598.841 316.261 599.233 316.447 599.592C316.632 599.951 316.854 600.261 317.112 600.523C317.378 600.776 317.677 600.976 318.008 601.123C318.346 601.262 318.73 601.331 319.157 601.331C319.706 601.331 320.202 601.196 320.645 600.927C321.097 600.657 321.48 600.257 321.795 599.727C322.109 599.196 322.351 598.539 322.521 597.755C322.69 596.963 322.775 596.049 322.775 595.012ZM315.539 594.865C315.539 595.118 315.539 595.371 315.539 595.624C315.547 595.877 315.563 596.122 315.588 596.359L322.279 591.35C322.158 590.934 322.005 590.554 321.819 590.211C321.634 589.868 321.412 589.574 321.154 589.329C320.895 589.076 320.601 588.88 320.27 588.741C319.94 588.603 319.568 588.533 319.157 588.533C318.609 588.533 318.108 588.668 317.657 588.937C317.213 589.207 316.834 589.611 316.519 590.15C316.205 590.681 315.963 591.342 315.793 592.134C315.624 592.918 315.539 593.828 315.539 594.865Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 621.413C324.905 622.637 324.784 623.756 324.542 624.768C324.3 625.772 323.932 626.638 323.44 627.364C322.948 628.083 322.335 628.642 321.601 629.042C320.875 629.434 320.02 629.63 319.036 629.63C318.189 629.63 317.419 629.471 316.725 629.152C316.031 628.826 315.438 628.328 314.946 627.658C314.454 626.989 314.075 626.136 313.809 625.099C313.543 624.062 313.41 622.833 313.41 621.413C313.41 620.188 313.531 619.069 313.773 618.057C314.023 617.045 314.39 616.179 314.874 615.461C315.366 614.734 315.979 614.175 316.713 613.783C317.447 613.383 318.302 613.183 319.278 613.183C320.125 613.183 320.895 613.346 321.589 613.673C322.283 613.991 322.876 614.489 323.368 615.167C323.86 615.836 324.239 616.69 324.505 617.726C324.771 618.763 324.905 619.992 324.905 621.413ZM322.775 621.486C322.775 621.209 322.763 620.935 322.739 620.666C322.723 620.388 322.702 620.119 322.678 619.857L315.987 624.891C316.108 625.315 316.261 625.707 316.447 626.066C316.632 626.426 316.854 626.736 317.112 626.997C317.378 627.25 317.677 627.45 318.008 627.597C318.346 627.736 318.73 627.805 319.157 627.805C319.706 627.805 320.202 627.671 320.645 627.401C321.097 627.132 321.48 626.732 321.795 626.201C322.109 625.67 322.351 625.013 322.521 624.229C322.69 623.437 322.775 622.523 322.775 621.486ZM315.539 621.339C315.539 621.592 315.539 621.845 315.539 622.098C315.547 622.352 315.563 622.596 315.588 622.833L322.279 617.824C322.158 617.408 322.005 617.028 321.819 616.685C321.634 616.343 321.412 616.049 321.154 615.804C320.895 615.551 320.601 615.355 320.27 615.216C319.94 615.077 319.568 615.008 319.157 615.008C318.609 615.008 318.108 615.142 317.657 615.412C317.213 615.681 316.834 616.085 316.519 616.624C316.205 617.155 315.963 617.816 315.793 618.608C315.624 619.392 315.539 620.302 315.539 621.339Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 647.887C324.905 649.112 324.784 650.23 324.542 651.243C324.3 652.247 323.932 653.112 323.44 653.839C322.948 654.557 322.335 655.117 321.601 655.517C320.875 655.909 320.02 656.104 319.036 656.104C318.189 656.104 317.419 655.945 316.725 655.627C316.031 655.3 315.438 654.802 314.946 654.133C314.454 653.463 314.075 652.61 313.809 651.573C313.543 650.536 313.41 649.308 313.41 647.887C313.41 646.662 313.531 645.544 313.773 644.531C314.023 643.519 314.39 642.654 314.874 641.935C315.366 641.209 315.979 640.649 316.713 640.257C317.447 639.857 318.302 639.657 319.278 639.657C320.125 639.657 320.895 639.821 321.589 640.147C322.283 640.466 322.876 640.964 323.368 641.641C323.86 642.311 324.239 643.164 324.505 644.201C324.771 645.238 324.905 646.466 324.905 647.887ZM322.775 647.961C322.775 647.683 322.763 647.409 322.739 647.14C322.723 646.862 322.702 646.593 322.678 646.332L315.987 651.365C316.108 651.79 316.261 652.182 316.447 652.541C316.632 652.9 316.854 653.21 317.112 653.471C317.378 653.725 317.677 653.925 318.008 654.072C318.346 654.21 318.73 654.28 319.157 654.28C319.706 654.28 320.202 654.145 320.645 653.876C321.097 653.606 321.48 653.206 321.795 652.675C322.109 652.145 322.351 651.488 322.521 650.704C322.69 649.912 322.775 648.997 322.775 647.961ZM315.539 647.814C315.539 648.067 315.539 648.32 315.539 648.573C315.547 648.826 315.563 649.071 315.588 649.308L322.279 644.299C322.158 643.882 322.005 643.503 321.819 643.16C321.634 642.817 321.412 642.523 321.154 642.278C320.895 642.025 320.601 641.829 320.27 641.69C319.94 641.551 319.568 641.482 319.157 641.482C318.609 641.482 318.108 641.617 317.657 641.886C317.213 642.156 316.834 642.56 316.519 643.099C316.205 643.629 315.963 644.291 315.793 645.083C315.624 645.866 315.539 646.777 315.539 647.814Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 682.358H314.474V680.374H318.54V668.691L314.753 670.773L313.978 668.961L319.012 666.279H320.875V680.374H324.408V682.358Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 700.836C324.905 702.06 324.784 703.179 324.542 704.191C324.3 705.196 323.932 706.061 323.44 706.788C322.948 707.506 322.335 708.065 321.601 708.465C320.875 708.857 320.02 709.053 319.036 709.053C318.189 709.053 317.419 708.894 316.725 708.576C316.031 708.249 315.438 707.751 314.946 707.082C314.454 706.412 314.075 705.559 313.809 704.522C313.543 703.485 313.41 702.256 313.41 700.836C313.41 699.611 313.531 698.493 313.773 697.48C314.023 696.468 314.39 695.602 314.874 694.884C315.366 694.157 315.979 693.598 316.713 693.206C317.447 692.806 318.302 692.606 319.278 692.606C320.125 692.606 320.895 692.769 321.589 693.096C322.283 693.414 322.876 693.912 323.368 694.59C323.86 695.26 324.239 696.113 324.505 697.15C324.771 698.186 324.905 699.415 324.905 700.836ZM322.775 700.909C322.775 700.632 322.763 700.358 322.739 700.089C322.723 699.811 322.702 699.542 322.678 699.281L315.987 704.314C316.108 704.738 316.261 705.13 316.447 705.49C316.632 705.849 316.854 706.159 317.112 706.42C317.378 706.673 317.677 706.873 318.008 707.02C318.346 707.159 318.73 707.229 319.157 707.229C319.706 707.229 320.202 707.094 320.645 706.824C321.097 706.555 321.48 706.155 321.795 705.624C322.109 705.094 322.351 704.436 322.521 703.653C322.69 702.861 322.775 701.946 322.775 700.909ZM315.539 700.762C315.539 701.015 315.539 701.269 315.539 701.522C315.547 701.775 315.563 702.02 315.588 702.256L322.279 697.248C322.158 696.831 322.005 696.452 321.819 696.109C321.634 695.766 321.412 695.472 321.154 695.227C320.895 694.974 320.601 694.778 320.27 694.639C319.94 694.5 319.568 694.431 319.157 694.431C318.609 694.431 318.108 694.566 317.657 694.835C317.213 695.104 316.834 695.509 316.519 696.047C316.205 696.578 315.963 697.239 315.793 698.031C315.624 698.815 315.539 699.725 315.539 700.762Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 735.307H314.474V733.323H318.54V721.64L314.753 723.722L313.978 721.909L319.012 719.227H320.875V733.323H324.408V735.307Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 761.782H314.474V759.798H318.54V748.114L314.753 750.196L313.978 748.384L319.012 745.702H320.875V759.798H324.408V761.782Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 780.259C324.905 781.484 324.784 782.602 324.542 783.615C324.3 784.619 323.932 785.484 323.44 786.211C322.948 786.929 322.335 787.489 321.601 787.889C320.875 788.281 320.02 788.476 319.036 788.476C318.189 788.476 317.419 788.317 316.725 787.999C316.031 787.672 315.438 787.174 314.946 786.505C314.454 785.835 314.075 784.982 313.809 783.945C313.543 782.908 313.41 781.68 313.41 780.259C313.41 779.034 313.531 777.916 313.773 776.903C314.023 775.891 314.39 775.026 314.874 774.307C315.366 773.581 315.979 773.021 316.713 772.629C317.447 772.229 318.302 772.029 319.278 772.029C320.125 772.029 320.895 772.193 321.589 772.519C322.283 772.838 322.876 773.336 323.368 774.013C323.86 774.683 324.239 775.536 324.505 776.573C324.771 777.61 324.905 778.838 324.905 780.259ZM322.775 780.332C322.775 780.055 322.763 779.781 322.739 779.512C322.723 779.234 322.702 778.965 322.678 778.704L315.987 783.737C316.108 784.162 316.261 784.553 316.447 784.913C316.632 785.272 316.854 785.582 317.112 785.843C317.378 786.097 317.677 786.297 318.008 786.444C318.346 786.582 318.73 786.652 319.157 786.652C319.706 786.652 320.202 786.517 320.645 786.248C321.097 785.978 321.48 785.578 321.795 785.047C322.109 784.517 322.351 783.86 322.521 783.076C322.69 782.284 322.775 781.369 322.775 780.332ZM315.539 780.186C315.539 780.439 315.539 780.692 315.539 780.945C315.547 781.198 315.563 781.443 315.588 781.68L322.279 776.671C322.158 776.254 322.005 775.875 321.819 775.532C321.634 775.189 321.412 774.895 321.154 774.65C320.895 774.397 320.601 774.201 320.27 774.062C319.94 773.923 319.568 773.854 319.157 773.854C318.609 773.854 318.108 773.989 317.657 774.258C317.213 774.528 316.834 774.932 316.519 775.471C316.205 776.001 315.963 776.663 315.793 777.455C315.624 778.238 315.539 779.149 315.539 780.186Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 806.733C324.905 807.958 324.784 809.077 324.542 810.089C324.3 811.093 323.932 811.959 323.44 812.685C322.948 813.404 322.335 813.963 321.601 814.363C320.875 814.755 320.02 814.951 319.036 814.951C318.189 814.951 317.419 814.792 316.725 814.473C316.031 814.147 315.438 813.649 314.946 812.979C314.454 812.31 314.075 811.457 313.809 810.42C313.543 809.383 313.41 808.154 313.41 806.733C313.41 805.509 313.531 804.39 313.773 803.378C314.023 802.365 314.39 801.5 314.874 800.782C315.366 800.055 315.979 799.496 316.713 799.104C317.447 798.704 318.302 798.504 319.278 798.504C320.125 798.504 320.895 798.667 321.589 798.994C322.283 799.312 322.876 799.81 323.368 800.488C323.86 801.157 324.239 802.01 324.505 803.047C324.771 804.084 324.905 805.313 324.905 806.733ZM322.775 806.807C322.775 806.529 322.763 806.256 322.739 805.986C322.723 805.709 322.702 805.439 322.678 805.178L315.987 810.211C316.108 810.636 316.261 811.028 316.447 811.387C316.632 811.746 316.854 812.057 317.112 812.318C317.378 812.571 317.677 812.771 318.008 812.918C318.346 813.057 318.73 813.126 319.157 813.126C319.706 813.126 320.202 812.991 320.645 812.722C321.097 812.453 321.48 812.053 321.795 811.522C322.109 810.991 322.351 810.334 322.521 809.55C322.69 808.758 322.775 807.844 322.775 806.807ZM315.539 806.66C315.539 806.913 315.539 807.166 315.539 807.419C315.547 807.672 315.563 807.917 315.588 808.154L322.279 803.145C322.158 802.729 322.005 802.349 321.819 802.006C321.634 801.663 321.412 801.369 321.154 801.124C320.895 800.871 320.601 800.675 320.27 800.537C319.94 800.398 319.568 800.328 319.157 800.328C318.609 800.328 318.108 800.463 317.657 800.733C317.213 801.002 316.834 801.406 316.519 801.945C316.205 802.476 315.963 803.137 315.793 803.929C315.624 804.713 315.539 805.623 315.539 806.66Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 833.208C324.905 834.432 324.784 835.551 324.542 836.563C324.3 837.568 323.932 838.433 323.44 839.16C322.948 839.878 322.335 840.437 321.601 840.837C320.875 841.229 320.02 841.425 319.036 841.425C318.189 841.425 317.419 841.266 316.725 840.948C316.031 840.621 315.438 840.123 314.946 839.454C314.454 838.784 314.075 837.931 313.809 836.894C313.543 835.857 313.41 834.628 313.41 833.208C313.41 831.983 313.531 830.865 313.773 829.852C314.023 828.84 314.39 827.974 314.874 827.256C315.366 826.529 315.979 825.97 316.713 825.578C317.447 825.178 318.302 824.978 319.278 824.978C320.125 824.978 320.895 825.141 321.589 825.468C322.283 825.786 322.876 826.284 323.368 826.962C323.86 827.632 324.239 828.485 324.505 829.522C324.771 830.558 324.905 831.787 324.905 833.208ZM322.775 833.281C322.775 833.004 322.763 832.73 322.739 832.461C322.723 832.183 322.702 831.914 322.678 831.652L315.987 836.686C316.108 837.11 316.261 837.502 316.447 837.862C316.632 838.221 316.854 838.531 317.112 838.792C317.378 839.045 317.677 839.245 318.008 839.392C318.346 839.531 318.73 839.601 319.157 839.601C319.706 839.601 320.202 839.466 320.645 839.196C321.097 838.927 321.48 838.527 321.795 837.996C322.109 837.466 322.351 836.808 322.521 836.025C322.69 835.233 322.775 834.318 322.775 833.281ZM315.539 833.134C315.539 833.387 315.539 833.641 315.539 833.894C315.547 834.147 315.563 834.392 315.588 834.628L322.279 829.62C322.158 829.203 322.005 828.824 321.819 828.481C321.634 828.138 321.412 827.844 321.154 827.599C320.895 827.346 320.601 827.15 320.27 827.011C319.94 826.872 319.568 826.803 319.157 826.803C318.609 826.803 318.108 826.938 317.657 827.207C317.213 827.476 316.834 827.881 316.519 828.419C316.205 828.95 315.963 829.611 315.793 830.403C315.624 831.187 315.539 832.097 315.539 833.134Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 867.679H314.474V865.695H318.54V854.012L314.753 856.094L313.978 854.281L319.012 851.599H320.875V865.695H324.408V867.679Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 894.154H314.474V892.17H318.54V880.486L314.753 882.568L313.978 880.756L319.012 878.074H320.875V892.17H324.408V894.154Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 920.628H314.474V918.644H318.54V906.961L314.753 909.043L313.978 907.23L319.012 904.548H320.875V918.644H324.408V920.628Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 939.105C324.905 940.33 324.784 941.449 324.542 942.461C324.3 943.465 323.932 944.331 323.44 945.057C322.948 945.776 322.335 946.335 321.601 946.735C320.875 947.127 320.02 947.323 319.036 947.323C318.189 947.323 317.419 947.164 316.725 946.845C316.031 946.519 315.438 946.021 314.946 945.351C314.454 944.682 314.075 943.828 313.809 942.792C313.543 941.755 313.41 940.526 313.41 939.105C313.41 937.881 313.531 936.762 313.773 935.75C314.023 934.737 314.39 933.872 314.874 933.154C315.366 932.427 315.979 931.868 316.713 931.476C317.447 931.076 318.302 930.876 319.278 930.876C320.125 930.876 320.895 931.039 321.589 931.366C322.283 931.684 322.876 932.182 323.368 932.86C323.86 933.529 324.239 934.382 324.505 935.419C324.771 936.456 324.905 937.685 324.905 939.105ZM322.775 939.179C322.775 938.901 322.763 938.628 322.739 938.358C322.723 938.081 322.702 937.811 322.678 937.55L315.987 942.583C316.108 943.008 316.261 943.4 316.447 943.759C316.632 944.118 316.854 944.429 317.112 944.69C317.378 944.943 317.677 945.143 318.008 945.29C318.346 945.429 318.73 945.498 319.157 945.498C319.706 945.498 320.202 945.363 320.645 945.094C321.097 944.825 321.48 944.424 321.795 943.894C322.109 943.363 322.351 942.706 322.521 941.922C322.69 941.13 322.775 940.216 322.775 939.179ZM315.539 939.032C315.539 939.285 315.539 939.538 315.539 939.791C315.547 940.044 315.563 940.289 315.588 940.526L322.279 935.517C322.158 935.101 322.005 934.721 321.819 934.378C321.634 934.035 321.412 933.741 321.154 933.496C320.895 933.243 320.601 933.047 320.27 932.909C319.94 932.77 319.568 932.7 319.157 932.7C318.609 932.7 318.108 932.835 317.657 933.105C317.213 933.374 316.834 933.778 316.519 934.317C316.205 934.848 315.963 935.509 315.793 936.301C315.624 937.085 315.539 937.995 315.539 939.032Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 973.577H314.474V971.593H318.54V959.91L314.753 961.992L313.978 960.179L319.012 957.497H320.875V971.593H324.408V973.577Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 992.054C324.905 993.279 324.784 994.397 324.542 995.41C324.3 996.414 323.932 997.279 323.44 998.006C322.948 998.724 322.335 999.284 321.601 999.684C320.875 1000.08 320.02 1000.27 319.036 1000.27C318.189 1000.27 317.419 1000.11 316.725 999.794C316.031 999.467 315.438 998.969 314.946 998.3C314.454 997.63 314.075 996.777 313.809 995.74C313.543 994.704 313.41 993.475 313.41 992.054C313.41 990.83 313.531 989.711 313.773 988.699C314.023 987.686 314.39 986.821 314.874 986.102C315.366 985.376 315.979 984.816 316.713 984.425C317.447 984.025 318.302 983.824 319.278 983.824C320.125 983.824 320.895 983.988 321.589 984.314C322.283 984.633 322.876 985.131 323.368 985.808C323.86 986.478 324.239 987.331 324.505 988.368C324.771 989.405 324.905 990.634 324.905 992.054ZM322.775 992.128C322.775 991.85 322.763 991.577 322.739 991.307C322.723 991.03 322.702 990.76 322.678 990.499L315.987 995.532C316.108 995.957 316.261 996.349 316.447 996.708C316.632 997.067 316.854 997.377 317.112 997.639C317.378 997.892 317.677 998.092 318.008 998.239C318.346 998.378 318.73 998.447 319.157 998.447C319.706 998.447 320.202 998.312 320.645 998.043C321.097 997.773 321.48 997.373 321.795 996.843C322.109 996.312 322.351 995.655 322.521 994.871C322.69 994.079 322.775 993.165 322.775 992.128ZM315.539 991.981C315.539 992.234 315.539 992.487 315.539 992.74C315.547 992.993 315.563 993.238 315.588 993.475L322.279 988.466C322.158 988.05 322.005 987.67 321.819 987.327C321.634 986.984 321.412 986.69 321.154 986.445C320.895 986.192 320.601 985.996 320.27 985.857C319.94 985.719 319.568 985.649 319.157 985.649C318.609 985.649 318.108 985.784 317.657 986.053C317.213 986.323 316.834 986.727 316.519 987.266C316.205 987.796 315.963 988.458 315.793 989.25C315.624 990.033 315.539 990.944 315.539 991.981Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.905 1018.53C324.905 1019.75 324.784 1020.87 324.542 1021.88C324.3 1022.89 323.932 1023.75 323.44 1024.48C322.948 1025.2 322.335 1025.76 321.601 1026.16C320.875 1026.55 320.02 1026.75 319.036 1026.75C318.189 1026.75 317.419 1026.59 316.725 1026.27C316.031 1025.94 315.438 1025.44 314.946 1024.77C314.454 1024.1 314.075 1023.25 313.809 1022.21C313.543 1021.18 313.41 1019.95 313.41 1018.53C313.41 1017.3 313.531 1016.19 313.773 1015.17C314.023 1014.16 314.39 1013.3 314.874 1012.58C315.366 1011.85 315.979 1011.29 316.713 1010.9C317.447 1010.5 318.302 1010.3 319.278 1010.3C320.125 1010.3 320.895 1010.46 321.589 1010.79C322.283 1011.11 322.876 1011.61 323.368 1012.28C323.86 1012.95 324.239 1013.81 324.505 1014.84C324.771 1015.88 324.905 1017.11 324.905 1018.53ZM322.775 1018.6C322.775 1018.32 322.763 1018.05 322.739 1017.78C322.723 1017.5 322.702 1017.23 322.678 1016.97L315.987 1022.01C316.108 1022.43 316.261 1022.82 316.447 1023.18C316.632 1023.54 316.854 1023.85 317.112 1024.11C317.378 1024.37 317.677 1024.57 318.008 1024.71C318.346 1024.85 318.73 1024.92 319.157 1024.92C319.706 1024.92 320.202 1024.79 320.645 1024.52C321.097 1024.25 321.48 1023.85 321.795 1023.32C322.109 1022.79 322.351 1022.13 322.521 1021.35C322.69 1020.55 322.775 1019.64 322.775 1018.6ZM315.539 1018.46C315.539 1018.71 315.539 1018.96 315.539 1019.21C315.547 1019.47 315.563 1019.71 315.588 1019.95L322.279 1014.94C322.158 1014.52 322.005 1014.14 321.819 1013.8C321.634 1013.46 321.412 1013.16 321.154 1012.92C320.895 1012.67 320.601 1012.47 320.27 1012.33C319.94 1012.19 319.568 1012.12 319.157 1012.12C318.609 1012.12 318.108 1012.26 317.657 1012.53C317.213 1012.8 316.834 1013.2 316.519 1013.74C316.205 1014.27 315.963 1014.93 315.793 1015.72C315.624 1016.51 315.539 1017.42 315.539 1018.46Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M324.408 1053H314.474V1051.02H318.54V1039.33L314.753 1041.41L313.978 1039.6L319.012 1036.92H320.875V1051.02H324.408V1053Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M530.896 188.402H520.41V186.308H524.701V173.976L520.703 176.173L519.886 174.26L525.199 171.429H527.166V186.308H530.896V188.402Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 216.27H520.41V214.176H524.701V201.843L520.703 204.041L519.886 202.128L525.199 199.297H527.166V214.176H530.896V216.27Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 235.696C531.419 236.989 531.292 238.17 531.036 239.238C530.781 240.298 530.393 241.212 529.874 241.979C529.354 242.737 528.707 243.328 527.933 243.75C527.166 244.163 526.264 244.37 525.225 244.37C524.331 244.37 523.518 244.202 522.785 243.866C522.053 243.521 521.427 242.996 520.908 242.289C520.388 241.582 519.988 240.682 519.707 239.587C519.426 238.493 519.286 237.196 519.286 235.696C519.286 234.404 519.413 233.223 519.669 232.154C519.933 231.086 520.32 230.172 520.831 229.414C521.351 228.647 521.998 228.056 522.773 227.643C523.547 227.221 524.45 227.009 525.48 227.009C526.374 227.009 527.187 227.182 527.92 227.526C528.652 227.863 529.278 228.388 529.797 229.104C530.317 229.81 530.717 230.711 530.998 231.805C531.279 232.9 531.419 234.197 531.419 235.696ZM529.171 235.774C529.171 235.481 529.159 235.192 529.133 234.908C529.116 234.615 529.095 234.33 529.069 234.055L522.006 239.368C522.134 239.816 522.296 240.229 522.492 240.609C522.687 240.988 522.922 241.315 523.194 241.591C523.475 241.858 523.79 242.069 524.139 242.224C524.497 242.371 524.901 242.444 525.353 242.444C525.932 242.444 526.455 242.302 526.924 242.018C527.4 241.733 527.805 241.311 528.137 240.751C528.469 240.191 528.724 239.497 528.903 238.67C529.082 237.834 529.171 236.868 529.171 235.774ZM521.534 235.619C521.534 235.886 521.534 236.153 521.534 236.42C521.542 236.687 521.559 236.946 521.585 237.196L528.648 231.909C528.52 231.469 528.358 231.068 528.162 230.707C527.967 230.345 527.732 230.034 527.46 229.776C527.187 229.509 526.877 229.302 526.528 229.155C526.178 229.009 525.787 228.936 525.353 228.936C524.774 228.936 524.246 229.078 523.769 229.362C523.3 229.646 522.9 230.073 522.568 230.642C522.236 231.202 521.981 231.9 521.802 232.736C521.623 233.563 521.534 234.524 521.534 235.619Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 263.564C531.419 264.857 531.292 266.037 531.036 267.106C530.781 268.166 530.393 269.08 529.874 269.847C529.354 270.605 528.707 271.195 527.933 271.618C527.166 272.031 526.264 272.238 525.225 272.238C524.331 272.238 523.518 272.07 522.785 271.734C522.053 271.389 521.427 270.864 520.908 270.157C520.388 269.45 519.988 268.55 519.707 267.455C519.426 266.361 519.286 265.064 519.286 263.564C519.286 262.271 519.413 261.091 519.669 260.022C519.933 258.953 520.32 258.04 520.831 257.282C521.351 256.515 521.998 255.924 522.773 255.511C523.547 255.088 524.45 254.877 525.48 254.877C526.374 254.877 527.187 255.05 527.92 255.394C528.652 255.73 529.278 256.256 529.797 256.971C530.317 257.678 530.717 258.579 530.998 259.673C531.279 260.768 531.419 262.065 531.419 263.564ZM529.171 263.642C529.171 263.349 529.159 263.06 529.133 262.776C529.116 262.483 529.095 262.198 529.069 261.922L522.006 267.235C522.134 267.683 522.296 268.097 522.492 268.476C522.687 268.856 522.922 269.183 523.194 269.459C523.475 269.726 523.79 269.937 524.139 270.092C524.497 270.239 524.901 270.312 525.353 270.312C525.932 270.312 526.455 270.17 526.924 269.885C527.4 269.601 527.805 269.179 528.137 268.619C528.469 268.058 528.724 267.365 528.903 266.537C529.082 265.701 529.171 264.736 529.171 263.642ZM521.534 263.487C521.534 263.754 521.534 264.021 521.534 264.288C521.542 264.555 521.559 264.814 521.585 265.064L528.648 259.776C528.52 259.337 528.358 258.936 528.162 258.574C527.967 258.212 527.732 257.902 527.46 257.644C527.187 257.376 526.877 257.17 526.528 257.023C526.178 256.877 525.787 256.803 525.353 256.803C524.774 256.803 524.246 256.945 523.769 257.23C523.3 257.514 522.9 257.941 522.568 258.51C522.236 259.07 521.981 259.768 521.802 260.604C521.623 261.431 521.534 262.392 521.534 263.487Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 291.432C531.419 292.725 531.292 293.905 531.036 294.974C530.781 296.034 530.393 296.947 529.874 297.714C529.354 298.473 528.707 299.063 527.933 299.485C527.166 299.899 526.264 300.106 525.225 300.106C524.331 300.106 523.518 299.938 522.785 299.602C522.053 299.257 521.427 298.731 520.908 298.025C520.388 297.318 519.988 296.417 519.707 295.323C519.426 294.228 519.286 292.931 519.286 291.432C519.286 290.139 519.413 288.959 519.669 287.89C519.933 286.821 520.32 285.908 520.831 285.149C521.351 284.382 521.998 283.792 522.773 283.378C523.547 282.956 524.45 282.745 525.48 282.745C526.374 282.745 527.187 282.917 527.92 283.262C528.652 283.598 529.278 284.124 529.797 284.839C530.317 285.546 530.717 286.446 530.998 287.541C531.279 288.635 531.419 289.932 531.419 291.432ZM529.171 291.509C529.171 291.216 529.159 290.928 529.133 290.643C529.116 290.35 529.095 290.066 529.069 289.79L522.006 295.103C522.134 295.551 522.296 295.965 522.492 296.344C522.687 296.723 522.922 297.051 523.194 297.327C523.475 297.594 523.79 297.805 524.139 297.96C524.497 298.106 524.901 298.18 525.353 298.18C525.932 298.18 526.455 298.038 526.924 297.753C527.4 297.469 527.805 297.046 528.137 296.486C528.469 295.926 528.724 295.232 528.903 294.405C529.082 293.569 529.171 292.604 529.171 291.509ZM521.534 291.354C521.534 291.621 521.534 291.889 521.534 292.156C521.542 292.423 521.559 292.681 521.585 292.931L528.648 287.644C528.52 287.205 528.358 286.804 528.162 286.442C527.967 286.08 527.732 285.77 527.46 285.511C527.187 285.244 526.877 285.037 526.528 284.891C526.178 284.744 525.787 284.671 525.353 284.671C524.774 284.671 524.246 284.813 523.769 285.098C523.3 285.382 522.9 285.809 522.568 286.377C522.236 286.938 521.981 287.636 521.802 288.472C521.623 289.299 521.534 290.26 521.534 291.354Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 327.741H520.41V325.647H524.701V313.314L520.703 315.512L519.886 313.599L525.199 310.768H527.166V325.647H530.896V327.741Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 347.167C531.419 348.46 531.292 349.641 531.036 350.709C530.781 351.769 530.393 352.683 529.874 353.45C529.354 354.208 528.707 354.799 527.933 355.221C527.166 355.635 526.264 355.841 525.225 355.841C524.331 355.841 523.518 355.673 522.785 355.337C522.053 354.993 521.427 354.467 520.908 353.76C520.388 353.054 519.988 352.153 519.707 351.058C519.426 349.964 519.286 348.667 519.286 347.167C519.286 345.875 519.413 344.694 519.669 343.625C519.933 342.557 520.32 341.643 520.831 340.885C521.351 340.118 521.998 339.528 522.773 339.114C523.547 338.692 524.45 338.481 525.48 338.481C526.374 338.481 527.187 338.653 527.92 338.998C528.652 339.334 529.278 339.859 529.797 340.575C530.317 341.281 530.717 342.182 530.998 343.276C531.279 344.371 531.419 345.668 531.419 347.167ZM529.171 347.245C529.171 346.952 529.159 346.663 529.133 346.379C529.116 346.086 529.095 345.801 529.069 345.526L522.006 350.839C522.134 351.287 522.296 351.7 522.492 352.08C522.687 352.459 522.922 352.786 523.194 353.062C523.475 353.329 523.79 353.54 524.139 353.696C524.497 353.842 524.901 353.915 525.353 353.915C525.932 353.915 526.455 353.773 526.924 353.489C527.4 353.204 527.805 352.782 528.137 352.222C528.469 351.662 528.724 350.968 528.903 350.141C529.082 349.305 529.171 348.339 529.171 347.245ZM521.534 347.09C521.534 347.357 521.534 347.624 521.534 347.891C521.542 348.159 521.559 348.417 521.585 348.667L528.648 343.38C528.52 342.94 528.358 342.54 528.162 342.178C527.967 341.816 527.732 341.505 527.46 341.247C527.187 340.98 526.877 340.773 526.528 340.626C526.178 340.48 525.787 340.407 525.353 340.407C524.774 340.407 524.246 340.549 523.769 340.833C523.3 341.118 522.9 341.544 522.568 342.113C522.236 342.673 521.981 343.371 521.802 344.207C521.623 345.034 521.534 345.995 521.534 347.09Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 383.477H520.41V381.382H524.701V369.05L520.703 371.248L519.886 369.334L525.199 366.503H527.166V381.382H530.896V383.477Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 411.344H520.41V409.25H524.701V396.918L520.703 399.115L519.886 397.202L525.199 394.371H527.166V409.25H530.896V411.344Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 430.771C531.419 432.063 531.292 433.244 531.036 434.313C530.781 435.373 530.393 436.286 529.874 437.053C529.354 437.812 528.707 438.402 527.933 438.824C527.166 439.238 526.264 439.445 525.225 439.445C524.331 439.445 523.518 439.277 522.785 438.941C522.053 438.596 521.427 438.07 520.908 437.364C520.388 436.657 519.988 435.756 519.707 434.662C519.426 433.567 519.286 432.27 519.286 430.771C519.286 429.478 519.413 428.297 519.669 427.229C519.933 426.16 520.32 425.247 520.831 424.488C521.351 423.721 521.998 423.131 522.773 422.717C523.547 422.295 524.45 422.084 525.48 422.084C526.374 422.084 527.187 422.256 527.92 422.601C528.652 422.937 529.278 423.463 529.797 424.178C530.317 424.885 530.717 425.785 530.998 426.88C531.279 427.974 531.419 429.271 531.419 430.771ZM529.171 430.848C529.171 430.555 529.159 430.267 529.133 429.982C529.116 429.689 529.095 429.405 529.069 429.129L522.006 434.442C522.134 434.89 522.296 435.304 522.492 435.683C522.687 436.062 522.922 436.39 523.194 436.665C523.475 436.933 523.79 437.144 524.139 437.299C524.497 437.445 524.901 437.519 525.353 437.519C525.932 437.519 526.455 437.376 526.924 437.092C527.4 436.808 527.805 436.385 528.137 435.825C528.469 435.265 528.724 434.571 528.903 433.744C529.082 432.908 529.171 431.943 529.171 430.848ZM521.534 430.693C521.534 430.96 521.534 431.228 521.534 431.495C521.542 431.762 521.559 432.02 521.585 432.27L528.648 426.983C528.52 426.544 528.358 426.143 528.162 425.781C527.967 425.419 527.732 425.109 527.46 424.85C527.187 424.583 526.877 424.376 526.528 424.23C526.178 424.083 525.787 424.01 525.353 424.01C524.774 424.01 524.246 424.152 523.769 424.437C523.3 424.721 522.9 425.148 522.568 425.716C522.236 426.277 521.981 426.975 521.802 427.811C521.623 428.638 521.534 429.599 521.534 430.693Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 458.639C531.419 459.931 531.292 461.112 531.036 462.181C530.781 463.241 530.393 464.154 529.874 464.921C529.354 465.679 528.707 466.27 527.933 466.692C527.166 467.106 526.264 467.313 525.225 467.313C524.331 467.313 523.518 467.145 522.785 466.808C522.053 466.464 521.427 465.938 520.908 465.231C520.388 464.525 519.988 463.624 519.707 462.53C519.426 461.435 519.286 460.138 519.286 458.639C519.286 457.346 519.413 456.165 519.669 455.097C519.933 454.028 520.32 453.114 520.831 452.356C521.351 451.589 521.998 450.999 522.773 450.585C523.547 450.163 524.45 449.952 525.48 449.952C526.374 449.952 527.187 450.124 527.92 450.469C528.652 450.805 529.278 451.331 529.797 452.046C530.317 452.753 530.717 453.653 530.998 454.748C531.279 455.842 531.419 457.139 531.419 458.639ZM529.171 458.716C529.171 458.423 529.159 458.134 529.133 457.85C529.116 457.557 529.095 457.273 529.069 456.997L522.006 462.31C522.134 462.758 522.296 463.172 522.492 463.551C522.687 463.93 522.922 464.257 523.194 464.533C523.475 464.8 523.79 465.012 524.139 465.167C524.497 465.313 524.901 465.386 525.353 465.386C525.932 465.386 526.455 465.244 526.924 464.96C527.4 464.675 527.805 464.253 528.137 463.693C528.469 463.133 528.724 462.439 528.903 461.612C529.082 460.776 529.171 459.811 529.171 458.716ZM521.534 458.561C521.534 458.828 521.534 459.095 521.534 459.362C521.542 459.63 521.559 459.888 521.585 460.138L528.648 454.851C528.52 454.411 528.358 454.011 528.162 453.649C527.967 453.287 527.732 452.977 527.46 452.718C527.187 452.451 526.877 452.244 526.528 452.098C526.178 451.951 525.787 451.878 525.353 451.878C524.774 451.878 524.246 452.02 523.769 452.304C523.3 452.589 522.9 453.015 522.568 453.584C522.236 454.144 521.981 454.842 521.802 455.678C521.623 456.506 521.534 457.467 521.534 458.561Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 486.506C531.419 487.799 531.292 488.98 531.036 490.048C530.781 491.108 530.393 492.022 529.874 492.789C529.354 493.547 528.707 494.138 527.933 494.56C527.166 494.974 526.264 495.18 525.225 495.18C524.331 495.18 523.518 495.012 522.785 494.676C522.053 494.331 521.427 493.806 520.908 493.099C520.388 492.392 519.988 491.492 519.707 490.397C519.426 489.303 519.286 488.006 519.286 486.506C519.286 485.214 519.413 484.033 519.669 482.964C519.933 481.896 520.32 480.982 520.831 480.224C521.351 479.457 521.998 478.867 522.773 478.453C523.547 478.031 524.45 477.819 525.48 477.819C526.374 477.819 527.187 477.992 527.92 478.337C528.652 478.673 529.278 479.198 529.797 479.914C530.317 480.62 530.717 481.521 530.998 482.615C531.279 483.71 531.419 485.007 531.419 486.506ZM529.171 486.584C529.171 486.291 529.159 486.002 529.133 485.718C529.116 485.425 529.095 485.14 529.069 484.865L522.006 490.178C522.134 490.626 522.296 491.039 522.492 491.419C522.687 491.798 522.922 492.125 523.194 492.401C523.475 492.668 523.79 492.879 524.139 493.034C524.497 493.181 524.901 493.254 525.353 493.254C525.932 493.254 526.455 493.112 526.924 492.828C527.4 492.543 527.805 492.121 528.137 491.561C528.469 491.001 528.724 490.307 528.903 489.48C529.082 488.644 529.171 487.678 529.171 486.584ZM521.534 486.429C521.534 486.696 521.534 486.963 521.534 487.23C521.542 487.497 521.559 487.756 521.585 488.006L528.648 482.719C528.52 482.279 528.358 481.879 528.162 481.517C527.967 481.155 527.732 480.844 527.46 480.586C527.187 480.319 526.877 480.112 526.528 479.965C526.178 479.819 525.787 479.746 525.353 479.746C524.774 479.746 524.246 479.888 523.769 480.172C523.3 480.457 522.9 480.883 522.568 481.452C522.236 482.012 521.981 482.71 521.802 483.546C521.623 484.373 521.534 485.334 521.534 486.429Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 522.815H520.41V520.721H524.701V508.389L520.703 510.587L519.886 508.673L525.199 505.842H527.166V520.721H530.896V522.815Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 550.683H520.41V548.589H524.701V536.257L520.703 538.454L519.886 536.541L525.199 533.71H527.166V548.589H530.896V550.683Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 578.551H520.41V576.457H524.701V564.125L520.703 566.322L519.886 564.409L525.199 561.578H527.166V576.457H530.896V578.551Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 597.977C531.419 599.27 531.292 600.451 531.036 601.519C530.781 602.579 530.393 603.493 529.874 604.26C529.354 605.018 528.707 605.609 527.933 606.031C527.166 606.445 526.264 606.651 525.225 606.651C524.331 606.651 523.518 606.483 522.785 606.147C522.053 605.803 521.427 605.277 520.908 604.57C520.388 603.864 519.988 602.963 519.707 601.868C519.426 600.774 519.286 599.477 519.286 597.977C519.286 596.685 519.413 595.504 519.669 594.435C519.933 593.367 520.32 592.453 520.831 591.695C521.351 590.928 521.998 590.338 522.773 589.924C523.547 589.502 524.45 589.291 525.48 589.291C526.374 589.291 527.187 589.463 527.92 589.808C528.652 590.144 529.278 590.669 529.797 591.385C530.317 592.091 530.717 592.992 530.998 594.086C531.279 595.181 531.419 596.478 531.419 597.977ZM529.171 598.055C529.171 597.762 529.159 597.473 529.133 597.189C529.116 596.896 529.095 596.612 529.069 596.336L522.006 601.649C522.134 602.097 522.296 602.511 522.492 602.89C522.687 603.269 522.922 603.596 523.194 603.872C523.475 604.139 523.79 604.35 524.139 604.506C524.497 604.652 524.901 604.725 525.353 604.725C525.932 604.725 526.455 604.583 526.924 604.299C527.4 604.014 527.805 603.592 528.137 603.032C528.469 602.472 528.724 601.778 528.903 600.951C529.082 600.115 529.171 599.15 529.171 598.055ZM521.534 597.9C521.534 598.167 521.534 598.434 521.534 598.701C521.542 598.969 521.559 599.227 521.585 599.477L528.648 594.19C528.52 593.75 528.358 593.35 528.162 592.988C527.967 592.626 527.732 592.315 527.46 592.057C527.187 591.79 526.877 591.583 526.528 591.436C526.178 591.29 525.787 591.217 525.353 591.217C524.774 591.217 524.246 591.359 523.769 591.643C523.3 591.928 522.9 592.354 522.568 592.923C522.236 593.483 521.981 594.181 521.802 595.017C521.623 595.845 521.534 596.805 521.534 597.9Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 634.287H520.41V632.192H524.701V619.86L520.703 622.058L519.886 620.144L525.199 617.313H527.166V632.192H530.896V634.287Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 653.713C531.419 655.006 531.292 656.186 531.036 657.255C530.781 658.315 530.393 659.229 529.874 659.996C529.354 660.754 528.707 661.344 527.933 661.767C527.166 662.18 526.264 662.387 525.225 662.387C524.331 662.387 523.518 662.219 522.785 661.883C522.053 661.538 521.427 661.012 520.908 660.306C520.388 659.599 519.988 658.699 519.707 657.604C519.426 656.51 519.286 655.213 519.286 653.713C519.286 652.42 519.413 651.24 519.669 650.171C519.933 649.102 520.32 648.189 520.831 647.431C521.351 646.664 521.998 646.073 522.773 645.66C523.547 645.237 524.45 645.026 525.48 645.026C526.374 645.026 527.187 645.198 527.92 645.543C528.652 645.879 529.278 646.405 529.797 647.12C530.317 647.827 530.717 648.728 530.998 649.822C531.279 650.917 531.419 652.214 531.419 653.713ZM529.171 653.791C529.171 653.498 529.159 653.209 529.133 652.924C529.116 652.631 529.095 652.347 529.069 652.071L522.006 657.384C522.134 657.832 522.296 658.246 522.492 658.625C522.687 659.004 522.922 659.332 523.194 659.608C523.475 659.875 523.79 660.086 524.139 660.241C524.497 660.388 524.901 660.461 525.353 660.461C525.932 660.461 526.455 660.319 526.924 660.034C527.4 659.75 527.805 659.328 528.137 658.767C528.469 658.207 528.724 657.514 528.903 656.686C529.082 655.85 529.171 654.885 529.171 653.791ZM521.534 653.635C521.534 653.903 521.534 654.17 521.534 654.437C521.542 654.704 521.559 654.963 521.585 655.213L528.648 649.925C528.52 649.486 528.358 649.085 528.162 648.723C527.967 648.361 527.732 648.051 527.46 647.792C527.187 647.525 526.877 647.319 526.528 647.172C526.178 647.025 525.787 646.952 525.353 646.952C524.774 646.952 524.246 647.094 523.769 647.379C523.3 647.663 522.9 648.09 522.568 648.659C522.236 649.219 521.981 649.917 521.802 650.753C521.623 651.58 521.534 652.541 521.534 653.635Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M531.419 681.581C531.419 682.874 531.292 684.054 531.036 685.123C530.781 686.183 530.393 687.096 529.874 687.863C529.354 688.622 528.707 689.212 527.933 689.634C527.166 690.048 526.264 690.255 525.225 690.255C524.331 690.255 523.518 690.087 522.785 689.751C522.053 689.406 521.427 688.88 520.908 688.174C520.388 687.467 519.988 686.566 519.707 685.472C519.426 684.377 519.286 683.08 519.286 681.581C519.286 680.288 519.413 679.107 519.669 678.039C519.933 676.97 520.32 676.057 520.831 675.298C521.351 674.531 521.998 673.941 522.773 673.527C523.547 673.105 524.45 672.894 525.48 672.894C526.374 672.894 527.187 673.066 527.92 673.411C528.652 673.747 529.278 674.273 529.797 674.988C530.317 675.695 530.717 676.595 530.998 677.69C531.279 678.784 531.419 680.081 531.419 681.581ZM529.171 681.658C529.171 681.365 529.159 681.077 529.133 680.792C529.116 680.499 529.095 680.215 529.069 679.939L522.006 685.252C522.134 685.7 522.296 686.114 522.492 686.493C522.687 686.872 522.922 687.2 523.194 687.476C523.475 687.743 523.79 687.954 524.139 688.109C524.497 688.255 524.901 688.329 525.353 688.329C525.932 688.329 526.455 688.186 526.924 687.902C527.4 687.618 527.805 687.195 528.137 686.635C528.469 686.075 528.724 685.381 528.903 684.554C529.082 683.718 529.171 682.753 529.171 681.658ZM521.534 681.503C521.534 681.77 521.534 682.038 521.534 682.305C521.542 682.572 521.559 682.83 521.585 683.08L528.648 677.793C528.52 677.354 528.358 676.953 528.162 676.591C527.967 676.229 527.732 675.919 527.46 675.66C527.187 675.393 526.877 675.186 526.528 675.04C526.178 674.893 525.787 674.82 525.353 674.82C524.774 674.82 524.246 674.962 523.769 675.247C523.3 675.531 522.9 675.958 522.568 676.526C522.236 677.087 521.981 677.785 521.802 678.621C521.623 679.448 521.534 680.409 521.534 681.503Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M530.896 717.89H520.41V715.796H524.701V703.463L520.703 705.661L519.886 703.748L525.199 700.917H527.166V715.796H530.896V717.89Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 46.9731H289.124V44.8789H293.415V32.5466L289.418 34.7442L288.6 32.831L293.914 30H295.88V44.8789H299.61V46.9731Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 74.8409H289.124V72.7467H293.415V60.4144L289.418 62.612L288.6 60.6988L293.914 57.8678H295.88V72.7467H299.61V74.8409Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 94.2673C300.134 95.56 300.006 96.7407 299.75 97.8093C299.495 98.8693 299.108 99.7829 298.588 100.55C298.069 101.308 297.422 101.899 296.647 102.321C295.88 102.735 294.978 102.941 293.939 102.941C293.045 102.941 292.232 102.773 291.5 102.437C290.767 102.092 290.141 101.567 289.622 100.86C289.103 100.153 288.702 99.2528 288.421 98.1584C288.14 97.0639 288 95.7669 288 94.2673C288 92.9747 288.128 91.794 288.383 90.7254C288.647 89.6567 289.035 88.7432 289.545 87.9849C290.065 87.2179 290.712 86.6275 291.487 86.2139C292.262 85.7916 293.164 85.5804 294.195 85.5804C295.089 85.5804 295.902 85.7528 296.634 86.0975C297.366 86.4336 297.992 86.9593 298.512 87.6746C299.031 88.3813 299.431 89.2819 299.712 90.3763C299.993 91.4708 300.134 92.7678 300.134 94.2673ZM297.886 94.3449C297.886 94.0519 297.873 93.7632 297.847 93.4788C297.83 93.1858 297.809 92.9014 297.784 92.6256L290.72 97.9386C290.848 98.3867 291.01 98.8004 291.206 99.1796C291.402 99.5588 291.636 99.8863 291.908 100.162C292.189 100.429 292.504 100.64 292.853 100.795C293.211 100.942 293.616 101.015 294.067 101.015C294.646 101.015 295.169 100.873 295.638 100.589C296.115 100.304 296.519 99.882 296.851 99.3218C297.183 98.7616 297.439 98.0679 297.617 97.2405C297.796 96.4046 297.886 95.4394 297.886 94.3449ZM290.248 94.1898C290.248 94.4569 290.248 94.7241 290.248 94.9913C290.256 95.2584 290.273 95.517 290.299 95.7669L297.362 90.4798C297.234 90.0402 297.073 89.6395 296.877 89.2775C296.681 88.9156 296.447 88.6053 296.174 88.3468C295.902 88.0797 295.591 87.8728 295.242 87.7263C294.893 87.5798 294.501 87.5066 294.067 87.5066C293.488 87.5066 292.96 87.6488 292.483 87.9331C292.015 88.2175 291.615 88.6441 291.282 89.2129C290.95 89.7731 290.695 90.4711 290.516 91.3071C290.337 92.1344 290.248 93.0953 290.248 94.1898Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 122.135C300.134 123.428 300.006 124.608 299.75 125.677C299.495 126.737 299.108 127.651 298.588 128.418C298.069 129.176 297.422 129.766 296.647 130.189C295.88 130.602 294.978 130.809 293.939 130.809C293.045 130.809 292.232 130.641 291.5 130.305C290.767 129.96 290.141 129.435 289.622 128.728C289.103 128.021 288.702 127.121 288.421 126.026C288.14 124.932 288 123.635 288 122.135C288 120.842 288.128 119.662 288.383 118.593C288.647 117.525 289.035 116.611 289.545 115.853C290.065 115.086 290.712 114.495 291.487 114.082C292.262 113.659 293.164 113.448 294.195 113.448C295.089 113.448 295.902 113.621 296.634 113.965C297.366 114.301 297.992 114.827 298.512 115.542C299.031 116.249 299.431 117.15 299.712 118.244C299.993 119.339 300.134 120.636 300.134 122.135ZM297.886 122.213C297.886 121.92 297.873 121.631 297.847 121.347C297.83 121.054 297.809 120.769 297.784 120.493L290.72 125.806C290.848 126.255 291.01 126.668 291.206 127.047C291.402 127.427 291.636 127.754 291.908 128.03C292.189 128.297 292.504 128.508 292.853 128.663C293.211 128.81 293.616 128.883 294.067 128.883C294.646 128.883 295.169 128.741 295.638 128.456C296.115 128.172 296.519 127.75 296.851 127.19C297.183 126.629 297.439 125.936 297.617 125.108C297.796 124.272 297.886 123.307 297.886 122.213ZM290.248 122.058C290.248 122.325 290.248 122.592 290.248 122.859C290.256 123.126 290.273 123.385 290.299 123.635L297.362 118.348C297.234 117.908 297.073 117.507 296.877 117.145C296.681 116.783 296.447 116.473 296.174 116.215C295.902 115.947 295.591 115.741 295.242 115.594C294.893 115.448 294.501 115.374 294.067 115.374C293.488 115.374 292.96 115.517 292.483 115.801C292.015 116.085 291.615 116.512 291.282 117.081C290.95 117.641 290.695 118.339 290.516 119.175C290.337 120.002 290.248 120.963 290.248 122.058Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 150.003C300.134 151.296 300.006 152.476 299.75 153.545C299.495 154.605 299.108 155.518 298.588 156.285C298.069 157.044 297.422 157.634 296.647 158.056C295.88 158.47 294.978 158.677 293.939 158.677C293.045 158.677 292.232 158.509 291.5 158.173C290.767 157.828 290.141 157.302 289.622 156.596C289.103 155.889 288.702 154.988 288.421 153.894C288.14 152.799 288 151.502 288 150.003C288 148.71 288.128 147.53 288.383 146.461C288.647 145.392 289.035 144.479 289.545 143.72C290.065 142.953 290.712 142.363 291.487 141.949C292.262 141.527 293.164 141.316 294.195 141.316C295.089 141.316 295.902 141.488 296.634 141.833C297.366 142.169 297.992 142.695 298.512 143.41C299.031 144.117 299.431 145.017 299.712 146.112C299.993 147.206 300.134 148.503 300.134 150.003ZM297.886 150.08C297.886 149.787 297.873 149.499 297.847 149.214C297.83 148.921 297.809 148.637 297.784 148.361L290.72 153.674C290.848 154.122 291.01 154.536 291.206 154.915C291.402 155.294 291.636 155.622 291.908 155.898C292.189 156.165 292.504 156.376 292.853 156.531C293.211 156.678 293.616 156.751 294.067 156.751C294.646 156.751 295.169 156.609 295.638 156.324C296.115 156.04 296.519 155.618 296.851 155.057C297.183 154.497 297.439 153.803 297.617 152.976C297.796 152.14 297.886 151.175 297.886 150.08ZM290.248 149.925C290.248 150.193 290.248 150.46 290.248 150.727C290.256 150.994 290.273 151.253 290.299 151.502L297.362 146.215C297.234 145.776 297.073 145.375 296.877 145.013C296.681 144.651 296.447 144.341 296.174 144.082C295.902 143.815 295.591 143.608 295.242 143.462C294.893 143.315 294.501 143.242 294.067 143.242C293.488 143.242 292.96 143.384 292.483 143.669C292.015 143.953 291.615 144.38 291.282 144.948C290.95 145.509 290.695 146.207 290.516 147.043C290.337 147.87 290.248 148.831 290.248 149.925Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 186.312H289.124V184.218H293.415V171.886L289.418 174.083L288.6 172.17L293.914 169.339H295.88V184.218H299.61V186.312Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 205.738C300.134 207.031 300.006 208.212 299.75 209.28C299.495 210.34 299.108 211.254 298.588 212.021C298.069 212.779 297.422 213.37 296.647 213.792C295.88 214.206 294.978 214.412 293.939 214.412C293.045 214.412 292.232 214.244 291.5 213.908C290.767 213.564 290.141 213.038 289.622 212.331C289.103 211.625 288.702 210.724 288.421 209.629C288.14 208.535 288 207.238 288 205.738C288 204.446 288.128 203.265 288.383 202.197C288.647 201.128 289.035 200.214 289.545 199.456C290.065 198.689 290.712 198.099 291.487 197.685C292.262 197.263 293.164 197.052 294.195 197.052C295.089 197.052 295.902 197.224 296.634 197.569C297.366 197.905 297.992 198.43 298.512 199.146C299.031 199.852 299.431 200.753 299.712 201.847C299.993 202.942 300.134 204.239 300.134 205.738ZM297.886 205.816C297.886 205.523 297.873 205.234 297.847 204.95C297.83 204.657 297.809 204.373 297.784 204.097L290.72 209.41C290.848 209.858 291.01 210.272 291.206 210.651C291.402 211.03 291.636 211.357 291.908 211.633C292.189 211.9 292.504 212.111 292.853 212.267C293.211 212.413 293.616 212.486 294.067 212.486C294.646 212.486 295.169 212.344 295.638 212.06C296.115 211.775 296.519 211.353 296.851 210.793C297.183 210.233 297.439 209.539 297.617 208.712C297.796 207.876 297.886 206.911 297.886 205.816ZM290.248 205.661C290.248 205.928 290.248 206.195 290.248 206.462C290.256 206.73 290.273 206.988 290.299 207.238L297.362 201.951C297.234 201.511 297.073 201.111 296.877 200.749C296.681 200.387 296.447 200.076 296.174 199.818C295.902 199.551 295.591 199.344 295.242 199.197C294.893 199.051 294.501 198.978 294.067 198.978C293.488 198.978 292.96 199.12 292.483 199.404C292.015 199.689 291.615 200.115 291.282 200.684C290.95 201.244 290.695 201.942 290.516 202.778C290.337 203.606 290.248 204.566 290.248 205.661Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 242.048H289.124V239.953H293.415V227.621L289.418 229.819L288.6 227.905L293.914 225.074H295.88V239.953H299.61V242.048Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 269.915H289.124V267.821H293.415V255.489L289.418 257.686L288.6 255.773L293.914 252.942H295.88V267.821H299.61V269.915Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 289.342C300.134 290.635 300.006 291.815 299.75 292.884C299.495 293.944 299.108 294.857 298.588 295.624C298.069 296.383 297.422 296.973 296.647 297.395C295.88 297.809 294.978 298.016 293.939 298.016C293.045 298.016 292.232 297.848 291.5 297.512C290.767 297.167 290.141 296.641 289.622 295.935C289.103 295.228 288.702 294.327 288.421 293.233C288.14 292.138 288 290.841 288 289.342C288 288.049 288.128 286.868 288.383 285.8C288.647 284.731 289.035 283.818 289.545 283.059C290.065 282.292 290.712 281.702 291.487 281.288C292.262 280.866 293.164 280.655 294.195 280.655C295.089 280.655 295.902 280.827 296.634 281.172C297.366 281.508 297.992 282.034 298.512 282.749C299.031 283.456 299.431 284.356 299.712 285.451C299.993 286.545 300.134 287.842 300.134 289.342ZM297.886 289.419C297.886 289.126 297.873 288.838 297.847 288.553C297.83 288.26 297.809 287.976 297.784 287.7L290.72 293.013C290.848 293.461 291.01 293.875 291.206 294.254C291.402 294.633 291.636 294.961 291.908 295.237C292.189 295.504 292.504 295.715 292.853 295.87C293.211 296.016 293.616 296.09 294.067 296.09C294.646 296.09 295.169 295.948 295.638 295.663C296.115 295.379 296.519 294.956 296.851 294.396C297.183 293.836 297.439 293.142 297.617 292.315C297.796 291.479 297.886 290.514 297.886 289.419ZM290.248 289.264C290.248 289.531 290.248 289.799 290.248 290.066C290.256 290.333 290.273 290.591 290.299 290.841L297.362 285.554C297.234 285.115 297.073 284.714 296.877 284.352C296.681 283.99 296.447 283.68 296.174 283.421C295.902 283.154 295.591 282.947 295.242 282.801C294.893 282.654 294.501 282.581 294.067 282.581C293.488 282.581 292.96 282.723 292.483 283.008C292.015 283.292 291.615 283.719 291.282 284.287C290.95 284.848 290.695 285.546 290.516 286.382C290.337 287.209 290.248 288.17 290.248 289.264Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 317.21C300.134 318.502 300.006 319.683 299.75 320.752C299.495 321.812 299.108 322.725 298.588 323.492C298.069 324.25 297.422 324.841 296.647 325.263C295.88 325.677 294.978 325.884 293.939 325.884C293.045 325.884 292.232 325.716 291.5 325.379C290.767 325.035 290.141 324.509 289.622 323.802C289.103 323.096 288.702 322.195 288.421 321.101C288.14 320.006 288 318.709 288 317.21C288 315.917 288.128 314.736 288.383 313.668C288.647 312.599 289.035 311.685 289.545 310.927C290.065 310.16 290.712 309.57 291.487 309.156C292.262 308.734 293.164 308.523 294.195 308.523C295.089 308.523 295.902 308.695 296.634 309.04C297.366 309.376 297.992 309.902 298.512 310.617C299.031 311.324 299.431 312.224 299.712 313.319C299.993 314.413 300.134 315.71 300.134 317.21ZM297.886 317.287C297.886 316.994 297.873 316.705 297.847 316.421C297.83 316.128 297.809 315.844 297.784 315.568L290.72 320.881C290.848 321.329 291.01 321.743 291.206 322.122C291.402 322.501 291.636 322.829 291.908 323.104C292.189 323.371 292.504 323.583 292.853 323.738C293.211 323.884 293.616 323.957 294.067 323.957C294.646 323.957 295.169 323.815 295.638 323.531C296.115 323.246 296.519 322.824 296.851 322.264C297.183 321.704 297.439 321.01 297.617 320.183C297.796 319.347 297.886 318.382 297.886 317.287ZM290.248 317.132C290.248 317.399 290.248 317.666 290.248 317.934C290.256 318.201 290.273 318.459 290.299 318.709L297.362 313.422C297.234 312.982 297.073 312.582 296.877 312.22C296.681 311.858 296.447 311.548 296.174 311.289C295.902 311.022 295.591 310.815 295.242 310.669C294.893 310.522 294.501 310.449 294.067 310.449C293.488 310.449 292.96 310.591 292.483 310.875C292.015 311.16 291.615 311.586 291.282 312.155C290.95 312.715 290.695 313.413 290.516 314.249C290.337 315.077 290.248 316.038 290.248 317.132Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 345.077C300.134 346.37 300.006 347.551 299.75 348.619C299.495 349.679 299.108 350.593 298.588 351.36C298.069 352.118 297.422 352.709 296.647 353.131C295.88 353.545 294.978 353.751 293.939 353.751C293.045 353.751 292.232 353.583 291.5 353.247C290.767 352.902 290.141 352.377 289.622 351.67C289.103 350.963 288.702 350.063 288.421 348.968C288.14 347.874 288 346.577 288 345.077C288 343.785 288.128 342.604 288.383 341.535C288.647 340.467 289.035 339.553 289.545 338.795C290.065 338.028 290.712 337.438 291.487 337.024C292.262 336.602 293.164 336.39 294.195 336.39C295.089 336.39 295.902 336.563 296.634 336.908C297.366 337.244 297.992 337.769 298.512 338.485C299.031 339.191 299.431 340.092 299.712 341.186C299.993 342.281 300.134 343.578 300.134 345.077ZM297.886 345.155C297.886 344.862 297.873 344.573 297.847 344.289C297.83 343.996 297.809 343.711 297.784 343.436L290.72 348.749C290.848 349.197 291.01 349.61 291.206 349.99C291.402 350.369 291.636 350.696 291.908 350.972C292.189 351.239 292.504 351.45 292.853 351.605C293.211 351.752 293.616 351.825 294.067 351.825C294.646 351.825 295.169 351.683 295.638 351.399C296.115 351.114 296.519 350.692 296.851 350.132C297.183 349.572 297.439 348.878 297.617 348.051C297.796 347.215 297.886 346.249 297.886 345.155ZM290.248 345C290.248 345.267 290.248 345.534 290.248 345.801C290.256 346.068 290.273 346.327 290.299 346.577L297.362 341.29C297.234 340.85 297.073 340.45 296.877 340.088C296.681 339.726 296.447 339.415 296.174 339.157C295.902 338.89 295.591 338.683 295.242 338.536C294.893 338.39 294.501 338.317 294.067 338.317C293.488 338.317 292.96 338.459 292.483 338.743C292.015 339.028 291.615 339.454 291.282 340.023C290.95 340.583 290.695 341.281 290.516 342.117C290.337 342.944 290.248 343.905 290.248 345Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 381.386H289.124V379.292H293.415V366.96L289.418 369.158L288.6 367.244L293.914 364.413H295.88V379.292H299.61V381.386Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 409.254H289.124V407.16H293.415V394.828L289.418 397.025L288.6 395.112L293.914 392.281H295.88V407.16H299.61V409.254Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 437.122H289.124V435.028H293.415V422.696L289.418 424.893L288.6 422.98L293.914 420.149H295.88V435.028H299.61V437.122Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 456.549C300.134 457.841 300.006 459.022 299.75 460.091C299.495 461.151 299.108 462.064 298.588 462.831C298.069 463.589 297.422 464.18 296.647 464.602C295.88 465.016 294.978 465.223 293.939 465.223C293.045 465.223 292.232 465.054 291.5 464.718C290.767 464.374 290.141 463.848 289.622 463.141C289.103 462.435 288.702 461.534 288.421 460.44C288.14 459.345 288 458.048 288 456.549C288 455.256 288.128 454.075 288.383 453.007C288.647 451.938 289.035 451.024 289.545 450.266C290.065 449.499 290.712 448.909 291.487 448.495C292.262 448.073 293.164 447.862 294.195 447.862C295.089 447.862 295.902 448.034 296.634 448.379C297.366 448.715 297.992 449.24 298.512 449.956C299.031 450.662 299.431 451.563 299.712 452.658C299.993 453.752 300.134 455.049 300.134 456.549ZM297.886 456.626C297.886 456.333 297.873 456.044 297.847 455.76C297.83 455.467 297.809 455.183 297.784 454.907L290.72 460.22C290.848 460.668 291.01 461.082 291.206 461.461C291.402 461.84 291.636 462.167 291.908 462.443C292.189 462.71 292.504 462.922 292.853 463.077C293.211 463.223 293.616 463.296 294.067 463.296C294.646 463.296 295.169 463.154 295.638 462.87C296.115 462.585 296.519 462.163 296.851 461.603C297.183 461.043 297.439 460.349 297.617 459.522C297.796 458.686 297.886 457.721 297.886 456.626ZM290.248 456.471C290.248 456.738 290.248 457.005 290.248 457.272C290.256 457.54 290.273 457.798 290.299 458.048L297.362 452.761C297.234 452.321 297.073 451.921 296.877 451.559C296.681 451.197 296.447 450.887 296.174 450.628C295.902 450.361 295.591 450.154 295.242 450.007C294.893 449.861 294.501 449.788 294.067 449.788C293.488 449.788 292.96 449.93 292.483 450.214C292.015 450.499 291.615 450.925 291.282 451.494C290.95 452.054 290.695 452.752 290.516 453.588C290.337 454.416 290.248 455.376 290.248 456.471Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 492.858H289.124V490.763H293.415V478.431L289.418 480.629L288.6 478.716L293.914 475.885H295.88V490.763H299.61V492.858Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 512.284C300.134 513.577 300.006 514.757 299.75 515.826C299.495 516.886 299.108 517.8 298.588 518.567C298.069 519.325 297.422 519.915 296.647 520.338C295.88 520.751 294.978 520.958 293.939 520.958C293.045 520.958 292.232 520.79 291.5 520.454C290.767 520.109 290.141 519.583 289.622 518.877C289.103 518.17 288.702 517.27 288.421 516.175C288.14 515.081 288 513.784 288 512.284C288 510.991 288.128 509.811 288.383 508.742C288.647 507.673 289.035 506.76 289.545 506.002C290.065 505.235 290.712 504.644 291.487 504.231C292.262 503.808 293.164 503.597 294.195 503.597C295.089 503.597 295.902 503.77 296.634 504.114C297.366 504.45 297.992 504.976 298.512 505.691C299.031 506.398 299.431 507.299 299.712 508.393C299.993 509.488 300.134 510.785 300.134 512.284ZM297.886 512.362C297.886 512.069 297.873 511.78 297.847 511.496C297.83 511.203 297.809 510.918 297.784 510.642L290.72 515.955C290.848 516.403 291.01 516.817 291.206 517.196C291.402 517.576 291.636 517.903 291.908 518.179C292.189 518.446 292.504 518.657 292.853 518.812C293.211 518.959 293.616 519.032 294.067 519.032C294.646 519.032 295.169 518.89 295.638 518.605C296.115 518.321 296.519 517.899 296.851 517.339C297.183 516.778 297.439 516.085 297.617 515.257C297.796 514.421 297.886 513.456 297.886 512.362ZM290.248 512.207C290.248 512.474 290.248 512.741 290.248 513.008C290.256 513.275 290.273 513.534 290.299 513.784L297.362 508.496C297.234 508.057 297.073 507.656 296.877 507.294C296.681 506.932 296.447 506.622 296.174 506.364C295.902 506.096 295.591 505.89 295.242 505.743C294.893 505.597 294.501 505.523 294.067 505.523C293.488 505.523 292.96 505.665 292.483 505.95C292.015 506.234 291.615 506.661 291.282 507.23C290.95 507.79 290.695 508.488 290.516 509.324C290.337 510.151 290.248 511.112 290.248 512.207Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M300.134 540.152C300.134 541.445 300.006 542.625 299.75 543.694C299.495 544.754 299.108 545.667 298.588 546.434C298.069 547.193 297.422 547.783 296.647 548.205C295.88 548.619 294.978 548.826 293.939 548.826C293.045 548.826 292.232 548.658 291.5 548.322C290.767 547.977 290.141 547.451 289.622 546.745C289.103 546.038 288.702 545.137 288.421 544.043C288.14 542.948 288 541.651 288 540.152C288 538.859 288.128 537.678 288.383 536.61C288.647 535.541 289.035 534.628 289.545 533.869C290.065 533.102 290.712 532.512 291.487 532.098C292.262 531.676 293.164 531.465 294.195 531.465C295.089 531.465 295.902 531.637 296.634 531.982C297.366 532.318 297.992 532.844 298.512 533.559C299.031 534.266 299.431 535.166 299.712 536.261C299.993 537.355 300.134 538.652 300.134 540.152ZM297.886 540.229C297.886 539.936 297.873 539.648 297.847 539.363C297.83 539.07 297.809 538.786 297.784 538.51L290.72 543.823C290.848 544.271 291.01 544.685 291.206 545.064C291.402 545.443 291.636 545.771 291.908 546.047C292.189 546.314 292.504 546.525 292.853 546.68C293.211 546.826 293.616 546.9 294.067 546.9C294.646 546.9 295.169 546.758 295.638 546.473C296.115 546.189 296.519 545.766 296.851 545.206C297.183 544.646 297.439 543.952 297.617 543.125C297.796 542.289 297.886 541.324 297.886 540.229ZM290.248 540.074C290.248 540.341 290.248 540.609 290.248 540.876C290.256 541.143 290.273 541.401 290.299 541.651L297.362 536.364C297.234 535.925 297.073 535.524 296.877 535.162C296.681 534.8 296.447 534.49 296.174 534.231C295.902 533.964 295.591 533.757 295.242 533.611C294.893 533.464 294.501 533.391 294.067 533.391C293.488 533.391 292.96 533.533 292.483 533.818C292.015 534.102 291.615 534.529 291.282 535.097C290.95 535.658 290.695 536.356 290.516 537.192C290.337 538.019 290.248 538.98 290.248 540.074Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M299.61 576.461H289.124V574.367H293.415V562.034L289.418 564.232L288.6 562.319L293.914 559.488H295.88V574.367H299.61V576.461Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M457.36 221.837C457.36 222.859 457.25 223.773 457.031 224.58C456.811 225.386 456.488 226.069 456.061 226.628C455.641 227.18 455.126 227.602 454.518 227.895C453.909 228.187 453.213 228.333 452.429 228.333C451.751 228.333 451.127 228.212 450.556 227.971C449.985 227.729 449.492 227.352 449.078 226.837C448.664 226.317 448.341 225.653 448.109 224.846C447.877 224.04 447.761 223.075 447.761 221.951C447.761 220.928 447.871 220.014 448.09 219.208C448.316 218.401 448.639 217.722 449.06 217.169C449.48 216.61 449.997 216.185 450.612 215.893C451.227 215.601 451.924 215.455 452.702 215.455C453.379 215.455 454.003 215.575 454.574 215.817C455.145 216.058 455.635 216.436 456.043 216.95C456.457 217.465 456.78 218.125 457.012 218.931C457.244 219.738 457.36 220.706 457.36 221.837ZM450.076 221.875C450.076 222.002 450.076 222.122 450.076 222.237C450.076 222.345 450.082 222.456 450.095 222.57L454.706 219.198C454.487 218.569 454.195 218.106 453.831 217.807C453.473 217.503 453.05 217.35 452.56 217.35C452.209 217.35 451.883 217.442 451.582 217.626C451.281 217.804 451.017 218.08 450.791 218.455C450.572 218.83 450.396 219.3 450.264 219.865C450.139 220.43 450.076 221.1 450.076 221.875ZM455.045 221.913C455.045 221.805 455.042 221.697 455.036 221.589C455.029 221.475 455.026 221.367 455.026 221.265L450.443 224.618C450.644 225.234 450.926 225.691 451.29 225.989C451.654 226.288 452.077 226.437 452.56 226.437C452.912 226.437 453.238 226.345 453.539 226.161C453.847 225.977 454.11 225.701 454.33 225.332C454.556 224.958 454.731 224.488 454.857 223.923C454.982 223.351 455.045 222.681 455.045 221.913Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 249.014V246.947H451.826V238.946L449.097 240.47L448.344 238.575L452.25 236.489H454.179V246.947H456.88V249.014H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 269.915V267.848H451.826V259.847L449.097 261.371L448.344 259.476L452.25 257.39H454.179V267.848H456.88V269.915H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 284.539C457.36 285.561 457.25 286.476 457.031 287.282C456.811 288.089 456.488 288.771 456.061 289.33C455.641 289.883 455.126 290.305 454.518 290.597C453.909 290.889 453.213 291.035 452.429 291.035C451.751 291.035 451.127 290.915 450.556 290.673C449.985 290.432 449.492 290.054 449.078 289.54C448.664 289.019 448.341 288.355 448.109 287.549C447.877 286.743 447.761 285.777 447.761 284.653C447.761 283.631 447.871 282.717 448.09 281.91C448.316 281.104 448.639 280.424 449.06 279.872C449.48 279.313 449.997 278.887 450.612 278.595C451.227 278.303 451.924 278.157 452.702 278.157C453.379 278.157 454.003 278.278 454.574 278.519C455.145 278.76 455.635 279.138 456.043 279.653C456.457 280.167 456.78 280.827 457.012 281.634C457.244 282.44 457.36 283.409 457.36 284.539ZM450.076 284.577C450.076 284.704 450.076 284.825 450.076 284.939C450.076 285.047 450.082 285.158 450.095 285.272L454.706 281.901C454.487 281.272 454.195 280.808 453.831 280.51C453.473 280.205 453.05 280.053 452.56 280.053C452.209 280.053 451.883 280.145 451.582 280.329C451.281 280.507 451.017 280.783 450.791 281.158C450.572 281.532 450.396 282.002 450.264 282.567C450.139 283.133 450.076 283.802 450.076 284.577ZM455.045 284.615C455.045 284.507 455.042 284.399 455.036 284.291C455.029 284.177 455.026 284.069 455.026 283.968L450.443 287.32C450.644 287.936 450.926 288.394 451.29 288.692C451.654 288.99 452.077 289.14 452.56 289.14C452.912 289.14 453.238 289.048 453.539 288.863C453.847 288.679 454.11 288.403 454.33 288.035C454.556 287.66 454.731 287.19 454.857 286.625C454.982 286.054 455.045 285.384 455.045 284.615Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 311.717V309.65H451.826V301.649L449.097 303.173L448.344 301.277L452.25 299.191H454.179V309.65H456.88V311.717H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 326.341C457.36 327.363 457.25 328.278 457.031 329.084C456.811 329.89 456.488 330.573 456.061 331.132C455.641 331.684 455.126 332.107 454.518 332.399C453.909 332.691 453.213 332.837 452.429 332.837C451.751 332.837 451.127 332.716 450.556 332.475C449.985 332.234 449.492 331.856 449.078 331.341C448.664 330.821 448.341 330.157 448.109 329.351C447.877 328.544 447.761 327.579 447.761 326.455C447.761 325.433 447.871 324.518 448.09 323.712C448.316 322.905 448.639 322.226 449.06 321.673C449.48 321.115 449.997 320.689 450.612 320.397C451.227 320.105 451.924 319.959 452.702 319.959C453.379 319.959 454.003 320.08 454.574 320.321C455.145 320.562 455.635 320.94 456.043 321.454C456.457 321.969 456.78 322.629 457.012 323.436C457.244 324.242 457.36 325.21 457.36 326.341ZM450.076 326.379C450.076 326.506 450.076 326.626 450.076 326.741C450.076 326.849 450.082 326.96 450.095 327.074L454.706 323.702C454.487 323.074 454.195 322.61 453.831 322.312C453.473 322.007 453.05 321.854 452.56 321.854C452.209 321.854 451.883 321.946 451.582 322.131C451.281 322.308 451.017 322.585 450.791 322.959C450.572 323.334 450.396 323.804 450.264 324.369C450.139 324.934 450.076 325.604 450.076 326.379ZM455.045 326.417C455.045 326.309 455.042 326.201 455.036 326.093C455.029 325.979 455.026 325.871 455.026 325.769L450.443 329.122C450.644 329.738 450.926 330.195 451.29 330.494C451.654 330.792 452.077 330.941 452.56 330.941C452.912 330.941 453.238 330.849 453.539 330.665C453.847 330.481 454.11 330.205 454.33 329.836C454.556 329.462 454.731 328.992 454.857 328.427C454.982 327.855 455.045 327.185 455.045 326.417Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 347.242C457.36 348.264 457.25 349.178 457.031 349.985C456.811 350.791 456.488 351.474 456.061 352.033C455.641 352.585 455.126 353.007 454.518 353.3C453.909 353.592 453.213 353.738 452.429 353.738C451.751 353.738 451.127 353.617 450.556 353.376C449.985 353.134 449.492 352.757 449.078 352.242C448.664 351.722 448.341 351.058 448.109 350.252C447.877 349.445 447.761 348.48 447.761 347.356C447.761 346.334 447.871 345.419 448.09 344.613C448.316 343.806 448.639 343.127 449.06 342.574C449.48 342.015 449.997 341.59 450.612 341.298C451.227 341.006 451.924 340.86 452.702 340.86C453.379 340.86 454.003 340.98 454.574 341.222C455.145 341.463 455.635 341.841 456.043 342.355C456.457 342.87 456.78 343.53 457.012 344.336C457.244 345.143 457.36 346.111 457.36 347.242ZM450.076 347.28C450.076 347.407 450.076 347.527 450.076 347.642C450.076 347.75 450.082 347.861 450.095 347.975L454.706 344.603C454.487 343.974 454.195 343.511 453.831 343.212C453.473 342.908 453.05 342.755 452.56 342.755C452.209 342.755 451.883 342.847 451.582 343.031C451.281 343.209 451.017 343.486 450.791 343.86C450.572 344.235 450.396 344.705 450.264 345.27C450.139 345.835 450.076 346.505 450.076 347.28ZM455.045 347.318C455.045 347.21 455.042 347.102 455.036 346.994C455.029 346.88 455.026 346.772 455.026 346.67L450.443 350.023C450.644 350.639 450.926 351.096 451.29 351.395C451.654 351.693 452.077 351.842 452.56 351.842C452.912 351.842 453.238 351.75 453.539 351.566C453.847 351.382 454.11 351.106 454.33 350.737C454.556 350.363 454.731 349.893 454.857 349.328C454.982 348.756 455.045 348.086 455.045 347.318Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 368.142C457.36 369.165 457.25 370.079 457.031 370.886C456.811 371.692 456.488 372.375 456.061 372.934C455.641 373.486 455.126 373.908 454.518 374.2C453.909 374.493 453.213 374.639 452.429 374.639C451.751 374.639 451.127 374.518 450.556 374.277C449.985 374.035 449.492 373.657 449.078 373.143C448.664 372.622 448.341 371.959 448.109 371.152C447.877 370.346 447.761 369.381 447.761 368.257C447.761 367.234 447.871 366.32 448.09 365.513C448.316 364.707 448.639 364.028 449.06 363.475C449.48 362.916 449.997 362.491 450.612 362.199C451.227 361.907 451.924 361.761 452.702 361.761C453.379 361.761 454.003 361.881 454.574 362.123C455.145 362.364 455.635 362.742 456.043 363.256C456.457 363.77 456.78 364.431 457.012 365.237C457.244 366.044 457.36 367.012 457.36 368.142ZM450.076 368.181C450.076 368.308 450.076 368.428 450.076 368.542C450.076 368.65 450.082 368.762 450.095 368.876L454.706 365.504C454.487 364.875 454.195 364.412 453.831 364.113C453.473 363.808 453.05 363.656 452.56 363.656C452.209 363.656 451.883 363.748 451.582 363.932C451.281 364.11 451.017 364.386 450.791 364.761C450.572 365.136 450.396 365.606 450.264 366.171C450.139 366.736 450.076 367.406 450.076 368.181ZM455.045 368.219C455.045 368.111 455.042 368.003 455.036 367.895C455.029 367.78 455.026 367.673 455.026 367.571L450.443 370.924C450.644 371.54 450.926 371.997 451.29 372.295C451.654 372.594 452.077 372.743 452.56 372.743C452.912 372.743 453.238 372.651 453.539 372.467C453.847 372.283 454.11 372.006 454.33 371.638C454.556 371.263 454.731 370.794 454.857 370.228C454.982 369.657 455.045 368.987 455.045 368.219Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 395.32V393.253H451.826V385.252L449.097 386.776L448.344 384.881L452.25 382.795H454.179V393.253H456.88V395.32H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 416.221V414.154H451.826V406.153L449.097 407.677L448.344 405.782L452.25 403.696H454.179V414.154H456.88V416.221H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 430.845C457.36 431.867 457.25 432.782 457.031 433.588C456.811 434.395 456.488 435.077 456.061 435.636C455.641 436.189 455.126 436.611 454.518 436.903C453.909 437.195 453.213 437.341 452.429 437.341C451.751 437.341 451.127 437.22 450.556 436.979C449.985 436.738 449.492 436.36 449.078 435.846C448.664 435.325 448.341 434.661 448.109 433.855C447.877 433.048 447.761 432.083 447.761 430.959C447.761 429.937 447.871 429.022 448.09 428.216C448.316 427.41 448.639 426.73 449.06 426.178C449.48 425.619 449.997 425.193 450.612 424.901C451.227 424.609 451.924 424.463 452.702 424.463C453.379 424.463 454.003 424.584 454.574 424.825C455.145 425.066 455.635 425.444 456.043 425.959C456.457 426.473 456.78 427.133 457.012 427.94C457.244 428.746 457.36 429.715 457.36 430.845ZM450.076 430.883C450.076 431.01 450.076 431.131 450.076 431.245C450.076 431.353 450.082 431.464 450.095 431.578L454.706 428.206C454.487 427.578 454.195 427.114 453.831 426.816C453.473 426.511 453.05 426.359 452.56 426.359C452.209 426.359 451.883 426.451 451.582 426.635C451.281 426.813 451.017 427.089 450.791 427.464C450.572 427.838 450.396 428.308 450.264 428.873C450.139 429.438 450.076 430.108 450.076 430.883ZM455.045 430.921C455.045 430.813 455.042 430.705 455.036 430.597C455.029 430.483 455.026 430.375 455.026 430.273L450.443 433.626C450.644 434.242 450.926 434.699 451.29 434.998C451.654 435.296 452.077 435.446 452.56 435.446C452.912 435.446 453.238 435.354 453.539 435.169C453.847 434.985 454.11 434.709 454.33 434.341C454.556 433.966 454.731 433.496 454.857 432.931C454.982 432.359 455.045 431.689 455.045 430.921Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 458.023V455.956H451.826V447.955L449.097 449.479L448.344 447.583L452.25 445.497H454.179V455.956H456.88V458.023H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 478.924V476.857H451.826V468.856L449.097 470.38L448.344 468.484L452.25 466.398H454.179V476.857H456.88V478.924H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 493.547C457.36 494.57 457.25 495.484 457.031 496.291C456.811 497.097 456.488 497.78 456.061 498.339C455.641 498.891 455.126 499.313 454.518 499.605C453.909 499.898 453.213 500.044 452.429 500.044C451.751 500.044 451.127 499.923 450.556 499.682C449.985 499.44 449.492 499.062 449.078 498.548C448.664 498.027 448.341 497.364 448.109 496.557C447.877 495.751 447.761 494.786 447.761 493.662C447.761 492.639 447.871 491.725 448.09 490.919C448.316 490.112 448.639 489.433 449.06 488.88C449.48 488.321 449.997 487.896 450.612 487.604C451.227 487.312 451.924 487.166 452.702 487.166C453.379 487.166 454.003 487.286 454.574 487.528C455.145 487.769 455.635 488.147 456.043 488.661C456.457 489.175 456.78 489.836 457.012 490.642C457.244 491.449 457.36 492.417 457.36 493.547ZM450.076 493.586C450.076 493.713 450.076 493.833 450.076 493.948C450.076 494.055 450.082 494.167 450.095 494.281L454.706 490.909C454.487 490.28 454.195 489.817 453.831 489.518C453.473 489.214 453.05 489.061 452.56 489.061C452.209 489.061 451.883 489.153 451.582 489.337C451.281 489.515 451.017 489.791 450.791 490.166C450.572 490.541 450.396 491.011 450.264 491.576C450.139 492.141 450.076 492.811 450.076 493.586ZM455.045 493.624C455.045 493.516 455.042 493.408 455.036 493.3C455.029 493.186 455.026 493.078 455.026 492.976L450.443 496.329C450.644 496.945 450.926 497.402 451.29 497.7C451.654 497.999 452.077 498.148 452.56 498.148C452.912 498.148 453.238 498.056 453.539 497.872C453.847 497.688 454.11 497.411 454.33 497.043C454.556 496.669 454.731 496.199 454.857 495.633C454.982 495.062 455.045 494.392 455.045 493.624Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 520.725V518.658H451.826V510.657L449.097 512.181L448.344 510.286L452.25 508.2H454.179V518.658H456.88V520.725H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 541.626V539.559H451.826V531.558L449.097 533.082L448.344 531.187L452.25 529.101H454.179V539.559H456.88V541.626H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 562.527V560.46H451.826V552.459L449.097 553.983L448.344 552.087L452.25 550.001H454.179V560.46H456.88V562.527H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 577.151C457.36 578.173 457.25 579.088 457.031 579.894C456.811 580.701 456.488 581.383 456.061 581.942C455.641 582.494 455.126 582.917 454.518 583.209C453.909 583.501 453.213 583.647 452.429 583.647C451.751 583.647 451.127 583.526 450.556 583.285C449.985 583.044 449.492 582.666 449.078 582.152C448.664 581.631 448.341 580.967 448.109 580.161C447.877 579.354 447.761 578.389 447.761 577.265C447.761 576.243 447.871 575.328 448.09 574.522C448.316 573.715 448.639 573.036 449.06 572.484C449.48 571.925 449.997 571.499 450.612 571.207C451.227 570.915 451.924 570.769 452.702 570.769C453.379 570.769 454.003 570.89 454.574 571.131C455.145 571.372 455.635 571.75 456.043 572.264C456.457 572.779 456.78 573.439 457.012 574.246C457.244 575.052 457.36 576.021 457.36 577.151ZM450.076 577.189C450.076 577.316 450.076 577.437 450.076 577.551C450.076 577.659 450.082 577.77 450.095 577.884L454.706 574.512C454.487 573.884 454.195 573.42 453.831 573.122C453.473 572.817 453.05 572.664 452.56 572.664C452.209 572.664 451.883 572.757 451.582 572.941C451.281 573.119 451.017 573.395 450.791 573.769C450.572 574.144 450.396 574.614 450.264 575.179C450.139 575.744 450.076 576.414 450.076 577.189ZM455.045 577.227C455.045 577.119 455.042 577.011 455.036 576.903C455.029 576.789 455.026 576.681 455.026 576.579L450.443 579.932C450.644 580.548 450.926 581.005 451.29 581.304C451.654 581.602 452.077 581.751 452.56 581.751C452.912 581.751 453.238 581.659 453.539 581.475C453.847 581.291 454.11 581.015 454.33 580.647C454.556 580.272 454.731 579.802 454.857 579.237C454.982 578.665 455.045 577.995 455.045 577.227Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M457.36 598.052C457.36 599.074 457.25 599.988 457.031 600.795C456.811 601.601 456.488 602.284 456.061 602.843C455.641 603.395 455.126 603.818 454.518 604.11C453.909 604.402 453.213 604.548 452.429 604.548C451.751 604.548 451.127 604.427 450.556 604.186C449.985 603.945 449.492 603.567 449.078 603.052C448.664 602.532 448.341 601.868 448.109 601.062C447.877 600.255 447.761 599.29 447.761 598.166C447.761 597.144 447.871 596.229 448.09 595.423C448.316 594.616 448.639 593.937 449.06 593.384C449.48 592.826 449.997 592.4 450.612 592.108C451.227 591.816 451.924 591.67 452.702 591.67C453.379 591.67 454.003 591.79 454.574 592.032C455.145 592.273 455.635 592.651 456.043 593.165C456.457 593.68 456.78 594.34 457.012 595.147C457.244 595.953 457.36 596.921 457.36 598.052ZM450.076 598.09C450.076 598.217 450.076 598.337 450.076 598.452C450.076 598.56 450.082 598.671 450.095 598.785L454.706 595.413C454.487 594.785 454.195 594.321 453.831 594.023C453.473 593.718 453.05 593.565 452.56 593.565C452.209 593.565 451.883 593.657 451.582 593.842C451.281 594.019 451.017 594.296 450.791 594.67C450.572 595.045 450.396 595.515 450.264 596.08C450.139 596.645 450.076 597.315 450.076 598.09ZM455.045 598.128C455.045 598.02 455.042 597.912 455.036 597.804C455.029 597.69 455.026 597.582 455.026 597.48L450.443 600.833C450.644 601.449 450.926 601.906 451.29 602.205C451.654 602.503 452.077 602.652 452.56 602.652C452.912 602.652 453.238 602.56 453.539 602.376C453.847 602.192 454.11 601.916 454.33 601.547C454.556 601.173 454.731 600.703 454.857 600.138C454.982 599.566 455.045 598.896 455.045 598.128Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M448.692 625.23V623.163H451.826V615.161L449.097 616.686L448.344 614.79L452.25 612.704H454.179V623.163H456.88V625.23H448.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M479.387 517.235C479.387 518.257 479.277 519.172 479.058 519.978C478.838 520.785 478.515 521.467 478.088 522.026C477.668 522.579 477.153 523.001 476.545 523.293C475.936 523.585 475.24 523.731 474.456 523.731C473.778 523.731 473.154 523.611 472.583 523.369C472.012 523.128 471.519 522.75 471.105 522.236C470.691 521.715 470.368 521.052 470.136 520.245C469.904 519.439 469.788 518.473 469.788 517.349C469.788 516.327 469.898 515.413 470.117 514.606C470.343 513.8 470.666 513.12 471.086 512.568C471.507 512.009 472.024 511.584 472.639 511.291C473.254 510.999 473.951 510.853 474.728 510.853C475.406 510.853 476.03 510.974 476.601 511.215C477.172 511.457 477.662 511.834 478.069 512.349C478.483 512.863 478.807 513.524 479.039 514.33C479.271 515.136 479.387 516.105 479.387 517.235ZM472.103 517.273C472.103 517.4 472.103 517.521 472.103 517.635C472.103 517.743 472.109 517.854 472.122 517.969L476.733 514.597C476.513 513.968 476.222 513.504 475.858 513.206C475.5 512.901 475.077 512.749 474.587 512.749C474.236 512.749 473.91 512.841 473.609 513.025C473.307 513.203 473.044 513.479 472.818 513.854C472.598 514.228 472.423 514.698 472.291 515.263C472.166 515.829 472.103 516.499 472.103 517.273ZM477.072 517.311C477.072 517.203 477.069 517.095 477.062 516.987C477.056 516.873 477.053 516.765 477.053 516.664L472.47 520.016C472.671 520.632 472.953 521.09 473.317 521.388C473.681 521.687 474.104 521.836 474.587 521.836C474.939 521.836 475.265 521.744 475.566 521.56C475.873 521.375 476.137 521.099 476.357 520.731C476.582 520.356 476.758 519.886 476.884 519.321C477.009 518.75 477.072 518.08 477.072 517.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 544.413V542.346H473.853V534.345L471.124 535.869L470.371 533.973L474.277 531.887H476.206V542.346H478.907V544.413H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 565.314V563.247H473.853V555.246L471.124 556.77L470.371 554.874L474.277 552.788H476.206V563.247H478.907V565.314H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 579.938C479.387 580.96 479.277 581.874 479.058 582.681C478.838 583.487 478.515 584.17 478.088 584.729C477.668 585.281 477.153 585.704 476.545 585.996C475.936 586.288 475.24 586.434 474.456 586.434C473.778 586.434 473.154 586.313 472.583 586.072C472.012 585.831 471.519 585.453 471.105 584.938C470.691 584.418 470.368 583.754 470.136 582.948C469.904 582.141 469.788 581.176 469.788 580.052C469.788 579.03 469.898 578.115 470.117 577.309C470.343 576.502 470.666 575.823 471.086 575.27C471.507 574.712 472.024 574.286 472.639 573.994C473.254 573.702 473.951 573.556 474.728 573.556C475.406 573.556 476.03 573.676 476.601 573.918C477.172 574.159 477.662 574.537 478.069 575.051C478.483 575.566 478.807 576.226 479.039 577.032C479.271 577.839 479.387 578.807 479.387 579.938ZM472.103 579.976C472.103 580.103 472.103 580.223 472.103 580.338C472.103 580.446 472.109 580.557 472.122 580.671L476.733 577.299C476.513 576.671 476.222 576.207 475.858 575.909C475.5 575.604 475.077 575.451 474.587 575.451C474.236 575.451 473.91 575.543 473.609 575.728C473.307 575.905 473.044 576.182 472.818 576.556C472.598 576.931 472.423 577.401 472.291 577.966C472.166 578.531 472.103 579.201 472.103 579.976ZM477.072 580.014C477.072 579.906 477.069 579.798 477.062 579.69C477.056 579.576 477.053 579.468 477.053 579.366L472.47 582.719C472.671 583.335 472.953 583.792 473.317 584.091C473.681 584.389 474.104 584.538 474.587 584.538C474.939 584.538 475.265 584.446 475.566 584.262C475.873 584.078 476.137 583.802 476.357 583.433C476.582 583.059 476.758 582.589 476.884 582.024C477.009 581.452 477.072 580.782 477.072 580.014Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 607.116V605.049H473.853V597.047L471.124 598.572L470.371 596.676L474.277 594.59H476.206V605.049H478.907V607.116H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 621.739C479.387 622.762 479.277 623.676 479.058 624.483C478.838 625.289 478.515 625.972 478.088 626.53C477.668 627.083 477.153 627.505 476.545 627.797C475.936 628.089 475.24 628.235 474.456 628.235C473.778 628.235 473.154 628.115 472.583 627.874C472.012 627.632 471.519 627.254 471.105 626.74C470.691 626.219 470.368 625.556 470.136 624.749C469.904 623.943 469.788 622.978 469.788 621.854C469.788 620.831 469.898 619.917 470.117 619.11C470.343 618.304 470.666 617.624 471.086 617.072C471.507 616.513 472.024 616.088 472.639 615.796C473.254 615.504 473.951 615.357 474.728 615.357C475.406 615.357 476.03 615.478 476.601 615.719C477.172 615.961 477.662 616.339 478.069 616.853C478.483 617.367 478.807 618.028 479.039 618.834C479.271 619.641 479.387 620.609 479.387 621.739ZM472.103 621.777C472.103 621.904 472.103 622.025 472.103 622.139C472.103 622.247 472.109 622.358 472.122 622.473L476.733 619.101C476.513 618.472 476.222 618.009 475.858 617.71C475.5 617.405 475.077 617.253 474.587 617.253C474.236 617.253 473.91 617.345 473.609 617.529C473.307 617.707 473.044 617.983 472.818 618.358C472.598 618.733 472.423 619.202 472.291 619.768C472.166 620.333 472.103 621.003 472.103 621.777ZM477.072 621.816C477.072 621.708 477.069 621.6 477.062 621.492C477.056 621.377 477.053 621.269 477.053 621.168L472.47 624.521C472.671 625.137 472.953 625.594 473.317 625.892C473.681 626.191 474.104 626.34 474.587 626.34C474.939 626.34 475.265 626.248 475.566 626.064C475.873 625.88 476.137 625.603 476.357 625.235C476.582 624.86 476.758 624.39 476.884 623.825C477.009 623.254 477.072 622.584 477.072 621.816Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 642.64C479.387 643.663 479.277 644.577 479.058 645.383C478.838 646.19 478.515 646.872 478.088 647.431C477.668 647.984 477.153 648.406 476.545 648.698C475.936 648.99 475.24 649.136 474.456 649.136C473.778 649.136 473.154 649.016 472.583 648.774C472.012 648.533 471.519 648.155 471.105 647.641C470.691 647.12 470.368 646.457 470.136 645.65C469.904 644.844 469.788 643.878 469.788 642.754C469.788 641.732 469.898 640.818 470.117 640.011C470.343 639.205 470.666 638.525 471.086 637.973C471.507 637.414 472.024 636.989 472.639 636.696C473.254 636.404 473.951 636.258 474.728 636.258C475.406 636.258 476.03 636.379 476.601 636.62C477.172 636.862 477.662 637.239 478.069 637.754C478.483 638.268 478.807 638.929 479.039 639.735C479.271 640.541 479.387 641.51 479.387 642.64ZM472.103 642.678C472.103 642.805 472.103 642.926 472.103 643.04C472.103 643.148 472.109 643.259 472.122 643.374L476.733 640.002C476.513 639.373 476.222 638.909 475.858 638.611C475.5 638.306 475.077 638.154 474.587 638.154C474.236 638.154 473.91 638.246 473.609 638.43C473.307 638.608 473.044 638.884 472.818 639.259C472.598 639.633 472.423 640.103 472.291 640.668C472.166 641.234 472.103 641.904 472.103 642.678ZM477.072 642.716C477.072 642.608 477.069 642.5 477.062 642.393C477.056 642.278 477.053 642.17 477.053 642.069L472.47 645.421C472.671 646.037 472.953 646.495 473.317 646.793C473.681 647.092 474.104 647.241 474.587 647.241C474.939 647.241 475.265 647.149 475.566 646.965C475.873 646.78 476.137 646.504 476.357 646.136C476.582 645.761 476.758 645.291 476.884 644.726C477.009 644.155 477.072 643.485 477.072 642.716Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 663.541C479.387 664.563 479.277 665.478 479.058 666.284C478.838 667.091 478.515 667.773 478.088 668.332C477.668 668.885 477.153 669.307 476.545 669.599C475.936 669.891 475.24 670.037 474.456 670.037C473.778 670.037 473.154 669.916 472.583 669.675C472.012 669.434 471.519 669.056 471.105 668.542C470.691 668.021 470.368 667.357 470.136 666.551C469.904 665.744 469.788 664.779 469.788 663.655C469.788 662.633 469.898 661.719 470.117 660.912C470.343 660.106 470.666 659.426 471.086 658.874C471.507 658.315 472.024 657.889 472.639 657.597C473.254 657.305 473.951 657.159 474.728 657.159C475.406 657.159 476.03 657.28 476.601 657.521C477.172 657.762 477.662 658.14 478.069 658.655C478.483 659.169 478.807 659.829 479.039 660.636C479.271 661.442 479.387 662.411 479.387 663.541ZM472.103 663.579C472.103 663.706 472.103 663.827 472.103 663.941C472.103 664.049 472.109 664.16 472.122 664.274L476.733 660.903C476.513 660.274 476.222 659.81 475.858 659.512C475.5 659.207 475.077 659.055 474.587 659.055C474.236 659.055 473.91 659.147 473.609 659.331C473.307 659.509 473.044 659.785 472.818 660.16C472.598 660.534 472.423 661.004 472.291 661.569C472.166 662.134 472.103 662.804 472.103 663.579ZM477.072 663.617C477.072 663.509 477.069 663.401 477.062 663.293C477.056 663.179 477.053 663.071 477.053 662.969L472.47 666.322C472.671 666.938 472.953 667.396 473.317 667.694C473.681 667.992 474.104 668.142 474.587 668.142C474.939 668.142 475.265 668.05 475.566 667.865C475.873 667.681 476.137 667.405 476.357 667.037C476.582 666.662 476.758 666.192 476.884 665.627C477.009 665.055 477.072 664.386 477.072 663.617Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 690.719V688.652H473.853V680.651L471.124 682.175L470.371 680.279L474.277 678.193H476.206V688.652H478.907V690.719H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 711.62V709.553H473.853V701.552L471.124 703.076L470.371 701.18L474.277 699.094H476.206V709.553H478.907V711.62H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 726.244C479.387 727.266 479.277 728.18 479.058 728.987C478.838 729.793 478.515 730.476 478.088 731.035C477.668 731.587 477.153 732.009 476.545 732.301C475.936 732.594 475.24 732.74 474.456 732.74C473.778 732.74 473.154 732.619 472.583 732.378C472.012 732.136 471.519 731.759 471.105 731.244C470.691 730.723 470.368 730.06 470.136 729.253C469.904 728.447 469.788 727.482 469.788 726.358C469.788 725.335 469.898 724.421 470.117 723.615C470.343 722.808 470.666 722.129 471.086 721.576C471.507 721.017 472.024 720.592 472.639 720.3C473.254 720.008 473.951 719.862 474.728 719.862C475.406 719.862 476.03 719.982 476.601 720.224C477.172 720.465 477.662 720.843 478.069 721.357C478.483 721.871 478.807 722.532 479.039 723.338C479.271 724.145 479.387 725.113 479.387 726.244ZM472.103 726.282C472.103 726.409 472.103 726.529 472.103 726.644C472.103 726.752 472.109 726.863 472.122 726.977L476.733 723.605C476.513 722.976 476.222 722.513 475.858 722.214C475.5 721.91 475.077 721.757 474.587 721.757C474.236 721.757 473.91 721.849 473.609 722.033C473.307 722.211 473.044 722.487 472.818 722.862C472.598 723.237 472.423 723.707 472.291 724.272C472.166 724.837 472.103 725.507 472.103 726.282ZM477.072 726.32C477.072 726.212 477.069 726.104 477.062 725.996C477.056 725.882 477.053 725.774 477.053 725.672L472.47 729.025C472.671 729.641 472.953 730.098 473.317 730.396C473.681 730.695 474.104 730.844 474.587 730.844C474.939 730.844 475.265 730.752 475.566 730.568C475.873 730.384 476.137 730.108 476.357 729.739C476.582 729.365 476.758 728.895 476.884 728.33C477.009 727.758 477.072 727.088 477.072 726.32Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 753.421V751.354H473.853V743.353L471.124 744.877L470.371 742.982L474.277 740.896H476.206V751.354H478.907V753.421H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 774.322V772.255H473.853V764.254L471.124 765.778L470.371 763.883L474.277 761.797H476.206V772.255H478.907V774.322H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 788.946C479.387 789.968 479.277 790.883 479.058 791.689C478.838 792.496 478.515 793.178 478.088 793.737C477.668 794.29 477.153 794.712 476.545 795.004C475.936 795.296 475.24 795.442 474.456 795.442C473.778 795.442 473.154 795.322 472.583 795.08C472.012 794.839 471.519 794.461 471.105 793.947C470.691 793.426 470.368 792.762 470.136 791.956C469.904 791.15 469.788 790.184 469.788 789.06C469.788 788.038 469.898 787.124 470.117 786.317C470.343 785.511 470.666 784.831 471.086 784.279C471.507 783.72 472.024 783.294 472.639 783.002C473.254 782.71 473.951 782.564 474.728 782.564C475.406 782.564 476.03 782.685 476.601 782.926C477.172 783.167 477.662 783.545 478.069 784.06C478.483 784.574 478.807 785.234 479.039 786.041C479.271 786.847 479.387 787.816 479.387 788.946ZM472.103 788.984C472.103 789.111 472.103 789.232 472.103 789.346C472.103 789.454 472.109 789.565 472.122 789.679L476.733 786.308C476.513 785.679 476.222 785.215 475.858 784.917C475.5 784.612 475.077 784.46 474.587 784.46C474.236 784.46 473.91 784.552 473.609 784.736C473.307 784.914 473.044 785.19 472.818 785.565C472.598 785.939 472.423 786.409 472.291 786.974C472.166 787.539 472.103 788.209 472.103 788.984ZM477.072 789.022C477.072 788.914 477.069 788.806 477.062 788.698C477.056 788.584 477.053 788.476 477.053 788.375L472.47 791.727C472.671 792.343 472.953 792.801 473.317 793.099C473.681 793.397 474.104 793.547 474.587 793.547C474.939 793.547 475.265 793.455 475.566 793.27C475.873 793.086 476.137 792.81 476.357 792.442C476.582 792.067 476.758 791.597 476.884 791.032C477.009 790.461 477.072 789.791 477.072 789.022Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 816.124V814.057H473.853V806.056L471.124 807.58L470.371 805.684L474.277 803.598H476.206V814.057H478.907V816.124H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 837.025V834.958H473.853V826.957L471.124 828.481L470.371 826.585L474.277 824.499H476.206V834.958H478.907V837.025H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 857.926V855.859H473.853V847.858L471.124 849.382L470.371 847.486L474.277 845.4H476.206V855.859H478.907V857.926H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 872.549C479.387 873.572 479.277 874.486 479.058 875.293C478.838 876.099 478.515 876.782 478.088 877.341C477.668 877.893 477.153 878.315 476.545 878.607C475.936 878.899 475.24 879.046 474.456 879.046C473.778 879.046 473.154 878.925 472.583 878.684C472.012 878.442 471.519 878.064 471.105 877.55C470.691 877.029 470.368 876.366 470.136 875.559C469.904 874.753 469.788 873.788 469.788 872.664C469.788 871.641 469.898 870.727 470.117 869.92C470.343 869.114 470.666 868.435 471.086 867.882C471.507 867.323 472.024 866.898 472.639 866.606C473.254 866.314 473.951 866.168 474.728 866.168C475.406 866.168 476.03 866.288 476.601 866.53C477.172 866.771 477.662 867.149 478.069 867.663C478.483 868.177 478.807 868.838 479.039 869.644C479.271 870.451 479.387 871.419 479.387 872.549ZM472.103 872.587C472.103 872.714 472.103 872.835 472.103 872.949C472.103 873.057 472.109 873.169 472.122 873.283L476.733 869.911C476.513 869.282 476.222 868.819 475.858 868.52C475.5 868.215 475.077 868.063 474.587 868.063C474.236 868.063 473.91 868.155 473.609 868.339C473.307 868.517 473.044 868.793 472.818 869.168C472.598 869.543 472.423 870.013 472.291 870.578C472.166 871.143 472.103 871.813 472.103 872.587ZM477.072 872.626C477.072 872.518 477.069 872.41 477.062 872.302C477.056 872.187 477.053 872.079 477.053 871.978L472.47 875.331C472.671 875.947 472.953 876.404 473.317 876.702C473.681 877.001 474.104 877.15 474.587 877.15C474.939 877.15 475.265 877.058 475.566 876.874C475.873 876.69 476.137 876.413 476.357 876.045C476.582 875.67 476.758 875.201 476.884 874.635C477.009 874.064 477.072 873.394 477.072 872.626Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M479.387 893.45C479.387 894.473 479.277 895.387 479.058 896.193C478.838 897 478.515 897.683 478.088 898.241C477.668 898.794 477.153 899.216 476.545 899.508C475.936 899.8 475.24 899.946 474.456 899.946C473.778 899.946 473.154 899.826 472.583 899.584C472.012 899.343 471.519 898.965 471.105 898.451C470.691 897.93 470.368 897.267 470.136 896.46C469.904 895.654 469.788 894.688 469.788 893.565C469.788 892.542 469.898 891.628 470.117 890.821C470.343 890.015 470.666 889.335 471.086 888.783C471.507 888.224 472.024 887.799 472.639 887.507C473.254 887.214 473.951 887.068 474.728 887.068C475.406 887.068 476.03 887.189 476.601 887.43C477.172 887.672 477.662 888.049 478.069 888.564C478.483 889.078 478.807 889.739 479.039 890.545C479.271 891.352 479.387 892.32 479.387 893.45ZM472.103 893.488C472.103 893.615 472.103 893.736 472.103 893.85C472.103 893.958 472.109 894.069 472.122 894.184L476.733 890.812C476.513 890.183 476.222 889.72 475.858 889.421C475.5 889.116 475.077 888.964 474.587 888.964C474.236 888.964 473.91 889.056 473.609 889.24C473.307 889.418 473.044 889.694 472.818 890.069C472.598 890.443 472.423 890.913 472.291 891.479C472.166 892.044 472.103 892.714 472.103 893.488ZM477.072 893.526C477.072 893.418 477.069 893.311 477.062 893.203C477.056 893.088 477.053 892.98 477.053 892.879L472.47 896.232C472.671 896.848 472.953 897.305 473.317 897.603C473.681 897.902 474.104 898.051 474.587 898.051C474.939 898.051 475.265 897.959 475.566 897.775C475.873 897.59 476.137 897.314 476.357 896.946C476.582 896.571 476.758 896.101 476.884 895.536C477.009 894.965 477.072 894.295 477.072 893.526Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M470.719 920.628V918.561H473.853V910.56L471.124 912.084L470.371 910.189L474.277 908.103H476.206V918.561H478.907V920.628H470.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M656.811 582.568C656.811 583.883 656.67 585.058 656.388 586.095C656.105 587.132 655.69 588.01 655.141 588.728C654.601 589.438 653.939 589.981 653.157 590.357C652.374 590.732 651.479 590.92 650.471 590.92C649.599 590.92 648.797 590.765 648.063 590.455C647.329 590.145 646.695 589.659 646.163 588.997C645.631 588.328 645.215 587.475 644.917 586.438C644.618 585.401 644.469 584.16 644.469 582.715C644.469 581.401 644.61 580.225 644.893 579.188C645.183 578.151 645.598 577.278 646.139 576.567C646.679 575.849 647.345 575.302 648.135 574.926C648.926 574.551 649.821 574.363 650.822 574.363C651.693 574.363 652.495 574.518 653.229 574.828C653.963 575.138 654.593 575.624 655.117 576.286C655.649 576.947 656.065 577.796 656.363 578.833C656.662 579.87 656.811 581.115 656.811 582.568ZM647.446 582.617C647.446 582.78 647.446 582.935 647.446 583.082C647.446 583.221 647.454 583.364 647.47 583.511L653.399 579.176C653.116 578.367 652.741 577.771 652.274 577.388C651.814 576.996 651.269 576.8 650.64 576.8C650.188 576.8 649.769 576.918 649.382 577.155C648.994 577.384 648.656 577.739 648.365 578.22C648.083 578.702 647.857 579.306 647.688 580.033C647.526 580.76 647.446 581.621 647.446 582.617ZM653.834 582.666C653.834 582.527 653.83 582.388 653.822 582.25C653.814 582.103 653.81 581.964 653.81 581.833L647.918 586.144C648.176 586.936 648.539 587.524 649.007 587.908C649.474 588.291 650.019 588.483 650.64 588.483C651.092 588.483 651.511 588.365 651.898 588.128C652.294 587.891 652.632 587.536 652.915 587.063C653.205 586.581 653.431 585.977 653.592 585.25C653.754 584.515 653.834 583.654 653.834 582.666Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 617.113V614.455H649.696V604.168L646.187 606.128L645.219 603.691L650.241 601.009H652.721V614.455H656.194V617.113H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 643.587V640.93H649.696V630.643L646.187 632.602L645.219 630.165L650.241 627.483H652.721V640.93H656.194V643.587H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 661.991C656.811 663.306 656.67 664.481 656.388 665.518C656.105 666.555 655.69 667.433 655.141 668.151C654.601 668.862 653.939 669.404 653.157 669.78C652.374 670.156 651.479 670.343 650.471 670.343C649.599 670.343 648.797 670.188 648.063 669.878C647.329 669.568 646.695 669.082 646.163 668.421C645.631 667.751 645.215 666.898 644.917 665.861C644.618 664.824 644.469 663.583 644.469 662.138C644.469 660.824 644.61 659.648 644.893 658.611C645.183 657.574 645.598 656.701 646.139 655.99C646.679 655.272 647.345 654.725 648.135 654.349C648.926 653.974 649.821 653.786 650.822 653.786C651.693 653.786 652.495 653.941 653.229 654.251C653.963 654.562 654.593 655.047 655.117 655.709C655.649 656.37 656.065 657.219 656.363 658.256C656.662 659.293 656.811 660.538 656.811 661.991ZM647.446 662.04C647.446 662.203 647.446 662.359 647.446 662.506C647.446 662.644 647.454 662.787 647.47 662.934L653.399 658.599C653.116 657.791 652.741 657.195 652.274 656.811C651.814 656.419 651.269 656.223 650.64 656.223C650.188 656.223 649.769 656.341 649.382 656.578C648.994 656.807 648.656 657.162 648.365 657.644C648.083 658.125 647.857 658.73 647.688 659.456C647.526 660.183 647.446 661.044 647.446 662.04ZM653.834 662.089C653.834 661.95 653.83 661.812 653.822 661.673C653.814 661.526 653.81 661.387 653.81 661.256L647.918 665.567C648.176 666.359 648.539 666.947 649.007 667.331C649.474 667.714 650.019 667.906 650.64 667.906C651.092 667.906 651.511 667.788 651.898 667.551C652.294 667.314 652.632 666.959 652.915 666.486C653.205 666.004 653.431 665.4 653.592 664.673C653.754 663.938 653.834 663.077 653.834 662.089Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 696.536V693.879H649.696V683.591L646.187 685.551L645.219 683.114L650.241 680.432H652.721V693.879H656.194V696.536H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 714.94C656.811 716.254 656.67 717.43 656.388 718.467C656.105 719.504 655.69 720.382 655.141 721.1C654.601 721.81 653.939 722.353 653.157 722.729C652.374 723.104 651.479 723.292 650.471 723.292C649.599 723.292 648.797 723.137 648.063 722.827C647.329 722.517 646.695 722.031 646.163 721.369C645.631 720.7 645.215 719.847 644.917 718.81C644.618 717.773 644.469 716.532 644.469 715.087C644.469 713.772 644.61 712.597 644.893 711.56C645.183 710.523 645.598 709.649 646.139 708.939C646.679 708.221 647.345 707.674 648.135 707.298C648.926 706.923 649.821 706.735 650.822 706.735C651.693 706.735 652.495 706.89 653.229 707.2C653.963 707.51 654.593 707.996 655.117 708.658C655.649 709.319 656.065 710.168 656.363 711.205C656.662 712.242 656.811 713.487 656.811 714.94ZM647.446 714.989C647.446 715.152 647.446 715.307 647.446 715.454C647.446 715.593 647.454 715.736 647.47 715.883L653.399 711.548C653.116 710.739 652.741 710.143 652.274 709.76C651.814 709.368 651.269 709.172 650.64 709.172C650.188 709.172 649.769 709.29 649.382 709.527C648.994 709.756 648.656 710.111 648.365 710.592C648.083 711.074 647.857 711.678 647.688 712.405C647.526 713.132 647.446 713.993 647.446 714.989ZM653.834 715.038C653.834 714.899 653.83 714.76 653.822 714.622C653.814 714.475 653.81 714.336 653.81 714.205L647.918 718.516C648.176 719.308 648.539 719.896 649.007 720.28C649.474 720.663 650.019 720.855 650.64 720.855C651.092 720.855 651.511 720.737 651.898 720.5C652.294 720.263 652.632 719.908 652.915 719.434C653.205 718.953 653.431 718.349 653.592 717.622C653.754 716.887 653.834 716.026 653.834 715.038Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 741.414C656.811 742.729 656.67 743.905 656.388 744.941C656.105 745.978 655.69 746.856 655.141 747.574C654.601 748.285 653.939 748.828 653.157 749.203C652.374 749.579 651.479 749.767 650.471 749.767C649.599 749.767 648.797 749.611 648.063 749.301C647.329 748.991 646.695 748.505 646.163 747.844C645.631 747.174 645.215 746.321 644.917 745.284C644.618 744.247 644.469 743.006 644.469 741.561C644.469 740.247 644.61 739.071 644.893 738.034C645.183 736.997 645.598 736.124 646.139 735.414C646.679 734.695 647.345 734.148 648.135 733.773C648.926 733.397 649.821 733.209 650.822 733.209C651.693 733.209 652.495 733.364 653.229 733.675C653.963 733.985 654.593 734.471 655.117 735.132C655.649 735.793 656.065 736.642 656.363 737.679C656.662 738.716 656.811 739.961 656.811 741.414ZM647.446 741.463C647.446 741.627 647.446 741.782 647.446 741.929C647.446 742.068 647.454 742.21 647.47 742.357L653.399 738.022C653.116 737.214 652.741 736.618 652.274 736.234C651.814 735.842 651.269 735.646 650.64 735.646C650.188 735.646 649.769 735.765 649.382 736.001C648.994 736.23 648.656 736.585 648.365 737.067C648.083 737.549 647.857 738.153 647.688 738.879C647.526 739.606 647.446 740.467 647.446 741.463ZM653.834 741.512C653.834 741.374 653.83 741.235 653.822 741.096C653.814 740.949 653.81 740.81 653.81 740.68L647.918 744.99C648.176 745.782 648.539 746.37 649.007 746.754C649.474 747.138 650.019 747.329 650.64 747.329C651.092 747.329 651.511 747.211 651.898 746.974C652.294 746.738 652.632 746.382 652.915 745.909C653.205 745.427 653.431 744.823 653.592 744.096C653.754 743.362 653.834 742.5 653.834 741.512Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 767.889C656.811 769.203 656.67 770.379 656.388 771.416C656.105 772.453 655.69 773.33 655.141 774.049C654.601 774.759 653.939 775.302 653.157 775.678C652.374 776.053 651.479 776.241 650.471 776.241C649.599 776.241 648.797 776.086 648.063 775.776C647.329 775.465 646.695 774.98 646.163 774.318C645.631 773.649 645.215 772.796 644.917 771.759C644.618 770.722 644.469 769.481 644.469 768.036C644.469 766.721 644.61 765.546 644.893 764.509C645.183 763.472 645.598 762.598 646.139 761.888C646.679 761.169 647.345 760.622 648.135 760.247C648.926 759.871 649.821 759.684 650.822 759.684C651.693 759.684 652.495 759.839 653.229 760.149C653.963 760.459 654.593 760.945 655.117 761.606C655.649 762.268 656.065 763.117 656.363 764.154C656.662 765.19 656.811 766.436 656.811 767.889ZM647.446 767.938C647.446 768.101 647.446 768.256 647.446 768.403C647.446 768.542 647.454 768.685 647.47 768.832L653.399 764.496C653.116 763.688 652.741 763.092 652.274 762.708C651.814 762.317 651.269 762.121 650.64 762.121C650.188 762.121 649.769 762.239 649.382 762.476C648.994 762.704 648.656 763.06 648.365 763.541C648.083 764.023 647.857 764.627 647.688 765.354C647.526 766.08 647.446 766.942 647.446 767.938ZM653.834 767.987C653.834 767.848 653.83 767.709 653.822 767.57C653.814 767.423 653.81 767.285 653.81 767.154L647.918 771.465C648.176 772.257 648.539 772.845 649.007 773.228C649.474 773.612 650.019 773.804 650.64 773.804C651.092 773.804 651.511 773.686 651.898 773.449C652.294 773.212 652.632 772.857 652.915 772.383C653.205 771.902 653.431 771.297 653.592 770.571C653.754 769.836 653.834 768.975 653.834 767.987Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 802.434V799.776H649.696V789.489L646.187 791.448L645.219 789.011L650.241 786.329H652.721V799.776H656.194V802.434H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 828.908V826.251H649.696V815.963L646.187 817.923L645.219 815.486L650.241 812.804H652.721V826.251H656.194V828.908H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 847.312C656.811 848.626 656.67 849.802 656.388 850.839C656.105 851.876 655.69 852.754 655.141 853.472C654.601 854.182 653.939 854.725 653.157 855.101C652.374 855.476 651.479 855.664 650.471 855.664C649.599 855.664 648.797 855.509 648.063 855.199C647.329 854.889 646.695 854.403 646.163 853.741C645.631 853.072 645.215 852.219 644.917 851.182C644.618 850.145 644.469 848.904 644.469 847.459C644.469 846.144 644.61 844.969 644.893 843.932C645.183 842.895 645.598 842.021 646.139 841.311C646.679 840.593 647.345 840.046 648.135 839.67C648.926 839.295 649.821 839.107 650.822 839.107C651.693 839.107 652.495 839.262 653.229 839.572C653.963 839.882 654.593 840.368 655.117 841.029C655.649 841.691 656.065 842.54 656.363 843.577C656.662 844.614 656.811 845.859 656.811 847.312ZM647.446 847.361C647.446 847.524 647.446 847.679 647.446 847.826C647.446 847.965 647.454 848.108 647.47 848.255L653.399 843.92C653.116 843.111 652.741 842.515 652.274 842.132C651.814 841.74 651.269 841.544 650.64 841.544C650.188 841.544 649.769 841.662 649.382 841.899C648.994 842.128 648.656 842.483 648.365 842.964C648.083 843.446 647.857 844.05 647.688 844.777C647.526 845.504 647.446 846.365 647.446 847.361ZM653.834 847.41C653.834 847.271 653.83 847.132 653.822 846.994C653.814 846.847 653.81 846.708 653.81 846.577L647.918 850.888C648.176 851.68 648.539 852.268 649.007 852.651C649.474 853.035 650.019 853.227 650.64 853.227C651.092 853.227 651.511 853.109 651.898 852.872C652.294 852.635 652.632 852.28 652.915 851.806C653.205 851.325 653.431 850.721 653.592 849.994C653.754 849.259 653.834 848.398 653.834 847.41Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 881.857V879.199H649.696V868.912L646.187 870.872L645.219 868.435L650.241 865.753H652.721V879.199H656.194V881.857H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 908.331V905.674H649.696V895.387L646.187 897.346L645.219 894.909L650.241 892.227H652.721V905.674H656.194V908.331H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 926.735C656.811 928.05 656.67 929.225 656.388 930.262C656.105 931.299 655.69 932.177 655.141 932.895C654.601 933.605 653.939 934.148 653.157 934.524C652.374 934.9 651.479 935.087 650.471 935.087C649.599 935.087 648.797 934.932 648.063 934.622C647.329 934.312 646.695 933.826 646.163 933.165C645.631 932.495 645.215 931.642 644.917 930.605C644.618 929.568 644.469 928.327 644.469 926.882C644.469 925.568 644.61 924.392 644.893 923.355C645.183 922.318 645.598 921.445 646.139 920.734C646.679 920.016 647.345 919.469 648.135 919.093C648.926 918.718 649.821 918.53 650.822 918.53C651.693 918.53 652.495 918.685 653.229 918.995C653.963 919.306 654.593 919.791 655.117 920.453C655.649 921.114 656.065 921.963 656.363 923C656.662 924.037 656.811 925.282 656.811 926.735ZM647.446 926.784C647.446 926.947 647.446 927.103 647.446 927.25C647.446 927.388 647.454 927.531 647.47 927.678L653.399 923.343C653.116 922.535 652.741 921.939 652.274 921.555C651.814 921.163 651.269 920.967 650.64 920.967C650.188 920.967 649.769 921.085 649.382 921.322C648.994 921.551 648.656 921.906 648.365 922.388C648.083 922.869 647.857 923.473 647.688 924.2C647.526 924.927 647.446 925.788 647.446 926.784ZM653.834 926.833C653.834 926.694 653.83 926.556 653.822 926.417C653.814 926.27 653.81 926.131 653.81 926L647.918 930.311C648.176 931.103 648.539 931.691 649.007 932.075C649.474 932.458 650.019 932.65 650.64 932.65C651.092 932.65 651.511 932.532 651.898 932.295C652.294 932.058 652.632 931.703 652.915 931.23C653.205 930.748 653.431 930.144 653.592 929.417C653.754 928.682 653.834 927.821 653.834 926.833Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 961.28V958.623H649.696V948.335L646.187 950.295L645.219 947.858L650.241 945.176H652.721V958.623H656.194V961.28H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 987.754V985.097H649.696V974.81L646.187 976.769L645.219 974.332L650.241 971.65H652.721V985.097H656.194V987.754H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 1014.23V1011.57H649.696V1001.28L646.187 1003.24L645.219 1000.81L650.241 998.125H652.721V1011.57H656.194V1014.23H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 1032.63C656.811 1033.95 656.67 1035.12 656.388 1036.16C656.105 1037.2 655.69 1038.07 655.141 1038.79C654.601 1039.5 653.939 1040.05 653.157 1040.42C652.374 1040.8 651.479 1040.98 650.471 1040.98C649.599 1040.98 648.797 1040.83 648.063 1040.52C647.329 1040.21 646.695 1039.72 646.163 1039.06C645.631 1038.39 645.215 1037.54 644.917 1036.5C644.618 1035.47 644.469 1034.22 644.469 1032.78C644.469 1031.47 644.61 1030.29 644.893 1029.25C645.183 1028.22 645.598 1027.34 646.139 1026.63C646.679 1025.91 647.345 1025.37 648.135 1024.99C648.926 1024.62 649.821 1024.43 650.822 1024.43C651.693 1024.43 652.495 1024.58 653.229 1024.89C653.963 1025.2 654.593 1025.69 655.117 1026.35C655.649 1027.01 656.065 1027.86 656.363 1028.9C656.662 1029.93 656.811 1031.18 656.811 1032.63ZM647.446 1032.68C647.446 1032.85 647.446 1033 647.446 1033.15C647.446 1033.29 647.454 1033.43 647.47 1033.58L653.399 1029.24C653.116 1028.43 652.741 1027.84 652.274 1027.45C651.814 1027.06 651.269 1026.86 650.64 1026.86C650.188 1026.86 649.769 1026.98 649.382 1027.22C648.994 1027.45 648.656 1027.8 648.365 1028.29C648.083 1028.77 647.857 1029.37 647.688 1030.1C647.526 1030.82 647.446 1031.69 647.446 1032.68ZM653.834 1032.73C653.834 1032.59 653.83 1032.45 653.822 1032.31C653.814 1032.17 653.81 1032.03 653.81 1031.9L647.918 1036.21C648.176 1037 648.539 1037.59 649.007 1037.97C649.474 1038.36 650.019 1038.55 650.64 1038.55C651.092 1038.55 651.511 1038.43 651.898 1038.19C652.294 1037.96 652.632 1037.6 652.915 1037.13C653.205 1036.65 653.431 1036.04 653.592 1035.31C653.754 1034.58 653.834 1033.72 653.834 1032.73Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M656.811 1059.11C656.811 1060.42 656.67 1061.6 656.388 1062.63C656.105 1063.67 655.69 1064.55 655.141 1065.27C654.601 1065.98 653.939 1066.52 653.157 1066.9C652.374 1067.27 651.479 1067.46 650.471 1067.46C649.599 1067.46 648.797 1067.3 648.063 1066.99C647.329 1066.68 646.695 1066.2 646.163 1065.54C645.631 1064.87 645.215 1064.01 644.917 1062.98C644.618 1061.94 644.469 1060.7 644.469 1059.25C644.469 1057.94 644.61 1056.76 644.893 1055.73C645.183 1054.69 645.598 1053.82 646.139 1053.11C646.679 1052.39 647.345 1051.84 648.135 1051.47C648.926 1051.09 649.821 1050.9 650.822 1050.9C651.693 1050.9 652.495 1051.06 653.229 1051.37C653.963 1051.68 654.593 1052.16 655.117 1052.82C655.649 1053.49 656.065 1054.34 656.363 1055.37C656.662 1056.41 656.811 1057.65 656.811 1059.11ZM647.446 1059.16C647.446 1059.32 647.446 1059.47 647.446 1059.62C647.446 1059.76 647.454 1059.9 647.47 1060.05L653.399 1055.71C653.116 1054.91 652.741 1054.31 652.274 1053.93C651.814 1053.53 651.269 1053.34 650.64 1053.34C650.188 1053.34 649.769 1053.46 649.382 1053.69C648.994 1053.92 648.656 1054.28 648.365 1054.76C648.083 1055.24 647.857 1055.85 647.688 1056.57C647.526 1057.3 647.446 1058.16 647.446 1059.16ZM653.834 1059.21C653.834 1059.07 653.83 1058.93 653.822 1058.79C653.814 1058.64 653.81 1058.5 653.81 1058.37L647.918 1062.68C648.176 1063.48 648.539 1064.06 649.007 1064.45C649.474 1064.83 650.019 1065.02 650.64 1065.02C651.092 1065.02 651.511 1064.9 651.898 1064.67C652.294 1064.43 652.632 1064.08 652.915 1063.6C653.205 1063.12 653.431 1062.52 653.592 1061.79C653.754 1061.05 653.834 1060.19 653.834 1059.21Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M645.667 1093.65V1090.99H649.696V1080.71L646.187 1082.67L645.219 1080.23L650.241 1077.55H652.721V1090.99H656.194V1093.65H645.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M690.474 579.285C690.474 580.453 690.349 581.498 690.098 582.42C689.847 583.341 689.477 584.122 688.99 584.76C688.509 585.392 687.922 585.874 687.226 586.208C686.53 586.542 685.735 586.709 684.838 586.709C684.064 586.709 683.35 586.571 682.698 586.295C682.045 586.019 681.483 585.588 681.009 585C680.536 584.405 680.167 583.646 679.901 582.725C679.636 581.803 679.503 580.7 679.503 579.415C679.503 578.247 679.629 577.202 679.88 576.28C680.138 575.358 680.507 574.582 680.988 573.951C681.468 573.312 682.06 572.826 682.762 572.492C683.465 572.158 684.261 571.991 685.15 571.991C685.925 571.991 686.638 572.129 687.291 572.405C687.943 572.681 688.502 573.112 688.968 573.7C689.442 574.288 689.811 575.043 690.076 575.964C690.342 576.886 690.474 577.993 690.474 579.285ZM682.149 579.328C682.149 579.473 682.149 579.611 682.149 579.742C682.149 579.865 682.157 579.992 682.171 580.123L687.441 576.269C687.19 575.551 686.857 575.021 686.441 574.68C686.032 574.332 685.548 574.157 684.989 574.157C684.587 574.157 684.214 574.263 683.87 574.473C683.526 574.676 683.225 574.992 682.967 575.42C682.716 575.848 682.515 576.385 682.364 577.031C682.221 577.677 682.149 578.443 682.149 579.328ZM687.828 579.372C687.828 579.248 687.825 579.125 687.818 579.002C687.81 578.871 687.807 578.748 687.807 578.631L682.569 582.463C682.798 583.167 683.121 583.69 683.537 584.031C683.953 584.372 684.437 584.542 684.989 584.542C685.39 584.542 685.763 584.437 686.107 584.227C686.459 584.016 686.76 583.701 687.011 583.28C687.269 582.852 687.47 582.315 687.613 581.669C687.757 581.015 687.828 580.25 687.828 579.372Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 610.146V607.784H684.15V598.64L681.031 600.381L680.17 598.215L684.634 595.831H686.839V607.784H689.926V610.146H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 633.834V631.471H684.15V622.327L681.031 624.069L680.17 621.903L684.634 619.519H686.839V631.471H689.926V633.834H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M690.474 650.347C690.474 651.516 690.349 652.561 690.098 653.483C689.847 654.404 689.477 655.184 688.99 655.823C688.509 656.454 687.922 656.937 687.226 657.271C686.53 657.605 685.735 657.772 684.838 657.772C684.064 657.772 683.35 657.634 682.698 657.358C682.045 657.082 681.483 656.65 681.009 656.063C680.536 655.467 680.167 654.709 679.901 653.787C679.636 652.866 679.503 651.763 679.503 650.478C679.503 649.31 679.629 648.265 679.88 647.343C680.138 646.421 680.507 645.645 680.988 645.013C681.468 644.375 682.06 643.889 682.762 643.555C683.465 643.221 684.261 643.054 685.15 643.054C685.925 643.054 686.638 643.192 687.291 643.468C687.943 643.743 688.502 644.175 688.968 644.763C689.442 645.351 689.811 646.106 690.076 647.027C690.342 647.949 690.474 649.056 690.474 650.347ZM682.149 650.391C682.149 650.536 682.149 650.674 682.149 650.805C682.149 650.928 682.157 651.055 682.171 651.186L687.441 647.332C687.19 646.614 686.857 646.084 686.441 645.743C686.032 645.394 685.548 645.22 684.989 645.22C684.587 645.22 684.214 645.325 683.87 645.536C683.526 645.739 683.225 646.055 682.967 646.483C682.716 646.911 682.515 647.448 682.364 648.094C682.221 648.74 682.149 649.506 682.149 650.391ZM687.828 650.435C687.828 650.311 687.825 650.188 687.818 650.064C687.81 649.934 687.807 649.81 687.807 649.694L682.569 653.526C682.798 654.23 683.121 654.753 683.537 655.094C683.953 655.435 684.437 655.605 684.989 655.605C685.39 655.605 685.763 655.5 686.107 655.29C686.459 655.079 686.76 654.763 687.011 654.343C687.269 653.914 687.47 653.377 687.613 652.731C687.757 652.078 687.828 651.313 687.828 650.435Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 681.209V678.847H684.15V669.702L681.031 671.444L680.17 669.278L684.634 666.894H686.839V678.847H689.926V681.209H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M690.474 697.723C690.474 698.891 690.349 699.936 690.098 700.858C689.847 701.779 689.477 702.56 688.99 703.198C688.509 703.83 687.922 704.312 687.226 704.646C686.53 704.98 685.735 705.147 684.838 705.147C684.064 705.147 683.35 705.009 682.698 704.733C682.045 704.457 681.483 704.026 681.009 703.438C680.536 702.843 680.167 702.084 679.901 701.163C679.636 700.241 679.503 699.138 679.503 697.853C679.503 696.685 679.629 695.64 679.88 694.718C680.138 693.797 680.507 693.02 680.988 692.389C681.468 691.75 682.06 691.264 682.762 690.93C683.465 690.596 684.261 690.429 685.15 690.429C685.925 690.429 686.638 690.567 687.291 690.843C687.943 691.119 688.502 691.55 688.968 692.138C689.442 692.726 689.811 693.481 690.076 694.402C690.342 695.324 690.474 696.431 690.474 697.723ZM682.149 697.766C682.149 697.911 682.149 698.049 682.149 698.18C682.149 698.303 682.157 698.43 682.171 698.561L687.441 694.707C687.19 693.989 686.857 693.459 686.441 693.118C686.032 692.77 685.548 692.595 684.989 692.595C684.587 692.595 684.214 692.701 683.87 692.911C683.526 693.114 683.225 693.43 682.967 693.858C682.716 694.286 682.515 694.823 682.364 695.469C682.221 696.115 682.149 696.881 682.149 697.766ZM687.828 697.81C687.828 697.686 687.825 697.563 687.818 697.44C687.81 697.309 687.807 697.186 687.807 697.07L682.569 700.901C682.798 701.605 683.121 702.128 683.537 702.469C683.953 702.81 684.437 702.981 684.989 702.981C685.39 702.981 685.763 702.875 686.107 702.665C686.459 702.454 686.76 702.139 687.011 701.718C687.269 701.29 687.47 700.753 687.613 700.107C687.757 699.454 687.828 698.688 687.828 697.81Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 775.959V773.597H684.15V764.453L681.031 766.195L680.17 764.028L684.634 761.644H686.839V773.597H689.926V775.959H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 799.647V797.285H684.15V788.141L681.031 789.882L680.17 787.716L684.634 785.332H686.839V797.285H689.926V799.647H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M690.474 816.161C690.474 817.329 690.349 818.374 690.098 819.296C689.847 820.218 689.477 820.998 688.99 821.636C688.509 822.268 687.922 822.75 687.226 823.084C686.53 823.418 685.735 823.585 684.838 823.585C684.064 823.585 683.35 823.447 682.698 823.171C682.045 822.895 681.483 822.464 681.009 821.876C680.536 821.281 680.167 820.522 679.901 819.601C679.636 818.679 679.503 817.576 679.503 816.291C679.503 815.123 679.629 814.078 679.88 813.156C680.138 812.235 680.507 811.458 680.988 810.827C681.468 810.188 682.06 809.702 682.762 809.368C683.465 809.034 684.261 808.867 685.15 808.867C685.925 808.867 686.638 809.005 687.291 809.281C687.943 809.557 688.502 809.988 688.968 810.576C689.442 811.164 689.811 811.919 690.076 812.841C690.342 813.762 690.474 814.869 690.474 816.161ZM682.149 816.204C682.149 816.349 682.149 816.487 682.149 816.618C682.149 816.741 682.157 816.868 682.171 816.999L687.441 813.145C687.19 812.427 686.857 811.897 686.441 811.556C686.032 811.208 685.548 811.034 684.989 811.034C684.587 811.034 684.214 811.139 683.87 811.349C683.526 811.552 683.225 811.868 682.967 812.296C682.716 812.724 682.515 813.261 682.364 813.907C682.221 814.553 682.149 815.319 682.149 816.204ZM687.828 816.248C687.828 816.124 687.825 816.001 687.818 815.878C687.81 815.747 687.807 815.624 687.807 815.508L682.569 819.339C682.798 820.043 683.121 820.566 683.537 820.907C683.953 821.248 684.437 821.419 684.989 821.419C685.39 821.419 685.763 821.313 686.107 821.103C686.459 820.892 686.76 820.577 687.011 820.156C687.269 819.728 687.47 819.191 687.613 818.545C687.757 817.892 687.828 817.126 687.828 816.248Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 847.022V844.66H684.15V835.516L681.031 837.258L680.17 835.091L684.634 832.707H686.839V844.66H689.926V847.022H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 870.71V868.348H684.15V859.203L681.031 860.945L680.17 858.779L684.634 856.395H686.839V868.348H689.926V870.71H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M690.474 887.224C690.474 888.392 690.349 889.437 690.098 890.359C689.847 891.28 689.477 892.061 688.99 892.699C688.509 893.331 687.922 893.813 687.226 894.147C686.53 894.481 685.735 894.648 684.838 894.648C684.064 894.648 683.35 894.51 682.698 894.234C682.045 893.958 681.483 893.527 681.009 892.939C680.536 892.344 680.167 891.585 679.901 890.664C679.636 889.742 679.503 888.639 679.503 887.354C679.503 886.186 679.629 885.141 679.88 884.219C680.138 883.297 680.507 882.521 680.988 881.89C681.468 881.251 682.06 880.765 682.762 880.431C683.465 880.097 684.261 879.93 685.15 879.93C685.925 879.93 686.638 880.068 687.291 880.344C687.943 880.62 688.502 881.051 688.968 881.639C689.442 882.227 689.811 882.982 690.076 883.903C690.342 884.825 690.474 885.932 690.474 887.224ZM682.149 887.267C682.149 887.412 682.149 887.55 682.149 887.681C682.149 887.804 682.157 887.931 682.171 888.062L687.441 884.208C687.19 883.49 686.857 882.96 686.441 882.619C686.032 882.271 685.548 882.096 684.989 882.096C684.587 882.096 684.214 882.202 683.87 882.412C683.526 882.615 683.225 882.931 682.967 883.359C682.716 883.787 682.515 884.324 682.364 884.97C682.221 885.616 682.149 886.382 682.149 887.267ZM687.828 887.311C687.828 887.187 687.825 887.064 687.818 886.941C687.81 886.81 687.807 886.687 687.807 886.57L682.569 890.402C682.798 891.106 683.121 891.629 683.537 891.97C683.953 892.311 684.437 892.481 684.989 892.481C685.39 892.481 685.763 892.376 686.107 892.166C686.459 891.955 686.76 891.64 687.011 891.219C687.269 890.791 687.47 890.254 687.613 889.608C687.757 888.954 687.828 888.189 687.828 887.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 918.085V915.723H684.15V906.579L681.031 908.32L680.17 906.154L684.634 903.77H686.839V915.723H689.926V918.085H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 941.773V939.41H684.15V930.266L681.031 932.008L680.17 929.842L684.634 927.458H686.839V939.41H689.926V941.773H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 965.46V963.098H684.15V953.954L681.031 955.696L680.17 953.529L684.634 951.145H686.839V963.098H689.926V965.46H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M690.474 981.974C690.474 983.142 690.349 984.188 690.098 985.109C689.847 986.031 689.477 986.811 688.99 987.45C688.509 988.081 687.922 988.564 687.226 988.897C686.53 989.231 685.735 989.398 684.838 989.398C684.064 989.398 683.35 989.26 682.698 988.985C682.045 988.709 681.483 988.277 681.009 987.689C680.536 987.094 680.167 986.336 679.901 985.414C679.636 984.492 679.503 983.389 679.503 982.105C679.503 980.936 679.629 979.891 679.88 978.97C680.138 978.048 680.507 977.271 680.988 976.64C681.468 976.001 682.06 975.515 682.762 975.181C683.465 974.847 684.261 974.681 685.15 974.681C685.925 974.681 686.638 974.818 687.291 975.094C687.943 975.37 688.502 975.802 688.968 976.39C689.442 976.977 689.811 977.732 690.076 978.654C690.342 979.576 690.474 980.682 690.474 981.974ZM682.149 982.018C682.149 982.163 682.149 982.301 682.149 982.431C682.149 982.555 682.157 982.682 682.171 982.812L687.441 978.959C687.19 978.24 686.857 977.71 686.441 977.369C686.032 977.021 685.548 976.847 684.989 976.847C684.587 976.847 684.214 976.952 683.87 977.163C683.526 977.366 683.225 977.681 682.967 978.11C682.716 978.538 682.515 979.075 682.364 979.721C682.221 980.367 682.149 981.132 682.149 982.018ZM687.828 982.061C687.828 981.938 687.825 981.814 687.818 981.691C687.81 981.56 687.807 981.437 687.807 981.321L682.569 985.153C682.798 985.857 683.121 986.379 683.537 986.72C683.953 987.061 684.437 987.232 684.989 987.232C685.39 987.232 685.763 987.127 686.107 986.916C686.459 986.706 686.76 986.39 687.011 985.969C687.269 985.541 687.47 985.004 687.613 984.358C687.757 983.705 687.828 982.939 687.828 982.061Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M690.474 1005.66C690.474 1006.83 690.349 1007.88 690.098 1008.8C689.847 1009.72 689.477 1010.5 688.99 1011.14C688.509 1011.77 687.922 1012.25 687.226 1012.59C686.53 1012.92 685.735 1013.09 684.838 1013.09C684.064 1013.09 683.35 1012.95 682.698 1012.67C682.045 1012.4 681.483 1011.96 681.009 1011.38C680.536 1010.78 680.167 1010.02 679.901 1009.1C679.636 1008.18 679.503 1007.08 679.503 1005.79C679.503 1004.62 679.629 1003.58 679.88 1002.66C680.138 1001.74 680.507 1000.96 680.988 1000.33C681.468 999.689 682.06 999.203 682.762 998.869C683.465 998.535 684.261 998.368 685.15 998.368C685.925 998.368 686.638 998.506 687.291 998.782C687.943 999.058 688.502 999.489 688.968 1000.08C689.442 1000.67 689.811 1001.42 690.076 1002.34C690.342 1003.26 690.474 1004.37 690.474 1005.66ZM682.149 1005.71C682.149 1005.85 682.149 1005.99 682.149 1006.12C682.149 1006.24 682.157 1006.37 682.171 1006.5L687.441 1002.65C687.19 1001.93 686.857 1001.4 686.441 1001.06C686.032 1000.71 685.548 1000.53 684.989 1000.53C684.587 1000.53 684.214 1000.64 683.87 1000.85C683.526 1001.05 683.225 1001.37 682.967 1001.8C682.716 1002.23 682.515 1002.76 682.364 1003.41C682.221 1004.05 682.149 1004.82 682.149 1005.71ZM687.828 1005.75C687.828 1005.63 687.825 1005.5 687.818 1005.38C687.81 1005.25 687.807 1005.12 687.807 1005.01L682.569 1008.84C682.798 1009.54 683.121 1010.07 683.537 1010.41C683.953 1010.75 684.437 1010.92 684.989 1010.92C685.39 1010.92 685.763 1010.81 686.107 1010.6C686.459 1010.39 686.76 1010.08 687.011 1009.66C687.269 1009.23 687.47 1008.69 687.613 1008.05C687.757 1007.39 687.828 1006.63 687.828 1005.75Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M680.568 1036.52V1034.16H684.15V1025.02L681.031 1026.76L680.17 1024.59L684.634 1022.21H686.839V1034.16H689.926V1036.52H680.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M727.443 334.952H716.406V332.747H720.923V319.766L716.715 322.079L715.854 320.065L721.447 317.085H723.518V332.747H727.443V334.952Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 364.91H716.406V362.705H720.923V349.724L716.715 352.037L715.854 350.023L721.447 347.043H723.518V362.705H727.443V364.91Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 385.982C727.995 387.343 727.86 388.585 727.591 389.71C727.322 390.826 726.915 391.788 726.368 392.595C725.821 393.393 725.14 394.015 724.324 394.459C723.518 394.895 722.568 395.112 721.474 395.112C720.533 395.112 719.677 394.935 718.906 394.582C718.135 394.219 717.477 393.665 716.93 392.922C716.383 392.178 715.962 391.23 715.666 390.078C715.37 388.926 715.223 387.56 715.223 385.982C715.223 384.621 715.357 383.378 715.626 382.253C715.904 381.129 716.312 380.167 716.849 379.369C717.396 378.561 718.077 377.94 718.893 377.504C719.708 377.06 720.659 376.838 721.743 376.838C722.684 376.838 723.54 377.019 724.311 377.382C725.082 377.736 725.74 378.289 726.287 379.042C726.834 379.786 727.255 380.734 727.551 381.886C727.847 383.038 727.995 384.403 727.995 385.982ZM725.628 386.063C725.628 385.755 725.615 385.451 725.588 385.152C725.57 384.843 725.548 384.544 725.521 384.254L718.086 389.846C718.221 390.318 718.391 390.753 718.597 391.153C718.803 391.552 719.05 391.896 719.336 392.187C719.632 392.468 719.964 392.69 720.331 392.854C720.708 393.008 721.134 393.085 721.609 393.085C722.218 393.085 722.769 392.935 723.262 392.636C723.764 392.336 724.19 391.892 724.539 391.302C724.889 390.713 725.158 389.982 725.346 389.111C725.534 388.232 725.628 387.216 725.628 386.063ZM717.589 385.9C717.589 386.181 717.589 386.463 717.589 386.744C717.598 387.025 717.616 387.297 717.643 387.56L725.077 381.995C724.943 381.532 724.772 381.11 724.566 380.729C724.36 380.348 724.114 380.022 723.827 379.75C723.54 379.468 723.213 379.251 722.845 379.097C722.478 378.942 722.066 378.865 721.609 378.865C720.999 378.865 720.443 379.015 719.941 379.314C719.449 379.614 719.027 380.063 718.678 380.661C718.328 381.251 718.059 381.986 717.871 382.866C717.683 383.737 717.589 384.748 717.589 385.9Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 415.94C727.995 417.3 727.86 418.543 727.591 419.668C727.322 420.784 726.915 421.745 726.368 422.553C725.821 423.351 725.14 423.973 724.324 424.417C723.518 424.852 722.568 425.07 721.474 425.07C720.533 425.07 719.677 424.893 718.906 424.54C718.135 424.177 717.477 423.623 716.93 422.879C716.383 422.136 715.962 421.188 715.666 420.035C715.37 418.883 715.223 417.518 715.223 415.94C715.223 414.579 715.357 413.336 715.626 412.211C715.904 411.086 716.312 410.125 716.849 409.327C717.396 408.519 718.077 407.898 718.893 407.462C719.708 407.018 720.659 406.796 721.743 406.796C722.684 406.796 723.54 406.977 724.311 407.34C725.082 407.694 725.74 408.247 726.287 409C726.834 409.744 727.255 410.692 727.551 411.844C727.847 412.996 727.995 414.361 727.995 415.94ZM725.628 416.021C725.628 415.713 725.615 415.409 725.588 415.11C725.57 414.801 725.548 414.502 725.521 414.212L718.086 419.804C718.221 420.276 718.391 420.711 718.597 421.11C718.803 421.51 719.05 421.854 719.336 422.145C719.632 422.426 719.964 422.648 720.331 422.811C720.708 422.966 721.134 423.043 721.609 423.043C722.218 423.043 722.769 422.893 723.262 422.594C723.764 422.294 724.19 421.85 724.539 421.26C724.889 420.67 725.158 419.94 725.346 419.069C725.534 418.189 725.628 417.173 725.628 416.021ZM717.589 415.858C717.589 416.139 717.589 416.42 717.589 416.702C717.598 416.983 717.616 417.255 717.643 417.518L725.077 411.953C724.943 411.49 724.772 411.068 724.566 410.687C724.36 410.306 724.114 409.98 723.827 409.708C723.54 409.426 723.213 409.209 722.845 409.054C722.478 408.9 722.066 408.823 721.609 408.823C720.999 408.823 720.443 408.973 719.941 409.272C719.449 409.571 719.027 410.021 718.678 410.619C718.328 411.209 718.059 411.944 717.871 412.824C717.683 413.694 717.589 414.706 717.589 415.858Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 445.898C727.995 447.258 727.86 448.501 727.591 449.626C727.322 450.742 726.915 451.703 726.368 452.511C725.821 453.309 725.14 453.93 724.324 454.375C723.518 454.81 722.568 455.028 721.474 455.028C720.533 455.028 719.677 454.851 718.906 454.497C718.135 454.135 717.477 453.581 716.93 452.837C716.383 452.093 715.962 451.145 715.666 449.993C715.37 448.841 715.223 447.476 715.223 445.898C715.223 444.537 715.357 443.294 715.626 442.169C715.904 441.044 716.312 440.083 716.849 439.284C717.396 438.477 718.077 437.856 718.893 437.42C719.708 436.976 720.659 436.753 721.743 436.753C722.684 436.753 723.54 436.935 724.311 437.298C725.082 437.652 725.74 438.205 726.287 438.958C726.834 439.702 727.255 440.65 727.551 441.802C727.847 442.954 727.995 444.319 727.995 445.898ZM725.628 445.979C725.628 445.671 725.615 445.367 725.588 445.068C725.57 444.759 725.548 444.46 725.521 444.169L718.086 449.762C718.221 450.234 718.391 450.669 718.597 451.068C718.803 451.467 719.05 451.812 719.336 452.102C719.632 452.384 719.964 452.606 720.331 452.769C720.708 452.923 721.134 453.001 721.609 453.001C722.218 453.001 722.769 452.851 723.262 452.552C723.764 452.252 724.19 451.808 724.539 451.218C724.889 450.628 725.158 449.898 725.346 449.027C725.534 448.147 725.628 447.131 725.628 445.979ZM717.589 445.816C717.589 446.097 717.589 446.378 717.589 446.66C717.598 446.941 717.616 447.213 717.643 447.476L725.077 441.911C724.943 441.448 724.772 441.026 724.566 440.645C724.36 440.264 724.114 439.938 723.827 439.665C723.54 439.384 723.213 439.166 722.845 439.012C722.478 438.858 722.066 438.781 721.609 438.781C720.999 438.781 720.443 438.931 719.941 439.23C719.449 439.529 719.027 439.978 718.678 440.577C718.328 441.167 718.059 441.902 717.871 442.781C717.683 443.652 717.589 444.664 717.589 445.816Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 484.741H716.406V482.537H720.923V469.555L716.715 471.868L715.854 469.855L721.447 466.875H723.518V482.537H727.443V484.741Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 505.813C727.995 507.174 727.86 508.417 727.591 509.542C727.322 510.658 726.915 511.619 726.368 512.426C725.821 513.225 725.14 513.846 724.324 514.291C723.518 514.726 722.568 514.944 721.474 514.944C720.533 514.944 719.677 514.767 718.906 514.413C718.135 514.05 717.477 513.497 716.93 512.753C716.383 512.009 715.962 511.061 715.666 509.909C715.37 508.757 715.223 507.392 715.223 505.813C715.223 504.453 715.357 503.21 715.626 502.085C715.904 500.96 716.312 499.998 716.849 499.2C717.396 498.393 718.077 497.771 718.893 497.336C719.708 496.891 720.659 496.669 721.743 496.669C722.684 496.669 723.54 496.851 724.311 497.213C725.082 497.567 725.74 498.121 726.287 498.874C726.834 499.617 727.255 500.565 727.551 501.717C727.847 502.87 727.995 504.235 727.995 505.813ZM725.628 505.895C725.628 505.587 725.615 505.283 725.588 504.983C725.57 504.675 725.548 504.375 725.521 504.085L718.086 509.678C718.221 510.149 718.391 510.585 718.597 510.984C718.803 511.383 719.05 511.728 719.336 512.018C719.632 512.299 719.964 512.522 720.331 512.685C720.708 512.839 721.134 512.916 721.609 512.916C722.218 512.916 722.769 512.767 723.262 512.467C723.764 512.168 724.19 511.723 724.539 511.134C724.889 510.544 725.158 509.814 725.346 508.943C725.534 508.063 725.628 507.047 725.628 505.895ZM717.589 505.732C717.589 506.013 717.589 506.294 717.589 506.575C717.598 506.857 717.616 507.129 717.643 507.392L725.077 501.826C724.943 501.364 724.772 500.942 724.566 500.561C724.36 500.18 724.114 499.853 723.827 499.581C723.54 499.3 723.213 499.082 722.845 498.928C722.478 498.774 722.066 498.697 721.609 498.697C720.999 498.697 720.443 498.846 719.941 499.146C719.449 499.445 719.027 499.894 718.678 500.493C718.328 501.082 718.059 501.817 717.871 502.697C717.683 503.568 717.589 504.58 717.589 505.732Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 544.657H716.406V542.452H720.923V529.471L716.715 531.784L715.854 529.77L721.447 526.79H723.518V542.452H727.443V544.657Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 574.615H716.406V572.41H720.923V559.429L716.715 561.742L715.854 559.728L721.447 556.748H723.518V572.41H727.443V574.615Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 595.687C727.995 597.048 727.86 598.29 727.591 599.415C727.322 600.531 726.915 601.493 726.368 602.3C725.821 603.098 725.14 603.72 724.324 604.164C723.518 604.6 722.568 604.817 721.474 604.817C720.533 604.817 719.677 604.641 718.906 604.287C718.135 603.924 717.477 603.37 716.93 602.627C716.383 601.883 715.962 600.935 715.666 599.783C715.37 598.631 715.223 597.265 715.223 595.687C715.223 594.326 715.357 593.083 715.626 591.958C715.904 590.834 716.312 589.872 716.849 589.074C717.396 588.266 718.077 587.645 718.893 587.21C719.708 586.765 720.659 586.543 721.743 586.543C722.684 586.543 723.54 586.724 724.311 587.087C725.082 587.441 725.74 587.994 726.287 588.747C726.834 589.491 727.255 590.439 727.551 591.591C727.847 592.743 727.995 594.108 727.995 595.687ZM725.628 595.769C725.628 595.46 725.615 595.156 725.588 594.857C725.57 594.548 725.548 594.249 725.521 593.959L718.086 599.551C718.221 600.023 718.391 600.459 718.597 600.858C718.803 601.257 719.05 601.602 719.336 601.892C719.632 602.173 719.964 602.395 720.331 602.559C720.708 602.713 721.134 602.79 721.609 602.79C722.218 602.79 722.769 602.64 723.262 602.341C723.764 602.042 724.19 601.597 724.539 601.007C724.889 600.418 725.158 599.687 725.346 598.817C725.534 597.937 725.628 596.921 725.628 595.769ZM717.589 595.605C717.589 595.886 717.589 596.168 717.589 596.449C717.598 596.73 717.616 597.002 717.643 597.265L725.077 591.7C724.943 591.237 724.772 590.815 724.566 590.434C724.36 590.053 724.114 589.727 723.827 589.455C723.54 589.174 723.213 588.956 722.845 588.802C722.478 588.647 722.066 588.57 721.609 588.57C720.999 588.57 720.443 588.72 719.941 589.019C719.449 589.319 719.027 589.768 718.678 590.366C718.328 590.956 718.059 591.691 717.871 592.571C717.683 593.442 717.589 594.453 717.589 595.605Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 625.645C727.995 627.006 727.86 628.248 727.591 629.373C727.322 630.489 726.915 631.451 726.368 632.258C725.821 633.056 725.14 633.678 724.324 634.122C723.518 634.558 722.568 634.775 721.474 634.775C720.533 634.775 719.677 634.598 718.906 634.245C718.135 633.882 717.477 633.328 716.93 632.584C716.383 631.841 715.962 630.893 715.666 629.741C715.37 628.588 715.223 627.223 715.223 625.645C715.223 624.284 715.357 623.041 715.626 621.916C715.904 620.791 716.312 619.83 716.849 619.032C717.396 618.224 718.077 617.603 718.893 617.167C719.708 616.723 720.659 616.501 721.743 616.501C722.684 616.501 723.54 616.682 724.311 617.045C725.082 617.399 725.74 617.952 726.287 618.705C726.834 619.449 727.255 620.397 727.551 621.549C727.847 622.701 727.995 624.066 727.995 625.645ZM725.628 625.726C725.628 625.418 725.615 625.114 725.588 624.815C725.57 624.506 725.548 624.207 725.521 623.917L718.086 629.509C718.221 629.981 718.391 630.416 718.597 630.816C718.803 631.215 719.05 631.559 719.336 631.85C719.632 632.131 719.964 632.353 720.331 632.516C720.708 632.671 721.134 632.748 721.609 632.748C722.218 632.748 722.769 632.598 723.262 632.299C723.764 631.999 724.19 631.555 724.539 630.965C724.889 630.376 725.158 629.645 725.346 628.774C725.534 627.895 725.628 626.878 725.628 625.726ZM717.589 625.563C717.589 625.844 717.589 626.126 717.589 626.407C717.598 626.688 717.616 626.96 717.643 627.223L725.077 621.658C724.943 621.195 724.772 620.773 724.566 620.392C724.36 620.011 724.114 619.685 723.827 619.413C723.54 619.131 723.213 618.914 722.845 618.759C722.478 618.605 722.066 618.528 721.609 618.528C720.999 618.528 720.443 618.678 719.941 618.977C719.449 619.277 719.027 619.726 718.678 620.324C718.328 620.914 718.059 621.649 717.871 622.529C717.683 623.4 717.589 624.411 717.589 625.563Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 655.603C727.995 656.963 727.86 658.206 727.591 659.331C727.322 660.447 726.915 661.408 726.368 662.216C725.821 663.014 725.14 663.635 724.324 664.08C723.518 664.515 722.568 664.733 721.474 664.733C720.533 664.733 719.677 664.556 718.906 664.202C718.135 663.84 717.477 663.286 716.93 662.542C716.383 661.799 715.962 660.851 715.666 659.698C715.37 658.546 715.223 657.181 715.223 655.603C715.223 654.242 715.357 652.999 715.626 651.874C715.904 650.749 716.312 649.788 716.849 648.989C717.396 648.182 718.077 647.561 718.893 647.125C719.708 646.681 720.659 646.459 721.743 646.459C722.684 646.459 723.54 646.64 724.311 647.003C725.082 647.357 725.74 647.91 726.287 648.663C726.834 649.407 727.255 650.355 727.551 651.507C727.847 652.659 727.995 654.024 727.995 655.603ZM725.628 655.684C725.628 655.376 725.615 655.072 725.588 654.773C725.57 654.464 725.548 654.165 725.521 653.874L718.086 659.467C718.221 659.939 718.391 660.374 718.597 660.773C718.803 661.173 719.05 661.517 719.336 661.808C719.632 662.089 719.964 662.311 720.331 662.474C720.708 662.629 721.134 662.706 721.609 662.706C722.218 662.706 722.769 662.556 723.262 662.257C723.764 661.957 724.19 661.513 724.539 660.923C724.889 660.333 725.158 659.603 725.346 658.732C725.534 657.852 725.628 656.836 725.628 655.684ZM717.589 655.521C717.589 655.802 717.589 656.083 717.589 656.365C717.598 656.646 717.616 656.918 717.643 657.181L725.077 651.616C724.943 651.153 724.772 650.731 724.566 650.35C724.36 649.969 724.114 649.643 723.827 649.37C723.54 649.089 723.213 648.872 722.845 648.717C722.478 648.563 722.066 648.486 721.609 648.486C720.999 648.486 720.443 648.636 719.941 648.935C719.449 649.234 719.027 649.683 718.678 650.282C718.328 650.872 718.059 651.607 717.871 652.487C717.683 653.357 717.589 654.369 717.589 655.521Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 694.446H716.406V692.242H720.923V679.26L716.715 681.574L715.854 679.56L721.447 676.58H723.518V692.242H727.443V694.446Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 724.404H716.406V722.2H720.923V709.218L716.715 711.531L715.854 709.518L721.447 706.538H723.518V722.2H727.443V724.404Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 754.362H716.406V752.157H720.923V739.176L716.715 741.489L715.854 739.475L721.447 736.495H723.518V752.157H727.443V754.362Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 775.434C727.995 776.795 727.86 778.038 727.591 779.163C727.322 780.278 726.915 781.24 726.368 782.047C725.821 782.846 725.14 783.467 724.324 783.911C723.518 784.347 722.568 784.565 721.474 784.565C720.533 784.565 719.677 784.388 718.906 784.034C718.135 783.671 717.477 783.118 716.93 782.374C716.383 781.63 715.962 780.682 715.666 779.53C715.37 778.378 715.223 777.013 715.223 775.434C715.223 774.073 715.357 772.831 715.626 771.706C715.904 770.581 716.312 769.619 716.849 768.821C717.396 768.014 718.077 767.392 718.893 766.957C719.708 766.512 720.659 766.29 721.743 766.29C722.684 766.29 723.54 766.471 724.311 766.834C725.082 767.188 725.74 767.741 726.287 768.494C726.834 769.238 727.255 770.186 727.551 771.338C727.847 772.49 727.995 773.856 727.995 775.434ZM725.628 775.516C725.628 775.207 725.615 774.903 725.588 774.604C725.57 774.296 725.548 773.996 725.521 773.706L718.086 779.299C718.221 779.77 718.391 780.206 718.597 780.605C718.803 781.004 719.05 781.349 719.336 781.639C719.632 781.92 719.964 782.143 720.331 782.306C720.708 782.46 721.134 782.537 721.609 782.537C722.218 782.537 722.769 782.387 723.262 782.088C723.764 781.789 724.19 781.344 724.539 780.755C724.889 780.165 725.158 779.435 725.346 778.564C725.534 777.684 725.628 776.668 725.628 775.516ZM717.589 775.352C717.589 775.634 717.589 775.915 717.589 776.196C717.598 776.477 717.616 776.749 717.643 777.013L725.077 771.447C724.943 770.985 724.772 770.563 724.566 770.182C724.36 769.801 724.114 769.474 723.827 769.202C723.54 768.921 723.213 768.703 722.845 768.549C722.478 768.395 722.066 768.317 721.609 768.317C720.999 768.317 720.443 768.467 719.941 768.767C719.449 769.066 719.027 769.515 718.678 770.114C718.328 770.703 718.059 771.438 717.871 772.318C717.683 773.189 717.589 774.2 717.589 775.352Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 814.278H716.406V812.073H720.923V799.092L716.715 801.405L715.854 799.391L721.447 796.411H723.518V812.073H727.443V814.278Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 835.35C727.995 836.711 727.86 837.953 727.591 839.078C727.322 840.194 726.915 841.156 726.368 841.963C725.821 842.761 725.14 843.383 724.324 843.827C723.518 844.263 722.568 844.48 721.474 844.48C720.533 844.48 719.677 844.303 718.906 843.95C718.135 843.587 717.477 843.033 716.93 842.29C716.383 841.546 715.962 840.598 715.666 839.446C715.37 838.294 715.223 836.928 715.223 835.35C715.223 833.989 715.357 832.746 715.626 831.621C715.904 830.497 716.312 829.535 716.849 828.737C717.396 827.929 718.077 827.308 718.893 826.872C719.708 826.428 720.659 826.206 721.743 826.206C722.684 826.206 723.54 826.387 724.311 826.75C725.082 827.104 725.74 827.657 726.287 828.41C726.834 829.154 727.255 830.102 727.551 831.254C727.847 832.406 727.995 833.771 727.995 835.35ZM725.628 835.432C725.628 835.123 725.615 834.819 725.588 834.52C725.57 834.211 725.548 833.912 725.521 833.622L718.086 839.214C718.221 839.686 718.391 840.121 718.597 840.521C718.803 840.92 719.05 841.265 719.336 841.555C719.632 841.836 719.964 842.058 720.331 842.222C720.708 842.376 721.134 842.453 721.609 842.453C722.218 842.453 722.769 842.303 723.262 842.004C723.764 841.704 724.19 841.26 724.539 840.67C724.889 840.081 725.158 839.35 725.346 838.48C725.534 837.6 725.628 836.584 725.628 835.432ZM717.589 835.268C717.589 835.549 717.589 835.831 717.589 836.112C717.598 836.393 717.616 836.665 717.643 836.928L725.077 831.363C724.943 830.9 724.772 830.478 724.566 830.097C724.36 829.716 724.114 829.39 723.827 829.118C723.54 828.836 723.213 828.619 722.845 828.465C722.478 828.31 722.066 828.233 721.609 828.233C720.999 828.233 720.443 828.383 719.941 828.682C719.449 828.982 719.027 829.431 718.678 830.029C718.328 830.619 718.059 831.354 717.871 832.234C717.683 833.105 717.589 834.116 717.589 835.268Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.995 865.308C727.995 866.668 727.86 867.911 727.591 869.036C727.322 870.152 726.915 871.114 726.368 871.921C725.821 872.719 725.14 873.341 724.324 873.785C723.518 874.221 722.568 874.438 721.474 874.438C720.533 874.438 719.677 874.261 718.906 873.908C718.135 873.545 717.477 872.991 716.93 872.247C716.383 871.504 715.962 870.556 715.666 869.403C715.37 868.251 715.223 866.886 715.223 865.308C715.223 863.947 715.357 862.704 715.626 861.579C715.904 860.454 716.312 859.493 716.849 858.695C717.396 857.887 718.077 857.266 718.893 856.83C719.708 856.386 720.659 856.164 721.743 856.164C722.684 856.164 723.54 856.345 724.311 856.708C725.082 857.062 725.74 857.615 726.287 858.368C726.834 859.112 727.255 860.06 727.551 861.212C727.847 862.364 727.995 863.729 727.995 865.308ZM725.628 865.389C725.628 865.081 725.615 864.777 725.588 864.478C725.57 864.169 725.548 863.87 725.521 863.58L718.086 869.172C718.221 869.644 718.391 870.079 718.597 870.479C718.803 870.878 719.05 871.222 719.336 871.513C719.632 871.794 719.964 872.016 720.331 872.179C720.708 872.334 721.134 872.411 721.609 872.411C722.218 872.411 722.769 872.261 723.262 871.962C723.764 871.662 724.19 871.218 724.539 870.628C724.889 870.038 725.158 869.308 725.346 868.437C725.534 867.557 725.628 866.541 725.628 865.389ZM717.589 865.226C717.589 865.507 717.589 865.788 717.589 866.07C717.598 866.351 717.616 866.623 717.643 866.886L725.077 861.321C724.943 860.858 724.772 860.436 724.566 860.055C724.36 859.674 724.114 859.348 723.827 859.076C723.54 858.794 723.213 858.577 722.845 858.422C722.478 858.268 722.066 858.191 721.609 858.191C720.999 858.191 720.443 858.341 719.941 858.64C719.449 858.939 719.027 859.389 718.678 859.987C718.328 860.577 718.059 861.312 717.871 862.192C717.683 863.062 717.589 864.074 717.589 865.226Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M727.443 904.151H716.406V901.947H720.923V888.965L716.715 891.279L715.854 889.265L721.447 886.285H723.518V901.947H727.443V904.151Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 573.221H846.149V571.347H849.989V560.313L846.412 562.28L845.681 560.568L850.435 558.035H852.195V571.347H855.531V573.221Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 598.302H846.149V596.428H849.989V585.394L846.412 587.361L845.681 585.649L850.435 583.116H852.195V596.428H855.531V598.302Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 615.83C856 616.987 855.886 618.043 855.657 619C855.429 619.948 855.082 620.765 854.617 621.452C854.153 622.13 853.574 622.658 852.88 623.036C852.195 623.406 851.387 623.591 850.458 623.591C849.658 623.591 848.93 623.441 848.275 623.14C847.62 622.832 847.06 622.362 846.595 621.729C846.13 621.097 845.772 620.291 845.521 619.312C845.269 618.333 845.144 617.172 845.144 615.83C845.144 614.674 845.258 613.617 845.486 612.661C845.723 611.705 846.069 610.888 846.526 610.209C846.991 609.523 847.57 608.995 848.263 608.625C848.957 608.247 849.764 608.058 850.686 608.058C851.486 608.058 852.214 608.212 852.869 608.521C853.524 608.821 854.084 609.292 854.549 609.932C855.013 610.564 855.371 611.37 855.623 612.349C855.874 613.328 856 614.489 856 615.83ZM853.989 615.9C853.989 615.638 853.977 615.379 853.954 615.125C853.939 614.863 853.92 614.608 853.897 614.362L847.578 619.115C847.692 619.516 847.837 619.886 848.012 620.226C848.187 620.565 848.397 620.858 848.641 621.105C848.892 621.344 849.174 621.533 849.486 621.671C849.806 621.802 850.168 621.868 850.572 621.868C851.09 621.868 851.558 621.741 851.977 621.486C852.404 621.232 852.766 620.854 853.063 620.353C853.36 619.852 853.589 619.231 853.749 618.491C853.909 617.743 853.989 616.879 853.989 615.9ZM847.155 615.761C847.155 616 847.155 616.239 847.155 616.478C847.163 616.717 847.178 616.949 847.201 617.172L853.52 612.442C853.406 612.048 853.261 611.69 853.086 611.366C852.911 611.042 852.701 610.764 852.457 610.533C852.214 610.294 851.936 610.109 851.623 609.978C851.311 609.847 850.96 609.781 850.572 609.781C850.054 609.781 849.581 609.909 849.155 610.163C848.736 610.417 848.378 610.799 848.081 611.308C847.783 611.809 847.555 612.434 847.395 613.182C847.235 613.922 847.155 614.782 847.155 615.761Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 640.911C856 642.068 855.886 643.124 855.657 644.081C855.429 645.029 855.082 645.846 854.617 646.533C854.153 647.211 853.574 647.739 852.88 648.117C852.195 648.487 851.387 648.672 850.458 648.672C849.658 648.672 848.93 648.522 848.275 648.221C847.62 647.913 847.06 647.443 846.595 646.81C846.13 646.178 845.772 645.372 845.521 644.393C845.269 643.414 845.144 642.253 845.144 640.911C845.144 639.755 845.258 638.698 845.486 637.742C845.723 636.786 846.069 635.969 846.526 635.29C846.991 634.604 847.57 634.076 848.263 633.706C848.957 633.328 849.764 633.139 850.686 633.139C851.486 633.139 852.214 633.293 852.869 633.602C853.524 633.902 854.084 634.373 854.549 635.013C855.013 635.645 855.371 636.451 855.623 637.43C855.874 638.409 856 639.57 856 640.911ZM853.989 640.981C853.989 640.719 853.977 640.46 853.954 640.206C853.939 639.944 853.92 639.689 853.897 639.443L847.578 644.196C847.692 644.597 847.837 644.967 848.012 645.307C848.187 645.646 848.397 645.939 848.641 646.186C848.892 646.425 849.174 646.614 849.486 646.752C849.806 646.883 850.168 646.949 850.572 646.949C851.09 646.949 851.558 646.822 851.977 646.567C852.404 646.313 852.766 645.935 853.063 645.434C853.36 644.933 853.589 644.312 853.749 643.572C853.909 642.824 853.989 641.96 853.989 640.981ZM847.155 640.842C847.155 641.081 847.155 641.32 847.155 641.559C847.163 641.798 847.178 642.03 847.201 642.253L853.52 637.523C853.406 637.129 853.261 636.771 853.086 636.447C852.911 636.123 852.701 635.845 852.457 635.614C852.214 635.375 851.936 635.19 851.623 635.059C851.311 634.928 850.96 634.862 850.572 634.862C850.054 634.862 849.581 634.99 849.155 635.244C848.736 635.498 848.378 635.88 848.081 636.389C847.783 636.89 847.555 637.515 847.395 638.263C847.235 639.003 847.155 639.863 847.155 640.842Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 665.992C856 667.149 855.886 668.205 855.657 669.162C855.429 670.11 855.082 670.927 854.617 671.614C854.153 672.292 853.574 672.82 852.88 673.198C852.195 673.568 851.387 673.753 850.458 673.753C849.658 673.753 848.93 673.603 848.275 673.302C847.62 672.994 847.06 672.524 846.595 671.891C846.13 671.259 845.772 670.453 845.521 669.474C845.269 668.495 845.144 667.334 845.144 665.992C845.144 664.836 845.258 663.779 845.486 662.823C845.723 661.867 846.069 661.05 846.526 660.371C846.991 659.685 847.57 659.157 848.263 658.787C848.957 658.409 849.764 658.22 850.686 658.22C851.486 658.22 852.214 658.374 852.869 658.683C853.524 658.983 854.084 659.454 854.549 660.094C855.013 660.726 855.371 661.532 855.623 662.511C855.874 663.49 856 664.651 856 665.992ZM853.989 666.062C853.989 665.8 853.977 665.541 853.954 665.287C853.939 665.025 853.92 664.77 853.897 664.524L847.578 669.277C847.692 669.678 847.837 670.048 848.012 670.388C848.187 670.727 848.397 671.02 848.641 671.267C848.892 671.506 849.174 671.695 849.486 671.833C849.806 671.964 850.168 672.03 850.572 672.03C851.09 672.03 851.558 671.903 851.977 671.648C852.404 671.394 852.766 671.016 853.063 670.515C853.36 670.014 853.589 669.393 853.749 668.653C853.909 667.905 853.989 667.041 853.989 666.062ZM847.155 665.923C847.155 666.162 847.155 666.401 847.155 666.64C847.163 666.879 847.178 667.111 847.201 667.334L853.52 662.604C853.406 662.21 853.261 661.852 853.086 661.528C852.911 661.204 852.701 660.926 852.457 660.695C852.214 660.456 851.936 660.271 851.623 660.14C851.311 660.009 850.96 659.943 850.572 659.943C850.054 659.943 849.581 660.071 849.155 660.325C848.736 660.579 848.378 660.961 848.081 661.47C847.783 661.971 847.555 662.596 847.395 663.344C847.235 664.084 847.155 664.944 847.155 665.923Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 698.626H846.149V696.752H849.989V685.718L846.412 687.685L845.681 685.973L850.435 683.44H852.195V696.752H855.531V698.626Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 716.154C856 717.311 855.886 718.367 855.657 719.324C855.429 720.272 855.082 721.089 854.617 721.776C854.153 722.454 853.574 722.982 852.88 723.36C852.195 723.73 851.387 723.915 850.458 723.915C849.658 723.915 848.93 723.765 848.275 723.464C847.62 723.156 847.06 722.686 846.595 722.053C846.13 721.421 845.772 720.615 845.521 719.636C845.269 718.657 845.144 717.496 845.144 716.154C845.144 714.998 845.258 713.941 845.486 712.985C845.723 712.029 846.069 711.212 846.526 710.533C846.991 709.847 847.57 709.319 848.263 708.949C848.957 708.571 849.764 708.382 850.686 708.382C851.486 708.382 852.214 708.536 852.869 708.845C853.524 709.145 854.084 709.616 854.549 710.256C855.013 710.888 855.371 711.694 855.623 712.673C855.874 713.652 856 714.813 856 716.154ZM853.989 716.224C853.989 715.962 853.977 715.703 853.954 715.449C853.939 715.187 853.92 714.932 853.897 714.686L847.578 719.439C847.692 719.84 847.837 720.21 848.012 720.55C848.187 720.889 848.397 721.182 848.641 721.429C848.892 721.668 849.174 721.857 849.486 721.995C849.806 722.127 850.168 722.192 850.572 722.192C851.09 722.192 851.558 722.065 851.977 721.81C852.404 721.556 852.766 721.178 853.063 720.677C853.36 720.176 853.589 719.555 853.749 718.815C853.909 718.067 853.989 717.203 853.989 716.224ZM847.155 716.085C847.155 716.324 847.155 716.563 847.155 716.802C847.163 717.041 847.178 717.273 847.201 717.496L853.52 712.766C853.406 712.372 853.261 712.014 853.086 711.69C852.911 711.366 852.701 711.088 852.457 710.857C852.214 710.618 851.936 710.433 851.623 710.302C851.311 710.171 850.96 710.105 850.572 710.105C850.054 710.105 849.581 710.233 849.155 710.487C848.736 710.741 848.378 711.123 848.081 711.632C847.783 712.133 847.555 712.758 847.395 713.506C847.235 714.246 847.155 715.106 847.155 716.085Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 748.788H846.149V746.915H849.989V735.88L846.412 737.847L845.681 736.135L850.435 733.602H852.195V746.915H855.531V748.788Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 773.869H846.149V771.996H849.989V760.961L846.412 762.928L845.681 761.216L850.435 758.683H852.195V771.996H855.531V773.869Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 791.398C856 792.554 855.886 793.611 855.657 794.567C855.429 795.515 855.082 796.332 854.617 797.019C854.153 797.697 853.574 798.225 852.88 798.603C852.195 798.973 851.387 799.158 850.458 799.158C849.658 799.158 848.93 799.008 848.275 798.707C847.62 798.399 847.06 797.929 846.595 797.296C846.13 796.664 845.772 795.858 845.521 794.879C845.269 793.9 845.144 792.739 845.144 791.398C845.144 790.241 845.258 789.185 845.486 788.228C845.723 787.272 846.069 786.455 846.526 785.776C846.991 785.09 847.57 784.562 848.263 784.192C848.957 783.814 849.764 783.625 850.686 783.625C851.486 783.625 852.214 783.779 852.869 784.088C853.524 784.388 854.084 784.859 854.549 785.499C855.013 786.131 855.371 786.937 855.623 787.916C855.874 788.895 856 790.056 856 791.398ZM853.989 791.467C853.989 791.205 853.977 790.946 853.954 790.692C853.939 790.43 853.92 790.175 853.897 789.929L847.578 794.682C847.692 795.083 847.837 795.453 848.012 795.793C848.187 796.132 848.397 796.425 848.641 796.672C848.892 796.911 849.174 797.1 849.486 797.238C849.806 797.37 850.168 797.435 850.572 797.435C851.09 797.435 851.558 797.308 851.977 797.053C852.404 796.799 852.766 796.421 853.063 795.92C853.36 795.419 853.589 794.798 853.749 794.058C853.909 793.31 853.989 792.446 853.989 791.467ZM847.155 791.328C847.155 791.567 847.155 791.806 847.155 792.045C847.163 792.284 847.178 792.516 847.201 792.739L853.52 788.009C853.406 787.615 853.261 787.257 853.086 786.933C852.911 786.609 852.701 786.331 852.457 786.1C852.214 785.861 851.936 785.676 851.623 785.545C851.311 785.414 850.96 785.348 850.572 785.348C850.054 785.348 849.581 785.476 849.155 785.73C848.736 785.985 848.378 786.366 848.081 786.875C847.783 787.376 847.555 788.001 847.395 788.749C847.235 789.489 847.155 790.349 847.155 791.328Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 816.479C856 817.635 855.886 818.692 855.657 819.648C855.429 820.596 855.082 821.413 854.617 822.1C854.153 822.778 853.574 823.306 852.88 823.684C852.195 824.054 851.387 824.239 850.458 824.239C849.658 824.239 848.93 824.089 848.275 823.788C847.62 823.48 847.06 823.01 846.595 822.377C846.13 821.745 845.772 820.939 845.521 819.96C845.269 818.981 845.144 817.82 845.144 816.479C845.144 815.322 845.258 814.266 845.486 813.309C845.723 812.353 846.069 811.536 846.526 810.857C846.991 810.171 847.57 809.643 848.263 809.273C848.957 808.895 849.764 808.706 850.686 808.706C851.486 808.706 852.214 808.86 852.869 809.169C853.524 809.469 854.084 809.94 854.549 810.58C855.013 811.212 855.371 812.018 855.623 812.997C855.874 813.976 856 815.137 856 816.479ZM853.989 816.548C853.989 816.286 853.977 816.027 853.954 815.773C853.939 815.511 853.92 815.256 853.897 815.01L847.578 819.763C847.692 820.164 847.837 820.534 848.012 820.874C848.187 821.213 848.397 821.506 848.641 821.753C848.892 821.992 849.174 822.181 849.486 822.319C849.806 822.451 850.168 822.516 850.572 822.516C851.09 822.516 851.558 822.389 851.977 822.134C852.404 821.88 852.766 821.502 853.063 821.001C853.36 820.5 853.589 819.879 853.749 819.139C853.909 818.391 853.989 817.527 853.989 816.548ZM847.155 816.409C847.155 816.648 847.155 816.887 847.155 817.126C847.163 817.365 847.178 817.597 847.201 817.82L853.52 813.09C853.406 812.696 853.261 812.338 853.086 812.014C852.911 811.69 852.701 811.413 852.457 811.181C852.214 810.942 851.936 810.757 851.623 810.626C851.311 810.495 850.96 810.429 850.572 810.429C850.054 810.429 849.581 810.557 849.155 810.811C848.736 811.066 848.378 811.447 848.081 811.956C847.783 812.457 847.555 813.082 847.395 813.83C847.235 814.57 847.155 815.43 847.155 816.409Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 841.56C856 842.716 855.886 843.773 855.657 844.729C855.429 845.677 855.082 846.494 854.617 847.181C854.153 847.859 853.574 848.387 852.88 848.765C852.195 849.135 851.387 849.32 850.458 849.32C849.658 849.32 848.93 849.17 848.275 848.869C847.62 848.561 847.06 848.091 846.595 847.458C846.13 846.826 845.772 846.02 845.521 845.041C845.269 844.062 845.144 842.901 845.144 841.56C845.144 840.403 845.258 839.347 845.486 838.39C845.723 837.434 846.069 836.617 846.526 835.938C846.991 835.252 847.57 834.724 848.263 834.354C848.957 833.976 849.764 833.787 850.686 833.787C851.486 833.787 852.214 833.941 852.869 834.25C853.524 834.55 854.084 835.021 854.549 835.661C855.013 836.293 855.371 837.099 855.623 838.078C855.874 839.057 856 840.218 856 841.56ZM853.989 841.629C853.989 841.367 853.977 841.108 853.954 840.854C853.939 840.592 853.92 840.337 853.897 840.091L847.578 844.844C847.692 845.245 847.837 845.615 848.012 845.955C848.187 846.294 848.397 846.587 848.641 846.834C848.892 847.073 849.174 847.262 849.486 847.4C849.806 847.532 850.168 847.597 850.572 847.597C851.09 847.597 851.558 847.47 851.977 847.215C852.404 846.961 852.766 846.583 853.063 846.082C853.36 845.581 853.589 844.96 853.749 844.22C853.909 843.472 853.989 842.608 853.989 841.629ZM847.155 841.49C847.155 841.729 847.155 841.968 847.155 842.207C847.163 842.446 847.178 842.678 847.201 842.901L853.52 838.171C853.406 837.777 853.261 837.419 853.086 837.095C852.911 836.771 852.701 836.494 852.457 836.262C852.214 836.023 851.936 835.838 851.623 835.707C851.311 835.576 850.96 835.51 850.572 835.51C850.054 835.51 849.581 835.638 849.155 835.892C848.736 836.147 848.378 836.528 848.081 837.037C847.783 837.538 847.555 838.163 847.395 838.911C847.235 839.651 847.155 840.511 847.155 841.49Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 874.193H846.149V872.32H849.989V861.285L846.412 863.252L845.681 861.54L850.435 859.007H852.195V872.32H855.531V874.193Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 899.274H846.149V897.401H849.989V886.366L846.412 888.333L845.681 886.621L850.435 884.088H852.195V897.401H855.531V899.274Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 924.355H846.149V922.482H849.989V911.447L846.412 913.414L845.681 911.702L850.435 909.169H852.195V922.482H855.531V924.355Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 941.884C856 943.04 855.886 944.097 855.657 945.053C855.429 946.001 855.082 946.818 854.617 947.505C854.153 948.183 853.574 948.711 852.88 949.089C852.195 949.459 851.387 949.645 850.458 949.645C849.658 949.645 848.93 949.494 848.275 949.193C847.62 948.885 847.06 948.415 846.595 947.782C846.13 947.15 845.772 946.344 845.521 945.365C845.269 944.386 845.144 943.225 845.144 941.884C845.144 940.727 845.258 939.671 845.486 938.714C845.723 937.758 846.069 936.941 846.526 936.262C846.991 935.576 847.57 935.048 848.263 934.678C848.957 934.3 849.764 934.111 850.686 934.111C851.486 934.111 852.214 934.265 852.869 934.574C853.524 934.874 854.084 935.345 854.549 935.985C855.013 936.617 855.371 937.423 855.623 938.402C855.874 939.381 856 940.542 856 941.884ZM853.989 941.953C853.989 941.691 853.977 941.432 853.954 941.178C853.939 940.916 853.92 940.661 853.897 940.415L847.578 945.168C847.692 945.569 847.837 945.939 848.012 946.279C848.187 946.618 848.397 946.911 848.641 947.158C848.892 947.397 849.174 947.586 849.486 947.724C849.806 947.856 850.168 947.921 850.572 947.921C851.09 947.921 851.558 947.794 851.977 947.539C852.404 947.285 852.766 946.907 853.063 946.406C853.36 945.905 853.589 945.284 853.749 944.544C853.909 943.796 853.989 942.932 853.989 941.953ZM847.155 941.814C847.155 942.053 847.155 942.292 847.155 942.531C847.163 942.77 847.178 943.002 847.201 943.225L853.52 938.495C853.406 938.101 853.261 937.743 853.086 937.419C852.911 937.095 852.701 936.818 852.457 936.586C852.214 936.347 851.936 936.162 851.623 936.031C851.311 935.9 850.96 935.834 850.572 935.834C850.054 935.834 849.581 935.962 849.155 936.216C848.736 936.471 848.378 936.852 848.081 937.361C847.783 937.862 847.555 938.487 847.395 939.235C847.235 939.975 847.155 940.835 847.155 941.814Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 974.517H846.149V972.644H849.989V961.609L846.412 963.576L845.681 961.864L850.435 959.331H852.195V972.644H855.531V974.517Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 992.046C856 993.202 855.886 994.259 855.657 995.215C855.429 996.163 855.082 996.98 854.617 997.667C854.153 998.345 853.574 998.874 852.88 999.251C852.195 999.621 851.387 999.807 850.458 999.807C849.658 999.807 848.93 999.656 848.275 999.355C847.62 999.047 847.06 998.577 846.595 997.944C846.13 997.312 845.772 996.506 845.521 995.527C845.269 994.548 845.144 993.387 845.144 992.046C845.144 990.889 845.258 989.833 845.486 988.876C845.723 987.92 846.069 987.103 846.526 986.424C846.991 985.738 847.57 985.21 848.263 984.84C848.957 984.462 849.764 984.273 850.686 984.273C851.486 984.273 852.214 984.427 852.869 984.736C853.524 985.036 854.084 985.507 854.549 986.147C855.013 986.779 855.371 987.585 855.623 988.564C855.874 989.543 856 990.704 856 992.046ZM853.989 992.115C853.989 991.853 853.977 991.594 853.954 991.34C853.939 991.078 853.92 990.823 853.897 990.577L847.578 995.33C847.692 995.731 847.837 996.101 848.012 996.441C848.187 996.78 848.397 997.073 848.641 997.32C848.892 997.559 849.174 997.748 849.486 997.887C849.806 998.018 850.168 998.083 850.572 998.083C851.09 998.083 851.558 997.956 851.977 997.701C852.404 997.447 852.766 997.069 853.063 996.568C853.36 996.067 853.589 995.446 853.749 994.706C853.909 993.958 853.989 993.094 853.989 992.115ZM847.155 991.976C847.155 992.215 847.155 992.454 847.155 992.693C847.163 992.932 847.178 993.164 847.201 993.387L853.52 988.657C853.406 988.263 853.261 987.905 853.086 987.581C852.911 987.257 852.701 986.98 852.457 986.748C852.214 986.509 851.936 986.324 851.623 986.193C851.311 986.062 850.96 985.996 850.572 985.996C850.054 985.996 849.581 986.124 849.155 986.378C848.736 986.633 848.378 987.014 848.081 987.523C847.783 988.024 847.555 988.649 847.395 989.397C847.235 990.137 847.155 990.997 847.155 991.976Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M856 1017.13C856 1018.28 855.886 1019.34 855.657 1020.3C855.429 1021.24 855.082 1022.06 854.617 1022.75C854.153 1023.43 853.574 1023.95 852.88 1024.33C852.195 1024.7 851.387 1024.89 850.458 1024.89C849.658 1024.89 848.93 1024.74 848.275 1024.44C847.62 1024.13 847.06 1023.66 846.595 1023.03C846.13 1022.39 845.772 1021.59 845.521 1020.61C845.269 1019.63 845.144 1018.47 845.144 1017.13C845.144 1015.97 845.258 1014.91 845.486 1013.96C845.723 1013 846.069 1012.18 846.526 1011.51C846.991 1010.82 847.57 1010.29 848.263 1009.92C848.957 1009.54 849.764 1009.35 850.686 1009.35C851.486 1009.35 852.214 1009.51 852.869 1009.82C853.524 1010.12 854.084 1010.59 854.549 1011.23C855.013 1011.86 855.371 1012.67 855.623 1013.65C855.874 1014.62 856 1015.78 856 1017.13ZM853.989 1017.2C853.989 1016.93 853.977 1016.68 853.954 1016.42C853.939 1016.16 853.92 1015.9 853.897 1015.66L847.578 1020.41C847.692 1020.81 847.837 1021.18 848.012 1021.52C848.187 1021.86 848.397 1022.15 848.641 1022.4C848.892 1022.64 849.174 1022.83 849.486 1022.97C849.806 1023.1 850.168 1023.16 850.572 1023.16C851.09 1023.16 851.558 1023.04 851.977 1022.78C852.404 1022.53 852.766 1022.15 853.063 1021.65C853.36 1021.15 853.589 1020.53 853.749 1019.79C853.909 1019.04 853.989 1018.18 853.989 1017.2ZM847.155 1017.06C847.155 1017.3 847.155 1017.54 847.155 1017.77C847.163 1018.01 847.178 1018.24 847.201 1018.47L853.52 1013.74C853.406 1013.34 853.261 1012.99 853.086 1012.66C852.911 1012.34 852.701 1012.06 852.457 1011.83C852.214 1011.59 851.936 1011.41 851.623 1011.27C851.311 1011.14 850.96 1011.08 850.572 1011.08C850.054 1011.08 849.581 1011.2 849.155 1011.46C848.736 1011.71 848.378 1012.1 848.081 1012.6C847.783 1013.11 847.555 1013.73 847.395 1014.48C847.235 1015.22 847.155 1016.08 847.155 1017.06Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M855.531 1049.76H846.149V1047.89H849.989V1036.85L846.412 1038.82L845.681 1037.11L850.435 1034.57H852.195V1047.89H855.531V1049.76Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M798.38 478.471H788.446V476.487H792.511V464.803L788.724 466.885L787.95 465.073L792.983 462.391H794.847V476.487H798.38V478.471Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 504.945H788.446V502.961H792.511V491.278L788.724 493.36L787.95 491.547L792.983 488.865H794.847V502.961H798.38V504.945Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 523.422C798.876 524.647 798.755 525.766 798.513 526.778C798.271 527.782 797.904 528.648 797.412 529.374C796.92 530.093 796.307 530.652 795.573 531.052C794.847 531.444 793.992 531.64 793.008 531.64C792.161 531.64 791.39 531.481 790.696 531.162C790.003 530.836 789.41 530.338 788.918 529.668C788.426 528.999 788.047 528.146 787.78 527.109C787.514 526.072 787.381 524.843 787.381 523.422C787.381 522.198 787.502 521.079 787.744 520.067C787.994 519.054 788.361 518.189 788.845 517.471C789.337 516.744 789.95 516.185 790.684 515.793C791.418 515.393 792.274 515.193 793.25 515.193C794.097 515.193 794.867 515.356 795.561 515.683C796.254 516.001 796.847 516.499 797.339 517.177C797.831 517.846 798.211 518.699 798.477 519.736C798.743 520.773 798.876 522.002 798.876 523.422ZM796.747 523.496C796.747 523.218 796.734 522.945 796.71 522.675C796.694 522.398 796.674 522.128 796.65 521.867L789.958 526.9C790.079 527.325 790.233 527.717 790.418 528.076C790.604 528.435 790.826 528.746 791.084 529.007C791.35 529.26 791.648 529.46 791.979 529.607C792.318 529.746 792.701 529.815 793.129 529.815C793.677 529.815 794.173 529.68 794.617 529.411C795.069 529.142 795.452 528.742 795.766 528.211C796.081 527.68 796.323 527.023 796.492 526.239C796.662 525.447 796.747 524.533 796.747 523.496ZM789.511 523.349C789.511 523.602 789.511 523.855 789.511 524.108C789.519 524.361 789.535 524.606 789.559 524.843L796.25 519.834C796.129 519.418 795.976 519.038 795.791 518.695C795.605 518.352 795.383 518.058 795.125 517.813C794.867 517.56 794.573 517.364 794.242 517.226C793.911 517.087 793.54 517.017 793.129 517.017C792.58 517.017 792.08 517.152 791.628 517.422C791.185 517.691 790.805 518.095 790.491 518.634C790.176 519.165 789.934 519.826 789.765 520.618C789.595 521.402 789.511 522.312 789.511 523.349Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 549.897C798.876 551.121 798.755 552.24 798.513 553.252C798.271 554.257 797.904 555.122 797.412 555.849C796.92 556.567 796.307 557.126 795.573 557.526C794.847 557.918 793.992 558.114 793.008 558.114C792.161 558.114 791.39 557.955 790.696 557.637C790.003 557.31 789.41 556.812 788.918 556.143C788.426 555.473 788.047 554.62 787.78 553.583C787.514 552.546 787.381 551.317 787.381 549.897C787.381 548.672 787.502 547.554 787.744 546.541C787.994 545.529 788.361 544.663 788.845 543.945C789.337 543.218 789.95 542.659 790.684 542.267C791.418 541.867 792.274 541.667 793.25 541.667C794.097 541.667 794.867 541.83 795.561 542.157C796.254 542.475 796.847 542.973 797.339 543.651C797.831 544.321 798.211 545.174 798.477 546.211C798.743 547.247 798.876 548.476 798.876 549.897ZM796.747 549.97C796.747 549.693 796.734 549.419 796.71 549.15C796.694 548.872 796.674 548.603 796.65 548.342L789.958 553.375C790.079 553.799 790.233 554.191 790.418 554.551C790.604 554.91 790.826 555.22 791.084 555.481C791.35 555.734 791.648 555.934 791.979 556.081C792.318 556.22 792.701 556.29 793.129 556.29C793.677 556.29 794.173 556.155 794.617 555.885C795.069 555.616 795.452 555.216 795.766 554.685C796.081 554.155 796.323 553.497 796.492 552.714C796.662 551.922 796.747 551.007 796.747 549.97ZM789.511 549.823C789.511 550.076 789.511 550.33 789.511 550.583C789.519 550.836 789.535 551.081 789.559 551.317L796.25 546.309C796.129 545.892 795.976 545.513 795.791 545.17C795.605 544.827 795.383 544.533 795.125 544.288C794.867 544.035 794.573 543.839 794.242 543.7C793.911 543.561 793.54 543.492 793.129 543.492C792.58 543.492 792.08 543.627 791.628 543.896C791.185 544.165 790.805 544.57 790.491 545.108C790.176 545.639 789.934 546.3 789.765 547.092C789.595 547.876 789.511 548.786 789.511 549.823Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 576.371C798.876 577.596 798.755 578.714 798.513 579.727C798.271 580.731 797.904 581.596 797.412 582.323C796.92 583.042 796.307 583.601 795.573 584.001C794.847 584.393 793.992 584.589 793.008 584.589C792.161 584.589 791.39 584.429 790.696 584.111C790.003 583.784 789.41 583.286 788.918 582.617C788.426 581.948 788.047 581.094 787.78 580.057C787.514 579.021 787.381 577.792 787.381 576.371C787.381 575.147 787.502 574.028 787.744 573.016C787.994 572.003 788.361 571.138 788.845 570.419C789.337 569.693 789.95 569.133 790.684 568.742C791.418 568.342 792.274 568.142 793.25 568.142C794.097 568.142 794.867 568.305 795.561 568.631C796.254 568.95 796.847 569.448 797.339 570.125C797.831 570.795 798.211 571.648 798.477 572.685C798.743 573.722 798.876 574.951 798.876 576.371ZM796.747 576.445C796.747 576.167 796.734 575.894 796.71 575.624C796.694 575.347 796.674 575.077 796.65 574.816L789.958 579.849C790.079 580.274 790.233 580.666 790.418 581.025C790.604 581.384 790.826 581.694 791.084 581.956C791.35 582.209 791.648 582.409 791.979 582.556C792.318 582.695 792.701 582.764 793.129 582.764C793.677 582.764 794.173 582.629 794.617 582.36C795.069 582.09 795.452 581.69 795.766 581.16C796.081 580.629 796.323 579.972 796.492 579.188C796.662 578.396 796.747 577.482 796.747 576.445ZM789.511 576.298C789.511 576.551 789.511 576.804 789.511 577.057C789.519 577.31 789.535 577.555 789.559 577.792L796.25 572.783C796.129 572.367 795.976 571.987 795.791 571.644C795.605 571.301 795.383 571.007 795.125 570.762C794.867 570.509 794.573 570.313 794.242 570.174C793.911 570.036 793.54 569.966 793.129 569.966C792.58 569.966 792.08 570.101 791.628 570.37C791.185 570.64 790.805 571.044 790.491 571.583C790.176 572.113 789.934 572.775 789.765 573.567C789.595 574.351 789.511 575.261 789.511 576.298Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 610.843H788.446V608.859H792.511V597.175L788.724 599.257L787.95 597.445L792.983 594.763H794.847V608.859H798.38V610.843Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 629.32C798.876 630.545 798.755 631.663 798.513 632.676C798.271 633.68 797.904 634.545 797.412 635.272C796.92 635.99 796.307 636.55 795.573 636.95C794.847 637.342 793.992 637.537 793.008 637.537C792.161 637.537 791.39 637.378 790.696 637.06C790.003 636.733 789.41 636.235 788.918 635.566C788.426 634.896 788.047 634.043 787.78 633.006C787.514 631.969 787.381 630.741 787.381 629.32C787.381 628.095 787.502 626.977 787.744 625.964C787.994 624.952 788.361 624.087 788.845 623.368C789.337 622.642 789.95 622.082 790.684 621.69C791.418 621.29 792.274 621.09 793.25 621.09C794.097 621.09 794.867 621.254 795.561 621.58C796.254 621.899 796.847 622.397 797.339 623.074C797.831 623.744 798.211 624.597 798.477 625.634C798.743 626.671 798.876 627.899 798.876 629.32ZM796.747 629.393C796.747 629.116 796.734 628.842 796.71 628.573C796.694 628.295 796.674 628.026 796.65 627.765L789.958 632.798C790.079 633.223 790.233 633.614 790.418 633.974C790.604 634.333 790.826 634.643 791.084 634.904C791.35 635.158 791.648 635.358 791.979 635.505C792.318 635.643 792.701 635.713 793.129 635.713C793.677 635.713 794.173 635.578 794.617 635.309C795.069 635.039 795.452 634.639 795.766 634.108C796.081 633.578 796.323 632.921 796.492 632.137C796.662 631.345 796.747 630.43 796.747 629.393ZM789.511 629.247C789.511 629.5 789.511 629.753 789.511 630.006C789.519 630.259 789.535 630.504 789.559 630.741L796.25 625.732C796.129 625.315 795.976 624.936 795.791 624.593C795.605 624.25 795.383 623.956 795.125 623.711C794.867 623.458 794.573 623.262 794.242 623.123C793.911 622.984 793.54 622.915 793.129 622.915C792.58 622.915 792.08 623.05 791.628 623.319C791.185 623.589 790.805 623.993 790.491 624.532C790.176 625.062 789.934 625.724 789.765 626.516C789.595 627.299 789.511 628.21 789.511 629.247Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 663.791H788.446V661.807H792.511V650.124L788.724 652.206L787.95 650.394L792.983 647.712H794.847V661.807H798.38V663.791Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 690.266H788.446V688.282H792.511V676.599L788.724 678.681L787.95 676.868L792.983 674.186H794.847V688.282H798.38V690.266Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 708.743C798.876 709.968 798.755 711.086 798.513 712.099C798.271 713.103 797.904 713.968 797.412 714.695C796.92 715.414 796.307 715.973 795.573 716.373C794.847 716.765 793.992 716.961 793.008 716.961C792.161 716.961 791.39 716.801 790.696 716.483C790.003 716.156 789.41 715.658 788.918 714.989C788.426 714.319 788.047 713.466 787.78 712.429C787.514 711.393 787.381 710.164 787.381 708.743C787.381 707.519 787.502 706.4 787.744 705.388C787.994 704.375 788.361 703.51 788.845 702.791C789.337 702.065 789.95 701.505 790.684 701.114C791.418 700.714 792.274 700.514 793.25 700.514C794.097 700.514 794.867 700.677 795.561 701.003C796.254 701.322 796.847 701.82 797.339 702.497C797.831 703.167 798.211 704.02 798.477 705.057C798.743 706.094 798.876 707.323 798.876 708.743ZM796.747 708.817C796.747 708.539 796.734 708.266 796.71 707.996C796.694 707.719 796.674 707.449 796.65 707.188L789.958 712.221C790.079 712.646 790.233 713.038 790.418 713.397C790.604 713.756 790.826 714.066 791.084 714.328C791.35 714.581 791.648 714.781 791.979 714.928C792.318 715.067 792.701 715.136 793.129 715.136C793.677 715.136 794.173 715.001 794.617 714.732C795.069 714.462 795.452 714.062 795.766 713.532C796.081 713.001 796.323 712.344 796.492 711.56C796.662 710.768 796.747 709.854 796.747 708.817ZM789.511 708.67C789.511 708.923 789.511 709.176 789.511 709.429C789.519 709.682 789.535 709.927 789.559 710.164L796.25 705.155C796.129 704.739 795.976 704.359 795.791 704.016C795.605 703.673 795.383 703.379 795.125 703.134C794.867 702.881 794.573 702.685 794.242 702.546C793.911 702.408 793.54 702.338 793.129 702.338C792.58 702.338 792.08 702.473 791.628 702.742C791.185 703.012 790.805 703.416 790.491 703.955C790.176 704.485 789.934 705.147 789.765 705.939C789.595 706.723 789.511 707.633 789.511 708.67Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 735.218C798.876 736.442 798.755 737.561 798.513 738.573C798.271 739.577 797.904 740.443 797.412 741.169C796.92 741.888 796.307 742.447 795.573 742.847C794.847 743.239 793.992 743.435 793.008 743.435C792.161 743.435 791.39 743.276 790.696 742.957C790.003 742.631 789.41 742.133 788.918 741.463C788.426 740.794 788.047 739.941 787.78 738.904C787.514 737.867 787.381 736.638 787.381 735.218C787.381 733.993 787.502 732.874 787.744 731.862C787.994 730.85 788.361 729.984 788.845 729.266C789.337 728.539 789.95 727.98 790.684 727.588C791.418 727.188 792.274 726.988 793.25 726.988C794.097 726.988 794.867 727.151 795.561 727.478C796.254 727.796 796.847 728.294 797.339 728.972C797.831 729.641 798.211 730.495 798.477 731.531C798.743 732.568 798.876 733.797 798.876 735.218ZM796.747 735.291C796.747 735.013 796.734 734.74 796.71 734.471C796.694 734.193 796.674 733.924 796.65 733.662L789.958 738.696C790.079 739.12 790.233 739.512 790.418 739.871C790.604 740.231 790.826 740.541 791.084 740.802C791.35 741.055 791.648 741.255 791.979 741.402C792.318 741.541 792.701 741.61 793.129 741.61C793.677 741.61 794.173 741.476 794.617 741.206C795.069 740.937 795.452 740.537 795.766 740.006C796.081 739.475 796.323 738.818 796.492 738.034C796.662 737.242 796.747 736.328 796.747 735.291ZM789.511 735.144C789.511 735.397 789.511 735.65 789.511 735.903C789.519 736.156 789.535 736.401 789.559 736.638L796.25 731.629C796.129 731.213 795.976 730.833 795.791 730.49C795.605 730.148 795.383 729.854 795.125 729.609C794.867 729.356 794.573 729.16 794.242 729.021C793.911 728.882 793.54 728.813 793.129 728.813C792.58 728.813 792.08 728.947 791.628 729.217C791.185 729.486 790.805 729.89 790.491 730.429C790.176 730.96 789.934 731.621 789.765 732.413C789.595 733.197 789.511 734.107 789.511 735.144Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 761.692C798.876 762.917 798.755 764.035 798.513 765.048C798.271 766.052 797.904 766.917 797.412 767.644C796.92 768.362 796.307 768.922 795.573 769.322C794.847 769.714 793.992 769.909 793.008 769.909C792.161 769.909 791.39 769.75 790.696 769.432C790.003 769.105 789.41 768.607 788.918 767.938C788.426 767.268 788.047 766.415 787.78 765.378C787.514 764.341 787.381 763.113 787.381 761.692C787.381 760.467 787.502 759.349 787.744 758.336C787.994 757.324 788.361 756.459 788.845 755.74C789.337 755.014 789.95 754.454 790.684 754.062C791.418 753.662 792.274 753.462 793.25 753.462C794.097 753.462 794.867 753.626 795.561 753.952C796.254 754.271 796.847 754.769 797.339 755.446C797.831 756.116 798.211 756.969 798.477 758.006C798.743 759.043 798.876 760.271 798.876 761.692ZM796.747 761.765C796.747 761.488 796.734 761.214 796.71 760.945C796.694 760.667 796.674 760.398 796.65 760.137L789.958 765.17C790.079 765.595 790.233 765.986 790.418 766.346C790.604 766.705 790.826 767.015 791.084 767.276C791.35 767.53 791.648 767.73 791.979 767.877C792.318 768.015 792.701 768.085 793.129 768.085C793.677 768.085 794.173 767.95 794.617 767.681C795.069 767.411 795.452 767.011 795.766 766.48C796.081 765.95 796.323 765.292 796.492 764.509C796.662 763.717 796.747 762.802 796.747 761.765ZM789.511 761.618C789.511 761.872 789.511 762.125 789.511 762.378C789.519 762.631 789.535 762.876 789.559 763.113L796.25 758.104C796.129 757.687 795.976 757.308 795.791 756.965C795.605 756.622 795.383 756.328 795.125 756.083C794.867 755.83 794.573 755.634 794.242 755.495C793.911 755.356 793.54 755.287 793.129 755.287C792.58 755.287 792.08 755.422 791.628 755.691C791.185 755.961 790.805 756.365 790.491 756.904C790.176 757.434 789.934 758.096 789.765 758.888C789.595 759.671 789.511 760.582 789.511 761.618Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 796.163H788.446V794.179H792.511V782.496L788.724 784.578L787.95 782.766L792.983 780.084H794.847V794.179H798.38V796.163Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 822.638H788.446V820.654H792.511V808.971L788.724 811.053L787.95 809.24L792.983 806.558H794.847V820.654H798.38V822.638Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 849.112H788.446V847.128H792.511V835.445L788.724 837.527L787.95 835.714L792.983 833.032H794.847V847.128H798.38V849.112Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 867.59C798.876 868.814 798.755 869.933 798.513 870.945C798.271 871.949 797.904 872.815 797.412 873.541C796.92 874.26 796.307 874.819 795.573 875.219C794.847 875.611 793.992 875.807 793.008 875.807C792.161 875.807 791.39 875.648 790.696 875.329C790.003 875.003 789.41 874.505 788.918 873.835C788.426 873.166 788.047 872.313 787.78 871.276C787.514 870.239 787.381 869.01 787.381 867.59C787.381 866.365 787.502 865.246 787.744 864.234C787.994 863.222 788.361 862.356 788.845 861.638C789.337 860.911 789.95 860.352 790.684 859.96C791.418 859.56 792.274 859.36 793.25 859.36C794.097 859.36 794.867 859.523 795.561 859.85C796.254 860.168 796.847 860.666 797.339 861.344C797.831 862.013 798.211 862.866 798.477 863.903C798.743 864.94 798.876 866.169 798.876 867.59ZM796.747 867.663C796.747 867.385 796.734 867.112 796.71 866.843C796.694 866.565 796.674 866.296 796.65 866.034L789.958 871.068C790.079 871.492 790.233 871.884 790.418 872.243C790.604 872.603 790.826 872.913 791.084 873.174C791.35 873.427 791.648 873.627 791.979 873.774C792.318 873.913 792.701 873.982 793.129 873.982C793.677 873.982 794.173 873.848 794.617 873.578C795.069 873.309 795.452 872.909 795.766 872.378C796.081 871.847 796.323 871.19 796.492 870.406C796.662 869.614 796.747 868.7 796.747 867.663ZM789.511 867.516C789.511 867.769 789.511 868.022 789.511 868.275C789.519 868.528 789.535 868.773 789.559 869.01L796.25 864.001C796.129 863.585 795.976 863.205 795.791 862.862C795.605 862.52 795.383 862.226 795.125 861.981C794.867 861.728 794.573 861.532 794.242 861.393C793.911 861.254 793.54 861.185 793.129 861.185C792.58 861.185 792.08 861.319 791.628 861.589C791.185 861.858 790.805 862.262 790.491 862.801C790.176 863.332 789.934 863.993 789.765 864.785C789.595 865.569 789.511 866.479 789.511 867.516Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 902.061H788.446V900.077H792.511V888.394L788.724 890.476L787.95 888.663L792.983 885.981H794.847V900.077H798.38V902.061Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 920.538C798.876 921.763 798.755 922.882 798.513 923.894C798.271 924.898 797.904 925.764 797.412 926.49C796.92 927.209 796.307 927.768 795.573 928.168C794.847 928.56 793.992 928.756 793.008 928.756C792.161 928.756 791.39 928.597 790.696 928.278C790.003 927.952 789.41 927.454 788.918 926.784C788.426 926.115 788.047 925.261 787.78 924.225C787.514 923.188 787.381 921.959 787.381 920.538C787.381 919.314 787.502 918.195 787.744 917.183C787.994 916.17 788.361 915.305 788.845 914.587C789.337 913.86 789.95 913.301 790.684 912.909C791.418 912.509 792.274 912.309 793.25 912.309C794.097 912.309 794.867 912.472 795.561 912.799C796.254 913.117 796.847 913.615 797.339 914.293C797.831 914.962 798.211 915.815 798.477 916.852C798.743 917.889 798.876 919.118 798.876 920.538ZM796.747 920.612C796.747 920.334 796.734 920.061 796.71 919.791C796.694 919.514 796.674 919.244 796.65 918.983L789.958 924.016C790.079 924.441 790.233 924.833 790.418 925.192C790.604 925.551 790.826 925.862 791.084 926.123C791.35 926.376 791.648 926.576 791.979 926.723C792.318 926.862 792.701 926.931 793.129 926.931C793.677 926.931 794.173 926.796 794.617 926.527C795.069 926.258 795.452 925.857 795.766 925.327C796.081 924.796 796.323 924.139 796.492 923.355C796.662 922.563 796.747 921.649 796.747 920.612ZM789.511 920.465C789.511 920.718 789.511 920.971 789.511 921.224C789.519 921.477 789.535 921.722 789.559 921.959L796.25 916.95C796.129 916.534 795.976 916.154 795.791 915.811C795.605 915.468 795.383 915.174 795.125 914.929C794.867 914.676 794.573 914.48 794.242 914.342C793.911 914.203 793.54 914.133 793.129 914.133C792.58 914.133 792.08 914.268 791.628 914.538C791.185 914.807 790.805 915.211 790.491 915.75C790.176 916.281 789.934 916.942 789.765 917.734C789.595 918.518 789.511 919.428 789.511 920.465Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.876 947.013C798.876 948.237 798.755 949.356 798.513 950.368C798.271 951.373 797.904 952.238 797.412 952.965C796.92 953.683 796.307 954.242 795.573 954.642C794.847 955.034 793.992 955.23 793.008 955.23C792.161 955.23 791.39 955.071 790.696 954.753C790.003 954.426 789.41 953.928 788.918 953.259C788.426 952.589 788.047 951.736 787.78 950.699C787.514 949.662 787.381 948.433 787.381 947.013C787.381 945.788 787.502 944.67 787.744 943.657C787.994 942.645 788.361 941.779 788.845 941.061C789.337 940.334 789.95 939.775 790.684 939.383C791.418 938.983 792.274 938.783 793.25 938.783C794.097 938.783 794.867 938.946 795.561 939.273C796.254 939.591 796.847 940.089 797.339 940.767C797.831 941.436 798.211 942.29 798.477 943.327C798.743 944.363 798.876 945.592 798.876 947.013ZM796.747 947.086C796.747 946.809 796.734 946.535 796.71 946.266C796.694 945.988 796.674 945.719 796.65 945.457L789.958 950.491C790.079 950.915 790.233 951.307 790.418 951.666C790.604 952.026 790.826 952.336 791.084 952.597C791.35 952.85 791.648 953.05 791.979 953.197C792.318 953.336 792.701 953.405 793.129 953.405C793.677 953.405 794.173 953.271 794.617 953.001C795.069 952.732 795.452 952.332 795.766 951.801C796.081 951.27 796.323 950.613 796.492 949.829C796.662 949.038 796.747 948.123 796.747 947.086ZM789.511 946.939C789.511 947.192 789.511 947.445 789.511 947.699C789.519 947.952 789.535 948.197 789.559 948.433L796.25 943.424C796.129 943.008 795.976 942.628 795.791 942.286C795.605 941.943 795.383 941.649 795.125 941.404C794.867 941.151 794.573 940.955 794.242 940.816C793.911 940.677 793.54 940.608 793.129 940.608C792.58 940.608 792.08 940.743 791.628 941.012C791.185 941.281 790.805 941.685 790.491 942.224C790.176 942.755 789.934 943.416 789.765 944.208C789.595 944.992 789.511 945.902 789.511 946.939Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M798.38 981.484H788.446V979.5H792.511V967.817L788.724 969.899L787.95 968.086L792.983 965.404H794.847V979.5H798.38V981.484Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 706.986H609.474V705.003H613.54V693.319L609.753 695.401L608.978 693.589L614.012 690.907H615.875V705.003H619.408V706.986Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 733.461H609.474V731.477H613.54V719.794L609.753 721.876L608.978 720.063L614.012 717.381H615.875V731.477H619.408V733.461Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 751.938C619.905 753.163 619.784 754.281 619.542 755.294C619.3 756.298 618.932 757.163 618.44 757.89C617.948 758.609 617.335 759.168 616.601 759.568C615.875 759.96 615.02 760.156 614.036 760.156C613.189 760.156 612.419 759.996 611.725 759.678C611.031 759.352 610.438 758.853 609.946 758.184C609.454 757.515 609.075 756.661 608.809 755.624C608.543 754.588 608.41 753.359 608.41 751.938C608.41 750.714 608.531 749.595 608.773 748.583C609.023 747.57 609.39 746.705 609.874 745.986C610.366 745.26 610.979 744.701 611.713 744.309C612.447 743.909 613.302 743.709 614.278 743.709C615.125 743.709 615.895 743.872 616.589 744.198C617.283 744.517 617.876 745.015 618.368 745.692C618.86 746.362 619.239 747.215 619.505 748.252C619.771 749.289 619.905 750.518 619.905 751.938ZM617.775 752.012C617.775 751.734 617.763 751.461 617.739 751.191C617.723 750.914 617.702 750.644 617.678 750.383L610.987 755.416C611.108 755.841 611.261 756.233 611.447 756.592C611.632 756.951 611.854 757.261 612.112 757.523C612.378 757.776 612.677 757.976 613.008 758.123C613.346 758.262 613.73 758.331 614.157 758.331C614.706 758.331 615.202 758.196 615.645 757.927C616.097 757.657 616.48 757.257 616.795 756.727C617.109 756.196 617.351 755.539 617.521 754.755C617.69 753.963 617.775 753.049 617.775 752.012ZM610.539 751.865C610.539 752.118 610.539 752.371 610.539 752.624C610.547 752.877 610.563 753.122 610.588 753.359L617.279 748.35C617.158 747.934 617.005 747.554 616.819 747.211C616.634 746.868 616.412 746.574 616.154 746.329C615.895 746.076 615.601 745.88 615.27 745.741C614.94 745.603 614.568 745.533 614.157 745.533C613.609 745.533 613.108 745.668 612.657 745.937C612.213 746.207 611.834 746.611 611.519 747.15C611.205 747.681 610.963 748.342 610.793 749.134C610.624 749.918 610.539 750.828 610.539 751.865Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 778.413C619.905 779.637 619.784 780.756 619.542 781.768C619.3 782.772 618.932 783.638 618.44 784.364C617.948 785.083 617.335 785.642 616.601 786.042C615.875 786.434 615.02 786.63 614.036 786.63C613.189 786.63 612.419 786.471 611.725 786.152C611.031 785.826 610.438 785.328 609.946 784.658C609.454 783.989 609.075 783.136 608.809 782.099C608.543 781.062 608.41 779.833 608.41 778.413C608.41 777.188 608.531 776.069 608.773 775.057C609.023 774.045 609.39 773.179 609.874 772.461C610.366 771.734 610.979 771.175 611.713 770.783C612.447 770.383 613.302 770.183 614.278 770.183C615.125 770.183 615.895 770.346 616.589 770.673C617.283 770.991 617.876 771.489 618.368 772.167C618.86 772.836 619.239 773.69 619.505 774.726C619.771 775.763 619.905 776.992 619.905 778.413ZM617.775 778.486C617.775 778.209 617.763 777.935 617.739 777.666C617.723 777.388 617.702 777.119 617.678 776.857L610.987 781.891C611.108 782.315 611.261 782.707 611.447 783.066C611.632 783.426 611.854 783.736 612.112 783.997C612.378 784.25 612.677 784.45 613.008 784.597C613.346 784.736 613.73 784.805 614.157 784.805C614.706 784.805 615.202 784.671 615.645 784.401C616.097 784.132 616.48 783.732 616.795 783.201C617.109 782.67 617.351 782.013 617.521 781.229C617.69 780.437 617.775 779.523 617.775 778.486ZM610.539 778.339C610.539 778.592 610.539 778.845 610.539 779.098C610.547 779.352 610.563 779.596 610.588 779.833L617.279 774.824C617.158 774.408 617.005 774.028 616.819 773.685C616.634 773.343 616.412 773.049 616.154 772.804C615.895 772.551 615.601 772.355 615.27 772.216C614.94 772.077 614.568 772.008 614.157 772.008C613.609 772.008 613.108 772.142 612.657 772.412C612.213 772.681 611.834 773.085 611.519 773.624C611.205 774.155 610.963 774.816 610.793 775.608C610.624 776.392 610.539 777.302 610.539 778.339Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 804.887C619.905 806.112 619.784 807.23 619.542 808.243C619.3 809.247 618.932 810.112 618.44 810.839C617.948 811.557 617.335 812.117 616.601 812.517C615.875 812.909 615.02 813.104 614.036 813.104C613.189 813.104 612.419 812.945 611.725 812.627C611.031 812.3 610.438 811.802 609.946 811.133C609.454 810.463 609.075 809.61 608.809 808.573C608.543 807.536 608.41 806.308 608.41 804.887C608.41 803.662 608.531 802.544 608.773 801.531C609.023 800.519 609.39 799.654 609.874 798.935C610.366 798.209 610.979 797.649 611.713 797.257C612.447 796.857 613.302 796.657 614.278 796.657C615.125 796.657 615.895 796.821 616.589 797.147C617.283 797.466 617.876 797.964 618.368 798.641C618.86 799.311 619.239 800.164 619.505 801.201C619.771 802.238 619.905 803.466 619.905 804.887ZM617.775 804.961C617.775 804.683 617.763 804.409 617.739 804.14C617.723 803.862 617.702 803.593 617.678 803.332L610.987 808.365C611.108 808.79 611.261 809.182 611.447 809.541C611.632 809.9 611.854 810.21 612.112 810.471C612.378 810.725 612.677 810.925 613.008 811.072C613.346 811.21 613.73 811.28 614.157 811.28C614.706 811.28 615.202 811.145 615.645 810.876C616.097 810.606 616.48 810.206 616.795 809.675C617.109 809.145 617.351 808.488 617.521 807.704C617.69 806.912 617.775 805.997 617.775 804.961ZM610.539 804.814C610.539 805.067 610.539 805.32 610.539 805.573C610.547 805.826 610.563 806.071 610.588 806.308L617.279 801.299C617.158 800.882 617.005 800.503 616.819 800.16C616.634 799.817 616.412 799.523 616.154 799.278C615.895 799.025 615.601 798.829 615.27 798.69C614.94 798.551 614.568 798.482 614.157 798.482C613.609 798.482 613.108 798.617 612.657 798.886C612.213 799.156 611.834 799.56 611.519 800.099C611.205 800.629 610.963 801.291 610.793 802.083C610.624 802.866 610.539 803.777 610.539 804.814Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 839.358H609.474V837.374H613.54V825.691L609.753 827.773L608.978 825.961L614.012 823.279H615.875V837.374H619.408V839.358Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 857.836C619.905 859.06 619.784 860.179 619.542 861.191C619.3 862.196 618.932 863.061 618.44 863.788C617.948 864.506 617.335 865.065 616.601 865.465C615.875 865.857 615.02 866.053 614.036 866.053C613.189 866.053 612.419 865.894 611.725 865.576C611.031 865.249 610.438 864.751 609.946 864.082C609.454 863.412 609.075 862.559 608.809 861.522C608.543 860.485 608.41 859.256 608.41 857.836C608.41 856.611 608.531 855.493 608.773 854.48C609.023 853.468 609.39 852.602 609.874 851.884C610.366 851.157 610.979 850.598 611.713 850.206C612.447 849.806 613.302 849.606 614.278 849.606C615.125 849.606 615.895 849.769 616.589 850.096C617.283 850.414 617.876 850.912 618.368 851.59C618.86 852.26 619.239 853.113 619.505 854.15C619.771 855.186 619.905 856.415 619.905 857.836ZM617.775 857.909C617.775 857.632 617.763 857.358 617.739 857.089C617.723 856.811 617.702 856.542 617.678 856.281L610.987 861.314C611.108 861.738 611.261 862.13 611.447 862.49C611.632 862.849 611.854 863.159 612.112 863.42C612.378 863.673 612.677 863.873 613.008 864.02C613.346 864.159 613.73 864.229 614.157 864.229C614.706 864.229 615.202 864.094 615.645 863.824C616.097 863.555 616.48 863.155 616.795 862.624C617.109 862.094 617.351 861.436 617.521 860.653C617.69 859.861 617.775 858.946 617.775 857.909ZM610.539 857.762C610.539 858.015 610.539 858.269 610.539 858.522C610.547 858.775 610.563 859.02 610.588 859.256L617.279 854.248C617.158 853.831 617.005 853.452 616.819 853.109C616.634 852.766 616.412 852.472 616.154 852.227C615.895 851.974 615.601 851.778 615.27 851.639C614.94 851.5 614.568 851.431 614.157 851.431C613.609 851.431 613.108 851.566 612.657 851.835C612.213 852.104 611.834 852.509 611.519 853.047C611.205 853.578 610.963 854.239 610.793 855.031C610.624 855.815 610.539 856.725 610.539 857.762Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 892.307H609.474V890.323H613.54V878.64L609.753 880.722L608.978 878.909L614.012 876.227H615.875V890.323H619.408V892.307Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 918.782H609.474V916.798H613.54V905.114L609.753 907.196L608.978 905.384L614.012 902.702H615.875V916.798H619.408V918.782Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 937.259C619.905 938.484 619.784 939.602 619.542 940.615C619.3 941.619 618.932 942.484 618.44 943.211C617.948 943.929 617.335 944.489 616.601 944.889C615.875 945.281 615.02 945.476 614.036 945.476C613.189 945.476 612.419 945.317 611.725 944.999C611.031 944.672 610.438 944.174 609.946 943.505C609.454 942.835 609.075 941.982 608.809 940.945C608.543 939.908 608.41 938.68 608.41 937.259C608.41 936.034 608.531 934.916 608.773 933.903C609.023 932.891 609.39 932.026 609.874 931.307C610.366 930.581 610.979 930.021 611.713 929.629C612.447 929.229 613.302 929.029 614.278 929.029C615.125 929.029 615.895 929.193 616.589 929.519C617.283 929.838 617.876 930.336 618.368 931.013C618.86 931.683 619.239 932.536 619.505 933.573C619.771 934.61 619.905 935.838 619.905 937.259ZM617.775 937.332C617.775 937.055 617.763 936.781 617.739 936.512C617.723 936.234 617.702 935.965 617.678 935.704L610.987 940.737C611.108 941.162 611.261 941.553 611.447 941.913C611.632 942.272 611.854 942.582 612.112 942.843C612.378 943.097 612.677 943.297 613.008 943.444C613.346 943.582 613.73 943.652 614.157 943.652C614.706 943.652 615.202 943.517 615.645 943.248C616.097 942.978 616.48 942.578 616.795 942.047C617.109 941.517 617.351 940.86 617.521 940.076C617.69 939.284 617.775 938.369 617.775 937.332ZM610.539 937.186C610.539 937.439 610.539 937.692 610.539 937.945C610.547 938.198 610.563 938.443 610.588 938.68L617.279 933.671C617.158 933.254 617.005 932.875 616.819 932.532C616.634 932.189 616.412 931.895 616.154 931.65C615.895 931.397 615.601 931.201 615.27 931.062C614.94 930.923 614.568 930.854 614.157 930.854C613.609 930.854 613.108 930.989 612.657 931.258C612.213 931.528 611.834 931.932 611.519 932.471C611.205 933.001 610.963 933.663 610.793 934.455C610.624 935.238 610.539 936.149 610.539 937.186Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 963.733C619.905 964.958 619.784 966.077 619.542 967.089C619.3 968.093 618.932 968.959 618.44 969.685C617.948 970.404 617.335 970.963 616.601 971.363C615.875 971.755 615.02 971.951 614.036 971.951C613.189 971.951 612.419 971.792 611.725 971.473C611.031 971.147 610.438 970.649 609.946 969.979C609.454 969.31 609.075 968.457 608.809 967.42C608.543 966.383 608.41 965.154 608.41 963.733C608.41 962.509 608.531 961.39 608.773 960.378C609.023 959.365 609.39 958.5 609.874 957.782C610.366 957.055 610.979 956.496 611.713 956.104C612.447 955.704 613.302 955.504 614.278 955.504C615.125 955.504 615.895 955.667 616.589 955.994C617.283 956.312 617.876 956.81 618.368 957.488C618.86 958.157 619.239 959.01 619.505 960.047C619.771 961.084 619.905 962.313 619.905 963.733ZM617.775 963.807C617.775 963.529 617.763 963.256 617.739 962.986C617.723 962.709 617.702 962.439 617.678 962.178L610.987 967.211C611.108 967.636 611.261 968.028 611.447 968.387C611.632 968.746 611.854 969.057 612.112 969.318C612.378 969.571 612.677 969.771 613.008 969.918C613.346 970.057 613.73 970.126 614.157 970.126C614.706 970.126 615.202 969.991 615.645 969.722C616.097 969.453 616.48 969.053 616.795 968.522C617.109 967.991 617.351 967.334 617.521 966.55C617.69 965.758 617.775 964.844 617.775 963.807ZM610.539 963.66C610.539 963.913 610.539 964.166 610.539 964.419C610.547 964.672 610.563 964.917 610.588 965.154L617.279 960.145C617.158 959.729 617.005 959.349 616.819 959.006C616.634 958.663 616.412 958.369 616.154 958.124C615.895 957.871 615.601 957.675 615.27 957.537C614.94 957.398 614.568 957.328 614.157 957.328C613.609 957.328 613.108 957.463 612.657 957.733C612.213 958.002 611.834 958.406 611.519 958.945C611.205 959.476 610.963 960.137 610.793 960.929C610.624 961.713 610.539 962.623 610.539 963.66Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 990.208C619.905 991.432 619.784 992.551 619.542 993.563C619.3 994.568 618.932 995.433 618.44 996.16C617.948 996.878 617.335 997.437 616.601 997.837C615.875 998.229 615.02 998.425 614.036 998.425C613.189 998.425 612.419 998.266 611.725 997.948C611.031 997.621 610.438 997.123 609.946 996.454C609.454 995.784 609.075 994.931 608.809 993.894C608.543 992.857 608.41 991.628 608.41 990.208C608.41 988.983 608.531 987.865 608.773 986.852C609.023 985.84 609.39 984.974 609.874 984.256C610.366 983.529 610.979 982.97 611.713 982.578C612.447 982.178 613.302 981.978 614.278 981.978C615.125 981.978 615.895 982.141 616.589 982.468C617.283 982.786 617.876 983.284 618.368 983.962C618.86 984.632 619.239 985.485 619.505 986.522C619.771 987.558 619.905 988.787 619.905 990.208ZM617.775 990.281C617.775 990.004 617.763 989.73 617.739 989.461C617.723 989.183 617.702 988.914 617.678 988.652L610.987 993.686C611.108 994.11 611.261 994.502 611.447 994.862C611.632 995.221 611.854 995.531 612.112 995.792C612.378 996.045 612.677 996.245 613.008 996.392C613.346 996.531 613.73 996.601 614.157 996.601C614.706 996.601 615.202 996.466 615.645 996.196C616.097 995.927 616.48 995.527 616.795 994.996C617.109 994.466 617.351 993.808 617.521 993.025C617.69 992.233 617.775 991.318 617.775 990.281ZM610.539 990.134C610.539 990.387 610.539 990.641 610.539 990.894C610.547 991.147 610.563 991.392 610.588 991.628L617.279 986.62C617.158 986.203 617.005 985.824 616.819 985.481C616.634 985.138 616.412 984.844 616.154 984.599C615.895 984.346 615.601 984.15 615.27 984.011C614.94 983.872 614.568 983.803 614.157 983.803C613.609 983.803 613.108 983.938 612.657 984.207C612.213 984.476 611.834 984.881 611.519 985.419C611.205 985.95 610.963 986.611 610.793 987.403C610.624 988.187 610.539 989.097 610.539 990.134Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 1024.68H609.474V1022.7H613.54V1011.01L609.753 1013.09L608.978 1011.28L614.012 1008.6H615.875V1022.7H619.408V1024.68Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 1051.15H609.474V1049.17H613.54V1037.49L609.753 1039.57L608.978 1037.76L614.012 1035.07H615.875V1049.17H619.408V1051.15Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 1077.63H609.474V1075.64H613.54V1063.96L609.753 1066.04L608.978 1064.23L614.012 1061.55H615.875V1075.64H619.408V1077.63Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 1096.11C619.905 1097.33 619.784 1098.45 619.542 1099.46C619.3 1100.47 618.932 1101.33 618.44 1102.06C617.948 1102.78 617.335 1103.33 616.601 1103.74C615.875 1104.13 615.02 1104.32 614.036 1104.32C613.189 1104.32 612.419 1104.16 611.725 1103.85C611.031 1103.52 610.438 1103.02 609.946 1102.35C609.454 1101.68 609.075 1100.83 608.809 1099.79C608.543 1098.75 608.41 1097.53 608.41 1096.11C608.41 1094.88 608.531 1093.76 608.773 1092.75C609.023 1091.74 609.39 1090.87 609.874 1090.15C610.366 1089.43 610.979 1088.87 611.713 1088.48C612.447 1088.08 613.302 1087.88 614.278 1087.88C615.125 1087.88 615.895 1088.04 616.589 1088.37C617.283 1088.68 617.876 1089.18 618.368 1089.86C618.86 1090.53 619.239 1091.38 619.505 1092.42C619.771 1093.46 619.905 1094.68 619.905 1096.11ZM617.775 1096.18C617.775 1095.9 617.763 1095.63 617.739 1095.36C617.723 1095.08 617.702 1094.81 617.678 1094.55L610.987 1099.58C611.108 1100.01 611.261 1100.4 611.447 1100.76C611.632 1101.12 611.854 1101.43 612.112 1101.69C612.378 1101.94 612.677 1102.14 613.008 1102.29C613.346 1102.43 613.73 1102.5 614.157 1102.5C614.706 1102.5 615.202 1102.36 615.645 1102.09C616.097 1101.82 616.48 1101.42 616.795 1100.89C617.109 1100.36 617.351 1099.71 617.521 1098.92C617.69 1098.13 617.775 1097.22 617.775 1096.18ZM610.539 1096.03C610.539 1096.29 610.539 1096.54 610.539 1096.79C610.547 1097.04 610.563 1097.29 610.588 1097.53L617.279 1092.52C617.158 1092.1 617.005 1091.72 616.819 1091.38C616.634 1091.04 616.412 1090.74 616.154 1090.5C615.895 1090.24 615.601 1090.05 615.27 1089.91C614.94 1089.77 614.568 1089.7 614.157 1089.7C613.609 1089.7 613.108 1089.84 612.657 1090.1C612.213 1090.37 611.834 1090.78 611.519 1091.32C611.205 1091.85 610.963 1092.51 610.793 1093.3C610.624 1094.08 610.539 1095 610.539 1096.03Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 1130.58H609.474V1128.59H613.54V1116.91L609.753 1118.99L608.978 1117.18L614.012 1114.5H615.875V1128.59H619.408V1130.58Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 1149.05C619.905 1150.28 619.784 1151.4 619.542 1152.41C619.3 1153.41 618.932 1154.28 618.44 1155.01C617.948 1155.72 617.335 1156.28 616.601 1156.68C615.875 1157.08 615.02 1157.27 614.036 1157.27C613.189 1157.27 612.419 1157.11 611.725 1156.79C611.031 1156.47 610.438 1155.97 609.946 1155.3C609.454 1154.63 609.075 1153.78 608.809 1152.74C608.543 1151.7 608.41 1150.47 608.41 1149.05C608.41 1147.83 608.531 1146.71 608.773 1145.7C609.023 1144.69 609.39 1143.82 609.874 1143.1C610.366 1142.38 610.979 1141.82 611.713 1141.42C612.447 1141.02 613.302 1140.82 614.278 1140.82C615.125 1140.82 615.895 1140.99 616.589 1141.31C617.283 1141.63 617.876 1142.13 618.368 1142.81C618.86 1143.48 619.239 1144.33 619.505 1145.37C619.771 1146.4 619.905 1147.63 619.905 1149.05ZM617.775 1149.13C617.775 1148.85 617.763 1148.58 617.739 1148.31C617.723 1148.03 617.702 1147.76 617.678 1147.5L610.987 1152.53C611.108 1152.96 611.261 1153.35 611.447 1153.71C611.632 1154.07 611.854 1154.38 612.112 1154.64C612.378 1154.89 612.677 1155.09 613.008 1155.24C613.346 1155.38 613.73 1155.45 614.157 1155.45C614.706 1155.45 615.202 1155.31 615.645 1155.04C616.097 1154.77 616.48 1154.37 616.795 1153.84C617.109 1153.31 617.351 1152.65 617.521 1151.87C617.69 1151.08 617.775 1150.16 617.775 1149.13ZM610.539 1148.98C610.539 1149.23 610.539 1149.49 610.539 1149.74C610.547 1149.99 610.563 1150.24 610.588 1150.47L617.279 1145.47C617.158 1145.05 617.005 1144.67 616.819 1144.33C616.634 1143.98 616.412 1143.69 616.154 1143.45C615.895 1143.19 615.601 1143 615.27 1142.86C614.94 1142.72 614.568 1142.65 614.157 1142.65C613.609 1142.65 613.108 1142.78 612.657 1143.05C612.213 1143.32 611.834 1143.73 611.519 1144.27C611.205 1144.8 610.963 1145.46 610.793 1146.25C610.624 1147.03 610.539 1147.94 610.539 1148.98Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.905 1175.53C619.905 1176.75 619.784 1177.87 619.542 1178.88C619.3 1179.89 618.932 1180.75 618.44 1181.48C617.948 1182.2 617.335 1182.76 616.601 1183.16C615.875 1183.55 615.02 1183.75 614.036 1183.75C613.189 1183.75 612.419 1183.59 611.725 1183.27C611.031 1182.94 610.438 1182.44 609.946 1181.77C609.454 1181.1 609.075 1180.25 608.809 1179.21C608.543 1178.18 608.41 1176.95 608.41 1175.53C608.41 1174.3 608.531 1173.19 608.773 1172.17C609.023 1171.16 609.39 1170.3 609.874 1169.58C610.366 1168.85 610.979 1168.29 611.713 1167.9C612.447 1167.5 613.302 1167.3 614.278 1167.3C615.125 1167.3 615.895 1167.46 616.589 1167.79C617.283 1168.11 617.876 1168.61 618.368 1169.28C618.86 1169.95 619.239 1170.81 619.505 1171.84C619.771 1172.88 619.905 1174.11 619.905 1175.53ZM617.775 1175.6C617.775 1175.32 617.763 1175.05 617.739 1174.78C617.723 1174.5 617.702 1174.23 617.678 1173.97L610.987 1179.01C611.108 1179.43 611.261 1179.82 611.447 1180.18C611.632 1180.54 611.854 1180.85 612.112 1181.11C612.378 1181.37 612.677 1181.57 613.008 1181.71C613.346 1181.85 613.73 1181.92 614.157 1181.92C614.706 1181.92 615.202 1181.79 615.645 1181.52C616.097 1181.25 616.48 1180.85 616.795 1180.32C617.109 1179.79 617.351 1179.13 617.521 1178.35C617.69 1177.55 617.775 1176.64 617.775 1175.6ZM610.539 1175.46C610.539 1175.71 610.539 1175.96 610.539 1176.21C610.547 1176.47 610.563 1176.71 610.588 1176.95L617.279 1171.94C617.158 1171.52 617.005 1171.14 616.819 1170.8C616.634 1170.46 616.412 1170.16 616.154 1169.92C615.895 1169.67 615.601 1169.47 615.27 1169.33C614.94 1169.19 614.568 1169.12 614.157 1169.12C613.609 1169.12 613.108 1169.26 612.657 1169.53C612.213 1169.8 611.834 1170.2 611.519 1170.74C611.205 1171.27 610.963 1171.93 610.793 1172.72C610.624 1173.51 610.539 1174.42 610.539 1175.46Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M619.408 1210H609.474V1208.02H613.54V1196.33L609.753 1198.41L608.978 1196.6L614.012 1193.92H615.875V1208.02H619.408V1210Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M825.896 345.402H815.41V343.308H819.701V330.976L815.703 333.173L814.886 331.26L820.199 328.429H822.166V343.308H825.896V345.402Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 373.27H815.41V371.176H819.701V358.843L815.703 361.041L814.886 359.128L820.199 356.297H822.166V371.176H825.896V373.27Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 392.696C826.419 393.989 826.292 395.17 826.036 396.238C825.781 397.298 825.393 398.212 824.874 398.979C824.354 399.737 823.707 400.328 822.932 400.75C822.166 401.163 821.264 401.37 820.225 401.37C819.331 401.37 818.518 401.202 817.785 400.866C817.053 400.521 816.427 399.996 815.908 399.289C815.388 398.582 814.988 397.682 814.707 396.587C814.426 395.493 814.286 394.196 814.286 392.696C814.286 391.404 814.413 390.223 814.669 389.154C814.933 388.086 815.32 387.172 815.831 386.414C816.351 385.647 816.998 385.056 817.773 384.643C818.547 384.221 819.45 384.009 820.48 384.009C821.374 384.009 822.187 384.182 822.92 384.526C823.652 384.863 824.278 385.388 824.797 386.104C825.317 386.81 825.717 387.711 825.998 388.805C826.279 389.9 826.419 391.197 826.419 392.696ZM824.171 392.774C824.171 392.481 824.159 392.192 824.133 391.908C824.116 391.615 824.095 391.33 824.069 391.055L817.006 396.368C817.134 396.816 817.296 397.229 817.492 397.609C817.687 397.988 817.922 398.315 818.194 398.591C818.475 398.858 818.79 399.069 819.139 399.224C819.497 399.371 819.901 399.444 820.353 399.444C820.932 399.444 821.455 399.302 821.923 399.018C822.4 398.733 822.805 398.311 823.137 397.751C823.469 397.191 823.724 396.497 823.903 395.67C824.082 394.834 824.171 393.868 824.171 392.774ZM816.534 392.619C816.534 392.886 816.534 393.153 816.534 393.42C816.542 393.687 816.559 393.946 816.585 394.196L823.648 388.909C823.52 388.469 823.358 388.068 823.162 387.707C822.967 387.345 822.732 387.034 822.46 386.776C822.187 386.509 821.877 386.302 821.528 386.155C821.178 386.009 820.787 385.936 820.353 385.936C819.774 385.936 819.246 386.078 818.769 386.362C818.3 386.647 817.9 387.073 817.568 387.642C817.236 388.202 816.981 388.9 816.802 389.736C816.623 390.563 816.534 391.524 816.534 392.619Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 420.564C826.419 421.857 826.292 423.037 826.036 424.106C825.781 425.166 825.393 426.08 824.874 426.847C824.354 427.605 823.707 428.195 822.932 428.618C822.166 429.031 821.264 429.238 820.225 429.238C819.331 429.238 818.518 429.07 817.785 428.734C817.053 428.389 816.427 427.864 815.908 427.157C815.388 426.45 814.988 425.55 814.707 424.455C814.426 423.361 814.286 422.064 814.286 420.564C814.286 419.271 814.413 418.091 814.669 417.022C814.933 415.953 815.32 415.04 815.831 414.282C816.351 413.515 816.998 412.924 817.773 412.511C818.547 412.088 819.45 411.877 820.48 411.877C821.374 411.877 822.187 412.05 822.92 412.394C823.652 412.73 824.278 413.256 824.797 413.971C825.317 414.678 825.717 415.579 825.998 416.673C826.279 417.768 826.419 419.065 826.419 420.564ZM824.171 420.642C824.171 420.349 824.159 420.06 824.133 419.776C824.116 419.483 824.095 419.198 824.069 418.922L817.006 424.235C817.134 424.683 817.296 425.097 817.492 425.476C817.687 425.856 817.922 426.183 818.194 426.459C818.475 426.726 818.79 426.937 819.139 427.092C819.497 427.239 819.901 427.312 820.353 427.312C820.932 427.312 821.455 427.17 821.923 426.885C822.4 426.601 822.805 426.179 823.137 425.619C823.469 425.058 823.724 424.365 823.903 423.537C824.082 422.701 824.171 421.736 824.171 420.642ZM816.534 420.487C816.534 420.754 816.534 421.021 816.534 421.288C816.542 421.555 816.559 421.814 816.585 422.064L823.648 416.777C823.52 416.337 823.358 415.936 823.162 415.574C822.967 415.212 822.732 414.902 822.46 414.644C822.187 414.376 821.877 414.17 821.528 414.023C821.178 413.877 820.787 413.803 820.353 413.803C819.774 413.803 819.246 413.946 818.769 414.23C818.3 414.514 817.9 414.941 817.568 415.51C817.236 416.07 816.981 416.768 816.802 417.604C816.623 418.431 816.534 419.392 816.534 420.487Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 448.432C826.419 449.725 826.292 450.905 826.036 451.974C825.781 453.034 825.393 453.947 824.874 454.714C824.354 455.473 823.707 456.063 822.932 456.485C822.166 456.899 821.264 457.106 820.225 457.106C819.331 457.106 818.518 456.938 817.785 456.602C817.053 456.257 816.427 455.731 815.908 455.025C815.388 454.318 814.988 453.417 814.707 452.323C814.426 451.228 814.286 449.931 814.286 448.432C814.286 447.139 814.413 445.959 814.669 444.89C814.933 443.821 815.32 442.908 815.831 442.149C816.351 441.382 816.998 440.792 817.773 440.378C818.547 439.956 819.45 439.745 820.48 439.745C821.374 439.745 822.187 439.917 822.92 440.262C823.652 440.598 824.278 441.124 824.797 441.839C825.317 442.546 825.717 443.446 825.998 444.541C826.279 445.635 826.419 446.932 826.419 448.432ZM824.171 448.509C824.171 448.216 824.159 447.928 824.133 447.643C824.116 447.35 824.095 447.066 824.069 446.79L817.006 452.103C817.134 452.551 817.296 452.965 817.492 453.344C817.687 453.723 817.922 454.051 818.194 454.327C818.475 454.594 818.79 454.805 819.139 454.96C819.497 455.106 819.901 455.18 820.353 455.18C820.932 455.18 821.455 455.038 821.923 454.753C822.4 454.469 822.805 454.046 823.137 453.486C823.469 452.926 823.724 452.232 823.903 451.405C824.082 450.569 824.171 449.604 824.171 448.509ZM816.534 448.354C816.534 448.621 816.534 448.889 816.534 449.156C816.542 449.423 816.559 449.681 816.585 449.931L823.648 444.644C823.52 444.205 823.358 443.804 823.162 443.442C822.967 443.08 822.732 442.77 822.46 442.511C822.187 442.244 821.877 442.037 821.528 441.891C821.178 441.744 820.787 441.671 820.353 441.671C819.774 441.671 819.246 441.813 818.769 442.098C818.3 442.382 817.9 442.809 817.568 443.377C817.236 443.938 816.981 444.636 816.802 445.472C816.623 446.299 816.534 447.26 816.534 448.354Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 484.741H815.41V482.647H819.701V470.314L815.703 472.512L814.886 470.599L820.199 467.768H822.166V482.647H825.896V484.741Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 504.167C826.419 505.46 826.292 506.641 826.036 507.709C825.781 508.769 825.393 509.683 824.874 510.45C824.354 511.208 823.707 511.799 822.932 512.221C822.166 512.635 821.264 512.841 820.225 512.841C819.331 512.841 818.518 512.673 817.785 512.337C817.053 511.993 816.427 511.467 815.908 510.76C815.388 510.054 814.988 509.153 814.707 508.058C814.426 506.964 814.286 505.667 814.286 504.167C814.286 502.875 814.413 501.694 814.669 500.625C814.933 499.557 815.32 498.643 815.831 497.885C816.351 497.118 816.998 496.528 817.773 496.114C818.547 495.692 819.45 495.481 820.48 495.481C821.374 495.481 822.187 495.653 822.92 495.998C823.652 496.334 824.278 496.859 824.797 497.575C825.317 498.281 825.717 499.182 825.998 500.276C826.279 501.371 826.419 502.668 826.419 504.167ZM824.171 504.245C824.171 503.952 824.159 503.663 824.133 503.379C824.116 503.086 824.095 502.801 824.069 502.526L817.006 507.839C817.134 508.287 817.296 508.701 817.492 509.08C817.687 509.459 817.922 509.786 818.194 510.062C818.475 510.329 818.79 510.54 819.139 510.696C819.497 510.842 819.901 510.915 820.353 510.915C820.932 510.915 821.455 510.773 821.923 510.489C822.4 510.204 822.805 509.782 823.137 509.222C823.469 508.662 823.724 507.968 823.903 507.141C824.082 506.305 824.171 505.339 824.171 504.245ZM816.534 504.09C816.534 504.357 816.534 504.624 816.534 504.891C816.542 505.159 816.559 505.417 816.585 505.667L823.648 500.38C823.52 499.94 823.358 499.54 823.162 499.178C822.967 498.816 822.732 498.505 822.46 498.247C822.187 497.98 821.877 497.773 821.528 497.626C821.178 497.48 820.787 497.407 820.353 497.407C819.774 497.407 819.246 497.549 818.769 497.833C818.3 498.118 817.9 498.544 817.568 499.113C817.236 499.673 816.981 500.371 816.802 501.207C816.623 502.035 816.534 502.995 816.534 504.09Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 540.477H815.41V538.382H819.701V526.05L815.703 528.248L814.886 526.334L820.199 523.503H822.166V538.382H825.896V540.477Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 568.344H815.41V566.25H819.701V553.918L815.703 556.115L814.886 554.202L820.199 551.371H822.166V566.25H825.896V568.344Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 587.771C826.419 589.063 826.292 590.244 826.036 591.313C825.781 592.373 825.393 593.286 824.874 594.053C824.354 594.812 823.707 595.402 822.932 595.824C822.166 596.238 821.264 596.445 820.225 596.445C819.331 596.445 818.518 596.277 817.785 595.941C817.053 595.596 816.427 595.07 815.908 594.364C815.388 593.657 814.988 592.756 814.707 591.662C814.426 590.567 814.286 589.27 814.286 587.771C814.286 586.478 814.413 585.297 814.669 584.229C814.933 583.16 815.32 582.247 815.831 581.488C816.351 580.721 816.998 580.131 817.773 579.717C818.547 579.295 819.45 579.084 820.48 579.084C821.374 579.084 822.187 579.256 822.92 579.601C823.652 579.937 824.278 580.463 824.797 581.178C825.317 581.885 825.717 582.785 825.998 583.88C826.279 584.974 826.419 586.271 826.419 587.771ZM824.171 587.848C824.171 587.555 824.159 587.267 824.133 586.982C824.116 586.689 824.095 586.405 824.069 586.129L817.006 591.442C817.134 591.89 817.296 592.304 817.492 592.683C817.687 593.062 817.922 593.39 818.194 593.665C818.475 593.933 818.79 594.144 819.139 594.299C819.497 594.445 819.901 594.519 820.353 594.519C820.932 594.519 821.455 594.376 821.923 594.092C822.4 593.808 822.805 593.385 823.137 592.825C823.469 592.265 823.724 591.571 823.903 590.744C824.082 589.908 824.171 588.943 824.171 587.848ZM816.534 587.693C816.534 587.96 816.534 588.228 816.534 588.495C816.542 588.762 816.559 589.02 816.585 589.27L823.648 583.983C823.52 583.544 823.358 583.143 823.162 582.781C822.967 582.419 822.732 582.109 822.46 581.85C822.187 581.583 821.877 581.376 821.528 581.23C821.178 581.083 820.787 581.01 820.353 581.01C819.774 581.01 819.246 581.152 818.769 581.437C818.3 581.721 817.9 582.148 817.568 582.716C817.236 583.277 816.981 583.975 816.802 584.811C816.623 585.638 816.534 586.599 816.534 587.693Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 615.639C826.419 616.931 826.292 618.112 826.036 619.181C825.781 620.241 825.393 621.154 824.874 621.921C824.354 622.679 823.707 623.27 822.932 623.692C822.166 624.106 821.264 624.313 820.225 624.313C819.331 624.313 818.518 624.145 817.785 623.808C817.053 623.464 816.427 622.938 815.908 622.231C815.388 621.525 814.988 620.624 814.707 619.53C814.426 618.435 814.286 617.138 814.286 615.639C814.286 614.346 814.413 613.165 814.669 612.097C814.933 611.028 815.32 610.114 815.831 609.356C816.351 608.589 816.998 607.999 817.773 607.585C818.547 607.163 819.45 606.952 820.48 606.952C821.374 606.952 822.187 607.124 822.92 607.469C823.652 607.805 824.278 608.331 824.797 609.046C825.317 609.753 825.717 610.653 825.998 611.748C826.279 612.842 826.419 614.139 826.419 615.639ZM824.171 615.716C824.171 615.423 824.159 615.134 824.133 614.85C824.116 614.557 824.095 614.273 824.069 613.997L817.006 619.31C817.134 619.758 817.296 620.172 817.492 620.551C817.687 620.93 817.922 621.257 818.194 621.533C818.475 621.8 818.79 622.012 819.139 622.167C819.497 622.313 819.901 622.386 820.353 622.386C820.932 622.386 821.455 622.244 821.923 621.96C822.4 621.675 822.805 621.253 823.137 620.693C823.469 620.133 823.724 619.439 823.903 618.612C824.082 617.776 824.171 616.811 824.171 615.716ZM816.534 615.561C816.534 615.828 816.534 616.095 816.534 616.362C816.542 616.63 816.559 616.888 816.585 617.138L823.648 611.851C823.52 611.411 823.358 611.011 823.162 610.649C822.967 610.287 822.732 609.977 822.46 609.718C822.187 609.451 821.877 609.244 821.528 609.098C821.178 608.951 820.787 608.878 820.353 608.878C819.774 608.878 819.246 609.02 818.769 609.304C818.3 609.589 817.9 610.015 817.568 610.584C817.236 611.144 816.981 611.842 816.802 612.678C816.623 613.506 816.534 614.467 816.534 615.561Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 643.506C826.419 644.799 826.292 645.98 826.036 647.048C825.781 648.108 825.393 649.022 824.874 649.789C824.354 650.547 823.707 651.138 822.932 651.56C822.166 651.974 821.264 652.18 820.225 652.18C819.331 652.18 818.518 652.012 817.785 651.676C817.053 651.331 816.427 650.806 815.908 650.099C815.388 649.392 814.988 648.492 814.707 647.397C814.426 646.303 814.286 645.006 814.286 643.506C814.286 642.214 814.413 641.033 814.669 639.964C814.933 638.896 815.32 637.982 815.831 637.224C816.351 636.457 816.998 635.867 817.773 635.453C818.547 635.031 819.45 634.819 820.48 634.819C821.374 634.819 822.187 634.992 822.92 635.337C823.652 635.673 824.278 636.198 824.797 636.914C825.317 637.62 825.717 638.521 825.998 639.615C826.279 640.71 826.419 642.007 826.419 643.506ZM824.171 643.584C824.171 643.291 824.159 643.002 824.133 642.718C824.116 642.425 824.095 642.14 824.069 641.865L817.006 647.178C817.134 647.626 817.296 648.039 817.492 648.419C817.687 648.798 817.922 649.125 818.194 649.401C818.475 649.668 818.79 649.879 819.139 650.034C819.497 650.181 819.901 650.254 820.353 650.254C820.932 650.254 821.455 650.112 821.923 649.828C822.4 649.543 822.805 649.121 823.137 648.561C823.469 648.001 823.724 647.307 823.903 646.48C824.082 645.644 824.171 644.678 824.171 643.584ZM816.534 643.429C816.534 643.696 816.534 643.963 816.534 644.23C816.542 644.497 816.559 644.756 816.585 645.006L823.648 639.719C823.52 639.279 823.358 638.879 823.162 638.517C822.967 638.155 822.732 637.844 822.46 637.586C822.187 637.319 821.877 637.112 821.528 636.965C821.178 636.819 820.787 636.746 820.353 636.746C819.774 636.746 819.246 636.888 818.769 637.172C818.3 637.457 817.9 637.883 817.568 638.452C817.236 639.012 816.981 639.71 816.802 640.546C816.623 641.373 816.534 642.334 816.534 643.429Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 679.815H815.41V677.721H819.701V665.389L815.703 667.587L814.886 665.673L820.199 662.842H822.166V677.721H825.896V679.815Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 707.683H815.41V705.589H819.701V693.257L815.703 695.454L814.886 693.541L820.199 690.71H822.166V705.589H825.896V707.683Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 735.551H815.41V733.457H819.701V721.125L815.703 723.322L814.886 721.409L820.199 718.578H822.166V733.457H825.896V735.551Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 754.977C826.419 756.27 826.292 757.451 826.036 758.519C825.781 759.579 825.393 760.493 824.874 761.26C824.354 762.018 823.707 762.609 822.932 763.031C822.166 763.445 821.264 763.651 820.225 763.651C819.331 763.651 818.518 763.483 817.785 763.147C817.053 762.803 816.427 762.277 815.908 761.57C815.388 760.864 814.988 759.963 814.707 758.869C814.426 757.774 814.286 756.477 814.286 754.977C814.286 753.685 814.413 752.504 814.669 751.436C814.933 750.367 815.32 749.453 815.831 748.695C816.351 747.928 816.998 747.338 817.773 746.924C818.547 746.502 819.45 746.291 820.48 746.291C821.374 746.291 822.187 746.463 822.92 746.808C823.652 747.144 824.278 747.669 824.797 748.385C825.317 749.091 825.717 749.992 825.998 751.086C826.279 752.181 826.419 753.478 826.419 754.977ZM824.171 755.055C824.171 754.762 824.159 754.473 824.133 754.189C824.116 753.896 824.095 753.612 824.069 753.336L817.006 758.649C817.134 759.097 817.296 759.511 817.492 759.89C817.687 760.269 817.922 760.596 818.194 760.872C818.475 761.139 818.79 761.35 819.139 761.506C819.497 761.652 819.901 761.725 820.353 761.725C820.932 761.725 821.455 761.583 821.923 761.299C822.4 761.014 822.805 760.592 823.137 760.032C823.469 759.472 823.724 758.778 823.903 757.951C824.082 757.115 824.171 756.15 824.171 755.055ZM816.534 754.9C816.534 755.167 816.534 755.434 816.534 755.701C816.542 755.969 816.559 756.227 816.585 756.477L823.648 751.19C823.52 750.75 823.358 750.35 823.162 749.988C822.967 749.626 822.732 749.315 822.46 749.057C822.187 748.79 821.877 748.583 821.528 748.436C821.178 748.29 820.787 748.217 820.353 748.217C819.774 748.217 819.246 748.359 818.769 748.643C818.3 748.928 817.9 749.354 817.568 749.923C817.236 750.483 816.981 751.181 816.802 752.017C816.623 752.845 816.534 753.805 816.534 754.9Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 791.287H815.41V789.192H819.701V776.86L815.703 779.058L814.886 777.144L820.199 774.313H822.166V789.192H825.896V791.287Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 810.713C826.419 812.006 826.292 813.186 826.036 814.255C825.781 815.315 825.393 816.229 824.874 816.996C824.354 817.754 823.707 818.344 822.932 818.767C822.166 819.18 821.264 819.387 820.225 819.387C819.331 819.387 818.518 819.219 817.785 818.883C817.053 818.538 816.427 818.012 815.908 817.306C815.388 816.599 814.988 815.699 814.707 814.604C814.426 813.51 814.286 812.213 814.286 810.713C814.286 809.42 814.413 808.24 814.669 807.171C814.933 806.102 815.32 805.189 815.831 804.431C816.351 803.664 816.998 803.073 817.773 802.66C818.547 802.237 819.45 802.026 820.48 802.026C821.374 802.026 822.187 802.199 822.92 802.543C823.652 802.879 824.278 803.405 824.797 804.12C825.317 804.827 825.717 805.728 825.998 806.822C826.279 807.917 826.419 809.214 826.419 810.713ZM824.171 810.791C824.171 810.498 824.159 810.209 824.133 809.925C824.116 809.631 824.095 809.347 824.069 809.071L817.006 814.384C817.134 814.832 817.296 815.246 817.492 815.625C817.687 816.004 817.922 816.332 818.194 816.608C818.475 816.875 818.79 817.086 819.139 817.241C819.497 817.388 819.901 817.461 820.353 817.461C820.932 817.461 821.455 817.319 821.923 817.034C822.4 816.75 822.805 816.328 823.137 815.768C823.469 815.207 823.724 814.514 823.903 813.686C824.082 812.85 824.171 811.885 824.171 810.791ZM816.534 810.635C816.534 810.903 816.534 811.17 816.534 811.437C816.542 811.704 816.559 811.963 816.585 812.213L823.648 806.925C823.52 806.486 823.358 806.085 823.162 805.723C822.967 805.361 822.732 805.051 822.46 804.792C822.187 804.525 821.877 804.319 821.528 804.172C821.178 804.025 820.787 803.952 820.353 803.952C819.774 803.952 819.246 804.094 818.769 804.379C818.3 804.663 817.9 805.09 817.568 805.659C817.236 806.219 816.981 806.917 816.802 807.753C816.623 808.58 816.534 809.541 816.534 810.635Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M826.419 838.581C826.419 839.874 826.292 841.054 826.036 842.123C825.781 843.183 825.393 844.096 824.874 844.863C824.354 845.622 823.707 846.212 822.932 846.634C822.166 847.048 821.264 847.255 820.225 847.255C819.331 847.255 818.518 847.087 817.785 846.751C817.053 846.406 816.427 845.88 815.908 845.174C815.388 844.467 814.988 843.566 814.707 842.472C814.426 841.377 814.286 840.08 814.286 838.581C814.286 837.288 814.413 836.107 814.669 835.039C814.933 833.97 815.32 833.057 815.831 832.298C816.351 831.531 816.998 830.941 817.773 830.527C818.547 830.105 819.45 829.894 820.48 829.894C821.374 829.894 822.187 830.066 822.92 830.411C823.652 830.747 824.278 831.273 824.797 831.988C825.317 832.695 825.717 833.595 825.998 834.69C826.279 835.784 826.419 837.081 826.419 838.581ZM824.171 838.658C824.171 838.365 824.159 838.077 824.133 837.792C824.116 837.499 824.095 837.215 824.069 836.939L817.006 842.252C817.134 842.7 817.296 843.114 817.492 843.493C817.687 843.872 817.922 844.2 818.194 844.476C818.475 844.743 818.79 844.954 819.139 845.109C819.497 845.255 819.901 845.329 820.353 845.329C820.932 845.329 821.455 845.186 821.923 844.902C822.4 844.618 822.805 844.195 823.137 843.635C823.469 843.075 823.724 842.381 823.903 841.554C824.082 840.718 824.171 839.753 824.171 838.658ZM816.534 838.503C816.534 838.77 816.534 839.038 816.534 839.305C816.542 839.572 816.559 839.83 816.585 840.08L823.648 834.793C823.52 834.354 823.358 833.953 823.162 833.591C822.967 833.229 822.732 832.919 822.46 832.66C822.187 832.393 821.877 832.186 821.528 832.04C821.178 831.893 820.787 831.82 820.353 831.82C819.774 831.82 819.246 831.962 818.769 832.247C818.3 832.531 817.9 832.958 817.568 833.526C817.236 834.087 816.981 834.785 816.802 835.621C816.623 836.448 816.534 837.409 816.534 838.503Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M825.896 874.89H815.41V872.796H819.701V860.463L815.703 862.661L814.886 860.748L820.199 857.917H822.166V872.796H825.896V874.89Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 203.973H584.124V201.879H588.415V189.547L584.418 191.744L583.6 189.831L588.914 187H590.88V201.879H594.61V203.973Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 231.841H584.124V229.747H588.415V217.414L584.418 219.612L583.6 217.699L588.914 214.868H590.88V229.747H594.61V231.841Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 251.267C595.134 252.56 595.006 253.741 594.75 254.809C594.495 255.869 594.108 256.783 593.588 257.55C593.069 258.308 592.422 258.899 591.647 259.321C590.88 259.735 589.978 259.941 588.939 259.941C588.045 259.941 587.232 259.773 586.5 259.437C585.767 259.092 585.141 258.567 584.622 257.86C584.103 257.153 583.702 256.253 583.421 255.158C583.14 254.064 583 252.767 583 251.267C583 249.975 583.128 248.794 583.383 247.725C583.647 246.657 584.035 245.743 584.545 244.985C585.065 244.218 585.712 243.628 586.487 243.214C587.262 242.792 588.164 242.58 589.195 242.58C590.089 242.58 590.902 242.753 591.634 243.098C592.366 243.434 592.992 243.959 593.512 244.675C594.031 245.381 594.431 246.282 594.712 247.376C594.993 248.471 595.134 249.768 595.134 251.267ZM592.886 251.345C592.886 251.052 592.873 250.763 592.847 250.479C592.83 250.186 592.809 249.901 592.784 249.626L585.72 254.939C585.848 255.387 586.01 255.8 586.206 256.18C586.402 256.559 586.636 256.886 586.908 257.162C587.189 257.429 587.504 257.64 587.853 257.795C588.211 257.942 588.616 258.015 589.067 258.015C589.646 258.015 590.169 257.873 590.638 257.589C591.115 257.304 591.519 256.882 591.851 256.322C592.183 255.762 592.439 255.068 592.617 254.241C592.796 253.405 592.886 252.439 592.886 251.345ZM585.248 251.19C585.248 251.457 585.248 251.724 585.248 251.991C585.256 252.258 585.273 252.517 585.299 252.767L592.362 247.48C592.234 247.04 592.073 246.64 591.877 246.278C591.681 245.916 591.447 245.605 591.174 245.347C590.902 245.08 590.591 244.873 590.242 244.726C589.893 244.58 589.501 244.507 589.067 244.507C588.488 244.507 587.96 244.649 587.483 244.933C587.015 245.218 586.615 245.644 586.282 246.213C585.95 246.773 585.695 247.471 585.516 248.307C585.337 249.134 585.248 250.095 585.248 251.19Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 279.135C595.134 280.428 595.006 281.608 594.75 282.677C594.495 283.737 594.108 284.651 593.588 285.418C593.069 286.176 592.422 286.766 591.647 287.189C590.88 287.602 589.978 287.809 588.939 287.809C588.045 287.809 587.232 287.641 586.5 287.305C585.767 286.96 585.141 286.435 584.622 285.728C584.103 285.021 583.702 284.121 583.421 283.026C583.14 281.932 583 280.635 583 279.135C583 277.842 583.128 276.662 583.383 275.593C583.647 274.525 584.035 273.611 584.545 272.853C585.065 272.086 585.712 271.495 586.487 271.082C587.262 270.659 588.164 270.448 589.195 270.448C590.089 270.448 590.902 270.621 591.634 270.965C592.366 271.301 592.992 271.827 593.512 272.542C594.031 273.249 594.431 274.15 594.712 275.244C594.993 276.339 595.134 277.636 595.134 279.135ZM592.886 279.213C592.886 278.92 592.873 278.631 592.847 278.347C592.83 278.054 592.809 277.769 592.784 277.493L585.72 282.806C585.848 283.255 586.01 283.668 586.206 284.047C586.402 284.427 586.636 284.754 586.908 285.03C587.189 285.297 587.504 285.508 587.853 285.663C588.211 285.81 588.616 285.883 589.067 285.883C589.646 285.883 590.169 285.741 590.638 285.456C591.115 285.172 591.519 284.75 591.851 284.19C592.183 283.629 592.439 282.936 592.617 282.108C592.796 281.272 592.886 280.307 592.886 279.213ZM585.248 279.058C585.248 279.325 585.248 279.592 585.248 279.859C585.256 280.126 585.273 280.385 585.299 280.635L592.362 275.348C592.234 274.908 592.073 274.507 591.877 274.145C591.681 273.783 591.447 273.473 591.174 273.215C590.902 272.947 590.591 272.741 590.242 272.594C589.893 272.448 589.501 272.374 589.067 272.374C588.488 272.374 587.96 272.517 587.483 272.801C587.015 273.085 586.615 273.512 586.282 274.081C585.95 274.641 585.695 275.339 585.516 276.175C585.337 277.002 585.248 277.963 585.248 279.058Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 307.003C595.134 308.296 595.006 309.476 594.75 310.545C594.495 311.605 594.108 312.518 593.588 313.285C593.069 314.044 592.422 314.634 591.647 315.056C590.88 315.47 589.978 315.677 588.939 315.677C588.045 315.677 587.232 315.509 586.5 315.173C585.767 314.828 585.141 314.302 584.622 313.596C584.103 312.889 583.702 311.988 583.421 310.894C583.14 309.799 583 308.502 583 307.003C583 305.71 583.128 304.53 583.383 303.461C583.647 302.392 584.035 301.479 584.545 300.72C585.065 299.953 585.712 299.363 586.487 298.949C587.262 298.527 588.164 298.316 589.195 298.316C590.089 298.316 590.902 298.488 591.634 298.833C592.366 299.169 592.992 299.695 593.512 300.41C594.031 301.117 594.431 302.017 594.712 303.112C594.993 304.206 595.134 305.503 595.134 307.003ZM592.886 307.08C592.886 306.787 592.873 306.499 592.847 306.214C592.83 305.921 592.809 305.637 592.784 305.361L585.72 310.674C585.848 311.122 586.01 311.536 586.206 311.915C586.402 312.294 586.636 312.622 586.908 312.898C587.189 313.165 587.504 313.376 587.853 313.531C588.211 313.678 588.616 313.751 589.067 313.751C589.646 313.751 590.169 313.609 590.638 313.324C591.115 313.04 591.519 312.618 591.851 312.057C592.183 311.497 592.439 310.803 592.617 309.976C592.796 309.14 592.886 308.175 592.886 307.08ZM585.248 306.925C585.248 307.193 585.248 307.46 585.248 307.727C585.256 307.994 585.273 308.253 585.299 308.502L592.362 303.215C592.234 302.776 592.073 302.375 591.877 302.013C591.681 301.651 591.447 301.341 591.174 301.082C590.902 300.815 590.591 300.608 590.242 300.462C589.893 300.315 589.501 300.242 589.067 300.242C588.488 300.242 587.96 300.384 587.483 300.669C587.015 300.953 586.615 301.38 586.282 301.948C585.95 302.509 585.695 303.207 585.516 304.043C585.337 304.87 585.248 305.831 585.248 306.925Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 343.312H584.124V341.218H588.415V328.886L584.418 331.083L583.6 329.17L588.914 326.339H590.88V341.218H594.61V343.312Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 362.738C595.134 364.031 595.006 365.212 594.75 366.28C594.495 367.34 594.108 368.254 593.588 369.021C593.069 369.779 592.422 370.37 591.647 370.792C590.88 371.206 589.978 371.412 588.939 371.412C588.045 371.412 587.232 371.244 586.5 370.908C585.767 370.564 585.141 370.038 584.622 369.331C584.103 368.625 583.702 367.724 583.421 366.629C583.14 365.535 583 364.238 583 362.738C583 361.446 583.128 360.265 583.383 359.197C583.647 358.128 584.035 357.214 584.545 356.456C585.065 355.689 585.712 355.099 586.487 354.685C587.262 354.263 588.164 354.052 589.195 354.052C590.089 354.052 590.902 354.224 591.634 354.569C592.366 354.905 592.992 355.43 593.512 356.146C594.031 356.852 594.431 357.753 594.712 358.847C594.993 359.942 595.134 361.239 595.134 362.738ZM592.886 362.816C592.886 362.523 592.873 362.234 592.847 361.95C592.83 361.657 592.809 361.373 592.784 361.097L585.72 366.41C585.848 366.858 586.01 367.272 586.206 367.651C586.402 368.03 586.636 368.357 586.908 368.633C587.189 368.9 587.504 369.111 587.853 369.267C588.211 369.413 588.616 369.486 589.067 369.486C589.646 369.486 590.169 369.344 590.638 369.06C591.115 368.775 591.519 368.353 591.851 367.793C592.183 367.233 592.439 366.539 592.617 365.712C592.796 364.876 592.886 363.911 592.886 362.816ZM585.248 362.661C585.248 362.928 585.248 363.195 585.248 363.462C585.256 363.73 585.273 363.988 585.299 364.238L592.362 358.951C592.234 358.511 592.073 358.111 591.877 357.749C591.681 357.387 591.447 357.076 591.174 356.818C590.902 356.551 590.591 356.344 590.242 356.197C589.893 356.051 589.501 355.978 589.067 355.978C588.488 355.978 587.96 356.12 587.483 356.404C587.015 356.689 586.615 357.115 586.282 357.684C585.95 358.244 585.695 358.942 585.516 359.778C585.337 360.606 585.248 361.566 585.248 362.661Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 399.048H584.124V396.953H588.415V384.621L584.418 386.819L583.6 384.905L588.914 382.074H590.88V396.953H594.61V399.048Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 426.915H584.124V424.821H588.415V412.489L584.418 414.686L583.6 412.773L588.914 409.942H590.88V424.821H594.61V426.915Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 446.342C595.134 447.635 595.006 448.815 594.75 449.884C594.495 450.944 594.108 451.857 593.588 452.624C593.069 453.383 592.422 453.973 591.647 454.395C590.88 454.809 589.978 455.016 588.939 455.016C588.045 455.016 587.232 454.848 586.5 454.512C585.767 454.167 585.141 453.641 584.622 452.935C584.103 452.228 583.702 451.327 583.421 450.233C583.14 449.138 583 447.841 583 446.342C583 445.049 583.128 443.868 583.383 442.8C583.647 441.731 584.035 440.818 584.545 440.059C585.065 439.292 585.712 438.702 586.487 438.288C587.262 437.866 588.164 437.655 589.195 437.655C590.089 437.655 590.902 437.827 591.634 438.172C592.366 438.508 592.992 439.034 593.512 439.749C594.031 440.456 594.431 441.356 594.712 442.451C594.993 443.545 595.134 444.842 595.134 446.342ZM592.886 446.419C592.886 446.126 592.873 445.838 592.847 445.553C592.83 445.26 592.809 444.976 592.784 444.7L585.72 450.013C585.848 450.461 586.01 450.875 586.206 451.254C586.402 451.633 586.636 451.961 586.908 452.237C587.189 452.504 587.504 452.715 587.853 452.87C588.211 453.016 588.616 453.09 589.067 453.09C589.646 453.09 590.169 452.948 590.638 452.663C591.115 452.379 591.519 451.956 591.851 451.396C592.183 450.836 592.439 450.142 592.617 449.315C592.796 448.479 592.886 447.514 592.886 446.419ZM585.248 446.264C585.248 446.531 585.248 446.799 585.248 447.066C585.256 447.333 585.273 447.591 585.299 447.841L592.362 442.554C592.234 442.115 592.073 441.714 591.877 441.352C591.681 440.99 591.447 440.68 591.174 440.421C590.902 440.154 590.591 439.947 590.242 439.801C589.893 439.654 589.501 439.581 589.067 439.581C588.488 439.581 587.96 439.723 587.483 440.008C587.015 440.292 586.615 440.719 586.282 441.287C585.95 441.848 585.695 442.546 585.516 443.382C585.337 444.209 585.248 445.17 585.248 446.264Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 474.21C595.134 475.502 595.006 476.683 594.75 477.752C594.495 478.812 594.108 479.725 593.588 480.492C593.069 481.25 592.422 481.841 591.647 482.263C590.88 482.677 589.978 482.884 588.939 482.884C588.045 482.884 587.232 482.716 586.5 482.379C585.767 482.035 585.141 481.509 584.622 480.802C584.103 480.096 583.702 479.195 583.421 478.101C583.14 477.006 583 475.709 583 474.21C583 472.917 583.128 471.736 583.383 470.668C583.647 469.599 584.035 468.685 584.545 467.927C585.065 467.16 585.712 466.57 586.487 466.156C587.262 465.734 588.164 465.523 589.195 465.523C590.089 465.523 590.902 465.695 591.634 466.04C592.366 466.376 592.992 466.902 593.512 467.617C594.031 468.324 594.431 469.224 594.712 470.319C594.993 471.413 595.134 472.71 595.134 474.21ZM592.886 474.287C592.886 473.994 592.873 473.705 592.847 473.421C592.83 473.128 592.809 472.844 592.784 472.568L585.72 477.881C585.848 478.329 586.01 478.743 586.206 479.122C586.402 479.501 586.636 479.829 586.908 480.104C587.189 480.371 587.504 480.583 587.853 480.738C588.211 480.884 588.616 480.957 589.067 480.957C589.646 480.957 590.169 480.815 590.638 480.531C591.115 480.246 591.519 479.824 591.851 479.264C592.183 478.704 592.439 478.01 592.617 477.183C592.796 476.347 592.886 475.382 592.886 474.287ZM585.248 474.132C585.248 474.399 585.248 474.666 585.248 474.934C585.256 475.201 585.273 475.459 585.299 475.709L592.362 470.422C592.234 469.982 592.073 469.582 591.877 469.22C591.681 468.858 591.447 468.548 591.174 468.289C590.902 468.022 590.591 467.815 590.242 467.669C589.893 467.522 589.501 467.449 589.067 467.449C588.488 467.449 587.96 467.591 587.483 467.875C587.015 468.16 586.615 468.586 586.282 469.155C585.95 469.715 585.695 470.413 585.516 471.249C585.337 472.077 585.248 473.038 585.248 474.132Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 502.077C595.134 503.37 595.006 504.551 594.75 505.619C594.495 506.679 594.108 507.593 593.588 508.36C593.069 509.118 592.422 509.709 591.647 510.131C590.88 510.545 589.978 510.751 588.939 510.751C588.045 510.751 587.232 510.583 586.5 510.247C585.767 509.902 585.141 509.377 584.622 508.67C584.103 507.963 583.702 507.063 583.421 505.968C583.14 504.874 583 503.577 583 502.077C583 500.785 583.128 499.604 583.383 498.535C583.647 497.467 584.035 496.553 584.545 495.795C585.065 495.028 585.712 494.438 586.487 494.024C587.262 493.602 588.164 493.39 589.195 493.39C590.089 493.39 590.902 493.563 591.634 493.908C592.366 494.244 592.992 494.769 593.512 495.485C594.031 496.191 594.431 497.092 594.712 498.186C594.993 499.281 595.134 500.578 595.134 502.077ZM592.886 502.155C592.886 501.862 592.873 501.573 592.847 501.289C592.83 500.996 592.809 500.711 592.784 500.436L585.72 505.749C585.848 506.197 586.01 506.61 586.206 506.99C586.402 507.369 586.636 507.696 586.908 507.972C587.189 508.239 587.504 508.45 587.853 508.605C588.211 508.752 588.616 508.825 589.067 508.825C589.646 508.825 590.169 508.683 590.638 508.399C591.115 508.114 591.519 507.692 591.851 507.132C592.183 506.572 592.439 505.878 592.617 505.051C592.796 504.215 592.886 503.249 592.886 502.155ZM585.248 502C585.248 502.267 585.248 502.534 585.248 502.801C585.256 503.068 585.273 503.327 585.299 503.577L592.362 498.29C592.234 497.85 592.073 497.45 591.877 497.088C591.681 496.726 591.447 496.415 591.174 496.157C590.902 495.89 590.591 495.683 590.242 495.536C589.893 495.39 589.501 495.317 589.067 495.317C588.488 495.317 587.96 495.459 587.483 495.743C587.015 496.028 586.615 496.454 586.282 497.023C585.95 497.583 585.695 498.281 585.516 499.117C585.337 499.944 585.248 500.905 585.248 502Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 538.386H584.124V536.292H588.415V523.96L584.418 526.158L583.6 524.244L588.914 521.413H590.88V536.292H594.61V538.386Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 566.254H584.124V564.16H588.415V551.828L584.418 554.025L583.6 552.112L588.914 549.281H590.88V564.16H594.61V566.254Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 594.122H584.124V592.028H588.415V579.696L584.418 581.893L583.6 579.98L588.914 577.149H590.88V592.028H594.61V594.122Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 613.549C595.134 614.841 595.006 616.022 594.75 617.091C594.495 618.151 594.108 619.064 593.588 619.831C593.069 620.589 592.422 621.18 591.647 621.602C590.88 622.016 589.978 622.223 588.939 622.223C588.045 622.223 587.232 622.054 586.5 621.718C585.767 621.374 585.141 620.848 584.622 620.141C584.103 619.435 583.702 618.534 583.421 617.44C583.14 616.345 583 615.048 583 613.549C583 612.256 583.128 611.075 583.383 610.007C583.647 608.938 584.035 608.024 584.545 607.266C585.065 606.499 585.712 605.909 586.487 605.495C587.262 605.073 588.164 604.862 589.195 604.862C590.089 604.862 590.902 605.034 591.634 605.379C592.366 605.715 592.992 606.24 593.512 606.956C594.031 607.662 594.431 608.563 594.712 609.658C594.993 610.752 595.134 612.049 595.134 613.549ZM592.886 613.626C592.886 613.333 592.873 613.044 592.847 612.76C592.83 612.467 592.809 612.183 592.784 611.907L585.72 617.22C585.848 617.668 586.01 618.082 586.206 618.461C586.402 618.84 586.636 619.167 586.908 619.443C587.189 619.71 587.504 619.922 587.853 620.077C588.211 620.223 588.616 620.296 589.067 620.296C589.646 620.296 590.169 620.154 590.638 619.87C591.115 619.585 591.519 619.163 591.851 618.603C592.183 618.043 592.439 617.349 592.617 616.522C592.796 615.686 592.886 614.721 592.886 613.626ZM585.248 613.471C585.248 613.738 585.248 614.005 585.248 614.272C585.256 614.54 585.273 614.798 585.299 615.048L592.362 609.761C592.234 609.321 592.073 608.921 591.877 608.559C591.681 608.197 591.447 607.887 591.174 607.628C590.902 607.361 590.591 607.154 590.242 607.007C589.893 606.861 589.501 606.788 589.067 606.788C588.488 606.788 587.96 606.93 587.483 607.214C587.015 607.499 586.615 607.925 586.282 608.494C585.95 609.054 585.695 609.752 585.516 610.588C585.337 611.416 585.248 612.376 585.248 613.471Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 649.858H584.124V647.763H588.415V635.431L584.418 637.629L583.6 635.716L588.914 632.885H590.88V647.763H594.61V649.858Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 669.284C595.134 670.577 595.006 671.757 594.75 672.826C594.495 673.886 594.108 674.8 593.588 675.567C593.069 676.325 592.422 676.915 591.647 677.338C590.88 677.751 589.978 677.958 588.939 677.958C588.045 677.958 587.232 677.79 586.5 677.454C585.767 677.109 585.141 676.583 584.622 675.877C584.103 675.17 583.702 674.27 583.421 673.175C583.14 672.081 583 670.784 583 669.284C583 667.991 583.128 666.811 583.383 665.742C583.647 664.673 584.035 663.76 584.545 663.002C585.065 662.235 585.712 661.644 586.487 661.231C587.262 660.808 588.164 660.597 589.195 660.597C590.089 660.597 590.902 660.77 591.634 661.114C592.366 661.45 592.992 661.976 593.512 662.691C594.031 663.398 594.431 664.299 594.712 665.393C594.993 666.488 595.134 667.785 595.134 669.284ZM592.886 669.362C592.886 669.069 592.873 668.78 592.847 668.496C592.83 668.203 592.809 667.918 592.784 667.642L585.72 672.955C585.848 673.403 586.01 673.817 586.206 674.196C586.402 674.576 586.636 674.903 586.908 675.179C587.189 675.446 587.504 675.657 587.853 675.812C588.211 675.959 588.616 676.032 589.067 676.032C589.646 676.032 590.169 675.89 590.638 675.605C591.115 675.321 591.519 674.899 591.851 674.339C592.183 673.778 592.439 673.085 592.617 672.257C592.796 671.421 592.886 670.456 592.886 669.362ZM585.248 669.207C585.248 669.474 585.248 669.741 585.248 670.008C585.256 670.275 585.273 670.534 585.299 670.784L592.362 665.496C592.234 665.057 592.073 664.656 591.877 664.294C591.681 663.932 591.447 663.622 591.174 663.364C590.902 663.096 590.591 662.89 590.242 662.743C589.893 662.597 589.501 662.523 589.067 662.523C588.488 662.523 587.96 662.665 587.483 662.95C587.015 663.234 586.615 663.661 586.282 664.23C585.95 664.79 585.695 665.488 585.516 666.324C585.337 667.151 585.248 668.112 585.248 669.207Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M595.134 697.152C595.134 698.445 595.006 699.625 594.75 700.694C594.495 701.754 594.108 702.667 593.588 703.434C593.069 704.193 592.422 704.783 591.647 705.205C590.88 705.619 589.978 705.826 588.939 705.826C588.045 705.826 587.232 705.658 586.5 705.322C585.767 704.977 585.141 704.451 584.622 703.745C584.103 703.038 583.702 702.137 583.421 701.043C583.14 699.948 583 698.651 583 697.152C583 695.859 583.128 694.678 583.383 693.61C583.647 692.541 584.035 691.628 584.545 690.869C585.065 690.102 585.712 689.512 586.487 689.098C587.262 688.676 588.164 688.465 589.195 688.465C590.089 688.465 590.902 688.637 591.634 688.982C592.366 689.318 592.992 689.844 593.512 690.559C594.031 691.266 594.431 692.166 594.712 693.261C594.993 694.355 595.134 695.652 595.134 697.152ZM592.886 697.229C592.886 696.936 592.873 696.648 592.847 696.363C592.83 696.07 592.809 695.786 592.784 695.51L585.72 700.823C585.848 701.271 586.01 701.685 586.206 702.064C586.402 702.443 586.636 702.771 586.908 703.047C587.189 703.314 587.504 703.525 587.853 703.68C588.211 703.826 588.616 703.9 589.067 703.9C589.646 703.9 590.169 703.758 590.638 703.473C591.115 703.189 591.519 702.766 591.851 702.206C592.183 701.646 592.439 700.952 592.617 700.125C592.796 699.289 592.886 698.324 592.886 697.229ZM585.248 697.074C585.248 697.341 585.248 697.609 585.248 697.876C585.256 698.143 585.273 698.401 585.299 698.651L592.362 693.364C592.234 692.925 592.073 692.524 591.877 692.162C591.681 691.8 591.447 691.49 591.174 691.231C590.902 690.964 590.591 690.757 590.242 690.611C589.893 690.464 589.501 690.391 589.067 690.391C588.488 690.391 587.96 690.533 587.483 690.818C587.015 691.102 586.615 691.529 586.282 692.097C585.95 692.658 585.695 693.356 585.516 694.192C585.337 695.019 585.248 695.98 585.248 697.074Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M594.61 733.461H584.124V731.367H588.415V719.034L584.418 721.232L583.6 719.319L588.914 716.488H590.88V731.367H594.61V733.461Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M752.36 378.837C752.36 379.859 752.25 380.773 752.031 381.58C751.811 382.386 751.488 383.069 751.061 383.628C750.641 384.18 750.126 384.602 749.518 384.895C748.909 385.187 748.213 385.333 747.429 385.333C746.751 385.333 746.127 385.212 745.556 384.971C744.985 384.729 744.492 384.352 744.078 383.837C743.664 383.317 743.341 382.653 743.109 381.846C742.877 381.04 742.761 380.075 742.761 378.951C742.761 377.928 742.871 377.014 743.09 376.208C743.316 375.401 743.639 374.722 744.06 374.169C744.48 373.61 744.997 373.185 745.612 372.893C746.227 372.601 746.924 372.455 747.702 372.455C748.379 372.455 749.003 372.575 749.574 372.817C750.145 373.058 750.635 373.436 751.043 373.95C751.457 374.465 751.78 375.125 752.012 375.931C752.244 376.738 752.36 377.706 752.36 378.837ZM745.076 378.875C745.076 379.002 745.076 379.122 745.076 379.237C745.076 379.345 745.082 379.456 745.095 379.57L749.706 376.198C749.487 375.569 749.195 375.106 748.831 374.807C748.473 374.503 748.05 374.35 747.56 374.35C747.209 374.35 746.883 374.442 746.582 374.626C746.281 374.804 746.017 375.08 745.791 375.455C745.572 375.83 745.396 376.3 745.264 376.865C745.139 377.43 745.076 378.1 745.076 378.875ZM750.045 378.913C750.045 378.805 750.042 378.697 750.036 378.589C750.029 378.475 750.026 378.367 750.026 378.265L745.443 381.618C745.644 382.234 745.926 382.691 746.29 382.99C746.654 383.288 747.077 383.437 747.56 383.437C747.912 383.437 748.238 383.345 748.539 383.161C748.847 382.977 749.11 382.701 749.33 382.332C749.556 381.958 749.731 381.488 749.857 380.923C749.982 380.351 750.045 379.681 750.045 378.913Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 406.014V403.948H746.826V395.946L744.097 397.47L743.344 395.575L747.25 393.489H749.179V403.948H751.88V406.014H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 426.915V424.848H746.826V416.847L744.097 418.371L743.344 416.476L747.25 414.39H749.179V424.848H751.88V426.915H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 441.539C752.36 442.561 752.25 443.476 752.031 444.282C751.811 445.089 751.488 445.771 751.061 446.33C750.641 446.883 750.126 447.305 749.518 447.597C748.909 447.889 748.213 448.035 747.429 448.035C746.751 448.035 746.127 447.915 745.556 447.673C744.985 447.432 744.492 447.054 744.078 446.54C743.664 446.019 743.341 445.355 743.109 444.549C742.877 443.743 742.761 442.777 742.761 441.653C742.761 440.631 742.871 439.717 743.09 438.91C743.316 438.104 743.639 437.424 744.06 436.872C744.48 436.313 744.997 435.888 745.612 435.595C746.227 435.303 746.924 435.157 747.702 435.157C748.379 435.157 749.003 435.278 749.574 435.519C750.145 435.761 750.635 436.138 751.043 436.653C751.457 437.167 751.78 437.827 752.012 438.634C752.244 439.44 752.36 440.409 752.36 441.539ZM745.076 441.577C745.076 441.704 745.076 441.825 745.076 441.939C745.076 442.047 745.082 442.158 745.095 442.273L749.706 438.901C749.487 438.272 749.195 437.808 748.831 437.51C748.473 437.205 748.05 437.053 747.56 437.053C747.209 437.053 746.883 437.145 746.582 437.329C746.281 437.507 746.017 437.783 745.791 438.158C745.572 438.532 745.396 439.002 745.264 439.567C745.139 440.133 745.076 440.802 745.076 441.577ZM750.045 441.615C750.045 441.507 750.042 441.399 750.036 441.291C750.029 441.177 750.026 441.069 750.026 440.968L745.443 444.32C745.644 444.936 745.926 445.394 746.29 445.692C746.654 445.99 747.077 446.14 747.56 446.14C747.912 446.14 748.238 446.048 748.539 445.863C748.847 445.679 749.11 445.403 749.33 445.035C749.556 444.66 749.731 444.19 749.857 443.625C749.982 443.054 750.045 442.384 750.045 441.615Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 468.717V466.65H746.826V458.649L744.097 460.173L743.344 458.277L747.25 456.191H749.179V466.65H751.88V468.717H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 483.341C752.36 484.363 752.25 485.278 752.031 486.084C751.811 486.89 751.488 487.573 751.061 488.132C750.641 488.684 750.126 489.107 749.518 489.399C748.909 489.691 748.213 489.837 747.429 489.837C746.751 489.837 746.127 489.716 745.556 489.475C744.985 489.234 744.492 488.856 744.078 488.341C743.664 487.821 743.341 487.157 743.109 486.351C742.877 485.544 742.761 484.579 742.761 483.455C742.761 482.433 742.871 481.518 743.09 480.712C743.316 479.905 743.639 479.226 744.06 478.673C744.48 478.115 744.997 477.689 745.612 477.397C746.227 477.105 746.924 476.959 747.702 476.959C748.379 476.959 749.003 477.08 749.574 477.321C750.145 477.562 750.635 477.94 751.043 478.454C751.457 478.969 751.78 479.629 752.012 480.436C752.244 481.242 752.36 482.21 752.36 483.341ZM745.076 483.379C745.076 483.506 745.076 483.627 745.076 483.741C745.076 483.849 745.082 483.96 745.095 484.074L749.706 480.702C749.487 480.074 749.195 479.61 748.831 479.312C748.473 479.007 748.05 478.854 747.56 478.854C747.209 478.854 746.883 478.947 746.582 479.131C746.281 479.308 746.017 479.585 745.791 479.959C745.572 480.334 745.396 480.804 745.264 481.369C745.139 481.934 745.076 482.604 745.076 483.379ZM750.045 483.417C750.045 483.309 750.042 483.201 750.036 483.093C750.029 482.979 750.026 482.871 750.026 482.769L745.443 486.122C745.644 486.738 745.926 487.195 746.29 487.494C746.654 487.792 747.077 487.941 747.56 487.941C747.912 487.941 748.238 487.849 748.539 487.665C748.847 487.481 749.11 487.205 749.33 486.836C749.556 486.462 749.731 485.992 749.857 485.427C749.982 484.855 750.045 484.185 750.045 483.417Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 504.242C752.36 505.264 752.25 506.178 752.031 506.985C751.811 507.791 751.488 508.474 751.061 509.033C750.641 509.585 750.126 510.007 749.518 510.3C748.909 510.592 748.213 510.738 747.429 510.738C746.751 510.738 746.127 510.617 745.556 510.376C744.985 510.134 744.492 509.757 744.078 509.242C743.664 508.722 743.341 508.058 743.109 507.252C742.877 506.445 742.761 505.48 742.761 504.356C742.761 503.334 742.871 502.419 743.09 501.613C743.316 500.806 743.639 500.127 744.06 499.574C744.48 499.015 744.997 498.59 745.612 498.298C746.227 498.006 746.924 497.86 747.702 497.86C748.379 497.86 749.003 497.98 749.574 498.222C750.145 498.463 750.635 498.841 751.043 499.355C751.457 499.87 751.78 500.53 752.012 501.336C752.244 502.143 752.36 503.111 752.36 504.242ZM745.076 504.28C745.076 504.407 745.076 504.527 745.076 504.642C745.076 504.75 745.082 504.861 745.095 504.975L749.706 501.603C749.487 500.974 749.195 500.511 748.831 500.212C748.473 499.908 748.05 499.755 747.56 499.755C747.209 499.755 746.883 499.847 746.582 500.031C746.281 500.209 746.017 500.486 745.791 500.86C745.572 501.235 745.396 501.705 745.264 502.27C745.139 502.835 745.076 503.505 745.076 504.28ZM750.045 504.318C750.045 504.21 750.042 504.102 750.036 503.994C750.029 503.88 750.026 503.772 750.026 503.67L745.443 507.023C745.644 507.639 745.926 508.096 746.29 508.395C746.654 508.693 747.077 508.842 747.56 508.842C747.912 508.842 748.238 508.75 748.539 508.566C748.847 508.382 749.11 508.106 749.33 507.737C749.556 507.363 749.731 506.893 749.857 506.328C749.982 505.756 750.045 505.086 750.045 504.318Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 525.142C752.36 526.165 752.25 527.079 752.031 527.886C751.811 528.692 751.488 529.375 751.061 529.934C750.641 530.486 750.126 530.908 749.518 531.2C748.909 531.493 748.213 531.639 747.429 531.639C746.751 531.639 746.127 531.518 745.556 531.277C744.985 531.035 744.492 530.657 744.078 530.143C743.664 529.622 743.341 528.959 743.109 528.152C742.877 527.346 742.761 526.381 742.761 525.257C742.761 524.234 742.871 523.32 743.09 522.514C743.316 521.707 743.639 521.028 744.06 520.475C744.48 519.916 744.997 519.491 745.612 519.199C746.227 518.907 746.924 518.761 747.702 518.761C748.379 518.761 749.003 518.881 749.574 519.123C750.145 519.364 750.635 519.742 751.043 520.256C751.457 520.77 751.78 521.431 752.012 522.237C752.244 523.044 752.36 524.012 752.36 525.142ZM745.076 525.181C745.076 525.308 745.076 525.428 745.076 525.542C745.076 525.65 745.082 525.762 745.095 525.876L749.706 522.504C749.487 521.875 749.195 521.412 748.831 521.113C748.473 520.809 748.05 520.656 747.56 520.656C747.209 520.656 746.883 520.748 746.582 520.932C746.281 521.11 746.017 521.386 745.791 521.761C745.572 522.136 745.396 522.606 745.264 523.171C745.139 523.736 745.076 524.406 745.076 525.181ZM750.045 525.219C750.045 525.111 750.042 525.003 750.036 524.895C750.029 524.78 750.026 524.673 750.026 524.571L745.443 527.924C745.644 528.54 745.926 528.997 746.29 529.295C746.654 529.594 747.077 529.743 747.56 529.743C747.912 529.743 748.238 529.651 748.539 529.467C748.847 529.283 749.11 529.006 749.33 528.638C749.556 528.264 749.731 527.794 749.857 527.228C749.982 526.657 750.045 525.987 750.045 525.219Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 552.32V550.253H746.826V542.252L744.097 543.776L743.344 541.881L747.25 539.795H749.179V550.253H751.88V552.32H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 573.221V571.154H746.826V563.153L744.097 564.677L743.344 562.782L747.25 560.696H749.179V571.154H751.88V573.221H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 587.845C752.36 588.867 752.25 589.782 752.031 590.588C751.811 591.395 751.488 592.077 751.061 592.636C750.641 593.189 750.126 593.611 749.518 593.903C748.909 594.195 748.213 594.341 747.429 594.341C746.751 594.341 746.127 594.22 745.556 593.979C744.985 593.738 744.492 593.36 744.078 592.846C743.664 592.325 743.341 591.661 743.109 590.855C742.877 590.048 742.761 589.083 742.761 587.959C742.761 586.937 742.871 586.022 743.09 585.216C743.316 584.41 743.639 583.73 744.06 583.178C744.48 582.619 744.997 582.193 745.612 581.901C746.227 581.609 746.924 581.463 747.702 581.463C748.379 581.463 749.003 581.584 749.574 581.825C750.145 582.066 750.635 582.444 751.043 582.959C751.457 583.473 751.78 584.133 752.012 584.94C752.244 585.746 752.36 586.715 752.36 587.845ZM745.076 587.883C745.076 588.01 745.076 588.131 745.076 588.245C745.076 588.353 745.082 588.464 745.095 588.578L749.706 585.206C749.487 584.578 749.195 584.114 748.831 583.816C748.473 583.511 748.05 583.359 747.56 583.359C747.209 583.359 746.883 583.451 746.582 583.635C746.281 583.813 746.017 584.089 745.791 584.464C745.572 584.838 745.396 585.308 745.264 585.873C745.139 586.438 745.076 587.108 745.076 587.883ZM750.045 587.921C750.045 587.813 750.042 587.705 750.036 587.597C750.029 587.483 750.026 587.375 750.026 587.273L745.443 590.626C745.644 591.242 745.926 591.699 746.29 591.998C746.654 592.296 747.077 592.446 747.56 592.446C747.912 592.446 748.238 592.354 748.539 592.169C748.847 591.985 749.11 591.709 749.33 591.341C749.556 590.966 749.731 590.496 749.857 589.931C749.982 589.359 750.045 588.69 750.045 587.921Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 615.023V612.956H746.826V604.955L744.097 606.479L743.344 604.583L747.25 602.497H749.179V612.956H751.88V615.023H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 635.924V633.857H746.826V625.856L744.097 627.38L743.344 625.484L747.25 623.398H749.179V633.857H751.88V635.924H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 650.547C752.36 651.57 752.25 652.484 752.031 653.291C751.811 654.097 751.488 654.78 751.061 655.339C750.641 655.891 750.126 656.313 749.518 656.605C748.909 656.898 748.213 657.044 747.429 657.044C746.751 657.044 746.127 656.923 745.556 656.682C744.985 656.44 744.492 656.063 744.078 655.548C743.664 655.027 743.341 654.364 743.109 653.557C742.877 652.751 742.761 651.786 742.761 650.662C742.761 649.639 742.871 648.725 743.09 647.919C743.316 647.112 743.639 646.433 744.06 645.88C744.48 645.321 744.997 644.896 745.612 644.604C746.227 644.312 746.924 644.166 747.702 644.166C748.379 644.166 749.003 644.286 749.574 644.528C750.145 644.769 750.635 645.147 751.043 645.661C751.457 646.175 751.78 646.836 752.012 647.642C752.244 648.449 752.36 649.417 752.36 650.547ZM745.076 650.586C745.076 650.713 745.076 650.833 745.076 650.948C745.076 651.055 745.082 651.167 745.095 651.281L749.706 647.909C749.487 647.28 749.195 646.817 748.831 646.518C748.473 646.214 748.05 646.061 747.56 646.061C747.209 646.061 746.883 646.153 746.582 646.337C746.281 646.515 746.017 646.791 745.791 647.166C745.572 647.541 745.396 648.011 745.264 648.576C745.139 649.141 745.076 649.811 745.076 650.586ZM750.045 650.624C750.045 650.516 750.042 650.408 750.036 650.3C750.029 650.186 750.026 650.078 750.026 649.976L745.443 653.329C745.644 653.945 745.926 654.402 746.29 654.7C746.654 654.999 747.077 655.148 747.56 655.148C747.912 655.148 748.238 655.056 748.539 654.872C748.847 654.688 749.11 654.412 749.33 654.043C749.556 653.669 749.731 653.199 749.857 652.633C749.982 652.062 750.045 651.392 750.045 650.624Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 677.725V675.658H746.826V667.657L744.097 669.181L743.344 667.286L747.25 665.2H749.179V675.658H751.88V677.725H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 698.626V696.559H746.826V688.558L744.097 690.082L743.344 688.187L747.25 686.101H749.179V696.559H751.88V698.626H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 719.527V717.46H746.826V709.459L744.097 710.983L743.344 709.088L747.25 707.002H749.179V717.46H751.88V719.527H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 734.151C752.36 735.173 752.25 736.088 752.031 736.894C751.811 737.701 751.488 738.383 751.061 738.942C750.641 739.494 750.126 739.917 749.518 740.209C748.909 740.501 748.213 740.647 747.429 740.647C746.751 740.647 746.127 740.526 745.556 740.285C744.985 740.044 744.492 739.666 744.078 739.152C743.664 738.631 743.341 737.967 743.109 737.161C742.877 736.354 742.761 735.389 742.761 734.265C742.761 733.243 742.871 732.328 743.09 731.522C743.316 730.715 743.639 730.036 744.06 729.484C744.48 728.925 744.997 728.499 745.612 728.207C746.227 727.915 746.924 727.769 747.702 727.769C748.379 727.769 749.003 727.89 749.574 728.131C750.145 728.372 750.635 728.75 751.043 729.264C751.457 729.779 751.78 730.439 752.012 731.246C752.244 732.052 752.36 733.021 752.36 734.151ZM745.076 734.189C745.076 734.316 745.076 734.437 745.076 734.551C745.076 734.659 745.082 734.77 745.095 734.884L749.706 731.512C749.487 730.884 749.195 730.42 748.831 730.122C748.473 729.817 748.05 729.665 747.56 729.665C747.209 729.665 746.883 729.757 746.582 729.941C746.281 730.119 746.017 730.395 745.791 730.769C745.572 731.144 745.396 731.614 745.264 732.179C745.139 732.744 745.076 733.414 745.076 734.189ZM750.045 734.227C750.045 734.119 750.042 734.011 750.036 733.903C750.029 733.789 750.026 733.681 750.026 733.579L745.443 736.932C745.644 737.548 745.926 738.005 746.29 738.304C746.654 738.602 747.077 738.751 747.56 738.751C747.912 738.751 748.238 738.659 748.539 738.475C748.847 738.291 749.11 738.015 749.33 737.647C749.556 737.272 749.731 736.802 749.857 736.237C749.982 735.665 750.045 734.995 750.045 734.227Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M752.36 755.052C752.36 756.074 752.25 756.988 752.031 757.795C751.811 758.601 751.488 759.284 751.061 759.843C750.641 760.395 750.126 760.818 749.518 761.11C748.909 761.402 748.213 761.548 747.429 761.548C746.751 761.548 746.127 761.427 745.556 761.186C744.985 760.945 744.492 760.567 744.078 760.052C743.664 759.532 743.341 758.868 743.109 758.062C742.877 757.255 742.761 756.29 742.761 755.166C742.761 754.144 742.871 753.229 743.09 752.423C743.316 751.616 743.639 750.937 744.06 750.384C744.48 749.826 744.997 749.4 745.612 749.108C746.227 748.816 746.924 748.67 747.702 748.67C748.379 748.67 749.003 748.791 749.574 749.032C750.145 749.273 750.635 749.651 751.043 750.165C751.457 750.68 751.78 751.34 752.012 752.147C752.244 752.953 752.36 753.921 752.36 755.052ZM745.076 755.09C745.076 755.217 745.076 755.337 745.076 755.452C745.076 755.56 745.082 755.671 745.095 755.785L749.706 752.413C749.487 751.785 749.195 751.321 748.831 751.023C748.473 750.718 748.05 750.565 747.56 750.565C747.209 750.565 746.883 750.657 746.582 750.842C746.281 751.019 746.017 751.296 745.791 751.67C745.572 752.045 745.396 752.515 745.264 753.08C745.139 753.645 745.076 754.315 745.076 755.09ZM750.045 755.128C750.045 755.02 750.042 754.912 750.036 754.804C750.029 754.69 750.026 754.582 750.026 754.48L745.443 757.833C745.644 758.449 745.926 758.906 746.29 759.205C746.654 759.503 747.077 759.652 747.56 759.652C747.912 759.652 748.238 759.56 748.539 759.376C748.847 759.192 749.11 758.916 749.33 758.547C749.556 758.173 749.731 757.703 749.857 757.138C749.982 756.566 750.045 755.896 750.045 755.128Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M743.692 782.23V780.163H746.826V772.162L744.097 773.686L743.344 771.79L747.25 769.704H749.179V780.163H751.88V782.23H743.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M774.387 674.235C774.387 675.257 774.277 676.172 774.058 676.978C773.838 677.785 773.515 678.467 773.088 679.026C772.668 679.579 772.153 680.001 771.545 680.293C770.936 680.585 770.24 680.731 769.456 680.731C768.778 680.731 768.154 680.611 767.583 680.369C767.012 680.128 766.519 679.75 766.105 679.236C765.691 678.715 765.368 678.052 765.136 677.245C764.904 676.439 764.788 675.473 764.788 674.349C764.788 673.327 764.898 672.413 765.117 671.606C765.343 670.8 765.666 670.12 766.086 669.568C766.507 669.009 767.024 668.584 767.639 668.291C768.254 667.999 768.951 667.853 769.728 667.853C770.406 667.853 771.03 667.974 771.601 668.215C772.172 668.457 772.662 668.834 773.069 669.349C773.483 669.863 773.807 670.524 774.039 671.33C774.271 672.136 774.387 673.105 774.387 674.235ZM767.103 674.273C767.103 674.4 767.103 674.521 767.103 674.635C767.103 674.743 767.109 674.854 767.122 674.969L771.733 671.597C771.513 670.968 771.222 670.504 770.858 670.206C770.5 669.901 770.077 669.749 769.587 669.749C769.236 669.749 768.91 669.841 768.609 670.025C768.307 670.203 768.044 670.479 767.818 670.854C767.598 671.228 767.423 671.698 767.291 672.263C767.166 672.829 767.103 673.499 767.103 674.273ZM772.072 674.311C772.072 674.203 772.069 674.095 772.062 673.987C772.056 673.873 772.053 673.765 772.053 673.664L767.47 677.016C767.671 677.632 767.953 678.09 768.317 678.388C768.681 678.687 769.104 678.836 769.587 678.836C769.939 678.836 770.265 678.744 770.566 678.56C770.873 678.375 771.137 678.099 771.357 677.731C771.582 677.356 771.758 676.886 771.884 676.321C772.009 675.75 772.072 675.08 772.072 674.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 701.413V699.346H768.853V691.345L766.124 692.869L765.371 690.973L769.277 688.887H771.206V699.346H773.907V701.413H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 722.314V720.247H768.853V712.246L766.124 713.77L765.371 711.874L769.277 709.788H771.206V720.247H773.907V722.314H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 736.938C774.387 737.96 774.277 738.874 774.058 739.681C773.838 740.487 773.515 741.17 773.088 741.729C772.668 742.281 772.153 742.704 771.545 742.996C770.936 743.288 770.24 743.434 769.456 743.434C768.778 743.434 768.154 743.313 767.583 743.072C767.012 742.831 766.519 742.453 766.105 741.938C765.691 741.418 765.368 740.754 765.136 739.948C764.904 739.141 764.788 738.176 764.788 737.052C764.788 736.03 764.898 735.115 765.117 734.309C765.343 733.502 765.666 732.823 766.086 732.27C766.507 731.712 767.024 731.286 767.639 730.994C768.254 730.702 768.951 730.556 769.728 730.556C770.406 730.556 771.03 730.676 771.601 730.918C772.172 731.159 772.662 731.537 773.069 732.051C773.483 732.566 773.807 733.226 774.039 734.032C774.271 734.839 774.387 735.807 774.387 736.938ZM767.103 736.976C767.103 737.103 767.103 737.223 767.103 737.338C767.103 737.446 767.109 737.557 767.122 737.671L771.733 734.299C771.513 733.671 771.222 733.207 770.858 732.909C770.5 732.604 770.077 732.451 769.587 732.451C769.236 732.451 768.91 732.543 768.609 732.728C768.307 732.905 768.044 733.182 767.818 733.556C767.598 733.931 767.423 734.401 767.291 734.966C767.166 735.531 767.103 736.201 767.103 736.976ZM772.072 737.014C772.072 736.906 772.069 736.798 772.062 736.69C772.056 736.576 772.053 736.468 772.053 736.366L767.47 739.719C767.671 740.335 767.953 740.792 768.317 741.091C768.681 741.389 769.104 741.538 769.587 741.538C769.939 741.538 770.265 741.446 770.566 741.262C770.873 741.078 771.137 740.802 771.357 740.433C771.582 740.059 771.758 739.589 771.884 739.024C772.009 738.452 772.072 737.782 772.072 737.014Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 764.116V762.049H768.853V754.047L766.124 755.572L765.371 753.676L769.277 751.59H771.206V762.049H773.907V764.116H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 778.739C774.387 779.762 774.277 780.676 774.058 781.483C773.838 782.289 773.515 782.972 773.088 783.53C772.668 784.083 772.153 784.505 771.545 784.797C770.936 785.089 770.24 785.235 769.456 785.235C768.778 785.235 768.154 785.115 767.583 784.874C767.012 784.632 766.519 784.254 766.105 783.74C765.691 783.219 765.368 782.556 765.136 781.749C764.904 780.943 764.788 779.978 764.788 778.854C764.788 777.831 764.898 776.917 765.117 776.11C765.343 775.304 765.666 774.624 766.086 774.072C766.507 773.513 767.024 773.088 767.639 772.796C768.254 772.504 768.951 772.357 769.728 772.357C770.406 772.357 771.03 772.478 771.601 772.719C772.172 772.961 772.662 773.339 773.069 773.853C773.483 774.367 773.807 775.028 774.039 775.834C774.271 776.641 774.387 777.609 774.387 778.739ZM767.103 778.777C767.103 778.904 767.103 779.025 767.103 779.139C767.103 779.247 767.109 779.358 767.122 779.473L771.733 776.101C771.513 775.472 771.222 775.009 770.858 774.71C770.5 774.405 770.077 774.253 769.587 774.253C769.236 774.253 768.91 774.345 768.609 774.529C768.307 774.707 768.044 774.983 767.818 775.358C767.598 775.733 767.423 776.202 767.291 776.768C767.166 777.333 767.103 778.003 767.103 778.777ZM772.072 778.816C772.072 778.708 772.069 778.6 772.062 778.492C772.056 778.377 772.053 778.269 772.053 778.168L767.47 781.521C767.671 782.137 767.953 782.594 768.317 782.892C768.681 783.191 769.104 783.34 769.587 783.34C769.939 783.34 770.265 783.248 770.566 783.064C770.873 782.88 771.137 782.603 771.357 782.235C771.582 781.86 771.758 781.39 771.884 780.825C772.009 780.254 772.072 779.584 772.072 778.816Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 799.64C774.387 800.663 774.277 801.577 774.058 802.383C773.838 803.19 773.515 803.872 773.088 804.431C772.668 804.984 772.153 805.406 771.545 805.698C770.936 805.99 770.24 806.136 769.456 806.136C768.778 806.136 768.154 806.016 767.583 805.774C767.012 805.533 766.519 805.155 766.105 804.641C765.691 804.12 765.368 803.457 765.136 802.65C764.904 801.844 764.788 800.878 764.788 799.754C764.788 798.732 764.898 797.818 765.117 797.011C765.343 796.205 765.666 795.525 766.086 794.973C766.507 794.414 767.024 793.989 767.639 793.696C768.254 793.404 768.951 793.258 769.728 793.258C770.406 793.258 771.03 793.379 771.601 793.62C772.172 793.862 772.662 794.239 773.069 794.754C773.483 795.268 773.807 795.929 774.039 796.735C774.271 797.541 774.387 798.51 774.387 799.64ZM767.103 799.678C767.103 799.805 767.103 799.926 767.103 800.04C767.103 800.148 767.109 800.259 767.122 800.374L771.733 797.002C771.513 796.373 771.222 795.909 770.858 795.611C770.5 795.306 770.077 795.154 769.587 795.154C769.236 795.154 768.91 795.246 768.609 795.43C768.307 795.608 768.044 795.884 767.818 796.259C767.598 796.633 767.423 797.103 767.291 797.668C767.166 798.234 767.103 798.904 767.103 799.678ZM772.072 799.716C772.072 799.608 772.069 799.5 772.062 799.393C772.056 799.278 772.053 799.17 772.053 799.069L767.47 802.421C767.671 803.037 767.953 803.495 768.317 803.793C768.681 804.092 769.104 804.241 769.587 804.241C769.939 804.241 770.265 804.149 770.566 803.965C770.873 803.78 771.137 803.504 771.357 803.136C771.582 802.761 771.758 802.291 771.884 801.726C772.009 801.155 772.072 800.485 772.072 799.716Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 820.541C774.387 821.563 774.277 822.478 774.058 823.284C773.838 824.091 773.515 824.773 773.088 825.332C772.668 825.885 772.153 826.307 771.545 826.599C770.936 826.891 770.24 827.037 769.456 827.037C768.778 827.037 768.154 826.916 767.583 826.675C767.012 826.434 766.519 826.056 766.105 825.542C765.691 825.021 765.368 824.357 765.136 823.551C764.904 822.744 764.788 821.779 764.788 820.655C764.788 819.633 764.898 818.719 765.117 817.912C765.343 817.106 765.666 816.426 766.086 815.874C766.507 815.315 767.024 814.889 767.639 814.597C768.254 814.305 768.951 814.159 769.728 814.159C770.406 814.159 771.03 814.28 771.601 814.521C772.172 814.762 772.662 815.14 773.069 815.655C773.483 816.169 773.807 816.829 774.039 817.636C774.271 818.442 774.387 819.411 774.387 820.541ZM767.103 820.579C767.103 820.706 767.103 820.827 767.103 820.941C767.103 821.049 767.109 821.16 767.122 821.274L771.733 817.903C771.513 817.274 771.222 816.81 770.858 816.512C770.5 816.207 770.077 816.055 769.587 816.055C769.236 816.055 768.91 816.147 768.609 816.331C768.307 816.509 768.044 816.785 767.818 817.16C767.598 817.534 767.423 818.004 767.291 818.569C767.166 819.134 767.103 819.804 767.103 820.579ZM772.072 820.617C772.072 820.509 772.069 820.401 772.062 820.293C772.056 820.179 772.053 820.071 772.053 819.969L767.47 823.322C767.671 823.938 767.953 824.396 768.317 824.694C768.681 824.992 769.104 825.142 769.587 825.142C769.939 825.142 770.265 825.05 770.566 824.865C770.873 824.681 771.137 824.405 771.357 824.037C771.582 823.662 771.758 823.192 771.884 822.627C772.009 822.055 772.072 821.386 772.072 820.617Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 847.719V845.652H768.853V837.651L766.124 839.175L765.371 837.279L769.277 835.193H771.206V845.652H773.907V847.719H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 868.62V866.553H768.853V858.552L766.124 860.076L765.371 858.18L769.277 856.094H771.206V866.553H773.907V868.62H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 883.244C774.387 884.266 774.277 885.18 774.058 885.987C773.838 886.793 773.515 887.476 773.088 888.035C772.668 888.587 772.153 889.009 771.545 889.301C770.936 889.594 770.24 889.74 769.456 889.74C768.778 889.74 768.154 889.619 767.583 889.378C767.012 889.136 766.519 888.759 766.105 888.244C765.691 887.723 765.368 887.06 765.136 886.253C764.904 885.447 764.788 884.482 764.788 883.358C764.788 882.335 764.898 881.421 765.117 880.615C765.343 879.808 765.666 879.129 766.086 878.576C766.507 878.017 767.024 877.592 767.639 877.3C768.254 877.008 768.951 876.862 769.728 876.862C770.406 876.862 771.03 876.982 771.601 877.224C772.172 877.465 772.662 877.843 773.069 878.357C773.483 878.871 773.807 879.532 774.039 880.338C774.271 881.145 774.387 882.113 774.387 883.244ZM767.103 883.282C767.103 883.409 767.103 883.529 767.103 883.644C767.103 883.752 767.109 883.863 767.122 883.977L771.733 880.605C771.513 879.976 771.222 879.513 770.858 879.214C770.5 878.91 770.077 878.757 769.587 878.757C769.236 878.757 768.91 878.849 768.609 879.033C768.307 879.211 768.044 879.487 767.818 879.862C767.598 880.237 767.423 880.707 767.291 881.272C767.166 881.837 767.103 882.507 767.103 883.282ZM772.072 883.32C772.072 883.212 772.069 883.104 772.062 882.996C772.056 882.882 772.053 882.774 772.053 882.672L767.47 886.025C767.671 886.641 767.953 887.098 768.317 887.396C768.681 887.695 769.104 887.844 769.587 887.844C769.939 887.844 770.265 887.752 770.566 887.568C770.873 887.384 771.137 887.108 771.357 886.739C771.582 886.365 771.758 885.895 771.884 885.33C772.009 884.758 772.072 884.088 772.072 883.32Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 910.421V908.354H768.853V900.353L766.124 901.877L765.371 899.982L769.277 897.896H771.206V908.354H773.907V910.421H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 931.322V929.255H768.853V921.254L766.124 922.778L765.371 920.883L769.277 918.797H771.206V929.255H773.907V931.322H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 945.946C774.387 946.968 774.277 947.883 774.058 948.689C773.838 949.496 773.515 950.178 773.088 950.737C772.668 951.29 772.153 951.712 771.545 952.004C770.936 952.296 770.24 952.442 769.456 952.442C768.778 952.442 768.154 952.322 767.583 952.08C767.012 951.839 766.519 951.461 766.105 950.947C765.691 950.426 765.368 949.762 765.136 948.956C764.904 948.15 764.788 947.184 764.788 946.06C764.788 945.038 764.898 944.124 765.117 943.317C765.343 942.511 765.666 941.831 766.086 941.279C766.507 940.72 767.024 940.294 767.639 940.002C768.254 939.71 768.951 939.564 769.728 939.564C770.406 939.564 771.03 939.685 771.601 939.926C772.172 940.167 772.662 940.545 773.069 941.06C773.483 941.574 773.807 942.234 774.039 943.041C774.271 943.847 774.387 944.816 774.387 945.946ZM767.103 945.984C767.103 946.111 767.103 946.232 767.103 946.346C767.103 946.454 767.109 946.565 767.122 946.679L771.733 943.308C771.513 942.679 771.222 942.215 770.858 941.917C770.5 941.612 770.077 941.46 769.587 941.46C769.236 941.46 768.91 941.552 768.609 941.736C768.307 941.914 768.044 942.19 767.818 942.565C767.598 942.939 767.423 943.409 767.291 943.974C767.166 944.539 767.103 945.209 767.103 945.984ZM772.072 946.022C772.072 945.914 772.069 945.806 772.062 945.698C772.056 945.584 772.053 945.476 772.053 945.375L767.47 948.727C767.671 949.343 767.953 949.801 768.317 950.099C768.681 950.397 769.104 950.547 769.587 950.547C769.939 950.547 770.265 950.455 770.566 950.27C770.873 950.086 771.137 949.81 771.357 949.442C771.582 949.067 771.758 948.597 771.884 948.032C772.009 947.461 772.072 946.791 772.072 946.022Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 973.124V971.057H768.853V963.056L766.124 964.58L765.371 962.684L769.277 960.598H771.206V971.057H773.907V973.124H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 994.025V991.958H768.853V983.957L766.124 985.481L765.371 983.585L769.277 981.499H771.206V991.958H773.907V994.025H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 1014.93V1012.86H768.853V1004.86L766.124 1006.38L765.371 1004.49L769.277 1002.4H771.206V1012.86H773.907V1014.93H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 1029.55C774.387 1030.57 774.277 1031.49 774.058 1032.29C773.838 1033.1 773.515 1033.78 773.088 1034.34C772.668 1034.89 772.153 1035.32 771.545 1035.61C770.936 1035.9 770.24 1036.05 769.456 1036.05C768.778 1036.05 768.154 1035.92 767.583 1035.68C767.012 1035.44 766.519 1035.06 766.105 1034.55C765.691 1034.03 765.368 1033.37 765.136 1032.56C764.904 1031.75 764.788 1030.79 764.788 1029.66C764.788 1028.64 764.898 1027.73 765.117 1026.92C765.343 1026.11 765.666 1025.43 766.086 1024.88C766.507 1024.32 767.024 1023.9 767.639 1023.61C768.254 1023.31 768.951 1023.17 769.728 1023.17C770.406 1023.17 771.03 1023.29 771.601 1023.53C772.172 1023.77 772.662 1024.15 773.069 1024.66C773.483 1025.18 773.807 1025.84 774.039 1026.64C774.271 1027.45 774.387 1028.42 774.387 1029.55ZM767.103 1029.59C767.103 1029.71 767.103 1029.84 767.103 1029.95C767.103 1030.06 767.109 1030.17 767.122 1030.28L771.733 1026.91C771.513 1026.28 771.222 1025.82 770.858 1025.52C770.5 1025.22 770.077 1025.06 769.587 1025.06C769.236 1025.06 768.91 1025.16 768.609 1025.34C768.307 1025.52 768.044 1025.79 767.818 1026.17C767.598 1026.54 767.423 1027.01 767.291 1027.58C767.166 1028.14 767.103 1028.81 767.103 1029.59ZM772.072 1029.63C772.072 1029.52 772.069 1029.41 772.062 1029.3C772.056 1029.19 772.053 1029.08 772.053 1028.98L767.47 1032.33C767.671 1032.95 767.953 1033.4 768.317 1033.7C768.681 1034 769.104 1034.15 769.587 1034.15C769.939 1034.15 770.265 1034.06 770.566 1033.87C770.873 1033.69 771.137 1033.41 771.357 1033.05C771.582 1032.67 771.758 1032.2 771.884 1031.64C772.009 1031.06 772.072 1030.39 772.072 1029.63Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M774.387 1050.45C774.387 1051.47 774.277 1052.39 774.058 1053.19C773.838 1054 773.515 1054.68 773.088 1055.24C772.668 1055.79 772.153 1056.22 771.545 1056.51C770.936 1056.8 770.24 1056.95 769.456 1056.95C768.778 1056.95 768.154 1056.83 767.583 1056.58C767.012 1056.34 766.519 1055.97 766.105 1055.45C765.691 1054.93 765.368 1054.27 765.136 1053.46C764.904 1052.65 764.788 1051.69 764.788 1050.56C764.788 1049.54 764.898 1048.63 765.117 1047.82C765.343 1047.01 765.666 1046.34 766.086 1045.78C766.507 1045.22 767.024 1044.8 767.639 1044.51C768.254 1044.21 768.951 1044.07 769.728 1044.07C770.406 1044.07 771.03 1044.19 771.601 1044.43C772.172 1044.67 772.662 1045.05 773.069 1045.56C773.483 1046.08 773.807 1046.74 774.039 1047.55C774.271 1048.35 774.387 1049.32 774.387 1050.45ZM767.103 1050.49C767.103 1050.62 767.103 1050.74 767.103 1050.85C767.103 1050.96 767.109 1051.07 767.122 1051.18L771.733 1047.81C771.513 1047.18 771.222 1046.72 770.858 1046.42C770.5 1046.12 770.077 1045.96 769.587 1045.96C769.236 1045.96 768.91 1046.06 768.609 1046.24C768.307 1046.42 768.044 1046.69 767.818 1047.07C767.598 1047.44 767.423 1047.91 767.291 1048.48C767.166 1049.04 767.103 1049.71 767.103 1050.49ZM772.072 1050.53C772.072 1050.42 772.069 1050.31 772.062 1050.2C772.056 1050.09 772.053 1049.98 772.053 1049.88L767.47 1053.23C767.671 1053.85 767.953 1054.3 768.317 1054.6C768.681 1054.9 769.104 1055.05 769.587 1055.05C769.939 1055.05 770.265 1054.96 770.566 1054.77C770.873 1054.59 771.137 1054.31 771.357 1053.95C771.582 1053.57 771.758 1053.1 771.884 1052.54C772.009 1051.96 772.072 1051.29 772.072 1050.53Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M765.719 1077.63V1075.56H768.853V1067.56L766.124 1069.08L765.371 1067.19L769.277 1065.1H771.206V1075.56H773.907V1077.63H765.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M847.811 153.568C847.811 154.883 847.67 156.058 847.387 157.095C847.105 158.132 846.69 159.01 846.141 159.728C845.601 160.438 844.939 160.981 844.157 161.357C843.374 161.732 842.479 161.92 841.471 161.92C840.599 161.92 839.797 161.765 839.063 161.455C838.329 161.145 837.695 160.659 837.163 159.997C836.631 159.328 836.215 158.475 835.917 157.438C835.618 156.401 835.469 155.16 835.469 153.715C835.469 152.401 835.61 151.225 835.893 150.188C836.183 149.151 836.598 148.278 837.139 147.567C837.679 146.849 838.345 146.302 839.135 145.926C839.926 145.551 840.821 145.363 841.821 145.363C842.693 145.363 843.495 145.518 844.229 145.828C844.963 146.138 845.593 146.624 846.117 147.286C846.649 147.947 847.065 148.796 847.363 149.833C847.662 150.87 847.811 152.115 847.811 153.568ZM838.446 153.617C838.446 153.78 838.446 153.935 838.446 154.082C838.446 154.221 838.454 154.364 838.47 154.511L844.399 150.176C844.116 149.367 843.741 148.771 843.273 148.388C842.814 147.996 842.269 147.8 841.64 147.8C841.188 147.8 840.769 147.918 840.382 148.155C839.994 148.384 839.656 148.739 839.365 149.22C839.083 149.702 838.857 150.306 838.688 151.033C838.526 151.76 838.446 152.621 838.446 153.617ZM844.834 153.666C844.834 153.527 844.83 153.388 844.822 153.25C844.814 153.103 844.81 152.964 844.81 152.833L838.918 157.144C839.176 157.936 839.539 158.524 840.007 158.908C840.474 159.291 841.019 159.483 841.64 159.483C842.092 159.483 842.511 159.365 842.898 159.128C843.294 158.891 843.632 158.536 843.915 158.063C844.205 157.581 844.431 156.977 844.592 156.25C844.754 155.515 844.834 154.654 844.834 153.666Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 188.113V185.455H840.696V175.168L837.187 177.128L836.219 174.691L841.241 172.009H843.721V185.455H847.194V188.113H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 214.587V211.93H840.696V201.643L837.187 203.602L836.219 201.165L841.241 198.483H843.721V211.93H847.194V214.587H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 232.991C847.811 234.306 847.67 235.481 847.387 236.518C847.105 237.555 846.69 238.433 846.141 239.151C845.601 239.862 844.939 240.404 844.157 240.78C843.374 241.156 842.479 241.343 841.471 241.343C840.599 241.343 839.797 241.188 839.063 240.878C838.329 240.568 837.695 240.082 837.163 239.421C836.631 238.751 836.215 237.898 835.917 236.861C835.618 235.824 835.469 234.583 835.469 233.138C835.469 231.824 835.61 230.648 835.893 229.611C836.183 228.574 836.598 227.701 837.139 226.99C837.679 226.272 838.345 225.725 839.135 225.349C839.926 224.974 840.821 224.786 841.821 224.786C842.693 224.786 843.495 224.941 844.229 225.251C844.963 225.562 845.593 226.047 846.117 226.709C846.649 227.37 847.065 228.219 847.363 229.256C847.662 230.293 847.811 231.538 847.811 232.991ZM838.446 233.04C838.446 233.203 838.446 233.359 838.446 233.506C838.446 233.644 838.454 233.787 838.47 233.934L844.399 229.599C844.116 228.791 843.741 228.195 843.273 227.811C842.814 227.419 842.269 227.223 841.64 227.223C841.188 227.223 840.769 227.341 840.382 227.578C839.994 227.807 839.656 228.162 839.365 228.644C839.083 229.125 838.857 229.73 838.688 230.456C838.526 231.183 838.446 232.044 838.446 233.04ZM844.834 233.089C844.834 232.95 844.83 232.812 844.822 232.673C844.814 232.526 844.81 232.387 844.81 232.256L838.918 236.567C839.176 237.359 839.539 237.947 840.007 238.331C840.474 238.714 841.019 238.906 841.64 238.906C842.092 238.906 842.511 238.788 842.898 238.551C843.294 238.314 843.632 237.959 843.915 237.486C844.205 237.004 844.431 236.4 844.592 235.673C844.754 234.938 844.834 234.077 844.834 233.089Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 267.536V264.879H840.696V254.591L837.187 256.551L836.219 254.114L841.241 251.432H843.721V264.879H847.194V267.536H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 285.94C847.811 287.254 847.67 288.43 847.387 289.467C847.105 290.504 846.69 291.382 846.141 292.1C845.601 292.81 844.939 293.353 844.157 293.729C843.374 294.104 842.479 294.292 841.471 294.292C840.599 294.292 839.797 294.137 839.063 293.827C838.329 293.517 837.695 293.031 837.163 292.369C836.631 291.7 836.215 290.847 835.917 289.81C835.618 288.773 835.469 287.532 835.469 286.087C835.469 284.772 835.61 283.597 835.893 282.56C836.183 281.523 836.598 280.649 837.139 279.939C837.679 279.221 838.345 278.674 839.135 278.298C839.926 277.923 840.821 277.735 841.821 277.735C842.693 277.735 843.495 277.89 844.229 278.2C844.963 278.51 845.593 278.996 846.117 279.658C846.649 280.319 847.065 281.168 847.363 282.205C847.662 283.242 847.811 284.487 847.811 285.94ZM838.446 285.989C838.446 286.152 838.446 286.307 838.446 286.454C838.446 286.593 838.454 286.736 838.47 286.883L844.399 282.548C844.116 281.739 843.741 281.143 843.273 280.76C842.814 280.368 842.269 280.172 841.64 280.172C841.188 280.172 840.769 280.29 840.382 280.527C839.994 280.756 839.656 281.111 839.365 281.592C839.083 282.074 838.857 282.678 838.688 283.405C838.526 284.132 838.446 284.993 838.446 285.989ZM844.834 286.038C844.834 285.899 844.83 285.76 844.822 285.622C844.814 285.475 844.81 285.336 844.81 285.205L838.918 289.516C839.176 290.308 839.539 290.896 840.007 291.28C840.474 291.663 841.019 291.855 841.64 291.855C842.092 291.855 842.511 291.737 842.898 291.5C843.294 291.263 843.632 290.908 843.915 290.434C844.205 289.953 844.431 289.349 844.592 288.622C844.754 287.887 844.834 287.026 844.834 286.038Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 312.414C847.811 313.729 847.67 314.905 847.387 315.941C847.105 316.978 846.69 317.856 846.141 318.574C845.601 319.285 844.939 319.828 844.157 320.203C843.374 320.579 842.479 320.767 841.471 320.767C840.599 320.767 839.797 320.611 839.063 320.301C838.329 319.991 837.695 319.505 837.163 318.844C836.631 318.174 836.215 317.321 835.917 316.284C835.618 315.247 835.469 314.006 835.469 312.561C835.469 311.247 835.61 310.071 835.893 309.034C836.183 307.997 836.598 307.124 837.139 306.414C837.679 305.695 838.345 305.148 839.135 304.773C839.926 304.397 840.821 304.209 841.821 304.209C842.693 304.209 843.495 304.364 844.229 304.675C844.963 304.985 845.593 305.471 846.117 306.132C846.649 306.793 847.065 307.642 847.363 308.679C847.662 309.716 847.811 310.961 847.811 312.414ZM838.446 312.463C838.446 312.627 838.446 312.782 838.446 312.929C838.446 313.068 838.454 313.21 838.47 313.357L844.399 309.022C844.116 308.214 843.741 307.618 843.273 307.234C842.814 306.842 842.269 306.646 841.64 306.646C841.188 306.646 840.769 306.765 840.382 307.001C839.994 307.23 839.656 307.585 839.365 308.067C839.083 308.549 838.857 309.153 838.688 309.879C838.526 310.606 838.446 311.467 838.446 312.463ZM844.834 312.512C844.834 312.374 844.83 312.235 844.822 312.096C844.814 311.949 844.81 311.81 844.81 311.68L838.918 315.99C839.176 316.782 839.539 317.37 840.007 317.754C840.474 318.138 841.019 318.329 841.64 318.329C842.092 318.329 842.511 318.211 842.898 317.974C843.294 317.738 843.632 317.382 843.915 316.909C844.205 316.427 844.431 315.823 844.592 315.096C844.754 314.362 844.834 313.5 844.834 312.512Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 338.889C847.811 340.203 847.67 341.379 847.387 342.416C847.105 343.453 846.69 344.33 846.141 345.049C845.601 345.759 844.939 346.302 844.157 346.678C843.374 347.053 842.479 347.241 841.471 347.241C840.599 347.241 839.797 347.086 839.063 346.776C838.329 346.465 837.695 345.98 837.163 345.318C836.631 344.649 836.215 343.796 835.917 342.759C835.618 341.722 835.469 340.481 835.469 339.036C835.469 337.721 835.61 336.546 835.893 335.509C836.183 334.472 836.598 333.598 837.139 332.888C837.679 332.169 838.345 331.622 839.135 331.247C839.926 330.871 840.821 330.684 841.821 330.684C842.693 330.684 843.495 330.839 844.229 331.149C844.963 331.459 845.593 331.945 846.117 332.606C846.649 333.268 847.065 334.117 847.363 335.154C847.662 336.19 847.811 337.436 847.811 338.889ZM838.446 338.938C838.446 339.101 838.446 339.256 838.446 339.403C838.446 339.542 838.454 339.685 838.47 339.832L844.399 335.496C844.116 334.688 843.741 334.092 843.273 333.708C842.814 333.317 842.269 333.121 841.64 333.121C841.188 333.121 840.769 333.239 840.382 333.476C839.994 333.704 839.656 334.06 839.365 334.541C839.083 335.023 838.857 335.627 838.688 336.354C838.526 337.08 838.446 337.942 838.446 338.938ZM844.834 338.987C844.834 338.848 844.83 338.709 844.822 338.57C844.814 338.423 844.81 338.285 844.81 338.154L838.918 342.465C839.176 343.257 839.539 343.845 840.007 344.228C840.474 344.612 841.019 344.804 841.64 344.804C842.092 344.804 842.511 344.686 842.898 344.449C843.294 344.212 843.632 343.857 843.915 343.383C844.205 342.902 844.431 342.297 844.592 341.571C844.754 340.836 844.834 339.975 844.834 338.987Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 373.434V370.776H840.696V360.489L837.187 362.448L836.219 360.011L841.241 357.329H843.721V370.776H847.194V373.434H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 399.908V397.251H840.696V386.963L837.187 388.923L836.219 386.486L841.241 383.804H843.721V397.251H847.194V399.908H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 418.312C847.811 419.626 847.67 420.802 847.387 421.839C847.105 422.876 846.69 423.754 846.141 424.472C845.601 425.182 844.939 425.725 844.157 426.101C843.374 426.476 842.479 426.664 841.471 426.664C840.599 426.664 839.797 426.509 839.063 426.199C838.329 425.889 837.695 425.403 837.163 424.741C836.631 424.072 836.215 423.219 835.917 422.182C835.618 421.145 835.469 419.904 835.469 418.459C835.469 417.144 835.61 415.969 835.893 414.932C836.183 413.895 836.598 413.021 837.139 412.311C837.679 411.593 838.345 411.046 839.135 410.67C839.926 410.295 840.821 410.107 841.821 410.107C842.693 410.107 843.495 410.262 844.229 410.572C844.963 410.882 845.593 411.368 846.117 412.029C846.649 412.691 847.065 413.54 847.363 414.577C847.662 415.614 847.811 416.859 847.811 418.312ZM838.446 418.361C838.446 418.524 838.446 418.679 838.446 418.826C838.446 418.965 838.454 419.108 838.47 419.255L844.399 414.92C844.116 414.111 843.741 413.515 843.273 413.132C842.814 412.74 842.269 412.544 841.64 412.544C841.188 412.544 840.769 412.662 840.382 412.899C839.994 413.128 839.656 413.483 839.365 413.964C839.083 414.446 838.857 415.05 838.688 415.777C838.526 416.504 838.446 417.365 838.446 418.361ZM844.834 418.41C844.834 418.271 844.83 418.132 844.822 417.994C844.814 417.847 844.81 417.708 844.81 417.577L838.918 421.888C839.176 422.68 839.539 423.268 840.007 423.651C840.474 424.035 841.019 424.227 841.64 424.227C842.092 424.227 842.511 424.109 842.898 423.872C843.294 423.635 843.632 423.28 843.915 422.806C844.205 422.325 844.431 421.721 844.592 420.994C844.754 420.259 844.834 419.398 844.834 418.41Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 452.857V450.199H840.696V439.912L837.187 441.872L836.219 439.435L841.241 436.753H843.721V450.199H847.194V452.857H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 479.331V476.674H840.696V466.387L837.187 468.346L836.219 465.909L841.241 463.227H843.721V476.674H847.194V479.331H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 497.735C847.811 499.05 847.67 500.225 847.387 501.262C847.105 502.299 846.69 503.177 846.141 503.895C845.601 504.605 844.939 505.148 844.157 505.524C843.374 505.9 842.479 506.087 841.471 506.087C840.599 506.087 839.797 505.932 839.063 505.622C838.329 505.312 837.695 504.826 837.163 504.165C836.631 503.495 836.215 502.642 835.917 501.605C835.618 500.568 835.469 499.327 835.469 497.882C835.469 496.568 835.61 495.392 835.893 494.355C836.183 493.318 836.598 492.445 837.139 491.734C837.679 491.016 838.345 490.469 839.135 490.093C839.926 489.718 840.821 489.53 841.821 489.53C842.693 489.53 843.495 489.685 844.229 489.995C844.963 490.306 845.593 490.791 846.117 491.453C846.649 492.114 847.065 492.963 847.363 494C847.662 495.037 847.811 496.282 847.811 497.735ZM838.446 497.784C838.446 497.947 838.446 498.103 838.446 498.25C838.446 498.388 838.454 498.531 838.47 498.678L844.399 494.343C844.116 493.535 843.741 492.939 843.273 492.555C842.814 492.163 842.269 491.967 841.64 491.967C841.188 491.967 840.769 492.085 840.382 492.322C839.994 492.551 839.656 492.906 839.365 493.388C839.083 493.869 838.857 494.473 838.688 495.2C838.526 495.927 838.446 496.788 838.446 497.784ZM844.834 497.833C844.834 497.694 844.83 497.556 844.822 497.417C844.814 497.27 844.81 497.131 844.81 497L838.918 501.311C839.176 502.103 839.539 502.691 840.007 503.075C840.474 503.458 841.019 503.65 841.64 503.65C842.092 503.65 842.511 503.532 842.898 503.295C843.294 503.058 843.632 502.703 843.915 502.23C844.205 501.748 844.431 501.144 844.592 500.417C844.754 499.682 844.834 498.821 844.834 497.833Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 532.28V529.623H840.696V519.335L837.187 521.295L836.219 518.858L841.241 516.176H843.721V529.623H847.194V532.28H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 558.754V556.097H840.696V545.81L837.187 547.769L836.219 545.332L841.241 542.65H843.721V556.097H847.194V558.754H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 585.229V582.571H840.696V572.284L837.187 574.244L836.219 571.807L841.241 569.125H843.721V582.571H847.194V585.229H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 603.633C847.811 604.947 847.67 606.123 847.387 607.16C847.105 608.197 846.69 609.074 846.141 609.793C845.601 610.503 844.939 611.046 844.157 611.422C843.374 611.797 842.479 611.985 841.471 611.985C840.599 611.985 839.797 611.83 839.063 611.52C838.329 611.209 837.695 610.723 837.163 610.062C836.631 609.393 836.215 608.54 835.917 607.503C835.618 606.466 835.469 605.225 835.469 603.78C835.469 602.465 835.61 601.29 835.893 600.253C836.183 599.216 836.598 598.342 837.139 597.632C837.679 596.913 838.345 596.366 839.135 595.991C839.926 595.615 840.821 595.427 841.821 595.427C842.693 595.427 843.495 595.583 844.229 595.893C844.963 596.203 845.593 596.689 846.117 597.35C846.649 598.012 847.065 598.861 847.363 599.898C847.662 600.934 847.811 602.179 847.811 603.633ZM838.446 603.682C838.446 603.845 838.446 604 838.446 604.147C838.446 604.286 838.454 604.429 838.47 604.576L844.399 600.24C844.116 599.432 843.741 598.836 843.273 598.452C842.814 598.061 842.269 597.865 841.64 597.865C841.188 597.865 840.769 597.983 840.382 598.22C839.994 598.448 839.656 598.803 839.365 599.285C839.083 599.767 838.857 600.371 838.688 601.098C838.526 601.824 838.446 602.686 838.446 603.682ZM844.834 603.731C844.834 603.592 844.83 603.453 844.822 603.314C844.814 603.167 844.81 603.029 844.81 602.898L838.918 607.209C839.176 608.001 839.539 608.589 840.007 608.972C840.474 609.356 841.019 609.548 841.64 609.548C842.092 609.548 842.511 609.429 842.898 609.193C843.294 608.956 843.632 608.601 843.915 608.127C844.205 607.646 844.431 607.041 844.592 606.315C844.754 605.58 844.834 604.719 844.834 603.731Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M847.811 630.107C847.811 631.422 847.67 632.597 847.387 633.634C847.105 634.671 846.69 635.549 846.141 636.267C845.601 636.977 844.939 637.52 844.157 637.896C843.374 638.271 842.479 638.459 841.471 638.459C840.599 638.459 839.797 638.304 839.063 637.994C838.329 637.684 837.695 637.198 837.163 636.537C836.631 635.867 836.215 635.014 835.917 633.977C835.618 632.94 835.469 631.699 835.469 630.254C835.469 628.94 835.61 627.764 835.893 626.727C836.183 625.69 836.598 624.817 837.139 624.106C837.679 623.388 838.345 622.841 839.135 622.465C839.926 622.09 840.821 621.902 841.821 621.902C842.693 621.902 843.495 622.057 844.229 622.367C844.963 622.678 845.593 623.163 846.117 623.825C846.649 624.486 847.065 625.335 847.363 626.372C847.662 627.409 847.811 628.654 847.811 630.107ZM838.446 630.156C838.446 630.319 838.446 630.475 838.446 630.621C838.446 630.76 838.454 630.903 838.47 631.05L844.399 626.715C844.116 625.907 843.741 625.311 843.273 624.927C842.814 624.535 842.269 624.339 841.64 624.339C841.188 624.339 840.769 624.457 840.382 624.694C839.994 624.923 839.656 625.278 839.365 625.76C839.083 626.241 838.857 626.845 838.688 627.572C838.526 628.299 838.446 629.16 838.446 630.156ZM844.834 630.205C844.834 630.066 844.83 629.927 844.822 629.789C844.814 629.642 844.81 629.503 844.81 629.372L838.918 633.683C839.176 634.475 839.539 635.063 840.007 635.447C840.474 635.83 841.019 636.022 841.64 636.022C842.092 636.022 842.511 635.904 842.898 635.667C843.294 635.43 843.632 635.075 843.915 634.602C844.205 634.12 844.431 633.516 844.592 632.789C844.754 632.054 844.834 631.193 844.834 630.205Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M836.667 664.652V661.994H840.696V651.707L837.187 653.667L836.219 651.23L841.241 648.548H843.721V661.994H847.194V664.652H836.667Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M881.474 150.285C881.474 151.453 881.349 152.498 881.098 153.42C880.847 154.341 880.477 155.122 879.99 155.76C879.509 156.392 878.921 156.874 878.226 157.208C877.53 157.542 876.735 157.709 875.838 157.709C875.064 157.709 874.35 157.571 873.698 157.295C873.045 157.019 872.482 156.588 872.009 156C871.536 155.405 871.167 154.646 870.901 153.725C870.636 152.803 870.503 151.7 870.503 150.415C870.503 149.247 870.629 148.202 870.88 147.28C871.138 146.358 871.507 145.582 871.988 144.951C872.468 144.312 873.06 143.826 873.762 143.492C874.465 143.158 875.261 142.991 876.15 142.991C876.925 142.991 877.638 143.129 878.29 143.405C878.943 143.681 879.502 144.112 879.968 144.7C880.442 145.288 880.811 146.043 881.076 146.964C881.341 147.886 881.474 148.993 881.474 150.285ZM873.149 150.328C873.149 150.473 873.149 150.611 873.149 150.742C873.149 150.865 873.157 150.992 873.171 151.123L878.441 147.269C878.19 146.551 877.857 146.021 877.441 145.68C877.032 145.332 876.548 145.157 875.989 145.157C875.587 145.157 875.214 145.263 874.87 145.473C874.526 145.676 874.225 145.992 873.967 146.42C873.716 146.848 873.515 147.385 873.364 148.031C873.221 148.677 873.149 149.443 873.149 150.328ZM878.828 150.372C878.828 150.248 878.825 150.125 878.818 150.002C878.81 149.871 878.807 149.748 878.807 149.631L873.569 153.463C873.798 154.167 874.121 154.69 874.537 155.031C874.953 155.372 875.437 155.542 875.989 155.542C876.39 155.542 876.763 155.437 877.107 155.227C877.459 155.016 877.76 154.701 878.011 154.28C878.269 153.852 878.47 153.315 878.613 152.669C878.757 152.015 878.828 151.25 878.828 150.372Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 181.146V178.784H875.15V169.64L872.031 171.381L871.17 169.215L875.634 166.831H877.839V178.784H880.926V181.146H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 204.834V202.471H875.15V193.327L872.031 195.069L871.17 192.903L875.634 190.519H877.839V202.471H880.926V204.834H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M881.474 221.347C881.474 222.516 881.349 223.561 881.098 224.483C880.847 225.404 880.477 226.184 879.99 226.823C879.509 227.454 878.921 227.937 878.226 228.271C877.53 228.605 876.735 228.772 875.838 228.772C875.064 228.772 874.35 228.634 873.698 228.358C873.045 228.082 872.482 227.65 872.009 227.063C871.536 226.467 871.167 225.709 870.901 224.787C870.636 223.866 870.503 222.763 870.503 221.478C870.503 220.31 870.629 219.265 870.88 218.343C871.138 217.421 871.507 216.645 871.988 216.013C872.468 215.375 873.06 214.889 873.762 214.555C874.465 214.221 875.261 214.054 876.15 214.054C876.925 214.054 877.638 214.192 878.29 214.468C878.943 214.743 879.502 215.175 879.968 215.763C880.442 216.351 880.811 217.106 881.076 218.027C881.341 218.949 881.474 220.056 881.474 221.347ZM873.149 221.391C873.149 221.536 873.149 221.674 873.149 221.805C873.149 221.928 873.157 222.055 873.171 222.186L878.441 218.332C878.19 217.614 877.857 217.084 877.441 216.743C877.032 216.394 876.548 216.22 875.989 216.22C875.587 216.22 875.214 216.325 874.87 216.536C874.526 216.739 874.225 217.055 873.967 217.483C873.716 217.911 873.515 218.448 873.364 219.094C873.221 219.74 873.149 220.506 873.149 221.391ZM878.828 221.435C878.828 221.311 878.825 221.188 878.818 221.064C878.81 220.934 878.807 220.81 878.807 220.694L873.569 224.526C873.798 225.23 874.121 225.753 874.537 226.094C874.953 226.435 875.437 226.605 875.989 226.605C876.39 226.605 876.763 226.5 877.107 226.29C877.459 226.079 877.76 225.763 878.011 225.343C878.269 224.914 878.47 224.377 878.613 223.731C878.757 223.078 878.828 222.313 878.828 221.435Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 252.209V249.847H875.15V240.702L872.031 242.444L871.17 240.278L875.634 237.894H877.839V249.847H880.926V252.209H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M881.474 268.723C881.474 269.891 881.349 270.936 881.098 271.858C880.847 272.779 880.477 273.56 879.99 274.198C879.509 274.83 878.921 275.312 878.226 275.646C877.53 275.98 876.735 276.147 875.838 276.147C875.064 276.147 874.35 276.009 873.698 275.733C873.045 275.457 872.482 275.026 872.009 274.438C871.536 273.843 871.167 273.084 870.901 272.163C870.636 271.241 870.503 270.138 870.503 268.853C870.503 267.685 870.629 266.64 870.88 265.718C871.138 264.797 871.507 264.02 871.988 263.389C872.468 262.75 873.06 262.264 873.762 261.93C874.465 261.596 875.261 261.429 876.15 261.429C876.925 261.429 877.638 261.567 878.29 261.843C878.943 262.119 879.502 262.55 879.968 263.138C880.442 263.726 880.811 264.481 881.076 265.402C881.341 266.324 881.474 267.431 881.474 268.723ZM873.149 268.766C873.149 268.911 873.149 269.049 873.149 269.18C873.149 269.303 873.157 269.43 873.171 269.561L878.441 265.707C878.19 264.989 877.857 264.459 877.441 264.118C877.032 263.77 876.548 263.595 875.989 263.595C875.587 263.595 875.214 263.701 874.87 263.911C874.526 264.114 874.225 264.43 873.967 264.858C873.716 265.286 873.515 265.823 873.364 266.469C873.221 267.115 873.149 267.881 873.149 268.766ZM878.828 268.81C878.828 268.686 878.825 268.563 878.818 268.44C878.81 268.309 878.807 268.186 878.807 268.07L873.569 271.901C873.798 272.605 874.121 273.128 874.537 273.469C874.953 273.81 875.437 273.981 875.989 273.981C876.39 273.981 876.763 273.875 877.107 273.665C877.459 273.454 877.76 273.139 878.011 272.718C878.269 272.29 878.47 271.753 878.613 271.107C878.757 270.454 878.828 269.688 878.828 268.81Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 346.959V344.597H875.15V335.453L872.031 337.195L871.17 335.028L875.634 332.644H877.839V344.597H880.926V346.959H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 370.647V368.285H875.15V359.141L872.031 360.882L871.17 358.716L875.634 356.332H877.839V368.285H880.926V370.647H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M881.474 387.161C881.474 388.329 881.349 389.374 881.098 390.296C880.847 391.218 880.477 391.998 879.99 392.636C879.509 393.268 878.921 393.75 878.226 394.084C877.53 394.418 876.735 394.585 875.838 394.585C875.064 394.585 874.35 394.447 873.698 394.171C873.045 393.895 872.482 393.464 872.009 392.876C871.536 392.281 871.167 391.522 870.901 390.601C870.636 389.679 870.503 388.576 870.503 387.291C870.503 386.123 870.629 385.078 870.88 384.156C871.138 383.235 871.507 382.458 871.988 381.827C872.468 381.188 873.06 380.702 873.762 380.368C874.465 380.034 875.261 379.867 876.15 379.867C876.925 379.867 877.638 380.005 878.29 380.281C878.943 380.557 879.502 380.988 879.968 381.576C880.442 382.164 880.811 382.919 881.076 383.841C881.341 384.762 881.474 385.869 881.474 387.161ZM873.149 387.204C873.149 387.349 873.149 387.487 873.149 387.618C873.149 387.741 873.157 387.868 873.171 387.999L878.441 384.145C878.19 383.427 877.857 382.897 877.441 382.556C877.032 382.208 876.548 382.034 875.989 382.034C875.587 382.034 875.214 382.139 874.87 382.349C874.526 382.552 874.225 382.868 873.967 383.296C873.716 383.724 873.515 384.261 873.364 384.907C873.221 385.553 873.149 386.319 873.149 387.204ZM878.828 387.248C878.828 387.124 878.825 387.001 878.818 386.878C878.81 386.747 878.807 386.624 878.807 386.508L873.569 390.339C873.798 391.043 874.121 391.566 874.537 391.907C874.953 392.248 875.437 392.419 875.989 392.419C876.39 392.419 876.763 392.313 877.107 392.103C877.459 391.892 877.76 391.577 878.011 391.156C878.269 390.728 878.47 390.191 878.613 389.545C878.757 388.892 878.828 388.126 878.828 387.248Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 418.022V415.66H875.15V406.516L872.031 408.258L871.17 406.091L875.634 403.707H877.839V415.66H880.926V418.022H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 441.71V439.348H875.15V430.203L872.031 431.945L871.17 429.779L875.634 427.395H877.839V439.348H880.926V441.71H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M881.474 458.224C881.474 459.392 881.349 460.437 881.098 461.359C880.847 462.28 880.477 463.061 879.99 463.699C879.509 464.331 878.921 464.813 878.226 465.147C877.53 465.481 876.735 465.648 875.838 465.648C875.064 465.648 874.35 465.51 873.698 465.234C873.045 464.958 872.482 464.527 872.009 463.939C871.536 463.344 871.167 462.585 870.901 461.664C870.636 460.742 870.503 459.639 870.503 458.354C870.503 457.186 870.629 456.141 870.88 455.219C871.138 454.297 871.507 453.521 871.988 452.89C872.468 452.251 873.06 451.765 873.762 451.431C874.465 451.097 875.261 450.93 876.15 450.93C876.925 450.93 877.638 451.068 878.29 451.344C878.943 451.62 879.502 452.051 879.968 452.639C880.442 453.227 880.811 453.982 881.076 454.903C881.341 455.825 881.474 456.932 881.474 458.224ZM873.149 458.267C873.149 458.412 873.149 458.55 873.149 458.681C873.149 458.804 873.157 458.931 873.171 459.062L878.441 455.208C878.19 454.49 877.857 453.96 877.441 453.619C877.032 453.271 876.548 453.096 875.989 453.096C875.587 453.096 875.214 453.202 874.87 453.412C874.526 453.615 874.225 453.931 873.967 454.359C873.716 454.787 873.515 455.324 873.364 455.97C873.221 456.616 873.149 457.382 873.149 458.267ZM878.828 458.311C878.828 458.187 878.825 458.064 878.818 457.941C878.81 457.81 878.807 457.687 878.807 457.57L873.569 461.402C873.798 462.106 874.121 462.629 874.537 462.97C874.953 463.311 875.437 463.481 875.989 463.481C876.39 463.481 876.763 463.376 877.107 463.166C877.459 462.955 877.76 462.64 878.011 462.219C878.269 461.791 878.47 461.254 878.613 460.608C878.757 459.954 878.828 459.189 878.828 458.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 489.085V486.723H875.15V477.579L872.031 479.32L871.17 477.154L875.634 474.77H877.839V486.723H880.926V489.085H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 512.773V510.41H875.15V501.266L872.031 503.008L871.17 500.842L875.634 498.458H877.839V510.41H880.926V512.773H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 536.46V534.098H875.15V524.954L872.031 526.696L871.17 524.529L875.634 522.145H877.839V534.098H880.926V536.46H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M881.474 552.974C881.474 554.142 881.349 555.188 881.098 556.109C880.847 557.031 880.477 557.811 879.99 558.45C879.509 559.081 878.921 559.564 878.226 559.897C877.53 560.231 876.735 560.398 875.838 560.398C875.064 560.398 874.35 560.26 873.698 559.985C873.045 559.709 872.482 559.277 872.009 558.689C871.536 558.094 871.167 557.336 870.901 556.414C870.636 555.492 870.503 554.389 870.503 553.105C870.503 551.936 870.629 550.891 870.88 549.97C871.138 549.048 871.507 548.271 871.988 547.64C872.468 547.001 873.06 546.515 873.762 546.181C874.465 545.847 875.261 545.681 876.15 545.681C876.925 545.681 877.638 545.818 878.29 546.094C878.943 546.37 879.502 546.802 879.968 547.39C880.442 547.977 880.811 548.732 881.076 549.654C881.341 550.576 881.474 551.682 881.474 552.974ZM873.149 553.018C873.149 553.163 873.149 553.301 873.149 553.431C873.149 553.555 873.157 553.682 873.171 553.812L878.441 549.959C878.19 549.24 877.857 548.71 877.441 548.369C877.032 548.021 876.548 547.847 875.989 547.847C875.587 547.847 875.214 547.952 874.87 548.163C874.526 548.366 874.225 548.681 873.967 549.11C873.716 549.538 873.515 550.075 873.364 550.721C873.221 551.367 873.149 552.132 873.149 553.018ZM878.828 553.061C878.828 552.938 878.825 552.814 878.818 552.691C878.81 552.56 878.807 552.437 878.807 552.321L873.569 556.153C873.798 556.857 874.121 557.379 874.537 557.72C874.953 558.061 875.437 558.232 875.989 558.232C876.39 558.232 876.763 558.127 877.107 557.916C877.459 557.706 877.76 557.39 878.011 556.969C878.269 556.541 878.47 556.004 878.613 555.358C878.757 554.705 878.828 553.939 878.828 553.061Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M881.474 576.662C881.474 577.83 881.349 578.875 881.098 579.797C880.847 580.718 880.477 581.499 879.99 582.137C879.509 582.769 878.921 583.251 878.226 583.585C877.53 583.919 876.735 584.086 875.838 584.086C875.064 584.086 874.35 583.948 873.698 583.672C873.045 583.396 872.482 582.965 872.009 582.377C871.536 581.782 871.167 581.023 870.901 580.102C870.636 579.18 870.503 578.077 870.503 576.792C870.503 575.624 870.629 574.579 870.88 573.657C871.138 572.736 871.507 571.959 871.988 571.328C872.468 570.689 873.06 570.203 873.762 569.869C874.465 569.535 875.261 569.368 876.15 569.368C876.925 569.368 877.638 569.506 878.29 569.782C878.943 570.058 879.502 570.489 879.968 571.077C880.442 571.665 880.811 572.42 881.076 573.341C881.341 574.263 881.474 575.37 881.474 576.662ZM873.149 576.705C873.149 576.85 873.149 576.988 873.149 577.119C873.149 577.242 873.157 577.369 873.171 577.5L878.441 573.646C878.19 572.928 877.857 572.398 877.441 572.057C877.032 571.709 876.548 571.534 875.989 571.534C875.587 571.534 875.214 571.64 874.87 571.85C874.526 572.053 874.225 572.369 873.967 572.797C873.716 573.225 873.515 573.762 873.364 574.408C873.221 575.054 873.149 575.82 873.149 576.705ZM878.828 576.749C878.828 576.625 878.825 576.502 878.818 576.379C878.81 576.248 878.807 576.125 878.807 576.009L873.569 579.84C873.798 580.544 874.121 581.067 874.537 581.408C874.953 581.749 875.437 581.92 875.989 581.92C876.39 581.92 876.763 581.814 877.107 581.604C877.459 581.393 877.76 581.078 878.011 580.657C878.269 580.229 878.47 579.692 878.613 579.046C878.757 578.393 878.828 577.627 878.828 576.749Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M871.568 607.523V605.161H875.15V596.017L872.031 597.758L871.17 595.592L875.634 593.208H877.839V605.161H880.926V607.523H871.568Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M918.443 -94.0483H907.406V-96.2527H911.923V-109.234L907.715 -106.921L906.854 -108.935L912.447 -111.915H914.518V-96.2527H918.443V-94.0483Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 -64.0905H907.406V-66.2948H911.923V-79.2762L907.715 -76.963L906.854 -78.9769L912.447 -81.9569H914.518V-66.2948H918.443V-64.0905Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 -43.0182C918.995 -41.6574 918.86 -40.4146 918.591 -39.2898C918.322 -38.174 917.915 -37.2124 917.368 -36.405C916.821 -35.6067 916.14 -34.9853 915.324 -34.5408C914.518 -34.1054 913.568 -33.8877 912.474 -33.8877C911.533 -33.8877 910.677 -34.0646 909.906 -34.4183C909.135 -34.7812 908.477 -35.3346 907.93 -36.0784C907.383 -36.8223 906.962 -37.7703 906.666 -38.9224C906.37 -40.0745 906.223 -41.4397 906.223 -43.0182C906.223 -44.3789 906.357 -45.6217 906.626 -46.7466C906.904 -47.8714 907.312 -48.833 907.849 -49.6313C908.396 -50.4387 909.077 -51.0601 909.893 -51.4955C910.708 -51.94 911.659 -52.1623 912.743 -52.1623C913.684 -52.1623 914.54 -51.9809 915.311 -51.618C916.082 -51.2642 916.74 -50.7108 917.287 -49.9579C917.834 -49.214 918.255 -48.2661 918.551 -47.114C918.847 -45.9619 918.995 -44.5966 918.995 -43.0182ZM916.628 -42.9365C916.628 -43.245 916.615 -43.5489 916.588 -43.8482C916.57 -44.1566 916.548 -44.456 916.521 -44.7463L909.086 -39.1537C909.221 -38.682 909.391 -38.2465 909.597 -37.8474C909.803 -37.4482 910.05 -37.1035 910.336 -36.8132C910.632 -36.532 910.964 -36.3098 911.331 -36.1465C911.708 -35.9923 912.134 -35.9151 912.609 -35.9151C913.218 -35.9151 913.769 -36.0648 914.262 -36.3642C914.764 -36.6636 915.19 -37.1081 915.539 -37.6977C915.889 -38.2874 916.158 -39.0176 916.346 -39.8885C916.534 -40.7684 916.628 -41.7844 916.628 -42.9365ZM908.589 -43.0998C908.589 -42.8186 908.589 -42.5374 908.589 -42.2562C908.598 -41.9749 908.616 -41.7028 908.642 -41.4397L916.077 -47.0051C915.943 -47.4678 915.772 -47.8896 915.566 -48.2706C915.36 -48.6516 915.114 -48.9782 914.827 -49.2503C914.54 -49.5315 914.213 -49.7493 913.845 -49.9035C913.478 -50.0577 913.066 -50.1348 912.609 -50.1348C911.999 -50.1348 911.443 -49.9851 910.941 -49.6858C910.449 -49.3864 910.027 -48.9374 909.678 -48.3386C909.328 -47.749 909.059 -47.0142 908.871 -46.1342C908.683 -45.2634 908.589 -44.2519 908.589 -43.0998Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 -13.0603C918.995 -11.6996 918.86 -10.4568 918.591 -9.33189C918.322 -8.21609 917.915 -7.25451 917.368 -6.44714C916.821 -5.64885 916.14 -5.02745 915.324 -4.58294C914.518 -4.14751 913.568 -3.92979 912.474 -3.92979C911.533 -3.92979 910.677 -4.10668 909.906 -4.46047C909.135 -4.82333 908.477 -5.3767 907.93 -6.12057C907.383 -6.86443 906.962 -7.81241 906.666 -8.9645C906.37 -10.1166 906.223 -11.4818 906.223 -13.0603C906.223 -14.421 906.357 -15.6638 906.626 -16.7887C906.904 -17.9136 907.312 -18.8752 907.849 -19.6735C908.396 -20.4808 909.077 -21.1022 909.893 -21.5377C910.708 -21.9822 911.659 -22.2044 912.743 -22.2044C913.684 -22.2044 914.54 -22.023 915.311 -21.6601C916.082 -21.3063 916.74 -20.753 917.287 -20C917.834 -19.2562 918.255 -18.3082 918.551 -17.1561C918.847 -16.004 918.995 -14.6387 918.995 -13.0603ZM916.628 -12.9787C916.628 -13.2871 916.615 -13.591 916.588 -13.8903C916.57 -14.1988 916.548 -14.4981 916.521 -14.7884L909.086 -9.19582C909.221 -8.7241 909.391 -8.28867 909.597 -7.88952C909.803 -7.49037 910.05 -7.14565 910.336 -6.85536C910.632 -6.57414 910.964 -6.35189 911.331 -6.18861C911.708 -6.03439 912.134 -5.95728 912.609 -5.95728C913.218 -5.95728 913.769 -6.10696 914.262 -6.40632C914.764 -6.70568 915.19 -7.15019 915.539 -7.73984C915.889 -8.32949 916.158 -9.05975 916.346 -9.93062C916.534 -10.8106 916.628 -11.8266 916.628 -12.9787ZM908.589 -13.1419C908.589 -12.8607 908.589 -12.5795 908.589 -12.2983C908.598 -12.0171 908.616 -11.7449 908.642 -11.4818L916.077 -17.0472C915.943 -17.5099 915.772 -17.9317 915.566 -18.3127C915.36 -18.6937 915.114 -19.0203 914.827 -19.2924C914.54 -19.5737 914.213 -19.7914 913.845 -19.9456C913.478 -20.0998 913.066 -20.1769 912.609 -20.1769C911.999 -20.1769 911.443 -20.0272 910.941 -19.7279C910.449 -19.4285 910.027 -18.9795 909.678 -18.3808C909.328 -17.7911 909.059 -17.0563 908.871 -16.1764C908.683 -15.3055 908.589 -14.294 908.589 -13.1419Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 16.8976C918.995 18.2583 918.86 19.5011 918.591 20.626C918.322 21.7418 917.915 22.7034 917.368 23.5107C916.821 24.309 916.14 24.9304 915.324 25.3749C914.518 25.8104 913.568 26.0281 912.474 26.0281C911.533 26.0281 910.677 25.8512 909.906 25.4974C909.135 25.1345 908.477 24.5812 907.93 23.8373C907.383 23.0934 906.962 22.1455 906.666 20.9934C906.37 19.8413 906.223 18.476 906.223 16.8976C906.223 15.5368 906.357 14.294 906.626 13.1692C906.904 12.0443 907.312 11.0827 907.849 10.2844C908.396 9.47704 909.077 8.85564 909.893 8.42021C910.708 7.97571 911.659 7.75345 912.743 7.75345C913.684 7.75345 914.54 7.93488 915.311 8.29774C916.082 8.65154 916.74 9.2049 917.287 9.95784C917.834 10.7017 918.255 11.6497 918.551 12.8018C918.847 13.9538 918.995 15.3191 918.995 16.8976ZM916.628 16.9792C916.628 16.6708 916.615 16.3669 916.588 16.0675C916.57 15.7591 916.548 15.4597 916.521 15.1694L909.086 20.762C909.221 21.2338 909.391 21.6692 909.597 22.0683C909.803 22.4675 910.05 22.8122 910.336 23.1025C910.632 23.3837 910.964 23.606 911.331 23.7693C911.708 23.9235 912.134 24.0006 912.609 24.0006C913.218 24.0006 913.769 23.8509 914.262 23.5515C914.764 23.2522 915.19 22.8077 915.539 22.218C915.889 21.6284 916.158 20.8981 916.346 20.0273C916.534 19.1473 916.628 18.1313 916.628 16.9792ZM908.589 16.8159C908.589 17.0971 908.589 17.3784 908.589 17.6596C908.598 17.9408 908.616 18.2129 908.642 18.476L916.077 12.9106C915.943 12.448 915.772 12.0261 915.566 11.6451C915.36 11.2641 915.114 10.9376 914.827 10.6654C914.54 10.3842 914.213 10.1665 913.845 10.0123C913.478 9.85805 913.066 9.78094 912.609 9.78094C911.999 9.78094 911.443 9.93063 910.941 10.23C910.449 10.5293 910.027 10.9784 909.678 11.5771C909.328 12.1668 909.059 12.9016 908.871 13.7815C908.683 14.6524 908.589 15.6638 908.589 16.8159Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 55.741H907.406V53.5366H911.923V40.5553L907.715 42.8685L906.854 40.8546L912.447 37.8746H914.518V53.5366H918.443V55.741Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 76.8133C918.995 78.174 918.86 79.4168 918.591 80.5417C918.322 81.6575 917.915 82.6191 917.368 83.4265C916.821 84.2248 916.14 84.8462 915.324 85.2907C914.518 85.7261 913.568 85.9438 912.474 85.9438C911.533 85.9438 910.677 85.7669 909.906 85.4131C909.135 85.0503 908.477 84.4969 907.93 83.753C907.383 83.0092 906.962 82.0612 906.666 80.9091C906.37 79.757 906.223 78.3918 906.223 76.8133C906.223 75.4526 906.357 74.2098 906.626 73.0849C906.904 71.96 907.312 70.9984 907.849 70.2001C908.396 69.3928 909.077 68.7714 909.893 68.336C910.708 67.8914 911.659 67.6692 912.743 67.6692C913.684 67.6692 914.54 67.8506 915.311 68.2135C916.082 68.5673 916.74 69.1206 917.287 69.8736C917.834 70.6174 918.255 71.5654 918.551 72.7175C918.847 73.8696 918.995 75.2349 918.995 76.8133ZM916.628 76.8949C916.628 76.5865 916.615 76.2826 916.588 75.9833C916.57 75.6748 916.548 75.3755 916.521 75.0852L909.086 80.6778C909.221 81.1495 909.391 81.5849 909.597 81.9841C909.803 82.3832 910.05 82.728 910.336 83.0182C910.632 83.2995 910.964 83.5217 911.331 83.685C911.708 83.8392 912.134 83.9163 912.609 83.9163C913.218 83.9163 913.769 83.7666 914.262 83.4673C914.764 83.1679 915.19 82.7234 915.539 82.1338C915.889 81.5441 916.158 80.8139 916.346 79.943C916.534 79.063 916.628 78.047 916.628 76.8949ZM908.589 76.7317C908.589 77.0129 908.589 77.2941 908.589 77.5753C908.598 77.8565 908.616 78.1287 908.642 78.3918L916.077 72.8264C915.943 72.3637 915.772 71.9419 915.566 71.5609C915.36 71.1799 915.114 70.8533 914.827 70.5812C914.54 70.2999 914.213 70.0822 913.845 69.928C913.478 69.7738 913.066 69.6967 912.609 69.6967C911.999 69.6967 911.443 69.8464 910.941 70.1457C910.449 70.4451 910.027 70.8941 909.678 71.4928C909.328 72.0825 909.059 72.8173 908.871 73.6972C908.683 74.5681 908.589 75.5796 908.589 76.7317Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 115.657H907.406V113.452H911.923V100.471L907.715 102.784L906.854 100.77L912.447 97.7903H914.518V113.452H918.443V115.657Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 145.615H907.406V143.41H911.923V130.429L907.715 132.742L906.854 130.728L912.447 127.748H914.518V143.41H918.443V145.615Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 166.687C918.995 168.048 918.86 169.29 918.591 170.415C918.322 171.531 917.915 172.493 917.368 173.3C916.821 174.098 916.14 174.72 915.324 175.164C914.518 175.6 913.568 175.817 912.474 175.817C911.533 175.817 910.677 175.641 909.906 175.287C909.135 174.924 908.477 174.371 907.93 173.627C907.383 172.883 906.962 171.935 906.666 170.783C906.37 169.631 906.223 168.265 906.223 166.687C906.223 165.326 906.357 164.083 906.626 162.958C906.904 161.834 907.312 160.872 907.849 160.074C908.396 159.266 909.077 158.645 909.893 158.21C910.708 157.765 911.659 157.543 912.743 157.543C913.684 157.543 914.54 157.724 915.311 158.087C916.082 158.441 916.74 158.994 917.287 159.747C917.834 160.491 918.255 161.439 918.551 162.591C918.847 163.743 918.995 165.108 918.995 166.687ZM916.628 166.769C916.628 166.46 916.615 166.156 916.588 165.857C916.57 165.548 916.548 165.249 916.521 164.959L909.086 170.551C909.221 171.023 909.391 171.459 909.597 171.858C909.803 172.257 910.05 172.602 910.336 172.892C910.632 173.173 910.964 173.395 911.331 173.559C911.708 173.713 912.134 173.79 912.609 173.79C913.218 173.79 913.769 173.64 914.262 173.341C914.764 173.042 915.19 172.597 915.539 172.007C915.889 171.418 916.158 170.687 916.346 169.817C916.534 168.937 916.628 167.921 916.628 166.769ZM908.589 166.605C908.589 166.886 908.589 167.168 908.589 167.449C908.598 167.73 908.616 168.002 908.642 168.265L916.077 162.7C915.943 162.237 915.772 161.815 915.566 161.434C915.36 161.053 915.114 160.727 914.827 160.455C914.54 160.174 914.213 159.956 913.845 159.802C913.478 159.647 913.066 159.57 912.609 159.57C911.999 159.57 911.443 159.72 910.941 160.019C910.449 160.319 910.027 160.768 909.678 161.366C909.328 161.956 909.059 162.691 908.871 163.571C908.683 164.442 908.589 165.453 908.589 166.605Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 196.645C918.995 198.006 918.86 199.248 918.591 200.373C918.322 201.489 917.915 202.451 917.368 203.258C916.821 204.056 916.14 204.678 915.324 205.122C914.518 205.558 913.568 205.775 912.474 205.775C911.533 205.775 910.677 205.598 909.906 205.245C909.135 204.882 908.477 204.328 907.93 203.585C907.383 202.841 906.962 201.893 906.666 200.741C906.37 199.589 906.223 198.223 906.223 196.645C906.223 195.284 906.357 194.041 906.626 192.916C906.904 191.792 907.312 190.83 907.849 190.032C908.396 189.224 909.077 188.603 909.893 188.167C910.708 187.723 911.659 187.501 912.743 187.501C913.684 187.501 914.54 187.682 915.311 188.045C916.082 188.399 916.74 188.952 917.287 189.705C917.834 190.449 918.255 191.397 918.551 192.549C918.847 193.701 918.995 195.066 918.995 196.645ZM916.628 196.726C916.628 196.418 916.615 196.114 916.588 195.815C916.57 195.506 916.548 195.207 916.521 194.917L909.086 200.509C909.221 200.981 909.391 201.416 909.597 201.816C909.803 202.215 910.05 202.559 910.336 202.85C910.632 203.131 910.964 203.353 911.331 203.516C911.708 203.671 912.134 203.748 912.609 203.748C913.218 203.748 913.769 203.598 914.262 203.299C914.764 202.999 915.19 202.555 915.539 201.965C915.889 201.376 916.158 200.645 916.346 199.774C916.534 198.895 916.628 197.879 916.628 196.726ZM908.589 196.563C908.589 196.844 908.589 197.126 908.589 197.407C908.598 197.688 908.616 197.96 908.642 198.223L916.077 192.658C915.943 192.195 915.772 191.773 915.566 191.392C915.36 191.011 915.114 190.685 914.827 190.413C914.54 190.131 914.213 189.914 913.845 189.759C913.478 189.605 913.066 189.528 912.609 189.528C911.999 189.528 911.443 189.678 910.941 189.977C910.449 190.277 910.027 190.726 909.678 191.324C909.328 191.914 909.059 192.649 908.871 193.529C908.683 194.4 908.589 195.411 908.589 196.563Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 226.603C918.995 227.963 918.86 229.206 918.591 230.331C918.322 231.447 917.915 232.408 917.368 233.216C916.821 234.014 916.14 234.635 915.324 235.08C914.518 235.515 913.568 235.733 912.474 235.733C911.533 235.733 910.677 235.556 909.906 235.202C909.135 234.84 908.477 234.286 907.93 233.542C907.383 232.799 906.962 231.851 906.666 230.698C906.37 229.546 906.223 228.181 906.223 226.603C906.223 225.242 906.357 223.999 906.626 222.874C906.904 221.749 907.312 220.788 907.849 219.99C908.396 219.182 909.077 218.561 909.893 218.125C910.708 217.681 911.659 217.459 912.743 217.459C913.684 217.459 914.54 217.64 915.311 218.003C916.082 218.357 916.74 218.91 917.287 219.663C917.834 220.407 918.255 221.355 918.551 222.507C918.847 223.659 918.995 225.024 918.995 226.603ZM916.628 226.684C916.628 226.376 916.615 226.072 916.588 225.773C916.57 225.464 916.548 225.165 916.521 224.875L909.086 230.467C909.221 230.939 909.391 231.374 909.597 231.773C909.803 232.173 910.05 232.517 910.336 232.808C910.632 233.089 910.964 233.311 911.331 233.474C911.708 233.629 912.134 233.706 912.609 233.706C913.218 233.706 913.769 233.556 914.262 233.257C914.764 232.957 915.19 232.513 915.539 231.923C915.889 231.333 916.158 230.603 916.346 229.732C916.534 228.852 916.628 227.836 916.628 226.684ZM908.589 226.521C908.589 226.802 908.589 227.083 908.589 227.365C908.598 227.646 908.616 227.918 908.642 228.181L916.077 222.616C915.943 222.153 915.772 221.731 915.566 221.35C915.36 220.969 915.114 220.643 914.827 220.37C914.54 220.089 914.213 219.872 913.845 219.717C913.478 219.563 913.066 219.486 912.609 219.486C911.999 219.486 911.443 219.636 910.941 219.935C910.449 220.234 910.027 220.683 909.678 221.282C909.328 221.872 909.059 222.607 908.871 223.487C908.683 224.357 908.589 225.369 908.589 226.521Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 265.446H907.406V263.242H911.923V250.26L907.715 252.574L906.854 250.56L912.447 247.58H914.518V263.242H918.443V265.446Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 295.404H907.406V293.2H911.923V280.218L907.715 282.531L906.854 280.518L912.447 277.538H914.518V293.2H918.443V295.404Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 325.362H907.406V323.157H911.923V310.176L907.715 312.489L906.854 310.475L912.447 307.495H914.518V323.157H918.443V325.362Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 346.434C918.995 347.795 918.86 349.038 918.591 350.163C918.322 351.278 917.915 352.24 917.368 353.047C916.821 353.846 916.14 354.467 915.324 354.911C914.518 355.347 913.568 355.565 912.474 355.565C911.533 355.565 910.677 355.388 909.906 355.034C909.135 354.671 908.477 354.118 907.93 353.374C907.383 352.63 906.962 351.682 906.666 350.53C906.37 349.378 906.223 348.013 906.223 346.434C906.223 345.073 906.357 343.831 906.626 342.706C906.904 341.581 907.312 340.619 907.849 339.821C908.396 339.014 909.077 338.392 909.893 337.957C910.708 337.512 911.659 337.29 912.743 337.29C913.684 337.29 914.54 337.471 915.311 337.834C916.082 338.188 916.74 338.741 917.287 339.494C917.834 340.238 918.255 341.186 918.551 342.338C918.847 343.49 918.995 344.856 918.995 346.434ZM916.628 346.516C916.628 346.207 916.615 345.903 916.588 345.604C916.57 345.296 916.548 344.996 916.521 344.706L909.086 350.299C909.221 350.77 909.391 351.206 909.597 351.605C909.803 352.004 910.05 352.349 910.336 352.639C910.632 352.92 910.964 353.143 911.331 353.306C911.708 353.46 912.134 353.537 912.609 353.537C913.218 353.537 913.769 353.387 914.262 353.088C914.764 352.789 915.19 352.344 915.539 351.755C915.889 351.165 916.158 350.435 916.346 349.564C916.534 348.684 916.628 347.668 916.628 346.516ZM908.589 346.352C908.589 346.634 908.589 346.915 908.589 347.196C908.598 347.477 908.616 347.749 908.642 348.013L916.077 342.447C915.943 341.985 915.772 341.563 915.566 341.182C915.36 340.801 915.114 340.474 914.827 340.202C914.54 339.921 914.213 339.703 913.845 339.549C913.478 339.395 913.066 339.318 912.609 339.318C911.999 339.318 911.443 339.467 910.941 339.767C910.449 340.066 910.027 340.515 909.678 341.114C909.328 341.703 909.059 342.438 908.871 343.318C908.683 344.189 908.589 345.2 908.589 346.352Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 385.278H907.406V383.073H911.923V370.092L907.715 372.405L906.854 370.391L912.447 367.411H914.518V383.073H918.443V385.278Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 406.35C918.995 407.711 918.86 408.953 918.591 410.078C918.322 411.194 917.915 412.156 917.368 412.963C916.821 413.761 916.14 414.383 915.324 414.827C914.518 415.263 913.568 415.48 912.474 415.48C911.533 415.48 910.677 415.303 909.906 414.95C909.135 414.587 908.477 414.033 907.93 413.29C907.383 412.546 906.962 411.598 906.666 410.446C906.37 409.294 906.223 407.928 906.223 406.35C906.223 404.989 906.357 403.746 906.626 402.621C906.904 401.497 907.312 400.535 907.849 399.737C908.396 398.929 909.077 398.308 909.893 397.872C910.708 397.428 911.659 397.206 912.743 397.206C913.684 397.206 914.54 397.387 915.311 397.75C916.082 398.104 916.74 398.657 917.287 399.41C917.834 400.154 918.255 401.102 918.551 402.254C918.847 403.406 918.995 404.771 918.995 406.35ZM916.628 406.432C916.628 406.123 916.615 405.819 916.588 405.52C916.57 405.211 916.548 404.912 916.521 404.622L909.086 410.214C909.221 410.686 909.391 411.121 909.597 411.521C909.803 411.92 910.05 412.265 910.336 412.555C910.632 412.836 910.964 413.058 911.331 413.222C911.708 413.376 912.134 413.453 912.609 413.453C913.218 413.453 913.769 413.303 914.262 413.004C914.764 412.704 915.19 412.26 915.539 411.67C915.889 411.081 916.158 410.35 916.346 409.48C916.534 408.6 916.628 407.584 916.628 406.432ZM908.589 406.268C908.589 406.549 908.589 406.831 908.589 407.112C908.598 407.393 908.616 407.665 908.642 407.928L916.077 402.363C915.943 401.9 915.772 401.478 915.566 401.097C915.36 400.716 915.114 400.39 914.827 400.118C914.54 399.836 914.213 399.619 913.845 399.465C913.478 399.31 913.066 399.233 912.609 399.233C911.999 399.233 911.443 399.383 910.941 399.682C910.449 399.982 910.027 400.431 909.678 401.029C909.328 401.619 909.059 402.354 908.871 403.234C908.683 404.105 908.589 405.116 908.589 406.268Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.995 436.308C918.995 437.668 918.86 438.911 918.591 440.036C918.322 441.152 917.915 442.114 917.368 442.921C916.821 443.719 916.14 444.341 915.324 444.785C914.518 445.221 913.568 445.438 912.474 445.438C911.533 445.438 910.677 445.261 909.906 444.908C909.135 444.545 908.477 443.991 907.93 443.247C907.383 442.504 906.962 441.556 906.666 440.404C906.37 439.251 906.223 437.886 906.223 436.308C906.223 434.947 906.357 433.704 906.626 432.579C906.904 431.454 907.312 430.493 907.849 429.695C908.396 428.887 909.077 428.266 909.893 427.83C910.708 427.386 911.659 427.164 912.743 427.164C913.684 427.164 914.54 427.345 915.311 427.708C916.082 428.062 916.74 428.615 917.287 429.368C917.834 430.112 918.255 431.06 918.551 432.212C918.847 433.364 918.995 434.729 918.995 436.308ZM916.628 436.389C916.628 436.081 916.615 435.777 916.588 435.478C916.57 435.169 916.548 434.87 916.521 434.58L909.086 440.172C909.221 440.644 909.391 441.079 909.597 441.479C909.803 441.878 910.05 442.222 910.336 442.513C910.632 442.794 910.964 443.016 911.331 443.179C911.708 443.334 912.134 443.411 912.609 443.411C913.218 443.411 913.769 443.261 914.262 442.962C914.764 442.662 915.19 442.218 915.539 441.628C915.889 441.039 916.158 440.308 916.346 439.437C916.534 438.557 916.628 437.541 916.628 436.389ZM908.589 436.226C908.589 436.507 908.589 436.789 908.589 437.07C908.598 437.351 908.616 437.623 908.642 437.886L916.077 432.321C915.943 431.858 915.772 431.436 915.566 431.055C915.36 430.674 915.114 430.348 914.827 430.076C914.54 429.794 914.213 429.577 913.845 429.422C913.478 429.268 913.066 429.191 912.609 429.191C911.999 429.191 911.443 429.341 910.941 429.64C910.449 429.94 910.027 430.389 909.678 430.987C909.328 431.577 909.059 432.312 908.871 433.192C908.683 434.062 908.589 435.074 908.589 436.226Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M918.443 475.151H907.406V472.947H911.923V459.965L907.715 462.279L906.854 460.265L912.447 457.285H914.518V472.947H918.443V475.151Z"
        fill="#6BB2EE"
        fillOpacity="0.4"
      />
      <path
        d="M989.38 49.4707H979.446V47.4867H983.511V35.8035L979.724 37.8854L978.95 36.0729L983.983 33.3909H985.847V47.4867H989.38V49.4707Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 75.9451H979.446V73.9611H983.511V62.2779L979.724 64.3598L978.95 62.5473L983.983 59.8653H985.847V73.9611H989.38V75.9451Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 94.4224C989.876 95.6471 989.755 96.7656 989.513 97.778C989.271 98.7822 988.904 99.6476 988.412 100.374C987.92 101.093 987.307 101.652 986.573 102.052C985.847 102.444 984.992 102.64 984.008 102.64C983.161 102.64 982.39 102.481 981.696 102.162C981.003 101.836 980.41 101.338 979.918 100.668C979.426 99.9987 979.047 99.1455 978.78 98.1087C978.514 97.0718 978.381 95.843 978.381 94.4224C978.381 93.1978 978.502 92.0793 978.744 91.0669C978.994 90.0545 979.361 89.1891 979.845 88.4706C980.337 87.744 980.95 87.1847 981.684 86.7928C982.418 86.3928 983.274 86.1927 984.25 86.1927C985.097 86.1927 985.867 86.356 986.561 86.6826C987.254 87.001 987.847 87.499 988.339 88.1767C988.831 88.8462 989.211 89.6993 989.477 90.7362C989.743 91.7731 989.876 93.0018 989.876 94.4224ZM987.747 94.4959C987.747 94.2183 987.734 93.9448 987.71 93.6754C987.694 93.3978 987.674 93.1284 987.65 92.8671L980.958 97.9005C981.079 98.325 981.233 98.7169 981.418 99.0761C981.604 99.4354 981.826 99.7456 982.084 100.007C982.35 100.26 982.648 100.46 982.979 100.607C983.318 100.746 983.701 100.815 984.129 100.815C984.677 100.815 985.173 100.68 985.617 100.411C986.069 100.142 986.452 99.7415 986.766 99.2109C987.081 98.6802 987.323 98.0229 987.492 97.2392C987.662 96.4472 987.747 95.5328 987.747 94.4959ZM980.511 94.349C980.511 94.6021 980.511 94.8551 980.511 95.1082C980.519 95.3613 980.535 95.6063 980.559 95.843L987.25 90.8342C987.129 90.4178 986.976 90.0382 986.791 89.6953C986.605 89.3523 986.383 89.0584 986.125 88.8135C985.867 88.5604 985.573 88.3645 985.242 88.2257C984.911 88.0869 984.54 88.0175 984.129 88.0175C983.58 88.0175 983.08 88.1522 982.628 88.4216C982.185 88.691 981.805 89.0952 981.491 89.634C981.176 90.1647 980.934 90.826 980.765 91.618C980.595 92.4017 980.511 93.3121 980.511 94.349Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 120.897C989.876 122.121 989.755 123.24 989.513 124.252C989.271 125.257 988.904 126.122 988.412 126.849C987.92 127.567 987.307 128.126 986.573 128.526C985.847 128.918 984.992 129.114 984.008 129.114C983.161 129.114 982.39 128.955 981.696 128.637C981.003 128.31 980.41 127.812 979.918 127.143C979.426 126.473 979.047 125.62 978.78 124.583C978.514 123.546 978.381 122.317 978.381 120.897C978.381 119.672 978.502 118.554 978.744 117.541C978.994 116.529 979.361 115.663 979.845 114.945C980.337 114.218 980.95 113.659 981.684 113.267C982.418 112.867 983.274 112.667 984.25 112.667C985.097 112.667 985.867 112.83 986.561 113.157C987.254 113.475 987.847 113.973 988.339 114.651C988.831 115.321 989.211 116.174 989.477 117.211C989.743 118.247 989.876 119.476 989.876 120.897ZM987.747 120.97C987.747 120.693 987.734 120.419 987.71 120.15C987.694 119.872 987.674 119.603 987.65 119.342L980.958 124.375C981.079 124.799 981.233 125.191 981.418 125.551C981.604 125.91 981.826 126.22 982.084 126.481C982.35 126.734 982.648 126.934 982.979 127.081C983.318 127.22 983.701 127.29 984.129 127.29C984.677 127.29 985.173 127.155 985.617 126.885C986.069 126.616 986.452 126.216 986.766 125.685C987.081 125.155 987.323 124.497 987.492 123.714C987.662 122.922 987.747 122.007 987.747 120.97ZM980.511 120.823C980.511 121.076 980.511 121.33 980.511 121.583C980.519 121.836 980.535 122.081 980.559 122.317L987.25 117.309C987.129 116.892 986.976 116.513 986.791 116.17C986.605 115.827 986.383 115.533 986.125 115.288C985.867 115.035 985.573 114.839 985.242 114.7C984.911 114.561 984.54 114.492 984.129 114.492C983.58 114.492 983.08 114.627 982.628 114.896C982.185 115.165 981.805 115.57 981.491 116.108C981.176 116.639 980.934 117.3 980.765 118.092C980.595 118.876 980.511 119.786 980.511 120.823Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 147.371C989.876 148.596 989.755 149.714 989.513 150.727C989.271 151.731 988.904 152.596 988.412 153.323C987.92 154.042 987.307 154.601 986.573 155.001C985.847 155.393 984.992 155.589 984.008 155.589C983.161 155.589 982.39 155.429 981.696 155.111C981.003 154.784 980.41 154.286 979.918 153.617C979.426 152.948 979.047 152.094 978.78 151.057C978.514 150.021 978.381 148.792 978.381 147.371C978.381 146.147 978.502 145.028 978.744 144.016C978.994 143.003 979.361 142.138 979.845 141.419C980.337 140.693 980.95 140.133 981.684 139.742C982.418 139.342 983.274 139.142 984.25 139.142C985.097 139.142 985.867 139.305 986.561 139.631C987.254 139.95 987.847 140.448 988.339 141.125C988.831 141.795 989.211 142.648 989.477 143.685C989.743 144.722 989.876 145.951 989.876 147.371ZM987.747 147.445C987.747 147.167 987.734 146.894 987.71 146.624C987.694 146.347 987.674 146.077 987.65 145.816L980.958 150.849C981.079 151.274 981.233 151.666 981.418 152.025C981.604 152.384 981.826 152.694 982.084 152.956C982.35 153.209 982.648 153.409 982.979 153.556C983.318 153.695 983.701 153.764 984.129 153.764C984.677 153.764 985.173 153.629 985.617 153.36C986.069 153.09 986.452 152.69 986.766 152.16C987.081 151.629 987.323 150.972 987.492 150.188C987.662 149.396 987.747 148.482 987.747 147.445ZM980.511 147.298C980.511 147.551 980.511 147.804 980.511 148.057C980.519 148.31 980.535 148.555 980.559 148.792L987.25 143.783C987.129 143.367 986.976 142.987 986.791 142.644C986.605 142.301 986.383 142.007 986.125 141.762C985.867 141.509 985.573 141.313 985.242 141.174C984.911 141.036 984.54 140.966 984.129 140.966C983.58 140.966 983.08 141.101 982.628 141.37C982.185 141.64 981.805 142.044 981.491 142.583C981.176 143.113 980.934 143.775 980.765 144.567C980.595 145.351 980.511 146.261 980.511 147.298Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 181.843H979.446V179.859H983.511V168.175L979.724 170.257L978.95 168.445L983.983 165.763H985.847V179.859H989.38V181.843Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 200.32C989.876 201.545 989.755 202.663 989.513 203.676C989.271 204.68 988.904 205.545 988.412 206.272C987.92 206.99 987.307 207.55 986.573 207.95C985.847 208.342 984.992 208.537 984.008 208.537C983.161 208.537 982.39 208.378 981.696 208.06C981.003 207.733 980.41 207.235 979.918 206.566C979.426 205.896 979.047 205.043 978.78 204.006C978.514 202.969 978.381 201.741 978.381 200.32C978.381 199.095 978.502 197.977 978.744 196.964C978.994 195.952 979.361 195.087 979.845 194.368C980.337 193.642 980.95 193.082 981.684 192.69C982.418 192.29 983.274 192.09 984.25 192.09C985.097 192.09 985.867 192.254 986.561 192.58C987.254 192.899 987.847 193.397 988.339 194.074C988.831 194.744 989.211 195.597 989.477 196.634C989.743 197.671 989.876 198.899 989.876 200.32ZM987.747 200.393C987.747 200.116 987.734 199.842 987.71 199.573C987.694 199.295 987.674 199.026 987.65 198.765L980.958 203.798C981.079 204.223 981.233 204.614 981.418 204.974C981.604 205.333 981.826 205.643 982.084 205.904C982.35 206.158 982.648 206.358 982.979 206.505C983.318 206.643 983.701 206.713 984.129 206.713C984.677 206.713 985.173 206.578 985.617 206.309C986.069 206.039 986.452 205.639 986.766 205.108C987.081 204.578 987.323 203.921 987.492 203.137C987.662 202.345 987.747 201.43 987.747 200.393ZM980.511 200.247C980.511 200.5 980.511 200.753 980.511 201.006C980.519 201.259 980.535 201.504 980.559 201.741L987.25 196.732C987.129 196.315 986.976 195.936 986.791 195.593C986.605 195.25 986.383 194.956 986.125 194.711C985.867 194.458 985.573 194.262 985.242 194.123C984.911 193.984 984.54 193.915 984.129 193.915C983.58 193.915 983.08 194.05 982.628 194.319C982.185 194.589 981.805 194.993 981.491 195.532C981.176 196.062 980.934 196.724 980.765 197.516C980.595 198.299 980.511 199.21 980.511 200.247Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 234.791H979.446V232.807H983.511V221.124L979.724 223.206L978.95 221.394L983.983 218.712H985.847V232.807H989.38V234.791Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 261.266H979.446V259.282H983.511V247.599L979.724 249.681L978.95 247.868L983.983 245.186H985.847V259.282H989.38V261.266Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 279.743C989.876 280.968 989.755 282.086 989.513 283.099C989.271 284.103 988.904 284.968 988.412 285.695C987.92 286.414 987.307 286.973 986.573 287.373C985.847 287.765 984.992 287.961 984.008 287.961C983.161 287.961 982.39 287.801 981.696 287.483C981.003 287.156 980.41 286.658 979.918 285.989C979.426 285.319 979.047 284.466 978.78 283.429C978.514 282.393 978.381 281.164 978.381 279.743C978.381 278.519 978.502 277.4 978.744 276.388C978.994 275.375 979.361 274.51 979.845 273.791C980.337 273.065 980.95 272.505 981.684 272.114C982.418 271.714 983.274 271.514 984.25 271.514C985.097 271.514 985.867 271.677 986.561 272.003C987.254 272.322 987.847 272.82 988.339 273.497C988.831 274.167 989.211 275.02 989.477 276.057C989.743 277.094 989.876 278.323 989.876 279.743ZM987.747 279.817C987.747 279.539 987.734 279.266 987.71 278.996C987.694 278.719 987.674 278.449 987.65 278.188L980.958 283.221C981.079 283.646 981.233 284.038 981.418 284.397C981.604 284.756 981.826 285.066 982.084 285.328C982.35 285.581 982.648 285.781 982.979 285.928C983.318 286.067 983.701 286.136 984.129 286.136C984.677 286.136 985.173 286.001 985.617 285.732C986.069 285.462 986.452 285.062 986.766 284.532C987.081 284.001 987.323 283.344 987.492 282.56C987.662 281.768 987.747 280.854 987.747 279.817ZM980.511 279.67C980.511 279.923 980.511 280.176 980.511 280.429C980.519 280.682 980.535 280.927 980.559 281.164L987.25 276.155C987.129 275.739 986.976 275.359 986.791 275.016C986.605 274.673 986.383 274.379 986.125 274.134C985.867 273.881 985.573 273.685 985.242 273.546C984.911 273.408 984.54 273.338 984.129 273.338C983.58 273.338 983.08 273.473 982.628 273.742C982.185 274.012 981.805 274.416 981.491 274.955C981.176 275.485 980.934 276.147 980.765 276.939C980.595 277.723 980.511 278.633 980.511 279.67Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 306.218C989.876 307.442 989.755 308.561 989.513 309.573C989.271 310.577 988.904 311.443 988.412 312.169C987.92 312.888 987.307 313.447 986.573 313.847C985.847 314.239 984.992 314.435 984.008 314.435C983.161 314.435 982.39 314.276 981.696 313.957C981.003 313.631 980.41 313.133 979.918 312.463C979.426 311.794 979.047 310.941 978.78 309.904C978.514 308.867 978.381 307.638 978.381 306.218C978.381 304.993 978.502 303.874 978.744 302.862C978.994 301.85 979.361 300.984 979.845 300.266C980.337 299.539 980.95 298.98 981.684 298.588C982.418 298.188 983.274 297.988 984.25 297.988C985.097 297.988 985.867 298.151 986.561 298.478C987.254 298.796 987.847 299.294 988.339 299.972C988.831 300.641 989.211 301.495 989.477 302.531C989.743 303.568 989.876 304.797 989.876 306.218ZM987.747 306.291C987.747 306.013 987.734 305.74 987.71 305.471C987.694 305.193 987.674 304.924 987.65 304.662L980.958 309.696C981.079 310.12 981.233 310.512 981.418 310.871C981.604 311.231 981.826 311.541 982.084 311.802C982.35 312.055 982.648 312.255 982.979 312.402C983.318 312.541 983.701 312.61 984.129 312.61C984.677 312.61 985.173 312.476 985.617 312.206C986.069 311.937 986.452 311.537 986.766 311.006C987.081 310.475 987.323 309.818 987.492 309.034C987.662 308.242 987.747 307.328 987.747 306.291ZM980.511 306.144C980.511 306.397 980.511 306.65 980.511 306.903C980.519 307.156 980.535 307.401 980.559 307.638L987.25 302.629C987.129 302.213 986.976 301.833 986.791 301.49C986.605 301.148 986.383 300.854 986.125 300.609C985.867 300.356 985.573 300.16 985.242 300.021C984.911 299.882 984.54 299.813 984.129 299.813C983.58 299.813 983.08 299.947 982.628 300.217C982.185 300.486 981.805 300.89 981.491 301.429C981.176 301.96 980.934 302.621 980.765 303.413C980.595 304.197 980.511 305.107 980.511 306.144Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 332.692C989.876 333.917 989.755 335.035 989.513 336.048C989.271 337.052 988.904 337.917 988.412 338.644C987.92 339.362 987.307 339.922 986.573 340.322C985.847 340.714 984.992 340.909 984.008 340.909C983.161 340.909 982.39 340.75 981.696 340.432C981.003 340.105 980.41 339.607 979.918 338.938C979.426 338.268 979.047 337.415 978.78 336.378C978.514 335.341 978.381 334.113 978.381 332.692C978.381 331.467 978.502 330.349 978.744 329.336C978.994 328.324 979.361 327.459 979.845 326.74C980.337 326.014 980.95 325.454 981.684 325.062C982.418 324.662 983.274 324.462 984.25 324.462C985.097 324.462 985.867 324.626 986.561 324.952C987.254 325.271 987.847 325.769 988.339 326.446C988.831 327.116 989.211 327.969 989.477 329.006C989.743 330.043 989.876 331.271 989.876 332.692ZM987.747 332.765C987.747 332.488 987.734 332.214 987.71 331.945C987.694 331.667 987.674 331.398 987.65 331.137L980.958 336.17C981.079 336.595 981.233 336.986 981.418 337.346C981.604 337.705 981.826 338.015 982.084 338.276C982.35 338.53 982.648 338.73 982.979 338.877C983.318 339.015 983.701 339.085 984.129 339.085C984.677 339.085 985.173 338.95 985.617 338.681C986.069 338.411 986.452 338.011 986.766 337.48C987.081 336.95 987.323 336.292 987.492 335.509C987.662 334.717 987.747 333.802 987.747 332.765ZM980.511 332.618C980.511 332.872 980.511 333.125 980.511 333.378C980.519 333.631 980.535 333.876 980.559 334.113L987.25 329.104C987.129 328.687 986.976 328.308 986.791 327.965C986.605 327.622 986.383 327.328 986.125 327.083C985.867 326.83 985.573 326.634 985.242 326.495C984.911 326.356 984.54 326.287 984.129 326.287C983.58 326.287 983.08 326.422 982.628 326.691C982.185 326.961 981.805 327.365 981.491 327.904C981.176 328.434 980.934 329.096 980.765 329.888C980.595 330.671 980.511 331.582 980.511 332.618Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 367.163H979.446V365.179H983.511V353.496L979.724 355.578L978.95 353.766L983.983 351.084H985.847V365.179H989.38V367.163Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 393.638H979.446V391.654H983.511V379.971L979.724 382.053L978.95 380.24L983.983 377.558H985.847V391.654H989.38V393.638Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 420.112H979.446V418.128H983.511V406.445L979.724 408.527L978.95 406.714L983.983 404.032H985.847V418.128H989.38V420.112Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 438.59C989.876 439.814 989.755 440.933 989.513 441.945C989.271 442.949 988.904 443.815 988.412 444.541C987.92 445.26 987.307 445.819 986.573 446.219C985.847 446.611 984.992 446.807 984.008 446.807C983.161 446.807 982.39 446.648 981.696 446.329C981.003 446.003 980.41 445.505 979.918 444.835C979.426 444.166 979.047 443.313 978.78 442.276C978.514 441.239 978.381 440.01 978.381 438.59C978.381 437.365 978.502 436.246 978.744 435.234C978.994 434.222 979.361 433.356 979.845 432.638C980.337 431.911 980.95 431.352 981.684 430.96C982.418 430.56 983.274 430.36 984.25 430.36C985.097 430.36 985.867 430.523 986.561 430.85C987.254 431.168 987.847 431.666 988.339 432.344C988.831 433.013 989.211 433.866 989.477 434.903C989.743 435.94 989.876 437.169 989.876 438.59ZM987.747 438.663C987.747 438.385 987.734 438.112 987.71 437.843C987.694 437.565 987.674 437.296 987.65 437.034L980.958 442.068C981.079 442.492 981.233 442.884 981.418 443.243C981.604 443.603 981.826 443.913 982.084 444.174C982.35 444.427 982.648 444.627 982.979 444.774C983.318 444.913 983.701 444.982 984.129 444.982C984.677 444.982 985.173 444.848 985.617 444.578C986.069 444.309 986.452 443.909 986.766 443.378C987.081 442.847 987.323 442.19 987.492 441.406C987.662 440.614 987.747 439.7 987.747 438.663ZM980.511 438.516C980.511 438.769 980.511 439.022 980.511 439.275C980.519 439.528 980.535 439.773 980.559 440.01L987.25 435.001C987.129 434.585 986.976 434.205 986.791 433.862C986.605 433.52 986.383 433.226 986.125 432.981C985.867 432.728 985.573 432.532 985.242 432.393C984.911 432.254 984.54 432.185 984.129 432.185C983.58 432.185 983.08 432.319 982.628 432.589C982.185 432.858 981.805 433.262 981.491 433.801C981.176 434.332 980.934 434.993 980.765 435.785C980.595 436.569 980.511 437.479 980.511 438.516Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 473.061H979.446V471.077H983.511V459.394L979.724 461.476L978.95 459.663L983.983 456.981H985.847V471.077H989.38V473.061Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 491.538C989.876 492.763 989.755 493.882 989.513 494.894C989.271 495.898 988.904 496.764 988.412 497.49C987.92 498.209 987.307 498.768 986.573 499.168C985.847 499.56 984.992 499.756 984.008 499.756C983.161 499.756 982.39 499.597 981.696 499.278C981.003 498.952 980.41 498.454 979.918 497.784C979.426 497.115 979.047 496.261 978.78 495.225C978.514 494.188 978.381 492.959 978.381 491.538C978.381 490.314 978.502 489.195 978.744 488.183C978.994 487.17 979.361 486.305 979.845 485.587C980.337 484.86 980.95 484.301 981.684 483.909C982.418 483.509 983.274 483.309 984.25 483.309C985.097 483.309 985.867 483.472 986.561 483.799C987.254 484.117 987.847 484.615 988.339 485.293C988.831 485.962 989.211 486.815 989.477 487.852C989.743 488.889 989.876 490.118 989.876 491.538ZM987.747 491.612C987.747 491.334 987.734 491.061 987.71 490.791C987.694 490.514 987.674 490.244 987.65 489.983L980.958 495.016C981.079 495.441 981.233 495.833 981.418 496.192C981.604 496.551 981.826 496.862 982.084 497.123C982.35 497.376 982.648 497.576 982.979 497.723C983.318 497.862 983.701 497.931 984.129 497.931C984.677 497.931 985.173 497.796 985.617 497.527C986.069 497.258 986.452 496.857 986.766 496.327C987.081 495.796 987.323 495.139 987.492 494.355C987.662 493.563 987.747 492.649 987.747 491.612ZM980.511 491.465C980.511 491.718 980.511 491.971 980.511 492.224C980.519 492.477 980.535 492.722 980.559 492.959L987.25 487.95C987.129 487.534 986.976 487.154 986.791 486.811C986.605 486.468 986.383 486.174 986.125 485.929C985.867 485.676 985.573 485.48 985.242 485.342C984.911 485.203 984.54 485.133 984.129 485.133C983.58 485.133 983.08 485.268 982.628 485.538C982.185 485.807 981.805 486.211 981.491 486.75C981.176 487.281 980.934 487.942 980.765 488.734C980.595 489.518 980.511 490.428 980.511 491.465Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.876 518.013C989.876 519.237 989.755 520.356 989.513 521.368C989.271 522.373 988.904 523.238 988.412 523.965C987.92 524.683 987.307 525.242 986.573 525.642C985.847 526.034 984.992 526.23 984.008 526.23C983.161 526.23 982.39 526.071 981.696 525.753C981.003 525.426 980.41 524.928 979.918 524.259C979.426 523.589 979.047 522.736 978.78 521.699C978.514 520.662 978.381 519.433 978.381 518.013C978.381 516.788 978.502 515.67 978.744 514.657C978.994 513.645 979.361 512.779 979.845 512.061C980.337 511.334 980.95 510.775 981.684 510.383C982.418 509.983 983.274 509.783 984.25 509.783C985.097 509.783 985.867 509.946 986.561 510.273C987.254 510.591 987.847 511.089 988.339 511.767C988.831 512.436 989.211 513.29 989.477 514.327C989.743 515.363 989.876 516.592 989.876 518.013ZM987.747 518.086C987.747 517.809 987.734 517.535 987.71 517.266C987.694 516.988 987.674 516.719 987.65 516.457L980.958 521.491C981.079 521.915 981.233 522.307 981.418 522.666C981.604 523.026 981.826 523.336 982.084 523.597C982.35 523.85 982.648 524.05 982.979 524.197C983.318 524.336 983.701 524.405 984.129 524.405C984.677 524.405 985.173 524.271 985.617 524.001C986.069 523.732 986.452 523.332 986.766 522.801C987.081 522.27 987.323 521.613 987.492 520.829C987.662 520.038 987.747 519.123 987.747 518.086ZM980.511 517.939C980.511 518.192 980.511 518.445 980.511 518.699C980.519 518.952 980.535 519.197 980.559 519.433L987.25 514.424C987.129 514.008 986.976 513.628 986.791 513.286C986.605 512.943 986.383 512.649 986.125 512.404C985.867 512.151 985.573 511.955 985.242 511.816C984.911 511.677 984.54 511.608 984.129 511.608C983.58 511.608 983.08 511.743 982.628 512.012C982.185 512.281 981.805 512.685 981.491 513.224C981.176 513.755 980.934 514.416 980.765 515.208C980.595 515.992 980.511 516.902 980.511 517.939Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M989.38 552.484H979.446V550.5H983.511V538.817L979.724 540.899L978.95 539.086L983.983 536.404H985.847V550.5H989.38V552.484Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 277.986H800.474V276.003H804.54V264.319L800.753 266.401L799.978 264.589L805.012 261.907H806.875V276.003H810.408V277.986Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 304.461H800.474V302.477H804.54V290.794L800.753 292.876L799.978 291.063L805.012 288.381H806.875V302.477H810.408V304.461Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 322.938C810.905 324.163 810.784 325.281 810.542 326.294C810.3 327.298 809.932 328.163 809.44 328.89C808.948 329.609 808.335 330.168 807.601 330.568C806.875 330.96 806.02 331.156 805.036 331.156C804.189 331.156 803.419 330.996 802.725 330.678C802.031 330.352 801.438 329.853 800.946 329.184C800.454 328.515 800.075 327.661 799.809 326.624C799.543 325.588 799.41 324.359 799.41 322.938C799.41 321.714 799.531 320.595 799.773 319.583C800.023 318.57 800.39 317.705 800.874 316.986C801.366 316.26 801.979 315.701 802.713 315.309C803.447 314.909 804.302 314.709 805.278 314.709C806.125 314.709 806.895 314.872 807.589 315.198C808.283 315.517 808.876 316.015 809.368 316.692C809.86 317.362 810.239 318.215 810.505 319.252C810.771 320.289 810.905 321.518 810.905 322.938ZM808.775 323.012C808.775 322.734 808.763 322.461 808.739 322.191C808.723 321.914 808.702 321.644 808.678 321.383L801.987 326.416C802.108 326.841 802.261 327.233 802.447 327.592C802.632 327.951 802.854 328.261 803.112 328.523C803.378 328.776 803.677 328.976 804.008 329.123C804.346 329.262 804.73 329.331 805.157 329.331C805.706 329.331 806.202 329.196 806.645 328.927C807.097 328.657 807.48 328.257 807.795 327.727C808.109 327.196 808.351 326.539 808.521 325.755C808.69 324.963 808.775 324.049 808.775 323.012ZM801.539 322.865C801.539 323.118 801.539 323.371 801.539 323.624C801.547 323.877 801.563 324.122 801.588 324.359L808.279 319.35C808.158 318.934 808.005 318.554 807.819 318.211C807.634 317.868 807.412 317.574 807.154 317.329C806.895 317.076 806.601 316.88 806.27 316.741C805.94 316.603 805.568 316.533 805.157 316.533C804.609 316.533 804.108 316.668 803.657 316.937C803.213 317.207 802.834 317.611 802.519 318.15C802.205 318.681 801.963 319.342 801.793 320.134C801.624 320.918 801.539 321.828 801.539 322.865Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 349.413C810.905 350.637 810.784 351.756 810.542 352.768C810.3 353.772 809.932 354.638 809.44 355.364C808.948 356.083 808.335 356.642 807.601 357.042C806.875 357.434 806.02 357.63 805.036 357.63C804.189 357.63 803.419 357.471 802.725 357.152C802.031 356.826 801.438 356.328 800.946 355.658C800.454 354.989 800.075 354.136 799.809 353.099C799.543 352.062 799.41 350.833 799.41 349.413C799.41 348.188 799.531 347.069 799.773 346.057C800.023 345.045 800.39 344.179 800.874 343.461C801.366 342.734 801.979 342.175 802.713 341.783C803.447 341.383 804.302 341.183 805.278 341.183C806.125 341.183 806.895 341.346 807.589 341.673C808.283 341.991 808.876 342.489 809.368 343.167C809.86 343.836 810.239 344.69 810.505 345.726C810.771 346.763 810.905 347.992 810.905 349.413ZM808.775 349.486C808.775 349.209 808.763 348.935 808.739 348.666C808.723 348.388 808.702 348.119 808.678 347.857L801.987 352.891C802.108 353.315 802.261 353.707 802.447 354.066C802.632 354.426 802.854 354.736 803.112 354.997C803.378 355.25 803.677 355.45 804.008 355.597C804.346 355.736 804.73 355.805 805.157 355.805C805.706 355.805 806.202 355.671 806.645 355.401C807.097 355.132 807.48 354.732 807.795 354.201C808.109 353.67 808.351 353.013 808.521 352.229C808.69 351.437 808.775 350.523 808.775 349.486ZM801.539 349.339C801.539 349.592 801.539 349.845 801.539 350.098C801.547 350.352 801.563 350.596 801.588 350.833L808.279 345.824C808.158 345.408 808.005 345.028 807.819 344.685C807.634 344.343 807.412 344.049 807.154 343.804C806.895 343.551 806.601 343.355 806.27 343.216C805.94 343.077 805.568 343.008 805.157 343.008C804.609 343.008 804.108 343.142 803.657 343.412C803.213 343.681 802.834 344.085 802.519 344.624C802.205 345.155 801.963 345.816 801.793 346.608C801.624 347.392 801.539 348.302 801.539 349.339Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 375.887C810.905 377.112 810.784 378.23 810.542 379.243C810.3 380.247 809.932 381.112 809.44 381.839C808.948 382.557 808.335 383.117 807.601 383.517C806.875 383.909 806.02 384.104 805.036 384.104C804.189 384.104 803.419 383.945 802.725 383.627C802.031 383.3 801.438 382.802 800.946 382.133C800.454 381.463 800.075 380.61 799.809 379.573C799.543 378.536 799.41 377.308 799.41 375.887C799.41 374.662 799.531 373.544 799.773 372.531C800.023 371.519 800.39 370.654 800.874 369.935C801.366 369.209 801.979 368.649 802.713 368.257C803.447 367.857 804.302 367.657 805.278 367.657C806.125 367.657 806.895 367.821 807.589 368.147C808.283 368.466 808.876 368.964 809.368 369.641C809.86 370.311 810.239 371.164 810.505 372.201C810.771 373.238 810.905 374.466 810.905 375.887ZM808.775 375.961C808.775 375.683 808.763 375.409 808.739 375.14C808.723 374.862 808.702 374.593 808.678 374.332L801.987 379.365C802.108 379.79 802.261 380.182 802.447 380.541C802.632 380.9 802.854 381.21 803.112 381.471C803.378 381.725 803.677 381.925 804.008 382.072C804.346 382.21 804.73 382.28 805.157 382.28C805.706 382.28 806.202 382.145 806.645 381.876C807.097 381.606 807.48 381.206 807.795 380.675C808.109 380.145 808.351 379.488 808.521 378.704C808.69 377.912 808.775 376.997 808.775 375.961ZM801.539 375.814C801.539 376.067 801.539 376.32 801.539 376.573C801.547 376.826 801.563 377.071 801.588 377.308L808.279 372.299C808.158 371.882 808.005 371.503 807.819 371.16C807.634 370.817 807.412 370.523 807.154 370.278C806.895 370.025 806.601 369.829 806.27 369.69C805.94 369.551 805.568 369.482 805.157 369.482C804.609 369.482 804.108 369.617 803.657 369.886C803.213 370.156 802.834 370.56 802.519 371.099C802.205 371.629 801.963 372.291 801.793 373.083C801.624 373.866 801.539 374.777 801.539 375.814Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 410.358H800.474V408.374H804.54V396.691L800.753 398.773L799.978 396.961L805.012 394.279H806.875V408.374H810.408V410.358Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 428.836C810.905 430.06 810.784 431.179 810.542 432.191C810.3 433.196 809.932 434.061 809.44 434.788C808.948 435.506 808.335 436.065 807.601 436.465C806.875 436.857 806.02 437.053 805.036 437.053C804.189 437.053 803.419 436.894 802.725 436.576C802.031 436.249 801.438 435.751 800.946 435.082C800.454 434.412 800.075 433.559 799.809 432.522C799.543 431.485 799.41 430.256 799.41 428.836C799.41 427.611 799.531 426.493 799.773 425.48C800.023 424.468 800.39 423.602 800.874 422.884C801.366 422.157 801.979 421.598 802.713 421.206C803.447 420.806 804.302 420.606 805.278 420.606C806.125 420.606 806.895 420.769 807.589 421.096C808.283 421.414 808.876 421.912 809.368 422.59C809.86 423.26 810.239 424.113 810.505 425.15C810.771 426.186 810.905 427.415 810.905 428.836ZM808.775 428.909C808.775 428.632 808.763 428.358 808.739 428.089C808.723 427.811 808.702 427.542 808.678 427.281L801.987 432.314C802.108 432.738 802.261 433.13 802.447 433.49C802.632 433.849 802.854 434.159 803.112 434.42C803.378 434.673 803.677 434.873 804.008 435.02C804.346 435.159 804.73 435.229 805.157 435.229C805.706 435.229 806.202 435.094 806.645 434.824C807.097 434.555 807.48 434.155 807.795 433.624C808.109 433.094 808.351 432.436 808.521 431.653C808.69 430.861 808.775 429.946 808.775 428.909ZM801.539 428.762C801.539 429.015 801.539 429.269 801.539 429.522C801.547 429.775 801.563 430.02 801.588 430.256L808.279 425.248C808.158 424.831 808.005 424.452 807.819 424.109C807.634 423.766 807.412 423.472 807.154 423.227C806.895 422.974 806.601 422.778 806.27 422.639C805.94 422.5 805.568 422.431 805.157 422.431C804.609 422.431 804.108 422.566 803.657 422.835C803.213 423.104 802.834 423.509 802.519 424.047C802.205 424.578 801.963 425.239 801.793 426.031C801.624 426.815 801.539 427.725 801.539 428.762Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 463.307H800.474V461.323H804.54V449.64L800.753 451.722L799.978 449.909L805.012 447.227H806.875V461.323H810.408V463.307Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 489.782H800.474V487.798H804.54V476.114L800.753 478.196L799.978 476.384L805.012 473.702H806.875V487.798H810.408V489.782Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 508.259C810.905 509.484 810.784 510.602 810.542 511.615C810.3 512.619 809.932 513.484 809.44 514.211C808.948 514.929 808.335 515.489 807.601 515.889C806.875 516.281 806.02 516.476 805.036 516.476C804.189 516.476 803.419 516.317 802.725 515.999C802.031 515.672 801.438 515.174 800.946 514.505C800.454 513.835 800.075 512.982 799.809 511.945C799.543 510.908 799.41 509.68 799.41 508.259C799.41 507.034 799.531 505.916 799.773 504.903C800.023 503.891 800.39 503.026 800.874 502.307C801.366 501.581 801.979 501.021 802.713 500.629C803.447 500.229 804.302 500.029 805.278 500.029C806.125 500.029 806.895 500.193 807.589 500.519C808.283 500.838 808.876 501.336 809.368 502.013C809.86 502.683 810.239 503.536 810.505 504.573C810.771 505.61 810.905 506.838 810.905 508.259ZM808.775 508.332C808.775 508.055 808.763 507.781 808.739 507.512C808.723 507.234 808.702 506.965 808.678 506.704L801.987 511.737C802.108 512.162 802.261 512.553 802.447 512.913C802.632 513.272 802.854 513.582 803.112 513.843C803.378 514.097 803.677 514.297 804.008 514.444C804.346 514.582 804.73 514.652 805.157 514.652C805.706 514.652 806.202 514.517 806.645 514.248C807.097 513.978 807.48 513.578 807.795 513.047C808.109 512.517 808.351 511.86 808.521 511.076C808.69 510.284 808.775 509.369 808.775 508.332ZM801.539 508.186C801.539 508.439 801.539 508.692 801.539 508.945C801.547 509.198 801.563 509.443 801.588 509.68L808.279 504.671C808.158 504.254 808.005 503.875 807.819 503.532C807.634 503.189 807.412 502.895 807.154 502.65C806.895 502.397 806.601 502.201 806.27 502.062C805.94 501.923 805.568 501.854 805.157 501.854C804.609 501.854 804.108 501.989 803.657 502.258C803.213 502.528 802.834 502.932 802.519 503.471C802.205 504.001 801.963 504.663 801.793 505.455C801.624 506.238 801.539 507.149 801.539 508.186Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 534.733C810.905 535.958 810.784 537.077 810.542 538.089C810.3 539.093 809.932 539.959 809.44 540.685C808.948 541.404 808.335 541.963 807.601 542.363C806.875 542.755 806.02 542.951 805.036 542.951C804.189 542.951 803.419 542.792 802.725 542.473C802.031 542.147 801.438 541.649 800.946 540.979C800.454 540.31 800.075 539.457 799.809 538.42C799.543 537.383 799.41 536.154 799.41 534.733C799.41 533.509 799.531 532.39 799.773 531.378C800.023 530.365 800.39 529.5 800.874 528.782C801.366 528.055 801.979 527.496 802.713 527.104C803.447 526.704 804.302 526.504 805.278 526.504C806.125 526.504 806.895 526.667 807.589 526.994C808.283 527.312 808.876 527.81 809.368 528.488C809.86 529.157 810.239 530.01 810.505 531.047C810.771 532.084 810.905 533.313 810.905 534.733ZM808.775 534.807C808.775 534.529 808.763 534.256 808.739 533.986C808.723 533.709 808.702 533.439 808.678 533.178L801.987 538.211C802.108 538.636 802.261 539.028 802.447 539.387C802.632 539.746 802.854 540.057 803.112 540.318C803.378 540.571 803.677 540.771 804.008 540.918C804.346 541.057 804.73 541.126 805.157 541.126C805.706 541.126 806.202 540.991 806.645 540.722C807.097 540.453 807.48 540.053 807.795 539.522C808.109 538.991 808.351 538.334 808.521 537.55C808.69 536.758 808.775 535.844 808.775 534.807ZM801.539 534.66C801.539 534.913 801.539 535.166 801.539 535.419C801.547 535.672 801.563 535.917 801.588 536.154L808.279 531.145C808.158 530.729 808.005 530.349 807.819 530.006C807.634 529.663 807.412 529.369 807.154 529.124C806.895 528.871 806.601 528.675 806.27 528.537C805.94 528.398 805.568 528.328 805.157 528.328C804.609 528.328 804.108 528.463 803.657 528.733C803.213 529.002 802.834 529.406 802.519 529.945C802.205 530.476 801.963 531.137 801.793 531.929C801.624 532.713 801.539 533.623 801.539 534.66Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 561.208C810.905 562.432 810.784 563.551 810.542 564.563C810.3 565.568 809.932 566.433 809.44 567.16C808.948 567.878 808.335 568.437 807.601 568.837C806.875 569.229 806.02 569.425 805.036 569.425C804.189 569.425 803.419 569.266 802.725 568.948C802.031 568.621 801.438 568.123 800.946 567.454C800.454 566.784 800.075 565.931 799.809 564.894C799.543 563.857 799.41 562.628 799.41 561.208C799.41 559.983 799.531 558.865 799.773 557.852C800.023 556.84 800.39 555.974 800.874 555.256C801.366 554.529 801.979 553.97 802.713 553.578C803.447 553.178 804.302 552.978 805.278 552.978C806.125 552.978 806.895 553.141 807.589 553.468C808.283 553.786 808.876 554.284 809.368 554.962C809.86 555.632 810.239 556.485 810.505 557.522C810.771 558.558 810.905 559.787 810.905 561.208ZM808.775 561.281C808.775 561.004 808.763 560.73 808.739 560.461C808.723 560.183 808.702 559.914 808.678 559.652L801.987 564.686C802.108 565.11 802.261 565.502 802.447 565.862C802.632 566.221 802.854 566.531 803.112 566.792C803.378 567.045 803.677 567.245 804.008 567.392C804.346 567.531 804.73 567.601 805.157 567.601C805.706 567.601 806.202 567.466 806.645 567.196C807.097 566.927 807.48 566.527 807.795 565.996C808.109 565.466 808.351 564.808 808.521 564.025C808.69 563.233 808.775 562.318 808.775 561.281ZM801.539 561.134C801.539 561.387 801.539 561.641 801.539 561.894C801.547 562.147 801.563 562.392 801.588 562.628L808.279 557.62C808.158 557.203 808.005 556.824 807.819 556.481C807.634 556.138 807.412 555.844 807.154 555.599C806.895 555.346 806.601 555.15 806.27 555.011C805.94 554.872 805.568 554.803 805.157 554.803C804.609 554.803 804.108 554.938 803.657 555.207C803.213 555.476 802.834 555.881 802.519 556.419C802.205 556.95 801.963 557.611 801.793 558.403C801.624 559.187 801.539 560.097 801.539 561.134Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 595.679H800.474V593.695H804.54V582.012L800.753 584.094L799.978 582.281L805.012 579.599H806.875V593.695H810.408V595.679Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 622.154H800.474V620.17H804.54V608.486L800.753 610.568L799.978 608.756L805.012 606.074H806.875V620.17H810.408V622.154Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 648.628H800.474V646.644H804.54V634.961L800.753 637.043L799.978 635.23L805.012 632.548H806.875V646.644H810.408V648.628Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 667.105C810.905 668.33 810.784 669.449 810.542 670.461C810.3 671.465 809.932 672.331 809.44 673.057C808.948 673.776 808.335 674.335 807.601 674.735C806.875 675.127 806.02 675.323 805.036 675.323C804.189 675.323 803.419 675.164 802.725 674.845C802.031 674.519 801.438 674.021 800.946 673.351C800.454 672.682 800.075 671.828 799.809 670.792C799.543 669.755 799.41 668.526 799.41 667.105C799.41 665.881 799.531 664.762 799.773 663.75C800.023 662.737 800.39 661.872 800.874 661.154C801.366 660.427 801.979 659.868 802.713 659.476C803.447 659.076 804.302 658.876 805.278 658.876C806.125 658.876 806.895 659.039 807.589 659.366C808.283 659.684 808.876 660.182 809.368 660.86C809.86 661.529 810.239 662.382 810.505 663.419C810.771 664.456 810.905 665.685 810.905 667.105ZM808.775 667.179C808.775 666.901 808.763 666.628 808.739 666.358C808.723 666.081 808.702 665.811 808.678 665.55L801.987 670.583C802.108 671.008 802.261 671.4 802.447 671.759C802.632 672.118 802.854 672.429 803.112 672.69C803.378 672.943 803.677 673.143 804.008 673.29C804.346 673.429 804.73 673.498 805.157 673.498C805.706 673.498 806.202 673.363 806.645 673.094C807.097 672.825 807.48 672.424 807.795 671.894C808.109 671.363 808.351 670.706 808.521 669.922C808.69 669.13 808.775 668.216 808.775 667.179ZM801.539 667.032C801.539 667.285 801.539 667.538 801.539 667.791C801.547 668.044 801.563 668.289 801.588 668.526L808.279 663.517C808.158 663.101 808.005 662.721 807.819 662.378C807.634 662.035 807.412 661.741 807.154 661.496C806.895 661.243 806.601 661.047 806.27 660.909C805.94 660.77 805.568 660.7 805.157 660.7C804.609 660.7 804.108 660.835 803.657 661.105C803.213 661.374 802.834 661.778 802.519 662.317C802.205 662.848 801.963 663.509 801.793 664.301C801.624 665.085 801.539 665.995 801.539 667.032Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 701.577H800.474V699.593H804.54V687.91L800.753 689.992L799.978 688.179L805.012 685.497H806.875V699.593H810.408V701.577Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 720.054C810.905 721.279 810.784 722.397 810.542 723.41C810.3 724.414 809.932 725.279 809.44 726.006C808.948 726.724 808.335 727.284 807.601 727.684C806.875 728.076 806.02 728.272 805.036 728.272C804.189 728.272 803.419 728.112 802.725 727.794C802.031 727.467 801.438 726.969 800.946 726.3C800.454 725.63 800.075 724.777 799.809 723.74C799.543 722.704 799.41 721.475 799.41 720.054C799.41 718.83 799.531 717.711 799.773 716.699C800.023 715.686 800.39 714.821 800.874 714.102C801.366 713.376 801.979 712.816 802.713 712.425C803.447 712.025 804.302 711.824 805.278 711.824C806.125 711.824 806.895 711.988 807.589 712.314C808.283 712.633 808.876 713.131 809.368 713.808C809.86 714.478 810.239 715.331 810.505 716.368C810.771 717.405 810.905 718.634 810.905 720.054ZM808.775 720.128C808.775 719.85 808.763 719.577 808.739 719.307C808.723 719.03 808.702 718.76 808.678 718.499L801.987 723.532C802.108 723.957 802.261 724.349 802.447 724.708C802.632 725.067 802.854 725.377 803.112 725.639C803.378 725.892 803.677 726.092 804.008 726.239C804.346 726.378 804.73 726.447 805.157 726.447C805.706 726.447 806.202 726.312 806.645 726.043C807.097 725.773 807.48 725.373 807.795 724.843C808.109 724.312 808.351 723.655 808.521 722.871C808.69 722.079 808.775 721.165 808.775 720.128ZM801.539 719.981C801.539 720.234 801.539 720.487 801.539 720.74C801.547 720.993 801.563 721.238 801.588 721.475L808.279 716.466C808.158 716.05 808.005 715.67 807.819 715.327C807.634 714.984 807.412 714.69 807.154 714.445C806.895 714.192 806.601 713.996 806.27 713.857C805.94 713.719 805.568 713.649 805.157 713.649C804.609 713.649 804.108 713.784 803.657 714.053C803.213 714.323 802.834 714.727 802.519 715.266C802.205 715.796 801.963 716.458 801.793 717.25C801.624 718.033 801.539 718.944 801.539 719.981Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.905 746.529C810.905 747.753 810.784 748.872 810.542 749.884C810.3 750.888 809.932 751.754 809.44 752.48C808.948 753.199 808.335 753.758 807.601 754.158C806.875 754.55 806.02 754.746 805.036 754.746C804.189 754.746 803.419 754.587 802.725 754.268C802.031 753.942 801.438 753.444 800.946 752.774C800.454 752.105 800.075 751.252 799.809 750.215C799.543 749.178 799.41 747.949 799.41 746.529C799.41 745.304 799.531 744.185 799.773 743.173C800.023 742.161 800.39 741.295 800.874 740.577C801.366 739.85 801.979 739.291 802.713 738.899C803.447 738.499 804.302 738.299 805.278 738.299C806.125 738.299 806.895 738.462 807.589 738.789C808.283 739.107 808.876 739.605 809.368 740.283C809.86 740.952 810.239 741.805 810.505 742.842C810.771 743.879 810.905 745.108 810.905 746.529ZM808.775 746.602C808.775 746.324 808.763 746.051 808.739 745.782C808.723 745.504 808.702 745.235 808.678 744.973L801.987 750.007C802.108 750.431 802.261 750.823 802.447 751.182C802.632 751.542 802.854 751.852 803.112 752.113C803.378 752.366 803.677 752.566 804.008 752.713C804.346 752.852 804.73 752.921 805.157 752.921C805.706 752.921 806.202 752.787 806.645 752.517C807.097 752.248 807.48 751.848 807.795 751.317C808.109 750.786 808.351 750.129 808.521 749.345C808.69 748.553 808.775 747.639 808.775 746.602ZM801.539 746.455C801.539 746.708 801.539 746.961 801.539 747.214C801.547 747.467 801.563 747.712 801.588 747.949L808.279 742.94C808.158 742.524 808.005 742.144 807.819 741.801C807.634 741.458 807.412 741.165 807.154 740.92C806.895 740.667 806.601 740.471 806.27 740.332C805.94 740.193 805.568 740.124 805.157 740.124C804.609 740.124 804.108 740.258 803.657 740.528C803.213 740.797 802.834 741.201 802.519 741.74C802.205 742.271 801.963 742.932 801.793 743.724C801.624 744.508 801.539 745.418 801.539 746.455Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M810.408 781H800.474V779.016H804.54V767.333L800.753 769.415L799.978 767.602L805.012 764.92H806.875V779.016H810.408V781Z"
        fill="#6BB2EE"
        fillOpacity="0.18"
      />
      <path
        d="M1016.9 -83.598H1006.41V-85.6921H1010.7V-98.0244L1006.7 -95.8269L1005.89 -97.74L1011.2 -100.571H1013.17V-85.6921H1016.9V-83.598Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 -55.7302H1006.41V-57.8243H1010.7V-70.1567L1006.7 -67.9591L1005.89 -69.8723L1011.2 -72.7033H1013.17V-57.8243H1016.9V-55.7302Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 -36.3037C1017.42 -35.011 1017.29 -33.8303 1017.04 -32.7617C1016.78 -31.7017 1016.39 -30.7882 1015.87 -30.0212C1015.35 -29.2628 1014.71 -28.6725 1013.93 -28.2502C1013.17 -27.8365 1012.26 -27.6297 1011.22 -27.6297C1010.33 -27.6297 1009.52 -27.7978 1008.79 -28.1339C1008.05 -28.4786 1007.43 -29.0043 1006.91 -29.711C1006.39 -30.4176 1005.99 -31.3182 1005.71 -32.4127C1005.43 -33.5072 1005.29 -34.8042 1005.29 -36.3037C1005.29 -37.5964 1005.41 -38.7771 1005.67 -39.8457C1005.93 -40.9143 1006.32 -41.8278 1006.83 -42.5862C1007.35 -43.3532 1008 -43.9435 1008.77 -44.3572C1009.55 -44.7795 1010.45 -44.9906 1011.48 -44.9906C1012.37 -44.9906 1013.19 -44.8182 1013.92 -44.4735C1014.65 -44.1374 1015.28 -43.6117 1015.8 -42.8964C1016.32 -42.1898 1016.72 -41.2892 1017 -40.1947C1017.28 -39.1002 1017.42 -37.8032 1017.42 -36.3037ZM1015.17 -36.2261C1015.17 -36.5191 1015.16 -36.8078 1015.13 -37.0922C1015.12 -37.3853 1015.09 -37.6696 1015.07 -37.9454L1008.01 -32.6324C1008.13 -32.1843 1008.3 -31.7706 1008.49 -31.3915C1008.69 -31.0123 1008.92 -30.6848 1009.19 -30.409C1009.48 -30.1418 1009.79 -29.9307 1010.14 -29.7756C1010.5 -29.6291 1010.9 -29.5558 1011.35 -29.5558C1011.93 -29.5558 1012.46 -29.698 1012.92 -29.9824C1013.4 -30.2668 1013.8 -30.6891 1014.14 -31.2493C1014.47 -31.8094 1014.72 -32.5032 1014.9 -33.3305C1015.08 -34.1664 1015.17 -35.1317 1015.17 -36.2261ZM1007.53 -36.3813C1007.53 -36.1141 1007.53 -35.8469 1007.53 -35.5798C1007.54 -35.3126 1007.56 -35.0541 1007.58 -34.8042L1014.65 -40.0913C1014.52 -40.5308 1014.36 -40.9315 1014.16 -41.2935C1013.97 -41.6555 1013.73 -41.9657 1013.46 -42.2242C1013.19 -42.4914 1012.88 -42.6982 1012.53 -42.8447C1012.18 -42.9912 1011.79 -43.0645 1011.35 -43.0645C1010.77 -43.0645 1010.25 -42.9223 1009.77 -42.6379C1009.3 -42.3535 1008.9 -41.9269 1008.57 -41.3581C1008.24 -40.798 1007.98 -40.0999 1007.8 -39.264C1007.62 -38.4366 1007.53 -37.4757 1007.53 -36.3813Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 -8.43591C1017.42 -7.14322 1017.29 -5.96255 1017.04 -4.89393C1016.78 -3.83392 1016.39 -2.92041 1015.87 -2.15341C1015.35 -1.39503 1014.71 -0.804703 1013.93 -0.382423C1013.17 0.0312424 1012.26 0.238075 1011.22 0.238075C1010.33 0.238075 1009.52 0.0700226 1008.79 -0.266083C1008.05 -0.610802 1007.43 -1.1365 1006.91 -1.84317C1006.39 -2.54984 1005.99 -3.45042 1005.71 -4.5449C1005.43 -5.63938 1005.29 -6.93639 1005.29 -8.43591C1005.29 -9.72861 1005.41 -10.9093 1005.67 -11.9779C1005.93 -13.0465 1006.32 -13.96 1006.83 -14.7184C1007.35 -15.4854 1008 -16.0757 1008.77 -16.4894C1009.55 -16.9117 1010.45 -17.1228 1011.48 -17.1228C1012.37 -17.1228 1013.19 -16.9505 1013.92 -16.6057C1014.65 -16.2696 1015.28 -15.7439 1015.8 -15.0287C1016.32 -14.322 1016.72 -13.4214 1017 -12.3269C1017.28 -11.2324 1017.42 -9.93544 1017.42 -8.43591ZM1015.17 -8.35835C1015.17 -8.65136 1015.16 -8.94006 1015.13 -9.22446C1015.12 -9.51746 1015.09 -9.80186 1015.07 -10.0776L1008.01 -4.76466C1008.13 -4.31652 1008.3 -3.90286 1008.49 -3.52367C1008.69 -3.14448 1008.92 -2.817 1009.19 -2.54122C1009.48 -2.27406 1009.79 -2.06293 1010.14 -1.9078C1010.5 -1.7613 1010.9 -1.68804 1011.35 -1.68804C1011.93 -1.68804 1012.46 -1.83024 1012.92 -2.11463C1013.4 -2.39902 1013.8 -2.8213 1014.14 -3.38148C1014.47 -3.94164 1014.72 -4.63539 1014.9 -5.46272C1015.08 -6.29865 1015.17 -7.26387 1015.17 -8.35835ZM1007.53 -8.51347C1007.53 -8.24632 1007.53 -7.97916 1007.53 -7.71201C1007.54 -7.44485 1007.56 -7.18631 1007.58 -6.93639L1014.65 -12.2235C1014.52 -12.663 1014.36 -13.0638 1014.16 -13.4257C1013.97 -13.7877 1013.73 -14.0979 1013.46 -14.3565C1013.19 -14.6236 1012.88 -14.8304 1012.53 -14.977C1012.18 -15.1235 1011.79 -15.1967 1011.35 -15.1967C1010.77 -15.1967 1010.25 -15.0545 1009.77 -14.7701C1009.3 -14.4857 1008.9 -14.0591 1008.57 -13.4903C1008.24 -12.9302 1007.98 -12.2321 1007.8 -11.3962C1007.62 -10.5689 1007.53 -9.60796 1007.53 -8.51347Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 19.4319C1017.42 20.7246 1017.29 21.9052 1017.04 22.9739C1016.78 24.0339 1016.39 24.9474 1015.87 25.7144C1015.35 26.4727 1014.71 27.0631 1013.93 27.4854C1013.17 27.899 1012.26 28.1059 1011.22 28.1059C1010.33 28.1059 1009.52 27.9378 1008.79 27.6017C1008.05 27.257 1007.43 26.7313 1006.91 26.0246C1006.39 25.3179 1005.99 24.4174 1005.71 23.3229C1005.43 22.2284 1005.29 20.9314 1005.29 19.4319C1005.29 18.1392 1005.41 16.9585 1005.67 15.8899C1005.93 14.8213 1006.32 13.9078 1006.83 13.1494C1007.35 12.3824 1008 11.792 1008.77 11.3784C1009.55 10.9561 1010.45 10.745 1011.48 10.745C1012.37 10.745 1013.19 10.9173 1013.92 11.262C1014.65 11.5981 1015.28 12.1238 1015.8 12.8391C1016.32 13.5458 1016.72 14.4464 1017 15.5409C1017.28 16.6353 1017.42 17.9323 1017.42 19.4319ZM1015.17 19.5094C1015.17 19.2164 1015.16 18.9277 1015.13 18.6433C1015.12 18.3503 1015.09 18.0659 1015.07 17.7901L1008.01 23.1031C1008.13 23.5513 1008.3 23.9649 1008.49 24.3441C1008.69 24.7233 1008.92 25.0508 1009.19 25.3266C1009.48 25.5937 1009.79 25.8049 1010.14 25.96C1010.5 26.1065 1010.9 26.1797 1011.35 26.1797C1011.93 26.1797 1012.46 26.0375 1012.92 25.7532C1013.4 25.4688 1013.8 25.0465 1014.14 24.4863C1014.47 23.9261 1014.72 23.2324 1014.9 22.4051C1015.08 21.5691 1015.17 20.6039 1015.17 19.5094ZM1007.53 19.3543C1007.53 19.6215 1007.53 19.8886 1007.53 20.1558C1007.54 20.4229 1007.56 20.6815 1007.58 20.9314L1014.65 15.6443C1014.52 15.2048 1014.36 14.804 1014.16 14.4421C1013.97 14.0801 1013.73 13.7699 1013.46 13.5113C1013.19 13.2442 1012.88 13.0373 1012.53 12.8908C1012.18 12.7443 1011.79 12.6711 1011.35 12.6711C1010.77 12.6711 1010.25 12.8133 1009.77 13.0977C1009.3 13.3821 1008.9 13.8086 1008.57 14.3774C1008.24 14.9376 1007.98 15.6357 1007.8 16.4716C1007.62 17.2989 1007.53 18.2598 1007.53 19.3543Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 55.741H1006.41V53.6468H1010.7V41.3145L1006.7 43.5121L1005.89 41.5989L1011.2 38.7679H1013.17V53.6468H1016.9V55.741Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 75.1674C1017.42 76.4601 1017.29 77.6408 1017.04 78.7094C1016.78 79.7694 1016.39 80.6829 1015.87 81.4499C1015.35 82.2083 1014.71 82.7986 1013.93 83.2209C1013.17 83.6346 1012.26 83.8414 1011.22 83.8414C1010.33 83.8414 1009.52 83.6734 1008.79 83.3373C1008.05 82.9926 1007.43 82.4669 1006.91 81.7602C1006.39 81.0535 1005.99 80.1529 1005.71 79.0584C1005.43 77.964 1005.29 76.667 1005.29 75.1674C1005.29 73.8747 1005.41 72.6941 1005.67 71.6255C1005.93 70.5568 1006.32 69.6433 1006.83 68.8849C1007.35 68.1179 1008 67.5276 1008.77 67.1139C1009.55 66.6917 1010.45 66.4805 1011.48 66.4805C1012.37 66.4805 1013.19 66.6529 1013.92 66.9976C1014.65 67.3337 1015.28 67.8594 1015.8 68.5747C1016.32 69.2814 1016.72 70.1819 1017 71.2764C1017.28 72.3709 1017.42 73.6679 1017.42 75.1674ZM1015.17 75.245C1015.17 74.952 1015.16 74.6633 1015.13 74.3789C1015.12 74.0859 1015.09 73.8015 1015.07 73.5257L1008.01 78.8387C1008.13 79.2868 1008.3 79.7005 1008.49 80.0797C1008.69 80.4589 1008.92 80.7863 1009.19 81.0621C1009.48 81.3293 1009.79 81.5404 1010.14 81.6955C1010.5 81.8421 1010.9 81.9153 1011.35 81.9153C1011.93 81.9153 1012.46 81.7731 1012.92 81.4887C1013.4 81.2043 1013.8 80.782 1014.14 80.2219C1014.47 79.6617 1014.72 78.968 1014.9 78.1406C1015.08 77.3047 1015.17 76.3395 1015.17 75.245ZM1007.53 75.0899C1007.53 75.357 1007.53 75.6242 1007.53 75.8913C1007.54 76.1585 1007.56 76.417 1007.58 76.667L1014.65 71.3798C1014.52 70.9403 1014.36 70.5396 1014.16 70.1776C1013.97 69.8157 1013.73 69.5054 1013.46 69.2469C1013.19 68.9797 1012.88 68.7729 1012.53 68.6264C1012.18 68.4799 1011.79 68.4066 1011.35 68.4066C1010.77 68.4066 1010.25 68.5488 1009.77 68.8332C1009.3 69.1176 1008.9 69.5442 1008.57 70.113C1008.24 70.6732 1007.98 71.3712 1007.8 72.2072C1007.62 73.0345 1007.53 73.9954 1007.53 75.0899Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 111.477H1006.41V109.382H1010.7V97.05L1006.7 99.2476L1005.89 97.3344L1011.2 94.5034H1013.17V109.382H1016.9V111.477Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 139.344H1006.41V137.25H1010.7V124.918L1006.7 127.115L1005.89 125.202L1011.2 122.371H1013.17V137.25H1016.9V139.344Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 158.771C1017.42 160.063 1017.29 161.244 1017.04 162.313C1016.78 163.373 1016.39 164.286 1015.87 165.053C1015.35 165.812 1014.71 166.402 1013.93 166.824C1013.17 167.238 1012.26 167.445 1011.22 167.445C1010.33 167.445 1009.52 167.277 1008.79 166.941C1008.05 166.596 1007.43 166.07 1006.91 165.364C1006.39 164.657 1005.99 163.756 1005.71 162.662C1005.43 161.567 1005.29 160.27 1005.29 158.771C1005.29 157.478 1005.41 156.297 1005.67 155.229C1005.93 154.16 1006.32 153.247 1006.83 152.488C1007.35 151.721 1008 151.131 1008.77 150.717C1009.55 150.295 1010.45 150.084 1011.48 150.084C1012.37 150.084 1013.19 150.256 1013.92 150.601C1014.65 150.937 1015.28 151.463 1015.8 152.178C1016.32 152.885 1016.72 153.785 1017 154.88C1017.28 155.974 1017.42 157.271 1017.42 158.771ZM1015.17 158.848C1015.17 158.555 1015.16 158.267 1015.13 157.982C1015.12 157.689 1015.09 157.405 1015.07 157.129L1008.01 162.442C1008.13 162.89 1008.3 163.304 1008.49 163.683C1008.69 164.062 1008.92 164.39 1009.19 164.665C1009.48 164.933 1009.79 165.144 1010.14 165.299C1010.5 165.445 1010.9 165.519 1011.35 165.519C1011.93 165.519 1012.46 165.376 1012.92 165.092C1013.4 164.808 1013.8 164.385 1014.14 163.825C1014.47 163.265 1014.72 162.571 1014.9 161.744C1015.08 160.908 1015.17 159.943 1015.17 158.848ZM1007.53 158.693C1007.53 158.96 1007.53 159.228 1007.53 159.495C1007.54 159.762 1007.56 160.02 1007.58 160.27L1014.65 154.983C1014.52 154.544 1014.36 154.143 1014.16 153.781C1013.97 153.419 1013.73 153.109 1013.46 152.85C1013.19 152.583 1012.88 152.376 1012.53 152.23C1012.18 152.083 1011.79 152.01 1011.35 152.01C1010.77 152.01 1010.25 152.152 1009.77 152.437C1009.3 152.721 1008.9 153.148 1008.57 153.716C1008.24 154.277 1007.98 154.975 1007.8 155.811C1007.62 156.638 1007.53 157.599 1007.53 158.693Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 186.639C1017.42 187.931 1017.29 189.112 1017.04 190.181C1016.78 191.241 1016.39 192.154 1015.87 192.921C1015.35 193.679 1014.71 194.27 1013.93 194.692C1013.17 195.106 1012.26 195.313 1011.22 195.313C1010.33 195.313 1009.52 195.145 1008.79 194.808C1008.05 194.464 1007.43 193.938 1006.91 193.231C1006.39 192.525 1005.99 191.624 1005.71 190.53C1005.43 189.435 1005.29 188.138 1005.29 186.639C1005.29 185.346 1005.41 184.165 1005.67 183.097C1005.93 182.028 1006.32 181.114 1006.83 180.356C1007.35 179.589 1008 178.999 1008.77 178.585C1009.55 178.163 1010.45 177.952 1011.48 177.952C1012.37 177.952 1013.19 178.124 1013.92 178.469C1014.65 178.805 1015.28 179.331 1015.8 180.046C1016.32 180.753 1016.72 181.653 1017 182.748C1017.28 183.842 1017.42 185.139 1017.42 186.639ZM1015.17 186.716C1015.17 186.423 1015.16 186.134 1015.13 185.85C1015.12 185.557 1015.09 185.273 1015.07 184.997L1008.01 190.31C1008.13 190.758 1008.3 191.172 1008.49 191.551C1008.69 191.93 1008.92 192.257 1009.19 192.533C1009.48 192.8 1009.79 193.012 1010.14 193.167C1010.5 193.313 1010.9 193.386 1011.35 193.386C1011.93 193.386 1012.46 193.244 1012.92 192.96C1013.4 192.675 1013.8 192.253 1014.14 191.693C1014.47 191.133 1014.72 190.439 1014.9 189.612C1015.08 188.776 1015.17 187.811 1015.17 186.716ZM1007.53 186.561C1007.53 186.828 1007.53 187.095 1007.53 187.362C1007.54 187.63 1007.56 187.888 1007.58 188.138L1014.65 182.851C1014.52 182.411 1014.36 182.011 1014.16 181.649C1013.97 181.287 1013.73 180.977 1013.46 180.718C1013.19 180.451 1012.88 180.244 1012.53 180.098C1012.18 179.951 1011.79 179.878 1011.35 179.878C1010.77 179.878 1010.25 180.02 1009.77 180.304C1009.3 180.589 1008.9 181.015 1008.57 181.584C1008.24 182.144 1007.98 182.842 1007.8 183.678C1007.62 184.506 1007.53 185.467 1007.53 186.561Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 214.506C1017.42 215.799 1017.29 216.98 1017.04 218.048C1016.78 219.108 1016.39 220.022 1015.87 220.789C1015.35 221.547 1014.71 222.138 1013.93 222.56C1013.17 222.974 1012.26 223.18 1011.22 223.18C1010.33 223.18 1009.52 223.012 1008.79 222.676C1008.05 222.331 1007.43 221.806 1006.91 221.099C1006.39 220.392 1005.99 219.492 1005.71 218.397C1005.43 217.303 1005.29 216.006 1005.29 214.506C1005.29 213.214 1005.41 212.033 1005.67 210.964C1005.93 209.896 1006.32 208.982 1006.83 208.224C1007.35 207.457 1008 206.867 1008.77 206.453C1009.55 206.031 1010.45 205.819 1011.48 205.819C1012.37 205.819 1013.19 205.992 1013.92 206.337C1014.65 206.673 1015.28 207.198 1015.8 207.914C1016.32 208.62 1016.72 209.521 1017 210.615C1017.28 211.71 1017.42 213.007 1017.42 214.506ZM1015.17 214.584C1015.17 214.291 1015.16 214.002 1015.13 213.718C1015.12 213.425 1015.09 213.14 1015.07 212.865L1008.01 218.178C1008.13 218.626 1008.3 219.039 1008.49 219.419C1008.69 219.798 1008.92 220.125 1009.19 220.401C1009.48 220.668 1009.79 220.879 1010.14 221.034C1010.5 221.181 1010.9 221.254 1011.35 221.254C1011.93 221.254 1012.46 221.112 1012.92 220.828C1013.4 220.543 1013.8 220.121 1014.14 219.561C1014.47 219.001 1014.72 218.307 1014.9 217.48C1015.08 216.644 1015.17 215.678 1015.17 214.584ZM1007.53 214.429C1007.53 214.696 1007.53 214.963 1007.53 215.23C1007.54 215.497 1007.56 215.756 1007.58 216.006L1014.65 210.719C1014.52 210.279 1014.36 209.879 1014.16 209.517C1013.97 209.155 1013.73 208.844 1013.46 208.586C1013.19 208.319 1012.88 208.112 1012.53 207.965C1012.18 207.819 1011.79 207.746 1011.35 207.746C1010.77 207.746 1010.25 207.888 1009.77 208.172C1009.3 208.457 1008.9 208.883 1008.57 209.452C1008.24 210.012 1007.98 210.71 1007.8 211.546C1007.62 212.373 1007.53 213.334 1007.53 214.429Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 250.815H1006.41V248.721H1010.7V236.389L1006.7 238.587L1005.89 236.673L1011.2 233.842H1013.17V248.721H1016.9V250.815Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 278.683H1006.41V276.589H1010.7V264.257L1006.7 266.454L1005.89 264.541L1011.2 261.71H1013.17V276.589H1016.9V278.683Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 306.551H1006.41V304.457H1010.7V292.125L1006.7 294.322L1005.89 292.409L1011.2 289.578H1013.17V304.457H1016.9V306.551Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 325.977C1017.42 327.27 1017.29 328.451 1017.04 329.519C1016.78 330.579 1016.39 331.493 1015.87 332.26C1015.35 333.018 1014.71 333.609 1013.93 334.031C1013.17 334.445 1012.26 334.651 1011.22 334.651C1010.33 334.651 1009.52 334.483 1008.79 334.147C1008.05 333.803 1007.43 333.277 1006.91 332.57C1006.39 331.864 1005.99 330.963 1005.71 329.868C1005.43 328.774 1005.29 327.477 1005.29 325.977C1005.29 324.685 1005.41 323.504 1005.67 322.435C1005.93 321.367 1006.32 320.453 1006.83 319.695C1007.35 318.928 1008 318.338 1008.77 317.924C1009.55 317.502 1010.45 317.291 1011.48 317.291C1012.37 317.291 1013.19 317.463 1013.92 317.808C1014.65 318.144 1015.28 318.669 1015.8 319.385C1016.32 320.091 1016.72 320.992 1017 322.086C1017.28 323.181 1017.42 324.478 1017.42 325.977ZM1015.17 326.055C1015.17 325.762 1015.16 325.473 1015.13 325.189C1015.12 324.896 1015.09 324.612 1015.07 324.336L1008.01 329.649C1008.13 330.097 1008.3 330.511 1008.49 330.89C1008.69 331.269 1008.92 331.596 1009.19 331.872C1009.48 332.139 1009.79 332.35 1010.14 332.506C1010.5 332.652 1010.9 332.725 1011.35 332.725C1011.93 332.725 1012.46 332.583 1012.92 332.299C1013.4 332.014 1013.8 331.592 1014.14 331.032C1014.47 330.472 1014.72 329.778 1014.9 328.951C1015.08 328.115 1015.17 327.15 1015.17 326.055ZM1007.53 325.9C1007.53 326.167 1007.53 326.434 1007.53 326.701C1007.54 326.969 1007.56 327.227 1007.58 327.477L1014.65 322.19C1014.52 321.75 1014.36 321.35 1014.16 320.988C1013.97 320.626 1013.73 320.315 1013.46 320.057C1013.19 319.79 1012.88 319.583 1012.53 319.436C1012.18 319.29 1011.79 319.217 1011.35 319.217C1010.77 319.217 1010.25 319.359 1009.77 319.643C1009.3 319.928 1008.9 320.354 1008.57 320.923C1008.24 321.483 1007.98 322.181 1007.8 323.017C1007.62 323.845 1007.53 324.805 1007.53 325.9Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 362.287H1006.41V360.192H1010.7V347.86L1006.7 350.058L1005.89 348.144L1011.2 345.313H1013.17V360.192H1016.9V362.287Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 381.713C1017.42 383.006 1017.29 384.186 1017.04 385.255C1016.78 386.315 1016.39 387.229 1015.87 387.996C1015.35 388.754 1014.71 389.344 1013.93 389.767C1013.17 390.18 1012.26 390.387 1011.22 390.387C1010.33 390.387 1009.52 390.219 1008.79 389.883C1008.05 389.538 1007.43 389.012 1006.91 388.306C1006.39 387.599 1005.99 386.699 1005.71 385.604C1005.43 384.51 1005.29 383.213 1005.29 381.713C1005.29 380.42 1005.41 379.24 1005.67 378.171C1005.93 377.102 1006.32 376.189 1006.83 375.431C1007.35 374.664 1008 374.073 1008.77 373.66C1009.55 373.237 1010.45 373.026 1011.48 373.026C1012.37 373.026 1013.19 373.198 1013.92 373.543C1014.65 373.879 1015.28 374.405 1015.8 375.12C1016.32 375.827 1016.72 376.728 1017 377.822C1017.28 378.917 1017.42 380.214 1017.42 381.713ZM1015.17 381.791C1015.17 381.498 1015.16 381.209 1015.13 380.924C1015.12 380.631 1015.09 380.347 1015.07 380.071L1008.01 385.384C1008.13 385.832 1008.3 386.246 1008.49 386.625C1008.69 387.004 1008.92 387.332 1009.19 387.608C1009.48 387.875 1009.79 388.086 1010.14 388.241C1010.5 388.388 1010.9 388.461 1011.35 388.461C1011.93 388.461 1012.46 388.319 1012.92 388.034C1013.4 387.75 1013.8 387.328 1014.14 386.767C1014.47 386.207 1014.72 385.514 1014.9 384.686C1015.08 383.85 1015.17 382.885 1015.17 381.791ZM1007.53 381.635C1007.53 381.903 1007.53 382.17 1007.53 382.437C1007.54 382.704 1007.56 382.963 1007.58 383.213L1014.65 377.925C1014.52 377.486 1014.36 377.085 1014.16 376.723C1013.97 376.361 1013.73 376.051 1013.46 375.792C1013.19 375.525 1012.88 375.319 1012.53 375.172C1012.18 375.025 1011.79 374.952 1011.35 374.952C1010.77 374.952 1010.25 375.094 1009.77 375.379C1009.3 375.663 1008.9 376.09 1008.57 376.659C1008.24 377.219 1007.98 377.917 1007.8 378.753C1007.62 379.58 1007.53 380.541 1007.53 381.635Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1017.42 409.581C1017.42 410.874 1017.29 412.054 1017.04 413.123C1016.78 414.183 1016.39 415.096 1015.87 415.863C1015.35 416.622 1014.71 417.212 1013.93 417.634C1013.17 418.048 1012.26 418.255 1011.22 418.255C1010.33 418.255 1009.52 418.087 1008.79 417.751C1008.05 417.406 1007.43 416.88 1006.91 416.174C1006.39 415.467 1005.99 414.566 1005.71 413.472C1005.43 412.377 1005.29 411.08 1005.29 409.581C1005.29 408.288 1005.41 407.107 1005.67 406.039C1005.93 404.97 1006.32 404.057 1006.83 403.298C1007.35 402.531 1008 401.941 1008.77 401.527C1009.55 401.105 1010.45 400.894 1011.48 400.894C1012.37 400.894 1013.19 401.066 1013.92 401.411C1014.65 401.747 1015.28 402.273 1015.8 402.988C1016.32 403.695 1016.72 404.595 1017 405.69C1017.28 406.784 1017.42 408.081 1017.42 409.581ZM1015.17 409.658C1015.17 409.365 1015.16 409.077 1015.13 408.792C1015.12 408.499 1015.09 408.215 1015.07 407.939L1008.01 413.252C1008.13 413.7 1008.3 414.114 1008.49 414.493C1008.69 414.872 1008.92 415.2 1009.19 415.476C1009.48 415.743 1009.79 415.954 1010.14 416.109C1010.5 416.255 1010.9 416.329 1011.35 416.329C1011.93 416.329 1012.46 416.186 1012.92 415.902C1013.4 415.618 1013.8 415.195 1014.14 414.635C1014.47 414.075 1014.72 413.381 1014.9 412.554C1015.08 411.718 1015.17 410.753 1015.17 409.658ZM1007.53 409.503C1007.53 409.77 1007.53 410.038 1007.53 410.305C1007.54 410.572 1007.56 410.83 1007.58 411.08L1014.65 405.793C1014.52 405.354 1014.36 404.953 1014.16 404.591C1013.97 404.229 1013.73 403.919 1013.46 403.66C1013.19 403.393 1012.88 403.186 1012.53 403.04C1012.18 402.893 1011.79 402.82 1011.35 402.82C1010.77 402.82 1010.25 402.962 1009.77 403.247C1009.3 403.531 1008.9 403.958 1008.57 404.526C1008.24 405.087 1007.98 405.785 1007.8 406.621C1007.62 407.448 1007.53 408.409 1007.53 409.503Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M1016.9 445.89H1006.41V443.796H1010.7V431.463L1006.7 433.661L1005.89 431.748L1011.2 428.917H1013.17V443.796H1016.9V445.89Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 -225.027H775.124V-227.121H779.415V-239.453L775.418 -237.256L774.6 -239.169L779.914 -242H781.88V-227.121H785.61V-225.027Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 -197.159H775.124V-199.253H779.415V-211.586L775.418 -209.388L774.6 -211.301L779.914 -214.132H781.88V-199.253H785.61V-197.159Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 -177.733C786.134 -176.44 786.006 -175.259 785.75 -174.191C785.495 -173.131 785.108 -172.217 784.588 -171.45C784.069 -170.692 783.422 -170.101 782.647 -169.679C781.88 -169.265 780.978 -169.059 779.939 -169.059C779.045 -169.059 778.232 -169.227 777.5 -169.563C776.767 -169.908 776.141 -170.433 775.622 -171.14C775.103 -171.847 774.702 -172.747 774.421 -173.842C774.14 -174.936 774 -176.233 774 -177.733C774 -179.025 774.128 -180.206 774.383 -181.275C774.647 -182.343 775.035 -183.257 775.545 -184.015C776.065 -184.782 776.712 -185.372 777.487 -185.786C778.262 -186.208 779.164 -186.42 780.195 -186.42C781.089 -186.42 781.902 -186.247 782.634 -185.902C783.366 -185.566 783.992 -185.041 784.512 -184.325C785.031 -183.619 785.431 -182.718 785.712 -181.624C785.993 -180.529 786.134 -179.232 786.134 -177.733ZM783.886 -177.655C783.886 -177.948 783.873 -178.237 783.847 -178.521C783.83 -178.814 783.809 -179.099 783.784 -179.374L776.72 -174.061C776.848 -173.613 777.01 -173.2 777.206 -172.82C777.402 -172.441 777.636 -172.114 777.908 -171.838C778.189 -171.571 778.504 -171.36 778.853 -171.205C779.211 -171.058 779.616 -170.985 780.067 -170.985C780.646 -170.985 781.169 -171.127 781.638 -171.411C782.115 -171.696 782.519 -172.118 782.851 -172.678C783.183 -173.238 783.439 -173.932 783.617 -174.759C783.796 -175.595 783.886 -176.561 783.886 -177.655ZM776.248 -177.81C776.248 -177.543 776.248 -177.276 776.248 -177.009C776.256 -176.742 776.273 -176.483 776.299 -176.233L783.362 -181.52C783.234 -181.96 783.073 -182.36 782.877 -182.722C782.681 -183.084 782.447 -183.395 782.174 -183.653C781.902 -183.92 781.591 -184.127 781.242 -184.274C780.893 -184.42 780.501 -184.493 780.067 -184.493C779.488 -184.493 778.96 -184.351 778.483 -184.067C778.015 -183.782 777.615 -183.356 777.282 -182.787C776.95 -182.227 776.695 -181.529 776.516 -180.693C776.337 -179.866 776.248 -178.905 776.248 -177.81Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 -149.865C786.134 -148.572 786.006 -147.392 785.75 -146.323C785.495 -145.263 785.108 -144.349 784.588 -143.582C784.069 -142.824 783.422 -142.234 782.647 -141.811C781.88 -141.398 780.978 -141.191 779.939 -141.191C779.045 -141.191 778.232 -141.359 777.5 -141.695C776.767 -142.04 776.141 -142.565 775.622 -143.272C775.103 -143.979 774.702 -144.879 774.421 -145.974C774.14 -147.068 774 -148.365 774 -149.865C774 -151.158 774.128 -152.338 774.383 -153.407C774.647 -154.475 775.035 -155.389 775.545 -156.147C776.065 -156.914 776.712 -157.505 777.487 -157.918C778.262 -158.341 779.164 -158.552 780.195 -158.552C781.089 -158.552 781.902 -158.379 782.634 -158.035C783.366 -157.699 783.992 -157.173 784.512 -156.458C785.031 -155.751 785.431 -154.85 785.712 -153.756C785.993 -152.661 786.134 -151.364 786.134 -149.865ZM783.886 -149.787C783.886 -150.08 783.873 -150.369 783.847 -150.653C783.83 -150.946 783.809 -151.231 783.784 -151.507L776.72 -146.194C776.848 -145.745 777.01 -145.332 777.206 -144.953C777.402 -144.573 777.636 -144.246 777.908 -143.97C778.189 -143.703 778.504 -143.492 778.853 -143.337C779.211 -143.19 779.616 -143.117 780.067 -143.117C780.646 -143.117 781.169 -143.259 781.638 -143.544C782.115 -143.828 782.519 -144.25 782.851 -144.81C783.183 -145.371 783.439 -146.064 783.617 -146.892C783.796 -147.728 783.886 -148.693 783.886 -149.787ZM776.248 -149.942C776.248 -149.675 776.248 -149.408 776.248 -149.141C776.256 -148.874 776.273 -148.615 776.299 -148.365L783.362 -153.652C783.234 -154.092 783.073 -154.493 782.877 -154.855C782.681 -155.217 782.447 -155.527 782.174 -155.785C781.902 -156.053 781.591 -156.259 781.242 -156.406C780.893 -156.552 780.501 -156.626 780.067 -156.626C779.488 -156.626 778.96 -156.483 778.483 -156.199C778.015 -155.915 777.615 -155.488 777.282 -154.919C776.95 -154.359 776.695 -153.661 776.516 -152.825C776.337 -151.998 776.248 -151.037 776.248 -149.942Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 -121.997C786.134 -120.704 786.006 -119.524 785.75 -118.455C785.495 -117.395 785.108 -116.482 784.588 -115.715C784.069 -114.956 783.422 -114.366 782.647 -113.944C781.88 -113.53 780.978 -113.323 779.939 -113.323C779.045 -113.323 778.232 -113.491 777.5 -113.827C776.767 -114.172 776.141 -114.698 775.622 -115.404C775.103 -116.111 774.702 -117.012 774.421 -118.106C774.14 -119.201 774 -120.498 774 -121.997C774 -123.29 774.128 -124.47 774.383 -125.539C774.647 -126.608 775.035 -127.521 775.545 -128.28C776.065 -129.047 776.712 -129.637 777.487 -130.051C778.262 -130.473 779.164 -130.684 780.195 -130.684C781.089 -130.684 781.902 -130.512 782.634 -130.167C783.366 -129.831 783.992 -129.305 784.512 -128.59C785.031 -127.883 785.431 -126.983 785.712 -125.888C785.993 -124.794 786.134 -123.497 786.134 -121.997ZM783.886 -121.92C783.886 -122.213 783.873 -122.501 783.847 -122.786C783.83 -123.079 783.809 -123.363 783.784 -123.639L776.72 -118.326C776.848 -117.878 777.01 -117.464 777.206 -117.085C777.402 -116.706 777.636 -116.378 777.908 -116.102C778.189 -115.835 778.504 -115.624 778.853 -115.469C779.211 -115.322 779.616 -115.249 780.067 -115.249C780.646 -115.249 781.169 -115.391 781.638 -115.676C782.115 -115.96 782.519 -116.382 782.851 -116.943C783.183 -117.503 783.439 -118.197 783.617 -119.024C783.796 -119.86 783.886 -120.825 783.886 -121.92ZM776.248 -122.075C776.248 -121.807 776.248 -121.54 776.248 -121.273C776.256 -121.006 776.273 -120.747 776.299 -120.498L783.362 -125.785C783.234 -126.224 783.073 -126.625 782.877 -126.987C782.681 -127.349 782.447 -127.659 782.174 -127.918C781.902 -128.185 781.591 -128.392 781.242 -128.538C780.893 -128.685 780.501 -128.758 780.067 -128.758C779.488 -128.758 778.96 -128.616 778.483 -128.331C778.015 -128.047 777.615 -127.62 777.282 -127.052C776.95 -126.491 776.695 -125.793 776.516 -124.957C776.337 -124.13 776.248 -123.169 776.248 -122.075Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 -85.688H775.124V-87.7822H779.415V-100.114L775.418 -97.9169L774.6 -99.8301L779.914 -102.661H781.88V-87.7822H785.61V-85.688Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 -66.2615C786.134 -64.9688 786.006 -63.7882 785.75 -62.7195C785.495 -61.6595 785.108 -60.746 784.588 -59.979C784.069 -59.2206 783.422 -58.6303 782.647 -58.208C781.88 -57.7944 780.978 -57.5875 779.939 -57.5875C779.045 -57.5875 778.232 -57.7556 777.5 -58.0917C776.767 -58.4364 776.141 -58.9621 775.622 -59.6688C775.103 -60.3754 774.702 -61.276 774.421 -62.3705C774.14 -63.465 774 -64.762 774 -66.2615C774 -67.5542 774.128 -68.7349 774.383 -69.8035C774.647 -70.8721 775.035 -71.7856 775.545 -72.544C776.065 -73.311 776.712 -73.9014 777.487 -74.315C778.262 -74.7373 779.164 -74.9484 780.195 -74.9484C781.089 -74.9484 781.902 -74.7761 782.634 -74.4314C783.366 -74.0952 783.992 -73.5695 784.512 -72.8543C785.031 -72.1476 785.431 -71.247 785.712 -70.1525C785.993 -69.058 786.134 -67.761 786.134 -66.2615ZM783.886 -66.184C783.886 -66.477 783.873 -66.7657 783.847 -67.0501C783.83 -67.3431 783.809 -67.6275 783.784 -67.9032L776.72 -62.5903C776.848 -62.1421 777.01 -61.7285 777.206 -61.3493C777.402 -60.9701 777.636 -60.6426 777.908 -60.3668C778.189 -60.0997 778.504 -59.8885 778.853 -59.7334C779.211 -59.5869 779.616 -59.5137 780.067 -59.5137C780.646 -59.5137 781.169 -59.6559 781.638 -59.9402C782.115 -60.2246 782.519 -60.6469 782.851 -61.2071C783.183 -61.7673 783.439 -62.461 783.617 -63.2883C783.796 -64.1243 783.886 -65.0895 783.886 -66.184ZM776.248 -66.3391C776.248 -66.0719 776.248 -65.8048 776.248 -65.5376C776.256 -65.2704 776.273 -65.0119 776.299 -64.762L783.362 -70.0491C783.234 -70.4886 783.073 -70.8894 782.877 -71.2513C782.681 -71.6133 782.447 -71.9235 782.174 -72.1821C781.902 -72.4492 781.591 -72.6561 781.242 -72.8026C780.893 -72.9491 780.501 -73.0223 780.067 -73.0223C779.488 -73.0223 778.96 -72.8801 778.483 -72.5957C778.015 -72.3113 777.615 -71.8848 777.282 -71.316C776.95 -70.7558 776.695 -70.0577 776.516 -69.2218C776.337 -68.3945 776.248 -67.4336 776.248 -66.3391Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 -29.9524H775.124V-32.0466H779.415V-44.3789L775.418 -42.1813L774.6 -44.0945L779.914 -46.9255H781.88V-32.0466H785.61V-29.9524Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 -2.08466H775.124V-4.17882H779.415V-16.5111L775.418 -14.3136L774.6 -16.2267L779.914 -19.0577H781.88V-4.17882H785.61V-2.08466Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 17.3418C786.134 18.6345 786.006 19.8152 785.75 20.8838C785.495 21.9438 785.108 22.8573 784.588 23.6243C784.069 24.3827 783.422 24.973 782.647 25.3953C781.88 25.809 780.978 26.0158 779.939 26.0158C779.045 26.0158 778.232 25.8477 777.5 25.5117C776.767 25.1669 776.141 24.6412 775.622 23.9346C775.103 23.2279 774.702 22.3273 774.421 21.2328C774.14 20.1384 774 18.8413 774 17.3418C774 16.0491 774.128 14.8685 774.383 13.7998C774.647 12.7312 775.035 11.8177 775.545 11.0593C776.065 10.2923 776.712 9.702 777.487 9.28833C778.262 8.86606 779.164 8.65492 780.195 8.65492C781.089 8.65492 781.902 8.82727 782.634 9.172C783.366 9.50809 783.992 10.0338 784.512 10.7491C785.031 11.4557 785.431 12.3563 785.712 13.4508C785.993 14.5453 786.134 15.8423 786.134 17.3418ZM783.886 17.4194C783.886 17.1264 783.873 16.8377 783.847 16.5533C783.83 16.2603 783.809 15.9759 783.784 15.7001L776.72 21.0131C776.848 21.4612 777.01 21.8749 777.206 22.2541C777.402 22.6333 777.636 22.9608 777.908 23.2365C778.189 23.5037 778.504 23.7148 778.853 23.8699C779.211 24.0164 779.616 24.0897 780.067 24.0897C780.646 24.0897 781.169 23.9475 781.638 23.6631C782.115 23.3787 782.519 22.9564 782.851 22.3963C783.183 21.8361 783.439 21.1423 783.617 20.315C783.796 19.4791 783.886 18.5139 783.886 17.4194ZM776.248 17.2643C776.248 17.5314 776.248 17.7986 776.248 18.0657C776.256 18.3329 776.273 18.5914 776.299 18.8413L783.362 13.5542C783.234 13.1147 783.073 12.714 782.877 12.352C782.681 11.9901 782.447 11.6798 782.174 11.4213C781.902 11.1541 781.591 10.9473 781.242 10.8008C780.893 10.6543 780.501 10.581 780.067 10.581C779.488 10.581 778.96 10.7232 778.483 11.0076C778.015 11.292 777.615 11.7186 777.282 12.2874C776.95 12.8475 776.695 13.5456 776.516 14.3816C776.337 15.2089 776.248 16.1698 776.248 17.2643Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 45.2096C786.134 46.5023 786.006 47.683 785.75 48.7516C785.495 49.8116 785.108 50.7251 784.588 51.4921C784.069 52.2505 783.422 52.8408 782.647 53.2631C781.88 53.6768 780.978 53.8836 779.939 53.8836C779.045 53.8836 778.232 53.7155 777.5 53.3794C776.767 53.0347 776.141 52.509 775.622 51.8023C775.103 51.0957 774.702 50.1951 774.421 49.1006C774.14 48.0061 774 46.7091 774 45.2096C774 43.9169 774.128 42.7362 774.383 41.6676C774.647 40.599 775.035 39.6855 775.545 38.9271C776.065 38.1601 776.712 37.5698 777.487 37.1561C778.262 36.7338 779.164 36.5227 780.195 36.5227C781.089 36.5227 781.902 36.6951 782.634 37.0398C783.366 37.3759 783.992 37.9016 784.512 38.6169C785.031 39.3235 785.431 40.2241 785.712 41.3186C785.993 42.4131 786.134 43.7101 786.134 45.2096ZM783.886 45.2872C783.886 44.9942 783.873 44.7054 783.847 44.4211C783.83 44.1281 783.809 43.8437 783.784 43.5679L776.72 48.8809C776.848 49.329 777.01 49.7426 777.206 50.1219C777.402 50.501 777.636 50.8285 777.908 51.1043C778.189 51.3715 778.504 51.5826 778.853 51.7377C779.211 51.8842 779.616 51.9575 780.067 51.9575C780.646 51.9575 781.169 51.8153 781.638 51.5309C782.115 51.2465 782.519 50.8242 782.851 50.264C783.183 49.7039 783.439 49.0101 783.617 48.1828C783.796 47.3469 783.886 46.3817 783.886 45.2872ZM776.248 45.132C776.248 45.3992 776.248 45.6664 776.248 45.9335C776.256 46.2007 776.273 46.4592 776.299 46.7091L783.362 41.422C783.234 40.9825 783.073 40.5818 782.877 40.2198C782.681 39.8578 782.447 39.5476 782.174 39.2891C781.902 39.0219 781.591 38.8151 781.242 38.6686C780.893 38.5221 780.501 38.4488 780.067 38.4488C779.488 38.4488 778.96 38.591 778.483 38.8754C778.015 39.1598 777.615 39.5864 777.282 40.1552C776.95 40.7153 776.695 41.4134 776.516 42.2493C776.337 43.0767 776.248 44.0376 776.248 45.132Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 73.0774C786.134 74.3701 786.006 75.5508 785.75 76.6194C785.495 77.6794 785.108 78.5929 784.588 79.3599C784.069 80.1183 783.422 80.7086 782.647 81.1309C781.88 81.5446 780.978 81.7514 779.939 81.7514C779.045 81.7514 778.232 81.5833 777.5 81.2472C776.767 80.9025 776.141 80.3768 775.622 79.6701C775.103 78.9635 774.702 78.0629 774.421 76.9684C774.14 75.8739 774 74.5769 774 73.0774C774 71.7847 774.128 70.604 774.383 69.5354C774.647 68.4668 775.035 67.5533 775.545 66.7949C776.065 66.0279 776.712 65.4376 777.487 65.0239C778.262 64.6016 779.164 64.3905 780.195 64.3905C781.089 64.3905 781.902 64.5628 782.634 64.9076C783.366 65.2437 783.992 65.7693 784.512 66.4846C785.031 67.1913 785.431 68.0919 785.712 69.1864C785.993 70.2809 786.134 71.5779 786.134 73.0774ZM783.886 73.1549C783.886 72.8619 783.873 72.5732 783.847 72.2888C783.83 71.9958 783.809 71.7115 783.784 71.4357L776.72 76.7487C776.848 77.1968 777.01 77.6104 777.206 77.9896C777.402 78.3688 777.636 78.6963 777.908 78.9721C778.189 79.2392 778.504 79.4504 778.853 79.6055C779.211 79.752 779.616 79.8253 780.067 79.8253C780.646 79.8253 781.169 79.6831 781.638 79.3987C782.115 79.1143 782.519 78.692 782.851 78.1318C783.183 77.5717 783.439 76.8779 783.617 76.0506C783.796 75.2147 783.886 74.2494 783.886 73.1549ZM776.248 72.9998C776.248 73.267 776.248 73.5341 776.248 73.8013C776.256 74.0685 776.273 74.327 776.299 74.5769L783.362 69.2898C783.234 68.8503 783.073 68.4496 782.877 68.0876C782.681 67.7256 782.447 67.4154 782.174 67.1569C781.902 66.8897 781.591 66.6829 781.242 66.5363C780.893 66.3899 780.501 66.3166 780.067 66.3166C779.488 66.3166 778.96 66.4588 778.483 66.7432C778.015 67.0276 777.615 67.4542 777.282 68.0229C776.95 68.5831 776.695 69.2812 776.516 70.1171C776.337 70.9445 776.248 71.9053 776.248 72.9998Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 109.386H775.124V107.292H779.415V94.96L775.418 97.1576L774.6 95.2444L779.914 92.4134H781.88V107.292H785.61V109.386Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 137.254H775.124V135.16H779.415V122.828L775.418 125.025L774.6 123.112L779.914 120.281H781.88V135.16H785.61V137.254Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 165.122H775.124V163.028H779.415V150.696L775.418 152.893L774.6 150.98L779.914 148.149H781.88V163.028H785.61V165.122Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 184.549C786.134 185.841 786.006 187.022 785.75 188.091C785.495 189.151 785.108 190.064 784.588 190.831C784.069 191.589 783.422 192.18 782.647 192.602C781.88 193.016 780.978 193.223 779.939 193.223C779.045 193.223 778.232 193.054 777.5 192.718C776.767 192.374 776.141 191.848 775.622 191.141C775.103 190.435 774.702 189.534 774.421 188.44C774.14 187.345 774 186.048 774 184.549C774 183.256 774.128 182.075 774.383 181.007C774.647 179.938 775.035 179.024 775.545 178.266C776.065 177.499 776.712 176.909 777.487 176.495C778.262 176.073 779.164 175.862 780.195 175.862C781.089 175.862 781.902 176.034 782.634 176.379C783.366 176.715 783.992 177.24 784.512 177.956C785.031 178.662 785.431 179.563 785.712 180.658C785.993 181.752 786.134 183.049 786.134 184.549ZM783.886 184.626C783.886 184.333 783.873 184.044 783.847 183.76C783.83 183.467 783.809 183.183 783.784 182.907L776.72 188.22C776.848 188.668 777.01 189.082 777.206 189.461C777.402 189.84 777.636 190.167 777.908 190.443C778.189 190.71 778.504 190.922 778.853 191.077C779.211 191.223 779.616 191.296 780.067 191.296C780.646 191.296 781.169 191.154 781.638 190.87C782.115 190.585 782.519 190.163 782.851 189.603C783.183 189.043 783.439 188.349 783.617 187.522C783.796 186.686 783.886 185.721 783.886 184.626ZM776.248 184.471C776.248 184.738 776.248 185.005 776.248 185.272C776.256 185.54 776.273 185.798 776.299 186.048L783.362 180.761C783.234 180.321 783.073 179.921 782.877 179.559C782.681 179.197 782.447 178.887 782.174 178.628C781.902 178.361 781.591 178.154 781.242 178.007C780.893 177.861 780.501 177.788 780.067 177.788C779.488 177.788 778.96 177.93 778.483 178.214C778.015 178.499 777.615 178.925 777.282 179.494C776.95 180.054 776.695 180.752 776.516 181.588C776.337 182.416 776.248 183.376 776.248 184.471Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 220.858H775.124V218.763H779.415V206.431L775.418 208.629L774.6 206.716L779.914 203.885H781.88V218.763H785.61V220.858Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 240.284C786.134 241.577 786.006 242.757 785.75 243.826C785.495 244.886 785.108 245.8 784.588 246.567C784.069 247.325 783.422 247.915 782.647 248.338C781.88 248.751 780.978 248.958 779.939 248.958C779.045 248.958 778.232 248.79 777.5 248.454C776.767 248.109 776.141 247.583 775.622 246.877C775.103 246.17 774.702 245.27 774.421 244.175C774.14 243.081 774 241.784 774 240.284C774 238.991 774.128 237.811 774.383 236.742C774.647 235.673 775.035 234.76 775.545 234.002C776.065 233.235 776.712 232.644 777.487 232.231C778.262 231.808 779.164 231.597 780.195 231.597C781.089 231.597 781.902 231.77 782.634 232.114C783.366 232.45 783.992 232.976 784.512 233.691C785.031 234.398 785.431 235.299 785.712 236.393C785.993 237.488 786.134 238.785 786.134 240.284ZM783.886 240.362C783.886 240.069 783.873 239.78 783.847 239.496C783.83 239.203 783.809 238.918 783.784 238.642L776.72 243.955C776.848 244.403 777.01 244.817 777.206 245.196C777.402 245.576 777.636 245.903 777.908 246.179C778.189 246.446 778.504 246.657 778.853 246.812C779.211 246.959 779.616 247.032 780.067 247.032C780.646 247.032 781.169 246.89 781.638 246.605C782.115 246.321 782.519 245.899 782.851 245.339C783.183 244.778 783.439 244.085 783.617 243.257C783.796 242.421 783.886 241.456 783.886 240.362ZM776.248 240.207C776.248 240.474 776.248 240.741 776.248 241.008C776.256 241.275 776.273 241.534 776.299 241.784L783.362 236.496C783.234 236.057 783.073 235.656 782.877 235.294C782.681 234.932 782.447 234.622 782.174 234.364C781.902 234.096 781.591 233.89 781.242 233.743C780.893 233.597 780.501 233.523 780.067 233.523C779.488 233.523 778.96 233.665 778.483 233.95C778.015 234.234 777.615 234.661 777.282 235.23C776.95 235.79 776.695 236.488 776.516 237.324C776.337 238.151 776.248 239.112 776.248 240.207Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M786.134 268.152C786.134 269.445 786.006 270.625 785.75 271.694C785.495 272.754 785.108 273.667 784.588 274.434C784.069 275.193 783.422 275.783 782.647 276.205C781.88 276.619 780.978 276.826 779.939 276.826C779.045 276.826 778.232 276.658 777.5 276.322C776.767 275.977 776.141 275.451 775.622 274.745C775.103 274.038 774.702 273.137 774.421 272.043C774.14 270.948 774 269.651 774 268.152C774 266.859 774.128 265.678 774.383 264.61C774.647 263.541 775.035 262.628 775.545 261.869C776.065 261.102 776.712 260.512 777.487 260.098C778.262 259.676 779.164 259.465 780.195 259.465C781.089 259.465 781.902 259.637 782.634 259.982C783.366 260.318 783.992 260.844 784.512 261.559C785.031 262.266 785.431 263.166 785.712 264.261C785.993 265.355 786.134 266.652 786.134 268.152ZM783.886 268.229C783.886 267.936 783.873 267.648 783.847 267.363C783.83 267.07 783.809 266.786 783.784 266.51L776.72 271.823C776.848 272.271 777.01 272.685 777.206 273.064C777.402 273.443 777.636 273.771 777.908 274.047C778.189 274.314 778.504 274.525 778.853 274.68C779.211 274.826 779.616 274.9 780.067 274.9C780.646 274.9 781.169 274.758 781.638 274.473C782.115 274.189 782.519 273.766 782.851 273.206C783.183 272.646 783.439 271.952 783.617 271.125C783.796 270.289 783.886 269.324 783.886 268.229ZM776.248 268.074C776.248 268.341 776.248 268.609 776.248 268.876C776.256 269.143 776.273 269.401 776.299 269.651L783.362 264.364C783.234 263.925 783.073 263.524 782.877 263.162C782.681 262.8 782.447 262.49 782.174 262.231C781.902 261.964 781.591 261.757 781.242 261.611C780.893 261.464 780.501 261.391 780.067 261.391C779.488 261.391 778.96 261.533 778.483 261.818C778.015 262.102 777.615 262.529 777.282 263.097C776.95 263.658 776.695 264.356 776.516 265.192C776.337 266.019 776.248 266.98 776.248 268.074Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M785.61 304.461H775.124V302.367H779.415V290.034L775.418 292.232L774.6 290.319L779.914 287.488H781.88V302.367H785.61V304.461Z"
        fill="#6BB2EE"
        fillOpacity="0.7"
      />
      <path
        d="M943.36 -50.1635C943.36 -49.1411 943.25 -48.2267 943.031 -47.4202C942.811 -46.6138 942.488 -45.9311 942.061 -45.3723C941.641 -44.8199 941.126 -44.3976 940.518 -44.1055C939.909 -43.8134 939.213 -43.6673 938.429 -43.6673C937.751 -43.6673 937.127 -43.788 936.556 -44.0293C935.985 -44.2706 935.492 -44.6484 935.078 -45.1628C934.664 -45.6835 934.341 -46.3471 934.109 -47.1535C933.877 -47.96 933.761 -48.9252 933.761 -50.0492C933.761 -51.0715 933.871 -51.9859 934.09 -52.7924C934.316 -53.5989 934.639 -54.2783 935.06 -54.8308C935.48 -55.3896 935.997 -55.815 936.612 -56.1071C937.227 -56.3992 937.924 -56.5453 938.702 -56.5453C939.379 -56.5453 940.003 -56.4246 940.574 -56.1833C941.145 -55.942 941.635 -55.5642 942.042 -55.0498C942.457 -54.5355 942.78 -53.8751 943.012 -53.0686C943.244 -52.2622 943.36 -51.2938 943.36 -50.1635ZM936.076 -50.1254C936.076 -49.9984 936.076 -49.8777 936.076 -49.7634C936.076 -49.6554 936.082 -49.5443 936.095 -49.43L940.706 -52.8019C940.487 -53.4306 940.195 -53.8941 939.831 -54.1926C939.473 -54.4974 939.05 -54.6498 938.56 -54.6498C938.209 -54.6498 937.883 -54.5577 937.582 -54.3736C937.281 -54.1958 937.017 -53.9195 936.791 -53.5449C936.572 -53.1702 936.396 -52.7003 936.264 -52.1352C936.139 -51.57 936.076 -50.9001 936.076 -50.1254ZM941.045 -50.0873C941.045 -50.1952 941.042 -50.3032 941.036 -50.4111C941.029 -50.5254 941.026 -50.6334 941.026 -50.735L936.443 -47.3821C936.644 -46.7662 936.926 -46.309 937.29 -46.0105C937.654 -45.712 938.077 -45.5628 938.56 -45.5628C938.912 -45.5628 939.238 -45.6549 939.539 -45.8391C939.847 -46.0232 940.11 -46.2994 940.33 -46.6677C940.556 -47.0424 940.731 -47.5123 940.857 -48.0775C940.982 -48.649 941.045 -49.3189 941.045 -50.0873Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 -22.9856V-25.0525H937.826V-33.0536L935.097 -31.5296L934.344 -33.4251L938.25 -35.5111H940.179V-25.0525H942.88V-22.9856H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 -2.08472V-4.15167H937.826V-12.1528L935.097 -10.6288L934.344 -12.5243L938.25 -14.6103H940.179V-4.15167H942.88V-2.08472H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 12.5391C943.36 13.5614 943.25 14.4758 943.031 15.2823C942.811 16.0888 942.488 16.7714 942.061 17.3302C941.641 17.8827 941.126 18.3049 940.518 18.597C939.909 18.8891 939.213 19.0352 938.429 19.0352C937.751 19.0352 937.127 18.9145 936.556 18.6732C935.985 18.4319 935.492 18.0541 935.078 17.5398C934.664 17.0191 934.341 16.3555 934.109 15.549C933.877 14.7425 933.761 13.7773 933.761 12.6534C933.761 11.631 933.871 10.7166 934.09 9.91013C934.316 9.10367 934.639 8.42421 935.06 7.87176C935.48 7.31295 935.997 6.88749 936.612 6.59539C937.227 6.30328 937.924 6.15723 938.702 6.15723C939.379 6.15723 940.003 6.27789 940.574 6.51919C941.145 6.76049 941.635 7.13832 942.042 7.65268C942.457 8.16703 942.78 8.82744 943.012 9.6339C943.244 10.4404 943.36 11.4088 943.36 12.5391ZM936.076 12.5772C936.076 12.7042 936.076 12.8248 936.076 12.9391C936.076 13.0471 936.082 13.1582 936.095 13.2725L940.706 9.9006C940.487 9.27195 940.195 8.80839 939.831 8.50994C939.473 8.20513 939.05 8.05273 938.56 8.05273C938.209 8.05273 937.883 8.14481 937.582 8.32896C937.281 8.50676 937.017 8.78299 936.791 9.15765C936.572 9.5323 936.396 10.0022 936.264 10.5674C936.139 11.1325 936.076 11.8025 936.076 12.5772ZM941.045 12.6153C941.045 12.5073 941.042 12.3994 941.036 12.2914C941.029 12.1771 941.026 12.0692 941.026 11.9676L936.443 15.3204C936.644 15.9364 936.926 16.3936 937.29 16.692C937.654 16.9905 938.077 17.1397 938.56 17.1397C938.912 17.1397 939.238 17.0476 939.539 16.8635C939.847 16.6793 940.11 16.4031 940.33 16.0348C940.556 15.6601 940.731 15.1902 940.857 14.6251C940.982 14.0536 941.045 13.3836 941.045 12.6153Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 39.717V37.65H937.826V29.6489L935.097 31.1729L934.344 29.2774L938.25 27.1914H940.179V37.65H942.88V39.717H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 54.3407C943.36 55.3631 943.25 56.2775 943.031 57.084C942.811 57.8904 942.488 58.5731 942.061 59.1319C941.641 59.6843 941.126 60.1066 940.518 60.3987C939.909 60.6908 939.213 60.8369 938.429 60.8369C937.751 60.8369 937.127 60.7162 936.556 60.4749C935.985 60.2336 935.492 59.8558 935.078 59.3414C934.664 58.8207 934.341 58.1571 934.109 57.3507C933.877 56.5442 933.761 55.579 933.761 54.455C933.761 53.4327 933.871 52.5183 934.09 51.7118C934.316 50.9053 934.639 50.2259 935.06 49.6734C935.48 49.1146 935.997 48.6892 936.612 48.3971C937.227 48.105 937.924 47.9589 938.702 47.9589C939.379 47.9589 940.003 48.0796 940.574 48.3209C941.145 48.5622 941.635 48.94 942.042 49.4544C942.457 49.9687 942.78 50.6291 943.012 51.4356C943.244 52.242 943.36 53.2104 943.36 54.3407ZM936.076 54.3788C936.076 54.5058 936.076 54.6265 936.076 54.7408C936.076 54.8488 936.082 54.9599 936.095 55.0742L940.706 51.7023C940.487 51.0736 940.195 50.6101 939.831 50.3116C939.473 50.0068 939.05 49.8544 938.56 49.8544C938.209 49.8544 937.883 49.9465 937.582 50.1306C937.281 50.3084 937.017 50.5847 936.791 50.9593C936.572 51.334 936.396 51.8039 936.264 52.369C936.139 52.9342 936.076 53.6041 936.076 54.3788ZM941.045 54.4169C941.045 54.309 941.042 54.201 941.036 54.0931C941.029 53.9788 941.026 53.8708 941.026 53.7692L936.443 57.1221C936.644 57.738 936.926 58.1952 937.29 58.4937C937.654 58.7922 938.077 58.9414 938.56 58.9414C938.912 58.9414 939.238 58.8493 939.539 58.6652C939.847 58.481 940.11 58.2048 940.33 57.8365C940.556 57.4618 940.731 56.9919 940.857 56.4268C940.982 55.8552 941.045 55.1853 941.045 54.4169Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 75.2416C943.36 76.2639 943.25 77.1784 943.031 77.9848C942.811 78.7913 942.488 79.4739 942.061 80.0327C941.641 80.5852 941.126 81.0075 940.518 81.2996C939.909 81.5917 939.213 81.7377 938.429 81.7377C937.751 81.7377 937.127 81.6171 936.556 81.3758C935.985 81.1345 935.492 80.7566 935.078 80.2423C934.664 79.7216 934.341 79.058 934.109 78.2515C933.877 77.4451 933.761 76.4799 933.761 75.3559C933.761 74.3335 933.871 73.4191 934.09 72.6127C934.316 71.8062 934.639 71.1267 935.06 70.5743C935.48 70.0155 935.997 69.59 936.612 69.2979C937.227 69.0058 937.924 68.8598 938.702 68.8598C939.379 68.8598 940.003 68.9804 940.574 69.2217C941.145 69.463 941.635 69.8408 942.042 70.3552C942.457 70.8696 942.78 71.53 943.012 72.3364C943.244 73.1429 943.36 74.1113 943.36 75.2416ZM936.076 75.2797C936.076 75.4067 936.076 75.5273 936.076 75.6416C936.076 75.7496 936.082 75.8607 936.095 75.975L940.706 72.6031C940.487 71.9745 940.195 71.5109 939.831 71.2125C939.473 70.9077 939.05 70.7552 938.56 70.7552C938.209 70.7552 937.883 70.8473 937.582 71.0315C937.281 71.2093 937.017 71.4855 936.791 71.8602C936.572 72.2348 936.396 72.7047 936.264 73.2699C936.139 73.835 936.076 74.505 936.076 75.2797ZM941.045 75.3178C941.045 75.2098 941.042 75.1019 941.036 74.9939C941.029 74.8796 941.026 74.7717 941.026 74.6701L936.443 78.0229C936.644 78.6389 936.926 79.0961 937.29 79.3945C937.654 79.693 938.077 79.8422 938.56 79.8422C938.912 79.8422 939.238 79.7501 939.539 79.566C939.847 79.3818 940.11 79.1056 940.33 78.7373C940.556 78.3627 940.731 77.8927 940.857 77.3276C940.982 76.7561 941.045 76.0862 941.045 75.3178Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 96.1424C943.36 97.1648 943.25 98.0792 943.031 98.8857C942.811 99.6921 942.488 100.375 942.061 100.934C941.641 101.486 941.126 101.908 940.518 102.2C939.909 102.493 939.213 102.639 938.429 102.639C937.751 102.639 937.127 102.518 936.556 102.277C935.985 102.035 935.492 101.657 935.078 101.143C934.664 100.622 934.341 99.9588 934.109 99.1524C933.877 98.3459 933.761 97.3807 933.761 96.2567C933.761 95.2344 933.871 94.3199 934.09 93.5135C934.316 92.707 934.639 92.0276 935.06 91.4751C935.48 90.9163 935.997 90.4909 936.612 90.1987C937.227 89.9066 937.924 89.7606 938.702 89.7606C939.379 89.7606 940.003 89.8812 940.574 90.1225C941.145 90.3638 941.635 90.7417 942.042 91.256C942.457 91.7704 942.78 92.4308 943.012 93.2373C943.244 94.0437 943.36 95.0121 943.36 96.1424ZM936.076 96.1805C936.076 96.3075 936.076 96.4282 936.076 96.5425C936.076 96.6504 936.082 96.7616 936.095 96.8759L940.706 93.504C940.487 92.8753 940.195 92.4118 939.831 92.1133C939.473 91.8085 939.05 91.6561 938.56 91.6561C938.209 91.6561 937.883 91.7482 937.582 91.9323C937.281 92.1101 937.017 92.3864 936.791 92.761C936.572 93.1357 936.396 93.6056 936.264 94.1707C936.139 94.7359 936.076 95.4058 936.076 96.1805ZM941.045 96.2186C941.045 96.1107 941.042 96.0027 941.036 95.8948C941.029 95.7805 941.026 95.6725 941.026 95.5709L936.443 98.9238C936.644 99.5397 936.926 99.9969 937.29 100.295C937.654 100.594 938.077 100.743 938.56 100.743C938.912 100.743 939.238 100.651 939.539 100.467C939.847 100.283 940.11 100.006 940.33 99.6381C940.556 99.2635 940.731 98.7936 940.857 98.2284C940.982 97.6569 941.045 96.987 941.045 96.2186Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 123.32V121.253H937.826V113.252L935.097 114.776L934.344 112.881L938.25 110.795H940.179V121.253H942.88V123.32H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 144.221V142.154H937.826V134.153L935.097 135.677L934.344 133.782L938.25 131.696H940.179V142.154H942.88V144.221H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 158.845C943.36 159.867 943.25 160.782 943.031 161.588C942.811 162.395 942.488 163.077 942.061 163.636C941.641 164.189 941.126 164.611 940.518 164.903C939.909 165.195 939.213 165.341 938.429 165.341C937.751 165.341 937.127 165.22 936.556 164.979C935.985 164.738 935.492 164.36 935.078 163.846C934.664 163.325 934.341 162.661 934.109 161.855C933.877 161.048 933.761 160.083 933.761 158.959C933.761 157.937 933.871 157.022 934.09 156.216C934.316 155.41 934.639 154.73 935.06 154.178C935.48 153.619 935.997 153.193 936.612 152.901C937.227 152.609 937.924 152.463 938.702 152.463C939.379 152.463 940.003 152.584 940.574 152.825C941.145 153.066 941.635 153.444 942.042 153.959C942.457 154.473 942.78 155.133 943.012 155.94C943.244 156.746 943.36 157.715 943.36 158.845ZM936.076 158.883C936.076 159.01 936.076 159.131 936.076 159.245C936.076 159.353 936.082 159.464 936.095 159.578L940.706 156.206C940.487 155.578 940.195 155.114 939.831 154.816C939.473 154.511 939.05 154.359 938.56 154.359C938.209 154.359 937.883 154.451 937.582 154.635C937.281 154.813 937.017 155.089 936.791 155.464C936.572 155.838 936.396 156.308 936.264 156.873C936.139 157.438 936.076 158.108 936.076 158.883ZM941.045 158.921C941.045 158.813 941.042 158.705 941.036 158.597C941.029 158.483 941.026 158.375 941.026 158.273L936.443 161.626C936.644 162.242 936.926 162.699 937.29 162.998C937.654 163.296 938.077 163.446 938.56 163.446C938.912 163.446 939.238 163.354 939.539 163.169C939.847 162.985 940.11 162.709 940.33 162.341C940.556 161.966 940.731 161.496 940.857 160.931C940.982 160.359 941.045 159.689 941.045 158.921Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 186.023V183.956H937.826V175.955L935.097 177.479L934.344 175.583L938.25 173.497H940.179V183.956H942.88V186.023H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 206.924V204.857H937.826V196.856L935.097 198.38L934.344 196.484L938.25 194.398H940.179V204.857H942.88V206.924H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 221.547C943.36 222.57 943.25 223.484 943.031 224.291C942.811 225.097 942.488 225.78 942.061 226.339C941.641 226.891 941.126 227.313 940.518 227.605C939.909 227.898 939.213 228.044 938.429 228.044C937.751 228.044 937.127 227.923 936.556 227.682C935.985 227.44 935.492 227.062 935.078 226.548C934.664 226.027 934.341 225.364 934.109 224.557C933.877 223.751 933.761 222.786 933.761 221.662C933.761 220.639 933.871 219.725 934.09 218.919C934.316 218.112 934.639 217.433 935.06 216.88C935.48 216.321 935.997 215.896 936.612 215.604C937.227 215.312 937.924 215.166 938.702 215.166C939.379 215.166 940.003 215.286 940.574 215.528C941.145 215.769 941.635 216.147 942.042 216.661C942.457 217.175 942.78 217.836 943.012 218.642C943.244 219.449 943.36 220.417 943.36 221.547ZM936.076 221.586C936.076 221.713 936.076 221.833 936.076 221.948C936.076 222.055 936.082 222.167 936.095 222.281L940.706 218.909C940.487 218.28 940.195 217.817 939.831 217.518C939.473 217.214 939.05 217.061 938.56 217.061C938.209 217.061 937.883 217.153 937.582 217.337C937.281 217.515 937.017 217.791 936.791 218.166C936.572 218.541 936.396 219.011 936.264 219.576C936.139 220.141 936.076 220.811 936.076 221.586ZM941.045 221.624C941.045 221.516 941.042 221.408 941.036 221.3C941.029 221.186 941.026 221.078 941.026 220.976L936.443 224.329C936.644 224.945 936.926 225.402 937.29 225.7C937.654 225.999 938.077 226.148 938.56 226.148C938.912 226.148 939.238 226.056 939.539 225.872C939.847 225.688 940.11 225.411 940.33 225.043C940.556 224.669 940.731 224.199 940.857 223.633C940.982 223.062 941.045 222.392 941.045 221.624Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 248.725V246.658H937.826V238.657L935.097 240.181L934.344 238.286L938.25 236.2H940.179V246.658H942.88V248.725H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 269.626V267.559H937.826V259.558L935.097 261.082L934.344 259.187L938.25 257.101H940.179V267.559H942.88V269.626H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 290.527V288.46H937.826V280.459L935.097 281.983L934.344 280.087L938.25 278.001H940.179V288.46H942.88V290.527H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 305.151C943.36 306.173 943.25 307.088 943.031 307.894C942.811 308.701 942.488 309.383 942.061 309.942C941.641 310.494 941.126 310.917 940.518 311.209C939.909 311.501 939.213 311.647 938.429 311.647C937.751 311.647 937.127 311.526 936.556 311.285C935.985 311.044 935.492 310.666 935.078 310.152C934.664 309.631 934.341 308.967 934.109 308.161C933.877 307.354 933.761 306.389 933.761 305.265C933.761 304.243 933.871 303.328 934.09 302.522C934.316 301.715 934.639 301.036 935.06 300.484C935.48 299.925 935.997 299.499 936.612 299.207C937.227 298.915 937.924 298.769 938.702 298.769C939.379 298.769 940.003 298.89 940.574 299.131C941.145 299.372 941.635 299.75 942.042 300.264C942.457 300.779 942.78 301.439 943.012 302.246C943.244 303.052 943.36 304.021 943.36 305.151ZM936.076 305.189C936.076 305.316 936.076 305.437 936.076 305.551C936.076 305.659 936.082 305.77 936.095 305.884L940.706 302.512C940.487 301.884 940.195 301.42 939.831 301.122C939.473 300.817 939.05 300.664 938.56 300.664C938.209 300.664 937.883 300.757 937.582 300.941C937.281 301.119 937.017 301.395 936.791 301.769C936.572 302.144 936.396 302.614 936.264 303.179C936.139 303.744 936.076 304.414 936.076 305.189ZM941.045 305.227C941.045 305.119 941.042 305.011 941.036 304.903C941.029 304.789 941.026 304.681 941.026 304.579L936.443 307.932C936.644 308.548 936.926 309.005 937.29 309.304C937.654 309.602 938.077 309.751 938.56 309.751C938.912 309.751 939.238 309.659 939.539 309.475C939.847 309.291 940.11 309.015 940.33 308.647C940.556 308.272 940.731 307.802 940.857 307.237C940.982 306.665 941.045 305.995 941.045 305.227Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M943.36 326.052C943.36 327.074 943.25 327.988 943.031 328.795C942.811 329.601 942.488 330.284 942.061 330.843C941.641 331.395 941.126 331.818 940.518 332.11C939.909 332.402 939.213 332.548 938.429 332.548C937.751 332.548 937.127 332.427 936.556 332.186C935.985 331.945 935.492 331.567 935.078 331.052C934.664 330.532 934.341 329.868 934.109 329.062C933.877 328.255 933.761 327.29 933.761 326.166C933.761 325.144 933.871 324.229 934.09 323.423C934.316 322.616 934.639 321.937 935.06 321.384C935.48 320.826 935.997 320.4 936.612 320.108C937.227 319.816 937.924 319.67 938.702 319.67C939.379 319.67 940.003 319.79 940.574 320.032C941.145 320.273 941.635 320.651 942.042 321.165C942.457 321.68 942.78 322.34 943.012 323.147C943.244 323.953 943.36 324.921 943.36 326.052ZM936.076 326.09C936.076 326.217 936.076 326.337 936.076 326.452C936.076 326.56 936.082 326.671 936.095 326.785L940.706 323.413C940.487 322.785 940.195 322.321 939.831 322.023C939.473 321.718 939.05 321.565 938.56 321.565C938.209 321.565 937.883 321.657 937.582 321.842C937.281 322.019 937.017 322.296 936.791 322.67C936.572 323.045 936.396 323.515 936.264 324.08C936.139 324.645 936.076 325.315 936.076 326.09ZM941.045 326.128C941.045 326.02 941.042 325.912 941.036 325.804C941.029 325.69 941.026 325.582 941.026 325.48L936.443 328.833C936.644 329.449 936.926 329.906 937.29 330.205C937.654 330.503 938.077 330.652 938.56 330.652C938.912 330.652 939.238 330.56 939.539 330.376C939.847 330.192 940.11 329.916 940.33 329.547C940.556 329.173 940.731 328.703 940.857 328.138C940.982 327.566 941.045 326.896 941.045 326.128Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M934.692 353.23V351.163H937.826V343.161L935.097 344.686L934.344 342.79L938.25 340.704H940.179V351.163H942.88V353.23H934.692Z"
        fill="#6BB2EE"
        fillOpacity="0.2"
      />
      <path
        d="M965.387 245.235C965.387 246.257 965.277 247.172 965.058 247.978C964.838 248.785 964.515 249.467 964.088 250.026C963.668 250.579 963.153 251.001 962.545 251.293C961.936 251.585 961.24 251.731 960.456 251.731C959.778 251.731 959.154 251.611 958.583 251.369C958.012 251.128 957.519 250.75 957.105 250.236C956.691 249.715 956.368 249.052 956.136 248.245C955.904 247.439 955.788 246.473 955.788 245.349C955.788 244.327 955.898 243.413 956.117 242.606C956.343 241.8 956.666 241.12 957.086 240.568C957.507 240.009 958.024 239.584 958.639 239.291C959.254 238.999 959.951 238.853 960.728 238.853C961.406 238.853 962.03 238.974 962.601 239.215C963.172 239.457 963.662 239.834 964.069 240.349C964.483 240.863 964.807 241.524 965.039 242.33C965.271 243.136 965.387 244.105 965.387 245.235ZM958.103 245.273C958.103 245.4 958.103 245.521 958.103 245.635C958.103 245.743 958.109 245.854 958.122 245.969L962.733 242.597C962.513 241.968 962.222 241.504 961.858 241.206C961.5 240.901 961.077 240.749 960.587 240.749C960.236 240.749 959.91 240.841 959.609 241.025C959.307 241.203 959.044 241.479 958.818 241.854C958.598 242.228 958.423 242.698 958.291 243.263C958.166 243.829 958.103 244.499 958.103 245.273ZM963.072 245.311C963.072 245.203 963.069 245.095 963.062 244.987C963.056 244.873 963.053 244.765 963.053 244.664L958.47 248.016C958.671 248.632 958.953 249.09 959.317 249.388C959.681 249.687 960.104 249.836 960.587 249.836C960.939 249.836 961.265 249.744 961.566 249.56C961.873 249.375 962.137 249.099 962.357 248.731C962.582 248.356 962.758 247.886 962.884 247.321C963.009 246.75 963.072 246.08 963.072 245.311Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 272.413V270.346H959.853V262.345L957.124 263.869L956.371 261.973L960.277 259.887H962.206V270.346H964.907V272.413H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 293.314V291.247H959.853V283.246L957.124 284.77L956.371 282.874L960.277 280.788H962.206V291.247H964.907V293.314H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 307.938C965.387 308.96 965.277 309.874 965.058 310.681C964.838 311.487 964.515 312.17 964.088 312.729C963.668 313.281 963.153 313.704 962.545 313.996C961.936 314.288 961.24 314.434 960.456 314.434C959.778 314.434 959.154 314.313 958.583 314.072C958.012 313.831 957.519 313.453 957.105 312.938C956.691 312.418 956.368 311.754 956.136 310.948C955.904 310.141 955.788 309.176 955.788 308.052C955.788 307.03 955.898 306.115 956.117 305.309C956.343 304.502 956.666 303.823 957.086 303.27C957.507 302.712 958.024 302.286 958.639 301.994C959.254 301.702 959.951 301.556 960.728 301.556C961.406 301.556 962.03 301.676 962.601 301.918C963.172 302.159 963.662 302.537 964.069 303.051C964.483 303.566 964.807 304.226 965.039 305.032C965.271 305.839 965.387 306.807 965.387 307.938ZM958.103 307.976C958.103 308.103 958.103 308.223 958.103 308.338C958.103 308.446 958.109 308.557 958.122 308.671L962.733 305.299C962.513 304.671 962.222 304.207 961.858 303.909C961.5 303.604 961.077 303.451 960.587 303.451C960.236 303.451 959.91 303.543 959.609 303.728C959.307 303.905 959.044 304.182 958.818 304.556C958.598 304.931 958.423 305.401 958.291 305.966C958.166 306.531 958.103 307.201 958.103 307.976ZM963.072 308.014C963.072 307.906 963.069 307.798 963.062 307.69C963.056 307.576 963.053 307.468 963.053 307.366L958.47 310.719C958.671 311.335 958.953 311.792 959.317 312.091C959.681 312.389 960.104 312.538 960.587 312.538C960.939 312.538 961.265 312.446 961.566 312.262C961.873 312.078 962.137 311.802 962.357 311.433C962.582 311.059 962.758 310.589 962.884 310.024C963.009 309.452 963.072 308.782 963.072 308.014Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 335.116V333.049H959.853V325.047L957.124 326.572L956.371 324.676L960.277 322.59H962.206V333.049H964.907V335.116H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 349.739C965.387 350.762 965.277 351.676 965.058 352.483C964.838 353.289 964.515 353.972 964.088 354.53C963.668 355.083 963.153 355.505 962.545 355.797C961.936 356.089 961.24 356.235 960.456 356.235C959.778 356.235 959.154 356.115 958.583 355.874C958.012 355.632 957.519 355.254 957.105 354.74C956.691 354.219 956.368 353.556 956.136 352.749C955.904 351.943 955.788 350.978 955.788 349.854C955.788 348.831 955.898 347.917 956.117 347.11C956.343 346.304 956.666 345.624 957.086 345.072C957.507 344.513 958.024 344.088 958.639 343.796C959.254 343.504 959.951 343.357 960.728 343.357C961.406 343.357 962.03 343.478 962.601 343.719C963.172 343.961 963.662 344.339 964.069 344.853C964.483 345.367 964.807 346.028 965.039 346.834C965.271 347.641 965.387 348.609 965.387 349.739ZM958.103 349.777C958.103 349.904 958.103 350.025 958.103 350.139C958.103 350.247 958.109 350.358 958.122 350.473L962.733 347.101C962.513 346.472 962.222 346.009 961.858 345.71C961.5 345.405 961.077 345.253 960.587 345.253C960.236 345.253 959.91 345.345 959.609 345.529C959.307 345.707 959.044 345.983 958.818 346.358C958.598 346.733 958.423 347.202 958.291 347.768C958.166 348.333 958.103 349.003 958.103 349.777ZM963.072 349.816C963.072 349.708 963.069 349.6 963.062 349.492C963.056 349.377 963.053 349.269 963.053 349.168L958.47 352.521C958.671 353.137 958.953 353.594 959.317 353.892C959.681 354.191 960.104 354.34 960.587 354.34C960.939 354.34 961.265 354.248 961.566 354.064C961.873 353.88 962.137 353.603 962.357 353.235C962.582 352.86 962.758 352.39 962.884 351.825C963.009 351.254 963.072 350.584 963.072 349.816Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 370.64C965.387 371.663 965.277 372.577 965.058 373.383C964.838 374.19 964.515 374.872 964.088 375.431C963.668 375.984 963.153 376.406 962.545 376.698C961.936 376.99 961.24 377.136 960.456 377.136C959.778 377.136 959.154 377.016 958.583 376.774C958.012 376.533 957.519 376.155 957.105 375.641C956.691 375.12 956.368 374.457 956.136 373.65C955.904 372.844 955.788 371.878 955.788 370.754C955.788 369.732 955.898 368.818 956.117 368.011C956.343 367.205 956.666 366.525 957.086 365.973C957.507 365.414 958.024 364.989 958.639 364.696C959.254 364.404 959.951 364.258 960.728 364.258C961.406 364.258 962.03 364.379 962.601 364.62C963.172 364.862 963.662 365.239 964.069 365.754C964.483 366.268 964.807 366.929 965.039 367.735C965.271 368.541 965.387 369.51 965.387 370.64ZM958.103 370.678C958.103 370.805 958.103 370.926 958.103 371.04C958.103 371.148 958.109 371.259 958.122 371.374L962.733 368.002C962.513 367.373 962.222 366.909 961.858 366.611C961.5 366.306 961.077 366.154 960.587 366.154C960.236 366.154 959.91 366.246 959.609 366.43C959.307 366.608 959.044 366.884 958.818 367.259C958.598 367.633 958.423 368.103 958.291 368.668C958.166 369.234 958.103 369.904 958.103 370.678ZM963.072 370.716C963.072 370.608 963.069 370.5 963.062 370.393C963.056 370.278 963.053 370.17 963.053 370.069L958.47 373.421C958.671 374.037 958.953 374.495 959.317 374.793C959.681 375.092 960.104 375.241 960.587 375.241C960.939 375.241 961.265 375.149 961.566 374.965C961.873 374.78 962.137 374.504 962.357 374.136C962.582 373.761 962.758 373.291 962.884 372.726C963.009 372.155 963.072 371.485 963.072 370.716Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 391.541C965.387 392.563 965.277 393.478 965.058 394.284C964.838 395.091 964.515 395.773 964.088 396.332C963.668 396.885 963.153 397.307 962.545 397.599C961.936 397.891 961.24 398.037 960.456 398.037C959.778 398.037 959.154 397.916 958.583 397.675C958.012 397.434 957.519 397.056 957.105 396.542C956.691 396.021 956.368 395.357 956.136 394.551C955.904 393.744 955.788 392.779 955.788 391.655C955.788 390.633 955.898 389.719 956.117 388.912C956.343 388.106 956.666 387.426 957.086 386.874C957.507 386.315 958.024 385.889 958.639 385.597C959.254 385.305 959.951 385.159 960.728 385.159C961.406 385.159 962.03 385.28 962.601 385.521C963.172 385.762 963.662 386.14 964.069 386.655C964.483 387.169 964.807 387.829 965.039 388.636C965.271 389.442 965.387 390.411 965.387 391.541ZM958.103 391.579C958.103 391.706 958.103 391.827 958.103 391.941C958.103 392.049 958.109 392.16 958.122 392.274L962.733 388.903C962.513 388.274 962.222 387.81 961.858 387.512C961.5 387.207 961.077 387.055 960.587 387.055C960.236 387.055 959.91 387.147 959.609 387.331C959.307 387.509 959.044 387.785 958.818 388.16C958.598 388.534 958.423 389.004 958.291 389.569C958.166 390.134 958.103 390.804 958.103 391.579ZM963.072 391.617C963.072 391.509 963.069 391.401 963.062 391.293C963.056 391.179 963.053 391.071 963.053 390.969L958.47 394.322C958.671 394.938 958.953 395.396 959.317 395.694C959.681 395.992 960.104 396.142 960.587 396.142C960.939 396.142 961.265 396.05 961.566 395.865C961.873 395.681 962.137 395.405 962.357 395.037C962.582 394.662 962.758 394.192 962.884 393.627C963.009 393.055 963.072 392.386 963.072 391.617Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 418.719V416.652H959.853V408.651L957.124 410.175L956.371 408.279L960.277 406.193H962.206V416.652H964.907V418.719H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 439.62V437.553H959.853V429.552L957.124 431.076L956.371 429.18L960.277 427.094H962.206V437.553H964.907V439.62H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 454.244C965.387 455.266 965.277 456.18 965.058 456.987C964.838 457.793 964.515 458.476 964.088 459.035C963.668 459.587 963.153 460.009 962.545 460.301C961.936 460.594 961.24 460.74 960.456 460.74C959.778 460.74 959.154 460.619 958.583 460.378C958.012 460.136 957.519 459.759 957.105 459.244C956.691 458.723 956.368 458.06 956.136 457.253C955.904 456.447 955.788 455.482 955.788 454.358C955.788 453.335 955.898 452.421 956.117 451.615C956.343 450.808 956.666 450.129 957.086 449.576C957.507 449.017 958.024 448.592 958.639 448.3C959.254 448.008 959.951 447.862 960.728 447.862C961.406 447.862 962.03 447.982 962.601 448.224C963.172 448.465 963.662 448.843 964.069 449.357C964.483 449.871 964.807 450.532 965.039 451.338C965.271 452.145 965.387 453.113 965.387 454.244ZM958.103 454.282C958.103 454.409 958.103 454.529 958.103 454.644C958.103 454.752 958.109 454.863 958.122 454.977L962.733 451.605C962.513 450.976 962.222 450.513 961.858 450.214C961.5 449.91 961.077 449.757 960.587 449.757C960.236 449.757 959.91 449.849 959.609 450.033C959.307 450.211 959.044 450.487 958.818 450.862C958.598 451.237 958.423 451.707 958.291 452.272C958.166 452.837 958.103 453.507 958.103 454.282ZM963.072 454.32C963.072 454.212 963.069 454.104 963.062 453.996C963.056 453.882 963.053 453.774 963.053 453.672L958.47 457.025C958.671 457.641 958.953 458.098 959.317 458.396C959.681 458.695 960.104 458.844 960.587 458.844C960.939 458.844 961.265 458.752 961.566 458.568C961.873 458.384 962.137 458.108 962.357 457.739C962.582 457.365 962.758 456.895 962.884 456.33C963.009 455.758 963.072 455.088 963.072 454.32Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 481.421V479.354H959.853V471.353L957.124 472.877L956.371 470.982L960.277 468.896H962.206V479.354H964.907V481.421H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 502.322V500.255H959.853V492.254L957.124 493.778L956.371 491.883L960.277 489.797H962.206V500.255H964.907V502.322H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 516.946C965.387 517.968 965.277 518.883 965.058 519.689C964.838 520.496 964.515 521.178 964.088 521.737C963.668 522.29 963.153 522.712 962.545 523.004C961.936 523.296 961.24 523.442 960.456 523.442C959.778 523.442 959.154 523.322 958.583 523.08C958.012 522.839 957.519 522.461 957.105 521.947C956.691 521.426 956.368 520.762 956.136 519.956C955.904 519.15 955.788 518.184 955.788 517.06C955.788 516.038 955.898 515.124 956.117 514.317C956.343 513.511 956.666 512.831 957.086 512.279C957.507 511.72 958.024 511.294 958.639 511.002C959.254 510.71 959.951 510.564 960.728 510.564C961.406 510.564 962.03 510.685 962.601 510.926C963.172 511.167 963.662 511.545 964.069 512.06C964.483 512.574 964.807 513.234 965.039 514.041C965.271 514.847 965.387 515.816 965.387 516.946ZM958.103 516.984C958.103 517.111 958.103 517.232 958.103 517.346C958.103 517.454 958.109 517.565 958.122 517.679L962.733 514.308C962.513 513.679 962.222 513.215 961.858 512.917C961.5 512.612 961.077 512.46 960.587 512.46C960.236 512.46 959.91 512.552 959.609 512.736C959.307 512.914 959.044 513.19 958.818 513.565C958.598 513.939 958.423 514.409 958.291 514.974C958.166 515.539 958.103 516.209 958.103 516.984ZM963.072 517.022C963.072 516.914 963.069 516.806 963.062 516.698C963.056 516.584 963.053 516.476 963.053 516.375L958.47 519.727C958.671 520.343 958.953 520.801 959.317 521.099C959.681 521.397 960.104 521.547 960.587 521.547C960.939 521.547 961.265 521.455 961.566 521.27C961.873 521.086 962.137 520.81 962.357 520.442C962.582 520.067 962.758 519.597 962.884 519.032C963.009 518.461 963.072 517.791 963.072 517.022Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 544.124V542.057H959.853V534.056L957.124 535.58L956.371 533.684L960.277 531.598H962.206V542.057H964.907V544.124H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 565.025V562.958H959.853V554.957L957.124 556.481L956.371 554.585L960.277 552.499H962.206V562.958H964.907V565.025H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 585.926V583.859H959.853V575.858L957.124 577.382L956.371 575.486L960.277 573.4H962.206V583.859H964.907V585.926H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 600.549C965.387 601.572 965.277 602.486 965.058 603.293C964.838 604.099 964.515 604.782 964.088 605.341C963.668 605.893 963.153 606.315 962.545 606.607C961.936 606.899 961.24 607.046 960.456 607.046C959.778 607.046 959.154 606.925 958.583 606.684C958.012 606.442 957.519 606.064 957.105 605.55C956.691 605.029 956.368 604.366 956.136 603.559C955.904 602.753 955.788 601.788 955.788 600.664C955.788 599.641 955.898 598.727 956.117 597.92C956.343 597.114 956.666 596.435 957.086 595.882C957.507 595.323 958.024 594.898 958.639 594.606C959.254 594.314 959.951 594.168 960.728 594.168C961.406 594.168 962.03 594.288 962.601 594.53C963.172 594.771 963.662 595.149 964.069 595.663C964.483 596.177 964.807 596.838 965.039 597.644C965.271 598.451 965.387 599.419 965.387 600.549ZM958.103 600.587C958.103 600.714 958.103 600.835 958.103 600.949C958.103 601.057 958.109 601.169 958.122 601.283L962.733 597.911C962.513 597.282 962.222 596.819 961.858 596.52C961.5 596.215 961.077 596.063 960.587 596.063C960.236 596.063 959.91 596.155 959.609 596.339C959.307 596.517 959.044 596.793 958.818 597.168C958.598 597.543 958.423 598.013 958.291 598.578C958.166 599.143 958.103 599.813 958.103 600.587ZM963.072 600.626C963.072 600.518 963.069 600.41 963.062 600.302C963.056 600.187 963.053 600.079 963.053 599.978L958.47 603.331C958.671 603.947 958.953 604.404 959.317 604.702C959.681 605.001 960.104 605.15 960.587 605.15C960.939 605.15 961.265 605.058 961.566 604.874C961.873 604.69 962.137 604.413 962.357 604.045C962.582 603.67 962.758 603.201 962.884 602.635C963.009 602.064 963.072 601.394 963.072 600.626Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M965.387 621.45C965.387 622.473 965.277 623.387 965.058 624.193C964.838 625 964.515 625.683 964.088 626.241C963.668 626.794 963.153 627.216 962.545 627.508C961.936 627.8 961.24 627.946 960.456 627.946C959.778 627.946 959.154 627.826 958.583 627.584C958.012 627.343 957.519 626.965 957.105 626.451C956.691 625.93 956.368 625.267 956.136 624.46C955.904 623.654 955.788 622.688 955.788 621.565C955.788 620.542 955.898 619.628 956.117 618.821C956.343 618.015 956.666 617.335 957.086 616.783C957.507 616.224 958.024 615.799 958.639 615.507C959.254 615.214 959.951 615.068 960.728 615.068C961.406 615.068 962.03 615.189 962.601 615.43C963.172 615.672 963.662 616.049 964.069 616.564C964.483 617.078 964.807 617.739 965.039 618.545C965.271 619.352 965.387 620.32 965.387 621.45ZM958.103 621.488C958.103 621.615 958.103 621.736 958.103 621.85C958.103 621.958 958.109 622.069 958.122 622.184L962.733 618.812C962.513 618.183 962.222 617.72 961.858 617.421C961.5 617.116 961.077 616.964 960.587 616.964C960.236 616.964 959.91 617.056 959.609 617.24C959.307 617.418 959.044 617.694 958.818 618.069C958.598 618.443 958.423 618.913 958.291 619.479C958.166 620.044 958.103 620.714 958.103 621.488ZM963.072 621.526C963.072 621.418 963.069 621.311 963.062 621.203C963.056 621.088 963.053 620.98 963.053 620.879L958.47 624.232C958.671 624.848 958.953 625.305 959.317 625.603C959.681 625.902 960.104 626.051 960.587 626.051C960.939 626.051 961.265 625.959 961.566 625.775C961.873 625.59 962.137 625.314 962.357 624.946C962.582 624.571 962.758 624.101 962.884 623.536C963.009 622.965 963.072 622.295 963.072 621.526Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
      <path
        d="M956.719 648.628V646.561H959.853V638.56L957.124 640.084L956.371 638.189L960.277 636.103H962.206V646.561H964.907V648.628H956.719Z"
        fill="#6BB2EE"
        fillOpacity="0.3"
      />
    </svg>

  );
}
