import { createContext, useContext } from 'react';

import { SidebarViewModel } from '.';

export const ViewModelContext = createContext<
  SidebarViewModel | undefined
>(undefined);

export const useSidebarViewModel = () => {
  const vm = useContext(ViewModelContext);

  if (!vm) throw new Error();

  return vm;
};
