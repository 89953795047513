import { matchPath } from 'react-router-dom';

import { route } from 'application/routes/route';

export type NavbarItemKey = 'posts' | 'themes' | 'tasks' | 'queue' | 'postModeration' | 'users' | 'rating';

export const routePatternsInClass: Record<NavbarItemKey, string> = {
  posts: route.posts(),
  themes: route.themes(),
  tasks: route.tasks(),
  queue: route.queue(),
  postModeration: route.postModeration(),
  users: route.users(),
  rating: route.rating(),
};

export const routesInClass: Record<NavbarItemKey, (id: string) => string> = {
  posts: (id: string) => route.posts(id),
  themes: (id: string) => route.themes(id),
  tasks: (id: string) => route.tasks(id),
  queue: (id: string) => route.queue(id),
  postModeration: (id: string) => route.postModeration(id),
  users: (id: string) => route.users(id),
  rating: (id: string) => route.rating(id),
};

export const getNavbarItemKeyByRoute = (route: string) => (
  Object.entries(routePatternsInClass).find(([_, pattern]) => matchPath(pattern, route))?.[0]
);

export const isRouteInClass = (pathname: string) => (
  Object.values(routePatternsInClass).some((pattern) => matchPath(pattern, pathname))
);

// HeaderClass and NavbarClass are used outside of Route, so useParams returns empty object
export const getClassId = (pathname: string) => {
  const matchesClasses = pathname.match(/(?<=\/classes\/)[a-z0-9-]+/);
  const matchesClassManager = pathname.match(/(?<=\/class-manager\/)[a-z0-9-]+/);

  if (matchesClasses?.length) return matchesClasses[0];

  if (matchesClassManager?.length) return matchesClassManager[0];

  return undefined;
};
