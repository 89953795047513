import React from 'react';
import { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassRoleByRoute } from 'application/authentication/useClassRoleByRoute';

import CheckWaiting from 'components/ui/atoms/icons/new/CheckWaiting';
import Paragraph from 'components/ui/atoms/typography/Paragraph';
import RatingIcon from 'components/ui/atoms/icons/new/Rating';
import PostIcon from 'components/ui/atoms/icons/new/Post';
import TasksIcon from 'components/ui/atoms/icons/new/Tasks';
import QueueIcon from 'components/ui/atoms/icons/new/Queue';
import PostModerationIcon from 'components/ui/atoms/icons/new/PostModeration';
import UsersIcon from 'components/ui/atoms/icons/new/Users';
import Flex from 'components/ui/atoms/flex/Flex';

import { ns } from 'utils/locales';

export const useItems = () => {
  const { t } = useTranslation([ns.common]);
  const { isStudent } = useClassRoleByRoute();

  const items: TabsProps['items'] = [
    {
      key: 'posts',
      label: (
        <Flex align="center" gap={8}>
          <PostIcon />
          <Paragraph weight="medium">
            {t(`${ns.common}:classSubLinks.posts`)}
          </Paragraph>
        </Flex>
      ),
    },
    {
      key: 'tasks',
      label: (
        <Flex align="center" gap={8}>
          <TasksIcon />
          <Paragraph weight="medium">
            {t(`${ns.common}:classSubLinks.tasks`)}
          </Paragraph>
        </Flex>
      ),
    },
    {
      key: 'queue',
      label: (
        <Flex align="center" gap={8}>
          <QueueIcon />
          <Paragraph weight="medium">
            {t(`${ns.common}:classSubLinks.queue`)}
          </Paragraph>
        </Flex>
      ),
    },
    {
      key: 'postModeration',
      label: (
        <Flex align="center" gap={8}>
          {isStudent ? <CheckWaiting /> : <PostModerationIcon />}
          <Paragraph weight="medium">
            {isStudent
              ? t(`${ns.common}:classSubLinks.awaitingSolutions`)
              : t(`${ns.common}:classSubLinks.postModeration`)}
          </Paragraph>
        </Flex>
      ),
    },
    {
      key: 'users',
      label: (
        <Flex align="center" gap={8}>
          <UsersIcon />
          <Paragraph weight="medium">
            {t(`${ns.common}:classSubLinks.users`)}
          </Paragraph>
        </Flex>
      ),
    },
    {
      key: 'rating',
      label: (
        <Flex align="center" gap={8}>
          <RatingIcon />
          <Paragraph weight="medium">
            {t(`${ns.common}:classSubLinks.rating`)}
          </Paragraph>
        </Flex>
      ),
    },
  ];

  return items;
};
