import React from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import { observer } from 'mobx-react-lite';

import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-pascal';
import 'ace-builds/src-noconflict/mode-r';

import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-github_dark';
import 'ace-builds/src-noconflict/ext-language_tools';

import { CodeEditorMode } from 'domain/entities/programmingLanguage';

import { useStore } from 'storesMobx/MobxStoreProvider';

import classes from './CodeEditor.module.scss';

export interface CodeEditorProps extends IAceEditorProps {
  id?: string;
  pl?: string;
  mode?: CodeEditorMode;
  heightFitContent?: boolean;
}

const CodeEditor: React.FC<CodeEditorProps> = (props) => {
  const { mode = CodeEditorMode.C_CPP, heightFitContent, ...restProps } = props;

  const { isDarkMode } = useStore().themeStore;

  return (
    <AceEditor
      mode={mode}
      theme={isDarkMode ? 'github_dark' : 'github'}
      editorProps={{ $blockScrolling: true }}
      fontSize={16}
      className={classes.code_editor}
      {...restProps}
      onLoad={(editor) => {
        // @ts-ignore
        editor.textInput.getElement().id = props.id;
        editor.renderer.setPadding(12);
        editor.renderer.setScrollMargin(24, 24, 0, 0);

        if (heightFitContent) {
          editor.setOptions({
            maxLines: editor.session.getLength(),
          });
        }
      }}
    />
  );
};

export default observer(CodeEditor);
