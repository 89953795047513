import React, { useEffect } from 'react';
import { Segmented } from 'antd';
import { SegmentedLabeledOption } from 'antd/es/segmented';
import { observer } from 'mobx-react-lite';

import Flex from 'components/ui/atoms/flex/Flex';
import RuFlag from 'components/ui/atoms/icons/flags/RuFlag';
import EnFlag from 'components/ui/atoms/icons/flags/EnFlag';

import { ILanguage } from 'domain/entities/language';

import { useStore } from 'storesMobx/MobxStoreProvider';

interface SegmentedLocalizationProps {
  value?: string;
  defaultValue?: string;
  localizations?: string[];
  onChange?: (value: string) => void;
}

const SegmentedLocalization: React.FC<SegmentedLocalizationProps> = ({
  value,
  defaultValue = 'ru',
  localizations,
  onChange,
}) => {
  const { commonDataStore } = useStore();

  let { languages } = commonDataStore;

  if (localizations) {
    languages = languages.filter((l) => localizations.includes(l.iso));
  }

  useEffect(() => {
    onChange?.('ru');
  }, []);

  return (
    <Segmented
      value={value}
      defaultValue={defaultValue}
      options={languages.map((l) => getOption(l))}
      onChange={(value) => onChange?.(value as string)}
    />
  );
};

const getOption = (language: ILanguage): SegmentedLabeledOption => {
  const icon = getLocalizationIcon(language.iso);

  return {
    value: language.iso,
    label: (
      <Flex align="center" gap={4}>
        <>
          {icon}
          {language.name}
        </>
      </Flex>
    ),
  };
};

export const getLocalizationIcon = (iso: string) => {
  switch (iso) {
    case 'ru':
      return <RuFlag size={30} />;
    case 'en':
      return <EnFlag size={30} />;
    default:
      return null;
  }
};

export default observer(SegmentedLocalization);
