import { sectionRepository } from 'domain/repositories/api';
import { IModuleDataSource } from 'domain/repositories/api/module/dataSource/IModuleDataSource';
import {
  ICreateModulePayload,
  IDeleteModulePayload,
  IForkModulePayload,
  IGetModuleOverviewPayload, IGetModulePayload,
  IGetModuleSectionsPayload,
  IModuleRepository, IUpdateModulePayload,
} from 'domain/repositories/api/module/IModuleRepository';

export class ModuleRepository implements IModuleRepository {
  public constructor(private _ds: IModuleDataSource) {}

  public getModule = (p: IGetModulePayload) => this._ds.getModule(p);

  public createModule = (p: ICreateModulePayload) => this._ds.createModule(p);

  public updateModule = (p: IUpdateModulePayload) => this._ds.updateModule(p);

  public deleteModule = (p: IDeleteModulePayload) => this._ds.deleteModule(p);

  public forkModule = (p: IForkModulePayload) => this._ds.forkModule(p);

  public getSections = async ({ studyModuleId }: IGetModuleSectionsPayload) => {
    const response = await this._ds.getSections({ studyModuleId });

    return { ...response, class_sections: response.class_sections ?? [] };
  };

  public getModuleOverview = async ({ id }: IGetModuleOverviewPayload) => {
    const { class_study_module } = await this._ds.getModule({ id });

    const { class_sections } = await this._ds.getSections({ studyModuleId: id });

    const sectionsWithTasks = await Promise.all(class_sections?.map(async (section) => {
      const { classTasks } = await sectionRepository.getTasks({
        sectionId: section.id,
        limit: 1000,
      });

      return { ...section, tasks: classTasks };
    }) ?? []);

    return {
      ...class_study_module,
      sections: sectionsWithTasks,
    };
  };
}
