import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import ClassStructure from 'components/ui/organisms/classStructure/ClassStructure';
import SegmentedLocalization from 'components/ui/atoms/segmentedLocalization/SegmentedLocalization';
import { useEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel/context';
import TwoColumns from 'components/ui/layout/twoColumns/TwoColumns';

import { getFieldFromTranslations } from 'utils/locales/getFieldFromTranslations';

const { Paragraph, Title } = Typography;

const EntityDrawerSubjectProxyContent: React.FC = () => {
  const { subjectProxyViewModel } = useEntityDrawerViewModel();

  const {
    subject,
    localization,
    modules,
    setLocalization,
  } = subjectProxyViewModel;

  const classStructureData = useMemo(
    () => modules
      ?.map((module) => ({
        ...module,
        classSections: module.sections
          .map((section) => ({
            ...section,
            classTasks: section.tasks,
          })),
      })),
    [modules],
  );

  if (!subject) return null;

  return (
    <TwoColumns>
      <div>
        <Title level={5}>{getFieldFromTranslations('name', subject.translations ?? [], localization)}</Title>
        <Paragraph>{getFieldFromTranslations('description', subject.translations ?? [], localization)}</Paragraph>
        <ClassStructure data={classStructureData ?? []} />
      </div>
      <SegmentedLocalization onChange={setLocalization} />
    </TwoColumns>
  );
};

export default observer(EntityDrawerSubjectProxyContent);
