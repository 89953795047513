import { getPrioritizeTranslation } from 'utils/locales/getPrioritizeTranslation';
import { EM_DASH } from 'utils/constants/symbols';

interface ITranslation {
  iso: string;
}

export const getFieldFromTranslations = (
  field: string,
  translations: ITranslation[] | null,
  desiredIso: string = 'ru',
): string => {
  if (!translations || !translations.length) return EM_DASH;

  const desiredTranslation = translations?.find((t) => t.iso === desiredIso);

  if (desiredTranslation) {
    return field in desiredTranslation
      ? (desiredTranslation as unknown as Record<string, string>)[field]
      : EM_DASH;
  }

  const secondaryIso = getPrioritizeTranslation(translations.filter((t) => t.iso !== desiredIso));

  const secondaryTranslation = translations.find((t) => t.iso === secondaryIso)!;

  const value = (secondaryTranslation as unknown as Record<string, string>)[field];

  return value
    ? `${value} [${secondaryIso}]`
    : EM_DASH;
};
