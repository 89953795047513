import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Table, Typography } from 'antd';
import Papa from 'papaparse';

import DownloadAltIcon from 'components/ui/atoms/icons/new/DownloadAlt';
import DeleteIcon from 'components/ui/atoms/icons/new/Delete';
import PlusIcon from 'components/ui/atoms/icons/PlusIcon';
import Flex from 'components/ui/atoms/flex/Flex';
import Button from 'components/ui/atoms/button/Button';
import IconButton from 'components/ui/atoms/iconButton/IconButton';
import { EditableTableAbility } from 'components/ui/organisms/editableTable/enums/editableTableAbility';
import { useDataEditing } from 'components/ui/organisms/editableTable/hooks/useDataEditing';
import { getDataSource } from 'components/ui/organisms/editableTable/helpers/getDataSource';
import { useColumns } from 'components/ui/organisms/editableTable/hooks/useColumns';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';
import { TDynamicTableColumns, TDynamicTableValues } from 'features/sqlEngine/types';

import { useStore } from 'storesMobx/MobxStoreProvider';

import { download } from 'utils/files/download';

import classes from './EditableTable.module.scss';

const { Title } = Typography;

interface IEditableTableProps {
  name: string;
  columns: string[];
  values: TDynamicTableValues;
  columnTypes?: SqlColumnType[];
  setColumns?: (columns: TDynamicTableColumns) => void;
  setValues?: (values: TDynamicTableValues) => void;
  setColumnTypes?: (columnTypes: SqlColumnType[]) => void;
  setName?: Dispatch<SetStateAction<string>>;
  displayName?: boolean;
  abilities?: EditableTableAbility[];
  deleteTable?: () => void;
}

const EditableTable: React.FC<IEditableTableProps> = ({
  name,
  columns,
  values,
  columnTypes,
  setColumns,
  setValues,
  setColumnTypes,
  setName,
  displayName = true,
  abilities = [],
  deleteTable,
}) => {
  const { themeStore } = useStore();

  const {
    addRow, deleteRow, addColumn, editColumn, deleteColumn, editCell, editColumnType,
  } = useDataEditing({
    columns, values, columnTypes, setColumns, setValues, setColumnTypes,
  });

  const tblRef: Parameters<typeof Table>[0]['ref'] = useRef(null);

  const { tableColumns } = useColumns(
    name,
    columns,
    abilities,
    deleteRow,
    addColumn,
    editColumn,
    deleteColumn,
    editCell,
    columnTypes,
    editColumnType,
  );

  const dataSource = getDataSource(values, columns);

  const handleAddRow = () => {
    addRow();

    tblRef.current?.scrollTo({ index: dataSource.length });
  };

  const handleExportAsCsv = () => {
    const csv = Papa.unparse(values);

    download(`${name}-csv-export.csv`, csv);
  };

  return (
    <div id={name}>
      {displayName && (
        <Flex gap={8} style={{ margin: '0 0 12px 0' }}>
          <Title level={4} editable={setName && { triggerType: ['text', 'icon'], onChange: setName }} style={{ flexShrink: 0 }}>
            {name}
          </Title>
          <Flex gap={4}>
            {deleteTable && (
              <IconButton icon={<DeleteIcon />} danger onClick={deleteTable} />
            )}
            {abilities.includes(EditableTableAbility.EXPORT_AS_CSV) && (
              <IconButton icon={<DownloadAltIcon />} onClick={handleExportAsCsv} />
            )}
          </Flex>
        </Flex>
      )}
      <Table
        // @ts-ignore
        columns={tableColumns}
        key={`table=${themeStore.isDarkMode}`}
        dataSource={dataSource}
        rowKey={(record: any) => Object.values(record).join('')}
        pagination={false}
        bordered
        virtual
        showHeader={false}
        className={classes.editable_table}
        scroll={{
          y: 400,
          x: columns.length * 300,
        }}
        ref={tblRef}
        onRow={(_, idx) => ({ className: !idx ? 'header-row' : undefined })}
        footer={() => (
          abilities.includes(EditableTableAbility.ADD_ROW) && (
            <Button size="small" icon={<PlusIcon />} block onClick={handleAddRow}>
              Добавить строку
            </Button>
          )
        )}
      />
    </div>
  );
};

export default EditableTable;
