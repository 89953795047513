export enum LocaleType {
  RU = 'ru-RU',
  EN = 'en-US',
}

export const ns = {
  agent: 'agent',
  auth: 'auth',
  buttons: 'buttons',
  common: 'common',
  errors: 'errors',
  placeholders: 'placeholders',
  messages: 'messages',
  confirmations: 'confirmations',
  headers: 'headers',
  tables: 'tables',
  tooltips: 'tooltips',
  factsBlocks: 'factsBlocks',
  prefixes: 'prefixes',
  post: 'post',
  report: 'report',
  results: 'results',
  labels: 'labels',
};
