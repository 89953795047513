import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import { tokenRepository } from 'domain/repositories/other/TokenRepository';
import {
  IAuthRepository,
  ILoginPayload,
  ILoginResponse, ILoginViaTsuAccountsPayload, ILoginViaTsuAccountsResponse, ILogoutResponse,
  IRefreshPayload, IRefreshResponse, IRegisterPayload, IRegisterResponse,
} from 'domain/repositories/api/auth/IAuthRepository';

export class AuthRepository implements IAuthRepository {
  private readonly prefix = `${process.env.REACT_APP_USER_API}/user`;

  public constructor(private _httpClient: IHttpClient) {}

  public login = (payload: ILoginPayload) => this._httpClient
    .post<ILoginResponse>(`${this.prefix}/login`, payload);

  public refresh = (payload: IRefreshPayload) => this._httpClient
    .post<IRefreshResponse>(`${this.prefix}/refresh`, payload);

  public logout = () => this._httpClient
    .post<ILogoutResponse>(`${this.prefix}/logout`, { refreshToken: tokenRepository.getRefreshToken() });

  public register = (payload: IRegisterPayload) => this._httpClient
    .post<IRegisterResponse>(`${this.prefix}/register`, payload);

  public loginViaTsuAccounts = (body: ILoginViaTsuAccountsPayload) => this._httpClient
    .post<ILoginViaTsuAccountsResponse>(`${this.prefix}/tsu/auth`, body);
}
