import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';

import { IEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/interfaces';

import { GetModuleDetailsProxyUseCase } from 'domain/useCases/proxy/GetModuleDetailsProxyUseCase';
import { IModuleProxy } from 'domain/entities/proxyModule';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

import { getFieldFromTranslations } from 'utils/locales/getFieldFromTranslations';

export class EntityDrawerModuleProxyViewModel implements IEntityDrawerViewModel {
  @observable public pageStatus = new LoadStatus();

  @observable public module: IModuleProxy | undefined;

  @observable public localization = '';

  public constructor(
    private _getModuleOverview: GetModuleDetailsProxyUseCase,
  ) {
    makeObservable(this);
  }

  @computed public get title() {
    return this.module
      ? getFieldFromTranslations('name', this.module.translations)
      : 'Загрузка...';
  }

  @computed public get sections() {
    return this.module?.sections?.map(({ id, translations, tasks }) => ({
      id,
      name: getFieldFromTranslations('name', translations, this.localization),
      tasks: tasks?.map(({ id, translation }) => ({
        id,
        name: getFieldFromTranslations('name', translation, this.localization),
        max_score: 0,
      })) ?? [],
    }));
  }

  @action public setLocalization = (l: string) => {
    this.localization = l;
  };

  @action public async fetch(id: string): Promise<void> {
    await this._getModuleOverview.fetch({
      payload: { id },
      loadStatus: this.pageStatus,
      onSuccess: ({ study_module }) => runInAction(() => {
        this.module = study_module;
      }),
    });
  }

  public reset = () => {
    this.module = undefined;

    this.pageStatus.onStartRequest();
  };
}
