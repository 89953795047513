import React, { MouseEvent, useState } from 'react';
import { Alert, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import Papa from 'papaparse';

import { useNotifications } from 'application/notification/useNotifications';

import TerminalIcon from 'components/ui/atoms/icons/new/Terminal';
import SettingsIcon from 'components/ui/atoms/icons/new/Settings';
import PlusIcon from 'components/ui/atoms/icons/PlusIcon';
import Flex from 'components/ui/atoms/flex/Flex';
import ContentLayout from 'components/ui/layout/contentLayout/ContentLayout';
import { EditableTableAbility } from 'components/ui/organisms/editableTable/enums/editableTableAbility';
import CodeEditor from 'components/ui/molecules/codeEditor/CodeEditor';
import Button from 'components/ui/atoms/button/Button';
import EditableTable from 'components/ui/organisms/editableTable/EditableTable';
import PageHeader from 'components/ui/molecules/pageHeader/PageHeader';
import { useTableCrud } from 'components/ui/organisms/editableTable/hooks/useTableCrud';

import { CodeEditorMode } from 'domain/entities/programmingLanguage';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';
import { TDynamicTable, TDynamicTableExtended } from 'features/sqlEngine/types';
import { SqlEngine } from 'features/sqlEngine/SqlEngine';

import { initialInputValue, initialTables } from 'pages/sqlTest/constants';

import { generateRandomName } from 'utils/random';
import { sleep } from 'utils/asyncHelpers';

const sqlEngine = new SqlEngine();

const SqlTest: React.FC = () => {
  const { notifyError } = useNotifications();

  const [codeEditorValue, setCodeEditorValue] = useState(initialInputValue);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<TDynamicTable[] | null>(null);

  const [tables, setTables] = useState<TDynamicTableExtended[]>(initialTables);

  const { updateTable, createTable, deleteTable } = useTableCrud({ tables, setTables });

  const handleExecute = async (e: MouseEvent) => {
    try {
      await sqlEngine.instantiateDatabase();

      tables.forEach(({
        name, columns, values, columnTypes,
      }) => {
        sqlEngine.setTable(name, { columns, values, columnTypes });

        sqlEngine.createTable(name);
      });

      const result = sqlEngine.executeStatement(codeEditorValue);

      setResult(result);
      setError(null);
    } catch (err) {
      setResult(null);
      setError(err?.toString() ?? 'Unknown Error');
    }

    sleep(0).then(() => {
      const btn = e.target as HTMLElement;

      const clientTop = btn.getBoundingClientRect().top;

      window.scrollTo({ left: window.scrollX, top: clientTop, behavior: 'smooth' });
    });
  };

  const handleFileUploadOnChange = (info: UploadChangeParam) => {
    const file = info.file as unknown as File;

    if (!file.type.startsWith('text')) {
      notifyError('Wrong file type');

      return;
    }

    Papa.parse(file, {
      complete: (results) => {
        const columnsLength = Array.isArray(results.data[0]) ? results.data[0].length : 0;

        setTables((prev) => [...prev, {
          name: `table_${generateRandomName()}`,
          columns: Array.from({ length: columnsLength }, (x, i) => `column${i}`),
          values: results.data as (string)[][],
          columnTypes: Array
            .from<SqlColumnType>({ length: columnsLength })
            .fill(SqlColumnType.VARCHAR),
        }]);
      },
    });
  };

  return (
    <ContentLayout>
      <PageHeader header="SQL-test">
        <Button icon={<PlusIcon />} onClick={createTable}>Add a table</Button>
        <Upload
          showUploadList={false}
          multiple
          onChange={handleFileUploadOnChange}
          beforeUpload={() => false}
        >
          <Button icon={<TerminalIcon />}>Add a table via CSV</Button>
        </Upload>
      </PageHeader>
      <Flex vertical gap={16} style={{ maxWidth: '1200px' }}>
        {tables.map((table, idx) => (
          <EditableTable
            key={`${table.name}${idx}`}
            name={table.name}
            columns={table.columns}
            abilities={Object.values(EditableTableAbility)}
            values={table.values}
            columnTypes={table.columnTypes}
            setColumns={(columns) => updateTable('columns', columns, idx)}
            setValues={(values) => updateTable('values', values, idx)}
            setColumnTypes={(columnTypes) => updateTable('columnTypes', columnTypes, idx)}
            setName={(name) => updateTable('name', name, idx)}
            deleteTable={() => deleteTable(idx)}
          />
        ))}
        <CodeEditor
          mode={CodeEditorMode.SQL}
          value={codeEditorValue}
          onChange={setCodeEditorValue}
        />
        <Button onClick={handleExecute} type="primary" icon={<SettingsIcon />}>Execute</Button>

        {error && <Alert type="error" description={error} />}
        {result && result.length ? (
          result.map((res, idx) => (
            <EditableTable
              key={idx}
              name="result"
              columns={res.columns}
              values={res.values}
              displayName={false}
            />
          ))
        ) : null}
      </Flex>
    </ContentLayout>
  );
};

export default SqlTest;
