import React from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import SegmentedLocalization from 'components/ui/atoms/segmentedLocalization/SegmentedLocalization';
import ModuleStructure from 'components/ui/organisms/classStructure/ModuleStructure';
import { useEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel/context';
import TwoColumns from 'components/ui/layout/twoColumns/TwoColumns';

import { getFieldFromTranslations } from 'utils/locales/getFieldFromTranslations';

const { Paragraph, Title } = Typography;

const EntityDrawerModuleProxyContent: React.FC = () => {
  const { moduleProxyViewModel } = useEntityDrawerViewModel();

  const {
    module,
    localization,
    sections,
    setLocalization,
  } = moduleProxyViewModel;

  if (!module) return null;

  return (
    <TwoColumns>
      <div>
        <Title level={5}>{getFieldFromTranslations('name', module.translations ?? [], localization)}</Title>
        <Paragraph>{getFieldFromTranslations('description', module.translations ?? [], localization)}</Paragraph>
        <ModuleStructure
          data={sections ?? []}
        />
      </div>
      <SegmentedLocalization onChange={setLocalization} />
    </TwoColumns>
  );
};

export default observer(EntityDrawerModuleProxyContent);
