import React, { useContext, useMemo } from 'react';

import { userStore } from 'domain/repositories/api';
import { themeRepository } from 'domain/repositories/other/ThemeRepository';

import { CommonDataStore } from 'storesMobx/stores/CommonDataStore';
import { ThemeStore } from 'storesMobx/stores/ThemeStore';
import { RootStore } from 'storesMobx/RootStore';

interface StoreProviderProps {
  children: React.ReactNode,
}

const StoreContext = React.createContext<RootStore | undefined>(undefined);

export const useStore: () => RootStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider');
  }

  return context;
};

export const MobxStoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const store: RootStore = useMemo(() => ({
    userStore,
    themeStore: new ThemeStore(themeRepository),
    commonDataStore: new CommonDataStore(),
  }), []);

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
