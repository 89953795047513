import React from 'react';
import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';

import { EntityDrawerType } from 'components/ui/organisms/entityDrawer/enums';
import EntityDrawerSectionProxyContent from 'components/ui/organisms/entityDrawer/entities/sectionProxy/content/EntityDrawerSectionProxyContent';
import WithLoader from 'components/ui/molecules/withLoader/WithLoader';
import { drawerPlacement, drawerWidth } from 'components/ui/organisms/entityDrawer/constants';
import { useEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel/context';

const ContentWithLoader = WithLoader(EntityDrawerSectionProxyContent, true);

const EntityDrawerModule: React.FC = () => {
  const { isOpened, close, sectionProxyViewModel } = useEntityDrawerViewModel();

  const { pageStatus, title } = sectionProxyViewModel;

  return (
    <Drawer
      title={title}
      placement={drawerPlacement}
      width={drawerWidth}
      open={isOpened(EntityDrawerType.SECTION_PROXY)}
      onClose={() => close(EntityDrawerType.SECTION_PROXY)}
      destroyOnClose
    >
      <ContentWithLoader
        isLoading={pageStatus.isLoading}
        isFailed={pageStatus.isFailed}
        errorCode={pageStatus.errorCode}
      />
    </Drawer>
  );
};

export default observer(EntityDrawerModule);
