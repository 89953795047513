import { action, makeObservable, observable } from 'mobx';

import { EntityDrawerSubjectProxyViewModel } from 'components/ui/organisms/entityDrawer/entities/subjectProxy/EntityDrawerSubjectProxyViewModel';
import {
  EntityDrawerModuleProxyViewModel,
} from 'components/ui/organisms/entityDrawer/entities/moduleProxy/EntityDrawerModuleProxyViewModel';
import {
  EntityDrawerSectionProxyViewModel,
} from 'components/ui/organisms/entityDrawer/entities/sectionProxy/EntityDrawerSectionProxyViewModel';
import {
  EntityDrawerTaskProxyViewModel,
} from 'components/ui/organisms/entityDrawer/entities/taskProxy/EntityDrawerTaskProxyViewModel';
import { IEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/interfaces';
import { EntityDrawerType } from 'components/ui/organisms/entityDrawer/enums';

export class EntityDrawerViewModel {
  @observable public openedDrawers: EntityDrawerType[] = [];

  public constructor(
    public taskProxyViewModel: EntityDrawerTaskProxyViewModel,
    public sectionProxyViewModel: EntityDrawerSectionProxyViewModel,
    public moduleProxyViewModel: EntityDrawerModuleProxyViewModel,
    public subjectProxyViewModel: EntityDrawerSubjectProxyViewModel,
  ) {
    makeObservable(this);
  }

  public isOpened = (type: EntityDrawerType) => this.openedDrawers.includes(type);

  private getDrawerViewModel = (type: EntityDrawerType): IEntityDrawerViewModel => {
    switch (type) {
      case EntityDrawerType.TASK_PROXY:
        return this.taskProxyViewModel;
      case EntityDrawerType.SECTION_PROXY:
        return this.sectionProxyViewModel;
      case EntityDrawerType.MODULE_PROXY:
        return this.moduleProxyViewModel;
      case EntityDrawerType.SUBJECT_PROXY:
        return this.subjectProxyViewModel;
      default:
        throw new Error('Unknown drawers');
    }
  };

  @action public open = (type: EntityDrawerType, payload: any) => {
    if (this.openedDrawers.includes(type)) throw new Error('Drawer is already opened');

    this.openedDrawers = [
      ...this.openedDrawers,
      type,
    ];

    (async () => {
      await this.getDrawerViewModel(type).fetch(payload);
    })();
  };

  @action public close = (type: EntityDrawerType) => {
    this.openedDrawers = this.openedDrawers.filter((drawer) => drawer !== type);

    this.getDrawerViewModel(type).reset();
  };
}
