import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import FallbackError from 'application/errrorBoundary/FallbackError';

import { IChildren } from 'utils/interfaces/IChildren';

const ErrorBoundaryProvider: React.FC<IChildren> = ({ children }) => (
  <ErrorBoundary FallbackComponent={FallbackError}>
    {children}
  </ErrorBoundary>
);

export default ErrorBoundaryProvider;
