import { TFunction } from 'i18next';

import { ns } from 'utils/locales';

export type TAuthSliderItem = {
  title: string;
  description: string;
}

export const getAuthSliderItems = (t: TFunction): TAuthSliderItem[] => [
  {
    title: t(`${ns.auth}:item-1-title`),
    description: t(`${ns.auth}:item-1-description`),
  },
  {
    title: t(`${ns.auth}:item-2-title`),
    description: t(`${ns.auth}:item-2-description`),
  },
];

export const MODE_SEARCH_PARAM = 'mode';

export enum AuthPageMode {
  REGISTER = 'register'
}
