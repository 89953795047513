import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function UsersIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="6" r="4" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M16 9C17.6569 9 19 7.65685 19 6C19 4.34315 17.6569 3 16 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <ellipse cx="10" cy="17" rx="7" ry="4" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M19 14C20.7542 14.3847 22 15.3589 22 16.5C22 17.5293 20.9863 18.4229 19.5 18.8704"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
