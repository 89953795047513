import { moduleRepository } from 'domain/repositories/api';
import { IClassDataSource } from 'domain/repositories/api/class/dataSource/IClassDataSource';
import {
  IClassRepository,
  ICreateClassPayload,
  IGetClassesPayload,
  IGetClassPayload,
  IUpdateClassPayload,
  IGetClassModulesPayload,
  IGetAvailableProgrammingLanguagesPayload,
  IForkClassPayload,
  IAddStudyGroupPayload,
  TDeleteClassUserPayload,
  TGetUserClassesPayload,
  TGetClassStructurePayload,
  TGetClassStructureResponse,
} from 'domain/repositories/api/class/IClassRepository';

export class ClassRepository implements IClassRepository {
  public constructor(private _ds: IClassDataSource) {}

  public getClassStructureNew = (p: TGetClassStructurePayload) => this._ds.getClassStructure(p);

  public getClassStructureOld = async ({ classId }: TGetClassStructurePayload) => {
    const { class: classEntity } = await this.getClass({ id: classId });

    const { class_study_modules } = await this._ds.getClassModules({ classId });

    const modulesStructures = await Promise.all(class_study_modules.map(async (module) => {
      const { class_sections } = await moduleRepository.getSections({ studyModuleId: module.id });

      return { ...module, classSections: class_sections };
    }));

    return {
      structure: {
        class: classEntity,
        classStudyModules: modulesStructures,
      },
      status: 'OK',
      trackingID: '',
    } as TGetClassStructureResponse;
  };

  public getClasses = (p: IGetClassesPayload) => this._ds.getClasses(p);

  public getClass = async ({ id }: IGetClassPayload) => {
    const { classes, total, ...response } = await this.getClasses({ filters: { id } });

    if (!classes.length) return { class: undefined, ...response };
    return { class: classes[0], ...response };
  };

  public createClass = (payload: ICreateClassPayload) => this._ds.createClass(payload);

  public updateClass = (payload: IUpdateClassPayload) => this._ds.updateClass(payload);

  public getClassModules = (payload: IGetClassModulesPayload) => this._ds.getClassModules(payload);

  public getAvailableProgrammingLanguages = (p: IGetAvailableProgrammingLanguagesPayload) => (
    this._ds.getAvailableProgrammingLanguages(p)
  );

  public forkClass = (p: IForkClassPayload) => this._ds.forkClass(p);

  public addStudyGroup = (p: IAddStudyGroupPayload) => this._ds.addStudyGroup(p);

  public deleteClassUser = (p: TDeleteClassUserPayload) => this._ds.deleteClassUser(p);

  public getUserClasses = (p: TGetUserClassesPayload) => this._ds.getUserClasses(p);
}
