import React from 'react';
import { Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import { useTranslation } from 'react-i18next';

import { ns } from 'utils/locales';

const SUPPORTED_CODES = [403, 404, 500];

type TProps = {
  errorCode?: number;
};

export default function ErrorState(props: TProps) {
  const { errorCode } = props;

  const { t } = useTranslation();

  const status = errorCode && SUPPORTED_CODES.includes(errorCode) ? errorCode as ResultStatusType : '500';

  return (
    <Result
      status={status}
      title={errorCode}
      subTitle={t(`${ns.errors}:requestError`)}
    />
  );
}
