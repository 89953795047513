import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function LanguageIcon(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 5.30769H15.8462M8.92308 3V5.30769M12.7692 21.4615L17.3846 10.6923L22 21.4615M14.1875 18.3846H20.5817M13.2163 5.30769C13.2163 5.30769 12.0481 9.82692 9.25962 13.2404C6.47115 16.6538 3.53846 18.3846 3.53846 18.3846"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.0769C12 16.0769 10.3173 14.7788 8.53847 12.4712C6.75962 10.1635 5.84616 8.38461 5.84616 8.38461"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
