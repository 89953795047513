import React from 'react';
import clsx from 'clsx';

import Background from 'components/ui/atoms/icons/auth/Background';

import classes from './AuthPage.module.scss';
import Description from './ui/Description';
import AuthForm from './ui/AuthForm';
import useRegisterMode from './hooks/useRegisterMode';

const AuthPageView: React.FC = () => {
  const { isRegisterMode } = useRegisterMode();

  return (
    <div className={clsx(classes.auth_page, {
      [classes.auth_page_register_mode]: isRegisterMode,
    })}
    >
      <Background className={classes.background} />
      <AuthForm isRegister={isRegisterMode} />
      <Description />
    </div>
  );
};

export default AuthPageView;
