import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function MinusSignIcon(props: IconProps) {
  return (
    <svg width="18" height="18" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 17">
      <path d="M0 7h8v2H0z" fill="currentColor" />
    </svg>
  );
}
