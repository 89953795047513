import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { route } from 'application/routes/route';

import DialogIcon from 'components/ui/atoms/icons/new/Dialog';
import SettingsIcon from 'components/ui/atoms/icons/new/Settings';
import ClassIcon from 'components/ui/atoms/icons/new/Class';
import { ISidebarItem } from 'components/ui/organisms/sidebar/types';
import InfoIcon from 'components/ui/atoms/icons/new/Info';

import { Permission } from 'domain/entities/permission';

import { useIsPermitted } from 'utils/permissions/useIsPermitted';
import { ns } from 'utils/locales';

export const useSidebarLinks = (): ISidebarItem[] => {
  const { t } = useTranslation([]);
  const { pathname } = useLocation();

  const curSubDirectory = `/${pathname.split('/')[1]}`;

  const canEditTaskBank = useIsPermitted(Permission.EDIT_TASK_BANK);
  const canEditSubdivisions = useIsPermitted(Permission.CAN_CREATE_SUBDIVISION);
  const isSuperAdmin = useIsPermitted(Permission.SUPER_ADMIN);

  return [
    {
      text: t(`${ns.common}:sidebarLinks.classes`),
      to: route.base,
      isActive: route.base === curSubDirectory || curSubDirectory === '/class',
      icon: <ClassIcon />,
    },
    // {
    //   text: t(`${ns.common}:sidebarLinks.about`),
    //   to: route.about,
    //   isActive: route.about === curSubDirectory,
    //   icon: <InfoIcon />,
    // },
    {
      text: t(`${ns.common}:sidebarLinks.support`),
      to: route.support,
      isActive: route.support === curSubDirectory,
      icon: <DialogIcon />,
    },
    ...(isSuperAdmin ? [
      {
        text: 'SQL-test',
        to: route.sqlTest,
        isActive: route.sqlTest === curSubDirectory,
        icon: <SettingsIcon />,
      },
    ] : []),
    ...(canEditSubdivisions ? [
      {
        text: 'Панель администратора',
        to: route.adminPanel,
        isActive: route.adminPanel === curSubDirectory,
        icon: <SettingsIcon />,
      },
    ] : []),
    ...(canEditTaskBank ? [
      {
        text: 'TaskBank',
        to: process.env.REACT_APP_TASK_BANK_URL,
        isActive: false,
        icon: <InfoIcon />,
      },
    ] : []),
  ];
};
