import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IAddUsersPayloadDS,
  IAddUsersResponseDS,
  ICreateInvitationLinkPayloadDS,
  ICreateInvitationLinkResponseDS,
  IDeleteInvitationPayloadDS, IDeleteInvitationResponseDS,
  IGetInvitationLinksPayloadDS,
  IGetInvitationLinksResponseDS,
  IGetStudentsPayloadDS,
  IGetStudentsResponseDS,
  IGetTeachersPayloadDS,
  IGetTeachersResponseDS,
  IUseInvitationLinkPayloadDS,
  IUseInvitationLinkResponseDS,
  IUserClassDataSource,
} from 'domain/repositories/api/userClass/dataSource/IUserClassDataSource';

export class UserClassDataSource implements IUserClassDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/class`;

  public constructor(private _httpClient: IHttpClient) {}

  public getStudentsList = ({ classId }: IGetStudentsPayloadDS) => this._httpClient
    .get<IGetStudentsResponseDS>(`${this.prefix}/${classId}/students`);

  public getTeachersList = ({ classId }: IGetTeachersPayloadDS) => this._httpClient
    .get<IGetTeachersResponseDS>(`${this.prefix}/${classId}/teachers`);

  public addUsers = ({ classId, ...body }: IAddUsersPayloadDS) => this._httpClient
    .post<IAddUsersResponseDS>(`${this.prefix}/${classId}/users/add`, body);

  public getInvitationLinks = ({ classId }: IGetInvitationLinksPayloadDS) => this._httpClient
    .get<IGetInvitationLinksResponseDS>(`${this.prefix}/${classId}/invite/get`);

  public createInvitationLink = ({ classId, ...body }: ICreateInvitationLinkPayloadDS) => (
    this._httpClient
      .post<ICreateInvitationLinkResponseDS>(`${this.prefix}/${classId}/invite/create`, body)
  );

  public useInvitationLink = ({ id }: IUseInvitationLinkPayloadDS) => this._httpClient
    .post<IUseInvitationLinkResponseDS>(`${this.prefix}/invite/${id}`);

  public deleteInvitationLink = ({ classId, invitationLinkId }: IDeleteInvitationPayloadDS) => (
    this._httpClient
      .post<IDeleteInvitationResponseDS>(`${this.prefix}/${classId}/invite/${invitationLinkId}/delete`)
  );
}
