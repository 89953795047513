import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function ChevronLeftIcon(props: IconProps) {
  return (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7 1L1 8L7 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
