import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TTag } from 'domain/entities/report';
import { GetReportTagsUseCase } from 'domain/useCases/report/GetReportTagsUseCase';
import { reportRepository } from 'domain/repositories/api';

import { ns } from 'utils/locales';

const getReportTags = new GetReportTagsUseCase({
  requestCallback: reportRepository.getTags,
});

export default function useReportTagsOptions() {
  const [tags, setTags] = useState<TTag[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(true);

      await getReportTags.fetch({
        payload: undefined,
        onSuccess: ({ tagNames }) => {
          setTags(tagNames ?? []);
          setLoading(false);
        },
      });
    })();
  }, []);

  const options = tags?.map(({ id, name }) => ({
    value: id,
    label: t(`${ns.report}:options.${name}`),
  }));

  return { tags, options, loading };
}
