import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAuthSliderItems } from 'pages/auth/constants';

export default function useAuthSlider() {
  const { t } = useTranslation();

  const sliderItems = getAuthSliderItems(t);

  const [curItemIdx, setCurItemIdx] = useState(0);

  const handleNext = () => {
    setCurItemIdx((prev) => (prev >= sliderItems.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setCurItemIdx((prev) => (!prev ? sliderItems.length - 1 : prev - 1));
  };

  return { curItem: sliderItems[curItemIdx], handlePrev, handleNext };
}
