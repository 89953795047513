import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';

import { IClass } from 'domain/entities/class';
import { proxyRepository } from 'domain/repositories/api';
import { GetLanguagesUseCase } from 'domain/useCases/languages/GetLanguagesUseCase';
import { ILanguage } from 'domain/entities/language';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

export class CommonDataStore {
  @observable public languages: ILanguage[] = [];

  @observable public classes: IClass[] = [];

  @observable public loadStatus = new LoadStatus();

  @observable public openedClass: IClass | null = null;

  private getLanguagesUseCase: GetLanguagesUseCase;

  @computed public get localizations() {
    return this.languages.map((language) => language.iso);
  }

  public getRemainingLocalizations = (current: string[]) => (
    this.localizations.filter((l) => !current.includes(l))
  );

  public constructor() {
    makeObservable(this);

    this.getLanguagesUseCase = new GetLanguagesUseCase({
      requestCallback: proxyRepository.getLanguages,
    });
  }

  @action public fetch = () => this.getLanguagesUseCase.fetch({
    payload: undefined,
    loadStatus: this.loadStatus,
    onSuccess: ({ language_response }) => runInAction(() => {
      this.languages = language_response;
    }),
  });

  @action public setOpenedClass = (classDetails: IClass | null) => {
    this.openedClass = classDetails;
  };

  @action public setClasses = (classes: IClass[]) => {
    this.classes = classes;
  };
}
