import backend from 'assets/illustrations/backend.svg';
import database from 'assets/illustrations/database.svg';
import frontend from 'assets/illustrations/frontend.svg';
import math from 'assets/illustrations/math.svg';
import mobile from 'assets/illustrations/mobile.svg';
import programming from 'assets/illustrations/programming.svg';
import systemAdministration from 'assets/illustrations/systemAdministration.svg';

import { ClassType } from 'domain/entities/class';

export const getClassIllustration = (classType?: ClassType): string => {
  switch (classType) {
    case ClassType.BACKEND:
      return backend;
    case ClassType.DATA_BASES:
      return database;
    case ClassType.FRONTEND:
      return frontend;
    case ClassType.MATH:
      return math;
    case ClassType.MOBILE:
      return mobile;
    case ClassType.PROGRAMMING:
      return programming;
    case ClassType.SYSTEM_ADMINISTRATION:
      return systemAdministration;
    default:
      return programming;
  }
};
