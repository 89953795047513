import React, { HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';

import classes from 'components/ui/atoms/typography/Title.module.scss';

import { Range } from 'utils/types/range';

type TProps = {
  level: Range<1, 5>
  children: ReactNode
} & HTMLAttributes<HTMLElement>

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4'

export default function Title(props: TProps) {
  const {
    level, children, className, ...rest
  } = props;

  const classNameString = clsx(classes.title, classes[`title_level_${level}`], className);

  const Heading = `h${level}` as HeadingTag;

  return (
    <Heading {...rest} className={classNameString}>
      {children}
    </Heading>
  );
}
