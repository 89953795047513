import React, { useMemo } from 'react';

import EntityDrawerSubjectProxy from 'components/ui/organisms/entityDrawer/entities/subjectProxy/EntityDrawerSubjectProxy';
import EntityDrawerModuleProxy from 'components/ui/organisms/entityDrawer/entities/moduleProxy/EntityDrawerModuleProxy';
import EntityDrawerSectionProxy from 'components/ui/organisms/entityDrawer/entities/sectionProxy/EntityDrawerSectionProxy';
import EntityDrawerTaskProxy from 'components/ui/organisms/entityDrawer/entities/taskProxy/EntityDrawerTaskProxy';
import { useViewModels } from 'components/ui/organisms/entityDrawer/provider/viewModelsFactory';
import {
  EntityDrawerVmContext,
} from 'components/ui/organisms/entityDrawer/viewModel/context';
import { EntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel';

import { IChildren } from 'utils/interfaces/IChildren';

const EntityDrawerProvider: React.FC<IChildren> = ({ children }) => {
  const vms = useViewModels();

  const vm = useMemo<EntityDrawerViewModel>(
    () => new EntityDrawerViewModel(
      ...vms,
    ),
    [],
  );

  return (
    <EntityDrawerVmContext.Provider value={vm}>
      {children}
      <EntityDrawerTaskProxy />
      <EntityDrawerSectionProxy />
      <EntityDrawerModuleProxy />
      <EntityDrawerSubjectProxy />
    </EntityDrawerVmContext.Provider>
  );
};

export default EntityDrawerProvider;
