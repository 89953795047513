import { EntityDrawerSubjectProxyViewModel } from 'components/ui/organisms/entityDrawer/entities/subjectProxy/EntityDrawerSubjectProxyViewModel';
import { EntityDrawerModuleProxyViewModel } from 'components/ui/organisms/entityDrawer/entities/moduleProxy/EntityDrawerModuleProxyViewModel';
import { EntityDrawerSectionProxyViewModel } from 'components/ui/organisms/entityDrawer/entities/sectionProxy/EntityDrawerSectionProxyViewModel';
import { EntityDrawerTaskProxyViewModel } from 'components/ui/organisms/entityDrawer/entities/taskProxy/EntityDrawerTaskProxyViewModel';

import { GetSubjectDetailsProxyUseCase } from 'domain/useCases/proxy/GetSubjectDetailsProxyUseCase';
import { GetSectionDetailsProxyUseCase } from 'domain/useCases/proxy/GetSectionDetailsProxyUseCase';
import { GetModuleDetailsProxyUseCase } from 'domain/useCases/proxy/GetModuleDetailsProxyUseCase';
import { proxyRepository } from 'domain/repositories/api';
import { GetTaskProxyUseCase } from 'domain/useCases/proxy/GetTaskProxyUseCase';

type ReturnType = [
  EntityDrawerTaskProxyViewModel,
  EntityDrawerSectionProxyViewModel,
  EntityDrawerModuleProxyViewModel,
  EntityDrawerSubjectProxyViewModel,
];

export const useViewModels = (): ReturnType => {
  const getTaskProxy = new GetTaskProxyUseCase({
    requestCallback: proxyRepository.getTaskProxy,
  });

  const taskProxyViewModel = new EntityDrawerTaskProxyViewModel(getTaskProxy);

  const getSectionProxy = new GetSectionDetailsProxyUseCase({
    requestCallback: proxyRepository.getSectionDetailsProxy,
  });

  const sectionProxyViewModel = new EntityDrawerSectionProxyViewModel(getSectionProxy);

  const getModuleProxy = new GetModuleDetailsProxyUseCase({
    requestCallback: proxyRepository.getModuleDetailsProxy,
  });

  const moduleProxyViewModel = new EntityDrawerModuleProxyViewModel(getModuleProxy);

  const getSubjectProxy = new GetSubjectDetailsProxyUseCase({
    requestCallback: proxyRepository.getSubjectDetailsProxy,
  });

  const subjectProxyViewModel = new EntityDrawerSubjectProxyViewModel(getSubjectProxy);

  return [
    taskProxyViewModel,
    sectionProxyViewModel,
    moduleProxyViewModel,
    subjectProxyViewModel,
  ];
};
