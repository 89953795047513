import { ITaskDataSource } from 'domain/repositories/api/task/dataSource/ITaskDataSource';
import {
  IGetClassTasksListPayload,
  IGetClassTaskPayload,
  ITaskRepository,
  IForkTaskPayload,
  IUpdateClassTaskPayload,
  IGetClassTaskConfigsPayload,
  IUpdateClassTaskConfigPayload, IDeleteClassTaskConfigPayload, IDeleteClassTaskPayload,
} from 'domain/repositories/api/task/ITaskRepository';

export class TaskRepository implements ITaskRepository {
  public constructor(private _ds: ITaskDataSource) {}

  public getClassTasks = (payload: IGetClassTasksListPayload) => this._ds.getClassTasks(payload);

  public getClassTask = (payload: IGetClassTaskPayload) => this._ds.getClassTask(payload);

  public updateClassTask = (payload: IUpdateClassTaskPayload) => this._ds.updateClassTask(payload);

  public deleteClassTask = (payload: IDeleteClassTaskPayload) => this._ds.deleteClassTask(payload);

  public forkTask = async (payload: IForkTaskPayload) => {
    const response = await this._ds.forkTask(payload);
    return { ...response, id: response.ID };
  };

  public getClassTaskConfigs = (p: IGetClassTaskConfigsPayload) => (
    this._ds.getClassTaskConfigs(p)
  );

  public updateClassTaskConfig = (p: IUpdateClassTaskConfigPayload) => (
    this._ds.updateClassTaskConfig(p)
  );

  public deleteClassTaskConfig = (p: IDeleteClassTaskConfigPayload) => (
    this._ds.deleteClassTaskConfig(p)
  );
}
