import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IClassDataSource,
  ICreateClassPayloadDS,
  IGetClassModulesPayloadDS,
  IGetClassModulesResponseDS,
  IGetClassesPayloadDS,
  IGetClassesResponseDS,
  IUpdateClassPayloadDS,
  IUpdateClassResponseDS,
  IGetAvailableProgrammingLanguagesPayloadDS,
  IGetAvailableProgrammingLanguagesResponseDS,
  IForkClassPayloadDS,
  IForkClassResponseDS,
  ICreateClassResponseDS,
  IAddStudyGroupPayloadDS,
  IAddStudyGroupResponseDS,
  TDeleteClassUserPayloadDS, TDeleteClassUserResponseDS,
  TGetUserClassesPayloadDS, TGetUserClassesResponseDS,
  TGetClassStructureResponseDS, TGetClassStructurePayloadDS,
} from 'domain/repositories/api/class/dataSource/IClassDataSource';

import { SearchQueryOperators } from 'utils/enums/SearchQueryOperators';

export class ClassDataSource implements IClassDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/class`;

  public constructor(private _httpClient: IHttpClient) {}

  public getClassStructure = ({ classId, withTasks }: TGetClassStructurePayloadDS) => this._httpClient.get<TGetClassStructureResponseDS>(`${this.prefix}/${classId}/structure/get`, {
    is_get_task: withTasks,
  });

  public getClasses = ({ filters, ...pagination }: IGetClassesPayloadDS) => this._httpClient.get<IGetClassesResponseDS>(`${this.prefix}/get`, {
    ...pagination,
    name: filters?.name && `${SearchQueryOperators.LIKE}${filters.name}`,
    id: filters?.id && `${SearchQueryOperators.EQUAL}${filters.id}`,
  }, !!filters?.id);

  public createClass = ({ subdivisionId, ...body }: ICreateClassPayloadDS) => this._httpClient
    .post<ICreateClassResponseDS>(`${this.prefix}/subdivision/${subdivisionId}/create`, body);

  public updateClass = ({ classId, ...body }: IUpdateClassPayloadDS) => this._httpClient
    .post<IUpdateClassResponseDS>(`${this.prefix}/${classId}/update`, body);

  public getClassModules = ({ classId }: IGetClassModulesPayloadDS) => this._httpClient
    .get<IGetClassModulesResponseDS>(`${this.prefix}/${classId}/classStudyModule/get`, {});

  public getAvailableProgrammingLanguages = (
    { classId }: IGetAvailableProgrammingLanguagesPayloadDS,
  ) => (
    this._httpClient
      .get<IGetAvailableProgrammingLanguagesResponseDS>(`${this.prefix}/${classId}/classProgrammingLanguage/available/get`, undefined, true)
  );

  public forkClass = ({ subdivisionId, subjectId, iso }: IForkClassPayloadDS) => this._httpClient
    .post<IForkClassResponseDS>(`${this.prefix}/subdivision/${subdivisionId}/subject/${subjectId}/${iso}/fork`);

  public addStudyGroup = ({ classId, studyGroupId }: IAddStudyGroupPayloadDS) => this._httpClient
    .post<IAddStudyGroupResponseDS>(`${this.prefix}/${classId}/studyGroup/${studyGroupId}/add`);

  public deleteClassUser = ({ classId, role, user_id }: TDeleteClassUserPayloadDS) => (
    this._httpClient
      .post<TDeleteClassUserResponseDS>(`${this.prefix}/${classId}/user/delete`, { role, user_id })
  );

  public getUserClasses = ({ userId }: TGetUserClassesPayloadDS) => (
    this._httpClient.get<TGetUserClassesResponseDS>(`${this.prefix}/get/user/${userId}`)
  );
}
