import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function ClearIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <path
        d="M8.99997 1.00003L1 9M0.999966 1L8.99993 8.99997"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
