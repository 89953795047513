import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IReportRepository, TCreateReportPayload, TCreateReportResponse, TGetTagsResponse,
} from './IReportRepository';

export class ReportRepository implements IReportRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/report`;

  public constructor(private _httpClient: IHttpClient) {}

  public getTags = () => this._httpClient.get<TGetTagsResponse>(`${this.prefix}/tag`);

  public createReport = (p: TCreateReportPayload) => this._httpClient.post<TCreateReportResponse>(`${this.prefix}`, p);
}
