import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function CheckWaiting(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10 7L2 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 12H2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M10 17H2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="17" cy="12" r="5" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M17 10V11.8462L18 13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
