import axios from 'axios';
import { LOGIN_TOKEN_URL, REFRESH_TOKEN_URL } from 'data/axios/constants';

import { tokenRepository } from 'domain/repositories/other/TokenRepository';
import { userStore } from 'domain/repositories/api';

import { deleteAllHttpCache } from 'utils/cache';

type RequestInterceptorParams = Parameters<typeof axios.interceptors.request.use>;
type RequestInterceptorOnFulfilled = RequestInterceptorParams[0];
type RequestInterceptorOnRejected = RequestInterceptorParams[1];

export const requestInterceptorOnFulfilled: RequestInterceptorOnFulfilled = (cfg) => {
  if (cfg.url !== LOGIN_TOKEN_URL && !tokenRepository.getRefreshToken()) {
    tokenRepository.reset();
    userStore.resetStore();
    deleteAllHttpCache();
  }

  if (cfg.url === REFRESH_TOKEN_URL) {
    const refreshToken = tokenRepository.getRefreshToken();

    if (refreshToken) cfg.headers.Authorization = `Bearer ${refreshToken}`;

    return cfg;
  }

  const accessToken = tokenRepository.getToken();
  if (accessToken) cfg.headers.Authorization = `Bearer ${accessToken}`;

  return cfg;
};

export const requestInterceptorOnRejected: RequestInterceptorOnRejected = (error) => (
  Promise.reject(error)
);
