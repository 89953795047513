import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IAdministrationRepository,
  IUpdateUserPayload, IUpdateUserResponse,
} from 'domain/repositories/api/administration/IAdministrationRepository';

export class AdministrationRepository implements IAdministrationRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/administration`;

  public constructor(private _httpClient: IHttpClient) {}

  public updateUser = ({ id, ...body }: IUpdateUserPayload) => this._httpClient
    .post<IUpdateUserResponse>(`${this.prefix}/user/${id}/update`, body);
}
