import {
  IGetSubjectDetailsProxyPayload,
  IGetSubjectDetailsProxyResponse,
} from 'domain/repositories/api/proxy/IProxyRepository';
import { APIUseCase } from 'domain/useCases/common/APIUseCase';

export class GetSubjectDetailsProxyUseCase extends APIUseCase<
  IGetSubjectDetailsProxyPayload,
  IGetSubjectDetailsProxyResponse
> { }
