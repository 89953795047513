import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Route } from 'application/routes/types';
import { routesForbiddenRules } from 'application/routes/routesAuthorization';
import { route } from 'application/routes/route';
import { useClassRoleByRoute } from 'application/authentication/useClassRoleByRoute';

import { IChildren } from 'utils/interfaces/IChildren';

const routePatterns = Object.fromEntries(Object.entries(route).map(([key, value]) => {
  if (typeof value === 'function') return [key, value()];

  return [key, value];
}));

const AuthorizationProvider: React.FC<IChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { classRole } = useClassRoleByRoute();

  useEffect(() => {
    const routeKey = Object.entries(routePatterns)
      .find(([_, pattern]) => matchPath(pattern, pathname))?.[0];

    if (!routeKey) return;

    const forbiddenRoles = routesForbiddenRules[routeKey as keyof Route];

    if (!forbiddenRoles.length) return;

    if (forbiddenRoles.includes(classRole)) {
      navigate(route.base);
    }
  }, []);

  return <>{children}</>;
};

export default observer(AuthorizationProvider);
