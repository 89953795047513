import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  ICreateUniversityPayload, ICreateUniversityResponse,
  IDeleteUniversityPayload, IDeleteUniversityResponse,
  IGetSubdivisionsListPayload, IGetSubdivisionsListResponse, IGetUniversityListPayload,
  IGetUniversityListResponse,
  IUniversityRepository, IUpdateUniversityPayload, IUpdateUniversityResponse,
} from 'domain/repositories/api/university/IUniversityRepository';

export class UniversityRepository implements IUniversityRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/university`;

  public constructor(private _httpClient: IHttpClient) {}

  public getUniversityList = ({ ...pagination }: IGetUniversityListPayload) => this._httpClient
    .get<IGetUniversityListResponse>(`${this.prefix}/get`, {
      ...pagination,
    });

  public createUniversity = ({ ...body }: ICreateUniversityPayload) => this._httpClient
    .post<ICreateUniversityResponse>(`${this.prefix}/create`, body);

  public updateUniversity = ({ id, ...body }: IUpdateUniversityPayload) => this._httpClient
    .post<IUpdateUniversityResponse>(`${this.prefix}/${id}/update`, body);

  public deleteUniversity = ({ id }: IDeleteUniversityPayload) => this._httpClient
    .post<IDeleteUniversityResponse>(`${this.prefix}/${id}/delete`);

  public getSubdivisions = ({ universityId, ...pagination }: IGetSubdivisionsListPayload) => (
    this._httpClient
      .get<IGetSubdivisionsListResponse>(`${this.prefix}/${universityId}/subdivision/get`, {
        ...pagination,
      })
  );
}
