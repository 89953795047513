import React, { PropsWithChildren } from 'react';

import useSetterValidationError from 'application/validationErrors/useSetterValidationError';

import classes from './AppLayout.module.scss';

export default function AppLayout(props: PropsWithChildren) {
  const { children } = props;

  useSetterValidationError();

  return (
    <div className={classes.app_layout}>
      {children}
    </div>
  );
}
