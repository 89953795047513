import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';
import Title from 'antd/es/typography/Title';
import Typography from 'antd/es/typography/Typography';

interface StructureWrapperProps {
  rightMargin?: number;
}

export const StructureWrapper = styled.div<StructureWrapperProps>`
  ${({ rightMargin = 0 }) => css`
    min-width: 800px;
    max-width: 1000px;
    margin-right: ${rightMargin}px;
  `}
`;

interface HeaderWrapperProps {
  headerType: 'module' | 'section' | 'task';
  leftPadding?: boolean;
}

export const HeaderWrapper = styled.div<ThemeProps<DefaultTheme> & HeaderWrapperProps>`
  ${({ theme, headerType = 'module', leftPadding = true }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding-left: ${leftPadding ? 45 : 0}px;
    position: relative;

    ${(headerType === 'module' || headerType === 'section') && css`
      &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) ${headerType === 'section' && 'translateX(5px)'};

        display:block;
        width: ${25 - 10 * +(headerType === 'section')}px;
        height: ${25 - 10 * +(headerType === 'section')}px;
        border-radius: 50%;

        background-color: ${theme.colorPrimary};
      }
    `}
    
    ${headerType === 'module' && css`
      margin-bottom: 10px;
    `};

    ${headerType === 'section' && css`
      margin-bottom: 5px;
    `};

    && {
      h2, h4 {
        margin: 0;
      }
    }
  `}
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  
  h4 {
    margin-right: 20px;
  }
`;

export const IconsWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items:center;
`;

interface PercentageTitleProps {
  $marginLeft?: string;
}

export const PercentageTitle = styled(Title)<PercentageTitleProps>`
  ${({ $marginLeft = '100%' }) => css`
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(${$marginLeft});
  `}
`;

export const PointsTitle = styled(Title)`
  width: 80px;
  text-align: center;
  flex-shrink: 0;
`;

export const PointsTypography = styled(Typography)`
  width: 80px;
  text-align: center;
  flex-shrink: 0;
`;

export const DottedLine = styled.div<ThemeProps<DefaultTheme>>`
  ${({ theme }) => css`
    flex-grow: 1;
    border-top: 1px dotted ${theme.colorText};
    margin-left: 10px;
    min-width: 40px;
  `}
`;

export const ModuleWrapper = styled.div<ThemeProps<DefaultTheme>>`
  ${({ theme }) => css`
    position: relative;
    &::after {
      content: '';
      
      display:block;
      background-color: ${theme.colorPrimary};
      width: 4px;
      height: calc(100% + 9px);
      
      position: absolute;
      top: 10px;
      left: 10px;
    }
  `}
`;

interface SectionWrapperProps {
  verticalLine?: boolean
}

export const SectionWrapper = styled.div<ThemeProps<DefaultTheme> & SectionWrapperProps>`
  ${({ theme, verticalLine = false }) => css`
    padding-bottom: 10px;

    ${verticalLine && css`
      position: relative;
      &::after {
        content: '';

        display: block;
        background-color: ${theme.colorPrimary};
        width: 4px;
        height: calc(100% + 10px);

        position: absolute;
        top: 10px;
        left: 10px;
      }
    `}
  `}
`;
