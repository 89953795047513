import { IUserClassDataSource } from 'domain/repositories/api/userClass/dataSource/IUserClassDataSource';
import {
  IAddUsersPayload,
  ICreateInvitationLinkPayload, IDeleteInvitationLinkPayload, IGetInvitationLinksPayload,
  IGetStudentsPayload, IUseInvitationLinkPayload,
  IUserClassRepository,
} from 'domain/repositories/api/userClass/IUserClassRepository';

export class UserClassRepository implements IUserClassRepository {
  public constructor(private _ds: IUserClassDataSource) {}

  public getStudentsList = (payload: IGetStudentsPayload) => this._ds.getStudentsList(payload);

  public getTeachersList = (payload: IGetStudentsPayload) => this._ds.getTeachersList(payload);

  public addUsers = (payload: IAddUsersPayload) => this._ds.addUsers(payload);

  public getInvitationLinks = (p: IGetInvitationLinksPayload) => this._ds.getInvitationLinks(p);

  public createInvitationLink = (payload: ICreateInvitationLinkPayload) => (
    this._ds.createInvitationLink(payload)
  );

  public useInvitationLink = (payload: IUseInvitationLinkPayload) => (
    this._ds.useInvitationLink(payload)
  );

  public deleteInvitationLink = (payload: IDeleteInvitationLinkPayload) => (
    this._ds.deleteInvitationLink(payload)
  );
}
