import { useEffect } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, LexicalEditor } from 'lexical';

export default function LexicalDefaultValuePlugin({ value = '' }: { value?: string | null }) {
  const [editor] = useLexicalComposerContext();

  const updateHTML = (editor: LexicalEditor, value: string, clear: boolean) => {
    const root = $getRoot();
    const parser = new DOMParser();
    const dom = parser.parseFromString(value, 'text/html');
    const nodes = $generateNodesFromDOM(editor, dom);
    if (clear) {
      root.clear();
    }

    // Plugin throws exception if these types of node are being tried to append
    root.append(...nodes.filter((node) => !['linebreak', 'text'].includes(node.getType())));
  };

  useEffect(() => {
    if (editor && value) {
      editor.update(() => {
        updateHTML(editor, value, true);
      });
    }
  }, [value]);

  return null;
}
