import { Route } from 'application/routes/types';

export const route: Route = {
  base: '/',

  classManager: (classId = ':classId') => `/class-manager/${classId}`,
  classManagerNew: (classId = ':classId') => `/class-manager/${classId}/new`,
  classManagerStructureEditor: (classId = ':classId') => `/class-manager/${classId}/structure-editor`,
  moduleManager: (classId = ':classId', moduleId = ':moduleId') => `/class-manager/${classId}/modules/${moduleId}`,
  sectionManager: (classId = ':classId', moduleId = ':moduleId', sectionId = ':sectionId') => `/class-manager/${classId}/modules/${moduleId}/sections/${sectionId}`,
  taskManager: (classId = ':classId', moduleId = ':moduleId', sectionId = ':sectionId', taskId = ':taskId') => `/class-manager/${classId}/modules/${moduleId}/sections/${sectionId}/tasks/${taskId}`,
  taskLinkedTestGroups: (classId = ':classId', moduleId = ':moduleId', sectionId = ':sectionId', taskId = ':taskId') => `/class-manager/${classId}/modules/${moduleId}/sections/${sectionId}/tasks/${taskId}/linked-test-groups`,
  taskLinkedReferenceSolutions: (classId = ':classId', moduleId = ':moduleId', sectionId = ':sectionId', taskId = ':taskId') => `/class-manager/${classId}/modules/${moduleId}/sections/${sectionId}/tasks/${taskId}/linked-reference-solutions`,
  taskLinkedClasses: (classId = ':classId', moduleId = ':moduleId', sectionId = ':sectionId', taskId = ':taskId') => `/class-manager/${classId}/modules/${moduleId}/sections/${sectionId}/tasks/${taskId}/linked-classes`,

  sectionManagerSimplified: (classId = ':classId', sectionId = ':sectionId') => `/class-manager/${classId}/sections/${sectionId}`,
  taskManagerSimplified: (classId = ':classId', taskId = ':taskId') => `/class-manager/${classId}/tasks/${taskId}`,
  taskLinkedTestGroupsSimplified: (classId = ':classId', taskId = ':taskId') => `/class-manager/${classId}/tasks/${taskId}/linked-test-groups`,
  taskLinkedReferenceSolutionsSimplified: (classId = ':classId', taskId = ':taskId') => `/class-manager/${classId}/tasks/${taskId}/linked-reference-solutions`,
  taskLinkedClassesSimplified: (classId = ':classId', taskId = ':taskId') => `/class-manager/${classId}/tasks/${taskId}/linked-classes`,

  refSolution: (refSolutionId = ':refSolutionId') => `/reference-solutions/${refSolutionId}`,

  posts: (classId = ':classId') => `/classes/${classId}/posts`,
  themes: (classId = ':classId') => `/classes/${classId}/themes`,
  tasks: (classId = ':classId') => `/classes/${classId}/tasks`,
  task: (classId = ':classId', taskId = ':taskId') => `/classes/${classId}/tasks/${taskId}`,
  sendSolution: (classId = ':classId', taskId = ':taskId') => `/classes/${classId}/tasks/${taskId}/send-solution`,
  queue: (classId = ':classId') => `/classes/${classId}/queue`,
  postModeration: (classId = ':classId') => `/classes/${classId}/post-moderation`,
  submission: (classId = ':classId', solutionId = ':solutionId', submissionId = ':submissionId') => `/classes/${classId}/solutions/${solutionId}/submissions/${submissionId}`,
  users: (classId = ':classId') => `/classes/${classId}/users`,
  rating: (classId = ':classId') => `/classes/${classId}/rating`,

  solutionsHistory: (classId = ':classId', userId = ':userId', solutionId = ':solutionId') => `/classes/${classId}/solutions/${solutionId}/users/${userId}/solutions-history`,
  userClassResults: (classId = ':classId', userId = ':userId') => `/classes/${classId}/users/${userId}/results`,

  user: (userId = ':userId') => `/users/${userId}`,
  settings: () => '/user/settings',
  tsuAuth: '/tsu/auth',

  joinClass: (invitationLinkId = ':invitationLinkId') => `/class/invite/${invitationLinkId}`,

  sqlTest: '/sql-test',
  adminPanel: '/admin-panel',

  support: '/support',
  about: '/about',
};
