import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useHover } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import DarkThemeIcon from 'components/ui/atoms/icons/new/DarkTheme';
import LightThemeIcon from 'components/ui/atoms/icons/new/LightTheme';
import Flex from 'components/ui/atoms/flex/Flex';

import { useStore } from 'storesMobx/MobxStoreProvider';

import { LocaleType } from 'utils/locales';

import classes from './FooterButtons.module.scss';

function FooterButtons() {
  const { isDarkMode, toggleTheme } = useStore().themeStore;
  const { i18n } = useTranslation();

  const themeBtnRef = useRef(null);
  const isHoverThemeBtn = useHover(themeBtnRef);

  const ThemeIcon = isDarkMode ? DarkThemeIcon : LightThemeIcon;
  const ThemeIconHover = isDarkMode ? LightThemeIcon : DarkThemeIcon;

  const langBtnRef = useRef(null);
  const isHoverLangBtn = useHover(langBtnRef);

  const langLabel = i18n.language === LocaleType.RU ? 'RU' : 'EN';
  const langLabelHover = i18n.language === LocaleType.RU ? 'EN' : 'RU';

  const toggleLanguage = async () => {
    if (i18n.language === LocaleType.RU) {
      await i18n.changeLanguage(LocaleType.EN);
    } else {
      await i18n.changeLanguage(LocaleType.RU);
    }
  };

  return (
    <Flex justify="center" gap={24} className={classes.buttons_container}>
      <button type="button" onClick={toggleTheme}>
        <div className={classes.auth_button} ref={themeBtnRef}>
          {isHoverThemeBtn ? <ThemeIconHover /> : <ThemeIcon />}
        </div>
      </button>
      <button type="button" onClick={toggleLanguage}>
        <div className={classes.auth_button} ref={langBtnRef}>
          {isHoverLangBtn ? langLabelHover : langLabel}
        </div>
      </button>
    </Flex>
  );
}

export default observer(FooterButtons);
