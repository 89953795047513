import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IGetClassRatingPayload,
  IGetClassRatingResponse,
  IGetModuleRatingPayload,
  IGetModuleRatingResponse,
  IGetSectionRatingPayload,
  IGetSectionRatingResponse,
  IRatingRepository, IRecalculateClassRatingPayload, IRecalculateClassRatingResponse,
} from 'domain/repositories/api/rating/IRatingRepostiory';

export class RatingRepository implements IRatingRepository {
  private readonly prefix = `${process.env.REACT_APP_RATING_API}/rating`;

  public constructor(private _httpClient: IHttpClient) {}

  public getClassRating = ({ id, ...pagination }: IGetClassRatingPayload) => this._httpClient
    .get<IGetClassRatingResponse>(`${this.prefix}/class/${id}/get`, {
      ...pagination,
    });

  public getModuleRating = ({ classId, moduleId, ...pagination }: IGetModuleRatingPayload) => (
    this._httpClient
      .get<IGetModuleRatingResponse>(`${this.prefix}/class/${classId}/module/get`, {
        id: moduleId,
        ...pagination,
      })
  );

  public getSectionRating = ({ classId, sectionId, ...pagination }: IGetSectionRatingPayload) => (
    this._httpClient
      .get<IGetSectionRatingResponse>(`${this.prefix}/class/${classId}/section/get`, {
        id: sectionId,
        ...pagination,
      })
  );

  public recalculateClassRating = ({ id }: IRecalculateClassRatingPayload) => this._httpClient
    .post<IRecalculateClassRatingResponse>(`${this.prefix}/class/${id}/recalculate`);
}
