import {
  ILoginViaTsuAccountsPayload, ILoginViaTsuAccountsResponse,
} from 'domain/repositories/api/auth/IAuthRepository';
import { ApiUseCaseConstructorParams, FetchFunctionParams } from 'domain/useCases/common/types';
import { APIUseCase } from 'domain/useCases/common/APIUseCase';
import { ITokenRepository } from 'domain/repositories/other/interfaces/ITokenRepository';

import { CommonDataStore } from 'storesMobx/stores/CommonDataStore';
import { UserStore } from 'storesMobx/stores/UserStore';

import { deleteAllHttpCache } from 'utils/cache';

interface ConstructorParams extends ApiUseCaseConstructorParams<
  ILoginViaTsuAccountsPayload,
  ILoginViaTsuAccountsResponse
> {
  tokenRepository: ITokenRepository;
  userStore: UserStore;
  commonDataStore: CommonDataStore;
}

export class LoginViaTsuAccountsUseCase extends APIUseCase<
  ILoginViaTsuAccountsPayload,
  ILoginViaTsuAccountsResponse
> {
  private readonly tokenRepository: ITokenRepository;

  private readonly userStore: UserStore;

  private readonly commonDataStore: CommonDataStore;

  public constructor(params: ConstructorParams) {
    super(params);

    this.userStore = params.userStore;
    this.tokenRepository = params.tokenRepository;
    this.commonDataStore = params.commonDataStore;
  }

  public fetch = (
    params: FetchFunctionParams<ILoginViaTsuAccountsPayload, ILoginViaTsuAccountsResponse>,
  ): Promise<void> => (
    super.fetch({
      ...params,
      onSuccess: async (response) => {
        const { token, refreshToken } = response;

        deleteAllHttpCache();

        this.tokenRepository.setToken(token);
        this.tokenRepository.setRefreshToken(refreshToken);

        await this.userStore.onAuthenticateSuccess();
        await this.commonDataStore.fetch();

        params.onSuccess?.(response);
      },
    })
  );
}
