import initSqlJs, { Database } from 'sql.js';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';
import { TDynamicTable } from 'features/sqlEngine/types';
import sqlWasm from 'features/sqlEngine/sql-wasm.wasm';

export class SqlEngine {
  public tables: Record<string, TDynamicTable> = {};

  private _databaseInstance: Database | null = null;

  private readonly defaultColumnType = SqlColumnType.VARCHAR;

  public constructor() {
    (async () => {
      await this.instantiateDatabase();
    })();
  }

  public instantiateDatabase = async () => {
    this._databaseInstance?.close();

    const sql = await initSqlJs({ locateFile: () => sqlWasm });

    this._databaseInstance = new sql.Database();
  };

  public setTable = (tableName: string, table: TDynamicTable) => {
    this.tables = { ...this.tables, [tableName]: table };
  };

  public createTable = (tableName: string) => {
    const table = this.tables[tableName];

    if (!table) return;

    const { columns, values } = table;

    const columnsString = columns.map((column, columnIdx) => {
      const type = table.columnTypes
        ? (table.columnTypes[columnIdx] ?? this.defaultColumnType)
        : this.defaultColumnType;

      return `${column} ${type}`;
    }).join(', ');
    const valuesStrings = values.map((row) => `INSERT INTO '${tableName}' VALUES (${row.map((value) => `'${value}'`).join(', ')});`);

    const sqlString = `
      DROP TABLE IF EXISTS '${tableName}'; \n
      CREATE TABLE '${tableName}' (${columnsString}); \n
      ${valuesStrings.join('\n')}
    `;

    this._databaseInstance?.run(sqlString);
  };

  public executeStatement = (statement: string) => (
    this._databaseInstance?.exec(statement) as TDynamicTable[]
  );
}
