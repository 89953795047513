import React from 'react';
import loadable from '@loadable/component';

import 'ace-builds/src-noconflict/ace';
import Center from './components/ui/atoms/center/Center';
import Loader from './components/ui/atoms/loader/Loader';

const loadOptions = { fallback: <Center marginTop="500px"><Loader /></Center> };

export const ClassesPage = loadable(() => import('pages/classes/ClassesPageProvider'), loadOptions);

export const ClassPage = loadable(() => import('pages/classManager/class/ClassPageProvider'), loadOptions);
export const ClassPageNew = loadable(() => import('pages/classManager/classNew/ClassPageNewProvider'), loadOptions);
export const ClassStructureEditor = loadable(() => import('pages/classManager/classStructureEditor/ClassStructureEditorProvider'), loadOptions);
export const ModulePage = loadable(() => import('pages/classManager/module/ModulePageProvider'), loadOptions);
export const SectionPage = loadable(() => import('pages/classManager/section/SectionPageProvider'), loadOptions);
export const TaskPage = loadable(() => import('pages/classManager/task/TaskPageProvider'), loadOptions);
export const TaskLinkedTestGroupsPage = loadable(() => import('pages/classManager/taskLinkedConfigs/TaskLinkedConfigsPageProvider'), loadOptions);
export const TaskLinkedRefSolutions = loadable(() => import('pages/classManager/taskLinkedReferenceSolutions/TaskLinkedReferenceSolutionsPage'), loadOptions);
export const TaskLinkedClasses = loadable(() => import('pages/classManager/taskLinkedClasses/TaskLinkedClassesProvider'), loadOptions);

export const PostsPage = loadable(() => import('pages/class/posts/PostsPageProvider'), loadOptions);
export const TasksPage = loadable(() => import('pages/class/tasks/TasksPageProvider'), loadOptions);
export const TaskClassPage = loadable(() => import('pages/class/task/TaskClassPageProvider'), loadOptions);
export const SendSolutionPage = loadable(() => import('pages/class/sendSolution/SendSolutionPageProvider'), loadOptions);
export const QueuePage = loadable(() => import('pages/class/queue/QueuePageProvider'), loadOptions);
export const SolutionPostModeration = loadable(() => import('pages/class/solutionPostModeration/SolutionPostModerationProvider'), loadOptions);
export const SolutionsHistoryPage = loadable(() => import('pages/class/solutionsHistory/SolutionsHistoryPageProvider'), loadOptions);
export const UsersPage = loadable(() => import('pages/class/users/UsersPageProvider'), loadOptions);
export const RatingPage = loadable(() => import('pages/class/rating/RatingPage'), loadOptions);

export const ResultsUserPage = loadable(() => import('pages/class/resultsUser/ResutlsUserPageProvider'), loadOptions);
export const JoinClassPage = loadable(() => import('pages/joinClass/JoinClassPage'), loadOptions);
export const UserPage = loadable(() => import('pages/user/UserPageProvider'), loadOptions);
export const SettingsPage = loadable(() => import('pages/settings/SettingsPage'), loadOptions);

export const AdminPanelPage = loadable(() => import('pages/adminPanel/AdminPanelPageProvider'), loadOptions);

export const NotFoundPage = loadable(() => import('pages/notFound/NotFoundPage'), loadOptions);
