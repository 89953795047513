import { TDynamicTableExtended } from 'features/sqlEngine/types';
import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';

export const initialTables: TDynamicTableExtended[] = [{
  columns: ['id', 'name'],
  values: [
    [0, 'Viesturs Kauliņš'],
    [1, 'Volfrīds Valters'],
    [2, 'Arians Voicišs'],
    ...Array.from({ length: 1000 }, (x, i) => [i + 3, `some-name${i + 3}`]),
  ],
  columnTypes: [SqlColumnType.INT, SqlColumnType.VARCHAR],
  name: 'names',
}, {
  columns: ['id', 'score'],
  values: [
    [0, 34],
    [1, 5342],
  ],
  columnTypes: [SqlColumnType.INT, SqlColumnType.INT],
  name: 'scores',
}];

export const initialInputValue = `SELECT name, score
FROM names
JOIN scores ON names.id=scores.id
`;
