import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function PostIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 10C4 6.22876 4 4.34315 5.17157 3.17157C6.34315 2 8.22876 2 12 2H14C17.7712 2 19.6569 2 20.8284 3.17157C22 4.34315 22 6.22876 22 10V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H12C8.22876 22 6.34315 22 5.17157 20.8284C4 19.6569 4 17.7712 4 14V10Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M9 12H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9 8H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9 16H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
