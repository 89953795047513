export const getDataSource = (
  values: (string | number)[][],
  columns: string[],
) => {
  const res = values.map((value) => (
    columns.reduce((prev, cur, idx) => ({ ...prev, [cur]: value[idx] }), {})
  ));

  const header = columns.reduce((prev, cur) => ({ ...prev, [cur]: cur }), {});

  return [header, ...res];
};
