import React, { PropsWithChildren } from 'react';
import { m, LazyMotion, domAnimation } from 'framer-motion';

type TProps = PropsWithChildren & {
  isOpen: boolean;
  className?: string;
  disableOverflowHidden?: boolean;
}

export const COLLAPSE_DURATION_MS = 250;
const COLLAPSE_DURATION_S = COLLAPSE_DURATION_MS / 1000;

export default function Collapse(props: TProps) {
  const {
    isOpen, className, disableOverflowHidden, children,
  } = props;

  const animate = {
    transition: { type: 'tween', duration: COLLAPSE_DURATION_S },
    height: isOpen ? 'auto' : 0,
  };

  return (
    <LazyMotion features={domAnimation} strict>
      <div aria-expanded={isOpen} className={className}>
        <m.div
          style={{ overflow: disableOverflowHidden ? 'unset' : 'hidden' }}
          initial={{ height: 0, opacity: 1 }}
          animate={animate}
          exit={{ height: 0, opacity: 1 }}
        >
          {children}
        </m.div>
      </div>
    </LazyMotion>
  );
}
