const COLOR_PRIMARY = '#218DFF';
const COLOR_PRIMARY_SOFT = 'rgba(33,141,255,0.46)';
const COLOR_PRIMARY_SOFT_DARK = '#062242';

const COLOR_PRIMARY_HOVER_LIGHT = 'rgba(33,141,255,0.18)';
const COLOR_PRIMARY_HOVER_DARK = '#032b4d';

const COLOR_LINK_HOVER_LIGHT = '#8c8c8c';
const COLOR_LINK_HOVER_DARK = '#8c8c8c';

const COLOR_BG_CONTAINER_LIGHT = '#FFFFFF';
const COLOR_BG_CONTAINER_DARK = '#131417';

const COLOR_TEXT_LIGHT = '#1C1E21';
const COLOR_TEXT_DARK = '#a2a2a2';

const COLOR_GOLD = '#FFD700';
const COLOR_SILVER = '#C0C0C0';
const COLOR_BRONZE = '#CD7F32';

const grayPalette = {
  'gray-1': '#ffffff',
  'gray-2': '#fafafa',
  'gray-3': '#f5f5f5',
  'gray-4': '#f0f0f0',
  'gray-5': '#d9d9d9',
  'gray-6': '#bfbfbf',
  'gray-7': '#8c8c8c',
  'gray-8': '#595959',
  'gray-9': '#434343',
  'gray-10': '#262626',
  'gray-11': '#1f1f1f',
  'gray-12': '#141414',
  'gray-13': '#000000',
};

export const colors = {
  COLOR_PRIMARY,
  COLOR_PRIMARY_SOFT,
  COLOR_PRIMARY_SOFT_DARK,

  COLOR_PRIMARY_HOVER_LIGHT,
  COLOR_PRIMARY_HOVER_DARK,

  COLOR_LINK_HOVER_LIGHT,
  COLOR_LINK_HOVER_DARK,

  COLOR_BG_CONTAINER_LIGHT,
  COLOR_BG_CONTAINER_DARK,

  COLOR_TEXT_LIGHT,
  COLOR_TEXT_DARK,

  COLOR_GOLD,
  COLOR_SILVER,
  COLOR_BRONZE,

  grayPalette,
};
