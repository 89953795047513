import React from 'react';
import clsx from 'clsx';

import classes from './Loader.module.scss';

type TProps = {
  fixedCentered?: boolean;
  className?: string;
  size?: 'small' | 'medium';
};

export default function Loader(props: TProps) {
  const { fixedCentered, className, size = 'medium' } = props;

  return (
    <div className={clsx(classes.loader, className, {
      [classes.loader_fixed_centered]: fixedCentered,
      [classes.loader_size_small]: size === 'small',
    })}
    />
  );
}
