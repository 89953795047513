export type ShowErrorFunction = (errorType: ErrorNotificationType | string) => void;

export enum ErrorNotificationType {
  INCORRECT_LOGIN_OR_PASSWORD = 'incorrectLoginOrPassword',
  FAILED_TO_FETCH_DATA = 'failedToFetchData',
  FAILED_TO_SEND_DATA = 'failedToSendData',
  SOMETHING_WENT_WRONG = 'somethingWentWrong',
  FAILED_TO_JOIN_CLASS = 'failedToJoinClass',
  FAILED_TO_CREATE = 'failedToCreate',
  FAILED_TO_UPDATE = 'failedToUpdate',
  FAILED_TO_DELETE = 'failedToDelete',
  FAILED_TO_CLONE = 'failedToClone',
  FAILED_TO_RECALCULATE = 'failedToRecalculate',
  FAILED_TO_REGISTER = 'failedToRegister',
  FAILED_TO_REGISTER_AGENT = 'failedToRegisterAgent',
  FAILED_TO_REPORT = 'failedToReport',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'notFound',
}

export type ShowSuccessNotificationFunction = (
  successMessageType: SuccessNotificationType | string
) => void;

export enum SuccessNotificationType {
  CHANGES_SUCCESSFULLY_SAVED = 'changesSuccessfullySaved',
  SOLUTION_SUCCESSFULLY_SENT = 'solutionSuccessfullySent',
  JOINED_CLASS_SUCCESSFULLY = 'joinedClassSuccessfully',
  CREATED_SUCCESSFULLY = 'createdSuccessfully',
  DELETED_SUCCESSFULLY = 'deletedSuccessfully',
  FORKED_SUCCESSFULLY = 'forkedSuccessfully',
  RECALCULATED_SUCCESSFULLY = 'recalculatedSuccessfully',
  REGISTERED_SUCCESSFULLY = 'registeredSuccessfully',
  REGISTERED_AGENT_SUCCESSFULLY = 'registeredAgentSuccessfully',
  CREATED_REPORT = 'createdReport',
}
