import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import NotificationsProvider from 'application/notification/NotificationsProvider';
import ModalConfirmProvider from 'application/confirmModal/ModalConfirmProvider';
import AuthenticationProvider from 'application/authentication/AuthenticationProvider';
import AuthorizationProvider from 'application/authorization/AuthorizationProvider';
import ReactBoundary from 'application/errrorBoundary/ErrorBoundaryProvider';

import Sidebar from 'components/ui/organisms/sidebar/SidebarProvider';
import EntityDrawerProvider from 'components/ui/organisms/entityDrawer/provider/EntityDrawerProvider';
import ModalProvider from 'components/ui/organisms/modal/context/ModalProvider';
import ClassContentLayout from 'components/ui/layout/classContentLayout/ClassContentLayout';

import ThemeProviderAnt from 'theme/ThemeProviderAnt';
import ThemeProviderStyledComponents from 'theme/ThemeProviderStyledComponents';

import { MobxStoreProvider } from 'storesMobx/MobxStoreProvider';

import AppLayout from './components/ui/layout/appLayout/AppLayout';
import ConfirmationModalProvider from './application/confirmationModal/ConfirmationModalProvider';
import { FlashMessageContext } from './components/ui/molecules/richEditor/context/FlashMessageContext';

const RootElement: React.FC = () => (
  <MobxStoreProvider>
    <ThemeProviderAnt>
      <ThemeProviderStyledComponents>
        <ReactBoundary>
          <NotificationsProvider>
            <ModalConfirmProvider>
              <ConfirmationModalProvider>
                <EntityDrawerProvider>
                  <ModalProvider>
                    <AuthenticationProvider>
                      <AuthorizationProvider>
                        <AppLayout>
                          <Sidebar />
                          <ClassContentLayout>
                            <FlashMessageContext>
                              <ScrollRestoration />
                              <Outlet />
                            </FlashMessageContext>
                          </ClassContentLayout>
                        </AppLayout>
                      </AuthorizationProvider>
                    </AuthenticationProvider>
                  </ModalProvider>
                </EntityDrawerProvider>
              </ConfirmationModalProvider>
            </ModalConfirmProvider>
          </NotificationsProvider>
        </ReactBoundary>
      </ThemeProviderStyledComponents>
    </ThemeProviderAnt>
  </MobxStoreProvider>
);

export default RootElement;
