import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  IPostDataSource,
  IGetPostsPayloadDS,
  IGetPostsResponseDS,
  ICreatePostPayloadDS,
  ICreatePostResponseDS,
  IEditPostPayloadDS,
  IEditPostResponseDS, IDeletePostPayloadDS, IDeletePostResponseDS,
} from 'domain/repositories/api/post/dataSource/IPostDataSource';

import { SearchQueryOperators } from 'utils/enums/SearchQueryOperators';

export class PostClassDataSource implements IPostDataSource {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/class`;

  public constructor(private _httpClient: IHttpClient) {}

  public getPosts = async ({ classId, filters, ...pagination }: IGetPostsPayloadDS) => (
    this._httpClient.get<IGetPostsResponseDS>(`${this.prefix}/${classId}/post/get`, {
      ...pagination,
      id: filters?.id && `${SearchQueryOperators.EQUAL}${filters.id}`,
      title: filters?.title && `${SearchQueryOperators.LIKE}${filters.title}`,
    })
  );

  public createPost = ({ classId, ...body }: ICreatePostPayloadDS) => this._httpClient
    .post<ICreatePostResponseDS>(`${this.prefix}/${classId}/post/add`, body);

  public editPost = ({ classId, postId, ...body }: IEditPostPayloadDS) => this._httpClient
    .post<IEditPostResponseDS>(`${this.prefix}/${classId}/post/${postId}/update`, body);

  public deletePost = ({ classId, postId }: IDeletePostPayloadDS) => this._httpClient
    .post<IDeletePostResponseDS>(`${this.prefix}/${classId}/post/${postId}/delete`);
}
