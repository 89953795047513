import React, { useRef, useState } from 'react';
import { Popover } from 'antd';

import FiltrationIcon from 'components/ui/atoms/icons/new/Filtration';
import DeleteIcon from 'components/ui/atoms/icons/new/Delete';
import PlusIcon from 'components/ui/atoms/icons/PlusIcon';
import Flex from 'components/ui/atoms/flex/Flex';
import { EditableTableAbility } from 'components/ui/organisms/editableTable/enums/editableTableAbility';
import TypePopoverContent from 'components/ui/organisms/editableTable/ui/TypePopoverContent';
import { useTableWrapper } from 'components/ui/organisms/editableTable/hooks/useTableWrapper';
import { ADD_COLUMN_NAME } from 'components/ui/organisms/editableTable/constants';
import IconButton from 'components/ui/atoms/iconButton/IconButton';

import { SqlColumnType } from 'features/sqlEngine/enums/ColumnType';

import { sleep } from 'utils/asyncHelpers';

type TitleColumnProps = {
  id: string;
  column: string;
  abilities: EditableTableAbility[];
  columnIdx: number;
  onAddColumn: () => void;
  onEditColumn: (columnIdx: number, newValue: string) => void;
  onDeleteColumn: (columnIdx: number) => void;
  sqlColumnType?: SqlColumnType;
  editColumnType?: (columnIdx: number, columnType: SqlColumnType) => void;
}

const TitleColumn: React.FC<TitleColumnProps> = (props) => {
  const {
    id,
    column,
    abilities,
    columnIdx,
    onAddColumn,
    onEditColumn,
    onDeleteColumn,
    sqlColumnType,
    editColumnType,
  } = props;

  const { tableWrapperRef } = useTableWrapper({ id });
  const paragraphRef = useRef<HTMLParagraphElement | null>(null);

  const [openColumnTypePopover, setOpenColumnTypePopover] = useState(false);

  const handleAddColumn = () => {
    onAddColumn();

    sleep(0).then(() => {
      if (tableWrapperRef.current) {
        tableWrapperRef.current.scrollTo({
          left: tableWrapperRef.current?.scrollWidth,
          top: tableWrapperRef.current?.scrollTop,
          behavior: 'smooth',
        });
      }
    });
  };

  if (column !== ADD_COLUMN_NAME) {
    return (
      <Flex align="center" gap={8}>
        <p
          contentEditable={abilities.includes(EditableTableAbility.EDIT_COLUMN)}
          suppressContentEditableWarning
          ref={paragraphRef}
          onBlur={() => onEditColumn(columnIdx, (paragraphRef.current as HTMLElement).innerText)}
        >
          {column}
        </p>
        {abilities.includes(EditableTableAbility.EDIT_COLUMN) && (
          <Flex gap={2}>
            {abilities.includes(EditableTableAbility.DELETE_COLUMN) && (
              <IconButton
                icon={<DeleteIcon />}
                danger
                onClick={() => onDeleteColumn(columnIdx)}
              />
            )}
            <Popover
              open={openColumnTypePopover}
              onOpenChange={setOpenColumnTypePopover}
              content={(
                <TypePopoverContent
                  value={sqlColumnType ?? SqlColumnType.VARCHAR}
                  onChange={(type) => editColumnType?.(columnIdx, type)}
                  closePopover={() => setOpenColumnTypePopover(false)}
                />
              )}
              title="Column type"
              trigger="click"
            >
              <IconButton icon={<FiltrationIcon />} />
            </Popover>
          </Flex>
        )}
      </Flex>
    );
  }

  if (abilities.includes(EditableTableAbility.ADD_COLUMN)) {
    return (
      <IconButton icon={<PlusIcon />} type="default" onClick={handleAddColumn} />
    );
  }

  return null;
};

export default TitleColumn;
