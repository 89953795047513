import axios from 'axios';
import { getResponseInterceptorOnRejected } from 'data/axios/responseInterceptor';
import { requestInterceptorOnFulfilled, requestInterceptorOnRejected } from 'data/axios/requestInterceptor';

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  requestInterceptorOnFulfilled,
  requestInterceptorOnRejected,
);

axiosInstance.interceptors.response.use(
  (response) => response,
  getResponseInterceptorOnRejected(axiosInstance),
);
