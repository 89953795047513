import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useSidebarViewModel } from 'components/ui/organisms/sidebar/viewModel/context';
import LinkRouter from 'components/ui/atoms/link/LinkRouter';
import Paragraph from 'components/ui/atoms/typography/Paragraph';
import Flex from 'components/ui/atoms/flex/Flex';
import { ISidebarItem } from 'components/ui/organisms/sidebar/types';

import classes from './LinkSidebar.module.scss';

type TProps = PropsWithChildren & {
  link: ISidebarItem;
  className?: string;
};

function LinkSidebar(props: TProps) {
  const {
    link, children, className, ...rest
  } = props;

  const {
    text, to, isActive, icon, onClick,
  } = link;

  const { isSidebarCollapsed } = useSidebarViewModel();

  const renderInnerContent = () => (
    <Flex
      align="center"
      justify="space-between"
      gap={8}
      className={clsx(classes.link_sidebar, {
        [classes.link_sidebar_is_active]: isActive,
        [classes.link_sidebar_is_sidebar_collapsed]: isSidebarCollapsed,
      }, className)}
      onClick={onClick}
      {...rest}
    >
      <Flex align="center" gap={8}>
        {icon}
        {!isSidebarCollapsed && (
          <Paragraph>{text}</Paragraph>
        )}
      </Flex>
      {!isSidebarCollapsed ? children : null}
    </Flex>
  );

  if (to) {
    return (
      <LinkRouter to={to}>
        {renderInnerContent()}
      </LinkRouter>
    );
  }

  return renderInnerContent();
}

export default observer(LinkSidebar);
