import React, { PropsWithChildren } from 'react';

import classes from './TwoColumns.module.scss';

export default function TwoColumns(props: PropsWithChildren) {
  const { children } = props;

  return (
    <div className={classes.two_columns}>
      {children}
    </div>
  );
}
