import { httpClient } from 'data/network/httpClient/HttpClient';

import { BatchRepository } from 'domain/repositories/api/batch/BatchRepository';
import { AdministrationRepository } from 'domain/repositories/api/administration/AdministrationRepository';
import { RatingRepository } from 'domain/repositories/api/rating/RatingRepository';
import { StudyGroupRepository } from 'domain/repositories/api/studyGroup/StudyGroupRepository';
import { SubdivisionRepository } from 'domain/repositories/api/subdivision/SubdivisionRepository';
import { UniversityRepository } from 'domain/repositories/api/university/UniversityRepository';
import { PermissionRepository } from 'domain/repositories/api/permission/PermissionRepository';
import { SolutionCommentDataSource } from 'domain/repositories/api/solutionComment/datasource/SolutionCommentDataSource';
import { SolutionCommentRepository } from 'domain/repositories/api/solutionComment/SolutionCommentRepository';
import { UserClassDataSource } from 'domain/repositories/api/userClass/dataSource/UserClassDataSource';
import { UserClassRepository } from 'domain/repositories/api/userClass/UserClassRepository';
import { ProxyRepository } from 'domain/repositories/api/proxy/ProxyRepository';
import { SolutionDataSource } from 'domain/repositories/api/solution/dataSource/SolutionDataSource';
import { UserRepository } from 'domain/repositories/api/user/UserRepository';
import { ModuleDataSource } from 'domain/repositories/api/module/dataSource/ModuleDataSource';
import { ModuleRepository } from 'domain/repositories/api/module/ModuleRepository';
import { TaskDataSource } from 'domain/repositories/api/task/dataSource/TaskDataSource';
import { TaskRepository } from 'domain/repositories/api/task/TaskRepository';
import { SubjectTypeRepository } from 'domain/repositories/api/subjectType/SubjectTypeRepository';
import { PostClassDataSource } from 'domain/repositories/api/post/dataSource/PostDataSource';
import { PostRepository } from 'domain/repositories/api/post/PostRepository';
import { SectionsRepository } from 'domain/repositories/api/section/SectionsRepository';
import { SectionsDataSource } from 'domain/repositories/api/section/dataSource/SectionsDataSource';
import { ClassRepository } from 'domain/repositories/api/class/ClassRepository';
import { ClassDataSource } from 'domain/repositories/api/class/dataSource/ClassDataSource';
import { AuthRepository } from 'domain/repositories/api/auth/AuthRepository';
import { SolutionRepository } from 'domain/repositories/api/solution/SolutionRepository';

import { UserStore } from 'storesMobx/stores/UserStore';

import { AgentRepository } from './agent/AgentRepository';
import { ReportRepository } from './report/ReportRepository';

export const authRepository = new AuthRepository(httpClient);

export const userRepository = new UserRepository(httpClient);

const classesDS = new ClassDataSource(httpClient);
export const classRepository = new ClassRepository(classesDS);

const moduleDS = new ModuleDataSource(httpClient);
export const moduleRepository = new ModuleRepository(moduleDS);

const sectionsDS = new SectionsDataSource(httpClient);
export const sectionRepository = new SectionsRepository(sectionsDS);

const tasksClassDS = new TaskDataSource(httpClient);
export const taskClassRepository = new TaskRepository(tasksClassDS);

export const subjectTypeRepository = new SubjectTypeRepository(httpClient);

export const universityRepository = new UniversityRepository(httpClient);

export const subdivisionRepository = new SubdivisionRepository(httpClient);

export const studyGroupRepository = new StudyGroupRepository(httpClient);

const postDS = new PostClassDataSource(httpClient);
export const postRepository = new PostRepository(postDS);

const solutionDS = new SolutionDataSource(httpClient);
export const solutionRepository = new SolutionRepository(solutionDS);

const solutionCommentDS = new SolutionCommentDataSource(httpClient);
export const solutionCommentRepository = new SolutionCommentRepository(solutionCommentDS);

const usersDS = new UserClassDataSource(httpClient);
export const userClassRepository = new UserClassRepository(usersDS);

export const permissionRepository = new PermissionRepository(httpClient);

export const proxyRepository = new ProxyRepository(httpClient);

export const ratingRepository = new RatingRepository(httpClient);

export const administrationRepository = new AdministrationRepository(httpClient);

export const batchRepository = new BatchRepository(httpClient);

export const agentRepository = new AgentRepository(httpClient);

export const reportRepository = new ReportRepository(httpClient);

// due to problems with cross-imports I had to put it here
export const userStore = new UserStore();
