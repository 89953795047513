import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { AxiosError } from 'axios';

import { MessageError } from 'domain/repositories/api/common';

import { useAuthPageViewModel } from 'pages/auth/viewModel/context';

import { ns } from 'utils/locales';

const schema = (t: TFunction) => z
  .object({
    name: z.string().min(4),
    email: z.string().min(1).email(),
    password: z.string().min(8),
    passwordConfirmation: z.string().min(8),
  })
  .refine(({ password, passwordConfirmation }) => password === passwordConfirmation, {
    message: t(`${ns.errors}:passwordsMismatch`),
    path: ['passwordConfirmation'],
  });

export type TRegisterFormState = z.infer<ReturnType<typeof schema>>;

type TParams = {
  disableRegisterMode: () => void;
}

export default function useRegisterForm(params: TParams) {
  const { disableRegisterMode } = params;

  const { register } = useAuthPageViewModel();
  const { t } = useTranslation();

  const methods = useForm<TRegisterFormState>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    resolver: zodResolver(schema(t)),
  });

  const handleSubmit = async ({ name, email, password }: TRegisterFormState) => {
    try {
      await register({ name, email, password }, disableRegisterMode);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data.message === MessageError.ENTITY_ALREADY_EXISTS) {
          methods.setError('email', { message: t(`${ns.errors}:emailAlreadyExists`) });
        }
      }
    }
  };

  return { methods, handleSubmit };
}
