import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function InfoIcon(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
      <path d="M12 17V11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="currentColor" />
    </svg>
  );
}
