import { createContext, useContext } from 'react';

import { IClass } from 'domain/entities/class';

export type TClassContext = {
  classDetails: IClass;
}

export const ClassContext = createContext<
  TClassContext | undefined
>(undefined);

export const useClassContext = () => {
  const contextValue = useContext(ClassContext);

  if (!contextValue) throw new Error();

  return contextValue;
};
