import React, {
  ChangeEvent, ReactElement, ReactNode, useState,
} from 'react';
import { InputProps, Input as CuiInput } from 'antd';
import clsx from 'clsx';
import { TextAreaProps } from 'antd/es/input';

import ShowIcon from 'components/ui/atoms/icons/new/Show';
import HideIcon from 'components/ui/atoms/icons/new/Hide';
import Paragraph from 'components/ui/atoms/typography/Paragraph';
import Collapse from 'components/animations/collapseHidden/Collapse';
import ClearIcon from 'components/ui/atoms/icons/new/Clear';

import { sleep } from 'utils/asyncHelpers';

import classes from './Input.module.scss';

export type TInputProps = Omit<InputProps & TextAreaProps, 'onChange' | 'prefix'> & {
  dropdown?: ReactElement;
  dropdownContainerClassname?: string;
  dropdownVisible?: boolean;
  error?: boolean;
  errorMessage?: string;
  prefix?: ReactNode;
  inputContainerClassName?: string;
  onChange?: (value: string) => void;
  iconRender?: () => void,
};

const BLUR_DELAY_MS = 100;

export default function Input(props: TInputProps) {
  const {
    className,
    allowClear,
    prefix,
    inputContainerClassName,
    dropdown,
    dropdownContainerClassname,
    dropdownVisible = true,
    error = false,
    errorMessage,
    iconRender,
    onChange,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = async () => {
    await sleep(BLUR_DELAY_MS);
    setIsFocused(false);
  };

  const InputTag = rest.type === 'password' ? CuiInput.Password : rest.type === 'textarea' ? CuiInput.TextArea : CuiInput;

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange?.(e.target.value);
  };

  const isDropdownVisible = dropdownVisible && isFocused;

  const renderInput = () => (
    <div className={inputContainerClassName}>
      <InputTag
        {...rest}
        allowClear={allowClear && { clearIcon: <ClearIcon /> }}
        className={clsx(classes.input, className, {
          [classes.input_error]: error,
        })}
        // @ts-ignore
        prefix={prefix ?? undefined}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...(props.type === 'password'
          ? { iconRender: (visible) => (visible ? <ShowIcon /> : <HideIcon />) }
          : {}
        )}
      />
      <Collapse isOpen={!!errorMessage}>
        <Paragraph color="error" className={classes.error_message}>{errorMessage}</Paragraph>
      </Collapse>
    </div>
  );

  return (
    !dropdown ? renderInput() : (
      <div className={clsx(classes.dropdown_container, dropdownContainerClassname)}>
        {renderInput()}
        <Collapse
          isOpen={isDropdownVisible}
          className={clsx(classes.dropdown, {
            [classes.dropdown_is_open]: isDropdownVisible,
          })}
        >
          {dropdown}
        </Collapse>
      </div>
    )
  );
}
