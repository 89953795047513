import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import classes from './ButtonNonUi.module.scss';

type TProps = HTMLAttributes<HTMLButtonElement>;

export default function ButtonNonUi(props: TProps) {
  const { className, ...rest } = props;

  return (
    <button type="button" className={clsx(className, classes.button_non_ui)} {...rest} />
  );
}
