export interface IResponse {
  status: ResponseStatus;
  trackingID: string;
}

export interface AxiosErrorData {
  blame: ResponseErrorBlame;
  message: string;
  status: ResponseStatus;
  systemCode?: number;
  systemMessage?: string;
  trackingID: string;
}

export enum MessageError {
  ENTITY_ALREADY_EXISTS = 'Entity already exist',
}

export type ResponseStatus = 'OK' | 'Error' | 'Not Found' | 'Internal Server Error';
export type ResponseErrorBlame = 'User' | 'Kafka' | 'Postgres' | 'S3' | 'Server' | 'Permission Service' | 'Unknown';

export interface IPaginationResponse {
  total: number;
}

export interface IEntityId {
  id: string;
}

export interface IEntityIdCaps {
  ID: string;
}

export interface IEntityLink {
  id: string;
  name: string;
}

export interface IPaginationPayload {
  page?: number;
  limit?: number;
  sort?: string;
}
