import {
  action, makeObservable, observable, runInAction,
} from 'mobx';

import { IPermission, Permission } from 'domain/entities/permission';
import { GetGlobalPermissionsUseCase } from 'domain/useCases/permissions/GetGlobalPermissionsUseCase';
import { GetPlatformPermissionsUseCase } from 'domain/useCases/permissions/GetPlatformPermissionsUseCase';
import { ClassRole, IClassRole } from 'domain/entities/class';
import { GetClassRolesUseCase } from 'domain/useCases/permissions/GetClassRolesUseCase';
import { GetMeUseCase } from 'domain/useCases/user/GetMeUseCase';
import { IUserMe } from 'domain/entities/user';
import { permissionRepository, userRepository } from 'domain/repositories/api';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

export class UserStore {
  @observable public isAuthenticated = true;

  @observable public profile: IUserMe | undefined;

  @observable public classRoles: IClassRole[] = [];

  @observable public globalPermissions : IPermission[] = [];

  @observable public platformPermissions : IPermission[] = [];

  @observable public loadStatus = new LoadStatus();

  @observable public isSuperAdmin = false;

  private getMeUseCase: GetMeUseCase;

  private getClassRolesUseCase: GetClassRolesUseCase;

  private getGlobalPermissions: GetGlobalPermissionsUseCase;

  private getPlatformPermissions: GetPlatformPermissionsUseCase;

  public constructor() {
    makeObservable(this);

    this.getMeUseCase = new GetMeUseCase({
      requestCallback: userRepository.getMe,
    });

    this.getClassRolesUseCase = new GetClassRolesUseCase({
      requestCallback: permissionRepository.getClassRoles,
    });

    this.getGlobalPermissions = new GetGlobalPermissionsUseCase({
      requestCallback: permissionRepository.getGlobalPermissions,
    });

    this.getPlatformPermissions = new GetPlatformPermissionsUseCase({
      requestCallback: permissionRepository.getPlatformPermissions,
    });
  }

  public getRoleInClass = (classId: string): ClassRole => {
    const classRole = this.classRoles.find(({ classId: curClassId }) => curClassId === classId);
    return classRole?.classRole ?? ClassRole.STUDENT;
  };

  public isTsuAccount = (): boolean => {
    if (!this.profile || !this.profile.accountId) return false;

    const { accountId } = this.profile;

    const isZeroGuid = !accountId
      .replaceAll('-', '')
      .replaceAll('0', '')
      .length;

    return !isZeroGuid;
  };

  @action public onAuthenticateSuccess = async () => {
    await this.fetch();
    runInAction(() => {
      this.isAuthenticated = true;
    });
  };

  @action public updateUsername = (name: string) => {
    if (!this.profile) return;

    this.profile = { ...this.profile, name };
  };

  @action public fetch = async () => {
    this.loadStatus.onStartRequest();

    const promises = [
      this.getMeUseCase.fetch({
        payload: undefined,
        onSuccess: ({ user }) => runInAction(() => {
          this.profile = user;
        }),
      }),
      this.getClassRolesUseCase.fetch({
        payload: undefined,
        onSuccess: ({ roles }) => runInAction(() => {
          this.classRoles = roles;
        }),
      }),
      this.getGlobalPermissions.fetch({
        payload: undefined,
        onSuccess: ({ permissions }) => runInAction(() => {
          this.globalPermissions = permissions ?? [];
          this.isSuperAdmin = !!permissions?.find((p) => p.permission === Permission.SUPER_ADMIN);
        }),
      }),
      this.getPlatformPermissions.fetch({
        payload: undefined,
        onSuccess: ({ permissions }) => runInAction(() => {
          this.platformPermissions = permissions ?? [];
          this.isSuperAdmin = !!permissions?.find((p) => p.permission === Permission.SUPER_ADMIN);
        }),
      }),
    ];

    await Promise.all(promises);

    this.loadStatus.onEndRequest();
  };

  @action public resetStore = () => {
    this.isAuthenticated = false;
    this.profile = undefined;
    this.loadStatus.onStartRequest();
  };
}
