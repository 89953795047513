import React, { createContext, useContext, useMemo } from 'react';

import Modal from 'components/ui/organisms/modal/Modal';
import { ModalViewModel } from 'components/ui/organisms/modal/ModalViewModel';

import { IChildren } from 'utils/interfaces/IChildren';

export const ModalVmContext = createContext<ModalViewModel>(
  new ModalViewModel(),
);

export const useModalViewModel = () => useContext(ModalVmContext);

const ModalProvider: React.FC<IChildren> = ({ children }) => {
  const vm = useMemo(() => new ModalViewModel(), []);

  return (
    <ModalVmContext.Provider value={vm}>
      {children}
      <Modal />
    </ModalVmContext.Provider>
  );
};

export default ModalProvider;
