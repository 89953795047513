import React from 'react';
import { Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import { useNavigate } from 'react-router-dom';

import { route } from 'application/routes/route';

import BackIcon from 'components/ui/atoms/icons/new/Back';
import HomeIcon from 'components/ui/atoms/icons/new/Home';
import Flex from 'components/ui/atoms/flex/Flex';
import Loader from 'components/ui/atoms/loader/Loader';
import Center from 'components/ui/atoms/center/Center';
import Button from 'components/ui/atoms/button/Button';

import classes from './WithLoader.module.scss';

const SUPPORTED_CODES = [403, 404, 500];

interface ComponentWithLoaderProps {
  isLoading: boolean;
  isFailed?: boolean;
  errorCode?: number;
}

const WithLoader = <T, >(Component: React.FC<any>, isLoaderCentered: boolean = false) => {
  const ComponentWithLoader: React.FC<ComponentWithLoaderProps & T> = ({
    isLoading,
    isFailed = false,
    errorCode,
    ...props
  }) => {
    const navigate = useNavigate();

    const onBack = () => {
      navigate(-1);
    };

    const onMainPage = () => {
      navigate(route.base);
    };

    const status = errorCode && SUPPORTED_CODES.includes(errorCode) ? errorCode as ResultStatusType : '500';

    return (
      isLoading
        ? <Center height="400px"><Loader /></Center>
        : isFailed
          ? (
            <div className={classes.page_wrapper}>
              <Result
                status={status}
                title={errorCode}
                subTitle="Произошла ошибка при выполнении запроса"
                extra={(
                  <Flex justify="center" gap={12}>
                    <Button type="primary" icon={<BackIcon />} onClick={onBack}>Назад</Button>
                    <Button type="primary" icon={<HomeIcon />} onClick={onMainPage}>На главную</Button>
                  </Flex>
                )}
              />
            </div>
          )
          : <Component {...props} />
    );
  };

  return ComponentWithLoader;
};

export default WithLoader;
