import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function ShowIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.27489 15.2957C3.42496 14.1915 3 13.6394 3 12C3 10.3606 3.42496 9.80853 4.27489 8.70433C5.97196 6.49956 8.81811 4 13 4C17.1819 4 20.028 6.49956 21.7251 8.70433C22.575 9.80853 23 10.3606 23 12C23 13.6394 22.575 14.1915 21.7251 15.2957C20.028 17.5004 17.1819 20 13 20C8.81811 20 5.97196 17.5004 4.27489 15.2957Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M16 12C16 13.6569 14.6569 15 13 15C11.3431 15 10 13.6569 10 12C10 10.3431 11.3431 9 13 9C14.6569 9 16 10.3431 16 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
