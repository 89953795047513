import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';

import 'index.scss';
import 'antd/dist/reset.css';
import 'i18n/i18n';

window['__react-beautiful-dnd-disable-dev-warnings'] = true;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <RouterProvider router={router} />,
);
