import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useNotifications } from 'application/notification/useNotifications';

import { CreateReportUseCase } from 'domain/useCases/report/CreateReportUseCase';
import { reportRepository } from 'domain/repositories/api';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

const createReportLoadStatus = new LoadStatus(false);

const schema = z.object({
  tagId: z.string(),
  description: z.string().min(1),
});

export type TReportFormState = z.infer<typeof schema>;

export default function useReportForm() {
  const { notifySuccess, notifyError } = useNotifications();

  const createReport = new CreateReportUseCase({
    requestCallback: reportRepository.createReport,
    notifyError,
    notifySuccess,
  });

  const methods = useForm<TReportFormState>({
    defaultValues: {
      tagId: '',
      description: '',
    },
    resolver: zodResolver(schema),
  });

  const handleSubmit = async ({ tagId, description }: TReportFormState) => {
    await createReport.fetch({
      payload: { description, tagId },
      loadStatus: createReportLoadStatus,
      onSuccess: () => {
        methods.reset();
      },
    });
  };

  return { methods, loading: createReportLoadStatus.isLoading, handleSubmit };
}
