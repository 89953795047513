import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Button from 'components/ui/atoms/button/Button';
import Paragraph from 'components/ui/atoms/typography/Paragraph';
import Flex from 'components/ui/atoms/flex/Flex';
import FormInput from 'components/ui/molecules/form/FormInput';
import Form from 'components/ui/molecules/form/Form';

import useRegisterMode from 'pages/auth/hooks/useRegisterMode';
import { useAuthPageViewModel } from 'pages/auth/viewModel/context';
import useRegisterForm from 'pages/auth/hooks/useRegisterForm';

import { ns } from 'utils/locales';

import classes from './LoginForm.module.scss';

function RegisterForm() {
  const { pageStatus } = useAuthPageViewModel();
  const { t } = useTranslation();
  const { disableRegisterMode } = useRegisterMode();
  const { methods, handleSubmit } = useRegisterForm({ disableRegisterMode });

  const placeholder = t(`${ns.placeholders}:startTypingText`);

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <Flex vertical gap={24}>
        <FormInput name="name" id="login-form-name" label={t(`${ns.placeholders}:name`)} placeholder={placeholder} />
        <FormInput name="email" id="login-form-email" type="email" label={t(`${ns.placeholders}:email`)} placeholder={placeholder} />
        <FormInput name="password" id="login-form-password" type="password" label={t(`${ns.placeholders}:password`)} placeholder={placeholder} />
        <FormInput name="passwordConfirmation" id="login-form-password-confirmation" type="password" label={t(`${ns.placeholders}:confirmPassword`)} placeholder={placeholder} />
        <Flex vertical gap={12} className={classes.buttons_container}>
          <Button variant="accent-alt" htmlType="submit" loading={pageStatus.isLoading}>{t(`${ns.buttons}:register`)}</Button>
          <Paragraph weight="bold" className={classes.or_label}>{t(`${ns.common}:or`)}</Paragraph>
          <Button variant="accent" onClick={disableRegisterMode}>{t(`${ns.buttons}:login`)}</Button>
        </Flex>
      </Flex>
    </Form>
  );
}

export default observer(RegisterForm);
