import React, { PropsWithChildren } from 'react';

import Flex from 'components/ui/atoms/flex/Flex';

type TProps = PropsWithChildren & {
  height?: string;
  marginTop?: string;
  className?: string;
};

export default function Center(props: TProps) {
  const {
    children, height = '100%', marginTop, className,
  } = props;

  return (
    <Flex justify="center" align="center" className={className} style={{ height, marginTop }}>
      {children}
    </Flex>
  );
}
