import React from 'react';
import { ButtonProps, Button as CuiButton } from 'antd';
import clsx from 'clsx';

import classes from './Button.module.scss';
import { TButtonVariant } from './types';
import { getClassNameVariant } from './helpers/getClassNameVariant';

type TProps = ButtonProps & {
  variant?: TButtonVariant;
};

export default function Button(props: TProps) {
  const { className, variant, ...rest } = props;

  return (
    <CuiButton
      className={clsx(classes.button, getClassNameVariant(variant), className)}
      {...rest}
    />
  );
}
