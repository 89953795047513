import React from 'react';
import { useTranslation } from 'react-i18next';

import Flex from 'components/ui/atoms/flex/Flex';
import Button from 'components/ui/atoms/button/Button';

import { ns } from 'utils/locales';

import Modal, { TModalProps } from './Modal';
import classes from './ConfirmationModal.module.scss';

type TProps = TModalProps & {
  confirmLoading?: boolean;
  onConfirm?: () => void;
}

export default function ConfirmationModal(props: TProps) {
  const {
    children, confirmLoading, onConfirm, onClose, ...rest
  } = props;

  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} width={548} {...rest} zIndex={5000}>
      {children}
      <Flex gap={12} className={classes.footer}>
        <Button variant="accent" loading={confirmLoading} onClick={onConfirm}>{t(`${ns.buttons}:confirm`)}</Button>
        <Button variant="error" onClick={onClose}>{t(`${ns.buttons}:cancel`)}</Button>
      </Flex>
    </Modal>
  );
}
