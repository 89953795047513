import React from 'react';
import { Typography } from 'antd';
import Title from 'antd/es/typography/Title';

import Flex from 'components/ui/atoms/flex/Flex';
import Button from 'components/ui/atoms/button/Button';
import SaveIcon from 'components/ui/atoms/icons/SaveIcon';
import Scale from 'components/animations/scale/Scale';

import classes from './PageHeader.module.scss';

interface PageHeaderProps {
  header: string;
  subHeader?: string;
  prefixText?: string;
  titleEditable?: boolean;
  editable?: boolean;
  wasEdited?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  onChangeTitle?: (title: string) => void;
  onSaveChanges?: Function;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  header,
  subHeader,
  prefixText,
  titleEditable = false,
  editable = false,
  wasEdited = false,
  isLoading,
  children,
  onChangeTitle,
  onSaveChanges,
}) => (
  <>
    <div className={classes.wrapper}>
      <div className={classes.title_wrapper}>
        {
          prefixText && (
            <Title level={2} className={classes.prefix}>
              {`${prefixText}`}
              &nbsp;
            </Title>
          )
        }
        <Title
          level={2}
          className={classes.title}
          editable={titleEditable && {
            triggerType: ['text', 'icon'],
            onChange: onChangeTitle,
          }}
        >
          {header}
        </Title>
      </div>

      <Flex gap={6}>
        <Scale isVisible={editable && wasEdited}>
          <Button
            type="primary"
            icon={<SaveIcon />}
            onClick={() => onSaveChanges?.()}
            loading={isLoading}
          >
            Сохранить изменения
          </Button>
          {/* <Button type="text" icon={<UndoIcon size={24} color={token.colorPrimary} />} /> */}
        </Scale>
        {children}
      </Flex>
    </div>

    {subHeader && <Typography.Title level={4}>{subHeader}</Typography.Title>}
  </>
);

export default PageHeader;
