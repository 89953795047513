import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';

import { IEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/interfaces';

import { ITaskProxy } from 'domain/entities/proxyTask';
import { GetTaskProxyUseCase } from 'domain/useCases/proxy/GetTaskProxyUseCase';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

import { getFieldFromTranslations } from 'utils/locales/getFieldFromTranslations';

export class EntityDrawerTaskProxyViewModel implements IEntityDrawerViewModel {
  @observable public pageStatus: LoadStatus = new LoadStatus();

  @observable public taskProxy: ITaskProxy | undefined;

  public constructor(
    private _getTaskProxy: GetTaskProxyUseCase,
  ) {
    makeObservable(this);
  }

  @computed public get title() {
    return this.taskProxy
      ? `Задача ${getFieldFromTranslations('name', this.taskProxy.task_translation)}`
      : 'Загрузка...';
  }

  @action public async fetch(id: string): Promise<void> {
    await this._getTaskProxy.fetch({
      payload: { id },
      onSuccess: ({ task }) => runInAction(() => {
        this.taskProxy = task;
      }),
      loadStatus: this.pageStatus,
    });
  }

  @action public reset = () => {
    this.taskProxy = undefined;

    this.pageStatus.onStartRequest();
  };
}
