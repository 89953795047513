import React from 'react';
import clsx from 'clsx';

import CancelIcon from 'components/ui/atoms/icons/new/Cancel';
import OkIcon from 'components/ui/atoms/icons/new/Ok';
import Flex from 'components/ui/atoms/flex/Flex';
import Paragraph from 'components/ui/atoms/typography/Paragraph';

import classes from './Notification.module.scss';

type TProps = {
  message?: string;
  type?: 'success' | 'error';
};

export default function Notification(props: TProps) {
  const { message, type = 'error' } = props;

  return (
    <Flex
      align="center"
      gap={12}
      className={clsx(classes.notification, {
        [classes.notification_success]: type === 'success',
        [classes.notification_error]: type === 'error',
      })}
    >
      <div className={classes.notification__icon}>
        {type === 'success' ? <OkIcon /> : <CancelIcon />}

      </div>
      <Paragraph variant="body" weight="bold">{message}</Paragraph>
    </Flex>
  );
}
