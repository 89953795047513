import {
  action, makeObservable, observable,
} from 'mobx';

import { RegisterUseCase } from 'domain/useCases/auth/RegisterUseCase';
import { ILoginPayload, IRegisterPayload } from 'domain/repositories/api/auth/IAuthRepository';
import { LoginUseCase } from 'domain/useCases/auth/LoginUseCase';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

export class AuthPageViewModel {
  @observable public pageStatus: LoadStatus = new LoadStatus(false);

  constructor(
    private _loginUseCase: LoginUseCase | null = null,
    private _registerUseCase: RegisterUseCase,
  ) {
    makeObservable(this);
  }

  @action public login = async (payload: ILoginPayload) => {
    await this._loginUseCase?.fetch({
      payload,
      loadStatus: this.pageStatus,
    });
  };

  @action public register = async (payload: IRegisterPayload, onSuccess: () => void) => {
    await this._registerUseCase?.fetch({
      payload,
      loadStatus: this.pageStatus,
      onSuccess,
    });
  };
}
