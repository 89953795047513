import React, { useState, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroller';
import clsx from 'clsx';

import Loader from 'components/ui/atoms/loader/Loader';
import Center from 'components/ui/atoms/center/Center';
import ChevronUpIcon from 'components/ui/atoms/icons/new/ChevronUp';
import ButtonNonUi from 'components/ui/atoms/button/ButtonNonUi';
import Collapse from 'components/animations/collapseHidden/Collapse';
import LinkClassSidebar from 'components/ui/organisms/sidebar/ui/linkClassSidebar/LinkClassSidebar';
import { useSidebarViewModel } from 'components/ui/organisms/sidebar/viewModel/context';
import Flex from 'components/ui/atoms/flex/Flex';
import { useSidebarLinks } from 'components/ui/organisms/sidebar/hooks/useSidebarLinks';
import LinkSidebar from 'components/ui/organisms/sidebar/ui/linkSidebar/LinkSidebar';

import classes from './LinksContainer.module.scss';

function LinksContainer() {
  const sidebarLinks = useSidebarLinks();
  const {
    classes: classesList, fetch, loadMoreAvailable, isSidebarCollapsed, noClasses,
  } = useSidebarViewModel();

  const [isClassesCollapsed, setClassesCollapsed] = useState(false);
  const toggleClassesCollapsed = () => setClassesCollapsed((prev) => !prev);

  const handleClassChevronClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleClassesCollapsed();
  };

  const [classesSidebarLink, ...restSidebarLinks] = sidebarLinks;

  return (
    <Flex vertical gap={8} className={classes.links_container}>
      <LinkSidebar link={classesSidebarLink}>
        <ButtonNonUi className={classes.link_chevron_button} onClick={handleClassChevronClick}>
          {!noClasses && (
            <ChevronUpIcon className={clsx(classes.link_chevron, {
              [classes.link_chevron_is_open]: isClassesCollapsed,
            })}
            />
          )}
        </ButtonNonUi>
      </LinkSidebar>
      {!noClasses && (
        <Collapse isOpen={isClassesCollapsed} className={classes.aria_expanded}>
          <InfiniteScroll
            loadMore={fetch}
            hasMore={loadMoreAvailable}
            loader={<Center height="50px"><Loader /></Center>}
            initialLoad={isClassesCollapsed}
            threshold={4000}
            useWindow={false}
            className={clsx(classes.classes_links_container, {
              [classes.classes_links_container_is_sidebar_collapsed]: isSidebarCollapsed,
            })}
          >
            {
              classesList.map((classDetails) => (
                <LinkClassSidebar classDetails={classDetails} key={classDetails.id} />
              ))
            }
          </InfiniteScroll>
        </Collapse>
      )}
      {
        restSidebarLinks.map((link, idx) => (
          <LinkSidebar link={link} key={idx} />
        ))
      }
    </Flex>
  );
}

export default observer(LinksContainer);
