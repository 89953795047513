import React, { Fragment, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { route } from 'application/routes/route';
import { useNotifications } from 'application/notification/useNotifications';

import WithLoader from 'components/ui/molecules/withLoader/WithLoader';

import { tokenRepository } from 'domain/repositories/other/TokenRepository';
import { LoginViaTsuAccountsUseCase } from 'domain/useCases/auth/LoginViaTsuAccountsUseCase';
import { authRepository } from 'domain/repositories/api';

import { useStore } from 'storesMobx/MobxStoreProvider';

const PageWithLoader = WithLoader(Fragment, true);

const TsuAuth: React.FC = () => {
  const { notifyError } = useNotifications();
  const navigate = useNavigate();
  const { userStore, commonDataStore } = useStore();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const loginViaTsuAccounts = new LoginViaTsuAccountsUseCase({
    requestCallback: authRepository.loginViaTsuAccounts,
    notifyError,
    userStore,
    tokenRepository,
    commonDataStore,
  });

  useEffect(() => {
    if (!token) navigate(route.base);

    loginViaTsuAccounts.fetch({
      payload: { token: token! },
      onSuccess: () => {
        navigate(route.base);
      },
      onError: () => {
        navigate(route.base);
      },
    });
  }, []);

  return (
    <PageWithLoader isLoading />
  );
};

export default TsuAuth;
