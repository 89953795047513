import React from 'react';
import { useController, useFormContext, useFormState } from 'react-hook-form';
import clsx from 'clsx';

import Paragraph from 'components/ui/atoms/typography/Paragraph';
import Input, { TInputProps } from 'components/ui/atoms/input/Input';

import classes from './Form.module.scss';

type TProps = {
  name: string;
  label?: string;
  classNameFormItem?: string;
  classNameLabel?: string;
} & TInputProps;

export default function FormInput(props: TProps) {
  const {
    name, label, classNameFormItem, classNameLabel, type, id, onChange, ...restProps
  } = props;

  const { control } = useFormContext();
  const formState = useFormState();
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: '',
  });

  const handleChange = (value: string) => {
    field.onChange(value);
    onChange?.(value);
  };

  return (
    <div className={classNameFormItem}>
      {label && (
        <label htmlFor={id}>
          <Paragraph
            weight="bold"
            color={fieldState?.error ? 'error' : undefined}
            className={clsx(classes.label, classNameLabel)}
          >
            {label}
          </Paragraph>
        </label>
      )}
      <Input
        name={name}
        value={field.value}
        id={id}
        onChange={handleChange}
        type={type}
        error={!!fieldState?.error}
        errorMessage={formState.errors[name]?.message?.toString()}
        {...restProps}
      />
    </div>
  );
}
