import { useTranslation } from 'react-i18next';

import { useClassRole } from 'application/authentication/useClassRole';
import { route } from 'application/routes/route';

import { ns } from 'utils/locales';

type TInnerClassLink = {
  to: string;
  text: string;
}

type TParams = {
  classId: string;
}

export default function useInnerClassLinks(params: TParams): TInnerClassLink[] {
  const { classId } = params;

  const { isStudent } = useClassRole(classId);
  const { t } = useTranslation([ns.common]);

  return [
    {
      to: route.posts(classId),
      text: t(`${ns.common}:classSubLinks.posts`),
    },
    {
      to: route.tasks(classId),
      text: t(`${ns.common}:classSubLinks.tasks`),
    },
    {
      to: route.queue(classId),
      text: t(`${ns.common}:classSubLinks.queue`),
    },
    {
      to: route.postModeration(classId),
      text: isStudent ? t(`${ns.common}:classSubLinks.awaitingSolutions`) : t(`${ns.common}:classSubLinks.postModeration`),
    },
    {
      to: route.users(classId),
      text: t(`${ns.common}:classSubLinks.users`),
    },
    {
      to: route.rating(classId),
      text: t(`${ns.common}:classSubLinks.rating`),
    },
  ];
}
