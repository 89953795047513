import {
  adjectives, animals, colors, uniqueNamesGenerator,
} from 'unique-names-generator';

export const generateRandomId = () => {
  const arrayNumbers = Array.from(
    { length: 4 },
    () => generateRandomInteger(1000, 10000),
  );

  return arrayNumbers.join('-');
};

export const generateRandomInteger = (min: number, max: number) => Math.floor(
  min + Math.random() * (max - min + 1),
);

export const generateRandomEnum = <T>(anEnum: T): T[keyof T] => {
  const enumValues = Object.values(anEnum as unknown as object);
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  return enumValues[randomIndex];
};

export const generateRandomName = () => (
  uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals] })
);
