import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';

import { WIDTH_SIDEBAR_COLLAPSED } from 'components/ui/organisms/sidebar/constants';

import { sidebarRepository } from 'domain/repositories/other/SidebarRepository';
import { GetClassesUseCase } from 'domain/useCases/class/GetClassesUseCase';
import { IClass } from 'domain/entities/class';

import { CommonDataStore } from 'storesMobx/stores/CommonDataStore';

import { WIDTH_SIDEBAR } from 'utils/layout/constants';
import { sleep } from 'utils/asyncHelpers';

export class SidebarViewModel {
  @observable public isSidebarCollapsed = sidebarRepository.isSidebarCollapsed();

  @observable public sidebarWidth = sidebarRepository.isSidebarCollapsed()
    ? WIDTH_SIDEBAR_COLLAPSED : WIDTH_SIDEBAR;

  @observable public isAnimationActive = false;

  @observable public classes: IClass[] = [];

  @observable public noClasses: boolean = false;

  @observable private total = 0;

  @observable public page = 0;

  @observable public limit = 30;

  public constructor(
    private _getClassesList: GetClassesUseCase,
    private _commonDataStore: CommonDataStore,
  ) {
    makeObservable(this);
  }

  @computed public get loadMoreAvailable() {
    return this.page * this.limit < this.total;
  }

  @action public fetch = async () => {
    this.page += 1;

    await this._getClassesList.fetch({
      payload: { page: this.page, limit: this.limit },
      onSuccess: ({ classes, total }) => runInAction(() => {
        if (!total) this.noClasses = true;

        this.classes = [...this.classes, ...classes];
        this.total = total;
        this._commonDataStore.setClasses(this.classes);
      }),
    });
  };

  @action public onClickBtnSidebarCollapse = async () => {
    if (this.isAnimationActive) return;

    if (!this.isSidebarCollapsed) {
      runInAction(() => {
        this.isAnimationActive = true;
        this.isSidebarCollapsed = true;
      });

      await sleep(200);

      runInAction(() => {
        this.sidebarWidth = WIDTH_SIDEBAR_COLLAPSED;
        this.isAnimationActive = false;
      });
    } else {
      runInAction(() => {
        this.isAnimationActive = true;
        this.sidebarWidth = WIDTH_SIDEBAR;
      });

      await sleep(200);

      runInAction(() => {
        this.isSidebarCollapsed = false;
        this.isAnimationActive = false;
      });
    }

    sidebarRepository.toggleIsSidebarCollapsed();
  };

  @action public setIsSidebarCollapsed = (isSidebarCollapsed: boolean) => {
    this.isSidebarCollapsed = isSidebarCollapsed;
    this.sidebarWidth = isSidebarCollapsed ? WIDTH_SIDEBAR_COLLAPSED : WIDTH_SIDEBAR;
    this.isAnimationActive = false;
  };
}
