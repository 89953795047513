import React from 'react';
import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { route } from 'application/routes/route';
import { useConfirmModal } from 'application/confirmModal/useConfirmModal';

import IconButton from 'components/ui/atoms/iconButton/IconButton';
import {
  HeaderTextWrapper, HeaderWrapper, IconsWrapper, PercentageTitle, PointsTitle,
} from 'components/ui/organisms/classStructure/styled';
import Link from 'components/ui/atoms/link/Link';
import DragIcon from 'components/ui/atoms/icons/DragIcon';
import DeleteIcon from 'components/ui/atoms/icons/DeleteIcon';

import { ns } from 'utils/locales';

interface SectionHeaderProps {
  id: string;
  name: string;
  points?: number;
  moduleId?: string;
  percent?: number;
  onDelete?: (id: string) => void;
  draggableProps?: DraggableProvidedDragHandleProps | null | undefined;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  id,
  name,
  points,
  moduleId,
  percent,
  onDelete,
  draggableProps,
}) => {
  const { classId } = useParams();
  const { t } = useTranslation();

  const { showConfirm } = useConfirmModal({
    title: t(`${ns.confirmations}:deleteSection`, { name }),
    cbOnOk: () => onDelete?.(id),
  });

  return (
    <HeaderWrapper headerType="section">
      <HeaderTextWrapper>
        {classId && moduleId && id ? (
          <Link to={route.sectionManager(classId, moduleId, id)}>
            <Title level={4}>{name}</Title>
          </Link>
        ) : (
          <Title level={4}>{name}</Title>
        )}
        {
          (onDelete || draggableProps) && (
            <IconsWrapper>
              {draggableProps && (
                <IconButton icon={<DragIcon />} {...draggableProps} />
              )}
              {onDelete && (
                <IconButton icon={<DeleteIcon />} onClick={() => showConfirm()} />
              )}
            </IconsWrapper>
          )
        }
      </HeaderTextWrapper>

      {points || points === 0 ? <PointsTitle level={4}>{`${points}`}</PointsTitle> : null}
      {percent && <PercentageTitle level={4} $marginLeft="60px">{`(${percent}%)`}</PercentageTitle>}
    </HeaderWrapper>
  );
};

export default SectionHeader;
