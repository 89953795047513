import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function SearchIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12.5" cy="11.5" r="9.5" stroke="currentColor" strokeWidth="1.5" />
      <path d="M19.5 18.5L23 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
