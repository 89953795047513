import React from 'react';

import { IconProps } from 'components/ui/atoms/icons/types/iconProps';

export default function RatingIcon(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.0002 16C7.24021 16 6.21983 10.2595 6.03907 5.70647C5.98879 4.43998 5.96365 3.80673 6.43937 3.22083C6.91508 2.63494 7.48445 2.53887 8.62318 2.34674C9.74724 2.15709 11.2166 2 13.0002 2C14.7837 2 16.2531 2.15709 17.3771 2.34674C18.5159 2.53887 19.0852 2.63494 19.5609 3.22083C20.0367 3.80673 20.0115 4.43998 19.9612 5.70647C19.7805 10.2595 18.7601 16 13.0002 16Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M12 8L13.5 6.5V10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M20 5L20.9486 5.31621C21.9387 5.64623 22.4337 5.81124 22.7168 6.20408C23 6.59692 23 7.11873 22.9999 8.16234L22.9999 8.23487C22.9999 9.09561 22.9999 9.52598 22.7927 9.87809C22.5855 10.2302 22.2093 10.4392 21.4569 10.8572L18.5 12.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M5.99994 5L5.05132 5.31621C4.06126 5.64623 3.56623 5.81124 3.2831 6.20408C2.99996 6.59692 2.99997 7.11873 3 8.16234L3 8.23487C3.00003 9.09561 3.00004 9.52598 3.20723 9.87809C3.41441 10.2302 3.79063 10.4392 4.54305 10.8572L7.49994 12.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M13 16V19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M16.5 22H9.5L9.83922 20.3039C9.93271 19.8365 10.3431 19.5 10.8198 19.5H15.1802C15.6569 19.5 16.0673 19.8365 16.1608 20.3039L16.5 22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19 22H7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
