import { IHttpClient } from 'data/network/httpClient/IHttpClient';

import {
  ICreateStudyGroupPayload, ICreateStudyGroupResponse,
  IDeleteStudyGroupPayload, IDeleteStudyGroupResponse,
  IStudyGroupRepository, IUpdateStudyGroupPayload, IUpdateStudyGroupResponse,
} from 'domain/repositories/api/studyGroup/IStudyGroupRepository';

export class StudyGroupRepository implements IStudyGroupRepository {
  private readonly prefix = `${process.env.REACT_APP_CLASS_API}/studyGroup`;

  public constructor(private _httpClient: IHttpClient) {}

  public createStudyGroup = (
    { subdivisionId, ...body }: ICreateStudyGroupPayload,
  ) => this._httpClient
    .post<ICreateStudyGroupResponse>(`${this.prefix}/subdivision/${subdivisionId}/create`, body);

  public updateStudyGroup = ({ id, ...body }: IUpdateStudyGroupPayload) => this._httpClient
    .post<IUpdateStudyGroupResponse>(`${this.prefix}/${id}/update`, body);

  public deleteStudyGroup = ({ id }: IDeleteStudyGroupPayload) => this._httpClient
    .post<IDeleteStudyGroupResponse>(`${this.prefix}/${id}/delete`);
}
