const AXIOS_CACHE_PREFIX = 'axios';
const I18NEXT_CACHE_PREFIX = 'i18next';

export const deleteAllHttpCache = () => {
  for (let i = 0; i < localStorage.length; i += 1) {
    const curKey = localStorage.key(i);

    if (curKey?.startsWith(AXIOS_CACHE_PREFIX)) {
      localStorage.removeItem(curKey);
    }
  }
};

export const deleteTranslationsCache = () => {
  for (let i = 0; i < localStorage.length; i += 1) {
    const curKey = localStorage.key(i);

    if (curKey?.startsWith(I18NEXT_CACHE_PREFIX)) {
      localStorage.removeItem(curKey);
    }
  }
};
