import { AxiosError } from 'axios';
import { REFRESH_TOKEN_URL } from 'data/axios/constants';

import {
  ErrorNotificationType,
  ShowErrorFunction,
  ShowSuccessNotificationFunction,
  SuccessNotificationType,
} from 'application/notification/types';

import { StatusFalseError } from 'domain/errors/StatusFalseError';
import { ApiUseCaseConstructorParams, FetchFunctionParams } from 'domain/useCases/common/types';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

import { sleep } from 'utils/asyncHelpers';

export abstract class APIUseCase<RequestPayloadType, ResponseType> {
  protected requestCallback: (payload: RequestPayloadType) => Promise<ResponseType>;

  protected readonly notifyError?: ShowErrorFunction;

  protected readonly notifySuccess?: ShowSuccessNotificationFunction;

  protected readonly shouldThrowException: boolean;

  protected readonly errorMessage: ErrorNotificationType = ErrorNotificationType
    .SOMETHING_WENT_WRONG;

  protected readonly successMessage: SuccessNotificationType = SuccessNotificationType
    .CHANGES_SUCCESSFULLY_SAVED;

  public constructor(params: ApiUseCaseConstructorParams<RequestPayloadType, ResponseType>) {
    this.requestCallback = params.requestCallback;

    this.notifyError = params.notifyError;
    this.notifySuccess = params.notifySuccess;

    this.shouldThrowException = params.shouldThrowException ?? false;
  }

  public async fetch({
    payload,
    onSuccess,
    onError,
    loadStatus,
    delay,
  }: FetchFunctionParams<RequestPayloadType, ResponseType>): Promise<void> {
    loadStatus?.onStartRequest();

    try {
      const data = await this.requestCallback(payload);

      if (delay) await sleep(delay);

      if (data && typeof data === 'object' && 'status' in data && data.status === false) {
        throw new StatusFalseError();
      }

      this.handleSuccess(data, loadStatus, onSuccess);
    } catch (e: any) {
      this.handleError(e, loadStatus, onError);
    }
  }

  protected handleSuccess = (
    data: ResponseType,
    loadStatus?: LoadStatus,
    onSuccess?: (response: ResponseType) => void,
  ) => {
    this.notifySuccess?.(this.successMessage);

    onSuccess?.(data);

    loadStatus?.onEndRequest();
  };

  protected handleError = (
    e: Error,
    loadStatus?: LoadStatus,
    onError?: (e: Error) => void,
  ) => {
    this.handleNotification(e);

    onError?.(e);

    loadStatus?.onEndRequest(false, e instanceof AxiosError ? e.response?.status : undefined);

    if (this.shouldThrowException) {
      throw e;
    }
  };

  protected handleNotification = (e: Error) => {
    if (e instanceof AxiosError) {
      if (e.request?.responseURL === REFRESH_TOKEN_URL) return;
      if (e.response?.status === 401) return;

      if (e.response?.status === 403) {
        this.notifyError?.(ErrorNotificationType.FORBIDDEN);

        return;
      }
    }

    if (e instanceof StatusFalseError) {
      this.notifyError?.(ErrorNotificationType.SOMETHING_WENT_WRONG);

      return;
    }

    this.notifyError?.(this.errorMessage);
  };
}
