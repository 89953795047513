import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { matchPath, useLocation } from 'react-router-dom';

import { route } from 'application/routes/route';

import ErrorState from 'components/ui/atoms/errorState/ErrorState';
import Loader from 'components/ui/atoms/loader/Loader';

import { tokenRepository } from 'domain/repositories/other/TokenRepository';

import TsuAuth from 'pages/tsuAuth/TsuAuth';
import AuthPage from 'pages/auth/AuthPageProvider';

import { useStore } from 'storesMobx/MobxStoreProvider';

import { IChildren } from 'utils/interfaces/IChildren';

const AuthenticationProvider: React.FC<IChildren> = ({ children }) => {
  const { userStore, commonDataStore } = useStore();
  const { pathname } = useLocation();

  const isTsuAuth = !!matchPath(pathname, route.tsuAuth);

  useEffect(() => {
    if (isTsuAuth || !tokenRepository.getToken()) return;

    (async () => {
      await userStore.fetch();
      await commonDataStore.fetch();
    })();
  }, []);

  const isLoading = !tokenRepository.getToken()
    ? false
    : userStore.loadStatus.isLoading || commonDataStore.loadStatus.isLoading;

  if (isTsuAuth) {
    return <TsuAuth />;
  }

  if (isLoading) return <Loader fixedCentered />;

  if (userStore.loadStatus.isFailed || commonDataStore.loadStatus.isFailed) {
    return (
      <ErrorState />
    );
  }

  if (userStore.isAuthenticated) {
    return (
      <>
        {children}
      </>
    );
  }

  return <AuthPage />;
};

export default observer(AuthenticationProvider);
