import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';

import { IEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/interfaces';

import { GetSubjectDetailsProxyUseCase } from 'domain/useCases/proxy/GetSubjectDetailsProxyUseCase';
import { ISubjectProxy } from 'domain/entities/proxySubject';

import { LoadStatus } from 'storesMobx/helpers/LoadStatus';

import { getFieldFromTranslations } from 'utils/locales/getFieldFromTranslations';

export class EntityDrawerSubjectProxyViewModel implements IEntityDrawerViewModel {
  @observable public pageStatus = new LoadStatus();

  @observable public subject: ISubjectProxy | undefined;

  @observable public localization = '';

  public constructor(
    private _getSubjectOverview: GetSubjectDetailsProxyUseCase,
  ) {
    makeObservable(this);
  }

  @computed public get title() {
    return this.subject
      ? getFieldFromTranslations('name', this.subject.translations)
      : 'Загрузка...';
  }

  @computed public get modules() {
    return this.subject?.study_modules?.map(({ id, translations, sections }) => ({
      id,
      name: getFieldFromTranslations('name', translations, this.localization),
      sections: sections?.map(({ id, translations, tasks }) => ({
        id,
        name: getFieldFromTranslations('name', translations, this.localization),
        tasks: tasks?.map(({ id, translation }) => ({
          id,
          name: getFieldFromTranslations('name', translation, this.localization),
          max_score: 0,
        })) ?? [],
      })) ?? [],
    }));
  }

  @action public setLocalization = (l: string) => {
    this.localization = l;
  };

  @action public async fetch(id: string): Promise<void> {
    await this._getSubjectOverview.fetch({
      payload: { id },
      loadStatus: this.pageStatus,
      onSuccess: ({ subject }) => runInAction(() => {
        this.subject = subject;
      }),
    });
  }

  public reset = () => {
    this.subject = undefined;

    this.pageStatus.onStartRequest();
  };
}
