import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import HeaderClass from 'components/ui/organisms/headerClass/HeaderClass';
import NavbarClass from 'components/ui/organisms/navbarClass/NavbarClass';

import { isPrimaryBg } from 'utils/routes/isPrimaryBg';
import { IChildren } from 'utils/interfaces/IChildren';

import classes from './ClassLayout.module.scss';

const ClassContentLayout: React.FC<IChildren> = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <div className={classes.page_wrapper}>
      <HeaderClass />
      <NavbarClass />
      <div className={clsx(classes.content_wrapper, {
        [classes.page_wrapper_primary_bg]: isPrimaryBg(pathname),
      })}
      >
        {children}
      </div>
    </div>
  );
};

export default ClassContentLayout;
