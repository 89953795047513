import React, { PropsWithChildren, useMemo, useState } from 'react';

import ConfirmationModal from 'components/ui/molecules/modal/ConfirmationModal';

import {
  ConfirmationModalContext,
  confirmationModalContextDefaultValue,
  TConfirmationModalContext,
  TConfirmationModalContextValue,
} from './context';

type TProps = PropsWithChildren;

export default function ConfirmationModalProvider(props: TProps) {
  const { children } = props;

  const [modalState, setModalState] = useState<TConfirmationModalContextValue>(
    confirmationModalContextDefaultValue,
  );

  const [isOpen, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const {
    content, title, onConfirm,
  } = modalState;

  const handleClose = () => setOpen(false);

  const handleConfirm = async () => {
    setConfirmLoading(true);
    await onConfirm?.();
    setConfirmLoading(false);

    handleClose();
  };

  const handleOpenConfirmationModal = (modalState: TConfirmationModalContextValue) => {
    setModalState(modalState);
    setOpen(true);
  };

  const contextValue = useMemo<TConfirmationModalContext>(() => ({
    openConfirmationModal: handleOpenConfirmationModal,
  }), []);

  return (
    <ConfirmationModalContext.Provider value={contextValue}>
      <ConfirmationModal
        title={title}
        open={isOpen}
        confirmLoading={confirmLoading}
        onClose={handleClose}
        onConfirm={handleConfirm}
      >
        {content}
      </ConfirmationModal>
      {children}
    </ConfirmationModalContext.Provider>
  );
}
