import { useLayoutEffect, useRef, useState } from 'react';

import { useStore } from 'storesMobx/MobxStoreProvider';

type TParams = {
  id: string;
}

export const useTableWrapper = (params: TParams) => {
  const { id } = params;

  const { themeStore } = useStore();

  const [tableWrapper, setTableWrapper] = useState<HTMLDivElement | null>(null);

  const tableWrapperRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const table = document.getElementById(id);

    if (!table) return;

    const tableWrapper = table.getElementsByClassName('ant-table-wrapper');

    if (tableWrapper.length) {
      const wrapper = tableWrapper[0] as HTMLDivElement;

      setTableWrapper(wrapper);
      tableWrapperRef.current = wrapper;
    }
  }, [themeStore.isDarkMode]);

  return { tableWrapper, tableWrapperRef };
};
