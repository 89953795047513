import React from 'react';
import Typography from 'antd/es/typography/Typography';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { route } from 'application/routes/route';
import { useConfirmModal } from 'application/confirmModal/useConfirmModal';

import Link from 'components/ui/atoms/link/Link';
import IconButton from 'components/ui/atoms/iconButton/IconButton';
import DragIcon from 'components/ui/atoms/icons/DragIcon';
import DeleteIcon from 'components/ui/atoms/icons/DeleteIcon';
import {
  DottedLine, HeaderWrapper, IconsWrapper, PointsTypography,
} from 'components/ui/organisms/classStructure/styled';

import { ns } from 'utils/locales';

interface TaskHeaderWrapper {
  id: string;
  name: string;
  maxScore: number;
  moduleId?: string;
  sectionId?: string;
  leftPadding?: boolean;
  index?: number;
  onDelete?: (id: string) => void;
  draggableProps?: DraggableProvidedDragHandleProps | null | undefined;
}

const TaskHeader: React.FC<TaskHeaderWrapper> = ({
  id,
  name,
  maxScore,
  moduleId,
  sectionId,
  leftPadding = true,
  index,
  onDelete,
  draggableProps,
}) => {
  const { classId } = useParams();
  const { t } = useTranslation();

  const { showConfirm } = useConfirmModal({
    title: t(`${ns.confirmations}:deleteTask`, { name }),
    cbOnOk: () => onDelete?.(id),
  });

  return (
    <HeaderWrapper headerType="task" leftPadding={leftPadding}>
      {classId && moduleId && sectionId && id ? (
        <Link to={route.taskManager(classId, moduleId, sectionId, id)}>
          <Typography>
            {index && `${index}. `}
            {name}
          </Typography>
        </Link>
      ) : classId && id ? (
        <Link to={route.taskManagerSimplified(classId, id)}>
          <Typography>
            {index && `${index}. `}
            {name}
          </Typography>
        </Link>
      ) : (
        <Typography>
          {index && `${index}. `}
          {name}
        </Typography>
      )}
      {
        (onDelete || draggableProps) && (
          <IconsWrapper>
            {draggableProps && (
              <IconButton icon={<DragIcon />} {...draggableProps} />
            )}
            {onDelete && (
              <IconButton icon={<DeleteIcon />} onClick={() => showConfirm()} />
            )}
          </IconsWrapper>
        )
      }
      <DottedLine />
      <PointsTypography>{`${maxScore} б.`}</PointsTypography>
    </HeaderWrapper>
  );
};

export default TaskHeader;
