import {
  action, makeObservable, observable,
} from 'mobx';
import { ModalProps } from 'antd';
import React from 'react';

import { FooterPropsArgs } from 'components/ui/organisms/modal/types';
import { ModalFooterProps } from 'components/ui/atoms/modalFooter/ModalFooter';

interface IOpenProps extends ModalProps {
  content: React.ReactNode;
}

export class ModalViewModel {
  @observable public isOpened = false;

  public props: IOpenProps | undefined;

  public constructor() {
    makeObservable(this);
  }

  public footerProps = (args?: FooterPropsArgs): Partial<ModalFooterProps> => ({
    onModalBack: () => {
      this.close();
      args?.onBackExtra?.();
    },
    onModalNext: async () => {
      this.close();
      await args?.onNextExtra?.();
    },
    isSingleStep: true,
  });

  @action public open = (props: IOpenProps) => {
    this.isOpened = true;
    this.props = props;
  };

  @action public close = () => {
    this.isOpened = false;
  };
}
