import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { MIN_SIDEBAR_WINDOW_WIDTH } from 'components/ui/organisms/sidebar/constants';
import TopBarView from 'components/ui/organisms/sidebar/TopBarView';
import { useSidebarViewModel } from 'components/ui/organisms/sidebar/viewModel/context';
import SidebarView from 'components/ui/organisms/sidebar/SidebarView';

import { useWindowSize } from 'utils/layout/useWindowResize';

const SidebarController: React.FC = () => {
  const [width] = useWindowSize();
  const { fetch, onClickBtnSidebarCollapse } = useSidebarViewModel();

  useEffect(() => {
    (async () => {
      await fetch();
    })();
  }, []);

  if (width !== 0 && width <= MIN_SIDEBAR_WINDOW_WIDTH) return <TopBarView />;

  return (
    <SidebarView onCollapseButtonClick={onClickBtnSidebarCollapse} />
  );
};

export default observer(SidebarController);
