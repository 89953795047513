import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import ButtonNonUi from 'components/ui/atoms/button/ButtonNonUi';
import ArrowRightAltIcon from 'components/ui/atoms/icons/new/ArrowRightAlt';
import ArrowLeftAltIcon from 'components/ui/atoms/icons/new/ArrowLeftAlt';

import useAuthSlider from 'pages/auth/hooks/useAuthSlider';
import descriptionFolder from 'pages/auth/assets/description-folder.png';
import descriptionFolderItem from 'pages/auth/assets/description-folder-item.png';
import descriptionFolderItemDark from 'pages/auth/assets/description-folder-item-dark.png';

import { useStore } from 'storesMobx/MobxStoreProvider';

import { ns } from 'utils/locales';

import classes from './Description.module.scss';

function Description() {
  const { t } = useTranslation();
  const { curItem, handlePrev, handleNext } = useAuthSlider();
  const { themeStore } = useStore();
  const { title, description } = curItem;

  const bgImage = themeStore.isDarkMode ? descriptionFolderItemDark : descriptionFolderItem;

  return (
    <div className={classes.description}>
      <img src={descriptionFolder} alt="" draggable={false} className={classes.description__folder} />
      <div className={classes.description__title_container}>
        <h1 className={classes.description__title}>{t(`${ns.auth}:title`)}</h1>
      </div>
      <div className={classes.description__item}>
        <img src={bgImage} alt="" draggable={false} className={classes.description__item__background} />
        <div className={classes.description__item__slide_buttons_container}>
          <ButtonNonUi onClick={handlePrev}>
            <div className={classes.description__item__slide_button}>
              <ArrowLeftAltIcon />
            </div>
          </ButtonNonUi>
          <ButtonNonUi onClick={handleNext}>
            <div className={classes.description__item__slide_button}>
              <ArrowRightAltIcon />
            </div>
          </ButtonNonUi>
        </div>
        <h6 className={classes.description__item__header}>{title}</h6>
        <p className={classes.description__item__text}>{description}</p>
      </div>
    </div>
  );
}

export default observer(Description);
