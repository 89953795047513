import { ISubdivision } from 'domain/entities/subdivision';
import { TModuleStructure } from 'domain/entities/module';

export enum ClassType {
  PROGRAMMING = 'Программирование',
  DATA_BASES = 'Базы данных',
  SYSTEM_ADMINISTRATION = 'Основы системного администрирования',
  FRONTEND = 'Разработка пользовательского интерфейса',
  BACKEND = 'Разработка серверных приложений',
  MOBILE = 'Мобильная разработка',
  ARCHITECTURE = 'Архитектура',
  ANALYTICS = 'Аналитика',
  ML = 'Машинное обучение',
  MATH = 'Математика для компьютерных наук'
}

export enum ClassRole {
  TEACHER = 0,
  STUDENT = 1,
  ASSISTANT = 2,
}

export enum ClassRoleString {
  STUDENT = 'student',
  TEACHER = 'teacher',
}

export interface IAddUserModel {
  role: ClassRoleString;
  userID: string;
}

export interface IClassRole {
  classId: string;
  classRole: ClassRole;
}

export interface IClass {
  class_type: ClassType
  createdAt: string
  description: string;
  id: string;
  id_parent: string | null;
  name: string;
  updatedAt: string
  subdivision: ISubdivision
}

export type TClassStructure = {
  class: IClass,
  classStudyModules: TModuleStructure[];
}
