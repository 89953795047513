import React from 'react';
import { Flex as CuiFlex, FlexProps } from 'antd';

type TProps = FlexProps;

export default function Flex(props: TProps) {
  const { children, ...rest } = props;

  return (
    <CuiFlex {...rest}>{children}</CuiFlex>
  );
}
