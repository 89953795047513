import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';

export class LoadStatus {
  @observable private _isLoading = true;

  @observable private _isFailed = false;

  @observable private _isFetched = false;

  @observable private _errorCode: number | undefined;

  public constructor(initialValue: boolean = true) {
    makeObservable(this);

    this.setIsLoading(initialValue);
  }

  @computed public get isLoading() {
    return this._isLoading;
  }

  @computed public get isFailed() {
    return this._isFailed;
  }

  @computed public get isFetched() {
    return this._isFetched;
  }

  @computed public get errorCode() {
    return this._errorCode;
  }

  @action private setIsLoading = (value: boolean) => {
    this._isLoading = value;
  };

  public onStartRequest() {
    runInAction(() => {
      this._isLoading = true;
      this._isFailed = false;
    });
  }

  public onEndRequest(isSuccess = true, errorCode: number | undefined = undefined) {
    runInAction(() => {
      this._isLoading = false;
      this._isFetched = true;
      this._isFailed = !isSuccess;
      if (errorCode) this._errorCode = errorCode;
    });
  }
}
