import type { EditorThemeClasses } from 'lexical';

import './RichEditorTheme.css';

const theme: EditorThemeClasses = {
  blockCursor: 'rich-editor-blockCursor',
  characterLimit: 'rich-editor-characterLimit',
  code: 'rich-editor-code',
  codeHighlight: {
    atrule: 'rich-editor-tokenAttr',
    attr: 'rich-editor-tokenAttr',
    boolean: 'rich-editor-tokenProperty',
    builtin: 'rich-editor-tokenSelector',
    cdata: 'rich-editor-tokenComment',
    char: 'rich-editor-tokenSelector',
    class: 'rich-editor-tokenFunction',
    'class-name': 'rich-editor-tokenFunction',
    comment: 'rich-editor-tokenComment',
    constant: 'rich-editor-tokenProperty',
    deleted: 'rich-editor-tokenProperty',
    doctype: 'rich-editor-tokenComment',
    entity: 'rich-editor-tokenOperator',
    function: 'rich-editor-tokenFunction',
    important: 'rich-editor-tokenVariable',
    inserted: 'rich-editor-tokenSelector',
    keyword: 'rich-editor-tokenAttr',
    namespace: 'rich-editor-tokenVariable',
    number: 'rich-editor-tokenProperty',
    operator: 'rich-editor-tokenOperator',
    prolog: 'rich-editor-tokenComment',
    property: 'rich-editor-tokenProperty',
    punctuation: 'rich-editor-tokenPunctuation',
    regex: 'rich-editor-tokenVariable',
    selector: 'rich-editor-tokenSelector',
    string: 'rich-editor-tokenSelector',
    symbol: 'rich-editor-tokenProperty',
    tag: 'rich-editor-tokenProperty',
    url: 'rich-editor-tokenOperator',
    variable: 'rich-editor-tokenVariable',
  },
  embedBlock: {
    base: 'rich-editor-embedBlock',
    focus: 'rich-editor-embedBlockFocus',
  },
  hashtag: 'rich-editor-hashtag',
  heading: {
    h1: 'rich-editor-h1',
    h2: 'rich-editor-h2',
    h3: 'rich-editor-h3',
    h4: 'rich-editor-h4',
    h5: 'rich-editor-h5',
    h6: 'rich-editor-h6',
  },
  hr: 'rich-editor-hr',
  image: 'rich-editor-image',
  indent: 'rich-editor-indent',
  inlineImage: 'inline-rich-editor-image',
  layoutContainer: 'rich-editor-layoutContainer',
  layoutItem: 'rich-editor-layoutItem',
  link: 'rich-editor-link',
  list: {
    checklist: 'rich-editor-checklist',
    listitem: 'rich-editor-listItem',
    listitemChecked: 'rich-editor-listItemChecked',
    listitemUnchecked: 'rich-editor-listItemUnchecked',
    nested: {
      listitem: 'rich-editor-nestedListItem',
    },
    olDepth: [
      'rich-editor-ol1',
      'rich-editor-ol2',
      'rich-editor-ol3',
      'rich-editor-ol4',
      'rich-editor-ol5',
    ],
    ul: 'rich-editor-ul',
  },
  ltr: 'rich-editor-ltr',
  mark: 'rich-editor-mark',
  markOverlap: 'rich-editor-markOverlap',
  paragraph: 'rich-editor-paragraph',
  quote: 'rich-editor-quote',
  rtl: 'rich-editor-rtl',
  table: 'rich-editor-table',
  tableCell: 'rich-editor-tableCell',
  tableCellActionButton: 'rich-editor-tableCellActionButton',
  tableCellActionButtonContainer:
    'rich-editor-tableCellActionButtonContainer',
  tableCellEditing: 'rich-editor-tableCellEditing',
  tableCellHeader: 'rich-editor-tableCellHeader',
  tableCellPrimarySelected: 'rich-editor-tableCellPrimarySelected',
  tableCellResizer: 'rich-editor-tableCellResizer',
  tableCellSelected: 'rich-editor-tableCellSelected',
  tableCellSortedIndicator: 'rich-editor-tableCellSortedIndicator',
  tableResizeRuler: 'rich-editor-tableCellResizeRuler',
  tableRowStriping: 'rich-editor-tableRowStriping',
  tableScrollableWrapper: 'rich-editor-tableScrollableWrapper',
  tableSelected: 'rich-editor-tableSelected',
  tableSelection: 'rich-editor-tableSelection',
  text: {
    bold: 'rich-editor-textBold',
    code: 'rich-editor-textCode',
    italic: 'rich-editor-textItalic',
    strikethrough: 'rich-editor-textStrikethrough',
    subscript: 'rich-editor-textSubscript',
    superscript: 'rich-editor-textSuperscript',
    underline: 'rich-editor-textUnderline',
    underlineStrikethrough: 'rich-editor-textUnderlineStrikethrough',
  },
};

export default theme;
