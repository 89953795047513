import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import ButtonNonUi from 'components/ui/atoms/button/ButtonNonUi';
import TsuIcon from 'components/ui/atoms/icons/new/Tsu';
import Paragraph from 'components/ui/atoms/typography/Paragraph';
import Button from 'components/ui/atoms/button/Button';
import Flex from 'components/ui/atoms/flex/Flex';
import Form from 'components/ui/molecules/form/Form';
import FormInput from 'components/ui/molecules/form/FormInput';

import useRegisterMode from 'pages/auth/hooks/useRegisterMode';
import { useAuthPageViewModel } from 'pages/auth/viewModel/context';
import useLoginForm from 'pages/auth/hooks/useLoginForm';

import { ns } from 'utils/locales';

import classes from './LoginForm.module.scss';

const REDIRECT_URI = `${window.location.origin}/tsu/auth`;
const TSU_AUTH_URI = `${process.env.REACT_APP_TSU_AUTH_URL}?applicationId=${process.env.REACT_APP_TSU_APPLICATION_ID}&redirectUri=${REDIRECT_URI}`;

function LoginForm() {
  const { pageStatus } = useAuthPageViewModel();
  const { t } = useTranslation();
  const { methods, handleSubmit } = useLoginForm();
  const { enableRegisterMode } = useRegisterMode();

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <Flex vertical gap={24}>
        <FormInput name="email" id="login-form-email" type="email" label={t(`${ns.placeholders}:email`)} placeholder={t(`${ns.placeholders}:startTypingText`)} allowClear />
        <FormInput name="password" id="login-form-password" type="password" label={t(`${ns.placeholders}:password`)} placeholder={t(`${ns.placeholders}:startTypingText`)} />
        <Flex vertical gap={12} className={classes.buttons_container}>
          <Button variant="accent-alt" htmlType="submit" loading={pageStatus.isLoading}>{t(`${ns.buttons}:login`)}</Button>
          <Paragraph weight="bold" className={classes.or_label}>{t(`${ns.common}:or`)}</Paragraph>
          <Button variant="accent" icon={<TsuIcon />} href={TSU_AUTH_URI}>{t(`${ns.buttons}:loginWithTsu`)}</Button>
          <Paragraph weight="medium" className={classes.register_label}>
            {t(`${ns.auth}:notWithUs`)}
            {' '}
            <ButtonNonUi className={classes.register_link} onClick={enableRegisterMode}>
              {t(`${ns.auth}:register`)}
            </ButtonNonUi>
          </Paragraph>
        </Flex>
      </Flex>
    </Form>
  );
}

export default observer(LoginForm);
