import axios, { AxiosInstance } from 'axios';
import { LOGIN_TOKEN_URL, REFRESH_TOKEN_URL } from 'data/axios/constants';

import { ILoginResponse } from 'domain/repositories/api/auth/IAuthRepository';
import { tokenRepository } from 'domain/repositories/other/TokenRepository';
import { authRepository, userStore } from 'domain/repositories/api';

import { deleteAllHttpCache } from 'utils/cache';

type ResponseInterceptorParams = Parameters<typeof axios.interceptors.response.use>;
type ResponseInterceptorOnRejected = ResponseInterceptorParams[1];

let promiseOfGettingNewRefreshToken: Promise<ILoginResponse> | null = null;

export const getResponseInterceptorOnRejected = (axiosInstance: AxiosInstance) => {
  const responseInterceptorOnRejected: ResponseInterceptorOnRejected = (error) => {
    const originalRequest = error.config;

    if (originalRequest.url === REFRESH_TOKEN_URL) {
      promiseOfGettingNewRefreshToken = null;
      tokenRepository.reset();
      userStore.resetStore();
      deleteAllHttpCache();

      return null;
    }

    if (error.response?.status === 401
      && originalRequest.url !== LOGIN_TOKEN_URL
      && !originalRequest._retry
    ) {
      if (!promiseOfGettingNewRefreshToken) {
        originalRequest._retry = true;

        promiseOfGettingNewRefreshToken = authRepository
          .refresh({ refreshToken: tokenRepository.getRefreshToken() ?? '' })
          .then((response) => {
            tokenRepository.init(response.token, response.refreshToken);

            promiseOfGettingNewRefreshToken = null;
            return response;
          });
      }

      return promiseOfGettingNewRefreshToken.then(() => axiosInstance(originalRequest));
    }

    return Promise.reject(error);
  };

  return responseInterceptorOnRejected;
};
