import {
  IGetPlatformPermissionsPayload,
  IGetPlatformPermissionsResponse,
} from 'domain/repositories/api/permission/IPermissionRepository';
import { APIUseCase } from 'domain/useCases/common/APIUseCase';

export class GetPlatformPermissionsUseCase extends APIUseCase<
  IGetPlatformPermissionsPayload,
  IGetPlatformPermissionsResponse
> { }
