import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import {
  $isTextNode,
  DOMConversionMap,
  TextNode,
} from 'lexical';

import { SettingsContext } from './context/SettingsContext';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import { parseAllowedFontSize } from './plugins/ToolbarPlugin/fontSize';
import PlaygroundEditorTheme from './themes/RichEditorTheme';
import { parseAllowedColor } from './ui/ColorPicker';
import RichEditorNew from './RichEditorNew';
import { ToolbarContext } from './context/ToolbarContext';
import { SharedHistoryContext } from './context/SharedHistoryContext';

function getExtraStyles(element: HTMLElement): string {
  // Parse styles from pasted input, but only if they match exactly the
  // sort of styles that would be produced by exportDOM
  let extraStyles = '';
  const fontSize = parseAllowedFontSize(element.style.fontSize);
  const backgroundColor = parseAllowedColor(element.style.backgroundColor);
  const color = parseAllowedColor(element.style.color);
  if (fontSize !== '' && fontSize !== '15px') {
    extraStyles += `font-size: ${fontSize};`;
  }
  if (backgroundColor !== '' && backgroundColor !== 'rgb(255, 255, 255)') {
    extraStyles += `background-color: ${backgroundColor};`;
  }
  if (color !== '' && color !== 'rgb(0, 0, 0)') {
    extraStyles += `color: ${color};`;
  }
  return extraStyles;
}

function buildImportMap(): DOMConversionMap {
  const importMap: DOMConversionMap = {};

  // Wrap all TextNode importers with a function that also imports
  // the custom styles implemented by the playground
  // eslint-disable-next-line no-restricted-syntax
  for (const [tag, fn] of Object.entries(TextNode.importDOM() || {})) {
    importMap[tag] = (importNode) => {
      const importer = fn(importNode);
      if (!importer) {
        return null;
      }
      return {
        ...importer,
        conversion: (element) => {
          const output = importer.conversion(element);
          if (
            output === null
            || output.forChild === undefined
            || output.after !== undefined
            || output.node !== null
          ) {
            return output;
          }
          const extraStyles = getExtraStyles(element);
          if (extraStyles) {
            const { forChild } = output;
            return {
              ...output,
              forChild: (child, parent) => {
                const textNode = forChild(child, parent);
                if ($isTextNode(textNode)) {
                  textNode.setStyle(textNode.getStyle() + extraStyles);
                }
                return textNode;
              },
            };
          }
          return output;
        },
      };
    };
  }

  return importMap;
}

export type TRichEditorProps = {
  defaultValue?: string;
  name?: string;
  id?: string;
  editable?: boolean;
  onChange?: (value: string) => void;
}

export default function RichEditorWrapper(props: TRichEditorProps) {
  const {
    defaultValue, name, id, editable = true, onChange,
  } = props;

  const initialConfig = {
    editorState: null,
    html: { import: buildImportMap() },
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    editable,
    theme: PlaygroundEditorTheme,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <SettingsContext>
          <ToolbarContext>
            <RichEditorNew defaultValue={defaultValue} onChange={onChange} />
          </ToolbarContext>
        </SettingsContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}
