import { ErrorNotificationType, SuccessNotificationType } from 'application/notification/types';

import { TCreateReportPayload, TCreateReportResponse } from 'domain/repositories/api/report/IReportRepository';
import { APIUseCase } from 'domain/useCases/common/APIUseCase';

export class CreateReportUseCase extends APIUseCase<
  TCreateReportPayload,
  TCreateReportResponse
> {
  protected override readonly errorMessage: ErrorNotificationType = ErrorNotificationType
    .FAILED_TO_REPORT;

  protected override readonly successMessage: SuccessNotificationType = SuccessNotificationType
    .CREATED_REPORT;
}
