import {
  IGetCheckerRunOnTestDetailsPayload,
  IGetClassSubmissionsPayload,
  ISendSubmissionPayload,
  ISolutionRepository, IGetSolutionSubmissionsPayload,
  IGetSubmissionDetailsPayload,
  IUpdateSolutionPayload, IResendSubmissionPayload,
  IGetCheckerRunOnTestFullDataPayload, IUpdateSubmissionPayload,
} from 'domain/repositories/api/solution/ISolutionRepository';
import { ISolutionDataSource } from 'domain/repositories/api/solution/dataSource/ISolutionDataSource';

export class SolutionRepository implements ISolutionRepository {
  public constructor(private _ds: ISolutionDataSource) {}

  public getClassSubmissions = (payload: IGetClassSubmissionsPayload) => (
    this._ds.getClassSubmissions(payload)
  );

  public getSubmissionDetails = async (payload: IGetSubmissionDetailsPayload) => (
    this._ds.getSubmissionDetails(payload)
  );

  public sendSubmission = async (payload: ISendSubmissionPayload) => {
    const response = await this._ds.sendSubmission(payload);
    return { ...response, id: response.ID };
  };

  public resendSubmission = (payload: IResendSubmissionPayload) => (
    this._ds.resendSubmission(payload)
  );

  public updateSolution = (payload: IUpdateSolutionPayload) => this._ds.updateSolution(payload);

  public updateSubmission = (payload: IUpdateSubmissionPayload) => (
    this._ds.updateSubmission(payload)
  );

  public getSolutionSubmissions = (payload: IGetSolutionSubmissionsPayload) => (
    this._ds.getSolutionSubmissions(payload)
  );

  public getCheckerRunOnTestDetails = (payload: IGetCheckerRunOnTestDetailsPayload) => (
    this._ds.getCheckerRunOnTestDetails(payload)
  );

  public getCheckerRunOnTestInput = (p: IGetCheckerRunOnTestFullDataPayload) => (
    this._ds.getCheckerRunOnTestInput(p)
  );

  public getCheckerRunOnTestOutput = (p: IGetCheckerRunOnTestFullDataPayload) => (
    this._ds.getCheckerRunOnTestOutput(p)
  );

  public getCheckerRunOnTestUserOutput = (p: IGetCheckerRunOnTestFullDataPayload) => (
    this._ds.getCheckerRunOnTestUserOutput(p)
  );

  public getCheckerRunOnTestHistory = (p: IGetCheckerRunOnTestFullDataPayload) => (
    this._ds.getCheckerRunOnTestHistory(p)
  );
}
