import React from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import SectionStructure from 'components/ui/organisms/classStructure/SectionStructure';
import SegmentedLocalization from 'components/ui/atoms/segmentedLocalization/SegmentedLocalization';
import { useEntityDrawerViewModel } from 'components/ui/organisms/entityDrawer/viewModel/context';
import TwoColumns from 'components/ui/layout/twoColumns/TwoColumns';

import { getFieldFromTranslations } from 'utils/locales/getFieldFromTranslations';

const { Paragraph, Title } = Typography;

const EntityDrawerSectionProxyContent: React.FC = () => {
  const { sectionProxyViewModel } = useEntityDrawerViewModel();

  const {
    section,
    localization,
    tasks,
    setLocalization,
  } = sectionProxyViewModel;

  return (
    <TwoColumns>
      <div>
        <Title level={5}>{getFieldFromTranslations('name', section?.translations ?? [], localization)}</Title>
        <Paragraph>{getFieldFromTranslations('description', section?.translations ?? [], localization)}</Paragraph>
        <SectionStructure
          data={tasks ?? []}
        />
      </div>
      <SegmentedLocalization onChange={setLocalization} />
    </TwoColumns>
  );
};

export default observer(EntityDrawerSectionProxyContent);
